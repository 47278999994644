<div *ngIf="show" [@state]="show ? 'enter' : 'leave'" class="snackbar-wrap">
  <div class="snackbar-content">
    <div class="snackbar-icon">
      <img src="{{ titleSvgPath }}" />
    </div>
    <div class="snackbar-text">
      <div *ngIf="title" class="snackbar-title">{{ title }}</div>
      <div class="snackbar-subtext">{{ message }}</div>
    </div>
  </div>
</div>
