import { TriggerReinvitePayload } from '@action/roster-page/roster-page.actions'
import {
  UploadRosterUserDto,
  UserDtoProps,
  UserDtoStatus,
  UserRosterStatus,
  UserValidationStatus
} from '@model/user/user.model'
import {
  RosterColumnsEnum,
  RosterColumnsNoMenu,
  RosterColumnsNoRole
} from '@page/roster/roster.constants'
import {
  RosterTabToTabVmLookup,
  RosterTabViewModel,
  RosterTabs,
  RosterUserValidationDto,
  RosterValidationDto,
  UploadRosterPropResultDto
} from './roster-page.view'

/** While only RosterColumnsWithBackgroundStyle can have changed or invalid all columns can have dimmed, and ideally this is improved. */
export type RosterUserVmBackgroundStyleLookup = Record<RosterColumnsEnum, RosterTableCellClasses>

export enum RosterTableCellClasses {
  changed = '_changed-roster-user-prop',
  invalid = '_invalid-roster-user-prop',
  none = ''
}

export class RosterTableViewModel {
  /**
   *  Gets the collection of upload roster user dtos from the roster validation dto.
   */
  static getUploadRosterVm(dto: RosterValidationDto | null): UploadRosterUserDto[] {
    return (
      dto?.users
        ?.filter((user) => user.status !== UserValidationStatus.removed)
        .map((item) => item.user) ?? []
    )
  }
  /** Updates the loading state for a particular tab. */
  static getUpdatedRosterTabWithReinviteSuccess(
    rosterTabVmLookupByTab: RosterTabToTabVmLookup,
    payload: TriggerReinvitePayload,
    loadingState: boolean
  ): RosterTabToTabVmLookup {
    const { tab } = payload
    const tabRequestFor = rosterTabVmLookupByTab[tab]
    if (!tabRequestFor) {
      console.error('getUpdatedRosterTabWithReinviteSuccess: tabRequestFor is undefined')
      return rosterTabVmLookupByTab
    }
    const newRosterTabVm: RosterTabViewModel = {
      ...tabRequestFor,
      isInProcess: loadingState
    }
    return {
      ...rosterTabVmLookupByTab,
      [tab]: newRosterTabVm
    }
  }
  /** Return columns specific for a tab and if in preview mode.
   * Only mobile user type should be able to see the preview mode.
   */
  static getTableDisplayedColumns(
    isPreviewMod: boolean,
    selectedRosterTab: RosterTabs
  ): RosterColumnsNoMenu[] | RosterColumnsNoRole[] | RosterColumnsEnum[] {
    if (isPreviewMod) {
      return RosterTableViewModel.getPreviewColumns()
    } else if (selectedRosterTab === RosterTabs.consolidatedAllUsers || selectedRosterTab === RosterTabs.admins) {
      return Object.values(RosterColumnsEnum)
    } else {
      return RosterTableViewModel.getDefaultColumns()
    }
  }
  static getPreviewColumns(): RosterColumnsNoMenu[] {
    return [
      RosterColumnsEnum.name,
      RosterColumnsEnum.phone,
      RosterColumnsEnum.email,
      RosterColumnsEnum.statusImage,
      RosterColumnsEnum.statusForSorting
    ]
  }
  static getDefaultColumns(): RosterColumnsNoRole[] {
    return [
      RosterColumnsEnum.name,
      RosterColumnsEnum.phone,
      RosterColumnsEnum.email,
      RosterColumnsEnum.statusImage,
      RosterColumnsEnum.statusForSorting,
      RosterColumnsEnum.menu
    ]
  }
  static getBackgroundStyleByColumns(): RosterUserVmBackgroundStyleLookup {
    return Object.values(RosterColumnsEnum).reduce((acc, column) => {
      acc[column] = RosterTableCellClasses.none
      return acc
    }, {} as RosterUserVmBackgroundStyleLookup)
  }
  static getBackgroundStyleByDto(dto: RosterUserValidationDto): RosterUserVmBackgroundStyleLookup {
    return Object.values(RosterColumnsEnum).reduce((acc, column) => {
      acc[column] = RosterTableViewModel.getBackgroundStyleForColumn(column, dto)
      return acc
    }, {} as RosterUserVmBackgroundStyleLookup)
  }
  /** Rows should be dimmed if the user is deactivated or removed. */
  static isDimmed = (status: UserRosterStatus) => {
    return status === (UserDtoStatus.deactivated || UserValidationStatus.removed)
  }

  /** Each roster table cell has it's own style based on validation of the uploaded roster, either changed, or invalid or none. */
  static getBackgroundStyleForColumn = (
    key: RosterColumnsEnum,
    dto: RosterUserValidationDto
  ): RosterTableCellClasses => {
    const { changes, errors } = dto
    if (!changes && !errors) {
      // console.warn('getBackgroundStyle: changes and errors is undefined')
      return RosterTableCellClasses.none
    }
    if (RosterTableViewModel.getIsPropInValidationPropArray(key, changes)) {
      return RosterTableCellClasses.changed
    } else if (RosterTableViewModel.getIsPropInValidationPropArray(key, errors)) {
      return RosterTableCellClasses.invalid
    }
    return RosterTableCellClasses.none
  }
  /** TODO Optimize so we can get the errors by logical id generated client side rather than iteration. */
  static getIsPropInValidationPropArray = (
    key: RosterColumnsEnum,
    arr: UploadRosterPropResultDto[]
  ): boolean => {
    if (arr?.length > 0) {
      if (key === 'name') {
        return (
          arr.filter((item) => item.key.toLowerCase() === UserDtoProps.firstName.toLowerCase())
            .length > 0 ||
          arr.filter((item) => item.key.toLowerCase() === UserDtoProps.lastName.toLowerCase())
            .length > 0
        )
      }

      return arr.filter((item) => item.key.toLowerCase() === key).length > 0
    }
    return false
  }
}
