import { HttpErrorResponse } from '@angular/common/http'
import { UserDtoProps } from './user.model'
import { phoneCountryCode } from '@shared/constants'

export class UserServerValidationModel {
  static getEmailKey = (email: string) => `${UserDtoProps.email}-${email}`
  /** When we operate on a user entered phone we don't make the user type in the country code as that's assumed as a constant +1 */
  static getClientSidePhoneKey = (phone: string) =>
    `${UserDtoProps.phone}-${phoneCountryCode}${phone}`
  static getPhoneKey = (phone: string) => `${UserDtoProps.phone}-${phone}`
  static errorContainsEmail = (res: HttpErrorResponse): boolean => {
    return res.error?.detail?.includes(UserDtoProps.email)
  }
  static errorContainsPhone = (res: HttpErrorResponse): boolean => {
    return res.error?.detail?.includes(UserDtoProps.phone)
  }
}
