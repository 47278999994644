import { Injectable } from '@angular/core'
import { GetResponseGroupDto } from '@model/message/response-group.model'
import { ApiService } from './api.service'

@Injectable()
export class ResponseGroupApiService {
  public readonly serviceBaseDir = '/ResponseGroup'
  constructor(private _apiService: ApiService) {}
  getResponseGroups(schoolId: number) {
    return this._apiService.get<GetResponseGroupDto[]>(
      `${this.serviceBaseDir}/all?schoolId=${schoolId}`
    )
  }
}
