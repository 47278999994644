import MapView from '@arcgis/core/views/MapView'
import { mockUnknownLogicalId } from '@mock/school.mock'
import { SchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { MapPageType } from '@service/map/map.component.service.model'
import { defaultArcGisZoom, tempHardCodedRotation } from '@shared/constants'
import { MapViewModel } from '@view/map/map.view'
import {
  IMapConfigAreaViewModel,
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'

export class ArcGisMapViewHandler {
  static updateToFailedHighlights(context: ArcGisMapService): void {
    context._mapView.highlightOptions = ArcGisSymbolFactory.getFailedEditHighlightOptions()
  }
  static updateToEditHighlights(context: ArcGisMapService): void {
    context._mapView.highlightOptions = ArcGisSymbolFactory.getHighlightOptions()
  }
  /** Whenever we update an area we need certain items to validate the change as well as construct the patch object. */
  static getMapConfigAreaVm = (context: ArcGisMapService): IMapConfigAreaViewModel => {
    const existingAreaNames: string[] = []
    if (context._schoolAreaLayer) {
      context._schoolAreaLayer.graphics.forEach((g) => {
        if (g?.attributes?.name) {
          existingAreaNames.push(g?.attributes?.name)
        }
      })
    }
    if (!context.schoolDto) {
      throw Error(`Arc gis context doesn't have ref to school dto so can't save area!`)
    }
    return {
      existingAreaNames,
      schoolLogicalId: context.schoolDto[SchoolDtoProps.logicalId] ?? mockUnknownLogicalId,
      schoolId: context.schoolDto[SchoolDtoProps.id] ?? 0
    }
  }
  static getMapView = (context: ArcGisMapService, schoolDto: SchoolDto): MapView | null => {
    const { latLong } = schoolDto
    const { lat, lon } = latLong ?? {}
    if (!lat || !lon) {
      console.error(`School dto missing lat lon!`)
      return null
    }
    let selector = context.vm.mapSelector
    if (context.type === MapPageType.schoolMapConfig) {
      selector = MapViewModel.getConfigVersionOfSelector()
    }
    const allowRotation = context?.activeConfigStep === MapConfigSteps.mapView
    const mapView: MapView = new MapView({
      map: context._map,
      center: [lon, lat],
      zoom: schoolDto?.schoolConfiguation?.zoomLevel ?? defaultArcGisZoom,
      container: selector,
      rotation: schoolDto?.schoolConfiguation?.mapViewRotation ?? tempHardCodedRotation,
      spatialReference: ArcgisSpatialRefence.webMercatorSpatialReference,
      ui: {
        components: ['attribution']
      } as __esri.UIProperties,
      constraints: {
        snapToZoom: false,
        rotationEnabled: allowRotation
        // TODO Discuss max zoom concept
        // maxScale: 20,
        // minScale: 10,
        // maxZoom: 20,
        // TODO Clarify min zoom to reduce cost of tile service
        // minZoom: 10
      } as __esri.MapViewConstraints,
      navigation: {
        // gamepad: {
        //     enabled: true
        // },
        browserTouchPanEnabled: true,
        momentumEnabled: false,
        mouseWheelZoomEnabled: true
      } as __esri.NavigationProperties,
      highlightOptions: ArcGisSymbolFactory.getHighlightOptions()
    })
    // Here in case we want to enable keyboard and mouse map rotation, in which case we have to sync the rotation value to the slider - see comments on function below
    // reactiveUtils.watch(
    //   () => mapView.rotation,
    //   (v) => this.handleUserMapRotation(context, v)
    // )
    return mapView
  }
  /** A and D key or right click hold and move on map rotates map when this is enabled - only on the map view config page
   * To implement this feature we'd need to sync the rotation value based on map interactions to the slider which isn't currently done, so keep map rotation disabled and do it via slider
   * the commented out section for reactive utils will need to be implemented if we want the user to be able to adjust the rotation via map interactions or keyboard interactions */
  // static handleUserMapRotation = (context: ArcGisMapService, v: number) => {
  //   context.store.dispatch(updateRotationSliderFromArcGis(getExtendedAction(v)))
  // }
}
