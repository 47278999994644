import { AreaGraphicAttributes } from './area.view'
import { LocationGraphicAttributes, LocationOfAlertResponseAttr } from './location.view'

export type GraphicAttributes =
  | AreaGraphicAttributes
  | LocationGraphicAttributes
  | LocationOfAlertResponseAttr

/** Excludes the recent message indicators since those change more dynamically. NOTE: Dimmed alert types were intentionally not added here as that is a layer that the graphic moves to after some time not changing it's type.*/
export enum GraphicAttributesTypeEnum {
  area = 'area',
  location = 'location',
  recentMessageIndicator = 'recentMessageIndicator',
  pollResponse = 'pollResponse',
  messageResponse = 'messageResponse',
  attackAlertResponse = 'attackAlertResponse',
  medicalAlertResponse = 'medicalAlertResponse',
  unknown = 'unknown'
}
/** Specific to the user recent message layers that indicate next to the user's location their recent messages by priority rules. */
export enum RecentMessageIndicatorTypeEnum {
  alert = 'alert',
  medicalAlert = 'medicalAlert',
  chatMessage = 'chatMessage',
  greenPoll = 'greenPoll',
  redPoll = 'redPoll',
  yellowPoll = 'yellowPoll',
  redWithMessagePoll = 'redWithMessagePoll',
  yellowWithMessagePoll = 'yellowWithMessagePoll',
  greenWithMessagePoll = 'greenWithMessagePoll',
  unknown = 'unknown'
}
export namespace RecentMessageViewModel {
  export const isPollWithMessage = (t?: RecentMessageIndicatorTypeEnum): boolean => {
    return !!t && POLL_WITH_MESSAGE_OPTIONS.includes(t)
  }
  export const isPollWithoutMessage = (t?: RecentMessageIndicatorTypeEnum): boolean => {
    return !!t && POLL_OPTIONS.includes(t)
  }
  export const getPollWithMessageVersion = (
    t: RecentMessageIndicatorTypeEnum
  ): RecentMessageIndicatorTypeEnum => {
    switch (t) {
      case RecentMessageIndicatorTypeEnum.redPoll:
        return RecentMessageIndicatorTypeEnum.redWithMessagePoll
      case RecentMessageIndicatorTypeEnum.yellowPoll:
        return RecentMessageIndicatorTypeEnum.yellowWithMessagePoll
      case RecentMessageIndicatorTypeEnum.greenPoll:
        return RecentMessageIndicatorTypeEnum.greenWithMessagePoll
      default:
        return RecentMessageIndicatorTypeEnum.unknown
    }
  }
  export const getPollFromPollWithMessage = (
    t: RecentMessageIndicatorTypeEnum
  ): RecentMessageIndicatorTypeEnum => {
    switch (t) {
      case RecentMessageIndicatorTypeEnum.redWithMessagePoll:
        return RecentMessageIndicatorTypeEnum.redPoll
      case RecentMessageIndicatorTypeEnum.yellowWithMessagePoll:
        return RecentMessageIndicatorTypeEnum.yellowPoll
      case RecentMessageIndicatorTypeEnum.greenWithMessagePoll:
        return RecentMessageIndicatorTypeEnum.greenPoll
      default:
        return RecentMessageIndicatorTypeEnum.unknown
    }
  }
  const POLL_WITH_MESSAGE_OPTIONS = [
    RecentMessageIndicatorTypeEnum.greenWithMessagePoll,
    RecentMessageIndicatorTypeEnum.yellowWithMessagePoll,
    RecentMessageIndicatorTypeEnum.redWithMessagePoll
  ]
  const POLL_OPTIONS = [
    RecentMessageIndicatorTypeEnum.greenPoll,
    RecentMessageIndicatorTypeEnum.yellowPoll,
    RecentMessageIndicatorTypeEnum.redPoll
  ]
}
