import { Injectable } from '@angular/core';
import { CanActivate, CanMatch } from '@angular/router';
import { UserTypes } from '@model/user/user.model';
import { UserDtoHelper } from "@domain/dto-helpers/user-model.helper";
import { Store } from '@ngrx/store';
import { selectGlobalState } from '@selector/auth.selector';
import { Observable, map } from 'rxjs';

/** We can only let a user visit the map to draw areas once they've selected a school.*/
@Injectable()
export class SchoolAreasMapGuard implements CanActivate, CanMatch {
    constructor(
        private store: Store
    ) { }

    canMatch(): Observable<boolean> {
        return this.accessControlLogic
    }
    canActivate(): Observable<boolean> {
        return this.accessControlLogic
    }
    accessControlLogic = this.store.select(selectGlobalState).pipe(map(
        (authState => authState.userDto?.type === UserTypes.globalAdmin || UserDtoHelper.schoolIdsExist(authState.userDto?.schoolIds))))
}