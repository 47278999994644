import { setScrollPlacesInChatRooms } from '@action/dashboard-page/chat-ui.actions'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { logSignalrEvent } from '@action/global-app.actions'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { FirstLevelDir, OwlRoutes } from '@domain/route/app-routes.domain'
import { LoggingSeverity } from '@microsoft/applicationinsights-web'
import { GetSchoolDto } from '@model/school/school.model'
import { OrgStatsModel } from '@model/user-organization/user-org-stats.model'
import { GetUserDto } from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { selectPageIsDashboard } from '@selector/route.selector'
import { LocalStorageKeys, SessionStorageKeys } from '@service/browser/base-storage.service'
import { LocalStorageService } from '@service/browser/local-storage.service'
import { SessionStorageService } from '@service/browser/session-storage.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { isLocalHost } from '@shared/js-window'

import { ScrollPlacesInChatRoom } from '@view/chat/chat.view'
import { Subscription } from 'rxjs'

@Injectable()
export class RouterService {
  currentPageIsDashSub: Subscription | null = null
  constructor(
    private router: Router,
    private store: Store,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private loggingService: LoggingService
  ) {
    this.currentPageIsDashSub = this.store
      .select(selectPageIsDashboard)
      .subscribe(this.handleIsDashPage)
  }
  handleIsDashPage = (isDash: boolean) => {
    if (isDash) {
      this.store.dispatch(
        setScrollPlacesInChatRooms(
          getExtendedAction(
            this.localStorageService.getKey<Record<string, ScrollPlacesInChatRoom>>(
              LocalStorageKeys.chatMessageReadReceipt
            ) ?? {}
          )
        )
      )
    }
  }
  navigate = (url: string): Promise<boolean> => {
    return this.router.navigateByUrl(url)
  }
  routeToSavedOrDefaultPage = (
    userDto: GetUserDto | null,
    orgStats: OrgStatsModel
  ) => {
    this.log('Routing to saved or default page')
    if (!userDto) {
      throw Error('UserDto is undefined')
    }
    // Without a school dto, or needed nested fields user can't yet use the saved initial page request feature
    let atLeastOneMobileUserExists = orgStats.mobileUserCount > 0
    let userSchooMapIsConfigured = OrgStatsModel.hasCompleteMapConfig(orgStats)
    let userSchoolIdsExist = UserDtoHelper.schoolIdsExist(userDto.schoolIds)
    let userCanUseSavedPageFeature = false
    let savedPage = this.sessionStorageService.getStringValue(SessionStorageKeys.initUrlRequestKey)
    //If they're allowed to use the saved page feature that overrides all other rules
    userCanUseSavedPageFeature =
      userSchoolIdsExist && userSchooMapIsConfigured && atLeastOneMobileUserExists

    this.log(`User can use saved page feature: ${userCanUseSavedPageFeature}`)

    if (userCanUseSavedPageFeature && savedPage) {
      this.log(`Routing to saved url: ${savedPage}`)
      this.navigate(savedPage)
    } else {
      if (UserDtoHelper.hasNoSchoolId(userDto.schoolIds)) {
        this.log('Routing to school search page')
        this.navigateToSchoolValidation()
      }
      //Take them to the school map config page if they're missing a boundary or areas
      else if (
        OrgStatsModel.hasIncompleteMapConfig(orgStats)) {
        this.log('Routing to school map config page')
        this.navigateToMapConfig()
      } else if (!OrgStatsModel.hasCompletedRoster(orgStats)) {
        this.log('Routing to roster page')
        this.navigateToUserRoster()
      } else if (OrgStatsModel.canAccessDashboard(orgStats)) {
        this.log('Routing to dashboard page')
        this.navigateToDashboard()
      }
      // this.handleDefaultRouteByUserDto(authState)
    }
  }
  // Navigation methods
  navigateToSchoolValidation = () => this.navigate(OwlRoutes.schoolValidationPage)
  navigateToMapConfig = () => this.navigate(OwlRoutes.firstLevelRoutes.setupSchoolAreasMap)
  navigateToUserRoster = () => this.navigate(OwlRoutes.firstLevelRoutes.roster)
  navigateToDashboard = () => this.navigate(OwlRoutes.firstLevelRoutes.dashboard)

  log = (m: string) => {
    this.loggingService.logLocally(m, OwlLoggingArea.ROUTING, OwlLogLevels.VERBOSE)
  }
  /** Domain logic that specifies onboarding stesp - TODO Move to external class */
  // handleDefaultRouteByUserDto = (authState:AuthState) => {

  // }
}
