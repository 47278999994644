import { getExtendedAction } from '@action/extended-ngrx-action'
import { patchAreaPendingDto } from '@action/school-map-config-page.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { BaseMapPopup } from '@component/shared/base-map-popup.model'
import { mockUnknownLogicalId } from '@mock/school.mock'
import { PatchSchoolAreaDto } from '@model/school/school-subarea.model'
import { AreaTypeDisplayNames, SelectableAreaType } from '@model/school/sub-area.model'
import { Store } from '@ngrx/store'
import { SchoolMapConfigPageComponentService } from '@page/school/map-config/map-config-page.component.service'
import { AppConfigService } from '@service/app-config/app-config.service'
import { alphanumericRegex, alphanumericValidationMessage } from '@shared/constants'
import { AreaGraphicAttributes, BaseAreaGraphicAttributes } from '@view/area/area.view'
import { OwlImageViewModel, SaveAreaIconOptions } from '@view/image.view'
import { MapConfigValidationErrors, MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import {
  IMapConfigAreaViewModel
} from '@view/pages/school-map-config-page/school-map-config.view'
import { BaseSelectOption } from '@view/shared/base-select.view'
import { Subscription, map } from 'rxjs'

@Component({
  selector: 'map-config-edit-area-popup',
  templateUrl: './map-config-edit-area-popup.component.html',
  styleUrls: ['./map-config-edit-area-popup.component.scss']
})
export class MapConfigEditAreaPopupComponent extends BaseMapPopup {
  cta = 'Enter Area Information'
  areaTypeLabel = 'Area type'
  areaNameInputLabel = 'Area name'
  areaNameInputControl: FormControl = this.fb.control('')

  areaTypeValue: SelectableAreaType | null = null
  areaTypeOptions: BaseSelectOption[] = Object.entries(AreaTypeDisplayNames).map((e) => {
    return {
      value: e[0],
      display: e[1]
    }
  })
  areaAttributes: BaseAreaGraphicAttributes | null = null
  private _schoolId: number = 0
  private _schoolLogicalId: string = mockUnknownLogicalId
  rings: number[][] = []

  /** Here for unified external handling for base class but this popup doesn't have the same concept as the dash popup does. */
  get isShownDueToClick(): boolean {
    return this.popupVisible
  }
  hasError$ = this.pageCompServ.errorReason$.pipe(
    map((errorReason) => {
      const hasError = !!errorReason
      // This is purely for UX so don't trigger value changes which this component relies on for queueing up async saves, also we must verify that it's a geom related error that disables select and input interactions
      if (hasError && MapConfigValidationViewModel.errorIsRelatedToAreaGeom(errorReason)) {
        this.areaNameInputControl.disable({ emitEvent: false })
      } else {
        this.areaNameInputControl.enable({ emitEvent: false })
      }
      return !!errorReason
    })
  )
  errorMessage$ = this.pageCompServ.errorReason$.pipe(
    map((r) => (r ? MapConfigValidationViewModel.mapConfigErrorMessages[r] ?? '' : ''))
  )

  // TODO Move to area view model
  errorImageSrc$ = this.pageCompServ.asyncSavePending$.pipe(
    map((lookupOfError) => {
      let image: SaveAreaIconOptions | null = null
      if (lookupOfError instanceof HttpErrorResponse) {
        image = SaveAreaIconOptions.error
      } else if (Object.values(lookupOfError)) {
        const areaId = this.areaAttributes?.id ?? null
        if (!areaId) {
          return ''
        }
        const loading = lookupOfError[areaId] ?? null
        if (loading !== null) {
          image = loading ? SaveAreaIconOptions.loading : SaveAreaIconOptions.saved
        }
      }
      if (image) {
        return OwlImageViewModel.getIconFullPath(image)
      }
      return ''
    })
  )

  /** Max length driven by app config */
  get errMsg(): string {
    const { pattern } = this?.areaNameInputControl?.errors ?? {}
    if (pattern) {
      return alphanumericValidationMessage
    }
    const currentValue = this.areaNameInputControl.getRawValue()
    const exceededCount = currentValue.length - this.appConfig.config.SCHOOL_AREA_NAME_MAX_CHAR
    if (exceededCount <= 0) {
      return `${MapConfigValidationViewModel.mapConfigErrorMessages[MapConfigValidationErrors.nameTooLong]
        } by ${exceededCount} character${exceededCount === 1 ? '' : 's'}.`
    } else {
      return ''
    }
  }

  constructor(
    cd: ChangeDetectorRef,
    elementRef: ElementRef,
    public pageCompServ: SchoolMapConfigPageComponentService,
    private store: Store,
    private fb: FormBuilder,
    private appConfig: AppConfigService
  ) {
    super(cd, elementRef)
  }
  areaNameSub: Subscription | null = null
  updateInputControl = (name: string) => {
    if (this.areaNameSub) {
      this.areaNameSub.unsubscribe()
    }
    this.areaNameInputControl = this.fb.control(name, [
      Validators.maxLength(this.appConfig.config.SCHOOL_AREA_NAME_MAX_CHAR),
      Validators.pattern(alphanumericRegex)
    ])
    this.areaNameSub = this.areaNameInputControl.valueChanges.subscribe(this.handleInputChange)
  }
  handleSelectionChange = () => {
    if (!this.areaTypeValue) {
      console.error(`DEV ERROR: select type for map config code error`)
      return
    }
    this.dispatchUpdatedPatchAreaPendingDto()
  }
  getPatchSchoolAreaDtoFromLocalRef = (): PatchSchoolAreaDto => {
    return {
      schoolId: this._schoolId,
      id: this.areaAttributes?.id ?? 0,
      type: this.areaTypeValue ?? undefined,
      name: this.areaNameInputControl.getRawValue() ?? undefined,
      boundary: this.rings ?? []
    }
  }
  /** Dispatch a new area name for validations as side effects before the debounced save can happen */
  handleInputChange = () => {
    if (!this.areaTypeValue) {
      console.error(`DEV ERROR: select type for map config code error`)
      return
    }
    this.dispatchUpdatedPatchAreaPendingDto()
  }
  dispatchUpdatedPatchAreaPendingDto = () => {
    this.store.dispatch(
      patchAreaPendingDto(getExtendedAction(this.getPatchSchoolAreaDtoFromLocalRef()))
    )
  }
  /**Builds the patch school area dto from local state using current type and name to avoid losing a debounced partial patch, patch all values that can be updated */
  handleRingsChange = (rings: number[][], canDispatchSave: boolean = true) => {
    if (!this.areaTypeValue) {
      console.error(`DEV ERROR: select type for map config code error`)
      return
    }
    // Save ref to latest update in case we want to enable editing geom while name is not valid
    // console.log(`Saving rings ref in map config popup`)
    this.rings = rings
    if (canDispatchSave) {
      // console.log(`Dispatching patch area with new rings`)
      this.dispatchUpdatedPatchAreaPendingDto()
    }
  }

  /** Consider moving to a base abstract class in regard to graphic related popups */
  setGraphic = (graphic: __esri.Graphic) => {
    this.graphic = graphic
  }
  setContent = (attributes: AreaGraphicAttributes, vm: IMapConfigAreaViewModel) => {
    this._schoolId = vm.schoolId
    this._schoolLogicalId = vm.schoolLogicalId
    this.updateInputControl(attributes.name)
    this.areaTypeValue = attributes.type as SelectableAreaType
    this.areaAttributes = attributes
    this.showPopup()
  }
  reset = () => {
    this.rings = []
    this.graphic = null
    this.areaAttributes = null
    this.areaTypeValue = null
    this.updateInputControl('')
  }
}
