import { INVITE_REINVITE_BUTTON_TOOLTIP_TEXT } from '@page/roster/roster.constants'
import {
  RosterPageViewModel,
  RosterTabs,
  RosterTabToMobileUserType,
  RosterUserDisplayString
} from './roster-page.view'

export class RosterTopSectionViewModel {
  /** Update the roster page subtitle when there's a preview of validations or include instructions for uploading a roster file. */
  static getSubTitle(isPreviw: boolean | null, selectedRosterTab: RosterTabs): string {
    if (
      selectedRosterTab === RosterTabs.admins ||
      selectedRosterTab === RosterTabs.consolidatedAllUsers
    ) {
      console.warn(
        `RosterTopSectionViewModel.getSubTitle: selectedRosterTab: ${selectedRosterTab} is not supported`
      )
      return ''
    }
    // These string literals need to be dynamically generated. And extracted to elsewhere.
    return isPreviw
      ? `Review roster content for accuracy. Click Re-Upload Roster to load an updated roster file and fix any errors, or click Confirm Upload to use this roster.`
      : 'Upload and configure rosters for teachers, staff, and students.'
  }
  static getTitle(isPreview: boolean, selectedRosterTab: RosterTabs): string {
    return isPreview && selectedRosterTab
      ? `${RosterPageViewModel.getTabButtonText(selectedRosterTab, false)} Roster`
      : 'Users'
  }
  static getReinviteButtonTooltipText = (tab: RosterTabs | null): string => {
    if (tab) {
      return `${INVITE_REINVITE_BUTTON_TOOLTIP_TEXT} ${RosterPageViewModel.getTabButtonText(
        tab,
        true
      )}`
    }
    return INVITE_REINVITE_BUTTON_TOOLTIP_TEXT
  }
  static getUploadButtonText = (rosterTab: RosterTabs): string => {
    const userType = RosterTabToMobileUserType[rosterTab]
    return userType ? `Upload ${RosterUserDisplayString[userType]} Roster` : ''
  }
}
