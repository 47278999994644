import { getUsersForSchool, logoutAuthAction, tokenReceived } from '@action/auth.action'
import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import { getPredefinedGroupsSuccess } from '@action/messages/predefined-messages-api.actions'
import { getResponseGroupsSuccess } from '@action/messages/response-group-api.actions'
import { getSchoolByIdSuccess } from '@action/school/school-validation-api.action'
import { setMapUiControlSessionState } from '@action/user/dashboard-page.action'
import {
  getUserError,
  getUserSuccess,
  getUsersForSchoolError,
  getUsersForSchoolSuccess
} from '@action/user/user-api.action'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppCoreDataService } from '@domain/app-core-data.service'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { mockUnknownSchoolId } from '@mock/school.mock'
import { DashboardPageDependencies } from '@model/user-organization/global-redirects.model'
import { OrgStatsModel } from '@model/user-organization/user-org-stats.model'
import { GetUserDto, SchoolIds } from '@model/user/user.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { routerNavigatedAction } from '@ngrx/router-store'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import {
  selectDashSubscriptionDependencies,
  selectGlobalRedirectHandleDeps
} from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AuthService } from '@service/auth/auth.service'
import { SessionStorageKeys } from '@service/browser/base-storage.service'
import { SessionStorageService } from '@service/browser/session-storage.service'
import { RouterService } from '@service/core/router.service'
import { UserApiService } from '@service/network/user.service'
import { GlobalStateProps } from '@state/auth.state'
import { IMapUiControlSessionSettings } from '@view/pages/dashboard-page/dashboard-page.view'
import { catchError, map, mergeMap, of, tap } from 'rxjs'

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService,
    private routeService: RouterService,
    private authService: AuthService,
    private store: Store,
    private sessionStorageService: SessionStorageService,
    private appConfigService: AppConfigService,
    private coreAppDataService: AppCoreDataService
  ) { }
  getTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenReceived),
      mergeMap((a: ExtendedAction<string>) =>
        this.userApiService.getUserByToken().pipe(
          map((res: GetUserDto) => {
            return getUserSuccess(getExtendedAction(res))
          }),
          catchError((e: any) => of(getUserError(getExtendedAction(e))))
        )
      )
    )
  )
  ensureCoreDataExistsOnNav$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          routerNavigatedAction
        ),
        concatLatestFrom((_) => this.store.select(selectGlobalState)),
        tap(([payload, globalState]) => {
          if(this.appConfigService.isPublicSite()){
            return
          }
          // console.log(`Router navigation action`, payload)
          const userDto = globalState[GlobalStateProps.userDto]
          if (!userDto) {
            console.warn(`No user dto on router navigation action`)
            return
          }
          const selectedSchoolId = globalState[GlobalStateProps.selectedSchoolId].id
          if (selectedSchoolId === mockUnknownSchoolId.id) {
            console.warn(`No selected school id after map config complete`)
            return
          }
          //If no core data, and trigger network calls to load core data
          if (this.coreAppDataService.isMissingCoreData(globalState)) {
            // Intentionally pass no school dto to trigger getting all global data needed across pages.
            this.coreAppDataService.handleCoreAppDataConcerns(userDto)
          }
        })
      ),
    { dispatch: false }
  )
  getUserDtoEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserSuccess),
        tap((action) => {
          this.coreAppDataService.handleCoreAppDataConcerns(action.payload)
        })
      ),
    { dispatch: false }
  )
  // This will make testing easier so just add a local dev wrapper around a select box in profile page to change the selected school id. This will eventually need to be integrated anyway.
  // handleChangeSelectedSchoolId$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(selectDifferentSchoolId),
  //       concatLatestFrom((_) => this.store.select(selectAuthState)),
  //       tap(([action, authState]) => {
  //       tap((action) => {
  //         const lookup = authState[AuthStateProps.userSchoolLookup]
  //         const selectedSchoolId = authState[AuthStateProps.selectedSchoolId].id
  //         const schoolDto = lookup[selectedSchoolId]
  //         if(!schoolDto){
  //           this.coreAppDataService.handleCoreAppDataConcerns(action.payload)
  //         }
  //       })
  //     ),
  //   { dispatch: false }
  // )
  getUsersForSchoolEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsersForSchool),
      mergeMap((a: ExtendedAction<SchoolIds>) =>
        this.userApiService.getUsersForSchool(a.payload.id).pipe(
          map((res) => {
            return getUsersForSchoolSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(getUsersForSchoolError(getExtendedAction(e))))
        )
      )
    )
  )
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutAuthAction),
        tap(() => {
          this.sessionStorageService.clearByKey(SessionStorageKeys.userSideNavSettingsKey)
          this.authService.logout()
        })
      ),
    { dispatch: false }
  )
  /** Once we get a user's dto in getUserDtoEffect$, four API calls are made once we know which school id we're using.
   * 1. Get school by id
   * 2. Get users for school
   * 3. Get predefined messages for school
   * 4. Get response groups for school
   * Each time one of these calls returns successfully, we check if all four have returned successfully.
   */
  handleRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          getSchoolByIdSuccess,
          getPredefinedGroupsSuccess,
          getResponseGroupsSuccess,
          getUsersForSchoolSuccess
        ),
        concatLatestFrom((_) => this.store.select(selectDashSubscriptionDependencies)),
        tap(([_, dep]: [any, DashboardPageDependencies | null]) => {
          if (!dep) {
            return
          }
          const { userDto, schoolDto, userDtos, currentOrgStats } = dep
          // if(!OrgStatsModel.dependenciesLoaded(dep)) {}
          if (!userDto) {
            return
          }
          if (!schoolDto) {
            return
          }
          if (!userDtos) {
            return
          }
          // Only active redirects to default page on first load of core app data depenedencies
          if (OrgStatsModel.shouldRouteToDashboard(currentOrgStats)) {
            // console.log('Route to dashboard or saved or default page.')
            this.handleSessionStateForDash()
            this.routeService.routeToSavedOrDefaultPage(userDto, currentOrgStats)
          }
        })
      ),
    { dispatch: false }
  )
  /** Once we succesfully get the users for the school, and the school dto we can invoke the logic, since that's a race condition, handle both actions for global redirect logic. */
  handleSchoolOnboardingRedirects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSchoolByIdSuccess, getUsersForSchoolSuccess),
        concatLatestFrom((_) => this.store.select(selectGlobalRedirectHandleDeps)),
        tap(([action, redirectModelDeps]) => {
          if (!redirectModelDeps) {
            return
          }
          // console.log('redirectModelDeps', redirectModelDeps)
          // Any onboarding page redirect should trigger once the user has completed the first step of the onboarding process, which is validating the school by selecting it and getting it assigned to their account.
          const { userDto } = redirectModelDeps
          if (!userDto) {
            return
          }
          const { schoolDto } = redirectModelDeps
          const { schoolIds } = userDto
          if (!UserDtoHelper.schoolIdsExist(schoolIds)) {
            this.routeService.navigateToSchoolValidation()
            return
          }
          if (!schoolDto) {
            return
          }
          const { currentOrgStats } = redirectModelDeps
          if (!OrgStatsModel.dependenciesLoaded(currentOrgStats)) {
            return
          }
          if (OrgStatsModel.shouldRedirectToMapConfig(currentOrgStats)) {
            this.routeService.navigateToMapConfig()
          } else if (OrgStatsModel.shouldRedirectToUserRoster(currentOrgStats)) {
            this.routeService.navigateToUserRoster()
          }
        })
      ),
    { dispatch: false }
  )
  /**
   * This function is used to persist the user settings from the session storage, and if there is no session storage, then use the default settings from the app config.
   */
  handleSessionStateForDash() {
    let userSettings: IMapUiControlSessionSettings
    let sessionObj: IMapUiControlSessionSettings | undefined | null =
      this.sessionStorageService.getKey(SessionStorageKeys.userSideNavSettingsKey)

    //Conditional assignment on if session has defaults or just use the default settings
    if (!sessionObj) {
      userSettings = this.appConfigService.config.SIDE_NAV_DEFAULTS
    } else {
      userSettings = sessionObj
    }
    this.store.dispatch(setMapUiControlSessionState(getExtendedAction(userSettings)))
  }
}
