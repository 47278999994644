import { getExtendedAction } from '@action/extended-ngrx-action'
import { replaySchoolDtoForMapConfig } from '@action/school-map-config-page.actions'
import Map from '@arcgis/core/Map'
import esriConfig from '@arcgis/core/config'
import MapView from '@arcgis/core/views/MapView'
import Compass from '@arcgis/core/widgets/Compass'
import ZoomViewModel from '@arcgis/core/widgets/Zoom/ZoomViewModel'
import { SchoolDtoProps } from '@model/school/school.model'
import { MapPageType } from '@service/map/map.component.service.model'
import { GlobalState } from '@state/auth.state'
import { SchoolMapConfigPageState } from '@state/page/school-map-config-page.state'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcgisAreaViewModel } from '../area/arcgis-area.view'
import { ArcGisMouseHandler } from '../cursor/arcgis-mouse-click.view'
import { ArcGisMouseMoveHandler } from '../cursor/arcgis-mouse-move.view'
import { ArcGisMouseWheelHandler } from '../cursor/arcgis-mouse-wheel.view'
import { ArcGisSchoolBoundaryGraphic } from '../graphic/school-boundary-graphic.view'
import { ArcGisBaselayerStyles } from '../layers/arcgis-baselayer-styles.view'
import { ArcgisLayersHandler } from '../layers/arcgis-layers-handler.view'
import { ArcGisMapConfigStateHandler } from '../map-config/arcgis-school-map-config-vm-handler.view'
import { ArcGisMapViewHandler } from '../map-view/map-view-handler.view'
import { handleUserTypeVisibility } from '../user-type/user-type-visibility.view'

export namespace ArcGisHandleSchoolDtoInit {
  /** Shared handler for a school dto from selected school id in auth state, sets up map view with layers for either dashboard map or map config map. Meant to be called one time on map page load */
  export const handleAuthSelectedSchoolDtoInit = (
    context: ArcGisMapService,
    authState: GlobalState | null
  ): void => {
    if (!authState) return
    if (context._map) return
    const onMapConfig = context.type === MapPageType.schoolMapConfig
    if (!SchoolMapConfigPageState.getLatLongOrNull(authState)) return
    const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId.id]
    if (!schoolDto) {
      return
    }
    context.schoolDto = schoolDto
    const { latLong, boundary } = schoolDto
    const { lat, lon } = latLong ?? {}
    if (!lat || !lon) return
    esriConfig.apiKey = context._appConfig.config.ARC_GIS_API_KEY
    const layers = ArcgisLayersHandler.getLayersBasedOnType(context)
    context._customBaseLayerOptionVm = ArcGisBaselayerStyles.getBaselayerByMapPageType(
      context.type,
      schoolDto[SchoolDtoProps.schoolConfiguation]?.schoolBaseMapType ?? null
    )
    context._map = new Map({
      basemap: context._customBaseLayerOptionVm.arcGisLayer,
      layers
    })
    context._mapView = ArcGisMapViewHandler.getMapView(context, schoolDto) ?? new MapView()
    //Add the boundary from the school dto if we're on the school dashboard
    if (context.type === MapPageType.schoolDashboard && boundary) {
      ArcGisSchoolBoundaryGraphic.addRingsToBoundaryLayer(context, boundary)
    }
    // TODO Determine if we should augment zoom behavior, if not remove this
    // context._setUpCustomZoomLevel()
    // Bind Event Handlers for Dashboard
    // console.log(`context.type === MapPageType.schoolDashboard`, context.type)
    if (
      context.type === MapPageType.schoolDashboard &&
      Array.isArray(schoolDto.subareas) &&
      schoolDto.subareas.length > 0
    ) {
      context.dashSubscriptions = [
        // TODO Find a way to make this happen on init so there's no race condition between adding areas to the map, and the historic processing below.
        context.schoolAreasWithAllRelatedData$.subscribe((data) =>
          ArcgisAreaViewModel.handleAreaDataChange(context, data)
        ),
        // Toggle layer visibility based on UI
        context.userTypeLayerVisibility$.subscribe((data) =>
          handleUserTypeVisibility(context, data)
        ),
        context.dimmedAlertGraphicsLayerVisibility$.subscribe((data) => {
          context.toggleDimmedAttackAlert(data)
        })
      ]
      context.historicSub = context.selectDashboardPageState$.subscribe(
        context.handleDashPageStateUpdate
      )
    }
    //Configure compass and rotation
    context._compass = new Compass({
      view: context._mapView
    })
    context._zoomVm = new ZoomViewModel({
      view: context._mapView
    })
    // MOVE TO CUSTOM POPUP HANDLER
    if (context.type === MapPageType.schoolDashboard) {
      //Only handle click events for school dashboard and if everything constructing the area layer worked fine
      context._mapView.on('click', (click: __esri.ViewClickEvent) => {
        ArcGisMouseHandler.dashMouseClick(context, click)
      })
      context._mapView.on('pointer-move', (event: __esri.ViewPointerMoveEvent) => {
        ArcGisMouseMoveHandler.handleDashMouseMove(context, event)
      })
      context._mapView.on('mouse-wheel', (action: __esri.ViewMouseWheelEvent) =>
        ArcGisMouseWheelHandler.handleDashboardWheelChange(context, action)
      )
      context._mapView.watch('zoom', context._handlZoomChange)
      context._mapView.on('key-down', (event) => handleIgnoredKeyboardKeys(context, event))
      context._handlZoomChange(context._mapView.zoom)
      context.mapConfigSubscriptions = [
        context.proximitySliderValue$.subscribe((value) =>
          ArcGisMapConfigStateHandler.handleNewProximitySliderValue(context, value)
        )
      ]
      // context._mapView.when((ready: any) => {
      //   //Use context to put base map symbol layer in the back
      //   if (context._map?.basemap?.baseLayers) {
      //     const refToSymbolLayer = context._map.basemap.baseLayers.find((l) => !!l)
      //     context._map?.allLayers.reorder(refToSymbolLayer, 0)
      //   }
      // })
    } else if (onMapConfig) {
      context._mapView.on('click', (click: __esri.ViewClickEvent) => {
        ArcGisMouseHandler.mapConfigMouseClick(context, click)
      })
      context._mapView.on('drag', (click: __esri.ViewDragEvent) => {
        ArcGisMouseMoveHandler.handleImmediateClickToSkipRotation(click)
      })
      context._mapView.on('pointer-move', (event: __esri.ViewPointerMoveEvent) => {
        ArcGisMouseMoveHandler.handleMapConfigMouseMove(context, event)
      })
      context._mapView.on('mouse-wheel', (event: __esri.ViewMouseWheelEvent) => {
        ArcGisMouseWheelHandler.handleMapConfigWheeelChange(context, event)
      })
      context._mapView.watch('zoom', (event: __esri.ViewMouseWheelEvent) =>
        ArcGisMouseWheelHandler.handleMapConfigWheeelChange(context, event)
      )
      // This can be enabled when the popup can be moved along with the map, until then it's disabled
      context._mapView.on('key-down', (event) => handleIgnoredKeyboardKeys(context, event))
      // Since we may navigate to this map config page from the dashboard, we'll need to update the module state slice with the dto in order to know the active step
      if (context.activeConfigStep === null) {
        context.store.dispatch(replaySchoolDtoForMapConfig(getExtendedAction(schoolDto)))
      }
    }
  }
  /** TODO We need to implement a popup updater on arrow keys as right now the popup will stay in the same place. */
  export const handleIgnoredKeyboardKeys = (
    context: ArcGisMapService,
    event: __esri.ViewKeyDownEvent
  ) => {
    //Prevent map rotation through A and D keys, prevent panning the map with arrow keys until popup handling has been implemented
    const arrowKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'a', 'd']
    if (arrowKeys.includes(event.key)) {
      // Prevent the default behavior of arrow keys
      event.stopPropagation()
    }
  }
}
