export enum MapConfigToolbarOptions {
  pointer = 'pointer',
  // When we prioritize this feature it'll be to select multiple areas, with editing and moving disabled with only the option to click delete in the custom tool section with a multi area deletion confirmation modal.
  // select = 'select',
  polygon = 'polygon',
  rectangle = 'rectangle',
  circle = 'circle',
  undo = 'undo',
  redo = 'redo',
  duplicate = 'duplicate',
  trash = 'trash'
}
export enum SketchViewModelActiveTool {
  move = 'move',
  reshape = 'reshape',
  circle = 'circle',
  point = 'point',
  multipoint = 'multipoint',
  polyline = 'polyline',
  polygon = 'polygon',
  rectangle = 'rectangle',
  transform = 'transform'
}
export type MapConfigSketchVmToolbarAllowedActiveTool =
  | SketchViewModelActiveTool.rectangle
  | SketchViewModelActiveTool.circle
  | SketchViewModelActiveTool.polygon

/**@deprecated not ideal to use sketch view model for this - best to explicitly set in handler Useful for logic on async events regarding arc gis sketch view model. */
export const sketchViewModelActiveToolAllowed = (
  activeTool:
    | 'move'
    | 'reshape'
    | 'circle'
    | 'point'
    | 'multipoint'
    | 'polyline'
    | 'polygon'
    | 'rectangle'
    | 'transform'
): activeTool is MapConfigSketchVmToolbarAllowedActiveTool => {
  switch (activeTool) {
    case MapConfigToolbarOptions.rectangle:
    case MapConfigToolbarOptions.circle:
    case MapConfigToolbarOptions.polygon:
      return true
    default:
      return false
  }
}
export const getTopSectionEnumValues = () => {
  return Object.values(MapConfigToolbarOptions).filter((o) => !bottomSectionToolBar.includes(o))
}
export const bottomSectionToolBar = [
  MapConfigToolbarOptions.trash,
  MapConfigToolbarOptions.duplicate
]

export interface IToolbarOptionsViewModel {
  option: MapConfigToolbarOptions
  cb: Function
  enabled: boolean
  visible: boolean
  imageSrc: string
}
export type ToolbarOptionsViewModel = Record<MapConfigToolbarOptions, IToolbarOptionsViewModel>
