import { clearStaleDashData, updateThreatModelEventLoop } from '@action/dashboard-page.actions'
import { setPressedPollId } from '@action/dashboard-page/chat-ui.actions'
import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import {
  handleHomeClick,
  handleZoomInClick,
  handleZoomOutClick,
  setFileUploadData,
  setMapApi,
  setMapMode,
  setMapPageUploadImageAspectRatio,
  setPolygonTypeEnum,
  toggleShowMap
} from '@action/school-map-config-page.actions'
import { patchSchoolStateSuccess } from '@action/school/school-api.action'
import { getSchoolByIdSuccess } from '@action/school/school-validation-api.action'
import {
  receiveSentSignalrChatMessage,
  receiveSignalrChatResponse,
  receiveSignalrSchoolOwlStateUpdate,
  receiveSignalrUserLocation,
  receiveSignalrUserPresenceChange,
  sendSignalrChatMessage
} from '@action/signalr.action'
import {
  clearSelectedMapId,
  clickPulsingAlert,
  setSelectedArea,
  setSelectedUserMobileId,
  toggleMapUiControl
} from '@action/user/dashboard-page.action'
import { Injectable } from '@angular/core'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { SchoolOwlStateHelper } from '@domain/dto-helpers/school-owl-state.helper'
import { GetResponseDto } from '@model/message/predefined-message.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import {
  selectDashboardPageState,
  selectDashboardPageStateWithSchoolState,
  selectDashboardViewModel,
  selectMapVisibleByUserClick
} from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import {
  selectCurrentDimmedAttackAlertState,
  selectMapStateTransitionDeps
} from '@selector/page/dashboard-page/dashboard-view.selectors'
import { LocalStorageKeys } from '@service/browser/base-storage.service'
import { LocalStorageService } from '@service/browser/local-storage.service'
import { UploadFileService } from '@service/browser/file/upload-file.service'
import { RouterService } from '@service/core/router.service'
import { MapComponentService } from '@service/map/map.component.service'
import { isLocalHost } from '@shared/js-window'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { FileViewModel } from '@view/browser/file.view'
import { MapUiControlEnum } from '@view/pages/dashboard-page/dashboard-page.view'
import { tap } from 'rxjs'

@Injectable()
export class MapPageEffects {
  // TODO set up inactivity timer - for now disable this feature
  enableAutoCollapseSidenav = false
  lastSchoolState: SchoolStateEnum | null = null
  constructor(
    private actions$: Actions,
    private compServ: MapComponentService,
    private _uploadFileService: UploadFileService,
    private store: Store,
    private mapComponentService: MapComponentService,
    private localStorageService: LocalStorageService,
    private routeService: RouterService
  ) { }
  getSchoolStateDtoEffect$ = createEffect(() =>
    () => this.actions$.pipe(
      ofType(receiveSignalrSchoolOwlStateUpdate),
      tap((action) => {
        const shouldRedirect = SchoolOwlStateHelper.shouldRedirect(this.lastSchoolState, action.payload.state)
        if (shouldRedirect) {
          this.routeService.navigateToDashboard()
        } else if (isLocalHost()) {
          console.info('School state change received no redirect needed')
        }
      })
    ),
    { dispatch: false }
  )
  updateThreatModelEventLoop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateThreatModelEventLoop),
        concatLatestFrom(() => this.store.select(selectDashboardPageState)),
        tap(([_, s]) => {
          this.mapComponentService.updateThreatModelEventLoop(s.responseIdToGetResponseDto)
        })
      ),
    { dispatch: false }
  )
  handleZoomIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleZoomInClick),
        tap((_) => {
          this.mapComponentService.handleZoomInClick()
        })
      ),
    { dispatch: false }
  )
  handleZoomOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleZoomOutClick),
        tap((_) => {
          this.mapComponentService.handleZoomOutClick()
        })
      ),
    { dispatch: false }
  )
  handleHomeClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleHomeClick),
        tap((_) => {
          this.mapComponentService.handleHomeClick()
        })
      ),
    { dispatch: false }
  )
  setSelectedUserMobileId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSelectedUserMobileId),
        tap((_) => {
          this.updateUserLocationOpacity(null)
        })
      ),
    { dispatch: false }
  )
  /** We can only remove opacity to user locations in emergency mode */
  clearSelectedMapId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearSelectedMapId),
        tap((_) => {
          this.updateUserLocationOpacity(null)
        })
      ),
    { dispatch: false }
  )
  /**  */
  setSelectedArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSelectedArea),
        tap((action) => {
          this.updateUserLocationOpacity(action.payload.id)
        })
      ),
    { dispatch: false }
  )
  /**
   * @param id @value number => is for when we can apply opacity to user locations in emergency or routine mode only,
   * @param id @value null => we can only remove opacity to user locations in emergency or suspected mode
   * */
  updateUserLocationOpacity = (id: number | null) => {
    if (
      this.lastSchoolState === SchoolStateEnum.suspected ||
      this.lastSchoolState === SchoolStateEnum.emergency
    ) {
      this.mapComponentService.handleSelectArea(id)
    }
  }
  /** We can only apply opacity to user locations in emergency mode */
  toggleMapUiControl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleMapUiControl),
        concatLatestFrom(() => this.store.select(selectDashboardViewModel)),
        tap(([action, dashVm]) => {
          if (action.payload === MapUiControlEnum.showSubAreas) {
            this.mapComponentService.handleSelectArea(
              dashVm.sideNavVisibilitySettings.showSubAreas ? dashVm.selectedAreaId : null
            )
          }
        })
      ),
    { dispatch: false }
  )
  clickPulsingAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clickPulsingAlert),
        tap((action) => {
          if (action.payload.chatResponseDtoPayload.logicalId) {
            this.localStorageService.addToIdStringValue(
              LocalStorageKeys.pulsingAlerts,
              `${action.payload.chatResponseDtoPayload.logicalId}`
            )
          }
        })
      ),
    { dispatch: false }
  )
  schoolByIdSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getSchoolByIdSuccess),
        tap((action) => {
          this.lastSchoolState = action.payload.schoolOwlState?.state ?? null
        })
      ),
    { dispatch: false }
  )
  filterByStaleDataDate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearStaleDashData),
        concatLatestFrom(() => this.store.select(selectDashboardPageState)),
        tap(([action, state]) => {
          // console.log(`Stale data dash board loaded check`)
          if (DashboardPageState.returnStateIfAllApiStateLoaded(state)) {
            this.mapComponentService.handleStaleData(action.payload, state)
          }
        })
      ),
    { dispatch: false }
  )
  toggleDimmedAttackLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleMapUiControl),
        concatLatestFrom(() => this.store.select(selectCurrentDimmedAttackAlertState)),
        tap(([action, currentToggleState]) => {
          if (action.payload === MapUiControlEnum.showOldAttackAlerts) {
            this.mapComponentService.toggleDimmedAttackAlerts(currentToggleState)
          }
        })
      ),
    { dispatch: false }
  )
  filterByConcludedEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patchSchoolStateSuccess, receiveSignalrSchoolOwlStateUpdate),
        concatLatestFrom(() => this.store.select(selectMapStateTransitionDeps)),
        tap(([action, { mapVisibility, selectedAreaId }]) => {
          // TODO Discuss how tracking last state in local ref not an ideal pattern and
          // ideally we track it in the school state object so it can be used in auto mapper logic
          if (action.payload.state === SchoolStateEnum.routine) {
            // this.mapComponentService.handleConcludeEvent()
            if (selectedAreaId) {
              this.updateUserLocationOpacity(null)
            }
          } else if (
            selectedAreaId &&
            this.lastSchoolState === SchoolStateEnum.routine &&
            (action.payload.state === SchoolStateEnum.emergency ||
              action.payload.state === SchoolStateEnum.suspected)
          ) {
            this.mapComponentService.handleSelectArea(selectedAreaId)
          }
          if (
            action.payload.state === SchoolStateEnum.routine &&
            this.lastSchoolState !== SchoolStateEnum.routine &&
            mapVisibility
          ) {
            this.store.dispatch(toggleShowMap())
          }
          this.lastSchoolState = action.payload.state
        })
      ),
    { dispatch: false }
  )
  realTimeChatMessageSent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveSentSignalrChatMessage, sendSignalrChatMessage),
        concatLatestFrom(() => this.store.select(selectDashboardPageState)),
        tap(([action, state]) => {
          if (ChatMessageDtoHelper.isChatMessageToMobileUserIds(action.payload)) {
            const { payload } = action.payload
            this.mapComponentService.handleSertResponse(state, payload)
          }
        })
      ),
    { dispatch: false }
  )
  // Can't handle here because there would be a race condition between the map and the chat messages
  // getChatMessagesSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(getChatMessagesSuccess),
  //       concatLatestFrom(() => this.store.select(selectAllLoadedDashboardPageState)),
  //       // We don't use the action because the messages are grouped by user and by type in state.
  //       tap(([_, state]) => {
  //         if(state){
  //           this.mapComponentService.handleUserMobileToResponseGeoQuery(state)
  //         }
  //       })
  //     ),
  //   { dispatch: false }
  // )
  realTimeChatMessageReceived$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveSignalrChatResponse),
        concatLatestFrom(() => this.store.select(selectDashboardPageStateWithSchoolState)),
        tap(([action, { dashPageState: state, schoolOwlState }]) => {
          const { payload: dto } = action
          // console.log('realTimeChatMessageReceived$', dto)
          if (ChatMessageDtoHelper.isChatResponseDtoPayloadWithAtLeastOneRequired(dto)) {
            this.mapComponentService.handleRealTimeLocationUpdate(
              dto.payload.mobileUserId,
              state,
              schoolOwlState
            )
            this.mapComponentService.handleRealTimeChatResponse(dto, state, schoolOwlState)
            //Additionally anytime we get a chat response we need to update area statuses if it has a response id that is marked for area status calculation
            if (dto.payload.responseId) {
              // console.log(dto.payload.responseId)
              const getResponseDto: GetResponseDto | null =
                state.responseIdToGetResponseDto[dto.payload.responseId] ?? null
              const isSos = dto.payload.isSos
              if (
                SchoolAreaModelHelper.dtoIsValidForUpdatingAreaStatusVm(getResponseDto) ||
                isSos
              ) {
                this.mapComponentService._handleRealTimeResponseGeospatialQuery(dto.payload)
              }
            }
          }
        })
      ),
    { dispatch: false }
  )
  realTimeLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveSignalrUserLocation),
        concatLatestFrom(() => this.store.select(selectDashboardPageStateWithSchoolState)),
        tap(([action, { dashPageState: state, schoolOwlState }]) => {
          this.mapComponentService.handleRealTimeLocationUpdate(
            action.payload.mobileUserId,
            state,
            schoolOwlState
          )
        })
      ),
    { dispatch: false }
  )
  removeLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveSignalrUserPresenceChange),
        concatLatestFrom(() => this.store.select(selectDashboardPageState)),
        tap(([action, state]) => {
          this.mapComponentService.handlePresenceChange(action.payload.mobileUserId, state)
        })
      ),
    { dispatch: false }
  )
  showMapEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleShowMap),
        concatLatestFrom(() => this.store.select(selectMapVisibleByUserClick)),
        tap(
          ([_, visbility]) =>
            this.enableAutoCollapseSidenav && this.compServ.handleShowMapToggle(visbility)
        )
      ),
    { dispatch: false }
  )
  setMapApi$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setMapApi),
        tap((a) => this.compServ.handleMapApiChange(a.payload))
      ),
    { dispatch: false }
  )
  setMapModeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setMapMode),
        tap((a) => this.compServ.handleMapModeChange(a.payload))
      ),
    { dispatch: false }
  )
  setPolygonTypeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPolygonTypeEnum),
        tap((a) => this.compServ.handlePolygonTypeChange())
      ),
    { dispatch: false }
  )
  setAspectRatioMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setFileUploadData),
        tap((a) => this.handleFileUploadedLogic(a))
      ),
    { dispatch: false }
  )
  setPressedPollId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPressedPollId),
        concatLatestFrom(() => this.store.select(selectDashboardPageStateWithSchoolState)),
        tap(([action, { dashPageState: state, schoolOwlState }]) => {
          this.mapComponentService.setPressedPollId(action.payload, state, schoolOwlState)
        })
      ),
    { dispatch: false }
  )

  /** Here to calculate and save the image properties for map usage */
  handleFileUploadedLogic = async (a: ExtendedAction<FileViewModel>) => {
    if (a.payload.href) {
      const imageProps = await this._uploadFileService.getAspectRatioOfUploadedFile(a.payload.href)
      this.store.dispatch(setMapPageUploadImageAspectRatio(getExtendedAction(imageProps)))
    } else {
      console.warn('No file to get aspect ratio of')
    }
  }
}
