import { clearStaleDashData, disableCheckStatus } from "@action/dashboard-page.actions"
import { getExtendedAction } from "@action/extended-ngrx-action"
import { pingServer, toggleProfile } from "@action/global-app.actions"
import { sendCheckStatus, showOwlSchoolStateChangeModal, showOwlSchoolStateConcludeEventModal, toggleSelectEventUi } from "@action/user/dashboard-page.action"
import { Injectable } from "@angular/core"
import { MatDrawerMode } from "@angular/material/sidenav"
import { Placement } from "@component/shared/custom-ui-label/custom-ui-label.component"
import { SchoolOwlStateHelper } from "@domain/dto-helpers/school-owl-state.helper"
import { mockUnknownLogicalId } from "@mock/school.mock"
import { SchoolStateTransitionEnum } from "@model/emergency.model"
import { ChatMessageDto, ChatMessageDtoPayload } from "@model/message.model"
import { PollType } from "@model/poll.model"
import { schoolAttackerTypeDisplayText, SchoolOwlState, schoolResponeTypeDisplayText, schoolStateDisplayText, SchoolStateEnum } from "@model/school/school-configuation.model"
import { SignalrMessageType } from "@model/signalr/signalr.model"
import { Store } from "@ngrx/store"
import { selectClientSideAdditionOfAtLeastOneUser, selectEnabledSiteLinks, selectOwlSchoolStateProperty, selectProfileData, selectSchoolOwlState, selectUsersForSchoolLookup } from "@selector/auth.selector"
import { selectAreaIdToAreaLogicalIdLookup, selectCurrentSchoolChatMessages, selectLinksAreVisibleOnDashboard, selectShowSelectEventUi, selectSideNavIsCollapsed } from "@selector/page/dashboard-page/dashboard-page-state.selectors"
import { dashboardSchoolStateLobelIsVisible, selectDisableCheckStatus, selectShowMainCtaButtonTooltip } from "@selector/page/dashboard-page/dashboard-view.selectors"
import { selectPageIsDashboard } from "@selector/route.selector"
import { DISABLE_BUTTON_TIMEOUT } from "@shared/constants"
import { SchoolStateTriggers } from "@view/rive/owl-animation.view"
import { combineLatest, debounceTime, map, Observable, Subscription, tap } from "rxjs"
import { AppConfigService } from "./app-config/app-config.service"
import { IAppComponentService } from "./app.component.service.model"
import { OwlRiveService } from "./rive/rive.service"
import { FirstLevelDir, OwlRoutes } from "@domain/route/app-routes.domain"
import { ISiteLinkViewModel, SideNavLinkIcons } from "@view/pages/dashboard-page/dashboard-page.view"
import { ButtonIconPlacement } from "@component/shared/button/button.component.view"
import { ExpandedLinkVariant } from "@component/header/dashboard-sidenav/expanded-link/expanded-link.component.model"


@Injectable()
export class AppComponentService implements IAppComponentService {
  //Rive Animation Canvas Size
  collapsedHeightAppTitleBuffer = 16

  //Size Nav
  closeSideNavDisabled = true
  sideNavOpened = true
  showFiller = false
  sideNavMode: MatDrawerMode = 'side'

  schoolOwlState: SchoolOwlState | null = null
  schoolState: SchoolStateEnum | null = null
  pageIsDash$ = this.store.select(selectPageIsDashboard)
  /** TODO Decide if any aspect of school state like attacker type and response type is represented in the confirmation modal */
  schoolStateOwl$ = this.store.select(selectSchoolOwlState)
  schoolStateIsInSuspectedOrEmergency$ = this.store
    .select(selectSchoolOwlState)
    .pipe(
      map((s) => s?.state === SchoolStateEnum.suspected || s?.state === SchoolStateEnum.emergency)
    )
  schoolStateIsSuspected$ = this.store
    .select(selectSchoolOwlState)
    .pipe(map((s) => s?.state === SchoolStateEnum.suspected))
  schoolStateIsEmergency$ = this.store
    .select(selectSchoolOwlState)
    .pipe(map((s) => s?.state === SchoolStateEnum.emergency))
  schoolState$ = this.store.select(selectOwlSchoolStateProperty)
  // TODO Set up function to display text per combination of school state and response/attack type
  schoolStateText$ = this.schoolStateOwl$.pipe(
    map((s) => {
      return s?.attackerType
        ? schoolStateDisplayText[s?.state].concat(schoolAttackerTypeDisplayText[s?.attackerType])
        : s?.responseType
          ? schoolResponeTypeDisplayText[s?.responseType]
          : s?.state === SchoolStateEnum.suspected
            ? 'Suspicious Activity Reported'
            : ''
    })
  )
  /** The side nav should be in collapsed mode during either emergencies or during suspected emergencies. Or if the user temporarily collapsed it by clicking view map. */
  showSelectEventUi$ = this.store.select(selectShowSelectEventUi)
  /** This selector uses a delay to prevent the select event ui from showing and hiding too quickly.*/
  throttleShowSelectEventUi$ = this.store.select(selectShowSelectEventUi).pipe(debounceTime(40))
  sideNavIsCollapsed$ = this.store.select(selectSideNavIsCollapsed)
  showCtaButtonTooltip$ = this.store.select(selectShowMainCtaButtonTooltip)
  schoolStateLabelVisible$ = this.store.select(dashboardSchoolStateLobelIsVisible)
  linksAreVisible$ = this.store.select(selectLinksAreVisibleOnDashboard)
  usersForSchoolLookup$ = this.store.select(selectUsersForSchoolLookup)
  selectProfileData$ = this.store.select(selectProfileData)

  // TODO Rework how this gets trigger to avoid having to change state on navigation
  selectClientSideAdditionOfAtLeastOneUser$ = this.store.select(
    selectClientSideAdditionOfAtLeastOneUser
  )

  enabledSiteNavLinks$ = this.store.select(selectEnabledSiteLinks)

  isLinkEnabled = (link: string): Observable<boolean> => {
    return this.enabledSiteNavLinks$.pipe(
      map((links) => !!links[link])
    )
  }
  toggleProfile = () => {
    this.store.dispatch(toggleProfile())
  }

  getDisabledAreaCheckStatus$ = (areaId?: number) => {
    return combineLatest([
      this.store.select(selectAreaIdToAreaLogicalIdLookup),
      this.store.select(selectDisableCheckStatus),
      this.store.select(selectCurrentSchoolChatMessages)
    ]).pipe(
      map(([areaIdToAreaLogicalIdLookup, disabledAreaStatuses, dtos]) => {
        const areaLogicalId = areaIdToAreaLogicalIdLookup[areaId || 0] ?? mockUnknownLogicalId
        const timeDifferenceMs = this.getLastCheckStatusTimeDifference(dtos, areaLogicalId)
        const disableButtonIntervalMs = DISABLE_BUTTON_TIMEOUT
        const disabled =
          disabledAreaStatuses[areaId || 0] || timeDifferenceMs < disableButtonIntervalMs
        if (disabled && !disabledAreaStatuses[areaId || 0]) {
          this.store.dispatch(disableCheckStatus(getExtendedAction({ id: areaId, disable: true })))
          setTimeout(() => {
            this.store.dispatch(
              disableCheckStatus(getExtendedAction({ id: areaId, disable: false }))
            )
          }, disableButtonIntervalMs - timeDifferenceMs)
        }
        if (!disabled && disabledAreaStatuses[areaId || 0]) {
          this.store.dispatch(disableCheckStatus(getExtendedAction({ id: areaId, disable: false })))
        }
        return disabled
      })
    )
  }
  //TODO issue with  Cannot find namespace 'NodeJS'. when using : NodeJS.Timer
  staleDashDataInterval: any

  setClearStaleDataInterval = () => {
    // console.log(`Setting stale data clearing interval`)
    this.staleDashDataInterval = setInterval(() => {
      this.store.dispatch(pingServer())
      // Only dispatch clear stale data while we're in routine mode
      if (this.schoolState === SchoolStateEnum.routine) {
        this.store.dispatch(
          clearStaleDashData(getExtendedAction(this.getSlidingDateQueryBasedOnNow()))
        )
      }
      // We check for stale data every minute, and we can ping the server to keep a fresh token on hand every 10 min
    }, this.appConfig.config.CLEAR_STALE_DASH_DATA_INTERVAL_SECONDS * 1000 * 10)
  }
  removeClearStaleDataInterval = () => {
    // console.log(`Clearing stale data clearing interval`)
    clearInterval(this.staleDashDataInterval)
  }
  /** This logic is used for fetching data as well as clearing it so add it as a cross cutting app wide concern for other usages */
  getDateQueryForDashData = (): Date => {
    // console.log(`this.schoolOwlState`)
    // console.log(this.schoolOwlState)
    // Until we have the historic date extension field null out on state change, only use it when we're in non routine state
    return SchoolOwlStateHelper.getDateQueryWithOverrideLogic(
      this.appConfig.getDateForQueryFromConfig(),
      this.schoolOwlState?.state === SchoolStateEnum.routine
        ? undefined
        : this.schoolOwlState?.historicDateExtensionOverride,
      this.appConfig.config.HISTORIC_DATA_LATENCY_BUFFER_SECONDS
    )
  }
  /** Handles historic time window clear stale data on interval and time back set in app config */
  getSlidingDateQueryBasedOnNow = (): Date => {
    const nowDate = new Date()
    const nowEpoch = nowDate.getTime()
    const historicDataTimeDate = this.getDateQueryForDashData()
    const historicDataTimeEpoch = historicDataTimeDate.getTime()
    const timePassedSinceQuery = nowEpoch - historicDataTimeEpoch
    // console.log(`Seconds passed since data query time ${timePassedSinceQuery / 1000}`)
    const startWindowAllowedOpenWindow =
      this.appConfig.config.HISTORIC_CONTENT_WINDOW_MINUTES * 60000
    const newStartWindowDateForAllowedData = new Date()
    const slidingHistoricTimeWindowStarted = timePassedSinceQuery > startWindowAllowedOpenWindow
    // console.log(`Sliding time back window started ${slidingHistoricTimeWindowStarted}`)
    const timeBackAllowed = slidingHistoricTimeWindowStarted
      ? startWindowAllowedOpenWindow
      : timePassedSinceQuery
    // console.log(`Time back data query window age ${timeBackAllowed / 1000}`)
    const newTimeAllowedDataDate = nowEpoch - timeBackAllowed
    newStartWindowDateForAllowedData.setTime(newTimeAllowedDataDate)
    return newStartWindowDateForAllowedData
  }

  marginLeftValue$ = this.sideNavIsCollapsed$.pipe(
    map((collapsed) => (collapsed ? '81px' : '200px'))
  )
  subs: Subscription[] = []
  riveSubscription?: Subscription
  riveStateSubscription?: Subscription

  rightLabelPlacement = Placement.right

  constructor(
    public appConfig: AppConfigService,
    private store: Store,
    private owlRiveService: OwlRiveService
  ) {
    this.subs = [
      this.pageIsDash$.subscribe(this.handlePageIsDash),
      this.schoolStateOwl$
        .pipe(
          tap((s) => {
            if (s) {
              // TODO move to logger after integrating application insights service
              // console.log(`Setting school state`)
              this.schoolOwlState = s
              if (s?.state) {
                // console.log(`Setting school owl state`)
                this.schoolState = s?.state
              } else {
                // console.warn(`School state returned without state field`)
              }
            } else {
              // console.log(`NO SCHOOL STATE YET`)
            }
          })
        )
        .subscribe()
    ]
  }
  /**  We need to apply and remove rive and clear stale data interval when we nav away from dash and reset when nav to. */
  handlePageIsDash = (pageIsDashboard: boolean) => {
    if (pageIsDashboard) {
      this.setClearStaleDataInterval()
    } else {
      this.destroyRive()
      this.removeClearStaleDataInterval()
    }
  }
  /** Space above rive animation is dependent on canvas height and a buffer height, if rive file changed, values referenced here will need to be updated, for example, OWL made larger. */
  getStyleForAppTitle = () => {
    return {
      'margin-top': `${this.owlRiveService.canvasHeight + this.collapsedHeightAppTitleBuffer}px`
    }
  }

  //STATE ANMIATION
  destroy = () => {
    this.subs.forEach((s) => (s ? s.unsubscribe : null))
  }
  /** Handle the main call to action button click based on the current school state, and display the related UI, routine shows select attack/response type, suspected shows related modal, and so does emergency. */
  appMainCtaButtonHandler = () => {
    switch (this.schoolState) {
      case SchoolStateEnum.routine:
        this.store.dispatch(toggleSelectEventUi())
        break
      case SchoolStateEnum.suspected:
        this.store.dispatch(
          showOwlSchoolStateChangeModal(
            getExtendedAction(SchoolStateTransitionEnum.dismissSuspected)
          )
        )
        break
      case SchoolStateEnum.emergency:
        this.store.dispatch(showOwlSchoolStateConcludeEventModal())
        break
    }
  }
  toggleSelectEventUi = () => {
    this.store.dispatch(toggleSelectEventUi())
  }
  sendCheckStatus = () => {
    this.store.dispatch(sendCheckStatus(getExtendedAction(0)))
  }
  initRive = () => {
    // console.log(`Setting up rive owl school state animation`)
    this.riveSubscription = this.owlRiveService.riveReadySubject$.subscribe((ready) => {
      if (ready && !this.riveStateSubscription) {
        this.riveStateSubscription = this.schoolStateOwl$.subscribe((s) => {
          this._transitionSchoolState(s)
        })
      }
    })
  }
  destroyRive = () => {
    if (this.riveSubscription) {
      // console.log(`Removing up rive owl school state animation`)
      this.riveSubscription.unsubscribe()
      this.riveSubscription = undefined
    }
    if (this.riveStateSubscription) {
      // console.log(`Removing up rive owl school state animation`)
      this.riveStateSubscription.unsubscribe()
      this.riveStateSubscription = undefined
    }
  }

  /** React handler for real time updates of school owl state */
  private _transitionSchoolState = (s: SchoolOwlState | null) => {
    if (!this.owlRiveService.rive || !s) {
      // console.log(`OWl rive service doesn't have rive instans or we don't have state`)
      return
    }
    // console.log(`_transitionSchoolState called with ${s.state}`)
    switch (s.state) {
      case SchoolStateEnum.routine:
        this.owlRiveService.trigger(SchoolStateTriggers.Normal)
        break
      case SchoolStateEnum.suspected:
        this.owlRiveService.trigger(SchoolStateTriggers.Suspected)
        break
      case SchoolStateEnum.emergency:
        this.owlRiveService.trigger(SchoolStateTriggers.Emergency)
        break
    }
  }

  private getLastCheckStatusTimeDifference = (dtos: ChatMessageDto[], areaLogicalId: string) => {
    let checkStatuseDates = dtos
      .filter((dto) => {
        if (dto.type === SignalrMessageType.chatMessage) {
          const typedDto = dto.payload as ChatMessageDtoPayload
          return typedDto.pollType === PollType.status && typedDto.chatRoomId == areaLogicalId
        } else {
          return false
        }
      })
      .map((item) => new Date((item.payload as ChatMessageDtoPayload)?.timestamp).getTime())
    const dateNow = new Date().getTime()
    const maxDate = new Date(Math.max(...checkStatuseDates)).getTime() || 0
    return dateNow - maxDate
  }
  schoolSettingsActionBtn: ISiteLinkViewModel[] = [
    {
      link: OwlRoutes.firstLevelRoutes.dashboard,
      text: 'Go to Dashboard',
      isLink: true,
      icon: SideNavLinkIcons.navToDash,
      iconPlacement: ButtonIconPlacement.left,
      variant: ExpandedLinkVariant.action,
      isEnabled$: this.isLinkEnabled(OwlRoutes.firstLevelRoutes.dashboard)
    }
  ]
  schoolSettingsLinks: ISiteLinkViewModel[] = [
    {
      link: OwlRoutes.firstLevelRoutes.setupSchoolAreasMap,
      text: 'School Map',
      isLink: true,
      icon: SideNavLinkIcons.schoolMap,
      variant: ExpandedLinkVariant.headerNav,
      iconPlacement: ButtonIconPlacement.left,
      isEnabled$: this.isLinkEnabled(OwlRoutes.firstLevelRoutes.setupSchoolAreasMap)
    },
    {
      link: OwlRoutes.firstLevelRoutes.roster,
      text: 'Users',
      isLink: true,
      icon: SideNavLinkIcons.users,
      iconPlacement: ButtonIconPlacement.left,
      variant: ExpandedLinkVariant.headerNav,
      isEnabled$: this.isLinkEnabled(OwlRoutes.firstLevelRoutes.roster)
    },
    {
      link: OwlRoutes.firstLevelRoutes.instructions,
      text: 'Communications',
      isLink: true,
      icon: SideNavLinkIcons.communication,
      iconPlacement: ButtonIconPlacement.left,
      variant: ExpandedLinkVariant.headerNav,
      isEnabled$: this.isLinkEnabled(OwlRoutes.firstLevelRoutes.instructions)
    }
  ]
}
