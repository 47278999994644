import { ChangeDetectorRef, ElementRef } from '@angular/core'
import Graphic from '@arcgis/core/Graphic'
import { GraphicAttributes } from '@view/area/graphic-attributes.model'
import { IMapConfigAreaViewModel } from '@view/pages/school-map-config-page/school-map-config.view'
import { MapPopupPositionEnum } from './map-popup/map-popup.component.model'

export abstract class BaseMapPopup {
  abstract isShownDueToClick: boolean
  /** Shared set content between different types of popups. Map config needs ref to school id being edited hence it's undefined by default */
  abstract setContent(
    attributes: GraphicAttributes,
    mapConfigAreaVm: IMapConfigAreaViewModel | null
  ): void
  /** Used to clear content for the popup on popup close */
  abstract reset(): void
  abstract setGraphic(g: Graphic): void
  private _popupVisible: boolean = false
  cd: ChangeDetectorRef
  elementRef: ElementRef
  /** Need to track the related graphic so we can reposition the popup when the user resizes the area */
  graphic: Graphic | null = null
  _position = MapPopupPositionEnum.right
  public get position(): MapPopupPositionEnum {
    return this._position
  }
  get componentWidth(): number {
    return this.elementRef.nativeElement.clientWidth ?? 0
  }
  get componentHeight(): number {
    return this.elementRef.nativeElement.clientHeight ?? 0
  }

  public get popupVisible(): boolean {
    return this._popupVisible
  }
  constructor(cd: ChangeDetectorRef, elementRef: ElementRef) {
    this.cd = cd
    this.elementRef = elementRef
  }
  /** Ensure that dom element is off screen */
  public setDefaultPosition = () => {
    this.elementRef.nativeElement.style.left = '-200px'
    this.elementRef.nativeElement.style.top = '-200px'
  }
  /** Use to reset and hide the popup when a map item is deselected. */
  public hidePopup = () => {
    this._popupVisible = false
    this.reset()
    this.setDefaultPosition()
    this.cd.markForCheck()
  }
  public showPopup = () => {
    this._popupVisible = true
    this.cd.markForCheck()
  }
  public setPopupPosition(value: MapPopupPositionEnum) {
    this._position = value
    this.cd.markForCheck()
  }
  /** Used to explicitly update the map popup for area and location interactions. */
  public setPosition(x: number, y: number, rotation: number) {
    this.elementRef.nativeElement.style.left = `${x}px`
    this.elementRef.nativeElement.style.top = `${y}px`
    this.cd.markForCheck()
  }
}
