export const SIGNALR_CONNECTION_SEND_MESSAGE = 'SendMessage'
export const SIGNALR_CONNECTION_RECEIVE_MESSAGE = 'ReceiveMessage'
/**
 * TODO Research mirroring C# enums, interfaces in TS via local automation script and/or DevOps pipeline.
 * Qulyr.OWL\Qulyr.OWL.BuisnessLogic\Hubs\AlertHub.cs
 */
export enum SignalrMessageType {
  /** This is sent from the browser to the mobile app */
  chatMessage = 'chatMessage',
  /** This is sent from the mobile app to the browser */
  chatResponse = 'chatResponse',
  /** Dedicated object for location updates */
  userLocation = 'userLocation',
  /** To dispatch presence change event */
  presenceChange = 'presenceChange',

  // TEST Doesn't work
  /** Patch partial of SchoolOwlState, we an send or receive this object based on server state. */
  schoolUpdate = 'schoolUpdate',
  schoolOwlStateUpdate = 'schoolOwlStateUpdate',

  /** Fired when an area is updated via PATCH */
  schoolAreaUpdate = 'schoolAreaUpdate',
  /** @deprecated // TODO remove along with base alert dto for balcony trigger api*/
  basicAlert = 'BasicAlert', //Perhaps we want to remove this TODO

  // discuss how this will be used and why we need real time updates - Yanic said we can't get location data with this event, so likely just need to save in db, and use in list of user for user management page
  mobileUserRegistered = 'mobileUserRegistered',
  userStatus = 'userStatus',
  polygonDirectMessage = 'polygonDirectMessage',
  /** We don't want to specify user ids in a collection we just want to send the area id to Balony and have them figure out who should get it. */
  polygonPollMessage = 'polygonPollMessage',
  userPollMessage = 'userPollMessage',

  /** Whether it's from the library, or a rules engine suggested poll or message we need to be able to trigger them from the front end */
  selectedUsersPollMessage = 'selectedUsersPollMessage',
  selectedUsersDirectMessage = 'selectedUsersDirectMessage',
  //School Configriation Update

  /** This is when the school preference is hybrid and all rule engine triggered messages and polls need to be approved by a school admin */
  suggestedMessage = 'suggestedMessage',
  suggestedPoll = 'suggestedPoll',

  processRosterCompleted = 'processRosterCompleted'
}

/** A union of non chat payloads */
export type NonChatPayloads =
  | SignalrMessageType.schoolOwlStateUpdate
  | SignalrMessageType.schoolUpdate
  | SignalrMessageType.mobileUserRegistered
  | SignalrMessageType.userLocation

/** A sub set of signal r types that only includes objects shown in chat */
export type ChatMessagePayloadTypes = Exclude<SignalrMessageType, NonChatPayloads>

export interface SignalrDto<T> {
  type: SignalrMessageType
  payload: T
}
