import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FloatLabelType } from '@angular/material/form-field'
@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {
  /** The only usage of this input should be in template binding to auto apply error classes for the material input component, no other usage should be set up as this is meant as a mainly pure component. */
  @Input() control: FormControl | null = null
  @Input() labelText: string = ''
  @Input() floatLabel: FloatLabelType = 'always' //auto' //'always'
  @Input() placeholderText: string = ''
  @Input() hint: string = ''
  @Input() prefix: string = ''
  @Input() errorMessage: string = ''
  @Input() mask: string | null = null
  @Input() description: string | null = null
  @Input() bigHeight: boolean = false
  /** String of mat-icon supported value. See: https://fonts.google.com/icons, or set up custom mat-icon component, using MatIconRegistry see https://v8.material.angular.io/components/icon/overview*/
  @Input() icon: string = ''
  get showIcon(): boolean {
    return !this.errorMessage && !!this.icon
  }
}
