import { PredefinedMessageType } from '@model/message/predefined-message.model'
import { createSelector } from '@ngrx/store'
import { selectOwlSchoolStateProperty } from '@selector/auth.selector'
import { ChatRoomTypeEnum, ChatUiViewModel } from '@view/chat/chat.view'
import {
  selectAreaLogicalIdToChatRoomNameLookup,
  selectAreaStatusLookup,
  selectChatRoomIdToAttributesLookup,
  selectChatVm,
  selectCurrentSchoolChatMessages,
  selectDashboardViewModel,
  selectGroupedPredefinedMessages,
  selectResponseIdToGetResponseDto,
  selectSentPollToPollResponseLookup,
  selectUserToDisplayNameLookup,
  selectUserToTypeLookup
} from './dashboard-page-state.selectors'

//Selectors for anything within chat vm
export const selectChatUiMenuIsOpen = createSelector(selectChatVm, (vm) => vm.chatMenuOpen)
export const selectShowChatList = createSelector(selectChatVm, (vm) => vm.showChatList)
export const selectActiveChatroomId = createSelector(
  selectChatVm,
  (chatVm) => chatVm.selectedChatRoomId
)
export const selectShowMessageSelection = createSelector(
  selectChatVm,
  (chatVm) => chatVm.showSelectMessage
)
export const selectShowPollSelection = createSelector(
  selectChatVm,
  (chatVm) => chatVm.showSelectPoll
)
export const selectSelectedPredefinedItemId = createSelector(
  selectChatVm,
  (chatVm) => chatVm.selectedPredefinedItemId
)
export const selectCreatePollView = createSelector(selectChatVm, (chatVm) => chatVm.showCreatePoll)
export const selectSelectedPredefinedMessageTypeForSelection = createSelector(
  selectChatVm,
  selectOwlSchoolStateProperty,
  ChatUiViewModel.getSelectedPredefinedMessageTypeForSelection
)
export const selectVisibleChatMessageVms = createSelector(
  selectChatVm,
  selectChatRoomIdToAttributesLookup,
  selectUserToDisplayNameLookup,
  selectAreaLogicalIdToChatRoomNameLookup,
  selectSentPollToPollResponseLookup,
  selectResponseIdToGetResponseDto,
  selectUserToTypeLookup,
  ChatUiViewModel.getChatroomMessages
)
export const selectVisibleChatListsVms = createSelector(
  selectChatVm,
  selectUserToDisplayNameLookup,
  selectAreaLogicalIdToChatRoomNameLookup,
  selectAreaStatusLookup,
  selectSentPollToPollResponseLookup,
  selectResponseIdToGetResponseDto,
  selectUserToTypeLookup,
  ChatUiViewModel.getChatLists
)
export const selectNumberOfChatRoomMessages = createSelector(selectVisibleChatMessageVms, (vms) =>
  Array.isArray(vms) ? vms.length : 0
)
export const selectChatUiIsVisible = createSelector(
  selectDashboardViewModel,
  (vm) => vm.chatUiVisible
)
/** We can only show the main chat ui if none of the menu triggered views are enabled */
export const selectShowChatUi = createSelector(
  selectShowMessageSelection,
  selectShowPollSelection,
  selectCreatePollView,
  selectChatUiIsVisible,
  (showMessageSelection, showPollSelection, showCreatePollView, chatUiIsVisible) =>
    // TODO Uncomment logic after view implemented
    !showMessageSelection &&
    !showPollSelection &&
    // !showCreatePollView &&
    chatUiIsVisible
)
export const selectVisibleChatRoomHasNoMessages = createSelector(
  selectVisibleChatMessageVms,
  (messages) => {
    if (!messages) {
      return true
    } else if (Array.isArray(messages) && messages.length == 0) {
      return true
    } else {
      return false
    }
  }
)
export const selectChatroomButtons = createSelector(
  selectChatRoomIdToAttributesLookup,
  ChatUiViewModel.getChatroomOptions
)
export const selectSelectedChatroomLabel = createSelector(
  selectChatRoomIdToAttributesLookup,
  selectActiveChatroomId,
  ChatUiViewModel.selectedChatroomLabel
)
export const selectCurrentChatRoomButtonVm = createSelector(
  selectChatRoomIdToAttributesLookup,
  selectActiveChatroomId,
  ChatUiViewModel.returnActiveChatAttributes
)
export const selectCurrentChatRoomType = createSelector(
  selectChatRoomIdToAttributesLookup,
  selectActiveChatroomId,
  ChatUiViewModel.returnActiveChatType
)
export const selectIsOneOnOneChatRoom = createSelector(
  selectCurrentChatRoomType,
  (type) => type === ChatRoomTypeEnum.oneOnOne
)
// For auto scrolling behavior of chat room buttons
export const selectChatRoomSelectVm = createSelector(
  selectChatUiIsVisible,
  selectActiveChatroomId,
  selectChatroomButtons,
  ChatUiViewModel.returnChatRoomSelectVm
)

export const selectHistoricChatMessagesForMap = createSelector(
  selectCurrentSchoolChatMessages,
  ChatUiViewModel.getMessagesToShowOnMap
)

export const selectGroupedPredefinedMessagesByCurrentView = createSelector(
  selectGroupedPredefinedMessages,
  selectShowPollSelection,
  selectShowMessageSelection,
  // TODO move to static class member and add three unit tests, one for each branch
  (groupedPredefinedMessages, showPollSelect, showMessageSelect) =>
    groupedPredefinedMessages?.filter((m) => {
      if (showMessageSelect) {
        return m.type === PredefinedMessageType.message
      } else if (showPollSelect) {
        return m.type === PredefinedMessageType.poll
      } else {
        return false
      }
    })
)

export const selectSelectedGroupedPredefinedMessages = createSelector(
  selectGroupedPredefinedMessages,
  selectSelectedPredefinedItemId,
  ChatUiViewModel.getSelectedPredefinedItem
)

export const selectTeachersButton = createSelector(
  selectChatVm,
  (chatVm) => chatVm.isTeachersButtonEnabled
)
export const selectOtherStaffButton = createSelector(
  selectChatVm,
  (chatVm) => chatVm.isOtherStaffButtonEnabled
)
export const selectStudentsButton = createSelector(
  selectChatVm,
  (chatVm) => chatVm.isStudentsButtonEnabled
)
export const selectIsUserTypeSelected = createSelector(
  selectChatVm,
  (chatVm) =>
    chatVm.isTeachersButtonEnabled ||
    chatVm.isOtherStaffButtonEnabled ||
    chatVm.isStudentsButtonEnabled
)
export const selectScrollPlacesInChatRooms = createSelector(
  selectChatVm,
  (chatVm) => chatVm.scrollPlacesInChatRooms
)
export const selectChatRoomScrollData = createSelector(
  selectScrollPlacesInChatRooms,
  selectVisibleChatMessageVms,
  selectActiveChatroomId,
  ChatUiViewModel.getScrollDataForChatRoom
)
export const selectMessageHeights = createSelector(
  selectIsOneOnOneChatRoom,
  selectVisibleChatMessageVms,
  ChatUiViewModel.calculateMessageSizes
)
