import { ElementRef, Injectable } from '@angular/core'
import { Alignment, Fit, Layout, LayoutParameters, Rive } from '@rive-app/canvas'
import { VisibilityService } from '@service/browser/visibility.service'
import {
  OwlSchoolStateStateArtBoard,
  OwlSchoolStateStateMachineName,
  SchoolStateTriggers
} from '@view/rive/owl-animation.view'
import { BehaviorSubject, Subscription } from 'rxjs'
import { IRiveService } from './rive.service.model'

@Injectable()
export class OwlRiveService implements IRiveService {
  public canvasHeight = 50
  public canvasWidth = 50

  rive?: Rive
  sub?: Subscription
  didInit: boolean = false

  riveReadySubject = new BehaviorSubject<boolean>(false)
  riveReadySubject$ = this.riveReadySubject.asObservable()
  isTabVisible: boolean | null = null
  lastTriggerWhenNoVisiblity: SchoolStateTriggers | null = null

  constructor(private visibilityService: VisibilityService) {
    this.sub = this.visibilityService.visibilityChange$.subscribe(this.handleVisibilityChange)
  }
  destroy = () => {
    if (this.sub) {
      this.sub.unsubscribe()
      this.didInit = false
      this.riveReadySubject.next(false)
    }
  }

  /** Here to save last trigger when tab not visible due to limitations in Rive's ability to run triggers when tab out of focus */
  handleVisibilityChange = (isVisible: boolean): void => {
    this.isTabVisible = isVisible
    if (this.lastTriggerWhenNoVisiblity && this.isTabVisible) {
      this.trigger(this.lastTriggerWhenNoVisiblity)
      this.lastTriggerWhenNoVisiblity = null
    }
    // console.log(`Updating tab visibility to ${isVisible}`)
  }
  /** Sign up for school state updates as well as setting up the main app logoc animation transitions. */
  init = (ref: ElementRef<HTMLCanvasElement>) => {
    setTimeout(() => {
      const options: LayoutParameters = {
        fit: Fit.Contain,
        alignment: Alignment.TopLeft,
        minX: 0,
        minY: 0,
        maxX: this.canvasWidth,
        maxY: this.canvasHeight
      }
      this.rive = new Rive({
        src: './assets/rive/owl-school-state-animation.riv',
        canvas: ref.nativeElement,
        artboard: OwlSchoolStateStateArtBoard,
        autoplay: true,
        stateMachines: OwlSchoolStateStateMachineName,
        layout: new Layout(options),
        onLoad: this.handleRiveOnLoad,
        onplay: () => {
          // console.log(`Playing rive`)
        },
        onLoadError: this.handleRiveOnLoadError
      })
    }, 100)
    this.didInit = true
  }
  handleRiveOnLoadError = (e: any) => {
    console.error(e)
  }
  /** Only subscribe to state updates once Rive is fully loaded */
  handleRiveOnLoad = (rive: any) => {
    // console.log('rive')
    // console.log(rive)
    // console.log(`Setting up RIVE async`)
    this.riveReadySubject.next(true)
  }
  /** Trigger state machine by provided trigger name. */
  trigger = (t: SchoolStateTriggers): void => {
    if (!this.rive) {
      return
    }
    //Rive has a limitation in running triggers when tab is not inforeground
    if (this.isTabVisible === false) {
      this.lastTriggerWhenNoVisiblity = t
      return
    }
    const inputs = this.rive.stateMachineInputs(OwlSchoolStateStateMachineName)
    const trigger = inputs?.find((input) => input.name === t)
    if (trigger) {
      // console.log(`Rive trigger found`)
      // console.log(trigger)
      // console.log('inputs.toString()')
      // console.log(inputs.forEach((i) => console.log(i)))
      try {
        //Due to limitations in Rive on running triggers sucessfully on page load, best to put it int he event loop for handling last for better reliability
        setTimeout(() => {
          trigger.fire()
        }, 500)
      } catch (e) {
        console.warn(`INTERNAL RIVE ERROR`)
        console.error(e)
      }
    } else {
      console.error(`Rive trigger NOT FOUND! rive input missing`)
      // console.info(inputs)
      // console.info('this.rive')
      console.info(this.rive)
    }
  }
}
