import { getExtendedAction } from '@action/extended-ngrx-action'
import { selectAreaById } from '@action/school-map-config-page.actions'
import {
  clearSelectedMapId,
  clickPulsingAlert,
  setSelectedArea,
  setSelectedUserMobileId
} from '@action/user/dashboard-page.action'
import { MobileUserTypes } from '@model/user/user.model'
import { AreaGraphicAttributes, AreaViewModel } from '@view/area/area.view'
import { LocationGraphicAttributes } from '@view/area/location.view'
import {
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcgisHitTest } from '../arcgis-hit-test.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisPopupView } from '../arcgis-popup.view'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcgisAreaViewModel } from '../area/arcgis-area.view'
import { ArcGisSketchCustomToolStateHandler } from '../sketch/custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'
import { ArcgisUserGraphicHandler } from '../user-locations/arcgis-user-symbol.view'

export namespace ArcGisMouseHandler {
  /**
   * Determine if update to handle new pattern of navigating anywhere from anywhere and can't rely on map view or complete config step to know to handle map config mouse click - revisit all the logic here and rework */
  export const mapConfigMouseClick = async (
    context: ArcGisMapService,
    click: __esri.ViewClickEvent
  ) => {
    // console.log(`click event`, click)
    if (
      context.activeConfigStep === MapConfigSteps.mapView
      // context.activeConfigStep?.option === MapConfigSteps.completeConfiguration
    ) {
      // console.log(`click event not handled due to being on map view or complete config step`, click)
      return
    }
    const onAddAreasStep = context.activeConfigStep === MapConfigSteps.addAreas
    const onPerimeterStep = context.activeConfigStep === MapConfigSteps.schoolPerimeter
    let hitTestResponse: __esri.HitTestResult | null = null
    if (onAddAreasStep) {
      hitTestResponse = await ArcgisHitTest.getHitForMapConfigAreaLayer(context, click)
    } else if (onPerimeterStep) {
      hitTestResponse = await ArcgisHitTest.getHitForMapConfigPerimeterLayer(context, click)
    }
    // Handle click on invalid area logic first
    let clickedGraphic: __esri.Graphic | null = null
    if ((onAddAreasStep || onPerimeterStep) && hitTestResponse) {
      clickedGraphic = (hitTestResponse.results[0] as __esri.GraphicHit)?.graphic ?? null
    }
    // If map config is invalid ignore clicks unles it's back on an invalid geometry to fix it
    if (
      (onAddAreasStep &&
        MapConfigValidationViewModel.errorIsRelatedToAreaGeom(context.mapConfigValidationError)) ||
      (onPerimeterStep &&
        MapConfigValidationViewModel.errorIsRelatedToBoundaryGeom(context.mapConfigValidationError))
    ) {
      //Reselect the geometry in question if it exists
      if (
        clickedGraphic &&
        clickedGraphic?.attributes?.logicalId ===
        context.mapConfigPopupRef?.areaAttributes?.logicalId
      ) {
        // console.log(`Selecting invalid geometry after off click context._sketchViewModel?.update([clickedGraphic])`)
        context._sketchViewModel?.update([clickedGraphic])
      }
      //If no graphic but in error state, update the representation of the sketch vm in the custom tool component
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
        context,
        context.mapConfigValidationError,
        clickedGraphic
      )
      // Skip remainder of click processing if error exists on either step
      return
    }
    // Ignore clicks when there's a validaiton error or if we're not on a geometry editing step
    //THIS IS AN ISSUE DUE TO RACE CONDITION ON HIDING THE POPUP MAYBE
    const lastAreaSelected = context.mapConfigPopupRef?.areaAttributes?.id ?? null
    // console.log('lastAreaSelected', lastAreaSelected)
    if (onAddAreasStep) {
      handleMapConfigAreaClick(context, lastAreaSelected, hitTestResponse, clickedGraphic)
    } else if (onPerimeterStep) {
      handleMapConfigBoundaryClick(context, hitTestResponse)
    }
  }
  export const handleMapConfigAreaClick = (
    context: ArcGisMapService,
    lastAreaSelected: number | null,
    hitTestResponse: __esri.HitTestResult | null,
    clickedGraphic: __esri.Graphic | null
  ) => {
    //First process the hit test for validation logic
    //TODO Validate that this approach isn't needed anymore now that we manually put graphics into edit state
    // const sketchVmInteractionPrevented =
    //   ArcGisSketchViewModelEventHandler.preventInteractionBasedOnHitTest(context, hitTestResponse)
    // If the popup is visible and we clicked on nothing revet the symbol and hide the popup and update the tool state
    if (
      // !context.mapConfigValidationError &&
      hitTestResponse &&
      hitTestResponse.results.length === 0 &&
      context.mapConfigPopupRef?.popupVisible
    ) {
      context.mapConfigPopupRef.hidePopup()
      // Since we're deselecting an area we have to manually update the state of the tools widget
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
        context,
        context.mapConfigValidationError
      )
      //Reset the style for the last selected area
      if (lastAreaSelected) {
        const lastAreaGraphic = context._schoolAreaLayer?.graphics.find(
          (a) => a.attributes.id === lastAreaSelected
        )
        if (lastAreaGraphic) {
          lastAreaGraphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol
        }
      }
      // Only update the popup if the user clicked back on the non valid area
    } else if (
      // !context.mapConfigValidationError &&
      hitTestResponse &&
      hitTestResponse.results.length !== 0
    ) {
      if (clickedGraphic) {
        // Any time we click a graphic update the custom tool component state - since user can delete the area
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          context.mapConfigValidationError,
          clickedGraphic
        )
        // console.log(`Clicked graphic id`, clickedGraphic?.attributes?.id)
        if (lastAreaSelected && lastAreaSelected !== clickedGraphic?.attributes?.id) {
          // console.log(
          //   `Resetting area logical id ${context.mapConfigPopupRef?.graphic?.attributes?.logicalId} to display from edit`
          // )
          ArcgisAreaViewModel.updateAreaSymbolById(
            context,
            lastAreaSelected,
            ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
          )
          context.store.dispatch(selectAreaById(getExtendedAction(lastAreaSelected)))
        }
        // We handle sketch model interactions in that handler and that's where we show a popup, no need to show from here
        ArcGisPopupView.displayPopupForGraphic(context, clickedGraphic)
        // console.log(`handleMapConfigAreaClick context._sketchViewModel?.update([clickedGraphic])`)
        context._sketchViewModel?.update([clickedGraphic])
        // Whenever we click on an area we should update the tools that are interactable
        clickedGraphic.symbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
      } else {
        context.store.dispatch(selectAreaById(getExtendedAction(null)))
      }
    }
  }
  export const handleMapConfigBoundaryClick = (
    context: ArcGisMapService,
    hitTestResponse: __esri.HitTestResult | null
  ) => {
    if (hitTestResponse?.results && hitTestResponse?.results.length > 0) {
      // console.log(`handling perimeter step graphic click`)
      const clickedGraphic = (hitTestResponse.results[0] as __esri.GraphicHit)?.graphic ?? null
      // console.log(`
      // handleMapConfigBoundaryClick
      // context._sketchViewModel?.update([clickedGraphic])
      // `)
      context._sketchViewModel?.update([clickedGraphic])
      if ((context._sketchViewModel as any).updatePolygonSymbol) {
        const noTypeSketchVmRef = context._sketchViewModel as any
        // Attempts in progress to fight the framework and remove the fill symbol for edit and use our own
        // const newSymbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
        // noTypeSketchVmRef.activeFillSymbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
        // noTypeSketchVmRef.updatePolygonSymbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
      }
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
        context,
        context.mapConfigValidationError,
        clickedGraphic
      )
    } else {
      // console.log(`handling perimeter no graphic click`)
      context._sketchViewModel?.complete()
      //Handle if clicked off boundary in edit mode
      const sketchVmGraphic = context._schoolBoundaryLayer?.graphics.at(0)
      const boundarySketchGraphic = context._schoolBoundaryLayer?.graphics.at(0)
      // If we have a boundary on the map change the style to display
      if (sketchVmGraphic) {
        sketchVmGraphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      } else if (boundarySketchGraphic) {
        boundarySketchGraphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      }
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
        context,
        context.mapConfigValidationError
      )
    }
  }
  export const dashMouseClick = async (context: ArcGisMapService, click: __esri.ViewClickEvent) => {
    //TODO Dispatch selected area instead of manuall setting the handle click event here
    //Test to see if the cursor is over any areas
    const response = await ArcgisHitTest.getHitForDashLayers(context, click)
    if (!response) {
      console.warn(`Failed to get hit test on click!`)
      return
    }
    const results: __esri.ViewHit[] = response.results
    const result = ArcgisHitTest.getHitResult(response, context)
    const graphic = (result as __esri.GraphicHit)?.graphic

    if (!context.popupRef) {
      console.warn(`Can't show popup due to missing pop up component ref.`)
      return
    }
    const clickedGraphicId = graphic?.attributes?.id ?? null
    const clickedLayerId = result?.layer?.id ?? null
    // console.log(`Hit test returned ${results.length} results`);

    //Update the selected border style of the area to indicate it was clicked
    const resultIs_studentLocation = clickedLayerId === context._layerTypeToIdLookup.studentLocation
    const resultIs_teacherLocation = clickedLayerId === context._layerTypeToIdLookup.teacherLocation
    const resultIs_otherStaffLocation =
      clickedLayerId === context._layerTypeToIdLookup.otherStaffLocation
    const resultIs_guestLocation = clickedLayerId === context._layerTypeToIdLookup.guestLocation
    const resultIsLocation =
      resultIs_studentLocation ||
      resultIs_teacherLocation ||
      resultIs_otherStaffLocation ||
      resultIs_guestLocation
    const resultIsArea = clickedLayerId === context._layerTypeToIdLookup.area
    const clickedOnNothing = results.length === 0
    const resultIsMedicalAlert = clickedLayerId === context._layerTypeToIdLookup.medicalAlert
    const resultIsOnDimmedAlertLayer = clickedLayerId === context._layerTypeToIdLookup.dimmedAlert
    const resultIsAlert =
      clickedLayerId === context._layerTypeToIdLookup.pulsingAlert ||
      // TODO clarify popup behavior for dimmed alerts
      resultIsOnDimmedAlertLayer ||
      clickedLayerId === context._layerTypeToIdLookup.attackAlert

    const triggerGraphicUpdates =
      clickedOnNothing || resultIsLocation || resultIsArea || resultIsAlert || resultIsMedicalAlert

    // console.log(`hit test results`)
    // results.forEach((r) => console.log(r))
    // Don't allow interactions with opaque locations
    if (resultIsLocation && context.opaqueUserIds[graphic.attributes.id]) {
      return
    }
    //Clear last interactions if new interaction should update location and area style and/or popup
    if (triggerGraphicUpdates) {
      handleLastClickedDashLogic(context)
    }
    if (clickedOnNothing) {
      context.popupRef.hidePopupOnClickEvent()
      context.store.dispatch(clearSelectedMapId())
      context.lastClickedAreaId = null
      context.lastClickedUserId = null
      context.lastClickedUserType = null
      context.lastClickedAlertId = null
      return
    }
    // const resultIsPulsingAlert = clickedLayerId === context._layerTypeToIdLookup.pulsingAlert

    //Check all results for interactions with pulsing alert graphic
    results.forEach((r) => {
      if (r.type !== 'graphic') {
        console.warn(`Click interaction result type isn't a graphic`)
        return
      }
      const hitGraphic = (r as __esri.GraphicHit).graphic
      if (hitGraphic && hitGraphic.layer.id === context._layerTypeToIdLookup.pulsingAlert) {
        context._pulsingAlertGraphicsLayer?.graphics.remove(hitGraphic)
        context.store.dispatch(clickPulsingAlert(getExtendedAction(hitGraphic.attributes)))
      }
    })

    // console.log(`
    // resultIsLocation ${resultIsLocation}
    // resultIsArea ${resultIsArea}
    // resultIsPulsingAlert ${resultIsPulsingAlert}
    // `)
    if (resultIsArea) {
      context.lastClickedUserId = null
      context.lastClickedUserType = null
      context.lastClickedAreaId = clickedGraphicId
      context.lastClickedAlertId = null
      const typedAttributes = graphic?.attributes as AreaGraphicAttributes
      const payload = AreaViewModel.getSetAreaActionFromGraphicAttr(typedAttributes)
      if (!payload) {
        console.warn(`Can't handle set selected area due to invalid area type`)
        return
      }
      context.store.dispatch(setSelectedArea(getExtendedAction(payload)))
      const clickedGraphic = context._schoolAreaLayer?.graphics.find(
        (g) => g?.attributes?.id === clickedGraphicId
      )
      if (clickedGraphic) {
        clickedGraphic.symbol = ArcGisSymbolFactory.getSchoolAreaSymbol(
          true,
          clickedGraphic?.attributes?.statusVm,
          false
        ).clone()
      }
    } else if (resultIsAlert || resultIsMedicalAlert) {
      context.lastClickedUserId = null
      context.lastClickedUserType = null
      context.lastClickedAreaId = null
      context.lastClickedAlertId = clickedGraphicId
      const clickedGraphic = context._medialAlertGraphicsLayer?.graphics.find(
        (g) => g?.attributes?.id === clickedGraphicId
      )
      if (clickedGraphic) {
        clickedGraphic.symbol = ArcGisSymbolFactory.getSymbolForMedicalAlertMessage(
          resultIsOnDimmedAlertLayer
        )
      }
    } else if (resultIsLocation) {
      context.lastClickedUserId = clickedGraphicId
      context.lastClickedAreaId = null
      context.lastClickedAlertId = null
      const typedAttributes = graphic?.attributes as LocationGraphicAttributes
      context.store.dispatch(
        setSelectedUserMobileId(
          getExtendedAction({
            mobileUserId: typedAttributes.id,
            name: typedAttributes.fullName
          })
        )
      )
      if (resultIs_studentLocation) {
        context.lastClickedUserType = MobileUserTypes.student
        setSelectedStyleForGraphicById(
          ArcGisSymbolFactory.studentLocationSymbolSelected,
          clickedGraphicId,
          context._studentLocationLayer
        )
        findRecentMessageGraphicUpdateSymbol(
          clickedGraphicId,
          context._studentRecentMessageLayer,
          true
        )
      }
      if (resultIs_teacherLocation) {
        context.lastClickedUserType = MobileUserTypes.teacher
        setSelectedStyleForGraphicById(
          ArcGisSymbolFactory.teacherLocationSymbolSelected,
          clickedGraphicId,
          context._teacherLocationLayer
        )
        findRecentMessageGraphicUpdateSymbol(
          clickedGraphicId,
          context._teacherRecentMessageLayer,
          true
        )
      }
      if (resultIs_otherStaffLocation) {
        context.lastClickedUserType = MobileUserTypes.otherStaff
        setSelectedStyleForGraphicById(
          ArcGisSymbolFactory.otherStaffLocationSymbolSelected,
          clickedGraphicId,
          context._otherStaffLocationLayer
        )
        findRecentMessageGraphicUpdateSymbol(
          clickedGraphicId,
          context._otherStaffRecentMessageLayer,
          true
        )
      }
      if (resultIs_guestLocation) {
        context.lastClickedUserType = MobileUserTypes.guest
        setSelectedStyleForGraphicById(
          ArcGisSymbolFactory.guestLocationSymbolSelected,
          clickedGraphicId,
          context._guestLocationLayer
        )
        findRecentMessageGraphicUpdateSymbol(
          clickedGraphicId,
          context._guestRecentMessageLayer,
          true
        )
      }
    }
    if (resultIsArea || resultIsLocation || resultIsAlert || resultIsMedicalAlert) {
      ArcGisEventHandlers.handleClickEventForPopup(graphic, context._mapView, context.popupRef)
    }
  }
  /** Finds a graphic by the graphic id and recreates the symbol as non selected */
  export const setSelectedStyleForGraphicById = (
    s: __esri.SimpleMarkerSymbol | __esri.PictureMarkerSymbol,
    id: string | null,
    layer: __esri.GraphicsLayer | undefined
  ): void => {
    const graphic = layer?.graphics.find((g) => g.attributes?.id === id)
    if (graphic) {
      graphic.symbol = s.clone()
    }
  }
  /** Finds a graphic by the graphic id and updates the symbol */
  export const findRecentMessageGraphicUpdateSymbol = (
    id: string | null,
    layer: __esri.GraphicsLayer | undefined,
    hideIndicator: boolean
  ): void => {
    const graphic = layer?.graphics.find((g) => g.attributes?.id === id)
    if (graphic) {
      ArcgisUserGraphicHandler.setStyleForRecentMessageGraphic(graphic, hideIndicator)
    }
  }
  /** Here to check the last clicked user type and change the symbol for that id on the layer associated to that user type */
  export const clearLastClickedUserLocation = (context: ArcGisMapService) => {
    if (context.lastClickedUserType === MobileUserTypes.student) {
      setSelectedStyleForGraphicById(
        ArcGisSymbolFactory.studentLocationSymbol,
        context.lastClickedUserId,
        context._studentLocationLayer
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.teacher) {
      setSelectedStyleForGraphicById(
        ArcGisSymbolFactory.teacherLocationSymbol,
        context.lastClickedUserId,
        context._teacherLocationLayer
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.otherStaff) {
      setSelectedStyleForGraphicById(
        ArcGisSymbolFactory.otherStaffLocationSymbol,
        context.lastClickedUserId,
        context._otherStaffLocationLayer
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.guest) {
      setSelectedStyleForGraphicById(
        ArcGisSymbolFactory.guestLocationSymbol,
        context.lastClickedUserId,
        context._guestLocationLayer
      )
    }
  }
  /** Here to check the last clicked user type and change the symbol for that id on the layer associated to that user type */
  export const clearLastClickedUserRecentMessage = (context: ArcGisMapService) => {
    if (context.lastClickedUserType === MobileUserTypes.student) {
      findRecentMessageGraphicUpdateSymbol(
        context.lastClickedUserId,
        context._studentRecentMessageLayer,
        false
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.teacher) {
      findRecentMessageGraphicUpdateSymbol(
        context.lastClickedUserId,
        context._teacherRecentMessageLayer,
        false
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.otherStaff) {
      findRecentMessageGraphicUpdateSymbol(
        context.lastClickedUserId,
        context._otherStaffRecentMessageLayer,
        false
      )
    }
    if (context.lastClickedUserType === MobileUserTypes.guest) {
      findRecentMessageGraphicUpdateSymbol(
        context.lastClickedUserId,
        context._guestRecentMessageLayer,
        false
      )
    }
  }
  export const clearLastClickedArea = (context: ArcGisMapService) => {
    const lastClickedArea = context?._schoolAreaLayer?.graphics?.find(
      (g) => g?.attributes?.id === context.lastClickedAreaId
    )
    if (lastClickedArea) {
      lastClickedArea.symbol = ArcGisSymbolFactory.getSchoolAreaSymbol(
        false,
        lastClickedArea.attributes.statusVm,
        false
      ).clone()
    }
  }
  export const clearLastClickedMedicalAlert = (context: ArcGisMapService) => {
    const lastClickedMedicalAlert = context?._medialAlertGraphicsLayer?.graphics?.find(
      (g) => g?.attributes?.id === context.lastClickedAlertId
    )
    if (lastClickedMedicalAlert) {
      lastClickedMedicalAlert.symbol = ArcGisSymbolFactory.getSymbolForMedicalAlertMessage()
    }
  }
  /**
   * Function should be called Whenever we click on the map, and it's either on
   * 1. an area
   * 2. a location
   * 3. nothing
   * Either way we have to remove any potentially selected items on the map
   */
  export const handleLastClickedDashLogic = (context: ArcGisMapService) => {
    clearLastClickedUserLocation(context)
    clearLastClickedUserRecentMessage(context)
    clearLastClickedArea(context)
    clearLastClickedMedicalAlert(context)
  }
}
