import { getUsersForSchool, reloadUsersForSchool } from '@action/auth.action'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { redirectAngularRouter } from '@action/redirect.action'
import { showCompleteRosterModal } from '@action/roster-page/roster-page.actions'
import { Injectable } from '@angular/core'
import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { selectSelectedSchoolId } from '@selector/auth.selector'
import { ModalService } from '@service/material/modal.service'
import { RosterPageViewModel } from '@view/pages/roster-page/roster-page.view'
import { tap } from 'rxjs'

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private modalService: ModalService
  ) { }
  reloadUsersForSchool$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reloadUsersForSchool),
        concatLatestFrom((_) => this.store.select(selectSelectedSchoolId)),
        tap(([_, schoolId]) => {
          this.store.dispatch(getUsersForSchool(getExtendedAction(schoolId)))
        })
      ),
    { dispatch: false }
  )
  handleShowCompleteRosterModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showCompleteRosterModal),
        tap(() => {

          this.modalService.showTransitionModal(
            RosterPageViewModel.getAppearedActiveUsersCongratulationsModal(
              () => {
                this.store.dispatch(redirectAngularRouter(getExtendedAction(FirstLevelDir.dashboard)))
              },
              () => { }
            )
          )

        })
      ),
    { dispatch: false }
  )
}
