import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import {
  ChatMessageDto,
  ChatMessageDtoPayload,
  ChatResponseDtoPayload,
  DtoWithTimestamp
} from '@model/message.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { TimeUtils } from '@shared/time.utils'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { UserLocationViewModel } from '@view/location/user-location.view'
import { ChatMessageDtoHelper } from './message.helper'

export class SchoolOwlStateHelper {
  /** When the app receives a state change for the school to leave the routine state, redirect the user to the dashboard.  */
  static shouldRedirect(
    owlSchoolState: SchoolStateEnum | null,
    newSchoolState: SchoolStateEnum
  ) {
    //If the first state we get is non routine we should also redirect
    if (!owlSchoolState) {
      return newSchoolState !== SchoolStateEnum.routine
    }
    // console.info(`
    //   owlSchoolState: ${owlSchoolState} -> newSchoolState:${newSchoolState}
    //   `)
    return owlSchoolState === SchoolStateEnum.routine && newSchoolState !== SchoolStateEnum.routine
  }
  static resetUserLocationVms = (
    userLocationLookup: Record<string, DecoratedUserLocationViewModel>
  ): Record<string, DecoratedUserLocationViewModel> => {
    let copy = { ...userLocationLookup }
    Object.values(copy).forEach((vm: DecoratedUserLocationViewModel) => {
      copy[vm.mobileUserId] = UserLocationViewModel.resetDecoratedUserLocation(vm)
    })
    return copy
  }
  /** Take a look up of user id to their most recent of type chat dto object to filter by timestamp
   * TODO Change type to something with a timestamp instead of ChatResponseDtoPayload
   */
  static filterDownLocationChatDtoLookup<T extends DtoWithTimestamp>(
    userIdToChatDto: Record<string, T>,
    d: Date
  ): Record<string, T> {
    const filterDate = d.getTime()
    const copy = { ...userIdToChatDto }

    Object.entries(copy).forEach((entry) => {
      const key = entry[0]
      const chatDtoPayload = entry[1]
      const timeStampDate = TimeUtils.getDateFromString(chatDtoPayload.timestamp)
      if (!timeStampDate) {
        console.error(`User location poll response lacks a timestamp`)
        return
      }
      const isStale = timeStampDate.getTime() <= filterDate
      if (isStale) {
        delete copy[key]
      }
    })
    return copy
  }
  /** Used by a filter function to determine if dto can stay as part of source of truth for geospatial query results */
  static filterDownChatResponseDto = (
    chatResponseDto: ChatResponseDtoPayload,
    d: Date
  ): boolean => {
    const filterDate = d.getTime()
    const timeStampDate = TimeUtils.getDateFromString(chatResponseDto.timestamp)
    if (!timeStampDate) {
      console.error(`User location poll response lacks a timestamp`)
      return false
    }
    const isStale = timeStampDate.getTime() <= filterDate
    if (isStale) {
      return false
    }
    return true
  }
  /** This function is in charge of clearing an areas status once the time it was set at is expired TODO remove after implementing optimized historic handling */
  static filterDownAreaStatusLookup(
    areaStatusLookup: Record<number, AreaStatusViewModel>,
    d: Date
  ): Record<number, AreaStatusViewModel> {
    const filterByDateEpoch = d.getTime()
    Object.entries(areaStatusLookup).forEach((e) => {
      const areaId = parseInt(e[0], 10)
      const areaStatusVm = e[1]
      if (!areaStatusVm.currentStatusDate) {
        //No need to do anything for an area that doesn't have a status
        return
      }
      if (areaStatusVm.currentStatusDate.getTime() < filterByDateEpoch) {
        areaStatusLookup[areaId] = AreaStatusViewModel.resetFromVm(areaStatusVm)
      }
    })
    return areaStatusLookup
  }
  /** In routine mode we need to at an interval remove data from display that has aged out */
  static getDashStateFromStaleDataFilterDate(
    s: DashboardPageState,
    a: ExtendedAction<Date>
  ): DashboardPageState {
    if (!a.payload) {
      return s
    }
    if (!a.payload?.getTime()) {
      console.warn(
        `Clear stale data date malformed in getDashStateFromStaleDataFilterDate`,
        a.payload
      )
      return s
    }
    return SchoolOwlStateHelper._filterDashPageStateByDate(s, a.payload)
  }
  static _filterDashPageStateByDate = (s: DashboardPageState, d: Date) => {
    const filteredDtos = s.currentSchoolChatMessages.filter((dto) =>
      SchoolOwlStateHelper.filterChatMessageDtoByTimestamp(dto, d)
    )
    const userMobileIdToLastSertMessageLookup =
      SchoolOwlStateHelper.filterDownLocationChatDtoLookup<ChatMessageDtoPayload>(
        s.userMobileIdToLastSertMessageLookup,
        d
      )

    //To save on client side geo spatial queries, we persist the area to resposne lookup but clear it as data becomes stale
    const userMobileIdToAlerts: Record<string, ChatResponseDtoPayload[]> = {}
    Object.entries(s.userMobileIdToAlerts).forEach((entry) => {
      const areaId = entry[0]
      const dtos: ChatResponseDtoPayload[] = entry[1]
      const filteredDtoPayloads = dtos.filter((dto) =>
        SchoolOwlStateHelper.filterDownChatResponseDto(dto, d)
      )
      userMobileIdToAlerts[areaId] = filteredDtoPayloads
    })
    const userMobileIdToNegateAlerts: Record<string, ChatResponseDtoPayload[]> = {}
    Object.entries(s.userMobileIdToNegateAlerts).forEach((entry) => {
      const areaId = entry[0]
      const dtos: ChatResponseDtoPayload[] = entry[1]
      const filteredDtoPayloads = dtos.filter((dto) =>
        SchoolOwlStateHelper.filterDownChatResponseDto(dto, d)
      )
      userMobileIdToNegateAlerts[areaId] = filteredDtoPayloads
    })
    const userMobileIdToLastPollResponseLookup =
      SchoolOwlStateHelper.filterDownLocationChatDtoLookup<ChatResponseDtoPayload>(
        s.userMobileIdToLastPollResponseLookup,
        d
      )
    const userMobileIdToLastMessageResponseLookup =
      SchoolOwlStateHelper.filterDownLocationChatDtoLookup<ChatResponseDtoPayload>(
        s.userMobileIdToLastMessageResponseLookup,
        d
      )
    const areaIdToUsersLastPollResponses: Record<number, ChatResponseDtoPayload[]> = {}
    Object.entries(s.areaIdToUsersLastPollResponses).forEach((entry) => {
      const areaId = parseInt(entry[0], 10)
      const dtos: ChatResponseDtoPayload[] = entry[1]
      const filteredDtoPayloads = dtos.filter((dto) =>
        SchoolOwlStateHelper.filterDownChatResponseDto(dto, d)
      )
      areaIdToUsersLastPollResponses[areaId] = filteredDtoPayloads
    })
    const areaIdToAlerts: Record<number, ChatResponseDtoPayload[]> = {}
    Object.entries(s.areaIdToAlerts).forEach((entry) => {
      const areaId = parseInt(entry[0], 10)
      const dtos: ChatResponseDtoPayload[] = entry[1]
      const filteredDtoPayloads = dtos.filter((dto) =>
        SchoolOwlStateHelper.filterDownChatResponseDto(dto, d)
      )
      areaIdToAlerts[areaId] = filteredDtoPayloads
    })
    const areaIdToNegatedAlerts: Record<number, ChatResponseDtoPayload[]> = {}
    Object.entries(s.areaIdToNegatedAlerts).forEach((entry) => {
      const areaId = parseInt(entry[0], 10)
      const dtos: ChatResponseDtoPayload[] = entry[1]
      const filteredDtoPayloads = dtos.filter((dto) =>
        SchoolOwlStateHelper.filterDownChatResponseDto(dto, d)
      )
      areaIdToAlerts[areaId] = filteredDtoPayloads
    })

    // Every time we remove dtos due to staleness recalculate area statuses
    let areaStatusLookup = AreaStatusViewModel.getAreaStatusLookupByChatResponses(
      s,
      areaIdToUsersLastPollResponses,
      areaIdToAlerts,
      areaIdToNegatedAlerts
    )
    let shouldClearSelectedPoll = false
    if (s.vm.pressedPollId) {
      const remainingPollIds = filteredDtos
        .filter((dto) => ChatMessageDtoHelper.isSentPoll(dto))
        .map((dto) => dto.payload.logicalId)
      shouldClearSelectedPoll = !remainingPollIds.includes(s.vm.pressedPollId)
      if (shouldClearSelectedPoll) {
        console.info(`MANUALLY CLEARING PRESSED POLL ID`)
      }
    }
    // console.log("filteredDtos", filteredDtos)
    return DashboardPageState.handleGetChatMessagesSuccess(
      {
        ...s,
        areaStatusLookup,
        userLocationLookup: SchoolOwlStateHelper.resetUserLocationVms(s.userLocationLookup),
        userMobileIdToLastSertMessageLookup,
        userMobileIdToAlerts,
        userMobileIdToNegateAlerts,
        userMobileIdToLastPollResponseLookup,
        userMobileIdToLastMessageResponseLookup,
        areaIdToUsersLastPollResponses,
        areaIdToAlerts,
        vm: shouldClearSelectedPoll
          ? {
            ...s.vm,
            pressedPollId: null
          }
          : s.vm
      },
      getExtendedAction(filteredDtos),
      d
    )
  }
  /** Helps with filtering chat message dtos by the concluded event date conditionally present in the owl state object. */
  static filterChatMessageDtoByTimestamp(dto: ChatMessageDto, d: Date): boolean {
    const dtoTimeStampDate = TimeUtils.getDateFromString(dto.payload.timestamp)
    if (!dtoTimeStampDate) {
      console.error(`DEV ERROR: Dto with id ${dto.payload?.logicalId} missing timestamp`)
      return false
    }
    return d.getTime() < dtoTimeStampDate.getTime()
  }
  /** Receives a date from the app configuration on historic time fetch, and an optional value for last concluded event time, and should use whichever is the more recent one.
   * @param HISTORIC_DATA_LATENCY_BUFFER_SECONDS Ensure inclusion of first submitted alert which may not make it into the historic time window due to server processing, if ommitted, defaults to 0 so client historic data query is an exact match to the historic data query time returned by the server
   */
  static getDateQueryWithOverrideLogic(
    historicMessageTime: Date,
    historicDateExtensionOverride: string | null | undefined,
    HISTORIC_DATA_LATENCY_BUFFER_SECONDS: number = 0
  ): Date {
    const epochForhistoricMessageTime = historicMessageTime.getTime()
    if (historicDateExtensionOverride) {
      const epochHistoricDateExtensionOverride = TimeUtils.getDateFromString(
        historicDateExtensionOverride
      )
      if (!epochHistoricDateExtensionOverride) {
        console.warn(`Issue with date value for historicDateExtensionOverride`)
        // console.info(`Using date query in config event for historic data`)
        return historicMessageTime
      }
      const adjustedTimeForFirstAlertInclusion = new Date(
        epochHistoricDateExtensionOverride.getTime() - HISTORIC_DATA_LATENCY_BUFFER_SECONDS * 1000
      )
      if (adjustedTimeForFirstAlertInclusion.getTime() < epochForhistoricMessageTime) {
        // console.info(`Using last suspected event for historic data`)
        return adjustedTimeForFirstAlertInclusion
      } else {
        // console.info(`Using date query in config event for historic data`)
        return historicMessageTime
      }
    } else {
      return historicMessageTime
    }
  }
}
