import { FirstLevelDir } from '@domain/route/app-routes.domain'
import {
  DashboardPageDependencies,
  GlobalRedirectHandlingDependencies
} from '@model/user-organization/global-redirects.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  selectGlobalState,
  selectCurrentOrgStats,
  selectGetSchoolUsersApiStateById,
  selectGetSchoolUsersApiStates,
  selectOwlSchoolStateProperty,
  selectSchoolApiById,
  selectSchoolById,
  selectSchoolUsers,
  selectSchoolUsersBySchoolId,
  selectSelectedSchoolId,
  selectUserDto
} from '@selector/auth.selector'
import { selectPageIsDashboard, selectUrl } from '@selector/route.selector'
import { OwlStateSlices } from '@state/app.state'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { DashboardPageViewModel } from '@view/pages/dashboard-page/dashboard-page.view'

export const selectDashboardPageState = createFeatureSelector<DashboardPageState>(
  OwlStateSlices.schoolDashboard
)
export const selectAllLoadedDashboardPageState = createSelector(
  selectPageIsDashboard,
  selectDashboardPageState,
  (isDash, state) =>
    isDash === false ? null : DashboardPageState.returnStateIfAllApiStateLoaded(state)
)
export const selectDashboardPageStateWithSchoolState = createSelector(
  selectDashboardPageState,
  selectOwlSchoolStateProperty,
  (dashPageState, schoolOwlState) => ({
    dashPageState,
    schoolOwlState
  })
)
export const selectChatRoomIdToAttributesLookup = createSelector(
  selectDashboardPageState,
  (state) => state.chatRoomIdToAttributesLookup
)
export const selectDashboardViewModel = createSelector(
  selectDashboardPageState,
  (state) => state.vm
)
export const selectChatVm = createSelector(selectDashboardViewModel, (vm) => vm.chatVm)
export const selectAreaStatusLookup = createSelector(
  selectDashboardPageState,
  (state) => state.areaStatusLookup
)
export const selectResponseIdToColorLookup = createSelector(
  selectDashboardPageState,
  (state) => state.responseIdToStatusColorEnum
)
export const selectResponseIdToGetResponseDto = createSelector(
  selectDashboardPageState,
  (state) => state.responseIdToGetResponseDto
)
export const selectuserMobileIdToLastPollResponseLookup = createSelector(
  selectDashboardPageState,
  (state) => state.userMobileIdToLastPollResponseLookup
)
export const selectGroupedPredefinedMessages = createSelector(
  selectDashboardPageState,
  (state) => state.groupedPredefinedMessages
)

export const selectUserToTypeLookup = createSelector(
  selectDashboardPageState,
  (state) => state.userToTypeLookup
)
export const selectSentPollToPollResponseLookup = createSelector(
  selectDashboardPageState,
  (state) => state.sentPollToPollResponseLookup
)
export const selectDashChatLoading = createSelector(
  selectDashboardPageState,
  (state) => state?.hasLoaded
)
export const selectDashChatError = createSelector(
  selectDashboardPageState,
  (state) => state?.hasError
)
export const selectAreaLogicalIdToChatRoomNameLookup = createSelector(
  selectDashboardPageState,
  (state) => state.areaLogicalIdToChatRoomNameLookup
)
export const selectAreaIdToAreaLogicalIdLookup = createSelector(
  selectDashboardPageState,
  (state) => state.areaIdToAreaLogicalIdLookup
)

//USER
export const selectUserToDisplayNameLookup = createSelector(
  selectDashboardPageState,
  (state) => state.mobileIdToDisplayNameLookup
)
export const selectGetUsersForSchoolApiState = createSelector(
  selectDashboardPageState,
  (state) => state.getUsersForSchoolApiState
)
//Locations
export const selectUserLocationsHistoricDataApiState = createSelector(
  selectDashboardPageState,
  (state) => state.userLocationsHistoricDataApiState
)
//CHAT
export const selectCurrentSchoolChatMessages = createSelector(
  selectDashboardPageState,
  (state) => state.currentSchoolChatMessages
)
export const selectHistoricChatMessagesApiState = createSelector(
  selectDashboardPageState,
  (state) => state.historicChatMessagesApiState
)
export const selectPredefinedMessagesApiState = createSelector(
  selectDashboardPageState,
  (state) => state.predefinedGroupsApiState
)
export const selectResponseGroupsApiState = createSelector(
  selectDashboardPageState,
  (state) => state.responseGroupsApiState
)
export const selectSentPollToIncludedUserTypes = createSelector(
  selectDashboardPageState,
  (state) => state.sentPollToIncludedUserTypes
)

//MAP
export const selectMapVisibleByUserClick = createSelector(
  selectDashboardViewModel,
  (vm) => vm.mapVisibleByUserClick
)
export const selectSideNavIsCollapsed = createSelector(
  selectSchoolById,
  selectDashboardPageState,
  DashboardPageViewModel.getSideNavIsCollapsed
)
export const selectShowSelectEventUi = createSelector(
  selectDashboardPageState,
  (state) => state.showSelectEventUi
)
//Links
export const selectLinksAreVisibleOnDashboard = createSelector(
  selectUrl,
  selectSchoolById,
  selectMapVisibleByUserClick,
  DashboardPageViewModel.getLinksAreVisibleOnDashboard
)
export const selectUserLocationLookup = createSelector(
  selectDashboardPageState,
  (state) => state.userLocationLookup
)

/** Selector exists in order to verify that all needed api calls have completed before we can redirect to the dashboard, get historic message/location data and subscribe to signalr for real time data updates. */
export const selectDashSubscriptionDependencies = createSelector(
  selectUserDto,
  selectSchoolById,
  selectPredefinedMessagesApiState,
  selectResponseGroupsApiState,
  selectGetSchoolUsersApiStates,
  selectSchoolUsers,
  selectCurrentOrgStats,
  (
    userDto,
    schoolDto,
    predefinedApi,
    responseGroupsApi,
    getUserSchoolApiState,
    userDtos,
    currentOrgStats
  ) => {
    if (!schoolDto) {
      return null
    }
    if (!schoolDto.id) {
      console.warn(`School dto has no id, required to process dashboard page dependencies`)
      return null
    }
    const usersForSchoolApiState = getUserSchoolApiState[schoolDto.id] ?? null
    if (!usersForSchoolApiState) {
      return null
    }
    if (
      usersForSchoolApiState.hasLoaded &&
      predefinedApi.hasLoaded &&
      responseGroupsApi.hasLoaded
    ) {
      return {
        userDto,
        schoolDto,
        userDtos,
        currentOrgStats
      } as DashboardPageDependencies
    } else {
      return null
    }
  }
)
/** This exists so that we only provide the dashboard component service the ids to use for getting user locations and messages and subscribing to real time updates once all required data is avaailable. */
export const selectIdsForDashWhenAllDataLoaded = createSelector(
  selectSelectedSchoolId,
  selectDashSubscriptionDependencies,
  (ids, deps) => (deps ? ids : null)
)

export const dashReadyToBeShown = createSelector(
  selectUrl,
  selectGlobalState,
  selectDashSubscriptionDependencies,
  (url, auth, dashDep) => {
    // console.log(`
    //   url: ${url}
    //   auth: ${auth}
    //   dashDep: ${dashDep}
    //   `)
    return url.includes(FirstLevelDir.dashboard) && dashDep
  }
)

export const selectGlobalRedirectHandleDeps = createSelector(
  selectUserDto,
  selectSchoolById,
  selectSchoolApiById,
  selectGetSchoolUsersApiStateById,
  selectSchoolUsers,
  selectCurrentOrgStats,
  GlobalRedirectHandlingDependencies.getModelDeps
)
