import Graphic from '@arcgis/core/Graphic'
import { ChatResponseDtoPayload } from '@model/message.model'
import { TimeUtils } from '@shared/time.utils'
import { LocationOfAlertResponseAttr } from '@view/area/location.view'

export class AlertViewModel {
  intervalId?: any
  growing: boolean = true
  // Increasing max size of pulse as there's no pulse in designs and this size is hard to notice
  // maxSize = 18
  maxSize = 48 //24 //TODO Determine size of alert or put into config for later easy update
  minSize = 12
  id!: string
  timestamp: Date | null = null

  constructor(
    public graphic: Graphic,
    public symbol: __esri.SimpleMarkerSymbol,
    private payload: ChatResponseDtoPayload
  ) {
    this.id = payload.logicalId ?? payload.id?.toString() ?? '0'
    const timestamp = TimeUtils.getDateFromString(payload.timestamp)
    if (timestamp) {
      this.timestamp = timestamp
    }
  }
  timeoutHandler = () => {
    let currentSize = this.symbol.size
    if (this.growing) {
      currentSize += 0.8
      if (currentSize >= this.maxSize) {
        this.growing = false
      }
    } else {
      currentSize -= 0.8
      if (currentSize <= this.minSize) {
        this.growing = true
      }
    }

    // Update the symbol size and apply it to the graphic
    this.symbol.size = currentSize
    this.graphic.symbol = this.symbol.clone()
  }
  handleClearPulse = () => {
    clearInterval(this.intervalId)

    this.symbol.size = this.minSize
    this.graphic.symbol = this.symbol.clone()
  }
  setUpTempPulseOnSymbol = (a: LocationOfAlertResponseAttr): Graphic => {
    this.intervalId = setInterval(this.timeoutHandler, 100)
    this.graphic.attributes = a
    return this.graphic
  }
}
