import { getSchoolById, getUsersForSchool, reloadInstructionsForCommunications, reloadUsersForSchool, tokenReceived } from '@action/auth.action'
import {
  decrementToastInQueue,
  enqueueToast,
  showSingleToast,
  toggleProfile
} from '@action/global-app.actions'
import { getPredefinedGroupsFail, getPredefinedGroupsSuccess } from '@action/messages/predefined-messages-api.actions'
import { getPredefinedGroupsForSchool } from '@action/messages/predefined-messages-page.actions'
import { getResponseGroupsSuccess, getResponseGroupsFail } from '@action/messages/response-group-api.actions'
import { getResponseGroupsForSchool } from '@action/messages/response-group-page.actions'
import {
  getSchoolPolygonByLoc,
  patchSchoolDto,
  postNewSchoolArea
} from '@action/school-map-config-page.actions'
import {
  deleteSchoolAreaSuccess,
  patchSchoolAreaDtoError,
  patchSchoolAreaDtoSuccess,
  patchSchoolStateError,
  patchSchoolSuccess,
  postSchoolAreaDtoError,
  postSchoolAreaDtoSuccess
} from '@action/school/school-api.action'
import { getPolygonError, getPolygonSuccess } from '@action/school/school-osm-polygon-api.action'
import {
  getSchoolByIdError,
  getSchoolByIdSuccess,
  saveSchoolForUserSuccess
} from '@action/school/school-validation-api.action'
import {
  receiveSignalrSchoolOwlStateUpdate,
  receiveSignalrSchoolUpdate
} from '@action/signalr.action'
import {
  getUserSuccess,
  getUsersForSchoolError,
  getUsersForSchoolSuccess,
  setValidationClaims
} from '@action/user/user-api.action'
import { routerNavigationAction } from '@ngrx/router-store'
import { Action, createReducer, on } from '@ngrx/store'
import { GlobalState, defaultAuthState } from '@state/auth.state'

const reducer = createReducer(
  defaultAuthState,
  //Routing reset for org stats
  on(routerNavigationAction, GlobalState.handleRouterNavigation),
  on(reloadUsersForSchool, GlobalState.handleReloadUsersForSchool),
  on(reloadInstructionsForCommunications, GlobalState.reloadInstructionsForCommunications),
  //USER
  // auth
  on(tokenReceived, GlobalState.handleTokenReceived),
  on(setValidationClaims, GlobalState.setValidationClaims),
  on(getUserSuccess, GlobalState.handleUserData),
  // onboarding
  on(saveSchoolForUserSuccess, GlobalState.handleUpdateToSchoolAddition),
  //SCHOOL DTO
  // GET
  on(getSchoolById, GlobalState.getSchoolById),
  on(getSchoolByIdSuccess, GlobalState.handleSchoolByIdSuccess),
  on(getSchoolByIdError, GlobalState.handleSchoolByIdError),
  //SCHOOL USER DTOS
  on(getUsersForSchool, GlobalState.handleGetUsersForSchool),
  on(getUsersForSchoolSuccess, GlobalState.handleGetUsersForSchoolSuccess),
  on(getUsersForSchoolError, GlobalState.handleGetUsersForSchoolError),
  // PATCH
  // patch sends updates to db, but relies on signalr to confirm UI state transition - use the below to indicate network loading or move update to signalr dispatch
  on(patchSchoolDto, GlobalState.handlePatchSchoolDto),
  on(patchSchoolSuccess, GlobalState.handlePatchSchoolSuccess),
  on(patchSchoolStateError, GlobalState.handlePatchSchoolError),
  // DELETE
  // Future state, needs to be implemented in accordance with the new map config step pattern
  // on(deleteSchoolPerimeterBoundarySuccess, GlobalState.deleteSchoolPerimeterBoundarySuccess),
  // area
  on(patchSchoolAreaDtoSuccess, GlobalState.handlePatchSchoolAreaSuccess),
  on(patchSchoolAreaDtoError, GlobalState.handlePatchSchoolAreaError),
  on(deleteSchoolAreaSuccess, GlobalState.deleteSchoolAreaSuccess),
  //REALTIME
  on(receiveSignalrSchoolOwlStateUpdate, GlobalState.handleNewSchoolOwlState),
  on(receiveSignalrSchoolUpdate, GlobalState.handleNewSchoolDto),
  //POST
  // area
  on(postNewSchoolArea, GlobalState.handleSaveSchoolAreaDto),
  on(postSchoolAreaDtoSuccess, GlobalState.handleSaveSchoolAreaDtoSuccess),
  on(postSchoolAreaDtoError, GlobalState.handleSaveSchoolAreaDtoError),
  //OSM
  // polygon api
  on(getSchoolPolygonByLoc, GlobalState.handleGetSchoolPolygonByLoc),
  on(getPolygonSuccess, GlobalState.handleSchoolPolygonByLocSuccess),
  on(getPolygonError, GlobalState.handleSchoolPolygonByLocError),
  //Global App State for toast
  on(showSingleToast, GlobalState.handleShowSingleToast),
  on(enqueueToast, GlobalState.handleEnqueueToast),
  on(decrementToastInQueue, GlobalState.decrementToastInQueue),

  on(toggleProfile, GlobalState.toggleProfile),
  
  //COMMUNICATION
  on(getPredefinedGroupsForSchool, GlobalState.handleGetPredefinedGroupsForSchool),
  on(getPredefinedGroupsSuccess, GlobalState.handleGetPredefinedGroupsForSchoolSuccess),
  on(getPredefinedGroupsFail, GlobalState.handleGetPredefinedGroupsForSchoolFail),
  on(getResponseGroupsForSchool, GlobalState.getResponseGroupsForSchool),
  on(getResponseGroupsSuccess, GlobalState.getResponseGroupsSuccess),
  on(getResponseGroupsFail, GlobalState.getResponseGroupsFail),
)

export function authReducer(state: GlobalState | undefined, action: Action) {
  return reducer(state, action)
}
