import { MatDialogRef } from '@angular/material/dialog'
import { ModalViewModel } from '@view/matieral/modal.view'

export abstract class BaseModalComponent<T> {
  constructor(public _dialogRef: MatDialogRef<T>, public _vm: ModalViewModel) { }
  onConfirm = () => {
    this._vm.confirmHandler(this._vm)
    this._dialogRef.close()
  }
  onCancel = () => {
    this._vm.cancelHandler(this._vm)
    this._dialogRef.close()
  }
}
