import { getUsersForSchool } from '@action/auth.action'
import {
  clearStaleDashData,
  disableCheckStatus,
  setChatRoomById,
  toggleChatUiVisibility
} from '@action/dashboard-page.actions'
import {
  handleChatRoomChangeLeft,
  handleChatRoomChangeRight,
  handleCreatePollClick,
  handlePollComponentHover,
  handleSelectMessageClick,
  handleSelectPollClick,
  handleSelectPredefinedItemId,
  handleShowChatListClick,
  setPressedPollId,
  setScrollPlacesInChatRoom,
  setScrollPlacesInChatRooms,
  toggleChatUiMenuVisibility,
  toggleOtherStaffTypeButton,
  toggleStudentsTypeButton,
  toggleTeachersTypeButton
} from '@action/dashboard-page/chat-ui.actions'
import { dispatchAppConfig } from '@action/global-app.actions'
import {
  getPredefinedGroupsFail,
  getPredefinedGroupsSuccess
} from '@action/messages/predefined-messages-api.actions'
import { getPredefinedGroupsForSchool } from '@action/messages/predefined-messages-page.actions'
import {
  getResponseGroupsFail,
  getResponseGroupsSuccess
} from '@action/messages/response-group-api.actions'
import { getResponseGroupsForSchool } from '@action/messages/response-group-page.actions'
import { toggleShowMap } from '@action/school-map-config-page.actions'
import {
  deleteSchoolAreaSuccess,
  patchSchoolAreaDtoSuccess,
  postSchoolAreaDtoSuccess
} from '@action/school/school-api.action'
import {
  getSchoolByIdSuccess,
  saveSchoolForUserSuccess
} from '@action/school/school-validation-api.action'
import {
  connectBySchoolIds,
  handleSignalrError,
  receiveSentSignalrChatMessage,
  receiveSignalrChatResponse,
  receiveSignalrUserLocation,
  receiveSignalrUserRegistered,
  sendSignalrChatMessage
} from '@action/signalr.action'
import {
  clearSelectedMapId,
  toggleSelectEventUi,
  getHistoricChatmessages,
  getHistoricUserLocations,
  setSelectedArea,
  setSelectedUserMobileId,
  setMapUiControlSessionState,
  toggleMapUiControl,
  updateSchoolState,
  updateAreaStatusByAreaIdToDtoLookup,
  updateAreaWithResponsePayload,
  threatIndicatorDimmed
} from '@action/user/dashboard-page.action'
import {
  getChatMessagesError,
  getChatMessagesSuccess,
  getHistoricUserLocationsError,
  getHistoricUserLocationsSuccess
} from '@action/user/message-api.action'
import { getUsersForSchoolError, getUsersForSchoolSuccess } from '@action/user/user-api.action'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'
import { PredefinedMessageModelHelper } from '@domain/dto-helpers/predefined-message-model.helper'
import { ResponseGroupModelHelper } from '@domain/dto-helpers/response-group-model.helper'
import { SchoolOwlStateHelper } from '@domain/dto-helpers/school-owl-state.helper'
import { Action, createReducer, on } from '@ngrx/store'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { ChatUiViewModel } from '@view/chat/chat.view'
import { UserLocationViewModel } from '@view/location/user-location.view'
import { DashboardPageViewModel } from '@view/pages/dashboard-page/dashboard-page.view'

const reducer = createReducer(
  new DashboardPageState(),
  on(dispatchAppConfig, DashboardPageState.handleAppConfig),
  //STALE DATA
  on(clearStaleDashData, SchoolOwlStateHelper.getDashStateFromStaleDataFilterDate),
  //SCHOOL
  // state
  on(updateSchoolState, DashboardPageState.hideAttackResponseTypeConditionally),
  // school dto
  on(getSchoolByIdSuccess, DashboardPageState.handleSchoolByIdSuccess),
  // area dto
  on(postSchoolAreaDtoSuccess, DashboardPageState.postSchoolAreaDtoSuccess),
  on(deleteSchoolAreaSuccess, DashboardPageState.deleteSchoolAreaSuccess),
  on(patchSchoolAreaDtoSuccess, DashboardPageState.patchSchoolAreaDtoSuccess),

  //USERS
  on(getUsersForSchool, DashboardPageState.handleGetUsersForSchool),
  on(getUsersForSchoolSuccess, DashboardPageState.handleGetUsersForSchoolSuccess),
  on(getUsersForSchoolError, DashboardPageState.handleGetUsersForSchoolError),

  //REAL TIME
  // messages
  on(sendSignalrChatMessage, DashboardPageState.handleChatMessageDto),
  on(receiveSignalrChatResponse, DashboardPageState.handleChatMessageDto),
  on(receiveSentSignalrChatMessage, DashboardPageState.handleChatMessageDto),
  // locations
  on(receiveSignalrUserLocation, UserLocationViewModel.handleReceivedUserLocation),
  // user
  on(receiveSignalrUserRegistered, DashboardPageState.handleUserRegistrationComplete),
  // send status poll
  on(disableCheckStatus, DashboardPageState.disableCheckStatus),
  // receive geometry engine updates
  on(updateAreaStatusByAreaIdToDtoLookup, SchoolAreaModelHelper.processMessagesByAreaIdResult),
  on(updateAreaWithResponsePayload, SchoolAreaModelHelper.updateAreaWithResponsePayload),
  on(threatIndicatorDimmed, SchoolAreaModelHelper.threatIndicatorDimmed),

  //CHAT
  on(setChatRoomById, ChatUiViewModel.handleSelectedChatroom),
  on(toggleChatUiVisibility, ChatUiViewModel.toggleChatUiVisibility),
  on(toggleChatUiMenuVisibility, ChatUiViewModel.toggleChatUiMenuVisibility),
  on(toggleTeachersTypeButton, ChatUiViewModel.toggleTeachersTypeButton),
  on(toggleOtherStaffTypeButton, ChatUiViewModel.toggleOtherStaffTypeButton),
  on(toggleStudentsTypeButton, ChatUiViewModel.toggleStudentsTypeButton),
  on(handleSelectMessageClick, ChatUiViewModel.handleSelectMessageClick),
  on(handleSelectPollClick, ChatUiViewModel.handleSelectPollClick),
  on(handleCreatePollClick, ChatUiViewModel.handleCreatePollClick),
  on(handleShowChatListClick, ChatUiViewModel.handleShowChatListClick),
  on(handleChatRoomChangeLeft, ChatUiViewModel.handleChatRoomChangeLeft),
  on(handleChatRoomChangeRight, ChatUiViewModel.handleChatRoomChangeRight),
  on(setScrollPlacesInChatRooms, ChatUiViewModel.setScrollPlacesInChatRooms),
  on(setScrollPlacesInChatRoom, ChatUiViewModel.setScrollPlacesInChatRoom),
  // poll
  on(handleSelectPredefinedItemId, ChatUiViewModel.handleSelectPredefinedItemId),

  //FILTER MAP SECTION
  on(setMapUiControlSessionState, DashboardPageViewModel.handleSetMapUiControl),
  on(toggleMapUiControl, DashboardPageViewModel.handleToggleMapUiControl),
  on(toggleShowMap, DashboardPageViewModel.toggleShowMap),
  on(setPressedPollId, DashboardPageViewModel.setPressedPollId),
  on(handlePollComponentHover, DashboardPageViewModel.handlePollComponentHover),

  //MAP SECTION
  on(setSelectedArea, DashboardPageViewModel.handleSetSelectedAreaId),
  on(setSelectedUserMobileId, DashboardPageViewModel.handleSetSelectedUserMobileId),
  on(clearSelectedMapId, DashboardPageViewModel.handleClearSelectedMapId),

  //SCHOOL EVENT
  on(toggleSelectEventUi, DashboardPageViewModel.handleClickSelectEvent),

  //Signal R Network State
  on(connectBySchoolIds, DashboardPageState.handleConnection),
  on(handleSignalrError, DashboardPageState.handleError),

  //User Onboarding
  on(saveSchoolForUserSuccess, DashboardPageState.handleUpdateToSchoolAddition),

  //API FETCHED DATA
  on(getPredefinedGroupsForSchool, PredefinedMessageModelHelper.getPredefinedGroups),
  on(getPredefinedGroupsSuccess, PredefinedMessageModelHelper.getPredefinedGroupsSuccess),
  on(getPredefinedGroupsFail, PredefinedMessageModelHelper.getPredefinedGroupsForSchoolFail),
  on(getResponseGroupsForSchool, ResponseGroupModelHelper.getResponseGroupsForSchool),
  on(getResponseGroupsSuccess, ResponseGroupModelHelper.getResponseGroupsSuccess),
  on(getResponseGroupsFail, ResponseGroupModelHelper.getResponseGroupsFail),

  //HISTORIC DATA
  // chat messages
  on(getHistoricChatmessages, DashboardPageState.handleGetHistoricChatMessages),
  on(getChatMessagesSuccess, DashboardPageState.handleGetChatMessagesSuccess),
  on(getChatMessagesError, DashboardPageState.handleGetChatMessagesError),
  // locations
  on(getHistoricUserLocations, UserLocationViewModel.handleGetHistoricUserLocations),
  on(getHistoricUserLocationsError, UserLocationViewModel.handleGetHistoricUserLocationsError),
  on(getHistoricUserLocationsSuccess, UserLocationViewModel.handleReceivedHistoricUserLocations)
)

export function dashboardReducer(state: DashboardPageState | undefined, action: Action) {
  return reducer(state, action)
}
