import { ExtendedAction } from '@action/extended-ngrx-action'
import { createAction, props } from '@ngrx/store'

export enum PredefinedGroupsActions {
  getPredefinedGroupsForSchool = `[Predefined Groups Actions] Get Predefined Groups By School id`
}

export const getPredefinedGroupsForSchool = createAction(
  PredefinedGroupsActions.getPredefinedGroupsForSchool,
  props<ExtendedAction<number>>()
)
