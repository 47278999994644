import { getExtendedAction } from '@action/extended-ngrx-action'
import { postNewSchoolArea, setMapConfigErrorReason } from '@action/school-map-config-page.actions'
import Graphic from '@arcgis/core/Graphic'
import { Polygon } from '@arcgis/core/geometry'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../../arcgis-map.service'
import { ArcGisPopupView } from '../../arcgis-popup.view'
import { ArcGisSymbolFactory } from '../../arcgis-symbol-factory.view'
import { ArcgisAreaViewModel } from '../../area/arcgis-area.view'
import { ArcGisGeomValidaiton } from '../../geometry/arcgis-geometry-validation.view'
import { ArcGisMapViewHandler } from '../../map-view/map-view-handler.view'
import { ArcGisToolEventInfoViewModel } from '../arc-gis-tool-event-info.view'
import { ArcGisSketchCustomToolStateHandler } from '../custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'
import { SketchVmStateEnum } from '../sketch-vm.view'

export namespace ArcGisSketchVmAreaEventHandler {
  export const handleAreaGeomRedo = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelRedoEvent
  ) => {
    // console.log(`Handle undo redo`)
    // console.log(event)
    ArcgisAreaViewModel.handleAreaGeomUpdate(context, event)
  }
  export const handleAreaGeomUndo = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelUndoEvent
  ) => {
    // console.log(`Handle undo event`)
    // console.log(event)
    ArcgisAreaViewModel.handleAreaGeomUpdate(context, event)
  }
  //   UPDATE AREA REGION
  //#region
  /** Handle sketch create for boundary tracing image polygon for saving */
  export const handleAreaUpdate = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelUpdateEvent
  ) => {
    // console.log(`area update event`, event)
    // We don't need to react to the update start event as that's triggered programmatically in click handlers and other concerns
    if (event.state === SketchVmStateEnum.start || event.state === SketchVmStateEnum.complete) {
      return
    }
    //If Update attempt on other area while current isn't valid skip handling
    const updateOnAreaPopupIsVisibleFor =
      context.mapConfigPopupRef?.areaAttributes?.id === event.graphics[0].attributes.id
    let validationErrorOrNull: MapConfigValidationErrors | null = null
    if (!updateOnAreaPopupIsVisibleFor) {
      console.warn(`DEV ERROR: update called on graphic not displayed in popup`)
      return
    }
    const eventIsForAnotherGraphic =
      event.graphics[0].attributes.logicalId !==
      context.mapConfigPopupRef?.areaAttributes?.logicalId
    const editInPauseStated =
      ArcGisToolEventInfoViewModel.updateToolEventInfoIsOfStoppedVariety(event)

    // console.log('eventIsForAnotherGaphic', eventIsForAnotherGaphic)
    // THis is better performance but feedback isn't instance
    // if (event.state === SketchVmStateEnum.active && editInPauseStated && event.graphics[0]) {
    // console.log(`event.state`, event.state)
    if (event.state === SketchVmStateEnum.active && event.graphics[0]) {
      // console.log(`Running validations in update`)
      validationErrorOrNull = ArcGisGeomValidaiton.getAreaValidationErrorOrNull(
        context,
        event.graphics[0].attributes?.id ?? null,
        event.graphics[0]
      )
      // Dispatch a page state update if the map config error state changes
      if (context.mapConfigValidationError !== validationErrorOrNull) {
        context.store.dispatch(setMapConfigErrorReason(getExtendedAction(validationErrorOrNull)))
      }
      // Always update the tools on every area update event as long as a graphic is available
      //TODO This is a bit of a performance hit so perhaps only on paused
      if (editInPauseStated) {
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          validationErrorOrNull,
          event.graphics[0]
        )
      }
    }
    if (
      //or if we're selecting a different area to edit
      !context.mapConfigValidationError &&
      context.mapConfigPopupRef?.areaAttributes?.id &&
      context.mapConfigPopupRef?.areaAttributes?.id !== event.graphics[0].attributes.id
    ) {
      ArcgisAreaViewModel.updateAreaSymbolById(
        context,
        context.mapConfigPopupRef.areaAttributes.id,
        ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      )
    }
    // if (context.mapConfigValidationError || validationErrorOrNull) {'
    if (!eventIsForAnotherGraphic && event.graphics[0]) {
      ArcgisAreaViewModel.updateAreaGraphicByErrorEditOrDisplay(
        context,
        event.graphics[0],
        validationErrorOrNull,
        event.state as SketchVmStateEnum
      )
    }
    const existingGraphics: Graphic[] | null = event?.graphics
    if (existingGraphics[0]) {
      //Save updates to existing area
      if (!context.mapConfigPopupRef) {
        throw Error(`DEV ERROR: No ref to map config pop up.`)
      }
      const graphic = existingGraphics[0]
      const projectedGraphicGeom = ArcGisPopupView.displayPopupForGraphic(context, graphic)
      // console.log(`projectedGraphicGeom`, projectedGraphicGeom)
      //Only process saving logic when we're pausing edit flow
      if (!editInPauseStated) {
        // Update the popup position as the user moves the graphic
        return
      }
      //States of complete and start will fire on selection and deseletion but there's no necessarily changes to save yet so only save when there's no error, we're in active state, and we're not mid point or area drag which will hurt performance
      if (
        projectedGraphicGeom &&
        event.state === SketchVmStateEnum.active &&
        editInPauseStated &&
        // // The update should allow saving the geometry, skip if geom invalid
        !validationErrorOrNull
      ) {
        const boundary = (projectedGraphicGeom as Polygon).rings[0]
        const areaHasId = !!event.graphics[0].attributes?.id
        if (!areaHasId) {
          // console.log(`Saving updated area shape with no id when no validation errors exists`)
          const areaMapConfigVm = ArcGisMapViewHandler.getMapConfigAreaVm(context)
          const dto = SchoolAreaModelHelper.getDtoFromAttrAndVm(
            boundary,
            graphic.attributes,
            areaMapConfigVm
          )
          context.store.dispatch(postNewSchoolArea(getExtendedAction(dto)))
        } else {
          // console.log(`Updating area shape`)
          // If non geom error exists only save updated rings in map config pop ref for later use on proper name addition
          const errorIsNotRelatedToAreaGeom =
            context.mapConfigValidationError &&
            MapConfigValidationViewModel.errorIsRelatedToAreaGeom(context.mapConfigValidationError)
          context.mapConfigPopupRef.handleRingsChange(boundary, !errorIsNotRelatedToAreaGeom)
        }
      }
      if (editInPauseStated && event.state === SketchVmStateEnum.active) {
        // console.log(`Handle update with sync of sketch vm in state`)
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          context.mapConfigValidationError
        )
      }
    }
  }
  //#endregion
  // CREATE AREA REGION
  // #region
  /** Handle sketch create for boundary tracing image polygon for saving */
  export const handleAreaCreate = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelCreateEvent
  ) => {
    const createEventIsComplete = event.state === SketchVmStateEnum.complete
    // console.log('createEventIsComplete', createEventIsComplete)
    if (!event.graphic || !createEventIsComplete) {
      return
    }
    // In case the user deleted the last area we must clear the error
    if (context.mapConfigValidationError === MapConfigValidationErrors.atLeastOneAreaMustExist) {
      context.store.dispatch(setMapConfigErrorReason(getExtendedAction(null)))
    }

    //Start validating the graphic as the user is drawing it
    const [graphic, dto] =
      ArcGisPopupView.displayPopupForNewlyCreatedGraphic(
        context,
        event.graphic,
        createEventIsComplete
      ) ?? []
    if (!graphic) {
      return
    }
    //Validate the drawn geometry and save if valid
    const clonedGraphic = event.graphic.clone()
    // Run validations on cloned graphic to avoid resetting the projection on the original
    const validationErrorOrNull = ArcGisGeomValidaiton.getAreaValidationErrorOrNull(
      context,
      null,
      clonedGraphic
    )

    ArcgisAreaViewModel.updateAreaGraphicByErrorEditOrDisplay(
      context,
      event.graphic,
      validationErrorOrNull,
      event.state as SketchVmStateEnum
    )
    console.log('validationErrorOrNull', validationErrorOrNull)
    if (validationErrorOrNull) {
      context.store.dispatch(setMapConfigErrorReason(getExtendedAction(validationErrorOrNull)))
    }
    if (!dto) {
      return
    }
    if (createEventIsComplete) {
      //Put the newly created area in edit mode, whether it passed validation or not
      // console.log(`handleAreaCreate context._sketchViewModel?.update([event.graphic])`)
      context._sketchViewModel?.update([event.graphic])
    }
    if (createEventIsComplete && !validationErrorOrNull) {
      context.store.dispatch(postNewSchoolArea(getExtendedAction(dto)))
    }
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
      context,
      validationErrorOrNull,
      graphic
    )
  }
  //#endregion
}
