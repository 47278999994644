import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FloatLabelType } from '@angular/material/form-field'
import { MatSelectChange } from '@angular/material/select'
import { MatSelectOptionVm } from '@view/matieral/select.view'

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent {
  @Input() floatLabel: FloatLabelType = 'always' //auto' //'always'
  @Input() labelText: string = ''
  @Input() selected: string | null = ''
  @Input() placeholderText: string = ''
  @Input() options: MatSelectOptionVm[] = []
  @Output() changeEvent = new EventEmitter<MatSelectChange>()

  get showSelect(): boolean {
    return this.options.length > 0
  }
  handleSelectChange($event: MatSelectChange) {
    this.changeEvent.next($event)
  }
}
