import { RosterColumnsEnum } from '@page/roster/roster.constants'
import {
  OwlUserTypes,
  RosterInvitationSummaryDto,
  RosterUserValidationDto,
  UserInvitationHistoryDto
} from '@view/pages/roster-page/roster-page.view'
import {
  RosterTableCellClasses,
  RosterTableViewModel
} from '@view/pages/roster-page/roster-table.view'
import {
  GetUserDto,
  StatusOrderForSorting,
  UserDtoProps,
  UserDtoStatus,
  UserRosterStatus
} from '../../../model/user/user.model'
import { IRosterUserVm } from './roster-table-user.view.model'

export class RosterUserVm implements IRosterUserVm {
  role: OwlUserTypes | null = null
  getUserDto: GetUserDto | null = null
  rosterValidationDto: RosterUserValidationDto | null = null
  userInvitationHistoryDto: UserInvitationHistoryDto | null = null
  rosterInvitationSummaryDto: RosterInvitationSummaryDto | null = null
  logicalId: string | undefined = undefined
  id: number | undefined = 0
  name = ''
  status: UserRosterStatus | null = null
  email = ''
  phone = ''
  statusForSorting = 0
  backgroundStyleByColumnEnum = RosterTableViewModel.getBackgroundStyleByColumns()
  /** This function is here to use in a template to get a background style by column enum value. */
  getBackgroundStyle = (column: RosterColumnsEnum): RosterTableCellClasses => {
    return this.backgroundStyleByColumnEnum[column]
  }
  static fromGetUserDto(
    dto: GetUserDto,
    expandedUserType: OwlUserTypes,
    inviteHistoryDto: UserInvitationHistoryDto | null
  ): IRosterUserVm {
    let rosterUserVm = new RosterUserVm()
    rosterUserVm.getUserDto = dto
    rosterUserVm.rosterValidationDto = null
    rosterUserVm.userInvitationHistoryDto = inviteHistoryDto
    rosterUserVm.logicalId = dto[UserDtoProps.logicalId]
    rosterUserVm.backgroundStyleByColumnEnum = RosterTableViewModel.getBackgroundStyleByColumns()
    rosterUserVm.id = dto[UserDtoProps.id]
    rosterUserVm.email = dto[UserDtoProps.email] ?? ''
    rosterUserVm.phone = dto[UserDtoProps.phone] ?? ''
    rosterUserVm.status = dto[UserDtoProps.status] ?? ('' as any)
    rosterUserVm.name = `${dto[UserDtoProps.firstName]} ${dto[UserDtoProps.lastName]}`
    rosterUserVm.role = expandedUserType
    rosterUserVm.statusForSorting = StatusOrderForSorting.indexOf(
      dto[UserDtoProps.status] ?? UserDtoStatus.invitationFailed
    )
    return rosterUserVm
  }
  /** In order to use constructor from user dto, need to bring getuserdto into context, otherwise redo code from fromGetUserDto */
  static getRosterUserVmFromValidationDto(
    dto: RosterUserValidationDto,
    userType: OwlUserTypes
  ): IRosterUserVm {
    let rosterUserVm = new RosterUserVm()

    rosterUserVm.rosterValidationDto = dto
    rosterUserVm.backgroundStyleByColumnEnum = RosterTableViewModel.getBackgroundStyleByDto(dto)
    rosterUserVm.getUserDto = null
    rosterUserVm.logicalId = dto.user[UserDtoProps.logicalId]
    rosterUserVm.id = undefined
    rosterUserVm.name = `${dto.user[UserDtoProps.firstName] ?? ''} ${
      dto.user[UserDtoProps.lastName] ?? ''
    }`.trim()
    rosterUserVm.role = userType
    rosterUserVm.status = dto.status
    rosterUserVm.statusForSorting = StatusOrderForSorting.indexOf(dto.status)
    rosterUserVm.email = dto.user[UserDtoProps.email] ?? ''
    rosterUserVm.phone = dto.user[UserDtoProps.phone] ?? ''
    return rosterUserVm
  }
}
