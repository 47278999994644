import PopupTemplate from '@arcgis/core/PopupTemplate'
import { PostSchoolAreaDto, SchoolAreaDto } from '@model/school/school-subarea.model'
import Graphic from '@arcgis/core/Graphic'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { ArcGisEventHandlers } from './arcgis-view-event-handler.view'
import { ArcGisMapViewHandler } from './map-view/map-view-handler.view'
import { ArcgisSpatialRefence } from './spatial-reference/arcgis-spatial-reference.view'
import { ArcGisMapService } from './arcgis-map.service'
import { ArcGisGraphicAttrFac } from './attributes/arcgis-graphic-attr-fac.view'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'

export class ArcGisPopupView {
  static displayPopupForNewlyCreatedGraphic = (
    context: ArcGisMapService,
    graphic: Graphic,
    showPopup: boolean
  ): [Graphic | undefined, PostSchoolAreaDto | undefined] | null => {
    let dto: PostSchoolAreaDto | undefined = undefined
    if (!context.mapConfigPopupRef) {
      console.warn(`DEV ERROR: Not ref to map config pop up.`)
      return null
    }
    //Project the graphic to the screen before using it to place the popup.
    const projectedGraphicGeom = ArcGisMapProjectionViewModel.projectToSpatialReference(
      graphic.geometry,
      ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    )
    if (showPopup) {
      const copyOfGraphic = graphic.clone()
      copyOfGraphic.geometry = projectedGraphicGeom
      const areaMapConfigVm = ArcGisMapViewHandler.getMapConfigAreaVm(context)
      const newAttributes = ArcGisGraphicAttrFac.getAttributesForNewArea(areaMapConfigVm)
      graphic.attributes = newAttributes
      dto = SchoolAreaModelHelper.getDtoFromAttrAndVm(
        (projectedGraphicGeom as __esri.Polygon).rings[0],
        graphic.attributes,
        areaMapConfigVm
      )
      // console.log(
      //   `Setting graphic for popup isGeographic`,
      //   copyOfGraphic.geometry.spatialReference.isGeographic
      // )
      // console.log(
      //   `Setting graphic for popup isWebMercator`,
      //   copyOfGraphic.geometry.spatialReference.isWebMercator
      // )
      context.mapConfigPopupRef?.setGraphic(copyOfGraphic)
      ArcGisEventHandlers.updatePopupPosition(context.mapConfigPopupRef, context._mapView)
      context.mapConfigPopupRef.setContent(graphic.attributes, areaMapConfigVm)
      context.mapConfigPopupRef.showPopup()
    }
    return [graphic, dto]
  }
  /** Updates the map config edit area popup and returns the associated project geometry. */
  static displayPopupForGraphic = (
    context: ArcGisMapService,
    graphic: Graphic
  ): __esri.Geometry | null => {
    if (!context.mapConfigPopupRef) {
      console.warn(`DEV ERROR: Not ref to map config pop up.`)
      return null
    }
    const projectedGraphicGeom = ArcGisMapProjectionViewModel.projectToSpatialReference(
      graphic.geometry,
      ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    )
    const copyOfGraphic = graphic.clone()
    copyOfGraphic.geometry = projectedGraphicGeom
    // console.log(
    //   `projection of graphic for popup isGeographic`,
    //   copyOfGraphic.geometry.spatialReference.isGeographic
    // )
    // console.log(
    //   `projection of graphic for popup is mercator`,
    //   copyOfGraphic.geometry.spatialReference.isWebMercator
    // )
    context.mapConfigPopupRef?.setGraphic(copyOfGraphic)
    ArcGisEventHandlers.updatePopupPosition(context.mapConfigPopupRef, context._mapView)
    //Every time we update an area we must validate it across all existing area names
    const areaMapConfigVm = ArcGisMapViewHandler.getMapConfigAreaVm(context)
    //Only update content on new area selection or creation
    const areaConfigPopupHasNoAttributes = context.mapConfigPopupRef.areaAttributes === null
    if (
      areaConfigPopupHasNoAttributes ||
      context.mapConfigPopupRef.areaAttributes?.id !== graphic.attributes.id
    ) {
      context.mapConfigPopupRef.setContent(copyOfGraphic.attributes, areaMapConfigVm)
    }
    return projectedGraphicGeom
  }
  /** @deprecated Using custom popup component, if never going to use native popup delete */
  /** @deprecated not using native popup template safe to remove */
  static createPopupTemplate = (areaDto: SchoolAreaDto): PopupTemplate => {
    // const { name, type } = areaDto;
    return new PopupTemplate({
      title: '{name}',
      content: 'Type: {type}',
      outFields: ['*'],
      fieldInfos: [
        {
          fieldName: 'name',
          label: 'Name'
        },
        {
          fieldName: 'type',
          label: 'Type'
        }
      ]
    })
  }
}
