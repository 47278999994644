import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectSelectedSchoolId } from '@selector/auth.selector'
import { selectUrl } from '@selector/route.selector'
import { OwlStateSlices } from '@state/app.state'
import { InstructionsPageState } from '@state/page/instructions-page.state'
import {
  InstructionsStandardVm,
  InstructionsVm
} from '@view/pages/instructions-page/instructions-page.view'
import { selectGroupedPredefinedMessages } from '../dashboard-page/dashboard-page-state.selectors'
import { MessageTypeEnum } from '@model/message/predefined-message.model'

export const selectInstructionsPageState = createFeatureSelector<InstructionsPageState>(
  OwlStateSlices.instructions
)

export const selectInstructions = createSelector(
  selectInstructionsPageState,
  (pageState): InstructionsVm[] => pageState.vm.instructionsToDisplay
)

export const selectCurrentStandard = createSelector(
  selectInstructionsPageState,
  (pageState): InstructionsStandardVm | null => pageState.vm.currentStandard
)

export const selectStandards = createSelector(
  selectInstructionsPageState,
  (pageState): InstructionsStandardVm[] => pageState.vm.instructionsStandards
)

export const selectDefaultStandard = createSelector(
  selectStandards,
  (standards): InstructionsStandardVm | null => standards.find((x) => x.default) ?? null
)

export const selectCurrentStandardOrDefault = createSelector(
  selectCurrentStandard,
  selectDefaultStandard,
  InstructionsPageState.getCurrentSchoolStandard
)

export const selectCurrentStandardTopSectionString = createSelector(
  selectCurrentStandardOrDefault,
  InstructionsPageState.getTopSectionStandardString
)

export const selectPageIsInstructions = createSelector(selectUrl, (url) =>
  url.includes(FirstLevelDir.instructions)
)

export const selectCurrentSchoolAndStandardIdsToUpdate = createSelector(
  selectInstructionsPageState,
  selectSelectedSchoolId,
  InstructionsPageState.getCurrentSchoolAndStandardIdsToUpdate
)

export const selectCurrentPredefinedInstructions = createSelector(
  selectGroupedPredefinedMessages,
  (mes) => mes?.filter((m) => m.messageType === MessageTypeEnum.instruction)
)

export const selectShowUpdateInstructionsUiPayload = createSelector(
  selectInstructionsPageState,
  (pageState) => pageState.vm.showUpdateInstructionsUiPayload
)
export const selectUpdateInstructionsUiIsVisible = createSelector(
  selectShowUpdateInstructionsUiPayload,
  (payload) => payload.show
)
