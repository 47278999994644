import { ChatResponseDtoPayload } from '@model/message.model'
import { DecoratedUserLocationViewModel, UserLocationDto } from '@model/user/user-location.model'
import { TimeUtils } from '@shared/time.utils'

export class LocationDtoHelper {
  /**
   * If the chat message has a location and it's more recent that the last known location, use that to update the location view model, otherwise preserve the last known.
   */
  static shouldUpdateLocationFromMessageDto = (
    existingLocationVm: DecoratedUserLocationViewModel | null,
    payload: ChatResponseDtoPayload
  ): boolean => {
    let shouldUpdateLocationInVm = false
    let newLocTimeStamp = TimeUtils.getDateFromString(payload.timestamp)
    if (!existingLocationVm?.locTimeStamp) {
      shouldUpdateLocationInVm = true
    } else if (existingLocationVm && newLocTimeStamp) {
      if (newLocTimeStamp.getTime() > existingLocationVm.locTimeStamp.getTime()) {
        shouldUpdateLocationInVm = true
      }
    }

    return shouldUpdateLocationInVm
  }
  static isUserLocationDto = (payload: any): payload is UserLocationDto => {
    return payload?.latLong !== undefined
  }
  /** Domain logic about whether we should apply the new location we receive from a location update. We get historic data from messages and locations and we won't know in advance if the messages or locations has the most up to date location data. */
  static shouldUpdateLocation = (
    userLocationVm: DecoratedUserLocationViewModel,
    dto: UserLocationDto
  ): boolean => {
    let newlocTimeStamp: Date | null = null
    if (dto.timestamp) {
      newlocTimeStamp = TimeUtils.getDateFromString(dto.timestamp)
    } else {
      console.warn(`Location dto missing timestamp! Id: ${dto.id}`)
    }
    // Apply the location if the user location vm doesn't have a timestamp
    if (!userLocationVm.locTimeStamp) {
      return true
    }
    // Ignore new locations without a timestamp
    if (!newlocTimeStamp) {
      return false
    }
    const newEpoch = newlocTimeStamp.getTime()
    const existingEpoch = userLocationVm.locTimeStamp.getTime()
    //     console.log(`
    // newEpoch ${newEpoch}
    // existingEpoch ${existingEpoch}
    //     `)
    // Apply the location if the timestamp is newer than the last known location
    if (newEpoch > existingEpoch) {
      return true
    }
    return false
  }
}
