import Graphic from '@arcgis/core/Graphic'
import { Point } from '@arcgis/core/geometry'
import { PictureMarkerSymbol } from '@arcgis/core/symbols'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { MobileUserShownOnMap, MobileUserTypes } from '@model/user/user.model'
import { GraphicAttributesTypeEnum } from '@view/area/graphic-attributes.model'
import { LocationGraphicAttributes } from '@view/area/location.view'
import { ArcGisGeometryHelper } from '../geometry/arcgis-geometry.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisPointFactory } from '../arcgis-point-factory'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'

export namespace ArcGisLocationHandler {
  /**
   *
   * @param context  ArcGisMapService
   * @param mobileUserId  string tells us which user we're handling a new location for
   * @param state  DashboardPageState Source of user's location view model
   * @param schoolOwlState  SchoolStateEnum | null plus USE_SELECTED_AREA_OUTSIDE_LOCATION_DIMMING drives if we apply opacity or not
   * @returns void but has a side effect on the context.opaqueUserIds object to update opacity for a user boolean
   */
  export const handleLocationOpacityLogic = (
    context: ArcGisMapService,
    mobileUserId: string,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ): void => {
    let vm: DecoratedUserLocationViewModel | null = state.userLocationLookup[mobileUserId] ?? null
    if (!vm) {
      console.error(
        `SYSTEM ERROR: Received location update for a user we don't have in our state.userLocationLookup object. Check onboarding user real time logic, for mobile user id ${mobileUserId}!`
      )
      return
    }
    let selectedAreaId: number | null = state.vm.selectedAreaId
    let userInSelectedArea = false
    let pressedPollId = state.vm.pressedPollId
    let applyOpacityBasedOnState = schoolOwlState !== SchoolStateEnum.routine
    let useDimmingLocationFeature =
      context._appConfig.config.FEATURE_FLAGS.USE_SELECTED_AREA_OUTSIDE_LOCATION_DIMMING &&
      applyOpacityBasedOnState
    if (useDimmingLocationFeature && selectedAreaId && vm?.latLong) {
      userInSelectedArea = ArcGisGeometryHelper.isUserInSelectedArea(
        context,
        vm.latLong.lon,
        vm.latLong.lat,
        selectedAreaId
      )
    }
    //If there's a pressed poll we set all location data to opaque
    if (pressedPollId) {
      context.opaqueUserIds[vm.mobileUserId] = true
    } else if (useDimmingLocationFeature) {
      if (selectedAreaId) {
        context.opaqueUserIds[vm.mobileUserId] = !userInSelectedArea
      } else {
        context.opaqueUserIds[vm.mobileUserId] = false
      }
    } else if (!pressedPollId) {
      context.opaqueUserIds[vm.mobileUserId] = false
    }
  }
  /** This function is meant to operate on a single location at a time
   * TODO Add logic to only add a hidden symbol if it's outside the selected area
   * @param opaque determines if the user's location is almost transparent and if the recent message indicator is hidden
   */
  export const handleLocationVm = (
    context: ArcGisMapService,
    locationVm: DecoratedUserLocationViewModel,
    selectedUserMobileId: string | null = null
  ): void => {
    const { userType, mobileUserId, latLong } = locationVm
    //Location vms are built initially from user dtos so don't have a location right away
    if (!latLong) {
      return
    }

    // Only set opacity if it was set due to area selection
    let useOpaqueSymbol = context.opaqueUserIds[locationVm.mobileUserId]

    // console.log(`locationVm`)
    // console.log(locationVm)
    const user_is_student = userType === MobileUserTypes.student
    const user_is_teacher = userType === MobileUserTypes.teacher
    const user_is_otherStaff = userType === MobileUserTypes.otherStaff
    const user_is_guest = userType === MobileUserTypes.guest
    let recentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
      locationVm.recentMessageIndicatorType
    )
    const showRecentMessageIndicatorIfExists = selectedUserMobileId !== mobileUserId
    // TODO incorporate dimming instead of hiding symbol
    // We still update the recent message location point when location updates but we may be hiding the recent message indicator temporarily while the popup is selected
    let conditionalRecentMessageSymbol =
      useOpaqueSymbol || !showRecentMessageIndicatorIfExists
        ? ArcGisSymbolFactory.hiddenSymbol
        : recentMessageSymbol
    let newAttributes: LocationGraphicAttributes = ArcGisGraphicAttrFac.getLocationAttributes(
      locationVm,
      (recentMessageSymbol as PictureMarkerSymbol)?.url
    )

    let recentMessageGraphic: Graphic | undefined
    if (user_is_student) {
      recentMessageGraphic = context._studentRecentMessageLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    }
    if (user_is_teacher) {
      recentMessageGraphic = context._teacherRecentMessageLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    }
    if (user_is_otherStaff) {
      recentMessageGraphic = context._otherStaffRecentMessageLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    }
    if (user_is_guest) {
      recentMessageGraphic = context._guestRecentMessageLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    }
    if (recentMessageGraphic) {
      recentMessageGraphic.geometry.set('latitude', latLong.lat)
      recentMessageGraphic.geometry.set('longitude', latLong.lon)
      recentMessageGraphic.symbol = conditionalRecentMessageSymbol.clone()
      recentMessageGraphic.attributes =
        ArcGisGraphicAttrFac.getRecentMessageIndicatorAttr(newAttributes)
      // console.log(`
      //   Arc gis user location view
      //     Updating
      //       user ${userType}
      //       mobile id ${mobileUserId}
      //       attribute type ${locationVm.recentMessageIndicatorType}
      // `)
      // console.log(
      //   `Updating recent message graphic for user recent message for id ${decoratedUserLocationObj.mobileUserId}`
      // )
    } else {
      // Only add the graphic if it doens't already exist on the map
      recentMessageGraphic = new Graphic({
        geometry: ArcGisPointFactory.getPoint(latLong.lon, latLong.lat),
        symbol: conditionalRecentMessageSymbol,
        attributes: ArcGisGraphicAttrFac.getRecentMessageIndicatorAttr(newAttributes)
      })
      if (user_is_student) {
        context._studentRecentMessageLayer?.graphics.add(recentMessageGraphic)
      }
      if (user_is_teacher) {
        context._teacherRecentMessageLayer?.graphics.add(recentMessageGraphic)
      }
      if (user_is_otherStaff) {
        context._otherStaffRecentMessageLayer?.graphics.add(recentMessageGraphic)
      }
      if (user_is_guest) {
        context._guestRecentMessageLayer?.graphics.add(recentMessageGraphic)
      }
      // console.log(
      //   `Adding recent message graphic for user recent message for id ${decoratedUserLocationObj.mobileUserId}`
      // )
    }

    let locationGraphic: Graphic | undefined
    const isSelected = mobileUserId === selectedUserMobileId
    let locationSymbol: __esri.SimpleMarkerSymbol | __esri.PictureMarkerSymbol | null = null
    if (user_is_student) {
      if (!useOpaqueSymbol) {
        locationSymbol = isSelected
          ? ArcGisSymbolFactory.studentLocationSymbolSelected
          : ArcGisSymbolFactory.studentLocationSymbol
      } else {
        locationSymbol = ArcGisSymbolFactory.studentLocationSymbolOpaque
      }
      locationGraphic = context._studentLocationLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    } else if (user_is_teacher) {
      if (!useOpaqueSymbol) {
        locationSymbol = isSelected
          ? ArcGisSymbolFactory.teacherLocationSymbolSelected
          : ArcGisSymbolFactory.teacherLocationSymbol
      } else {
        locationSymbol = ArcGisSymbolFactory.teacherLocationSymbolOpaque
      }
      locationGraphic = context._teacherLocationLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    } else if (user_is_otherStaff) {
      if (!useOpaqueSymbol) {
        locationSymbol = isSelected
          ? ArcGisSymbolFactory.otherStaffLocationSymbolSelected
          : ArcGisSymbolFactory.otherStaffLocationSymbol
      } else {
        locationSymbol = ArcGisSymbolFactory.otherStaffLocationSymbolOpaque
      }
      locationGraphic = context._otherStaffLocationLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    } else if (user_is_guest) {
      if (!useOpaqueSymbol) {
        locationSymbol = isSelected
          ? ArcGisSymbolFactory.guestLocationSymbolSelected
          : ArcGisSymbolFactory.guestLocationSymbol
      } else {
        locationSymbol = ArcGisSymbolFactory.guestLocationSymbolOpaque
      }
      locationGraphic = context._guestLocationLayer?.graphics.find(
        (g) => g.attributes?.id === mobileUserId
      )
    }
    if (!locationSymbol) {
      console.error(`DEV ERROR: No location symbol for user.`)
      return
    }
    if (locationGraphic) {
      locationGraphic.geometry.set('latitude', latLong.lat)
      locationGraphic.geometry.set('longitude', latLong.lon)
      locationGraphic.symbol = locationSymbol.clone()
      locationGraphic.attributes = { ...newAttributes, kind: GraphicAttributesTypeEnum.location }
      locationGraphic.clone()
    } else {
      const locationGraphic = new Graphic({
        geometry: ArcGisPointFactory.getPoint(latLong.lon, latLong.lat),
        symbol: locationSymbol,
        attributes: { ...newAttributes, kind: GraphicAttributesTypeEnum.location }
      })
      if (user_is_student) {
        context._studentLocationLayer?.graphics.add(locationGraphic)
      }
      if (user_is_teacher) {
        context._teacherLocationLayer?.graphics.add(locationGraphic)
      }
      if (user_is_otherStaff) {
        context._otherStaffLocationLayer?.graphics.add(locationGraphic)
      }
      if (user_is_guest) {
        context._guestLocationLayer?.graphics.add(locationGraphic)
      }
      // console.log(
      //   `Adding location graphic for user recent message for id ${decoratedUserLocationObj.mobileUserId}`
      // )
    }

    // update popup position if the location update is for context graphic
    if (locationGraphic && context.popupRef != null && mobileUserId === selectedUserMobileId) {
      context.popupRef.graphic = locationGraphic
      context.popupRef.setContent(locationGraphic.attributes)
      if (context.popupRef?.isShownDueToClick) {
        ArcGisEventHandlers.updatePopupPosition(context.popupRef, context._mapView)
      }
    }
    //If the location moves while we're hovering over the location, we need to decide what to do
    // For now close the popup
    if (context.lastHoveredUserId === mobileUserId && !context.popupRef?.isShownDueToClick) {
      context.popupRef?.hidePopup()
    }
  }

  export const handleHidePopapAndRemoveGraphic = (
    context: ArcGisMapService,
    locationLayer: GraphicsLayer | undefined,
    recentMessageLayer: GraphicsLayer | undefined,
    mobileUserId: string
  ): void => {
    if (!locationLayer || !recentMessageLayer) {
      console.warn(`DEV ERROR: locationLayer or recentMessageLayer is undefined`)
      return
    }
    const locationGraphic = locationLayer?.graphics.find((g) => g.attributes?.id === mobileUserId)
    if (!locationGraphic) {
      //Use's location graphic has already been removed
      return
    }
    const recentMessageGraphic = recentMessageLayer?.graphics.find(
      (g) => g.attributes?.id === mobileUserId
    )
    if (locationGraphic) {
      locationLayer.graphics.remove(locationGraphic)
    }
    if (recentMessageGraphic) {
      recentMessageLayer.graphics.remove(recentMessageGraphic)
    }
    if (context.lastHoveredUserId === mobileUserId) {
      context.lastHoveredUserId = null
    }
    if (context.lastClickedUserId === mobileUserId && context.popupRef?.isShownDueToClick) {
      context.popupRef?.hidePopup()
    }
  }

  export const handleRemoveLocationVm = (
    context: ArcGisMapService,
    locationVm: DecoratedUserLocationViewModel
  ) => {
    const { userType, mobileUserId, latLong } = locationVm
    //Location vms are built initially from user dtos so don't have a location right away
    if (!latLong) {
      return
    }

    switch (userType) {
      case MobileUserTypes.student:
        handleHidePopapAndRemoveGraphic(
          context,
          context._studentLocationLayer,
          context._studentRecentMessageLayer,
          mobileUserId
        )
        break
      case MobileUserTypes.teacher:
        handleHidePopapAndRemoveGraphic(
          context,
          context._teacherLocationLayer,
          context._teacherRecentMessageLayer,
          mobileUserId
        )
        break
      case MobileUserTypes.otherStaff:
        handleHidePopapAndRemoveGraphic(
          context,
          context._otherStaffLocationLayer,
          context._otherStaffRecentMessageLayer,
          mobileUserId
        )
        break
      case MobileUserTypes.guest:
        handleHidePopapAndRemoveGraphic(
          context,
          context._guestLocationLayer,
          context._guestRecentMessageLayer,
          mobileUserId
        )
        break
    }
  }
  /**
   * If no area value toggle visibility to no opacity
   * If area use geom engine on each location graphic
   * @param payload with @value of null implies reset the graphic to the normal symbol
   * @returns boolean that indicates if item should be hidden on recent message layer
   */
  export const handleOpacityToggle = (
    context: ArcGisMapService,
    g: Graphic,
    payload: number | null,
    userType: MobileUserShownOnMap
    // PROXIMITY_DISTANCE_IN_METERS: number = 0
  ): boolean => {
    if (payload === null || payload === 0) {
      g.symbol = ArcGisSymbolFactory.locationSymbolByUserType[userType]
      context.opaqueUserIds[g.attributes.id] = false
      return false
    }
    const point = g.geometry as Point
    const inArea = ArcGisGeometryHelper.isUserInSelectedArea(
      context,
      point.longitude,
      point.latitude,
      payload
      // PROXIMITY_DISTANCE_IN_METERS
    )
    if (inArea && g?.attributes?.kind === GraphicAttributesTypeEnum.location) {
      // console.log(`${g.attributes.fullName} is in area`)
      //If they're in the area we need to know if they are hovered or clicked
      context.opaqueUserIds[g.attributes.id] = false
      g.symbol = ArcGisSymbolFactory.locationSymbolByUserType[userType].clone()
      // Perhaps this isn't needed as selected an area implies deselecting a user TODO Determine requirements
      if (
        context.lastClickedUserType === userType &&
        context.lastClickedUserId === g.attributes.id
      ) {
        g.symbol = ArcGisSymbolFactory.selectedLocationSymbolByUserType[userType].clone()
      }
      return false
    } else {
      // console.log(`${g.attributes.fullName} is NOT in area`)
      g.symbol = ArcGisSymbolFactory.opacitySymbolByUserType[userType].clone()
      context.opaqueUserIds[g.attributes.id] = true
      return true
    }
  }
}
