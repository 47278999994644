export enum BtnColorVariants {
  primary = 'primary',
  primaryVariant = 'primary-variant',
  secondary = 'secondary',
  secondaryVariant = 'secondary-variant'
}
export enum ButtonWidth {
  fullWidth = 'full-width',
  fitContent = 'fit-content'
}
export enum ButtonIconPlacement {
  left = 'left',
  right = 'right'
}
export enum ButtonHeight {
  small = 'small',
  default = 'default',
  large = 'large',
}
export enum BtnBorderRadius {
  default = 'default',
  small = 'small',
  large = 'large'
}
