export namespace TimeUtils {
  export const getQueryString = (d: Date): string => {
    return `&start=${d.toISOString()}`
  }
  export const getDateFromString = (s: unknown): Date | null => {
    if (typeof s === 'string') {
      return getDateFromStringOrNull(s)
    } else {
      return null
    }
  }
  const getDateFromStringOrNull = (s: string): Date | null => {
    const date = new Date(s)
    if (!date.getTime()) {
      return null
    } else {
      return date
    }
  }
  /**
   *
   * @param d Assume that all passed in d params are in UTC time
   * @returns time since - with unit of measurement ex: 4 seconds
   */
  //Changes according new design
  export const getTimeSinceStringFromDate = (d: Date): string | null => {
    const now = new Date(new Date().toUTCString())
    const diffInMilliseconds = now.getTime() - d.getTime()
    if (diffInMilliseconds < 0) {
      return null
    }

    const seconds = Math.round(diffInMilliseconds / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)
    const weeks = Math.round(days / 7)
    const months = Math.round(days / 30)
    const years = Math.round(days / 365)
    if (seconds === 0) {
      return 'now'
    } else if (seconds < 60) {
      return `${seconds}s`
    } else if (minutes < 60) {
      return `${minutes}m`
    } else if (hours < 24) {
      return `${hours}h`
    } else if (days < 7) {
      return `${days}d`
    } else if (weeks < 4) {
      return `${weeks}w`
    } else if (months < 12) {
      return `${months}mth`
    } else {
      return `${years}y`
    }
  }
  export const getTimeUnitPluralityString = (n: number): string => (n === 1 ? '' : 's')

  //Utils for working with timestamp objects
  /** null return indicates one or both strings weren't dates. */
  export const firstTimestampIsAfterSecond = (s1: string, s2: string): boolean | null => {
    const dateS1 = TimeUtils.getDateFromString(s1)
    const dateS2 = TimeUtils.getDateFromString(s2)
    if (!dateS1 || !dateS2) {
      return null
    }
    return dateS1.getTime() > dateS2.getTime()
  }
  export const firstDateIsAfterSecond = (d: Date, d2: Date): boolean => {
    return d.getTime() > d2.getTime()
  }
  /**
   *
   * @param timestamp - the timestamp to compare
   * @param epochSeconds - the number of seconds to compare against
   * @returns true if the timestamp is older than the epochSeconds
   */
  export const timestampExceedsEpochSeconds = (
    timestamp: Date | unknown,
    epochSeconds: number
  ): boolean => {
    if (!(timestamp instanceof Date)) {
      return false
    }
    const now = new Date()
    const diff = now.getTime() - timestamp.getTime()
    if (diff > epochSeconds * 1000) {
      return true
    } else {
      return false
    }
  }
}
