import { getExtendedAction } from '@action/extended-ngrx-action'
import { threatIndicatorDimmed } from '@action/user/dashboard-page.action'
import { Polygon } from '@arcgis/core/geometry'
import { LatLon } from '@model/location.model'
import { ChatResponseDtoPayload } from '@model/message.model'
import { GetResponseDto, ResponseTypeEnum } from '@model/message/predefined-message.model'
import { ThreatModelConfig } from '@service/app-config/app-config.model'
import { isLocalHost } from '@shared/js-window'
import { TimeUtils } from '@shared/time.utils'
import {
  IThreatItemViewModel,
  ProcessThreatVmOptions,
  ThreatItemViewModel,
  ThreatSourceEnum
} from '@view/area/threat-area.view'
import { ArcGisMapService } from './arcgis/arcgis-map.service'
import { ArcGisPointFactory } from './arcgis/arcgis-point-factory'
import { ArcGisGeometryHelper } from './arcgis/geometry/arcgis-geometry.view'

export enum Quadrant {
  topLeft,
  topRight,
  bottomRight,
  bottomLeft
}
/** Requirements to receive alert and when two, draw a line, and expand by school config property proximityDistanceForAttackerAreaInFeet feet. TODO Verify that alerts and threat indicators should be treated the same and standardize polygon around points and expand by proximityDistanceForAttackerAreaInFeet property.*/
export class OwlThreatModel {
  /** If we only have one point or the points we do have cause a lat or lon radius to be smaller than this, use this instead. */
  readonly MIN_THREAT_AREA_RADIUS = 0.00009
  /** Updated during construction of model from config */
  private _THREAT_POINT_BUFFER: number | null = null
  private _MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS: number = 0

  threatModelVms!: IThreatItemViewModel[]

  //Local computation aggregation variables
  lonForCenterOfThreat!: number
  latForCenterOfThreat!: number
  minLat!: number
  maxLat!: number
  maxLon!: number
  minLon!: number
  /** Count of poll response ThreatSourceEnum */
  numberOfThreatIndicators!: number

  /** Mutated on recalculation and represent lat long outlining the threat area oval */
  perimeterOfThreatGraphic!: number[][]

  constructor(model: ThreatModelConfig) {
    const {
      THREAT_AREA_UPDATE_INTERVAL_SECONDS,
      MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS,
      THREAT_POINT_BUFFER
    } = model
    this._setInitialValues()
    this.numberOfThreatIndicators = 0
    this.threatModelVms = []
    this._THREAT_POINT_BUFFER = THREAT_POINT_BUFFER
    this._MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS = MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS
  }

  private _setInitialValues = () => {
    this.lonForCenterOfThreat = 0
    this.latForCenterOfThreat = 0
    this.minLat = 0
    this.maxLat = 0
    this.maxLon = 0
    this.minLon = 0
  }
  //#region
  //PUBLIC API
  /** Reset the internal data to prep for aggregating new data. */
  concludeEvent = (context: ArcGisMapService) => {
    this._setInitialValues()
    context._threatLocationLayer?.removeAll()
  }
  /** Call only on app load */
  setByHistoricValues = (
    context: ArcGisMapService,
    responseIdToGetResponseDto: Record<number, GetResponseDto>,
    dtos: ChatResponseDtoPayload[]
  ): void => {
    dtos.forEach((dto) =>
      this._updateThreatVmsWithThreatIndicators(dto, responseIdToGetResponseDto)
    )
    const options: ProcessThreatVmOptions = {
      historicProcessing: true
    }
    this._processVms(context, options)
    this._calculatePerimterOfHull()
    this._renderGraphic(context)
  }
  /** Call on real time event handling */
  updateThreatGraphicWithPayload = (
    context: ArcGisMapService,
    payload: ChatResponseDtoPayload,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ) => {
    if (!payload?.latLong) {
      console.warn(`Received alert without location! ${payload?.logicalId}`)
      return
    }
    this._setInitialValues()
    this._updateThreatVmsWithThreatIndicators(payload, responseIdToGetResponseDto)
    this._processVms(context)
    this._calculatePerimterOfHull()
    this._renderGraphic(context)
  }

  clearStaleThreatVmsAndUpdate = (d: Date, context: ArcGisMapService) => {
    const newList = ThreatItemViewModel.filterByDate(this.threatModelVms, d)
    this.threatModelVms = newList
    this._setInitialValues()
    this._processVms(context)
    this._calculatePerimterOfHull()
    this._renderGraphic(context)
    this.threatModelLog(
      `clearStaleThreatVmsAndUpdate this.numberOfThreatIndicators ${this.numberOfThreatIndicators}`
    )
  }
  reprocessThreatVmsForEventLoop = (
    context: ArcGisMapService,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): string[] => {
    let removedIds: string[]
    if (this.threatModelVms.length === 0) {
      return []
    }
    this._setInitialValues()
    removedIds = this._processVms(context, responseIdToGetResponseDto)
    this._calculatePerimterOfHull()
    this._renderGraphic(context)
    return removedIds
  }
  //#endregion
  private _logState = () => {
    this.threatModelLog(`
    this.numberOfThreatIndicators ${this.numberOfThreatIndicators}
    number of vms = ${this.threatModelVms.length}
    `)
    this.threatModelLog(this.perimeterOfThreatGraphic)
    this.threatModelLog(this.perimeterOfThreatGraphic)
  }
  private _renderGraphic = (context: ArcGisMapService) => {
    if (this.hasMoreThanTwoThreatVms()) {
      const graphic = ArcGisGeometryHelper.getThreatGraphic(this.perimeterOfThreatGraphic)
      context._threatLocationLayer?.removeAll()
      context._threatLocationLayer?.graphics.add(graphic)
      this._logState()
    } else if (this.hasOneOrTwoThreatVms()) {
      this._updateThreatGraphicOneOrTwoLocations(context)
      this._logState()
    } else {
      context._threatLocationLayer?.removeAll()
      this._logState()
      // console.info(`No threat graphic to render`)
    }
  }

  private _calculateMinMaxLatLong = (latLong: LatLon) => {
    const { lon, lat } = latLong
    if (lon < this.minLon) {
      this.minLon = lon
    } else if (lon > this.maxLon) {
      this.maxLon = lon
    }
    if (lat < this.minLat) {
      this.minLat = lat
    } else if (lat > this.maxLat) {
      this.maxLat = lat
    }
  }
  /**
   *  Process the threat model vms to update the local state by filtering by the upper bound in threat indicator time
   *  update the map related display of each alert view model
   * @param context ArcGisMapService
   * @returns string[] of removed ids
   */
  private _processVms = (
    context: ArcGisMapService,
    overrideDefaultOptions: ProcessThreatVmOptions = {}
  ): string[] => {
    let options: ProcessThreatVmOptions = {
      historicProcessing: false,
      ...overrideDefaultOptions
    }
    const { historicProcessing } = options
    this.numberOfThreatIndicators = 0
    const removedIds: string[] = []
    // Remove any vms that are older than the threshold
    const onlyThreatModelVms = this.threatModelVms.filter(this.filterVmForZonePredicate)
    this.threatModelVms = this.threatModelVms.filter(
      (vm: IThreatItemViewModel, index: number, array: IThreatItemViewModel[]) => {
        const { timestamp } = vm
        const isLast = index === array.length - 1
        const isLastThreatForZone = onlyThreatModelVms.at(-1)?.logicalId === vm.logicalId
        const tooOld = this.timestampExceedsMaxAge(timestamp)
        // Always include the most recent threat indicator vm
        const shouldRemoveIdFromVmCollection = tooOld && !isLast && !isLastThreatForZone
        if (shouldRemoveIdFromVmCollection) {
          this.threatModelLog(`Marking id ${vm.logicalId} as too old with timestamp ${timestamp}`)
          removedIds.push(vm.logicalId)
        }
        // Whether it's the last one or not we want the area status vm to recalculate
        //if a threat indicator becomes too old, but only dispatch if it's not a historic processing call
        if (!historicProcessing && tooOld) {
          // This gets called continuously, so we need to find a way to keep the last threat indicator around while also dispatching it when it's too old but not when it's been removed already.
          // Need to get a ref to area id to the alerts
          const point = ArcGisPointFactory.getPointFromThreatVm(vm)
          if (!context._schoolAreaLayer) {
            console.warn(`No school area layer to dim threat indicator`)
          } else {
            const areaId = ArcGisGeometryHelper.getAreaIdForPoint(context._schoolAreaLayer, point)
            if (areaId) {
              const action = getExtendedAction({
                areaId,
                threatVm: vm
              })
              context.store.dispatch(threatIndicatorDimmed(action))
            }
          }
        }

        return !shouldRemoveIdFromVmCollection
      }
    )
    this.threatModelVms.forEach((vm, index) => {
      if (index === 0) {
        this._setValuesForFirstLocation(vm.latLong)
      }
      this._updateAggregatesFromVm(vm)
    })
    return removedIds
  }
  private _setValuesForFirstLocation = (latLong: LatLon) => {
    const { lon, lat } = latLong
    this.minLat = lat
    this.maxLat = lat
    this.maxLon = lon
    this.minLon = lon
  }
  private _updateThreatVmsWithThreatIndicators = (
    p: ChatResponseDtoPayload,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ) => {
    let vm: IThreatItemViewModel | null = null
    const { isSos, latLong, responseId } = p
    this.threatModelLog(`Processing threat payload with:
    isSos: ${isSos}
    responseId: ${responseId}
    lat ${latLong?.lat}
    lon ${latLong?.lon}
    `)
    if (!latLong) {
      return
    }
    if (responseId) {
      const responseIdType = responseIdToGetResponseDto[responseId]?.type ?? null
      if (!responseIdType) {
        console.warn(`getResponseDto Type not found for responseId ${responseId}`)
      }
      const isForZoneCalculation = responseIdType === ResponseTypeEnum.threatIndicatorWithZone
      vm = ThreatItemViewModel.getVmFromMessageWithResponseId(p, responseId, isForZoneCalculation)
    } else if (isSos && !responseId) {
      // Here for Balcony reverse compatibility, that object won't have a response id
      vm = ThreatItemViewModel.getVmFromAlertPayload(p)
    }
    if (!vm) {
      console.error(
        `Threat indicator logic flawed, must either have response id or is sos of true!`
      )
      return
    }
    this.threatModelVms.push(vm)
  }
  /** Accepts a unified from dto payloads vm and aggregates needed data in a local reference to later use to generate circle points, and then the related graphic. */
  private _updateAggregatesFromVm = (vm: IThreatItemViewModel) => {
    const { lat, lon } = vm.latLong
    this._calculateMinMaxLatLong(vm.latLong)
    this.lonForCenterOfThreat += lon
    this.latForCenterOfThreat += lat
    this.numberOfThreatIndicators += 1
  }
  /** Function mutates local state by resetting circle points and recalculating them from the aggregated lat lon measurments */
  private _calculatePerimterOfHull = (): void => {
    //Reset the circle points on each calculation
    this.perimeterOfThreatGraphic = []
    if (this.threatModelVms.length === 0) {
      return
    }
    if (!this._THREAT_POINT_BUFFER) {
      console.error(`THREAT_POINT_BUFFER missing in configuration`)
      return
    } else {
      this.threatModelLog(`Creating convex hull from threat model vms`, this.threatModelVms)
    }
    const points = this.getThreatZonePoints()
    if (!points.length) {
      return
    }
    let convexHull = ArcGisGeometryHelper.getBufferedConvexHull(points, this._THREAT_POINT_BUFFER)
    if (convexHull != null && (convexHull as any).type === 'polygon') {
      this.perimeterOfThreatGraphic = (convexHull as Polygon).rings[0]
      this.threatModelLog(convexHull)
      this.threatModelLog(`numer of vms in threat model ${this.threatModelVms.length}`)
      this.threatModelLog(`number of hull points ${this.perimeterOfThreatGraphic.length}`)
      return
    } else {
      console.error(`Threat vms exist but couldn't create convex hull from them!`)
    }
  }
  /** Go from one or two points directly to a convex hull without creating a polygon */
  private _updateThreatGraphicOneOrTwoLocations = (context: ArcGisMapService): void => {
    context._threatLocationLayer?.removeAll()
    if (!this._THREAT_POINT_BUFFER) {
      console.error(`THREAT_POINT_BUFFER missing in configuration`)
      return
    }
    const oneOrTwoPointHull = ArcGisGeometryHelper.getBufferedConvexHull(
      this.getThreatZonePoints(),
      this._THREAT_POINT_BUFFER
    )
    if (oneOrTwoPointHull) {
      const graphic = ArcGisGeometryHelper.getThreatGraphic((oneOrTwoPointHull as any).rings[0])
      context._threatLocationLayer?.graphics.add(graphic)
    }
  }
  /** This specifies which kind of threat view models can be used for the threat area graphic */
  private getThreatZonePoints = (): __esri.Point[] => {
    return this.threatModelVms.filter(this.filterVmForZonePredicate).map((vm) => vm.point)
  }
  /**
   * Checks the timestamp on the vm and
   * if it's older than this._MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS then return false
   * otherwise return true
   */
  timestampExceedsMaxAge = (timestamp: Date): boolean => {
    return TimeUtils.timestampExceedsEpochSeconds(
      timestamp,
      this._MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS
    )
  }
  filterVmForZonePredicate = (vm: IThreatItemViewModel): boolean =>
    vm.type === ThreatSourceEnum.threatIndicatorMobileResponse && !!vm?.forZoneCalculation

  /**
   * @returns true if more than 2 threat model items where
   *  forZoneCalculation is true and
   * type = ThreatSourceEnum.threatIndicatorMobileResponse
   *
   * */
  hasMoreThanTwoThreatVms(): boolean {
    return this.threatModelVms.filter(this.filterVmForZonePredicate).length > 2
  }
  /**
   *
   * @returns true if there's one or two threat model items where
   *  forZoneCalculation is true and
   * type = ThreatSourceEnum.threatIndicatorMobileResponse
   *
   */
  hasOneOrTwoThreatVms(): boolean {
    const threatVms = this.threatModelVms.filter(this.filterVmForZonePredicate)
    return threatVms.length === 1 || threatVms.length === 2
  }
  shouldLog = false
  // Should take in all params as console log does
  threatModelLog = (
    m: any,
    ...optionalParams: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    if (isLocalHost() && this.shouldLog) {
      console.log(m, optionalParams)
    }
  }
}
