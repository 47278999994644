//Angular
import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'

//Rxjs
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  Subject,
  throwError
} from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'

//Models
import { IAuthInterceptorService } from './auth-interceptor.model'
import { AuthService } from '../auth/auth.service'
import { AppConfigService } from '../app-config/app-config.service'
import { Store } from '@ngrx/store'
import { redirectAngularRouter } from '@action/redirect.action'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { FirstLevelDir, OwlRoutes } from '@domain/route/app-routes.domain'
import { SessionStorageKeys } from '@service/browser/base-storage.service'

@Injectable()
export class AuthInterceptorService implements IAuthInterceptorService, HttpInterceptor {
  /**
   * Here to keep an async state indicator of msal token refresh process
   *  only here to enable usage of pub/sub design pattern and the emitted value is ignored, only that it is emitted is what is important */
  refreshTokenInProgress = false

  /** Awaiting new access token, so hold all 401 expired token calls until further notice*/
  tokenRefreshedSource = new Subject<unknown>()

  /** Observable created out of tokenRefreshedSource to be subscribed to while awaiting new access token*/
  newTokenObservable = this.tokenRefreshedSource.asObservable()

  constructor(
    private store: Store,
    private authService: AuthService,
    private appConfig: AppConfigService
  ) {}

  /**
   *
   * @param request of HttpRequest<any> type so that any request body can be handled
   * @returns same HttpRequest with same body but with updated Bearer header
   * If request url requires authentication, checks authservice and if auth service has token, the token is added to the header
   */
  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const apiUrl = this.appConfig?.config?.API_URL
    if (apiUrl && request.url.indexOf(apiUrl) > -1) {
      if (this.authService.getAccessToken()) {
        const modified = request.clone({
          setHeaders: this.authService.getAuthHeaders()
        })
        request = modified
      }
    }
    return request
  }
  /**
   *
   * @param res Passes response caught by the intercept method inside serviec implementing HTTPInterceptor
   * @param caught Not use in this function but
   * @param request
   * @param next
   * @returns
   */
  handleAuthExceptions(
    res: HttpErrorResponse,
    caught: Observable<HttpEvent<any>>,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.log(`AuthInterceptor 
        handleAuthExceptions error caught:`)
    if (res.status === 500) {
      this.store.dispatch(
        redirectAngularRouter(
          getExtendedAction(OwlRoutes.firstLevelRoutes[FirstLevelDir.serverError])
        )
      )
    }
    if (res.status === 401) {
      this.log(`
        AuthInterceptor
            handleResponseErrorWhenUserFullyAuthed
            Expired token, starting auth flow in auth interceptor, 
            and pausing network comms, until we have a new token
        `)
      //get new token and then redo service calls
      return this.triggerRefreshAccessTokenFlow().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request)
          return next.handle(request)
        }),
        catchError<any, ObservableInput<any>>((error: HttpErrorResponse): Observable<never> => {
          this.authService.logout()
          return EMPTY
        })
      )
    } else if (res.status === 403) {
      this.log(`Interceptor caught access denied from server!.`)
      //If we redirect to unauthorized page the saved url needs to be cleared to prevent later redirection back to same unauthorized page
      sessionStorage.removeItem(SessionStorageKeys.initUrlRequestKey)
      // TODO Get designs for a access denied type of path, in case user manipulates the URL to /admin but they're not the OWL admin, just a teacher
      //Send user to access denied page
      // this.authService.redirectToForbiddenPage()
    }
    //if we caught another error that's not a 401 unauthorized or 403 forbidden, pass to the error handler
    return throwError(res)
  }

  /** Main HTTP interceptor function that adds token to headers for api calls, and handles any 401 errors to renew the token */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request)

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse, caught: Observable<HttpEvent<Error>>) => {
        return this.handleAuthExceptions(error, caught, request, next)
      })
    )
  }

  /** Handles an internal observable that freezes all other nework calls until we have a new token */
  triggerRefreshAccessTokenFlow(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer: any) => {
        this.newTokenObservable.subscribe(() => {
          observer.next()
          observer.complete()
        })
      })
    } else {
      this.refreshTokenInProgress = true
      return this.authService.startAuthFlow().pipe(
        tap((next: any): MonoTypeOperatorFunction<any> => {
          this.refreshTokenInProgress = false
          this.tokenRefreshedSource.next({})
          return next
        }),
        catchError((err: any, caught: Observable<any>): ObservableInput<any> => {
          this.refreshTokenInProgress = false
          this.authService.redirectToLoginRedirectPage()
          return EMPTY
        })
      )
    }
  }

  /** TODO Extract to logging service with log area flags */
  log = (m: string) => {
    console.log(m)
  }
}
