import { createAction, props } from '@ngrx/store'
import { IToastViewModel } from '@view/chat/auto-sent-message-snackbar.view'
import { ExtendedAction } from './extended-ngrx-action'
import { SignalrEventProps } from '@service/logging/logging.model'
import { IAppConfig } from '@service/app-config/app-config.model'

export enum GlobalAppActionsEnum {
  //Trigger Token Renewal
  pingServer = '[App Actions] Ping Server to Validate Token Lifetime',
  //Logging
  logAppEvent = '[App Actions] Log App Event',
  //Toast
  handleToastVm = '[App Actions] Handle Toast Vm',
  showSingleToast = '[App Actions] Show Single Toast',
  enqueueToast = '[App Actions] Enqueue Toast',
  decrementToastInQueue = '[App Actions] Decrement Toasts in queue',
  dispatchAppConfig = '[App Actions] Dispatch App Config',

  toggleProfile = '[App Actions] Toggle Profile',

  showActivityTimeOutModal = '[App Actions] Show Activity TimeOut Modal',
  setScrollVisibility = '[App Actions] Set Scroll Visibility',
  downloadSignups = '[App Actions] Download Signups',
  invitePilotUsers = '[App Actions] Invite Pilot Users'
}
//Logging
export const pingServer = createAction(GlobalAppActionsEnum.pingServer)
export const logSignalrEvent = createAction(
  GlobalAppActionsEnum.logAppEvent,
  props<ExtendedAction<SignalrEventProps>>()
)
export const logEvent = createAction(
  GlobalAppActionsEnum.logAppEvent,
  props<ExtendedAction<Object>>()
)
//Toast - move to app actions
export const handleToastVm = createAction(
  GlobalAppActionsEnum.handleToastVm,
  props<ExtendedAction<IToastViewModel>>()
)
export const showSingleToast = createAction(
  GlobalAppActionsEnum.showSingleToast,
  props<ExtendedAction<IToastViewModel>>()
)
export const enqueueToast = createAction(
  GlobalAppActionsEnum.enqueueToast,
  props<ExtendedAction<IToastViewModel>>()
)
export const decrementToastInQueue = createAction(GlobalAppActionsEnum.decrementToastInQueue)
export const dispatchAppConfig = createAction(
  GlobalAppActionsEnum.dispatchAppConfig,
  props<ExtendedAction<IAppConfig>>()
)
export const toggleProfile = createAction(GlobalAppActionsEnum.toggleProfile)

/** Payload of true hides scroll, payload of false shows it. */
export const setScrollVisibility = createAction(
  GlobalAppActionsEnum.setScrollVisibility,
  props<ExtendedAction<boolean>>()
)

export const showActivityTimeOutModal = createAction(GlobalAppActionsEnum.showActivityTimeOutModal)

export const downloadPilotUserSignups = createAction(GlobalAppActionsEnum.downloadSignups)
export const invitePilotUsers = createAction(GlobalAppActionsEnum.invitePilotUsers)