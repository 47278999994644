import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '@component/shared/modal/base-modal.component';
import { WelcomePageComponentService } from '@page/public/welcome-page/welcome-page.component.service';
import { FormButtonsViewModel, ModalViewModel } from '@view/matieral/modal.view';

@Component({
  selector: 'app-pilot-user-sign-up-form',
  templateUrl: './pilot-user-sign-up-form.component.html',
  styleUrls: ['./pilot-user-sign-up-form.component.scss']
})
export class PilotUserSignUpFormComponent extends BaseModalComponent<PilotUserSignUpFormComponent> {
  buttonsVm = FormButtonsViewModel
  constructor(
    public dialogRef: MatDialogRef<PilotUserSignUpFormComponent>,
    @Inject(MAT_DIALOG_DATA) public vm: ModalViewModel,
    public pageCompServ: WelcomePageComponentService
  ) {
    super(dialogRef, vm)
    this.pageCompServ.init()
  }
}
