import { MobileUserTypes, MobileUserTypesForPollInteractions } from '@model/user/user.model'
import { StatusColorEnum } from '@view/area/area.view'
import { ILocationVisibilityViewModel } from '@view/map/locations/map-control-ui.view'

export class PollAggregatedResultVm {
  constructor(
    public pollResponseId: number = 0,
    /** This will be taken from state by the poll id. */
    public answer: string = '',
    /** This will either come from the predefined poll response or use a list of 1 of 8 neutral poll colors */
    public color: StatusColorEnum | string = '',
    /** This is the number of users that responded with this poll id. */
    public count: number = 0
  ) {}
}

export class ChatPollViewModel {
  /** This is the primary key in our database of the predefined poll, must exist in order for feature to work */
  predefinedPollId?: number

  /** This is the sent poll instance id */
  logicalPollId?: string

  /** This is the question from predefined messages by logical poll id. */
  question: string = ''

  aggregatedResultItem: PollAggregatedResultVm[] = []

  /** If we send to area, we won't know total count of audience, but if we have a list of user ids, then we can display % responded. */
  respondentsNumber: number = 0
  totalCount: number = 0
  notAnsweredCount: number = 0

  notAnsweredColore: string = ''
  autoSent: boolean = false
}
/** Container in case additional logic is needed for poll interactions */
export interface IPollInteractionViewModel {
  pollId: string
  hover: boolean
}
export class PollInteractionViewModel {
  static getPollInteractionVm = () => {}
  /** Logic that combines location vm by user type, the user types associated to a poll's responses and the poll interaction vm that let's us know if the user is hovering over a specific poll. */
  static getShowNoVisibleUsersOnMapTooltipForPoll = (
    locationVisibilityVm: ILocationVisibilityViewModel,
    userTypesIncludedInPollReponses: Record<string, MobileUserTypes[]>,
    pollInteractionVm: IPollInteractionViewModel | null
  ) => {
    // Always hide the tooltip if user isn't hovering over a poll, which is represented by a null value for the interaction vm
    if (!pollInteractionVm) {
      return false
    }
    // Assume true unless any user type has responses and is visible since we have to check every time
    let showNoUserPollResponsesToShowOnMapTooltip = true
    const { pollId } = pollInteractionVm
    const userTypesForCurrentPoll = userTypesIncludedInPollReponses[
      pollId
    ] as MobileUserTypesForPollInteractions[]
    //If no poll response user types exist don't show the tooltip
    if (!userTypesForCurrentPoll || userTypesForCurrentPoll.length === 0) {
      // console.log(`No poll response user types exist for poll ${pollId}`)
      return false
    }
    const userTypeResponsesHidden: Record<MobileUserTypesForPollInteractions, boolean> = {
      [MobileUserTypes.teacher]:
        userTypesForCurrentPoll.includes(MobileUserTypes.teacher) &&
        !locationVisibilityVm.showTeachers,
      [MobileUserTypes.student]:
        userTypesForCurrentPoll.includes(MobileUserTypes.student) &&
        !locationVisibilityVm.showStudents,
      [MobileUserTypes.otherStaff]:
        userTypesForCurrentPoll.includes(MobileUserTypes.otherStaff) &&
        !locationVisibilityVm.showOtherStaff,
      [MobileUserTypes.guest]:
        userTypesForCurrentPoll.includes(MobileUserTypes.guest) && !locationVisibilityVm.showGuests
    }
    // In order to show the tool tip all user types associated to the poll should be hidden
    userTypesForCurrentPoll.forEach((t) => {
      if (!userTypeResponsesHidden[t]) {
        showNoUserPollResponsesToShowOnMapTooltip = false
      }
    })
    if (showNoUserPollResponsesToShowOnMapTooltip) {
      return pollId
    } else return null
  }
}
