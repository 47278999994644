import { UserDtoProps } from '@model/user/user.model'

export enum RosterFileErrorsProps {
  invalidFileType = 'invalidFileType',
  unknownFileType = 'unknownFileType',
  fileNameMissing = 'fileNameMissing',
  emptyFile = 'emptyFile',
  noColumnsInFile = 'noColumnsInFile',
  fileTypeError = 'fileTypeError',
  devErrorSampleFileBorken = 'devErrorSampleFileBorken',
  invalidColumns = 'invalidColumns'
}
export const RosterErrorTypeDisplayStrings: Record<RosterFileErrorsProps, string> = {
  [RosterFileErrorsProps.invalidFileType]: 'Invalid File Type!',
  [RosterFileErrorsProps.unknownFileType]: 'File must be csv or xlsx',
  [RosterFileErrorsProps.fileNameMissing]: 'File name missing error',
  [RosterFileErrorsProps.emptyFile]: 'Empty file error',
  [RosterFileErrorsProps.noColumnsInFile]:
    'File Must Have Columns: First Name, Last Name, Phone, Email',
  [RosterFileErrorsProps.fileTypeError]: 'File type error',
  [RosterFileErrorsProps.devErrorSampleFileBorken]: 'Sample loading error',
  [RosterFileErrorsProps.invalidColumns]: 'Missed columns error'
}
export type RosterFileUploadError = {
  errorTitle?: string
  errorMessage?: string
}
export const rosterFileSample = [
  {
    [UserDtoProps.firstName]: '',
    [UserDtoProps.lastName]: '',
    [UserDtoProps.phone]: '',
    [UserDtoProps.email]: ''
  }
]
export const fileTypesToUpload = ['csv', 'xlsx']

export class RosterFileErrorViewModel {
  static getPayloadFromEnum = (
    errorType?: RosterFileErrorsProps,
    message?: string
  ): RosterFileUploadError => {
    const typeToUse = errorType ?? RosterFileErrorsProps.fileNameMissing
    return {
      errorTitle: RosterErrorTypeDisplayStrings[typeToUse],
      errorMessage: message ?? ''
    }
  }
}
export function getMissedUploadedFileColumnNames(keyNames: string[]): string[] | undefined {
  const result = [] as string[]
  const validKeys = Object.keys(rosterFileSample[0])
  validKeys.forEach((name) => {
    if (keyNames.lastIndexOf(name) < 0) {
      result.push(name)
    }
  })
  return result.length > 0 ? result : undefined
}
