import { Injectable } from '@angular/core'
import { Observable, fromEvent, map, merge, startWith } from 'rxjs'

@Injectable()
export class VisibilityService {
  readonly visibilityChange$: Observable<boolean>

  constructor() {
    const visibilityMap = {
      'hidden': false,
      'visible': true
    }

    this.visibilityChange$ = merge(
      fromEvent(document, 'visibilitychange'),
      fromEvent(document, 'blur'),
      fromEvent(document, 'focus')
    ).pipe(
      startWith(0),
      map(() => visibilityMap[document.visibilityState])
    )
  }
}
