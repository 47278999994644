import { ExtendedAction } from '@action/extended-ngrx-action'
import { ChatMessageDto } from '@model/message.model'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import { GetSchoolDto } from '@model/school/school.model'
import { UserLocationDto, UserPresenceChangeDto } from '@model/user/user-location.model'
import { GetUserDto } from '@model/user/user.model'
import { createAction, props } from '@ngrx/store'

export enum SignalrAction {
  connectBySchoolIds = `[SignalrAction] Connect By School Ids`,
  disconnectFromWebsocket = `[SignalrAction] Disconnect`,
  handleSignalrError = `[SignalrAction] Handle Error`,

  //Receive
  receiveSignalrChatMessage = `[SignalrAction] Receive Chat Message`,
  receiveSignalrChatResponse = `[SignalrAction] Receive Chat Response`,
  receiveSignalrUserLocation = `[SignalrAction] Receive User Location`,
  receiveSignalrUserPresenceChange = '[SignalrAction] User Presence Changed',

  // school
  receiveSignalrSchoolOwlStateUpdate = `[SignalrAction] Receive School Owl State`,
  receiveSignalrSchoolUpdate = `[SignalrAction] Receive School Dto Update`,

  //Send
  sendSignalrChatMessage = `[SignalrAction] Send Chat Message`,
  receiveSentSignalrChatMessage = '[SignalrAction] Receive Browser Sent Chat Message',

  //User registration complete
  receiveSignalrUserRegistered = '[SignalrAction] Receive User Registered'
}
//Connection
export const connectBySchoolIds = createAction(
  SignalrAction.connectBySchoolIds,
  props<ExtendedAction<string[]>>()
)
// disconnect
export const disconnectFromWebsocket = createAction(SignalrAction.disconnectFromWebsocket)
export const handleSignalrError = createAction(SignalrAction.handleSignalrError)
//Receive
// location
export const receiveSignalrUserLocation = createAction(
  SignalrAction.receiveSignalrUserLocation,
  props<ExtendedAction<UserLocationDto>>()
)
export const receiveSignalrUserPresenceChange = createAction(
  SignalrAction.receiveSignalrUserPresenceChange,
  props<ExtendedAction<UserPresenceChangeDto>>()
)
// school
export const receiveSignalrSchoolUpdate = createAction(
  SignalrAction.receiveSignalrSchoolUpdate,
  props<ExtendedAction<GetSchoolDto>>()
)
export const receiveSignalrSchoolOwlStateUpdate = createAction(
  SignalrAction.receiveSignalrSchoolOwlStateUpdate,
  props<ExtendedAction<SchoolOwlState>>()
)
//Mobile User Response
export const receiveSignalrChatResponse = createAction(
  SignalrAction.receiveSignalrChatResponse,
  props<ExtendedAction<ChatMessageDto>>()
)

//Owl Browser Sent
export const sendSignalrChatMessage = createAction(
  SignalrAction.sendSignalrChatMessage,
  props<ExtendedAction<ChatMessageDto>>()
)
export const receiveSentSignalrChatMessage = createAction(
  SignalrAction.receiveSentSignalrChatMessage,
  props<ExtendedAction<ChatMessageDto>>()
)
export const receiveSignalrUserRegistered = createAction(
  SignalrAction.receiveSignalrUserRegistered,
  props<ExtendedAction<GetUserDto>>()
)
