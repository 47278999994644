import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  clearPilotUserSignUpModalErrorMessage,
  submitSignUpPilotUser
} from '@action/welcome-page/welcome-page.actions'
import { Injectable } from '@angular/core'
import { FormControl, ValidationErrors, Validators } from '@angular/forms'
import { MatSelectChange } from '@angular/material/select'
import { UnitedStatesVm } from '@model/united-state.model'
import { ValidationMessages } from '@model/user/create-update-user.model'
import {
  IPilotUserSignUpFormValue,
  PilotUserPropsWithValidationType,
  PilotUserSignUpFormModel,
  PilotUserSignUpProps
} from '@model/user/pilot-user-sign-up.model'
import { Store } from '@ngrx/store'
import {
  selectPilotUserSignUpHasError,
  selectPilotUserSignUpIsLoading,
  showPilotUserSignUpModalErrorMessage
} from '@selector/page/welcome-page/welcome-page.selector'
import { phoneMask, pilotSingnUpRecaptchaAction } from '@shared/constants'
import { MatSelectOptionVm } from '@view/matieral/select.view'
import { GridViewModelAttributes } from '@view/shared/grid.view'
import { PilotUserSignUpFormVm } from '@view/user/welcome-user.view'
import { map, Observable, of, Subscription, withLatestFrom } from 'rxjs'
import { ReCaptchaV3Service } from 'ng-recaptcha'

@Injectable()
export class WelcomePageComponentService {
  constructor(
    private store: Store,
    private _userForm: PilotUserSignUpFormModel,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  reCaptchaExecutionSubscription: Subscription | null = null

  isPilotSignUpError$ = this.store.select(selectPilotUserSignUpHasError)
  isPilotSignUpLoading$ = this.store.select(selectPilotUserSignUpIsLoading)
  showPilotUserSignUpModalErrorMessage$ = this.store.select(showPilotUserSignUpModalErrorMessage)

  formProps = Object.values(PilotUserSignUpProps)
  canSubmit$: Observable<boolean> = of(false)

  /* Each form shares half horizontal space, but email and phone get full width */
  formArrangement: GridViewModelAttributes<PilotUserSignUpProps>[] = [
    { type: PilotUserSignUpProps.firstName, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.lastName, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.school, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.position, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.city, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.state, cols: 1, rows: 1 },
    { type: PilotUserSignUpProps.email, cols: 2, rows: 1 },
    { type: PilotUserSignUpProps.phone, cols: 2, rows: 1 },
    { type: PilotUserSignUpProps.information, cols: 2, rows: 1 }
  ]
  get isValid(): boolean {
    return this._userForm.isValid()
  }
  formChanges$: Observable<IPilotUserSignUpFormValue> = new Observable()
  init = () => {
    this.formChanges$ = this._userForm.getChangeObs()
    this.canSubmit$ = this.formChanges$.pipe(
      withLatestFrom(this.isPilotSignUpError$, this.isPilotSignUpLoading$),
      map(this.handleCanSubmit)
    )
  }

  handleCanSubmit = ([formValue, hasError, isLoading]: [
    IPilotUserSignUpFormValue,
    boolean,
    boolean
  ]): boolean => {
    this.store.dispatch(clearPilotUserSignUpModalErrorMessage())
    if (isLoading) {
      return false
    }
    if (this._userForm.isValid()) {
      return true
    } else {
      return false
    }
  }

  handleSubmitSignUpPilotUserForm = () => {}
  isSelect(p: PilotUserSignUpProps): boolean {
    return p === PilotUserSignUpProps.state
  }
  isInput(page: PilotUserSignUpProps): boolean {
    return page !== PilotUserSignUpProps.state
  }
  handleFormChange = (c: any) => {
    if (this._userForm.isValid()) {
      this.dispatchNewFormValue(this._userForm.getRawFormValues())
    }
  }
  /** Update form model with new value for control. */
  handleSelectChange = ($event: MatSelectChange, p: PilotUserSignUpProps) => {
    this._userForm.getFormControlByKey(p).setValue($event.value)
  }
  getValue = (p: PilotUserSignUpProps): string => {
    return this._userForm.getFormValueByKey(p)
  }
  getMask = (p: PilotUserSignUpProps): string | null => {
    if (p === PilotUserSignUpProps.phone) {
      return phoneMask
    }
    return null
  }
  getPrefix = (p: PilotUserSignUpProps): string => {
    return PilotUserSignUpFormVm.UserCreateUpdateFormPrefix[p] ?? ''
  }
  getIcon = (p: PilotUserSignUpProps): string => {
    return PilotUserSignUpFormVm.MatIconByUserProp[p] ?? ''
  }
  getDisabled = (p: PilotUserSignUpProps): boolean => {
    return this._userForm.getFormControlByKey(p).disabled
  }
  getHint = (p: PilotUserSignUpProps): string => {
    return PilotUserSignUpFormVm.LabelTextForUserProp[p]
  }
  /** Show the first error message if there are errors, after the input has been interacted with and user has left that input. */
  getErrorProps = (p: PilotUserSignUpProps): ValidationErrors | null => {
    const control = this._userForm.getFormControlByKey(p)
    const { errors, pristine, touched } = control

    if (!pristine && errors && touched) {
      // console.log(`errors for ${p}`, errors)
      return errors
    }
    return null
  }
  getControl(p: PilotUserSignUpProps): FormControl {
    return this._userForm.getFormControlByKey(p)
  }
  /** Get configured message object by prop. */
  getErrorMessages(p: PilotUserSignUpProps): ValidationMessages | null {
    // Pilot user sign ups require all fields except phone number
    if (p !== PilotUserSignUpProps.phone) {
      return this._userForm.errorMessages[p as PilotUserPropsWithValidationType] ?? null
    }
    return null
  }
  getErrorMessage(p: PilotUserSignUpProps): string {
    const errors = this.getErrorProps(p)
    if (!errors) {
      return ''
    }
    const firstErrorProp: PilotUserPropsWithValidationType | null =
      (Object.keys(errors)[0] as PilotUserPropsWithValidationType) ?? null

    const messages: ValidationMessages | null = this.getErrorMessages(p)
    if (!messages || !firstErrorProp) {
      return ''
    }
    return messages[firstErrorProp] ?? ''
  }
  getOptions(p: PilotUserSignUpProps): MatSelectOptionVm[] {
    if (p === PilotUserSignUpProps.state) {
      return UnitedStatesVm.selectStateOptions
    }
    return []
  }
  getPlaceHolder(p: PilotUserSignUpProps): string {
    return PilotUserSignUpFormVm.PlaceholderTextByUserProp[p]
  }
  getLabelText(p: PilotUserSignUpProps): string {
    const control = this._userForm.getFormControlByKey(p)
    //Check if control is required
    if (!control.hasValidator(Validators.required)) {
      return `${PilotUserSignUpFormVm.LabelTextForUserProp[p]} (optional)`
    }
    return PilotUserSignUpFormVm.LabelTextForUserProp[p]
  }
  getTouched(p: PilotUserSignUpProps): boolean {
    return this._userForm.getFormControlByKey(p).touched
  }
  getDirty = (p: PilotUserSignUpProps): boolean => {
    return this._userForm.getFormControlByKey(p).dirty
  }
  dispatchNewFormValue = (value: any) => {
    console.log('Dispatch new form value', value)
  }
  onSubmit = () => {
    const formValues = this._userForm.getRawFormValues()
    this.reCaptchaExecutionSubscription = this.recaptchaV3Service
      .execute(pilotSingnUpRecaptchaAction)
      .subscribe({
        next: (response) => {
          formValues.token = response
        },
        error: (error) => {
          //implement showing error
          console.error(error)
        },
        complete: () => {
          this.store.dispatch(submitSignUpPilotUser(getExtendedAction(formValues)))
        }
      })
  }

  ngOnDestroy(): void {
    this.reCaptchaExecutionSubscription?.unsubscribe()
  }
}
