import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { UserDtoProps } from '@model/user/user.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  selectGlobalState,
  selectLoggedInUserId,
  selectSelectedSchoolId,
  selectUserDto
} from '@selector/auth.selector'
import { selectUrl } from '@selector/route.selector'
import { OwlStateSlices } from '@state/app.state'
import { GlobalStateProps } from '@state/auth.state'
import { RosterPageState } from '@state/page/roster-page.state'

export const selectRosterPageState = createFeatureSelector<RosterPageState>(OwlStateSlices.roster)

export const selectRosterTabToVmLookup = createSelector(
  selectRosterPageState,
  (pageState) => pageState?.rosterTabVmLookupByTab
)
export const selectInviteSummayByTypeApiState = createSelector(
  selectRosterPageState,
  (s) => s.invitationSummaryApiLookup
)
export const reinviteUnregisteredLoading = createSelector(
  selectRosterPageState,
  (s) => s.reinviteApiResult.isLoading
)
export const reinviteUnregisteredError = createSelector(
  selectRosterPageState,
  (s) => s.reinviteApiResult.hasError
)
export const selectCreateUserLoading = createSelector(
  selectRosterPageState,
  (s) => s.createUserApiState.isLoading
)
export const selectCreateUserError = createSelector(
  selectRosterPageState,
  (s) => s.createUserApiState.hasError
)
export const selectUpdateUserLoading = createSelector(
  selectRosterPageState,
  (s) => s.updateUserApiState.isLoading
)
export const selectUpdateUserError = createSelector(
  selectRosterPageState,
  (s) => s.updateUserApiState.hasError
)
export const selectCreateUpdateUserErrorText = createSelector(
  selectRosterPageState,
  (s) => s.createUpdateUserErrorText
)
export const selectServerValidations = createSelector(
  selectRosterPageState,
  (s) => s.serverValidations
)
export const createUpdateUserIsLoading = createSelector(
  selectUpdateUserLoading,
  selectCreateUserLoading,
  (updateLoading, createUserLoading) => updateLoading || createUserLoading
)
export const createUpdateUserHasError = createSelector(
  reinviteUnregisteredError,
  selectCreateUserError,
  (reinviteError, createUserError) => reinviteError || createUserError
)
export const selectRosterPageVm = createSelector(selectRosterPageState, (pageState) => pageState.vm)

export const selectRosterFileUploadBrowserError = createSelector(
  selectRosterPageState,
  (pageState) => pageState?.vm?.uploadRosterFileBrowserError ?? null
)
export const selectUserVmToDeactivate = createSelector(
  selectRosterPageVm,
  selectSelectedSchoolId,
  selectUserDto,
  (vm, schoolIds, currentUser) => {
    return {
      userId: currentUser?.[UserDtoProps.id] !== vm?.selectedUserId ? vm?.selectedUserId ?? -1 : -1,
      schoolIds: schoolIds
    }
  }
)

export const selectRosterPageIsReadyToShow = createSelector(
  selectUrl,
  selectGlobalState,
  (url, authState) => {
    const isRosterPage = url.includes(FirstLevelDir.roster)
    const userSchoolApiState = authState.getUserSchoolApiState[authState.selectedSchoolId.id]
    return isRosterPage && userSchoolApiState.hasLoaded
  }
)
export const selectUsersForRosterPage = createSelector(
  selectGlobalState,
  selectRosterPageIsReadyToShow,
  (authState, isReady) => {
    if (!isReady) return null
    return authState[GlobalStateProps.usersForSchoolLookup][authState.selectedSchoolId.id]
  }
)
export const selectSetRosterUsersByTabPayload = createSelector(
  selectUsersForRosterPage,
  selectInviteSummayByTypeApiState,
  RosterPageState.getSetRosterUsersByTabPayload
)

export const selectSelectedRosterTab = createSelector(
  selectRosterPageVm,
  (vm) => vm?.selectedRosterTab
)
export const selectRosterVmBySelectedTab = createSelector(
  selectRosterTabToVmLookup,
  selectSelectedRosterTab,
  (rosterTabToVmLoookup, selectedRosterTab) => rosterTabToVmLoookup[selectedRosterTab]
)
export const selectUsersBySelectedType = createSelector(
  selectRosterVmBySelectedTab,
  (rosterTabVm) => (rosterTabVm ? rosterTabVm.userVms : null)
)
export const selectSelectedUserTypeUsersExist = createSelector(
  selectUsersBySelectedType,
  (userVms) => (userVms ? userVms.length > 0 : false)
)
