import { createSelector } from '@ngrx/store'
import { selectSelectedRosterTab } from './roster-page.selector'
import { selectRosterValidationIsValid } from './roster-view.selector'
import { RosterTopSectionViewModel } from '@view/pages/roster-page/roster-top-section.view'

export const selectReinviteButtonTooltipText = createSelector(
  selectSelectedRosterTab,
  RosterTopSectionViewModel.getReinviteButtonTooltipText
)

export const selectUploadButtonText = createSelector(
  selectSelectedRosterTab,
  RosterTopSectionViewModel.getUploadButtonText
)
