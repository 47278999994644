import { WelcomePageFeature } from "@view/user/welcome-user.view"

export interface IOwlFeatureFlags {
  WELCOME_PAGE_FEATURE: WelcomePageFeature
  USE_LOCATIONS_SIGNALR_ENDPOINT: boolean
  USE_SELECTED_AREA_OUTSIDE_LOCATION_DIMMING: boolean
  USE_DASHBOARD_TOAST_NOTIFICATIONS: boolean
}
export const FEATURE_FLAGS_CONFIG_DEFAULTS: IOwlFeatureFlags = {
  WELCOME_PAGE_FEATURE: WelcomePageFeature.createAccount,
  USE_LOCATIONS_SIGNALR_ENDPOINT: false,
  USE_SELECTED_AREA_OUTSIDE_LOCATION_DIMMING: false,
  USE_DASHBOARD_TOAST_NOTIFICATIONS: false
}
