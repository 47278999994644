/** Interface available to define the signature for any page state component, and any nested api state to indiate multiple interactions per page. */
export interface IApiState {
  isLoading: boolean
  hasLoaded: boolean
  hasError: boolean
  error?: string
}
/** Here to serve as the base implementation of any part of the app that utilizes network calls. */
export class ApiState {
  isLoading: boolean = false
  hasLoaded: boolean = false
  hasError: boolean = false
  error?: string
  static createIsLoadingState(): ApiState {
    return {
      isLoading: true,
      hasLoaded: false,
      hasError: false
    }
  }
  static createHadLoadedState(): ApiState {
    return {
      isLoading: false,
      hasLoaded: true,
      hasError: false
    }
  }
  static createHasErrorState(): ApiState {
    return {
      isLoading: false,
      hasLoaded: false,
      hasError: true
    }
  }
}
