import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  downloadPilotUserSignups,
  enqueueToast,
  handleToastVm,
  invitePilotUsers,
  logEvent,
  logSignalrEvent,
  pingServer,
  setScrollVisibility,
  showActivityTimeOutModal,
  showSingleToast
} from '@action/global-app.actions'
import {
  receiveSentSignalrChatMessage,
  receiveSignalrChatResponse,
  receiveSignalrUserLocation
} from '@action/signalr.action'
import { pingServerError, pingServerSuccess } from '@action/user/user-api.action'
import { downloadSignupsFailure, downloadSignupsSuccess, invitePilotUsersFailure, invitePilotUsersSuccess } from '@action/welcome-page/welcome-api.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SnackbarService } from '@component/shared/snackbar/snackbar.service'
import { LocationDtoHelper } from '@domain/dto-helpers/location-model.helper'
import { ChatMessageDto } from '@model/message.model'
import { UserLocationDto } from '@model/user/user-location.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { selectNumberOfToastsInQueue, selectTimestampOfSingleToast } from '@selector/auth.selector'
import { DownloadFileService } from '@service/browser/file/download-file.service'
import { SignalrEventProps } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { ModalService } from '@service/material/modal.service'
import { PilotUserApiService } from '@service/network/pilot-user.service'
import { UserApiService } from '@service/network/user.service'
import { getFullToastLifeTime } from '@shared/constants'
import { TimeUtils } from '@shared/time.utils'
import { disableScrollingGlobally } from '@view/global.view'
import { ModalViewModel } from '@view/matieral/modal.view'
import { catchError, concatMap, debounceTime, delay, map, mergeMap, of, tap } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GlobalAppEffects {
  readonly SHOULD_LOG_SIGNALR_EVENTS = false
  constructor(
    private actions$: Actions,
    private store: Store,
    private modalService: ModalService,
    private userService: UserApiService,
    private snackBarService: SnackbarService,
    private loggingService: LoggingService,
    private pilotUserService: PilotUserApiService,
    private downloadFileService: DownloadFileService
  ) { }
  handleInvitePilotUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invitePilotUsers),
        mergeMap((_) =>
          this.pilotUserService.invitePilotUser().pipe(
            map(() => {
              return invitePilotUsersSuccess()
            }),
            catchError((e: HttpErrorResponse) => of(invitePilotUsersFailure(getExtendedAction(e))))
          )
        )
      ),
    { dispatch: false }
  )
  handleDownloadSignups$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadPilotUserSignups),
        mergeMap((_) =>
          this.pilotUserService.downloadPilotUsers().pipe(
            map((userDtos) => {
              this.downloadFileService.downloadGenericCsv(userDtos, 'pilot-user-signups.csv')
              return downloadSignupsSuccess()
            }),
            catchError((e: HttpErrorResponse) => of(downloadSignupsFailure(getExtendedAction(e))))
          )
        )
      ),
    { dispatch: false }
  )
  pingServer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pingServer),
        mergeMap((_) =>
          this.userService.pingServer().pipe(
            map(() => {
              return pingServerSuccess()
            }),
            catchError((e: HttpErrorResponse) => of(pingServerError(getExtendedAction(e))))
          )
        )
      ),
    { dispatch: false }
  )
  handleSignalrTrackedEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          receiveSignalrUserLocation,
          receiveSignalrChatResponse,
          receiveSentSignalrChatMessage
        ),
        tap((action) => {
          if (this.SHOULD_LOG_SIGNALR_EVENTS) {
            this.store.dispatch(
              logSignalrEvent(
                getExtendedAction({
                  latency: this.getLatencyForPayload(action.payload),
                  signalrEventName: action.type
                } as SignalrEventProps)
              )
            )
          }
        })
      ),
    { dispatch: false }
  )
  /** TODO Validate approach and put into time utils */
  getLatencyForPayload = (dto: UserLocationDto | ChatMessageDto): number => {
    let latency = 0
    let timeOfPayload = 0
    let now = new Date(new Date().toUTCString()).getTime()
    if (LocationDtoHelper.isUserLocationDto(dto)) {
      timeOfPayload = TimeUtils.getDateFromString(dto?.timestamp)?.getTime() ?? 0
    } else {
      timeOfPayload = TimeUtils.getDateFromString(dto?.payload?.timestamp)?.getTime() ?? 0
    }
    if (timeOfPayload) {
      latency = now - timeOfPayload
    }
    return latency
  }
  logSignalrEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logSignalrEvent),
        // Debounce logging of signalr events to prevent degraded performance
        debounceTime(100),
        tap(({ payload }) => {
          const { latency, signalrEventName } = payload
          this.loggingService.logEvent(`${signalrEventName} - ${latency}`, {
            latency,
            signalrEventName
          })
        })
      ),
    { dispatch: false }
  )
  logEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logEvent),
        tap((action) => {
          this.loggingService.logEvent(action.type, action.payload)
        })
      ),
    { dispatch: false }
  )
  handleToastVmLogic$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleToastVm),
        concatLatestFrom(() => this.store.select(selectNumberOfToastsInQueue)),
        tap(([action, numberOfToastsInQueue]) => {
          if (numberOfToastsInQueue === 0) {
            this.store.dispatch(showSingleToast(getExtendedAction(action.payload)))
          } else {
            this.store.dispatch(enqueueToast(getExtendedAction(action.payload)))
          }
        })
      ),
    { dispatch: false }
  )
  showSingleToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSingleToast),
        tap((action) => {
          this.snackBarService.show(action.payload)
        })
      ),
    { dispatch: false }
  )
  enqueueToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(enqueueToast),
        concatLatestFrom(() => this.store.select(selectTimestampOfSingleToast)),
        concatMap(([toastVm, timestamp]) =>
          of(toastVm).pipe(delay(this.getTimeForNextToast(timestamp)))
        ),
        tap((action) => {
          this.snackBarService.show(action.payload)
        })
      ),
    { dispatch: false }
  )
  getTimeForNextToast = (timestamp: Date | null): number => {
    if (timestamp) {
      const now = new Date()
      const diff = now.getTime() - timestamp.getTime()
      // console.log(`time since last toast`, diff)
      const time = getFullToastLifeTime() - diff
      // console.log(`Computed Time for next toast`, time)
      return time
    }
    // console.log(`Usual Time for next toast`, getFullToastLifeTime())
    return getFullToastLifeTime()
  }
  showActivityTimeOutModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showActivityTimeOutModal),
        tap(() => {
          this.modalService.showActivityWarningModal(
            new ModalViewModel(
              true,
              '',
              'Are you still there?',
              `Click to continue working`,
              "I'm here",
              '',
              () => { },
              () => { }
            )
          )
        })
      ),
    { dispatch: false }
  )
  toggleScrollVisibility$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setScrollVisibility),
        tap((a) => {
          disableScrollingGlobally(a.payload)
        })
      ),
    { dispatch: false }
  )
}
