import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BtnColorVariants } from '@component/shared/button/button.component.view'
import { BaseModalComponent } from '@component/shared/modal/base-modal.component'
import { ModalViewModel } from '@view/matieral/modal.view'

@Component({
  selector: 'app-transition-modal',
  templateUrl: './transition-modal.component.html',
  styleUrls: ['./transition-modal.component.scss']
})
export class TransitionModalComponent extends BaseModalComponent<TransitionModalComponent> {
  cancelButtonType = BtnColorVariants.secondary

  get icon(): string {
    return this.vm.iconName
  }
  get showIcon(): boolean {
    return !!this.vm.iconName
  }
  get titleSvgPath(): string {
    return `./assets/icons/${this.icon}.svg`
  }

  get hasMultiLinePrompt(): boolean {
    return this.vm.promptText.includes(ModalViewModel.lineBreakToken)
  }

  get mulitLinePrompt(): string[] {
    return this.vm.promptText.split(ModalViewModel.lineBreakToken)
  }

  constructor(
    public dialogRef: MatDialogRef<TransitionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public vm: ModalViewModel
  ) {
    super(dialogRef, vm)
  }
}
