import { downloadEvent, getEvents, setEvents } from '@action/events-page/events-page.actions'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { selectEvents, selectIsLoaded } from '@selector/page/events-page/events-page.selector'
import { EventVm } from '@view/pages/events-page/events-page.view'

@Injectable()
export class EventsPageComponentService {
  events$ = this.store.select(selectEvents)
  isEventsLoaded$ = this.store.select(selectIsLoaded)

  constructor(private store: Store) {}

  init = () => {
    this.handleInitialDataLoading()
  }
  destroy = () => {
    this.store.dispatch(setEvents(getExtendedAction([])))
  }

  handleInitialDataLoading = () => {
    this.store.dispatch(getEvents())
  }

  selectEvent = (event: EventVm) => {
    this.store.dispatch(downloadEvent(getExtendedAction(event)))
  }
}
