import { Injectable } from '@angular/core'
import { mockSchoolDto } from '@mock/school.mock'
import { LatLon } from '@model/location.model'
import { PostSchoolForUserDto } from '@model/school/schoo-validation.model'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import {
  GetSchoolAreaDto,
  PatchSchoolAreaDto,
  SchoolAreaDto,
  SchoolAreaDtoProps
} from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDto } from '@model/school/school.model'
import { AppConfigService } from '@service/app-config/app-config.service'
import { Observable, of } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class SchoolApiService {
  public readonly serviceBaseDir = '/School'
  constructor(private _apiService: ApiService, private _appConfigService: AppConfigService) {}
  //GET
  searchForSchoolByQuery(q: string) {
    return this._apiService.get<SchoolDto[]>(`${this.serviceBaseDir}/find?${q}&count=100`)
  }
  getSchoolById = (id: number): Observable<GetSchoolDto> => {
    if (this._appConfigService.config.USE_MOCK_API_DATA) {
      return of(mockSchoolDto)
    }
    return this._apiService.get<GetSchoolDto>(`${this.serviceBaseDir}/${id}`)
  }
  getSchoolOsmPolygon = ({ lat, lon }: LatLon) => {
    if (this._appConfigService.config.USE_MOCK_API_DATA) {
      return of(mockSchoolDto.boundary)
    }
    console.log(`getSchoolOsmPolygon: ${lat}, ${lon}`)
    return this._apiService.get<number[][] | null>(
      `${this.serviceBaseDir}/polygon?lat=${lat}&lon=${lon}`
    )
  }

  //POST
  setUsersSchool = (dto: PostSchoolForUserDto) => {
    return this._apiService.post<GetSchoolDto, PostSchoolForUserDto>(`${this.serviceBaseDir}`, dto)
  }
  saveSchoolArea = (dto: SchoolAreaDto) => {
    return this._apiService.post(
      `${this.serviceBaseDir}/${dto[SchoolAreaDtoProps.schoolId]}/area`,
      dto
    )
  }
  updateSchoolArea = (dto: PatchSchoolAreaDto): Observable<GetSchoolAreaDto> => {
    return this._apiService.patch(
      `${this.serviceBaseDir}/${dto[SchoolAreaDtoProps.schoolId]}/area/${
        dto[SchoolAreaDtoProps.id]
      }`,
      dto
    )
  }
  deleteSchoolArea = (id: number) => {
    return this._apiService.delete<GetSchoolAreaDto>(`${this.serviceBaseDir}/area/${id}`)
  }

  //PATCH
  patchSchool = (dto: Partial<SchoolDto>) => {
    if (!dto.id) {
      throw new Error('SchoolDto.id is required to Patch school')
    }
    const { id, ...rest } = dto
    return this._apiService.patch<SchoolDto, Partial<SchoolDto>>(
      `${this.serviceBaseDir}/${dto.id}`,
      rest
    )
  }

  patchSchoolState = (dto: Partial<SchoolOwlState>) => {
    if (!dto.schoolId) {
      throw new Error('schoolId is required to Patch school owl state')
    }
    const { schoolId, ...rest } = dto
    return this._apiService.patch<SchoolOwlState, Partial<SchoolOwlState>>(
      `${this.serviceBaseDir}/state/${dto.schoolId}`,
      rest
    )
  }
}
