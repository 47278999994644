import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AppTitleSvgIconComponent } from '@component/header/dashboard-sidenav/app-title-svg-icon/app-title-svg-icon.component'
import { AssistancePreferencesComponent } from '@component/header/dashboard-sidenav/assistance-preferences/assistance-preferences.component'
import { DashboardSideNavHeaderComponent } from '@component/header/dashboard-sidenav/dashboard-sidenav.component'
import { ExpandedLinkComponent } from '@component/header/dashboard-sidenav/expanded-link/expanded-link.component'
import { MainCtaButtonComponent } from '@component/header/dashboard-sidenav/main-cta-button/main-cta-button.component'
import { MapUiControlIconComponent } from '@component/header/dashboard-sidenav/map-ui-control-icon/map-ui-control-icon.component'
import { MapUiControlItemComponent } from '@component/header/dashboard-sidenav/map-ui-control-item/map-ui-control-item.component'
import { SideNavIconComponent } from '@component/header/dashboard-sidenav/side-nav-icon/side-nav-icon.component'
import { DashboardTopNavHeaderComponent } from '@component/header/dashboard-topnav/dashboard-topnav.component'
import { DrillModeComponent } from '@component/header/dashboard-topnav/drill-mode/drill-mode.component'
import { HideMapComponent } from '@component/header/dashboard-topnav/hide-map/hide-map.component'
import { OwlLogoComponent } from '@component/logo/owl-logo/owl-logo.component'
import { TextLogoComponent } from '@component/logo/text-logo/text-logo.component'
import { MapHomeButtonComponent } from '@component/school-map-config/map-home-button/map-home-button.component'
import { MapZoomButtonsComponent } from '@component/school-map-config/map-zoom-buttons/map-zoom-buttons.component'
import { AuthPageLoadingComponent } from '@component/shared/auth-page-loading/auth-page-loading.component'
import { ButtonWithIconComponent } from '@component/shared/button-w-icon/button-w-icon.component'
import { ButtonComponent } from '@component/shared/button/button.component'
import { CheckIconComponent } from '@component/shared/check-icon/check-icon.component'
import { CtaButtonComponent } from '@component/shared/cta-button/cta-button.component'
import { CustomMapControlLabelComponent } from '@component/shared/custom-map-control-label/custom-map-control-label.component'
import { CustomUiLabelComponent } from '@component/shared/custom-ui-label/custom-ui-label.component'
import { FormInputComponent } from '@component/shared/form-input/form-input.component'
import { FormSelectComponent } from '@component/shared/form-select/form-select.component'
import { LargeDescriptionTextWithAccentComponent } from '@component/shared/large-description-text-with-accent/large-description-text-with-accent.component'
import { LargeInformativePillComponent } from '@component/shared/large-informative-pill/large-informative-pill.component'
import { MapPopupComponent } from '@component/shared/map-popup/map-popup.component'
import { MultiLineSpinnerComponent } from '@component/shared/multi-line-spinner/multi-line-spinner.component'
import { SafeHtml } from '@component/shared/pipe/safe-html'
import { ProfileDetailsComponent } from '@component/shared/profile/profile-details/profile-details.component'
import { ProfileComponent } from '@component/shared/profile/profile.component'
import { SignInSignUpButtonsComponent } from '@component/shared/sign-in-sign-up-buttons/sign-in-sign-up-buttons.component'
import { StatusIconsComponent } from '@component/shared/status-icons/status-icons.component'
import { ToggleMatComponent } from '@component/shared/toggle-mat/toggle-mat.component'
import { ToggleComponent } from '@component/shared/toggle/toggle.component'
import { TopSectionComponent } from '@component/shared/top-section/top-section.component'
import { ViewPortClassesDirective } from '@directive/viewport-classes.directive'
import { RosterTopButtonsSectionComponent } from '@page/roster/roster-top-buttons-section/roster-top-buttons-section.component'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { MaterialModule } from './material-module'
import { PageHeaderComponent } from '@component/shared/page-header/page-header.component'
import { InstructionsTopButtonsSectionComponent } from '@component/instructions-page/instructions-top-buttons-section/instructions-top-buttons-section.component'
import { RevertToStandardModalCheckboxexComponent } from '@component/instructions-page/revert-to-standard-modal-checkboxex/revert-to-standard-modal-checkboxex.component'

@NgModule({
  declarations: [
    PageHeaderComponent,
    OwlLogoComponent,
    LargeDescriptionTextWithAccentComponent,
    LargeInformativePillComponent,
    SignInSignUpButtonsComponent,
    TextLogoComponent,
    AuthPageLoadingComponent,
    MultiLineSpinnerComponent,
    DashboardSideNavHeaderComponent,
    DashboardTopNavHeaderComponent,
    TopSectionComponent,
    RosterTopButtonsSectionComponent,
    InstructionsTopButtonsSectionComponent,
    RevertToStandardModalCheckboxexComponent,
    //SIDE NAVIDATION
    // All components part of sidenav
    AppTitleSvgIconComponent,
    ExpandedLinkComponent,
    DrillModeComponent,
    HideMapComponent,
    SideNavIconComponent,
    MapUiControlIconComponent,
    MapUiControlItemComponent,
    AssistancePreferencesComponent,
    // only shown on dashboard
    MainCtaButtonComponent,
    // shared map ui
    MapHomeButtonComponent,
    MapZoomButtonsComponent,
    MapPopupComponent,
    //Building Blocks
    ButtonComponent,
    ButtonWithIconComponent,
    ToggleMatComponent,
    ToggleComponent,
    CtaButtonComponent,
    CustomUiLabelComponent,
    StatusIconsComponent,
    CustomMapControlLabelComponent,
    FormInputComponent,
    FormSelectComponent,
    //Shared image components
    CheckIconComponent,
    //Shared directives
    // FitContentDirective,
    SafeHtml,
    ViewPortClassesDirective,
    ProfileComponent,
    ProfileDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    //Components
    PageHeaderComponent,
    OwlLogoComponent,
    LargeDescriptionTextWithAccentComponent,
    LargeInformativePillComponent,
    SignInSignUpButtonsComponent,
    TextLogoComponent,
    ExpandedLinkComponent,
    ProfileComponent,
    // layout
    AuthPageLoadingComponent,
    MultiLineSpinnerComponent,
    DashboardSideNavHeaderComponent,
    DashboardTopNavHeaderComponent,
    AppTitleSvgIconComponent,
    TopSectionComponent,
    // shared map ui
    MapHomeButtonComponent,
    MapZoomButtonsComponent,
    MapPopupComponent,
    // building blocks
    ButtonComponent,
    RevertToStandardModalCheckboxexComponent,
    ButtonWithIconComponent,
    ToggleMatComponent,
    CtaButtonComponent,
    CustomUiLabelComponent,
    StatusIconsComponent,
    FormInputComponent,
    FormSelectComponent,
    //Shared directives
    // FitContentDirective,
    ViewPortClassesDirective,
    //Shared Image Components
    CheckIconComponent,
    SafeHtml
  ],
  providers: [provideNgxMask()]
})
export class SharedModule {}
