import { Point, Polygon } from '@arcgis/core/geometry'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'
import { BaseMapPopup } from '@component/shared/base-map-popup.model'

export namespace ArcGisGrahpicViewportHandler {
  export const handleGeometryPopupOutsideViewport = (
    mapView: __esri.MapView,
    popupRef: BaseMapPopup
  ) => {
    if (popupRef.isShownDueToClick) {
      if (popupRef.graphic?.geometry) {
        const projectedGeometry = ArcGisMapProjectionViewModel.projectToSpatialReference(
          popupRef.graphic.geometry,
          ArcgisSpatialRefence.webMercatorSpatialReference
        )

        const geometry =
          projectedGeometry?.type == 'point'
            ? (projectedGeometry as Point)
            : (projectedGeometry as Polygon)
        if (!geometry) {
          console.error(
            `DEV ERROR: Attempting to call handleGeometryPopupOutsideViewport when no related geometry exists.`
          )
          return
        }

        const xmax = geometry instanceof Polygon ? geometry.extent.xmax : geometry.x
        const xmin = geometry instanceof Polygon ? geometry.extent.xmin : geometry.x
        if (
          (xmax - mapView.extent.xmax > 0 && xmin - mapView.extent.xmax > 0) ||
          (mapView.extent.xmin - xmax > 0 && mapView.extent.xmin - xmin > 0)
        ) {
          popupRef.hidePopup()
        } else {
          ArcGisEventHandlers.updatePopupPosition(popupRef, mapView)
        }
      }
    }
  }
}
