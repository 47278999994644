import { LatLonAlt } from './location.model'

export enum PollType {
  /**
   * This is a very specific type of poll that only has certain available answers, so that each available answer is associated with a color to represent a user's status or a room's status based on some TBD rules for teach then aggregation and prioritization of students in the classroom.
   * */
  status = 'status',
  /** Similar to status poll but directed at a specific area by id. */
  statusToArea = 'statusToArea',
  /** Similar to status poll but directed at a specific user by id */
  checkStatus = 'checkStatus',

  /** This would be used with the assisted mode to enable the user to approve or deny sending a suggested poll. */
  suggested = 'suggested',
  /** This poll is created by the school admin user in the dashboard and sent one time. It's question and multiple available answers are saved in the db but not reusable by the school admin */
  adHoc = 'adHoc',
  /* When send Predefined Poll */
  predefined = 'Predefined'
}

//Sent from Balcony
export interface PollResponseDto {
  id: string | number //??
  responseId: string | number //??
  pollType: PollType

  userId: string | number //??
  logicalId: string
  schoolId: string | number //??

  // TODO Discuss this with Yaniv as a base extended object that's transmitted with all user related comms, including alerts, messages, polls, etc...
  latLong?: LatLonAlt
  timestamp?: string
}
