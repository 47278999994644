export enum CrossBrowserStateIndicator {
  LoggingOut = 'loggingOut',
  FullyAuthenticated = 'fullyAuthed',
  Unknown = 'unknown'
}

export enum MsalAcquireTokenSilentErrorCodes {
  SILENT_SSO_ERROR = 'silent_sso_error',
  NO_ACCOUNT_ERROR = 'no_account_error',
  INVALID_CLIENT = 'invalid_client'
}

export enum MsalRedirectErrorCode {
  InteractionRequired = 'interaction_required',
  UserLoginError = 'user_login_error',
  TokenRenewalError = 'token_renewal_error',
  ConsentRequired = 'consent_required',
  UserDoesntHaveExistingSessionRequestPromptValueNone = 'AADB2C90077',
  //error code when user clicks forgot password
  ResetPasswordFlowErrorCode = 'AADB2C90118',
  //: The user has cancelled entering self-asserted information"
  //Cancel a user flow in b2c - like reset password or edit profile
  UserCancelled = 'AADB2C90091'
}
