import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms'
import { UserTypeVariants } from '@model/user/create-update-user.model'
import { MobileUserTypes } from '@model/user/user.model'
import { AppComponentService } from '@service/app.component.service'
import { maxNameCharacters, maxTextCharacters, nameRegex, phoneMaxLength } from '@shared/constants'
import { OwlChatValidationsViewModel } from '@view/chat/chat-validations.view'

/** Assumes that each control has the same set of validations, need to create ad hoc validation object types for OWL domain. */
export enum AdditionalValidations {
  required = 'required',
  format = 'format',
  maxLength = 'maxlength',
  minLength = 'minlength'
}
export const additionalPhoneValidations = {
  required: 'Phone is required',
  format: 'Phone is invalid',
  maxlength: 'Phone requires 10 numbers',
  minlength: 'Phone requires 10 numbers'
}
export const additionalEmailValidations = {
  required: 'Email is required',
  email: 'Email format is incorrect.',
  maxlength: 'Email is too long',
  minlength: 'Email is too short'
}

export class CustomFormValidators {
  static getTextInputAdditionalValidations = (displayString: string) => ({
    required: `${displayString} is required`,
    pattern: `${displayString} must be letters only`,
    maxlength: `${displayString} is too long`,
    minlength: `${displayString} is too short`
  })
  static getTextInputInstructionValidations = (linesCountErrorText: string) => ({
    required: `Text is required`,
    maxlength: `Text is too long`,
    minlength: `Text is too short`,
    linesCount: linesCountErrorText
  })
  /** Takes in any string and parses the first 10 numbers from it, to do a best try for a valid phone number. */
  static getPhone = (unsanitizedPhone: string | undefined): string => {
    if (!unsanitizedPhone) return ''
    const digitsOnly = unsanitizedPhone.replace(/\D/g, '')
    const countryCodeRemoved = digitsOnly.startsWith('1')
      ? digitsOnly.slice(1, 11)
      : digitsOnly.slice(0, 10)
    return countryCodeRemoved
  }
  static getNameValidators = (fb: FormBuilder, minLength = 1) => {
    return [
      Validators.required,
      CustomFormValidators.trimmedTextRequiredValidator(fb),
      Validators.minLength(minLength),
      Validators.maxLength(maxNameCharacters),
      Validators.pattern(nameRegex)
    ]
  }
  static getInstructionValidators = (
    fb: FormBuilder,
    appCompServ: AppComponentService,
    minLength = 20
  ) => {
    return [
      Validators.required,
      CustomFormValidators.trimmedTextRequiredValidator(fb),
      CustomFormValidators.linesCountValidator(fb, appCompServ),
      Validators.minLength(minLength),
      Validators.maxLength(appCompServ.appConfig.config.INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE)
    ]
  }
  /** Phones are required for mobile users but not for web users. */
  static getPhoneValidaitonsByType = (
    variant: UserTypeVariants,
    mobileUserType: MobileUserTypes | undefined = undefined
  ): ValidatorFn[] => {
    const requiredValidators = [
      Validators.required,
      Validators.minLength(phoneMaxLength),
      Validators.maxLength(phoneMaxLength)
    ]
    if (variant === UserTypeVariants.mobile) {
      return requiredValidators
    }
    if (variant === UserTypeVariants.web) {
      // User could be admin with mobile user type, in which case phone is required.
      return mobileUserType
        ? requiredValidators
        : [Validators.minLength(phoneMaxLength), Validators.maxLength(phoneMaxLength)]
    }
    return []
  }
  /** Pass in form builder because We need to create a new control to avoid triggering form changes to handling trimming.
   * The is the fastest way to extend the existing Angular email validator with adding the trimming of the form value to allow user to add as many space prefix and suffixes as they want. */
  static trimmedEmailValidator(fb: FormBuilder): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        return Validators.email(fb.control(control.value.trim()))
      }
      return null
    }
  }
  static trimmedTextRequiredValidator(fb: FormBuilder): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        return Validators.required(fb.control(control.value.trim()))
      }
      return null
    }
  }
  static getOptionalTextValidators = () => {
    return [Validators.maxLength(maxTextCharacters)]
  }
  static linesCountValidator(fb: FormBuilder, appCompServ: AppComponentService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        if (
          OwlChatValidationsViewModel.tooManyLinesForInstruction(
            control.value,
            appCompServ.appConfig.config
          ) &&
          !OwlChatValidationsViewModel.lastCarriageReturnAllowed(
            control.value,
            appCompServ.appConfig.config
          )
        ) {
          return { linesCount: true }
        }
      }
      return null
    }
  }
}
