import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  getInstructionsStandards,
  setCurrentInstructions,
  setInstructionsStandardIdToUpdate,
  setUpdateInstructionsUiPayload,
  toggleShowRevertStandardModal,
  updateInstructions,
  updateUpdateInstructionsForm
} from '@action/instructions-page/instructions-page.actions'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  selectCurrentPredefinedInstructions,
  selectCurrentStandardOrDefault,
  selectCurrentStandardTopSectionString,
  selectInstructions,
  selectPageIsInstructions,
  selectShowUpdateInstructionsUiPayload,
  selectStandards,
  selectUpdateInstructionsUiIsVisible
} from '@selector/page/instructions-page/instructions-page.selector'
import { UpdateInstructionDto } from '@view/pages/instructions-page/instructions-page-dto.view'
import {
  InstructionsVm,
  IShowUpdateInstructionsUiPayload
} from '@view/pages/instructions-page/instructions-page.view'
import { Subscription } from 'rxjs'

@Injectable()
export class InstructionsPageComponentService {
  selectInstructions$ = this.store.select(selectInstructions)
  selectPageIsInstructions$ = this.store.select(selectPageIsInstructions)
  selectCurrentStandardTopSectionString$ = this.store.select(selectCurrentStandardTopSectionString)
  selectStandards$ = this.store.select(selectStandards)
  selectCurrentStandard$ = this.store.select(selectCurrentStandardOrDefault)
  selectCurrentPredefinedInstructions$ = this.store.select(selectCurrentPredefinedInstructions)
  selectUpdateInstructionsUiIsVisible$ = this.store.select(selectUpdateInstructionsUiIsVisible)
  selectShowUpdateInstructionsUiPayload$ = this.store.select(selectShowUpdateInstructionsUiPayload)
  subs: Subscription[] = []

  constructor(private store: Store) {}

  init = () => {
    this.handleInitialDataLoading()
    this.subs = [
      this.selectCurrentPredefinedInstructions$.subscribe((instructions) => {
        if (instructions && instructions.length > 0) {
          this.store.dispatch(setCurrentInstructions(getExtendedAction(instructions)))
        }
      })
    ]
  }
  destroy = () => {
    this.subs.forEach((s) => (!s.closed ? s.unsubscribe() : null))
  }

  toggleShowRevertStandardModal = () => {
    this.store.dispatch(toggleShowRevertStandardModal())
  }
  showUpdate = (vm: InstructionsVm) => {
    const payload: IShowUpdateInstructionsUiPayload = {
      show: true,
      instructionsVm: vm
    }

    this.store.dispatch(setUpdateInstructionsUiPayload(getExtendedAction(payload)))
  }

  handleInitialDataLoading = () => {
    this.store.dispatch(getInstructionsStandards())
  }

  handleInstructionsStandardIdToUpdate = (standardId: number) => {
    this.store.dispatch(setInstructionsStandardIdToUpdate(getExtendedAction(standardId)))
  }

  dispatchNewFormValue = (formValue: any) => {
    this.store.dispatch(updateUpdateInstructionsForm(getExtendedAction(formValue)))
  }

  handleUpdateInstructions = (dto: UpdateInstructionDto[]) => {
    this.store.dispatch(updateInstructions(getExtendedAction(dto)))
  }
  handleCloseUpdateInstructionUi = () => {
    this.store.dispatch(
      setUpdateInstructionsUiPayload(
        getExtendedAction({
          show: false,
          instructionsVm: null
        })
      )
    )
  }
}
