import { OwlRoutes, SecondLevelDir } from '@domain/route/app-routes.domain'
import { GetSchoolDto, SchoolDto } from '@model/school/school.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import {
  selectGlobalState,
  selectCurrentOrgStats,
  selectSchoolById,
  selectSchoolConfiguration,
  selectSchoolDtoWithRecommendedPolygon,
  selectSchoolUsers,
  selectUserDto
} from '@selector/auth.selector'
import { selectUrl } from '@selector/route.selector'
import { OwlStateSlices } from '@state/app.state'
import { SchoolMapConfigPageState } from '@state/page/school-map-config-page.state'
import {
  IMapConfigViewModel,
  MapConfigSteps,
  SchoolMapConfigStepViewModel
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'

export const selectSchoolMapConfigPageState = createFeatureSelector<SchoolMapConfigPageState>(
  OwlStateSlices.schoolMapConfig
)
export const selectMapConfigVm = createSelector(selectSchoolMapConfigPageState, (state) =>
  state ? state?.vm : null
)
export const selectUploadedImageFileVm = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state.vm.fileVm
)
export const selectSchoolMapApi = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.mapApi ?? null
)
export const selectImageFileHref = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state.vm.fileVm?.href ?? null
)
export const selectSchoolMapMode = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.mapMode ?? null
)
export const selectSchoolShapeMode = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.shapeMode ?? null
)
//Base layer
export const selectCurrentBaseLayerToggleVm = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.baseLayerSelectVm ?? null
)
export const selectBaseLayerToggleVms = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.baseLayerSelectVms ?? null
)
//Map Config Steps
export const selectCurrentMapConfigStep = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.currentMapConfigStep ?? null
)
export interface ISchoolDtoWithCurrentMapConfigStep {
  step: MapConfigSteps | null
  schoolDto: GetSchoolDto | null
}
export const selectCurrentMapConfigStepWithSchoolDto = createSelector(
  selectCurrentMapConfigStep,
  selectSchoolById,
  (step, schoolDto) => (step ? { step, schoolDto } : null)

)
export const showInstructions = createSelector(selectSchoolMapConfigPageState, (state) =>
  SchoolMapConfigStepViewModel.showInstructions(state)
)
export const selectToolEnabledState = createSelector(
  selectSchoolMapConfigPageState,
  (state) => state?.vm?.mapConfigToolbarEnabledState ?? null
)
export const selectZoomAndHomeShownOnMapConfig = createSelector(
  selectSchoolMapConfigPageState,
  SchoolMapConfigStepViewModel.zoomAndHomeShownOnMapConfig
)
/** We're ready to react to the map config step async when we've attempted to load the osm polygon and we either have data or not. */
export const selectMapConfigViewModelWithState = createSelector(
  selectSchoolMapConfigPageState,
  selectSchoolDtoWithRecommendedPolygon,
  (state, mapConfigPartialState) => {
    const vm: IMapConfigViewModel | null =
      state?.vm?.currentMapConfigStep && mapConfigPartialState
        ? {
          step: state.vm.currentMapConfigStep,
          error: state?.vm?.mapConfigErrorReason ?? null,
          partialState: mapConfigPartialState
        }
        : null
    return vm
  }
)
//Validation
export const selectMapConfigErrorReason = createSelector(
  selectMapConfigVm,
  (vm) => vm?.mapConfigErrorReason ?? null
)
//Slider Selectors
export const selectCurrentProximitySliderValue = createSelector(
  selectMapConfigVm,
  (vm) => vm?.proximitySliderValue ?? null
)
export const selectRotationSliderValue = createSelector(
  selectMapConfigVm,
  (vm) => vm?.rotationSliderValue ?? null
)

// Async Save
export const selectAsyncSavePendingLookup = createSelector(
  selectSchoolMapConfigPageState,
  selectMapConfigErrorReason,
  (state, errorOrNull) => (errorOrNull ? {} : state.asyncSavePendingLookup)
)
//New School Config Selector
export const selectNewSchoolConfiguration = createSelector(
  selectSchoolConfiguration,
  selectCurrentProximitySliderValue,

  (config, schoolProximityBoundary) =>
    // Null check since it can be zero and needs to be saveds
    schoolProximityBoundary !== null
      ? {
        ...config,
        schoolProximityBoundary
      }
      : { ...config }
)
export interface ISchoolDtoWithMapConfigValidationError {
  schoolDto: GetSchoolDto | null
  error: MapConfigValidationErrors | null
}
export const selectSchoolDtoByIdWithMapConfigValidationError = createSelector(
  selectSchoolById,
  selectMapConfigErrorReason,
  (schoolDto, error) => ({ schoolDto, error }) as ISchoolDtoWithMapConfigValidationError
)

export const mapConfigIsReadyToShow = createSelector(
  selectUrl,
  selectGlobalState,
  selectSchoolById,
  (url, authState) => {
    const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId.id] ?? null
    return url.includes(OwlRoutes.firstLevelRoutes.setupSchoolAreasMap) && !!schoolDto
  }
)
export const selectMapconfigDeps = createSelector(
  selectUserDto,
  selectSchoolById,
  selectSchoolUsers,
  selectCurrentOrgStats,
  SchoolMapConfigPageState.getMapConfigDeps
)
export const selectMapConfigSliderLabel = createSelector(
  selectSchoolMapConfigPageState,
  SchoolMapConfigStepViewModel.getSliderLabel
)