import { PictureMarkerSymbol } from '@arcgis/core/symbols'
import { DecoratedAlertViewModel } from '@model/alert.model'
import {
  ChatMessageDtoWithUserIdsPayload,
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload,
  ChatResponseWithPollResponseIdPayload
} from '@model/message.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { MobileUserTypes } from '@model/user/user.model'
import { TimeUtils } from '@shared/time.utils'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { UserLocationViewModel } from '@view/location/user-location.view'
import { IMapChatContentViewModel } from '@view/map/locations/map-control-ui.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisPointFactory } from '../arcgis-point-factory'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { ArcGisMessageGraphicHandler } from '../graphic/message-location-graphic.view'
import { ArcGisPollResponseLocGraphicHandler } from '../graphic/poll-response-location-graphic.view'
import { ArcGisLocationHandler } from '../user-locations/arcgis-user-location.view'

/** Handles non alert/SOS message aka regular message locations being added to the map */
export class ArcGisMessageLocHandler {
  static _handleSertResponse = (
    context: ArcGisMapService,
    state: DashboardPageState,
    payload: ChatMessageDtoWithUserIdsPayload
  ): void => {
    const userMobileId = payload.mobileUserIds[0]
    const currentLocationVm = state.userLocationLookup[userMobileId] ?? null
    if (!currentLocationVm) {
      console.warn(`User location vm not found for user ${userMobileId}`)
      return
    }
    const userType = state.userToTypeLookup[userMobileId]
    if (!userType) {
      return
    }
    let newLocationVm =
      UserLocationViewModel.getDecoratedUserLocationFromChatMessage(currentLocationVm)
    if (!newLocationVm) {
      console.error(
        `DEV ERROR! Attempting to process response for user without location view model.`
      )
      return
    }
    let recentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
      newLocationVm.recentMessageIndicatorType
    )
    let locationAttributes
    if (recentMessageSymbol instanceof PictureMarkerSymbol) {
      //In cast we're going from a poll message to a poll indicator pass in the new url for the icon
      locationAttributes = ArcGisGraphicAttrFac.getLocationAttributes(
        newLocationVm,
        recentMessageSymbol?.url
      )
    } else {
      locationAttributes = ArcGisGraphicAttrFac.getLocationAttributes(newLocationVm)
    }
    ArcGisLocationHandler.handleLocationVm(context, newLocationVm, state.vm.selectedUserMobileId)
    if (locationAttributes && context.popupRef?.attributes?.id === locationAttributes.id) {
      // console.log(`Updating popup attributes for open popup`)
      context.popupRef?.setContent(locationAttributes)
    }
  }
  static handleHistoricChatMessageLocation = (
    context: ArcGisMapService,
    groupedDtos: IMapChatContentViewModel | null,
    clickedAlertIds: string | null
  ): void => {
    // Only handle adding all this historic content to the map once
    if (context.historicChatMessagesAdded) {
      // console.log(`Receiving historic info update but ignoring due to already handled that data`)
      return
    }
    if (!groupedDtos) {
      return
    }
    const {
      threatIndicatorLookup,
      medicalAlertsLookup,
      // TODO Remove all but last threat indicator based on threat model config to fix unnecessary dispatch issue in reprocess vms call
      threatIndicators,
      responseIdToStatusColorEnum,
      responseIdToGetResponseDto,
      studentPollResponses,
      teacherPollResponses,
      otherStaffPollResponses,
      guestPollResponses,
      studentMessages,
      teacherMessages,
      otherStaffMessages,
      guestMessages,
      locationVmLookup
    } = groupedDtos ?? {}
    // TODO Reconile the complication is that we have alerts mixed into messages and poll responses
    // console.log(`Handling historic data
    // alerts ${alerts.length}

    // responseIdToStatusColorEnum ${Object.values(responseIdToStatusColorEnum).length}
    // responseIdToGetResponseDto ${Object.values(responseIdToGetResponseDto).length}

    // studentPollResponses ${studentPollResponses.length}
    // teacherPollResponses ${teacherPollResponses.length}
    // otherStaffPollResponses ${otherStaffPollResponses.length}
    // guestPollResponses ${guestPollResponses.length}

    // studentMessages ${studentMessages.length}
    // teacherMessages ${teacherMessages.length}
    // otherStaffMessages ${otherStaffMessages.length}
    // guestMessages ${guestMessages.length}

    // locationVms ${Object.values(locationVmLookup).length}
    // `)

    Object.entries(threatIndicatorLookup).forEach((userCollection) => {
      //TODO Extract and refactor to remove nesting
      const threatVms = userCollection[1]
      threatVms.forEach((alertVm: DecoratedAlertViewModel) => {
        const timestampString = alertVm.chatResponseDtoPayload.timestamp
        const timestampDateObj = TimeUtils.getDateFromString(timestampString)
        if (
          context.threatModel &&
          timestampDateObj &&
          context.threatModel.timestampExceedsMaxAge(timestampDateObj)
        ) {
          // Add dimmed points if they're older than the threat model allows
          ArcGisPointFactory.addAlertPollResponseToMap(
            context,
            alertVm.chatResponseDtoPayload,
            false,
            alertVm,
            true
          )
        } else {
          ArcGisMessageLocHandler.addAlertPoint(
            context,
            alertVm.chatResponseDtoPayload,
            false,
            alertVm
          )
          //Only add pulsing alerts to those that that haven't been clicked
          if (
            (clickedAlertIds &&
              clickedAlertIds.indexOf(`${alertVm.chatResponseDtoPayload.logicalId}`) === -1) ||
            clickedAlertIds === null
          ) {
            ArcGisMessageLocHandler.addAlertPoint(
              context,
              alertVm.chatResponseDtoPayload,
              true,
              alertVm
            )
          }
        }
      })
    })
    Object.entries(medicalAlertsLookup).forEach((userCollection) => {
      const medicalAlertVms = userCollection[1]
      medicalAlertVms.forEach((medicalAlertVm) => {
        // const timestampDateObj = TimeUtils.getDateFromString(
        //   medicalAlertVm.chatResponseDtoPayload.timestamp
        // )
        // if (
        //   context.threatModel &&
        //   timestampDateObj &&
        //   context.threatModel.timestampExceedsMaxAge(timestampDateObj)
        // ) {
        //   ArcGisPointFactory.addMedicalAlertResponseToMap(
        //     context,
        //     medicalAlertVm.chatResponseDtoPayload,
        //     medicalAlertVm,
        //     true
        //   )
        // } else {
        ArcGisPointFactory.addMedicalAlertResponseToMap(
          context,
          medicalAlertVm.chatResponseDtoPayload,
          medicalAlertVm
        )
        // }
      })
    })
    //Add all the poll payloads to the map
    context._studentPollResponsesLayer?.graphics.addMany(
      ArcGisPointFactory.getPointsForPollResponses(
        responseIdToStatusColorEnum,
        studentPollResponses
      )
    )
    context._teacherPollResponsesLayer?.graphics.addMany(
      ArcGisPointFactory.getPointsForPollResponses(
        responseIdToStatusColorEnum,
        teacherPollResponses
      )
    )
    context._otherStaffPollResponsesLayer?.graphics.addMany(
      ArcGisPointFactory.getPointsForPollResponses(
        responseIdToStatusColorEnum,
        otherStaffPollResponses
      )
    )
    context._guestPollResponsesLayer?.graphics.addMany(
      ArcGisPointFactory.getPointsForPollResponses(responseIdToStatusColorEnum, guestPollResponses)
    )
    //Add all the message payloads to the map
    context._studentMessageLocationLayer?.graphics.addMany(
      ArcGisPointFactory.getPointGraphicsForChatResponseMessages(studentMessages)
    )
    context._teacherMessageLocationLayer?.graphics.addMany(
      ArcGisPointFactory.getPointGraphicsForChatResponseMessages(teacherMessages)
    )
    context._otherStaffMessageLocationLayer?.graphics.addMany(
      ArcGisPointFactory.getPointGraphicsForChatResponseMessages(otherStaffMessages)
    )
    context._guestMessageLocationLayer?.graphics.addMany(
      ArcGisPointFactory.getPointGraphicsForChatResponseMessages(guestMessages)
    )
    // console.log(`locationVms in selectHistoricChatContentWithLookups`)
    // console.log(locationVmLookup)
    Object.values(locationVmLookup).forEach((vm) =>
      ArcGisLocationHandler.handleLocationVm(context, vm)
    )

    if (context.threatModel) {
      context.threatModel.setByHistoricValues(context, responseIdToGetResponseDto, threatIndicators)
    } else {
      console.warn(`DEV ERROR: Threat model not constructed for map service!`)
    }
  }
  /** Either adds a medical alert to the map. */
  // static addMedicalAlertPoint = (
  //   context: ArcGisMapService,
  //   payload: ChatMessageMedicalAlertResponseDtoPayload
  // ) => {
  //   ArcGisPointFactory.addMedicalAlertResponseToMap(context, payload)
  // }
  /** Either adds a pulsing alert or a alert image symbol to the related layer. */
  static addAlertPoint = (
    context: ArcGisMapService,
    alert: ChatResponseDtoPayload,
    isPulsing: boolean,
    alertVm: DecoratedAlertViewModel
  ) => {
    ArcGisPointFactory.addAlertPollResponseToMap(context, alert, isPulsing, alertVm)
  }
  static _handleRealTimeChatResponseReceivedMessageDto = (
    context: ArcGisMapService,
    payload: ChatResponseWithMessagePayload,
    lookup: Record<string, MobileUserTypes>
  ) => {
    const userType = lookup[payload.mobileUserId]
    if (userType === MobileUserTypes.student) {
      context._studentMessageLocationLayer?.graphics.add(
        ArcGisMessageGraphicHandler.getGraphicForMessageResponse(payload)
      )
    }
    if (userType === MobileUserTypes.teacher) {
      context._teacherMessageLocationLayer?.graphics.add(
        ArcGisMessageGraphicHandler.getGraphicForMessageResponse(payload)
      )
    }
    if (userType === MobileUserTypes.otherStaff) {
      context._otherStaffMessageLocationLayer?.graphics.add(
        ArcGisMessageGraphicHandler.getGraphicForMessageResponse(payload)
      )
    }
    if (userType === MobileUserTypes.guest) {
      context._guestMessageLocationLayer?.graphics.add(
        ArcGisMessageGraphicHandler.getGraphicForMessageResponse(payload)
      )
    }
  }
  static _handleRealTimeChatResponsePollResponse = (
    context: ArcGisMapService,
    payload: ChatResponseWithPollResponseIdPayload,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    const { userToTypeLookup, responseIdToStatusColorEnum, vm } = state
    const { pressedPollId } = vm
    const userType = userToTypeLookup[payload.mobileUserId]
    if (userType === MobileUserTypes.student) {
      const graphic = ArcGisMessageGraphicHandler.getGraphicForPollResponse(
        payload,
        responseIdToStatusColorEnum
      )
      if (graphic) {
        context._studentPollResponsesLayer?.graphics.add(graphic)
      }
    }
    if (userType === MobileUserTypes.teacher) {
      const graphic = ArcGisMessageGraphicHandler.getGraphicForPollResponse(
        payload,
        responseIdToStatusColorEnum
      )
      if (graphic) {
        context._teacherPollResponsesLayer?.graphics.add(graphic)
      }
    }
    if (userType === MobileUserTypes.otherStaff) {
      const graphic = ArcGisMessageGraphicHandler.getGraphicForPollResponse(
        payload,
        responseIdToStatusColorEnum
      )
      if (graphic) {
        context._otherStaffPollResponsesLayer?.graphics.add(graphic)
      }
    }
    if (userType === MobileUserTypes.guest) {
      const graphic = ArcGisMessageGraphicHandler.getGraphicForPollResponse(
        payload,
        responseIdToStatusColorEnum
      )
      if (graphic) {
        context._guestPollResponsesLayer?.graphics.add(graphic)
      }
    }
    // Need to recalc the area that potentially has a new poll response to show.
    if (pressedPollId) {
      ArcGisPollResponseLocGraphicHandler.pollResponsesVisibilityHandler(
        context,
        pressedPollId,
        state,
        schoolOwlState
      )
    }
  }
}
