import { GetResponseDto } from './predefined-message.model'

export enum ResponseGroupTypeEnum {
  alert = 'alert',
  medicalAlert = 'medicalAlert',
  status = 'status',
  statusAlert = 'statusAlert'
}
export interface GetResponseGroupDto {
  id: number
  logicalId: string
  schoolId: string
  name: string
  image: string
  type: ResponseGroupTypeEnum
  responses: GetResponseDto[]
  groups: GetResponseGroupDto[]
}
