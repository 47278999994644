import { ActivatedRouteSnapshot, NavigationEnd, Params, RouterStateSnapshot } from '@angular/router'
import { FirstLevelDir, SecondLevelDir } from '@domain/route/app-routes.domain'
import {
  BaseRouterStoreState,
  RouterStateSerializer,
  SerializedRouterStateSnapshot,
  routerRequestAction
} from '@ngrx/router-store'
import { Action } from '@ngrx/store'

export interface IOwlRouterTopLevelState extends BaseRouterStoreState {
  url: string
  navigationId: number
  action?: Action
  state: IOwlRouterState
  lastNavigationEndEvent?: NavigationEnd
}
// export interface IOwlRouteState extends SerializedRouterStateSnapshot {
export interface IOwlRouterState {
  // root: ActivatedRouteSnapshot
  url: string
  params: Params
  queryParams: Params
  // TODO potential add fragments if part of a feature
}

export class CustomRouterSerializer implements RouterStateSerializer<IOwlRouterState> {
  // root?: ActivatedRouteSnapshot
  serialize(routerState: RouterStateSnapshot): IOwlRouterState {
    let route = routerState.root

    while (route.firstChild) {
      route = route.firstChild
    }

    const {
      url,
      root: { queryParams }
    } = routerState

    //Params should come from last route to get all url params
    const { params } = route
    return { url, params, queryParams }
  }
}

export class DefaultRouterState implements IOwlRouterTopLevelState {
  url: string = '/'
  navigationId = -1
  action = routerRequestAction
  state = new DefaultOwlRouterState()
}

export class DefaultOwlRouterState implements IOwlRouterState {
  // root = {} as any
  url = '/'
  params = {}
  queryParams = {}
}
export const defaultDefaultOwlRouterState = new DefaultOwlRouterState()
export const defaultOwlRouterTopLevelState: IOwlRouterTopLevelState = {
  navigationId: 0,
  url: '/',
  action: '' as any,
  state: defaultDefaultOwlRouterState
}
export class RouteStateHelper {
  /** includes a check for a url that allows a map component service to be used. Currently only for school dashboard and school map config. */
  static canSetUpMap = (url: string) => {
    // console.log('Route State Helper, url')
    // console.log(url)
    return (
      url.indexOf(FirstLevelDir.dashboard) > -1 ||
      url.indexOf(FirstLevelDir.setupSchoolAreasMap) > -1
    )
  }
}
