import { ExtendedAction } from '@action/extended-ngrx-action'
import { ExpandedLinkVariant } from '@component/header/dashboard-sidenav/expanded-link/expanded-link.component.model'
import { ButtonIconPlacement } from '@component/shared/button/button.component.view'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { mockUnknownLogicalId } from '@mock/school.mock'
import { AttackerTypeEnum, EmergencyResponeEnum } from '@model/emergency.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { SchoolDto } from '@model/school/school.model'
import { CommonUtils } from '@shared/common.utils'
import { DEFAULT_CHATROOM_ID, defaultMapApi, userDefaultSideNavSettings } from '@shared/constants'
import { isLocalHost } from '@shared/js-window'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { SetAreaAction, SetUserAction } from '@view/area/area.view'
import { ChatRoomTypeEnum, ChatUiViewModel } from '@view/chat/chat.view'
import { IPollInteractionViewModel } from '@view/poll/poll.view'
import { Observable } from 'rxjs'

export enum SideNavLinkIcons {
  showMap = 'showMap',
  schoolMapConfig = 'schoolMapConfig',
  dashboard = 'dashboard',
  schoolMap = 'schoolMap',
  users = 'users',
  communication = 'communication',
  navToDash = 'next-step-circle-blue-arrow'
}

export enum MapUiControlEnum {
  showAreaPanel = 'showAreaPanel',
  showTeachers = 'showTeachers',
  showOtherStaff = 'showOtherStaff',
  showStudents = 'showStudents',
  showGuests = 'showGuests',
  showSubAreas = 'showSubAreas',
  showOldAttackAlerts = 'showOldAttackAlerts'
}
export const mapUiControlToDisplayTextMap: Record<MapUiControlEnum, string> = {
  [MapUiControlEnum.showSubAreas]: 'areas',
  [MapUiControlEnum.showTeachers]: 'teachers',
  [MapUiControlEnum.showOtherStaff]: 'other Staff',
  [MapUiControlEnum.showStudents]: 'students',
  [MapUiControlEnum.showAreaPanel]: 'area panel',
  [MapUiControlEnum.showGuests]: 'guests',
  [MapUiControlEnum.showOldAttackAlerts]: 'old attack alerts'
}
export type IMapUiControlSessionSettings = Record<MapUiControlEnum, boolean>
export interface UserTypeSelectViewModel {
  cb: Function
  isSelected$: Observable<boolean>
  userType: string
  displayText: string
}

// TODO Decide if any other page in the site has this collection of links
export interface IAttackResponseEventTypeViewModel {
  text: string
  icon: AttackerTypeEnum | EmergencyResponeEnum
  cb: Function
}
export interface IMapUiControlsViewModel {
  text$: Observable<string>
  icon: MapUiControlEnum
  cb: Function
  enabled$: Observable<boolean>
}
/** TODO Extract to global scope as this model will be shared between many pages that have a navigation header. */
export interface ISiteLinkViewModel {
  text: string
  isLink: boolean
  link?: string
  icon?: SideNavLinkIcons
  iconPlacement?: ButtonIconPlacement
  cb?: Function
  variant: ExpandedLinkVariant
  //TODO: use another interface
  count?: Observable<number>
  isEnabled$: Observable<boolean>
}
export class DashboardPageViewModel {
  sideNavVisibilitySettings: IMapUiControlSessionSettings = userDefaultSideNavSettings
  disabledAreaCheckStatuses: Record<number, boolean> = {}

  mapApi = defaultMapApi
  chatUiVisible = false
  chatVm = new ChatUiViewModel()
  pollComponentHoverVm: IPollInteractionViewModel | null = null

  // FROM Navigation
  mapVisibleByUserClick = false
  /** When a user clicks on an area we should open the related chat room or create one if it exists. */
  selectedAreaId = 0
  /** Similar to areas, when we click on a user, we should open an existing chat room or create one if it doesn't exist. */
  selectedUserMobileId: string | null = null
  /** In the future we'll be able to select multiple users on the map and start a chat with them. */
  selectedUsers = []

  /** Here to track current press and hold on poll component to display related poll responses on the map. Here because in chat-ui state every click on poll recrates chat content. */
  pressedPollId: string | null = null

  /** Only display links if the school has a dto with state and it's not emergency or suspected, if we're on an allowed URL, as well as the user didn't temporarily toggle on the map view.  */
  static getLinksAreVisibleOnDashboard = (
    url: string,
    schoolDto: SchoolDto | null,
    mapVisibleByUserClick: boolean
    // pageState: DashboardPageState
  ): boolean => {
    //Only for local host temporarily in case you want the dash links to be visible in collaped side nav mode
    // if (isLocalHost()) {
    //   console.warn(`Showing links on dashboard on local host only and temporarliy at that`)
    //   return true
    // }
    //This selector will be used on many pages so we have to check if the schoolDto is null since it'll load later
    if (!schoolDto) {
      return false
    }

    if (mapVisibleByUserClick || SchoolDtoHelper.isEmergencyOrSuspected(schoolDto)) {
      return false
    }
    return true
  }

  /** Determines if top left ribbon is visible */
  static getSchoolOwlStateLabelVisible = (schoolDto: SchoolDto | null): boolean => {
    return schoolDto ? SchoolDtoHelper.isEmergencyOrSuspected(schoolDto) : false
  }

  /** We only show the area panel map when the user either clicks to view the map and then the area panel toggle is true, or if we're in suspected or emergency state and the toggle is set to true.  */
  static getAreaPanelIsVisible = (
    mapVisibleByUserClick: boolean,
    showAreaPanel: boolean,
    schoolOwlStateProperty: SchoolStateEnum | null
  ): boolean => {
    return (
      (mapVisibleByUserClick && showAreaPanel) ||
      ((schoolOwlStateProperty === SchoolStateEnum.suspected ||
        schoolOwlStateProperty === SchoolStateEnum.emergency) &&
        showAreaPanel)
    )
  }

  /** The side nav on the dashboard is collapsed based on two conditions, if the user temporarily toggle to see the map, or if the school state isn't routine */
  static getSideNavIsCollapsed = (
    schoolDto: SchoolDto | null,
    pageState: DashboardPageState
  ): boolean => {
    //This selector will be used on many pages so we have to check if the schoolDto is null since it'll load later, and same with the page state, different pages may need to incorporate logic that determines collapsed state
    if (!schoolDto) {
      return false
    }
    return pageState.vm.mapVisibleByUserClick || SchoolDtoHelper.isEmergencyOrSuspected(schoolDto)
  }

  //FILTER MAP SECTION
  static handleSetMapUiControl = (
    s: DashboardPageState,
    a: ExtendedAction<IMapUiControlSessionSettings>
  ): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        sideNavVisibilitySettings: a.payload
      }
    }
  }
  static handleToggleMapUiControl = (
    s: DashboardPageState,
    a: ExtendedAction<MapUiControlEnum>
  ): DashboardPageState => {
    const newSettings = Object.assign({}, s.vm.sideNavVisibilitySettings)
    newSettings[a.payload] = !newSettings[a.payload]
    return {
      ...s,
      vm: {
        ...s.vm,
        sideNavVisibilitySettings: newSettings
      }
    }
  }
  static toggleShowMap = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        mapVisibleByUserClick: !s.vm.mapVisibleByUserClick
      }
    }
  }
  /** When we click off any area or location on the map, then we can revert to the default chat room, and remove any map selection indicators from the dash view model. */
  static handleClearSelectedMapId = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        selectedAreaId: 0,
        selectedUserMobileId: mockUnknownLogicalId,
        selectedUsers: [],
        chatVm: {
          ...s.vm.chatVm,
          selectedChatRoomId: DEFAULT_CHATROOM_ID
          // TODO Rebuild chat rooms by id and remove any without messages
        }
      }
    }
    // Short Form
    // return {
    //   ...s,
    //   vm: Object.assign({}, s.vm, { selectedAreaId: a.payload })
    // }
  }
  static handleSetSelectedUserMobileId = (
    s: DashboardPageState,
    a: ExtendedAction<SetUserAction>
  ): DashboardPageState => {
    const { mobileUserId, name } = a.payload
    let messagesByChatroomId = Object.assign({}, s.vm.chatVm.messagesByChatroomId)
    let chatRoomIdToAttributesLookup = Object.assign({}, s.chatRoomIdToAttributesLookup)
    // If the chat room for this user doesn't exist create one
    if (!chatRoomIdToAttributesLookup[mobileUserId]) {
      chatRoomIdToAttributesLookup[mobileUserId] = {
        label: name,
        chatRoomId: mobileUserId,
        // TODO Set up handling of many selected user ids
        mobileUserIds: [mobileUserId],
        // areaId: null,
        areaLogicalId: null,
        type: ChatRoomTypeEnum.oneOnOne
      }
    }
    return {
      ...s,
      chatRoomIdToAttributesLookup,
      vm: {
        ...s.vm,
        selectedAreaId: 0,
        selectedUserMobileId: a.payload.mobileUserId,
        chatUiVisible: true,
        chatVm: {
          ...s.vm.chatVm,
          selectedChatRoomId: mobileUserId,
          showChatList: false,
          showSelectPoll: false,
          messagesByChatroomId:
            s.vm.chatVm.messagesByChatroomId[mobileUserId]?.length > 0
              ? messagesByChatroomId
              : {
                ...messagesByChatroomId,
                [mobileUserId]: []
              }
          // TODO Rebuild chat rooms by id and remove any without messages
        }
      }
    }
  }
  static handleSetSelectedAreaId = (
    s: DashboardPageState,
    a: ExtendedAction<SetAreaAction>
  ): DashboardPageState => {
    let messagesByChatroomId = Object.assign({}, s.vm.chatVm.messagesByChatroomId)
    let chatRoomIdToAttributesLookup = Object.assign({}, s.chatRoomIdToAttributesLookup)
    const { logicalId, name, type } = a.payload
    // If there's no chat room for this area, create one
    const chatRoomExists = logicalId in chatRoomIdToAttributesLookup
    if (!chatRoomExists) {
      chatRoomIdToAttributesLookup[logicalId] = {
        areaLogicalId: logicalId,
        chatRoomId: logicalId,
        label: CommonUtils.getSmartAreaName(type, name),
        mobileUserIds: null,
        type: ChatRoomTypeEnum.subarea
      }
    }
    return {
      ...s,
      chatRoomIdToAttributesLookup: chatRoomIdToAttributesLookup,
      vm: {
        ...s.vm,
        selectedAreaId: a.payload.id,
        selectedUserMobileId: mockUnknownLogicalId,
        chatUiVisible: true,
        chatVm: {
          ...s.vm.chatVm,
          selectedChatRoomId: logicalId,
          showChatList: false,
          messagesByChatroomId: chatRoomExists
            ? messagesByChatroomId
            : {
              ...messagesByChatroomId,
              [logicalId]: []
            }
          // TODO Rebuild chat rooms by id and remove any without messages
        }
      }
    }
  }
  static handleClickSelectEvent = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      showSelectEventUi: !s.showSelectEventUi
    }
  }
  //POLL INTERACTIONS
  static setPressedPollId = (
    s: DashboardPageState,
    a: ExtendedAction<string | null>
  ): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        pressedPollId: a.payload
      }
    }
  }
  /** Whenever we hover over a poll component set that in state so we can determine if we need ot let the user know there's no visible users on the map to show responses for. */
  static handlePollComponentHover = (
    s: DashboardPageState,
    a: ExtendedAction<IPollInteractionViewModel>
  ): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        pollComponentHoverVm: a.payload.hover ? a.payload : null
      }
    }
  }
}
