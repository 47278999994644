import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { GetUserDto } from '@model/user/user.model'
import { createAction, props } from '@ngrx/store'

export enum UserApiAction {
  tokenReceived = '[UserApiAction] Token Received',
  pingServerSuccess = '[UserApiAction] Ping Server Success',
  pingServerError = '[UserApiAction] Ping Server Error',

  userDtoSuccess = '[UserApiAction] Used Dto Success',
  userDtoError = '[UserApiAction] Used Dto Error',

  getUsersForSchoolSuccess = '[UserApiAction] Get Users For School Success',
  getUsersForSchoolError = '[UserApiAction] Get Users For School Error ',

  idTokenClaimsForValidationStep = '[UserApiAction] Id Token Claims for Validation Step'
}

export const pingServerSuccess = createAction(
  UserApiAction.pingServerSuccess
)
export const pingServerError = createAction(
  UserApiAction.pingServerError,
  props<ExtendedAction<HttpErrorResponse>>()
)
export const getUserSuccess = createAction(
  UserApiAction.userDtoSuccess,
  props<ExtendedAction<GetUserDto>>()
)
export const getUserError = createAction(UserApiAction.userDtoError, props<ExtendedAction<any>>())

export const getUsersForSchoolSuccess = createAction(
  UserApiAction.getUsersForSchoolSuccess,
  props<ExtendedAction<GetUserDto[]>>()
)
export const getUsersForSchoolError = createAction(
  UserApiAction.getUsersForSchoolError,
  props<ExtendedAction<HttpErrorResponse>>()
)
export const setValidationClaims = createAction(
  UserApiAction.idTokenClaimsForValidationStep,
  props<ExtendedAction<Object>>()
)
