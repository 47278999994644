import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { DeleteAreaSideEffectPayload } from '@domain/dto-helpers/area-model.helper'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import { GetSchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto } from '@model/school/school.model'
import { createAction, props } from '@ngrx/store'

export enum SchoolDtoApiActions {
  patchSchoolSuccess = '[SchoolDtoApiActions] Patch School Success',
  patchSchoolError = '[SchoolDtoApiActions] Patch School Error',

  patchSchoolStateSuccess = '[SchoolDtoApiActions] Patch School Owl State Success',
  patchSchoolStateError = '[SchoolDtoApiActions] Patch School Owl State Error',

  postSchoolAreaDtoSuccess = '[SchoolDtoApiActions] Post School Area Success',
  postSchoolAreaDtoError = '[SchoolDtoApiActions] Post School Area Error',

  patchSchoolAreaDtoSuccess = '[SchoolDtoApiActions] Patch School Area Success',
  patchSchoolAreaDtoError = '[SchoolDtoApiActions] Patch School Area Error',

  pushSignalrSchoolStateSuccess = '[SchoolDtoApiActions] Push Signalr School State Success',
  pushSignalrSchoolStateError = '[SchoolDtoApiActions] Push Signalr School State Error',
  // DELETE
  // area
  deleteSchoolAreaSuccess = '[SchoolDtoApiActions] Delete School Area Success',
  deleteSchoolAreaError = '[SchoolDtoApiActions] Delete School Area Error',
  // boundary
  deleteSchoolPerimeterBoundarySuccess = '[SchoolDtoApiActions] Delete School Boundary Success',
  deleteSchoolPerimeterBoundaryError = '[SchoolDtoApiActions] Delete School Boundary Error'
}
//School Signalr Update
export const pushSignalrSchoolStateSuccess = createAction(
  SchoolDtoApiActions.pushSignalrSchoolStateSuccess,
  props<ExtendedAction<boolean>>()
)
export const pushSignalrSchoolStateError = createAction(
  SchoolDtoApiActions.pushSignalrSchoolStateError,
  props<ExtendedAction<boolean>>()
)
//PATCH
export const patchSchoolSuccess = createAction(
  SchoolDtoApiActions.patchSchoolSuccess,
  props<ExtendedAction<GetSchoolDto>>()
)
export const patchSchoolError = createAction(
  SchoolDtoApiActions.patchSchoolError,
  props<ExtendedAction<HttpErrorResponse>>()
)
export const patchSchoolStateSuccess = createAction(
  SchoolDtoApiActions.patchSchoolStateSuccess,
  props<ExtendedAction<SchoolOwlState>>()
)
export const patchSchoolStateError = createAction(
  SchoolDtoApiActions.patchSchoolStateError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//DELETE
// area
export const deleteSchoolAreaSuccess = createAction(
  SchoolDtoApiActions.deleteSchoolAreaSuccess,
  props<ExtendedAction<DeleteAreaSideEffectPayload>>()
)
export const deleteSchoolAreaError = createAction(
  SchoolDtoApiActions.deleteSchoolAreaError,
  props<ExtendedAction<HttpErrorResponse>>()
)
// boundary
// Future state
// export const deleteSchoolPerimeterBoundarySuccess = createAction(
//   SchoolDtoApiActions.deleteSchoolPerimeterBoundarySuccess,
//   props<ExtendedAction<GetSchoolDto>>()
// )
// Future state
// export const deleteSchoolPerimeterBoundaryError = createAction(
//   SchoolDtoApiActions.deleteSchoolPerimeterBoundaryError,
//   props<ExtendedAction<HttpErrorResponse>>()
// )
//POST
export const postSchoolAreaDtoSuccess = createAction(
  SchoolDtoApiActions.postSchoolAreaDtoSuccess,
  props<ExtendedAction<GetSchoolAreaDto>>()
)
export const postSchoolAreaDtoError = createAction(
  SchoolDtoApiActions.postSchoolAreaDtoError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//PATCH
// area
export const patchSchoolAreaDtoSuccess = createAction(
  SchoolDtoApiActions.patchSchoolAreaDtoSuccess,
  props<ExtendedAction<GetSchoolAreaDto>>()
)
export const patchSchoolAreaDtoError = createAction(
  SchoolDtoApiActions.patchSchoolAreaDtoError,
  props<ExtendedAction<HttpErrorResponse>>()
)
