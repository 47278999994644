<div class="map-config-custom-popup" [ngClass]="{ '--shown': popupVisible }">
  <div class="_area-popup-title">
    {{ cta }}
  </div>
  <div class="_area-validation-message-container">
    <span *ngIf="hasError$ | async" class="--error-icon">
      <img src="./assets/icons/alert-circle.svg" />
    </span>
    <span *ngIf="hasError$ | async" class="--area-validation-message">
      {{ errorMessage$ | async }}
    </span>
    <span *ngIf="errorImageSrc$ | async as imageSrc" class="--area-async-save-icon">
      <ng-container *ngIf="imageSrc">
        <img src="{{ imageSrc }}" />
      </ng-container>
    </span>
  </div>
  <div class="_area-attribute-input-container">
    <div class="__area-type-drop-down-container">
      <mat-form-field>
        <mat-label>{{ areaTypeLabel }}</mat-label>
        <mat-select
          [(ngModel)]="areaTypeValue"
          (selectionChange)="handleSelectionChange()"
          [disabled]="(hasError$ | async) ?? false"
        >
          <mat-option *ngFor="let option of areaTypeOptions" [value]="option.value">{{
            option.display
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="__area-name-input-container">
      <mat-form-field class="example-form-field">
        <mat-label>{{ areaNameInputLabel }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="areaNameInputControl"
        />
        <mat-error *ngIf="areaNameInputControl.invalid">{{ errMsg }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
