import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { LogLevel } from '@azure/msal-browser'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AuthService } from '@service/auth/auth.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { Observable } from 'rxjs'

@Injectable()
export class UnauthRouteGuard implements CanActivate {
  constructor(private authService: AuthService, private appConfigService: AppConfigService, private loggingService: LoggingService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    //First check if app is public version or not
    if (this.appConfigService.isPublicSite()) {
      this.authService.redirectToWelcomePage()
      return false
    }
    this.loggingService.logLocally(`
     Unauthed route guard:
       for route ${route}
       router state url ${state.url}
     `,
      OwlLoggingArea.ROUTING,
      OwlLogLevels.VERBOSE
    )
    if (this.authService.userHasToken()) {
      return false
    } else {
      return true
    }
  }
}
