<div
  class="map-custom-popup --{{ statusColor }}"
  [ngClass]="{ '--shown': popupVisible, '--alert-click': showAction && isAlertPopup }"
  (click)="showAction && isAlertPopup ? onNameClickInAlert() : false"
>
  <div class="map-custom-popup-content">
    <div class="top-row">
      <div class="_popup-pointer --{{ position }}"></div>
      <div *ngIf="isAlertPopup" class="_title --alert-title-icon">
        <ng-container *ngIf="icon; else defaultIcon">
          <div class="alert-icon" [innerHTML]="icon | safe"></div>
        </ng-container>
        <ng-template #defaultIcon>
          <div class="--alert-icon-default">
            <img src="{{ defaultAlertIcon }}" />
          </div>
        </ng-template>
      </div>
      <div *ngIf="isLocationPopup" class="_title --location-title">
        {{ title }}
      </div>
      <div *ngIf="isAreaPopup" class="_title --area-title">{{ areaName }}</div>
    </div>
    <div class="bottom-row">
      <div class="__response" *ngIf="isAreaPopup">
        <app-status-icons [lookup]="lookup"></app-status-icons>
        <div class="_message --area" *ngIf="statusMessage">
          {{ statusMessage }}
        </div>
      </div>
      <div *ngIf="isLocationPopup">
        <div class="_userType">
          {{ userType }}
        </div>
        <div class="_message" *ngIf="statusMessage">
          {{ statusMessage }}
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showAction && !isAlertPopup">
    <div
      class="_action-icon"
      [ngClass]="{ '--disabled': isAreaPopup && (disabled$ | async) }"
      (click)="actionHandler()"
      (mouseenter)="handleActionIconHover(true)"
      (mouseleave)="handleActionIconHover(false)"
    >
      <app-custom-ui-label
        [displayed]="showLabel"
        [text]="actionLabelText"
        [variant]="labelVarient"
        labelClass="action-button-label"
      >
        <img src="./assets/icons/send-status-poll.svg" />
      </app-custom-ui-label>
    </div>
  </ng-container>
  <ng-container *ngIf="showIcon && !isAlertPopup">
    <div class="_message-icon">
      <img src="{{ icon }}" />
    </div>
  </ng-container>
  <ng-container *ngIf="showAction && isAlertPopup">
    <div class="_alert-info">
      <div class="_alert-message">{{ statusMessage }}</div>
      <div class="_alerter-info">
        <div class="_alerter-type">
          <img src="./assets/icons/{{ alerterUserTypeImg }}.svg" />
        </div>
        <div class="_alerter-name">{{ title }}</div>
      </div>
      <div class="_alert-time">{{ timeStampToDisplay }}</div>
    </div>
  </ng-container>
</div>
