import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { AuthRouteGuard } from '@domain/guards/auth-route-guard'
import { PublicRouteGuard } from '@domain/guards/public-route-guard'
import { SchoolAreasMapGuard } from '@domain/guards/school-areas-map-selection.guard'
import { SchoolDashboardGuard } from '@domain/guards/school-dashboard.guard'
import { SchoolValidationSelectionGuard } from '@domain/guards/select-school.guard'
import { UnauthRouteGuard } from '@domain/guards/unauth-route-guard'
import { AuthInterceptorService } from '@service/auth-interceptor/auth-interceptor.service'
import { AuthService } from '@service/auth/auth.service'
import { DownloadFileService } from '@service/browser/file/download-file.service'
import { UploadFileService } from '@service/browser/file/upload-file.service'
import { RouterService } from '@service/core/router.service'
import { ArcGisMapService } from '@service/map/arcgis/arcgis-map.service'
import { MapComponentService } from '@service/map/map.component.service'
import { OpenLayersMapService } from '@service/map/open-layers/open-layers.service'
import { ModalService } from '@service/material/modal.service'
import { CommunicationsStandardApiService } from '@service/network/communicationsStandard.service'
import { EventsApiService } from '@service/network/events.service'
import { MessageApiService } from '@service/network/message.service'
import { OpenStreetService } from '@service/network/openstreet.service'
import { ResponseGroupApiService } from '@service/network/response-group.service'
import { RosterApiService } from '@service/network/roster.service'
import { SignalrService } from '@service/network/signalr.service'
import { UserApiService } from '@service/network/user.service'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    AuthService,
    UserApiService,
    RosterApiService,
    CommunicationsStandardApiService,
    MessageApiService,
    EventsApiService,
    ResponseGroupApiService,
    //Authentication Gurads
    AuthRouteGuard,
    UnauthRouteGuard,
    PublicRouteGuard,
    //School Onboarding Related Guards
    SchoolValidationSelectionGuard,
    SchoolAreasMapGuard,
    SchoolDashboardGuard,
    //Global Services
    RouterService,
    SignalrService,
    ModalService,
    MapComponentService,
    ArcGisMapService,
    OpenLayersMapService,
    OpenStreetService,
    UploadFileService,
    DownloadFileService,
    // Enable once app is calling server endpoints other than signalr and ensure that token isn't being added to signalr requests in the usual way
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule { }
