import { Injectable } from '@angular/core'
import { MobileUserTypes, UploadRosterUserDto } from '@model/user/user.model'
import { ApiService } from './api.service'
import {
  RosterInvitationSummaryDto,
  OwlUserTypes,
  RosterValidationDto
} from '@view/pages/roster-page/roster-page.view'
import { Observable } from 'rxjs'

@Injectable()
export class RosterApiService {
  public readonly serviceBaseDir = 'Roster'
  constructor(private _apiService: ApiService) {}

  validateRoster(
    schoolId: Number,
    userType: OwlUserTypes,
    users: UploadRosterUserDto[]
  ): Observable<RosterValidationDto> {
    return this._apiService.post(
      `/${this.serviceBaseDir}/validate?schoolId=${schoolId}&userType=${userType}`,
      users
    )
  }

  updateRoster(schoolId: Number, userType: OwlUserTypes, users: UploadRosterUserDto[]) {
    return this._apiService.post(
      `/${this.serviceBaseDir}/process?schoolId=${schoolId}&userType=${userType}`,
      users
    )
  }
  getRosterHistory(
    schoolId: Number,
    userType: MobileUserTypes
  ): Observable<RosterInvitationSummaryDto> {
    return this._apiService.get(`/${this.serviceBaseDir}?schoolId=${schoolId}&userType=${userType}`)
  }
}
