<h2 mat-dialog-title>{{ vm.titleText }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="hasConfigContent; else defaultText">
    <ng-content></ng-content>
  </ng-container>
  <ng-template #defaultText>
    {{ vm.promptText }}
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" (click)="onConfirm()">
    {{ vm.confirmButtonText }}
  </button>
  <button mat-button [mat-dialog-close]="true" (click)="onCancel()">
    {{ vm.cancelButtonText }}
  </button>
</mat-dialog-actions>
