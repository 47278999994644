import { mockUnknownLogicalId } from '@mock/school.mock'
import {
  AreaType,
  SchoolAreaType,
  SelectableAreaType,
  isValidSelectableAreaType
} from '@model/school/sub-area.model'
import { AreaStatusIcons, AreaStatusViewModel } from './area-status.view'
import { GraphicAttributesTypeEnum } from './graphic-attributes.model'

export interface IAreaPanelViewModel {
  id: number
  logicalId: string
  /** Derived using getSmartAreaName output using area name and type passed in during vm construction */
  displayString: string
  /** Kept as the original name as specified by the SERT admin */
  name: string
  /** Kept as the original type as specified by the SERT admin */
  type: SelectableAreaType
  message: string
  /** Has an enum value for each icon to display and indicates if it's displayed or not. */
  iconLookup: Record<AreaStatusIcons, boolean>
  areaStatus: StatusColorEnum
}

export interface BaseAreaGraphicAttributes {
  kind: GraphicAttributesTypeEnum
  id: number
  /** TODO Make sure this can be non nullable and convert to string only since most of our area logic requires the presence of a logical id. */
  logicalId: string | null
  name: string
  type: AreaType
  areaType: SchoolAreaType
}
export interface NewAreaGraphicAttributes {
  kind: GraphicAttributesTypeEnum
  logicalId: string
  name: string
  type: SelectableAreaType
  areaType: SchoolAreaType
}
export interface AreaGraphicAttributes extends BaseAreaGraphicAttributes {
  statusVm: AreaStatusViewModel
}
export interface MapConfigEditAreaViewModel {
  schoolId: number
  rings: number[][]
  name: string
  type: SelectableAreaType
}
export enum StatusColorEnum {
  green = 'green',
  yellow = 'yellow',
  red = 'red',
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
  unknown = 'unknown'
}
export interface SetAreaAction {
  id: number
  logicalId: string
  name: string
  type: SelectableAreaType
}
export interface SetUserAction {
  mobileUserId: string
  name: string
}
export class AreaViewModel {
  /** Toggles a window unload handler to warn of losing unsaved area data */
  static bindAsyncSavePendingAlert = (bind: boolean) => {
    if (bind) {
      window.addEventListener('beforeunload', AreaViewModel.handleWarnOfLosingAreaData)
    } else {
      window.removeEventListener('beforeunload', AreaViewModel.handleWarnOfLosingAreaData)
    }
  }
  /** Cancel the event and show a confirmation message */
  static handleWarnOfLosingAreaData = (event: BeforeUnloadEvent) => {
    event.preventDefault()
    event.returnValue = 'A save operation is in progress. Do you really want to leave?'
  }
  static getSetAreaActionFromGraphicAttr(attr: AreaGraphicAttributes): SetAreaAction | null {
    if (isValidSelectableAreaType(attr.type)) {
      return {
        id: attr.id,
        logicalId: attr.logicalId ?? mockUnknownLogicalId,
        name: attr.name,
        type: attr.type as SelectableAreaType
      }
    } else {
      console.warn(`getAreaVmFromGraphicAttributes Invalid area type ${attr.type}`)
      return null
    }
  }
  static getSetAreaActionPayloadFromVm(vm: IAreaPanelViewModel): SetAreaAction | null {
    if (isValidSelectableAreaType(vm.type)) {
      return {
        id: vm.id,
        logicalId: vm.logicalId ?? mockUnknownLogicalId,
        name: vm.name,
        type: vm.type
      }
    }
    console.warn(`areaAreaVmFromAreaPanelVm: Invalid area type ${vm.type}`)
    return null
  }
}
// export interface IAreaStatusContributor {
//   mobileUserId: string
//   timestamp: Date
//   statusColor: StatusColorEnum
// }
