import { Injectable } from '@angular/core'
import { PreloadingStrategy, Route } from '@angular/router'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import { isLocalHost } from '@shared/js-window'
import { GlobalState } from '@state/auth.state'
import { Observable, map, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class OwlModulePreloadingStrategy implements PreloadingStrategy {
  constructor(private store: Store) { }
  /** TODO Add logic that only preloads modules that are allowed by user/school onboarding process, but for now block preloading until user is authenticated. */
  preload(route: Route, load: Function): Observable<any> {
    // console.log(`Preload straetgy`)
    // console.log(`
    //     route.title ${route.title}
    //     load ${load}
    //     route
    //     `)
    // console.log(route)
    //Don't preload anything for local dev to make hot module reloading faster
    if (isLocalHost()) {
      return of(null)
    }
    if (
      this.store.select(selectGlobalState).pipe(map((authState: GlobalState) => !!authState.userDto))
    ) {
      return load()
    }
    return of(null)
  }
}
