import { SignalrAction } from '@action/signalr.action'

export interface EventData {
  email: string
}
export interface SignalrEventProps {
  latency: number
  signalrEventName: SignalrAction
}
/**
 * Verbose wraps console log by logging area
 * while minimal is left in case there's a middle level of logging required in the future
 * none is left to enable by area deactivating of logging
 */
export enum OwlLogLevels {
  NONE = 'NONE',
  MINIMAL = 'MINIMAL',
  VERBOSE = 'VERBOSE'
}
/**
 * Enables logging by named area to aid in troubleshooting any potentials issues
 * Areas can be added as needed
 */
export enum OwlLoggingArea {
  AUTH = 'AUTH',
  MAP = 'MAP',
  ROUTING = 'ROUTING',
  EFFECTS = 'EFFECTS'
}
export type LogConfigKeyValue = { [key in OwlLoggingArea]: OwlLogLevels }
