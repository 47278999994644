import { Injectable } from '@angular/core'
import { BaseStorageService, StorageType } from './base-storage.service'

@Injectable()
export class LocalStorageService extends BaseStorageService {
  override storageType = StorageType.localStorage
  override getService(): Storage {
    return localStorage
  }
  constructor() {
    super()
  }
}
