import { getExtendedAction } from '@action/extended-ngrx-action'
import { handleMapConfigToolbarStateSync } from '@action/school-map-config-page.actions'
import { defaultToolEnabledState } from '@view/map/map.view'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import {
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../../arcgis-map.service'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'

export namespace ArcGisSketchCustomToolStateHandler {
  /** We have to dynamically update the custom tool component in a variety of async events
   * @param errorOrNull is passed as a core concern in terms of preventing interaction when the user causes the map config to be invalid
   * @param activeTool is passed when we click on one of the drawing tools in the custom tool component
   * @param clickedGraphic is passed when we handle a click on the map view and we click on an area or boundary
   */
  export const handleUpdateCustomToolsVm = (
    context: ArcGisMapService,
    errorOrNull: MapConfigValidationErrors | null,
    clickedGraphic: __esri.Graphic | null = null,
    activeTool?: MapConfigToolbarOptions
  ) => {
    let sketchVmForState: Record<MapConfigToolbarOptions, boolean> = {
      ...defaultToolEnabledState
    }
    const isAreaStep = context.activeConfigStep === MapConfigSteps.addAreas
    const isBoundaryStep = context.activeConfigStep === MapConfigSteps.schoolPerimeter
    //     console.log(`
    // isAreaStep ${isAreaStep}
    // isBoundaryStep ${isBoundaryStep}
    //     `)
    //     console.log(`context.activeConfigStep`, context.activeConfigStep)
    //In our click handler we should prevent new interactions while the current one has caused an error state
    //Always use the sketch vm for source of truth regarding undo/redo
    if (errorOrNull) {
      sketchVmForState[MapConfigToolbarOptions.pointer] = false
    }

    if (isAreaStep || isBoundaryStep) {
      sketchVmForState[MapConfigToolbarOptions.redo] = context._sketchViewModel?.canRedo() ?? false
      sketchVmForState[MapConfigToolbarOptions.undo] = context._sketchViewModel?.canUndo() ?? false
      // console.log('context._sketchViewModel?.canRedo()', context._sketchViewModel?.canRedo())
    }
    let hasSavedPerimeter = false
    if (context.schoolDto) {
      hasSavedPerimeter = SchoolDtoHelper.hasBoundary(context.schoolDto)
    }
    if (isAreaStep) {
      const canStartNewInteraction =
        !MapConfigValidationViewModel.errorIsRelatedToAreaGeom(errorOrNull) &&
        !MapConfigValidationViewModel.errorIsRelatedToAreaAttributes(errorOrNull)
      //Only enable new area creation tools if we don't have a graphic in edit mode
      sketchVmForState[MapConfigToolbarOptions.rectangle] = canStartNewInteraction
      sketchVmForState[MapConfigToolbarOptions.circle] = canStartNewInteraction
      sketchVmForState[MapConfigToolbarOptions.polygon] = canStartNewInteraction
    } else if (isBoundaryStep) {
      // Only allow create polygon tools on boundary step if nothing from osm or user removed the map perimeter boundary
      const schoolBoundaryLayerGraphic = context._schoolBoundaryLayer?.graphics.at(0)
      const schoolBoundaryInSketchVm = context._sketchViewModel?.updateGraphics.at(0)
      const showDrawTools =
        !clickedGraphic && !schoolBoundaryInSketchVm && !schoolBoundaryLayerGraphic
      // console.log(`hideBoundaryDrawTools`, showDrawTools)
      // console.log(`schoolBoundaryInSketchVm`, schoolBoundaryInSketchVm)
      // console.log(`schoolBoundaryGraphic`, schoolBoundaryLayerGraphic)
      sketchVmForState[MapConfigToolbarOptions.rectangle] = showDrawTools
      sketchVmForState[MapConfigToolbarOptions.circle] = showDrawTools
      sketchVmForState[MapConfigToolbarOptions.polygon] = showDrawTools
      //If on the boundary step and user has no graphic in sketch vm, and they just removed a graphic
      //Let them re-apply the original saved boundary graphic via back navigate
      // UNDO DELETE BOUNDARY FEATURE IN DEV
      // if (
      //   !schoolBoundaryLayerGraphic &&
      //   !schoolBoundaryInSketchVm &&
      //   !clickedGraphic &&
      //   context._schoolBoundaryGraphic
      // ) {
      //   sketchVmForState[MapConfigToolbarOptions.undo] = true
      // }
    }
    if (clickedGraphic || context._sketchViewModel?.updateGraphics.at(0)) {
      sketchVmForState[MapConfigToolbarOptions.trash] = true
    }
    // Remove ability to delete a saved boundary
    if (hasSavedPerimeter && isBoundaryStep) {
      //For now remove ability to delete boundary feature
      sketchVmForState[MapConfigToolbarOptions.trash] = false
    }
    // console.log(`activeTool`, activeTool)
    //For area step we pass in the clicked graphic from the hit test
    if (activeTool) {
      sketchVmForState[activeTool] = false
    }
    context.store.dispatch(handleMapConfigToolbarStateSync(getExtendedAction(sketchVmForState)))
  }
}
