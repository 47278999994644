import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OwlStateSlices } from '@state/app.state'
import { EventsPageState } from '@state/page/events-page.state'
import { EventVm } from '@view/pages/events-page/events-page.view'

export const selectEventsPageState = createFeatureSelector<EventsPageState>(OwlStateSlices.events)

export const selectEvents = createSelector(
  selectEventsPageState,
  (pageState): EventVm[] => pageState.vm.events
)

export const selectIsLoaded = createSelector(
  selectEventsPageState,
  (pageState): boolean => pageState.getEventsApiState.hasLoaded
)
