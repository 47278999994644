import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  Subscription
} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  static listOfAppBreakpointsToObserve: string[] = [
    Breakpoints.XLarge,
    Breakpoints.Large,
    Breakpoints.Medium,
    Breakpoints.Small,
    Breakpoints.XSmall
  ]

  currentBreakpoint: string = ''

  //TODO get rid of behavior subject pattern and set up sharedAppState file with these observables as selectors from the current breakpoint stored as first prop in sharedAppState aka cross page app state - will be used for things like auth state and app preferences, etc..
  breakpointSubject$ = new BehaviorSubject<string>(this.currentBreakpoint)

  isXtraLarge$: Observable<boolean> = this.breakpointSubject$.pipe(
    map((breakpoint) => breakpoint === Breakpoints.XLarge)
  )
  isLarge$: Observable<boolean> = this.breakpointSubject$.pipe(
    map((breakpoint) => breakpoint === Breakpoints.Large)
  )
  isMedium$: Observable<boolean> = this.breakpointSubject$.pipe(
    map((breakpoint) => breakpoint === Breakpoints.Medium)
  )
  isSmall$: Observable<boolean> = this.breakpointSubject$.pipe(
    map((breakpoint) => breakpoint === Breakpoints.Small)
  )
  isXtraSmall$: Observable<boolean> = this.breakpointSubject$.pipe(
    map((breakpoint) => breakpoint === Breakpoints.XSmall)
  )

  readonly breakpoint$ = this.breakpointObserver
    .observe(ViewportService.listOfAppBreakpointsToObserve)
    .pipe(
      // tap((value) => console.log(value)),
      distinctUntilChanged()
    )

  sub?: Subscription

  init = (): void => {
    this.sub = this.breakpoint$.subscribe(this.breakpointChanged)
  }
  cleanUp = (): void => {
    this.sub?.unsubscribe()
  }

  private breakpointChanged = (breakpointState: BreakpointState): void => {
    // console.log(breakpointState)
    ViewportService.listOfAppBreakpointsToObserve.forEach((b) => {
      if (breakpointState.breakpoints[b]) {
        this.breakpointSubject$.next(b)
      }
    })
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    // console.log(`constructor called in view port service`)
    this.init()
  }
}
