import { Injectable } from '@angular/core'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { GetUserDto, UserDto, UserDtoProps } from '@model/user/user.model'
import { notApplicableDisplayString } from '@shared/constants'

export class UserMetaData {
  email: string = ''
  displayName: string = ''
  oid: string = ''
  static getFromUserDto = (dto: GetUserDto): UserMetaData => {
    return {
      email: dto[UserDtoProps.email] ?? '',
      displayName: UserDtoHelper.getFullName(dto) ?? '',
      oid: dto[UserDtoProps.oid] ?? ''
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserMetaDataService {
  private _userMetaData?: UserMetaData

  get userMetaData(): UserMetaData | undefined {
    return this._userMetaData
  }
  constructor() {}

  init = (dto: GetUserDto) => {
    this._userMetaData = UserMetaData.getFromUserDto(dto)
  }

  public extendPropsWithUserAnalytics = (props: any) => {
    return {
      ...props,
      ...this.userMetaData
    }
  }
  public getUserData = (): UserMetaData | undefined => {
    return this.userMetaData
  }
}
