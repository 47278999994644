import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { MatRadioChange } from '@angular/material/radio'
import { InstructionsPageComponentService } from '@page/instructions/instructions-page/instructions-page.component.service'
import { InstructionsStandardVm } from '@view/pages/instructions-page/instructions-page.view'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-revert-to-standard-modal-checkboxex',
  templateUrl: './revert-to-standard-modal-checkboxex.component.html',
  styleUrls: ['./revert-to-standard-modal-checkboxex.component.scss']
})
export class RevertToStandardModalCheckboxexComponent implements OnInit, OnDestroy {
  selectedOption: number | null = null
  standardDataSub?: Subscription

  constructor(public instructionsPageCompServ: InstructionsPageComponentService) {}

  ngOnInit() {
    this.standardDataSub = this.instructionsPageCompServ.selectCurrentStandard$.subscribe(
      this.handleNewStandardData
    )
  }
  ngOnDestroy() {
    this.standardDataSub?.unsubscribe()
  }

  handleNewStandardData = (standard: InstructionsStandardVm | null): void => {
    this.selectedOption = standard?.id ?? null
  }

  isChecked(id: number): boolean {
    return id === this.selectedOption
  }

  changeStandard(e: MatRadioChange) {
    this.instructionsPageCompServ.handleInstructionsStandardIdToUpdate(e.value)
  }
}
