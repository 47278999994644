import { Injectable } from "@angular/core"
import { PilotUserSignUpDto } from "@model/user/pilot-user-sign-up.model"
import { Observable, of } from "rxjs"
import { ApiService } from "./api.service"
import { HttpHeaders } from "@angular/common/http"


@Injectable()
export class PilotUserApiService {
    public readonly serviceBaseDir = 'PilotUsers'
    constructor(private _apiService: ApiService) { }
    //Sign Up Pilot User
    signUpPilotUser = (dto: PilotUserSignUpDto) => {
        console.log('signUpPilotUser', dto)
        return this._apiService.post(`/${this.serviceBaseDir}`, dto)
    }
    invitePilotUser = () => {
        return this._apiService.post(`/${this.serviceBaseDir}/invite`, {})
    }
    downloadPilotUsers = (): Observable<string> => {
        const headers = new HttpHeaders({
            'Content-Type': 'text/csv',
            'Accept': 'text/csv'
        });
        return this._apiService.get(`/${this.serviceBaseDir}/download`, { headers, responseType: 'text' })
    }
}
