import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  setMapConfigErrorReason,
  setProximitySliderValue
} from '@action/school-map-config-page.actions'
import { Polygon } from '@arcgis/core/geometry'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisGeomValidaiton } from '../geometry/arcgis-geometry-validation.view'
import { ArcGisGeometryHelper } from '../geometry/arcgis-geometry.view'
import { ArcGisAreaGraphicFactory } from '../graphic/area-graphic.view'
import { ArcGisSketchViewModelStates } from '../sketch/arc-gis-sketch-vm-event-handler.view'
import { ArcGisToolEventInfoViewModel } from '../sketch/arc-gis-tool-event-info.view'
import { ArcGisSketchCustomToolStateHandler } from '../sketch/custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'
import { SketchEventTypeEnum, SketchEvents } from '../sketch/sketch-vm.view'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { ArcGisMapViewHandler } from '../map-view/map-view-handler.view'

export namespace ArcgisBoundaryViewModel {
  /** If configuration includes a location proximity buffer value indicate that or use the default value from config */
  export const setBoundaryProximityIfExists = (context: ArcGisMapService) => {
    //First check if there's an edit graphic
    const editGraphic = context._sketchViewModel?.updateGraphics.at(0)
    const boundaryGraphic = context._schoolBoundaryLayer?.graphics.at(0)
    if (editGraphic) {
      ArcgisBoundaryViewModel.setLocationPerimeterBufferIndicator(
        context,
        context._syncRefToProximitySliderValue ??
        context._appConfig.config.BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET,
        boundaryGraphic
      )
    } else if (boundaryGraphic) {
      ArcgisBoundaryViewModel.setLocationPerimeterBufferIndicator(
        context,
        context._syncRefToProximitySliderValue ??
        context._appConfig.config.BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET,
        boundaryGraphic
      )
    }
  }
  /** Used to indicate the buffer around the school boundary perimeter that will still allow location tracking and therefore message sending. */
  export const setLocationPerimeterBufferIndicator = (
    context: ArcGisMapService,
    value: number,
    graphic: __esri.Graphic | null = null
  ): void => {
    let graphicToUse: __esri.Graphic | null = null
    if (value === 0 || graphic === null) {
      // console.log(`No need for proximity diff`)
      if (context._schoolBoundaryProximityLayer?.graphics) {
        context._schoolBoundaryProximityLayer.removeAll()
      }
      return
    }
    if (graphic) {
      graphicToUse = graphic
    } else {
      graphicToUse = context._schoolBoundaryLayer?.graphics.at(0) ?? null
    }
    if (!graphicToUse) {
      throw Error(`DEV ERROR: No sketch graphic passed and no graphic on layer!`)
    }
    if (!graphicToUse?.geometry) {
      console.error(
        `DEV ERROR: Attempting to call setLocationPerimeterBufferIndicator without boundary on the map`
      )
      return
    }
    if (!(graphicToUse?.geometry instanceof Polygon)) {
      console.error(
        `DEV ERROR: Attempting to call setLocationPerimeterBufferIndicator when boundary isn't a polygon geometry`
      )
      return
    }
    let bufferedProximityGraphic: __esri.Polygon = ArcGisGeometryHelper.getBufferedPolygonByFeet(
      graphicToUse.geometry,
      value
    )

    // console.log('bufferedProximityGraphic', bufferedProximityGraphic)
    const diffedGeom = ArcGisGeometryHelper.getDiff(bufferedProximityGraphic, graphicToUse.geometry)
    // Clear the layer if updating
    if (context._schoolBoundaryProximityLayer?.graphics) {
      context._schoolBoundaryProximityLayer.removeAll()
    }

    if (diffedGeom && diffedGeom.type === 'polygon') {
      // console.log(`Adding locaiton proximity buffer`, bufferGraphic)
      context._schoolBoundaryProximityLayer?.add(
        ArcGisAreaGraphicFactory.getLocationProximityBufferGraphic(diffedGeom as Polygon)
      )
      toggleProximityLayer(context, true)
    } else if (!diffedGeom) {
      console.warn('DEV ERROR: Diffed geom for proximity layer failed!')
    }
  }
  export const toggleProximityLayer = (context: ArcGisMapService, visibility: boolean) => {
    //In case user has been to the boundary step already check if the visiblility was toggled off, or show it again when navigating back
    if (context._schoolBoundaryProximityLayer) {
      context._schoolBoundaryProximityLayer.visible = visibility
    }
  }
  /** Checks if the user has a saved boundary and if the map was cleared of it
   * @ deprecated until the feature is re-enabled - for now we can't delete a boundary after we create it
   * @returns boolean
   */
  export const hasSavedButDeletedBoundary = (context: ArcGisMapService): boolean => {
    const hasSavedBoundary = context.schoolDto && SchoolDtoHelper.hasBoundary(context.schoolDto)
    // console.log(`has saved boundary `, hasSavedBoundary)
    const hasSketchedBoundary = context._sketchViewModel?.updateGraphics.at(0)
    const hasBoundaryOnMap = context._schoolBoundaryLayer?.graphics.at(0)
    if (hasSavedBoundary && !hasSketchedBoundary && !hasBoundaryOnMap) {
      return true
    }
    return false
  }
  /** Checks if the user has drawn boundary on map, irrespective of whether it's saved or not
   * @returns boolean
   */
  export const hasDrawnBoundaryOnMap = (context: ArcGisMapService): boolean => {
    const hasSketchedBoundary = context._sketchViewModel?.updateGraphics.at(0)
    const hasBoundaryOnMap = context._schoolBoundaryLayer?.graphics.at(0)
    if (hasSketchedBoundary || hasBoundaryOnMap) {
      return true
    }
    return false
  }
  export const validateBoundaryAndUpdateErrorState = (
    context: ArcGisMapService,
    event:
      | __esri.SketchViewModelUpdateEvent
      | __esri.SketchViewModelRedoEvent
      | __esri.SketchViewModelUndoEvent
  ) => {
    if (!event.graphics[0]) {
      return null
    }
    const editGraphic = event.graphics[0]
    let updateStateIsActive = false
    let editInPauseStated = false
    let createdNewShape = false
    let eventIsUndo = event.type === SketchEventTypeEnum.undo
    let eventIsRedo = event.type === SketchEventTypeEnum.redo

    if (event.type === SketchEvents.update) {
      updateStateIsActive = event.state === ArcGisSketchViewModelStates.active
      editInPauseStated = ArcGisToolEventInfoViewModel.updateToolEventInfoIsOfStoppedVariety(event)
      createdNewShape =
        event.state === ArcGisSketchViewModelStates.start &&
        event.type === SketchEventTypeEnum.update
    }
    // We allow the user to click save and continue once a user has created a valid boundary
    const validationErrorOrNull = ArcGisGeomValidaiton.validateBoundary(context, editGraphic)
    setBoundarySymbolByErrorOrNull(context, validationErrorOrNull, editGraphic)
    const proximityBoundaryGraphic = context._schoolBoundaryProximityLayer?.graphics.at(0)
    const valueForProximity =
      context._syncRefToProximitySliderValue ??
      context._appConfig.config.BOUNDARY_PERIMETER_DEFAULT_PROXIMITY_IN_FEET
    // When we change the boundary update the proximity if it exists and dispatch engage slider
    if (createdNewShape && !validationErrorOrNull && !proximityBoundaryGraphic) {
      context.store.dispatch(setProximitySliderValue(getExtendedAction(valueForProximity)))
    }
    // Update the proximity buffer graphic any time it's valid and has changed or it has just been created, or geom undid or redid
    if (
      (createdNewShape || editInPauseStated || eventIsRedo || eventIsUndo) &&
      !validationErrorOrNull
    ) {
      setLocationPerimeterBufferIndicator(context, valueForProximity, event.graphics[0])
    }

    //Skip dispatching while updates are in progress, considering create, update, redo, and undo
    if (!createdNewShape && !editInPauseStated && !eventIsUndo && !eventIsRedo) {
      return null
    }
    // When the edit is paused or area just created, or we redid or undid we should update validations
    const validationHasChanged = context.mapConfigValidationError !== validationErrorOrNull
    if (validationHasChanged) {
      context.store.dispatch(setMapConfigErrorReason(getExtendedAction(validationErrorOrNull)))
    }
    //And update the boundary related tool items enabled state if the boundary is now valid
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(context, validationErrorOrNull)
    return validationErrorOrNull
  }
  export const setBoundarySymbolByErrorOrNull = (
    context: ArcGisMapService,
    validationErrorOrNull: MapConfigValidationErrors | null,
    graphic: __esri.Graphic,
    isCreateEvent: boolean = false
  ) => {
    if (validationErrorOrNull) {
      graphic.symbol = ArcGisSymbolFactory.failedValidationAreaSymbol.clone()
      ArcGisMapViewHandler.updateToFailedHighlights(context)
      return
    }
    if (isCreateEvent) {
      graphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
    } else {
      graphic.symbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
      ArcGisMapViewHandler.updateToEditHighlights(context)
    }
  }
}
