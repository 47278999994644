import { reloadInstructionsForCommunications } from '@action/auth.action'
import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import {
  getPredefinedGroupsFail,
  getPredefinedGroupsSuccess
} from '@action/messages/predefined-messages-api.actions'
import { getPredefinedGroupsForSchool } from '@action/messages/predefined-messages-page.actions'
import {
  getHistoricChatmessages,
  getHistoricUserLocations,
  sendCheckStatus,
  sendMessage
} from '@action/user/dashboard-page.action'
import {
  getChatMessagesError,
  getChatMessagesSuccess,
  getHistoricUserLocationsError,
  getHistoricUserLocationsSuccess,
  sendCheckStatusError,
  sendCheckStatusSuccess,
  sendMessageError,
  sendMessageSuccess
} from '@action/user/message-api.action'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ChatMessageDto } from '@model/message.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import { selectCurrentChatRoomButtonVm } from '@selector/page/dashboard-page/chat-ui-selectors'
import { selectUserLocationLookup } from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import { AppConfigService } from '@service/app-config/app-config.service'
import { ArcGisGeometryHelper } from '@service/map/arcgis/geometry/arcgis-geometry.view'
import { MessageApiService } from '@service/network/message.service'
import { catchError, map, mergeMap, of } from 'rxjs'

//TODO Integrate after user service set up and integrated
@Injectable()
export class MessageEffects {
  constructor(
    private actions$: Actions,
    private messageService: MessageApiService,
    private store: Store,
    private _appConfig: AppConfigService
  ) { }
  getMessageDtosEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoricChatmessages),
      mergeMap((a: ExtendedAction<number>) =>
        this.messageService.getChatMessages(a.payload).pipe(
          map((res: ChatMessageDto[]) => getChatMessagesSuccess(getExtendedAction(res))),
          catchError((e: HttpErrorResponse) => of(getChatMessagesError(getExtendedAction(e))))
        )
      )
    )
  )
  getHistoricLocationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoricUserLocations),
      mergeMap((a: ExtendedAction<number>) =>
        this.messageService.getLocations(a.payload).pipe(
          map((res) => getHistoricUserLocationsSuccess(getExtendedAction(res))),
          catchError((e: HttpErrorResponse) =>
            of(getHistoricUserLocationsError(getExtendedAction(e)))
          )
        )
      )
    )
  )
  sendCheckStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendCheckStatus),
      concatLatestFrom(() => [
        this.store.select(selectGlobalState),
        this.store.select(selectUserLocationLookup)
      ]),
      mergeMap(([action, authState, userLocationLookup]) => {
        const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId.id]
        const area = schoolDto?.subareas?.find((x) => x.id == action.payload)
        const userIds = ArcGisGeometryHelper.getUsersInArea(
          area?.boundary ?? schoolDto?.boundary ?? null,
          userLocationLookup,
          this._appConfig.config.PROXIMITY_DISTANCE_IN_FEET ?? 1
        )
        return this.messageService
          .sendCheckStatus(authState.selectedSchoolId.id, action.payload, userIds)
          .pipe(
            map(() => sendCheckStatusSuccess()),
            catchError((e: HttpErrorResponse) => of(sendCheckStatusError(getExtendedAction(e))))
          )
      })
    )
  )
  sendMessageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessage),
      concatLatestFrom(() => [
        this.store.select(selectGlobalState),
        this.store.select(selectUserLocationLookup),
        this.store.select(selectCurrentChatRoomButtonVm)
      ]),
      mergeMap(([action, authState, users, currentChatRoomVm]) => {
        // TODO Integrate logic between sub area and whole school polygon and pass in user types to geometery engine
        // const dto: ChatMessageDtoPayload = JSON.parse(JSON.stringify(action.payload))
        // if (currentChatRoomVm?.areaLogicalId) {
        //   const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId.id]
        //   const area = schoolDto?.subareas?.find(
        //     (x) => x.logicalId == currentChatRoomVm?.areaLogicalId
        //   )
        //   if (!area) {
        //     console.error(`Area not found due to no match on logical id after send message.
        //     currentChatRoomVm ${currentChatRoomVm?.areaLogicalId}`)
        //   }
        //   const userIds = ArcGisGeometryHelper.getUsersInArea(
        //     area?.boundary ?? schoolDto?.boundary ?? null,
        //     users,
        //     this._appConfig.config.PROXIMITY_DISTANCE_IN_METERS ?? 1
        //   )
        //   dto.pollToUserIds = userIds as string[]
        // }
        return this.messageService.sendMessage(action.payload).pipe(
          map(() => sendMessageSuccess()),
          catchError((e: HttpErrorResponse) => of(sendMessageError(getExtendedAction(e))))
        )
      })
    )
  )
  getPredefinedMessagesBySchoolId = createEffect(() =>
    this.actions$.pipe(
      ofType(getPredefinedGroupsForSchool),
      mergeMap((a: ExtendedAction<number>) =>
        this.messageService.getPredefinedMessages(a.payload).pipe(
          map((res) => getPredefinedGroupsSuccess(getExtendedAction(res))),
          catchError((e: HttpErrorResponse) => of(getPredefinedGroupsFail(getExtendedAction(e))))
        )
      )
    )
  )
  reloadInstructionsForCommunications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadInstructionsForCommunications),
      map((action) => getPredefinedGroupsForSchool(getExtendedAction(action.payload)))
    )
  )
}
