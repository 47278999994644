import { setScrollPlacesInLocalStorage } from '@action/dashboard-page/chat-ui.actions'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core'
import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { mockSchoolDto } from '@mock/school.mock'
import { mockStudentMobileUserDto, mockUserDto } from '@mock/user.mock'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import { selectUrl } from '@selector/route.selector'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AppComponentService } from '@service/app.component.service'
import { AuthService } from '@service/auth/auth.service'
import { ActivityTimerService } from '@service/core/activity-timer.service'
import { RouterService } from '@service/core/router.service'
import { LoggingService } from '@service/logging/logging.service'
import { UserMetaDataService } from '@service/logging/user-meta-data.service'
import { MIN_UI_RELATED_STATE_THROTTLE_TIME } from '@shared/constants'
import { GlobalStateProps } from '@state/auth.state'
import { Subscription, map } from 'rxjs'

//TODO Read up on docs and best practices around arcgis assets
// import esriConfig from "@arcgis/core/config.js";
// esriConfig.assetsPath = "./assets";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.store.dispatch(setScrollPlacesInLocalStorage(getExtendedAction(true)))
  }

  loggingSub: Subscription | null = null
  loggerInitialized = false
  isPublicPath = false
  isPublicPath$ = this.store.select(selectUrl).pipe(
    map((url) => {
      this.isPublicPath = url?.includes(FirstLevelDir.public)
      return this.isPublicPath
    })
  )
  constructor(
    public appCompServ: AppComponentService,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private routerService: RouterService,
    private userMetaDataService: UserMetaDataService,
    private loggingService: LoggingService,
    private store: Store,
    private activityService: ActivityTimerService
  ) { }
  /** Handle OWL app specific routing if mocking or initialize auth service which will redirect once auth steps are complete. */
  ngOnInit(): void {
    // Skip auth init and logging if public - TODO integrate google analytics for public site user tracking
    if (this.isPublicPath) {
      return
    }

    //If url is for create account flow, don't init auth service, this will be handled on the create account page
    if (this.appConfig.isCreateAccountFlow()) {
      return
    }

    if (this.appConfig.config.USE_AUTH) {
      setTimeout(async () => {
        await this.authService.init()
        if (!this.appConfig.isPublicSite()) {
          this.activityService.init()
        }
      }, MIN_UI_RELATED_STATE_THROTTLE_TIME)
    } else {
      // TODO Do want to maintain an use auth of false for mock purposes?
      // this.routerService.routeToSavedOrDefaultPage(mockUserDto, mockSchoolDto, [
      //   mockStudentMobileUserDto
      // ])
    }
    this.loggingSub = this.store.select(selectGlobalState).subscribe((authState) => {
      if (!this.loggerInitialized && authState[GlobalStateProps.userDto]) {
        this.userMetaDataService.init(authState[GlobalStateProps.userDto])
        this.loggingService.init()
        this.loggerInitialized = true
      }
    })
  }
  ngOnDestroy(): void {
    this.loggingSub?.unsubscribe()
    this.appCompServ.destroy()
    this.activityService.destroy()
  }
}
