import { Injectable } from '@angular/core'
import { HttpResponse } from '@angular/common/http'
import * as XLSX from 'xlsx'

@Injectable()
export class DownloadFileService {
  /** Takes a string of csv data and downloads it */
  downloadGenericCsv = (data: string, fileName: string) => {
    // Split the CSV data by newline to get the rows
    const rows = data.split('\n').map((row) => row.split(','))

    // Create a worksheet from the CSV rows
    const worksheet = XLSX.utils.aoa_to_sheet(rows)

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: 'csv', type: 'array' })

    // Create a Blob from the binary string and trigger the download
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' })
    this.download(blob, fileName)
  }

  downloadZip = (response: HttpResponse<Blob>) => {
    if (response.body == null) return
    const blob = new Blob([response.body], {
      type: response.headers.get('Content-Type') ?? 'application/octet-stream'
    })
    const contentDispositionHeader = response.headers.get('content-disposition')
    if (contentDispositionHeader != null) {
      const contentDispositionHeaderResult = contentDispositionHeader
        .split(';')[1]
        .trim()
        .split('=')[1]
      const contentDispositionFileName = contentDispositionHeaderResult.replace(/"/g, '')
      this.download(blob, contentDispositionFileName)
    }
  }

  download = (blob: Blob, fileName: string) => {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
