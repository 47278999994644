import { getExtendedAction } from '@action/extended-ngrx-action'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcgisAreaViewModel } from '../area/arcgis-area.view'
import { ArcgisBoundaryViewModel } from '../area/arcgis-boundary.view'
import { ArcGisSketchVmAreaEventHandler } from './sketch-vm-event-handlers/arc-gis-sketch-vm-area-event-handler.view'
import { setMapConfigErrorReason } from '@action/school-map-config-page.actions'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'

export enum ArcGisSketchViewModelStates {
  start = 'start',
  active = 'active',
  complete = 'complete',
  cancel = 'cancel'
}

export namespace ArcGisSketchViewModelEventHandler {
  /** Checks a conditional hit test and prevents action if there's a validation error and the user is attempting to initiate interaction with a different area. */
  export const preventInteractionBasedOnHitTest = (
    context: ArcGisMapService,
    response: __esri.HitTestResult | null
  ): boolean => {
    if (!response) {
      return false
    }
    const clickedGraphic = (response.results[0] as __esri.GraphicHit)?.graphic ?? null
    if (!clickedGraphic) {
      context._sketchViewModel?.cancel()
      return true
    }
    const clickedGraphicIsSelectedGraphic =
      clickedGraphic.attributes.id === context.mapConfigPopupRef?.areaAttributes?.id
    //If there is an area validation prevent interaction with other areas
    if (context.mapConfigValidationError && !clickedGraphicIsSelectedGraphic) {
      context._sketchViewModel?.cancel()
      return true
    } else {
      return false
    }
  }
  //NAVIGATION
  //#region
  // /** When navigating away from edit areas we may have to close the map config popup. //Update the sketch vm and the popup in case an area was selected and then update the related state, this step needs to be before tool vm update because sketch vm redo/undo defines that state*/
  export const deselectAreaEditIfSelected = (context: ArcGisMapService) => {
    ArcGisSketchViewModelEventHandler.cancelCreateInProgressIfExists(context)
    //If in edit mode
    if (context.mapConfigPopupRef?.areaAttributes?.id) {
      // NOTE: Complete doesn't actually deselect the update graphic in sketch view model - TODO write into docs about arc gis sketch view model limitations and reason for this code for future devs to use
      context._sketchViewModel?.complete()
      ArcgisAreaViewModel.updateAreaSymbolById(
        context,
        context.mapConfigPopupRef.areaAttributes.id,
        ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      )
      context.mapConfigPopupRef.hidePopup()
    }
  }
  export const cancelCreateInProgressIfExists = (context: ArcGisMapService) => {
    //If in create mode
    if (context._sketchViewModel?.createGraphic) {
      context._sketchViewModel.cancel()
    }
  }
  /** Revert boundary back to display symbol  */
  export const deselectPerimeterBoundary = (context: ArcGisMapService) => {
    //If in edit mode
    if (context._sketchViewModel?.updateGraphics.at(0)) {
      context._sketchViewModel.cancel()
    }
    // Note: This property exists if you check with debugger in local context but not available on type, hence the any
    if ((context._sketchViewModel as any)?.updating) {
      context._sketchViewModel?.complete()
    }
    const boundaryGraphic = context._schoolBoundaryLayer?.graphics?.at(0)
    if (boundaryGraphic) {
      boundaryGraphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
    }
  }
  //#endregion
  //EVENT HANDLERS BINDING
  //#region
  /** Since the user can move from editing boundary to areas we need to clear event handles and reset them */
  export const removeEventHandlers = (context: ArcGisMapService): void => {
    if (context._sketchViewModelEventHandles.length > 0) {
      context._sketchViewModelEventHandles.forEach((h) => {
        h.remove()
      })
    }
  }
  export const addSketchViewModelEventHandlersForBoundary = (context: ArcGisMapService) => {
    if (!context._sketchViewModel) {
      return
    }
    removeEventHandlers(context)
    context._sketchViewModelEventHandles = [
      context._sketchViewModel.on('create', (event) => {
        ArcGisSketchViewModelEventHandler.handleCreateBoundary(context, event)
      }),
      context._sketchViewModel.on('update', (event) => {
        ArcGisSketchViewModelEventHandler.handleUpdateBoundary(context, event)
      }),
      context._sketchViewModel.on('undo', (event) => {
        ArcGisSketchViewModelEventHandler.handleBoundaryGeomUndo(context, event)
      }),
      context._sketchViewModel.on('redo', (event) => {
        ArcGisSketchViewModelEventHandler.handleBoundaryGeomRedo(context, event)
      }),
      context._sketchViewModel.on('delete', (event) => {
        ArcGisSketchViewModelEventHandler.handleDeleteForBoundary(context, event)
      })
    ]
  }
  export const addSketchViewModelEventHandlersForAreas = (context: ArcGisMapService) => {
    if (!context._sketchViewModel) {
      return
    }
    removeEventHandlers(context)
    context._sketchViewModelEventHandles = [
      context._sketchViewModel.on('redo', (event) => {
        ArcGisSketchVmAreaEventHandler.handleAreaGeomRedo(context, event)
      }),
      context._sketchViewModel.on('undo', (event) => {
        ArcGisSketchVmAreaEventHandler.handleAreaGeomUndo(context, event)
      }),
      context._sketchViewModel.on('update', (event) => {
        ArcGisSketchVmAreaEventHandler.handleAreaUpdate(context, event)
      }),
      context._sketchViewModel.on('create', (event) => {
        ArcGisSketchVmAreaEventHandler.handleAreaCreate(context, event)
      }),
      context._sketchViewModel.on('delete', (event) => {
        context._sketchViewModel?.cancel()
      })
    ]
  }
  //#endregion
  // BOUNDARY REGION
  // #region
  export const handleDeleteForBoundary = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelDeleteEvent
  ) => {
    context.store.dispatch(setMapConfigErrorReason(getExtendedAction(MapConfigValidationErrors.boundaryMustExist)))
  }
  /** Handle sketch create for boundary tracing image polygon for saving */
  export const handleUpdateBoundary = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelUpdateEvent
  ) => {
    ArcgisBoundaryViewModel.validateBoundaryAndUpdateErrorState(context, event)
  }
  export const handleCreateBoundary = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelCreateEvent
  ) => {
    if (event.state === ArcGisSketchViewModelStates.complete) {
      context._sketchViewModel?.update(event.graphic)
    }
  }
  export const handleBoundaryGeomRedo = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelRedoEvent
  ) => {
    // console.log(`Handle undo redo`)
    // console.log(event)
    ArcgisBoundaryViewModel.validateBoundaryAndUpdateErrorState(context, event)
  }
  export const handleBoundaryGeomUndo = (
    context: ArcGisMapService,
    event: __esri.SketchViewModelUndoEvent
  ) => {
    // console.log(`Handle undo event`)
    // console.log(event)
    ArcgisBoundaryViewModel.validateBoundaryAndUpdateErrorState(context, event)
  }
  // #endregion
}
