import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { GetPredefinedGroupDto } from '@model/message/predefined-message.model'

import { createAction, props } from '@ngrx/store'

export enum PredefinedMessagesApiActions {
  getPredefinedGroupsSuccess = `[Predefined Groups Actions] Get Predefined Groups By School id Success`,
  getPredefinedGroupsFail = `[Predefined Groups Actions] Get Predefined Groups By School id Fail`
}

export const getPredefinedGroupsSuccess = createAction(
  PredefinedMessagesApiActions.getPredefinedGroupsSuccess,
  props<ExtendedAction<GetPredefinedGroupDto[]>>()
)
export const getPredefinedGroupsFail = createAction(
  PredefinedMessagesApiActions.getPredefinedGroupsFail,
  props<ExtendedAction<HttpErrorResponse>>()
)
