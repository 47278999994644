import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { GetResponseDto } from '@model/message/predefined-message.model'
import { GetResponseGroupDto, ResponseGroupTypeEnum } from '@model/message/response-group.model'
import { ApiState } from '@state/api.state'
import { DashboardPageState } from '@state/page/dashboard-page.state'
export type ResponseIdsByResponseGroupTypeLookup = Record<ResponseGroupTypeEnum, GetResponseDto[]>
export const emptyResponseIdsByResponseGroupTypeLookup = Object.values(
  ResponseGroupTypeEnum
).reduce(
  (accum, curr) => ({
    ...accum,
    [curr]: []
  }),
  {} as ResponseIdsByResponseGroupTypeLookup
)
export class ResponseGroupModelHelper {
  //RESPONSE GROUPS
  //get
  static getResponseGroupsForSchool = (
    s: DashboardPageState,
    a: ExtendedAction<number>
  ): DashboardPageState => {
    return {
      ...s,
      responseGroupsApiState: ApiState.createIsLoadingState()
    }
  }
  static accumResponseDtoByGroupedType = (dtos: GetResponseGroupDto[]) => {
    const responseIdToGetResponseDto: Record<number, GetResponseDto> = {}
    const responseIdsByResponseGroupTypeLookup = {
      ...emptyResponseIdsByResponseGroupTypeLookup
    }
    const processResponseDto = (groupDto: GetResponseGroupDto, r: GetResponseDto) => {
      responseIdToGetResponseDto[r.id] = r
      // Create a lookup up of all response ids associated to a specific type of resposne group for later reference
      const existingIds = responseIdsByResponseGroupTypeLookup[groupDto.type] ?? []
      if (existingIds.length > 0) {
        responseIdsByResponseGroupTypeLookup[groupDto.type] = [...existingIds, r]
      } else {
        responseIdsByResponseGroupTypeLookup[groupDto.type] = [r]
      }
    }
    dtos.forEach((dto) => {
      if (dto.responses) {
        dto.responses.forEach((r) => processResponseDto(dto, r))
      }
      // Process groups nested
      if (dto.groups.length > 0) {
        dto.groups.forEach((g: GetResponseGroupDto) => {
          if (g.responses) {
            g.responses.forEach((r: GetResponseDto) => processResponseDto(dto, r))
          }
        })
      }
    })
    return {
      responseIdToGetResponseDto,
      responseIdsByResponseGroupTypeLookup
    }
  }
  /** Predefined messages update the responseIdToGetResponseDto collection with messages that are sent to OWL mobile but now we need to update it with unsolicited poll response items to complete the lookup, spread original lookup as it's a race condition between which api endpoint completes first */
  static getResponseGroupsSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetResponseGroupDto[]>
  ): DashboardPageState => {
    // console.log(`getResponseGroupsSuccess`)
    // console.log(a.payload)
    const { responseIdToGetResponseDto, responseIdsByResponseGroupTypeLookup } =
      ResponseGroupModelHelper.accumResponseDtoByGroupedType(a.payload)
    const newResponseIdToGetResponseDto = {
      ...s.responseIdToGetResponseDto,
      ...responseIdToGetResponseDto
    }
    // Need to accumulate responseIdToStatusColorEnum
    const newResponseIdToStatusColorEnum = {
      ...s.responseIdToStatusColorEnum
    }
    Object.values(newResponseIdToGetResponseDto).forEach((r) => {
      if (r.color) {
        newResponseIdToStatusColorEnum[r.id] = r.color ?? null
      }
    })
    Object.values(responseIdsByResponseGroupTypeLookup).forEach((getResponseDtos) => {
      getResponseDtos.map((r) => {
        if (r.color) {
          newResponseIdToStatusColorEnum[r.id] = r.color ?? null
        }
      })
    })
    // console.log('newResponseIdToStatusColorEnum', newResponseIdToStatusColorEnum)
    // console.log('responseIdsByResponseGroupTypeLookup', responseIdsByResponseGroupTypeLookup)
    return {
      ...s,
      responseGroupsApiState: ApiState.createHadLoadedState(),
      responseIdToGetResponseDto: newResponseIdToGetResponseDto,
      responseIdsByResponseGroupTypeLookup,
      responseIdToStatusColorEnum: newResponseIdToStatusColorEnum
    }
  }
  static getResponseGroupsFail = (
    s: DashboardPageState,
    a: ExtendedAction<HttpErrorResponse>
  ): DashboardPageState => {
    // groupedPredefinedMessages: null
    return {
      ...s,
      responseGroupsApiState: ApiState.createHasErrorState()
    }
  }
}
