import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  completeMapConfig,
  engageMapConfigSlider,
  handleMapConfigToolbarClick,
  navigateToStepInMapConfig,
  setProximitySliderValue,
  updateRotationSlider,
  updateSchoolProximityBoundary
} from '@action/school-map-config-page.actions'
import { Injectable } from '@angular/core'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { Store } from '@ngrx/store'
import {
  ISchoolDtoWithCurrentMapConfigStep,
  ISchoolDtoWithMapConfigValidationError,
  mapConfigIsReadyToShow,
  selectAsyncSavePendingLookup,
  selectBaseLayerToggleVms,
  selectCurrentBaseLayerToggleVm,
  selectCurrentMapConfigStep,
  selectCurrentMapConfigStepWithSchoolDto,
  selectCurrentProximitySliderValue,
  selectMapConfigErrorReason,
  selectMapConfigSliderLabel,
  selectRotationSliderValue,
  selectSchoolDtoByIdWithMapConfigValidationError,
  selectToolEnabledState,
  selectZoomAndHomeShownOnMapConfig,
  showInstructions
} from '@selector/page/school-map-config-page/school-map-config-page-state.selector'
import { MapConfigInstructionsText } from '@service/app-config/app-config.model'
import { AppConfigService } from '@service/app-config/app-config.service'
import { MapComponentService } from '@service/map/map.component.service'
import { MIN_MAIN_UI_STATE_UPDATE_THROTTLE_TIME } from '@shared/constants'
import {
  MapConfigToolbarOptions,
  ToolbarOptionsViewModel,
  bottomSectionToolBar,
  getTopSectionEnumValues
} from '@view/map/school-map-config/school-map-config-toolbar.view'
import { MapConfigValidationErrors, MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import {
  ISchoolMapConfigStepViewModel,
  MapConfigSteps,
  SchoolMapConfigStepViewModel
} from '@view/pages/school-map-config-page/school-map-config.view'
import {
  MapConfigSliderTypeEnum,
  MapConfigSliderViewModel
} from '@view/pages/school-map-config-page/school-map-slider.view'
import { Observable, Subscription, combineLatest, debounceTime, map } from 'rxjs'

@Injectable()
export class SchoolMapConfigPageComponentService {
  sliderLabel$ = this.store.select(selectMapConfigSliderLabel)
  /** Map config ready to be shown once school dto is loaded. */
  pageIsReadyToShow$ = this.store
    .select(mapConfigIsReadyToShow)
    .pipe(debounceTime(MIN_MAIN_UI_STATE_UPDATE_THROTTLE_TIME))

  currentStep: MapConfigSteps | null = null
  errorReason: MapConfigValidationErrors | null = null
  subs: Subscription[] = []

  asyncSavePending$ = this.store.select(selectAsyncSavePendingLookup)
  errorReason$ = this.store.select(selectMapConfigErrorReason)

  baseLayerToggleVms$ = this.store.select(selectBaseLayerToggleVms)
  currentBaseLayerToggleVm$ = this.store.select(selectCurrentBaseLayerToggleVm)

  // mapConfigStepVms$ = this.store.select(selectMapConfigStepVms)
  schoolDtoWithMapConfigErrors$ = this.store.select(selectSchoolDtoByIdWithMapConfigValidationError)

  currentMapConfigStep$ = this.store.select(selectCurrentMapConfigStep)
  selectCurrentMapConfigStepWithSchoolDto$ = this.store.select(selectCurrentMapConfigStepWithSchoolDto)

  toolEnabledState$ = this.store.select(selectToolEnabledState)
  zoomAndHomeShown$ = this.store.select(selectZoomAndHomeShownOnMapConfig)
  showInstructions$ = this.store
    .select(showInstructions)
    .pipe(map((show) => show && this.instructionsText))

  handleSchoolDtoWithCurrStepForCtaText = (payload: ISchoolDtoWithCurrentMapConfigStep | null): string => {
    const { step, schoolDto } = payload ?? {}
    if (!step) {
      console.warn(`No step found for ctaBtnText$`)
      return ''
    }
    if (!schoolDto) {
      console.warn(`No schoolDto found`)
      return ''
    }
    const mapConfigComplete = SchoolDtoHelper.hasCompleteMapConfigSteps(schoolDto)
    if (mapConfigComplete) {
      return SchoolMapConfigStepViewModel.completeMapConfigCtaButtonTextLookup[step]
    } else {
      return SchoolMapConfigStepViewModel.incompleteMapConfigCtaBtnTextLookup[step]
    }
  }
  ctaBtnText$ = this.selectCurrentMapConfigStepWithSchoolDto$.pipe(
    map(this.handleSchoolDtoWithCurrStepForCtaText)
  )

  showLayerSelection$ = this.currentMapConfigStep$.pipe(
    map((step) => step === MapConfigSteps.mapView)
  )
  showBoundaryProximitySlide$ = this.currentMapConfigStep$.pipe(
    map((step) => step === MapConfigSteps.schoolPerimeter)
  )
  onSetAreasStep$ = this.currentMapConfigStep$.pipe(
    map((step) => step === MapConfigSteps.addAreas)
  )
  //TODO May need school dto here to update this complete config concept
  // completeConfiguration$ = this.currentMapConfigStep$.pipe(
  //   map((stepVm) => stepVm?.option === MapConfigSteps.completeConfiguration)
  // )
  getCurrentSliderVm$ = combineLatest([
    this.currentMapConfigStep$,
    this.store.select(selectCurrentProximitySliderValue),
    this.store.select(selectRotationSliderValue),
    this.errorReason$
  ]).pipe(
    map(([step, proximitySliderValue, rotationSliderValue, error]) => {
      const onPerimeterStep = step === MapConfigSteps.schoolPerimeter
      const onMapViewStep = step === MapConfigSteps.mapView
      if (!onPerimeterStep && !onMapViewStep) {
        return null
      }
      // Null comparison since value can be saved as 0
      if (onPerimeterStep && proximitySliderValue !== null) {
        return MapConfigSliderViewModel.getVm(
          step,
          error,
          this.appConfigService.config.BOUNDARY_PERIMETER_MAX_PROXIMITY_IN_FEET,
          proximitySliderValue,
          this.onProximitySliderChange,
          this.onProximitySliderPress,
          this.sliderProximityRelease,
          MapConfigSliderTypeEnum.perimeter
        )
      }
      if (onMapViewStep && rotationSliderValue !== null) {
        return MapConfigSliderViewModel.getVm(
          step,
          error,
          360,
          rotationSliderValue,
          this.sliderRotationChange,
          this.sliderRotationPress,
          this.sliderRotationRelease,
          MapConfigSliderTypeEnum.compass
        )
      }
      return null
    })
  )

  backButtonDisplayed$ = this.currentMapConfigStep$.pipe(
    map((s) => s && s !== MapConfigSteps.mapView)
  )
  // /** @deprecated Don't use forward or back logic anymore */
  // mapConfigBackNavBlocked$ = this.store.select(selectMapConfigBackNavBlocked)
  // /** @deprecated Don't use forward or back logic anymore */
  // selectMapConfigForwardNavBlocked$ = this.store.select(selectMapConfigForwardNavBlocked).pipe(
  //   // We never block forward when the user is on the map view step as there is no validation
  //   map((blocked) => {
  //     if (this.currentStep === MapConfigSteps.mapView) {
  //       return false
  //     }
  //     return blocked
  //   })
  // )
  // /** @deprecated Don't use forward or back logic anymore */
  // selectMapConfigBackwardNavBlocked$ = this.store.select(selectMapConfigBackNavBlocked)

  // Deprecated
  // forwardBlocked: boolean = false
  // backwardBlocked: boolean = false

  topToolbarVm$ = this.toolEnabledState$.pipe(
    map(
      (enabledState): ToolbarOptionsViewModel =>
        getTopSectionEnumValues().reduce((accum, curr: MapConfigToolbarOptions) => {
          return {
            ...accum,
            [curr]: this.getToolBarVmInstance(curr, enabledState)
          }
        }, {} as ToolbarOptionsViewModel)
    ),
    map((v) => Object.values(v))
  )
  bottomToolbarVm$ = this.toolEnabledState$.pipe(
    map(
      (enabledState): ToolbarOptionsViewModel =>
        bottomSectionToolBar.reduce((accum, curr: MapConfigToolbarOptions) => {
          return {
            ...accum,
            [curr]: this.getToolBarVmInstance(curr, enabledState)
          }
        }, {} as ToolbarOptionsViewModel)
    ),
    map((v) => Object.values(v))
  )

  getToolBarVmInstance = (
    o: MapConfigToolbarOptions,
    enabledState: Record<MapConfigToolbarOptions, boolean>
  ) => ({
    enabled: enabledState[o],
    cb: () => this.handleDispatchAction(o),
    option: o,
    visible: true,
    imageSrc: `assets/icons/map-config-toolbar/light/${o}.svg`
  })
  instructionsText?: Record<MapConfigSteps, MapConfigInstructionsText>
  constructor(
    public store: Store,
    private appConfigService: AppConfigService,
    private mapComponentService: MapComponentService
  ) {
  }
  updateCurrentStep = (step: MapConfigSteps | null) => {
    console.log("updateCurrentStep called for step: ", step)
    this.currentStep = step
  }
  init = () => {
    const { MAP_CONFIG_INSTRUCTIONS } = this.appConfigService.config ?? {}
    this.instructionsText = {
      ...MAP_CONFIG_INSTRUCTIONS
    }
    this.subs = [
      this.currentMapConfigStep$.subscribe(this.updateCurrentStep),
      this.errorReason$.subscribe((e) => (this.errorReason = e))
    ]
  }
  destroy = () => {
    this.subs.forEach((s) => s.unsubscribe())
  }

  getStepIsActive = (s: MapConfigSteps): Observable<boolean> => {
    return this.currentMapConfigStep$.pipe(map((step) => step === s))
  }

  getIsCompleteStep = (s: MapConfigSteps): Observable<boolean> => {
    return this.schoolDtoWithMapConfigErrors$.pipe(
      map(({ error, schoolDto }) => SchoolDtoHelper.stepIsComplete(s, schoolDto)
      )
    )
  }

  handleGoToMapConfigStep = (step: MapConfigSteps, currentStep: MapConfigSteps) => {
    //Ignore clicks on the current step
    if (step === currentStep) return
    console.log("handleGoToMapConfigStep called for step: ", step)
    console.log("current currentStep: ", currentStep)
    this.saveCurrentStep()
    this.dispatchNavToStep(step)
  }

  dispatchNavToStep = (step: MapConfigSteps) => {
    this.store.dispatch(navigateToStepInMapConfig(getExtendedAction(step)))
  }

  handleStepIsDisabled = (step: MapConfigSteps, { error, schoolDto }: ISchoolDtoWithMapConfigValidationError): boolean => {
    // const hasAreas = SchoolDtoHelper.stepIsComplete(MapConfigSteps.addAreas, schoolDto)
    const hasBoundary = SchoolDtoHelper.stepIsComplete(MapConfigSteps.schoolPerimeter, schoolDto)
    const hasMapViewConfig = SchoolDtoHelper.stepIsComplete(MapConfigSteps.mapView, schoolDto)
    const areaHasErrors = MapConfigValidationViewModel.errorIsRelatedToAreaStep(error)
    const boundaryHasErrors = MapConfigValidationViewModel.errorIsRelatedToBoundaryGeom(error)
    const mapGeometryHasErrors = areaHasErrors || boundaryHasErrors
    // console.log(`New error reason is ${error} and step ${step}`)
    if (this.currentStep === step) {
      return false
    }
    switch (step) {
      case MapConfigSteps.mapView:
        return mapGeometryHasErrors
      case MapConfigSteps.schoolPerimeter:
        return areaHasErrors || !hasBoundary || !hasMapViewConfig
      case MapConfigSteps.addAreas:
        return boundaryHasErrors || !hasBoundary
      default:
        return true
    }
  }

  stepIsDisabled = (step: MapConfigSteps): Observable<boolean> => {
    return this.schoolDtoWithMapConfigErrors$.pipe(
      map(payload => this.handleStepIsDisabled(step, payload))
    )
  }
  handleCtaButtonInteractable = (payload: ISchoolDtoWithMapConfigValidationError): boolean => {
    const { error, schoolDto } = payload
    const hasAreas = SchoolDtoHelper.stepIsComplete(MapConfigSteps.addAreas, schoolDto)
    switch (this.currentStep) {
      case MapConfigSteps.mapView:
        // The map view step is always valid to save
        return true
      case MapConfigSteps.schoolPerimeter:
        const boundaryStepAllowedSave = !MapConfigValidationViewModel.
          errorIsRelatedToBoundaryGeom(error)
        // console.log(`Boundary step allowed to save: ${boundaryStepAllowedSave}`)
        return boundaryStepAllowedSave
      case MapConfigSteps.addAreas:
        const areasStepAllowedSave = !MapConfigValidationViewModel.errorIsRelatedToAreaStep(error) && hasAreas
        // console.log(`Add areas step allowed to save: ${areasStepAllowedSave}`)
        return areasStepAllowedSave
      default:
        throw Error('Invalid step, passed in ISchoolMapConfigStepViewModel passed to handleMapConfigSaveBtn')
    }
  }

  /** Based on the step we're on determine if we're allowed to save.
   * Set up handler for each step validity
   */
  allowedToSave$ = this.schoolDtoWithMapConfigErrors$.pipe(map(this.handleCtaButtonInteractable))

  /** Based on the step we're on we save differently 
  */
  saveCurrentStep = (): void => {
    //Set up handle for each step
    switch (this.currentStep) {
      case MapConfigSteps.mapView:
        this.mapComponentService.handleSaveMapView()
        break
      case MapConfigSteps.schoolPerimeter:
        this.mapComponentService.handleSaveBoundaryClick()
        break
      case MapConfigSteps.addAreas:
        this.mapComponentService.handleCompleteMap()

        break
      default:
        throw Error('Invalid step, passed in ISchoolMapConfigStepViewModel passed to handleSaveForStep')
    }
  }
  /** Here specifically for the map config steps cta button. */
  saveAndMoveNext = () => {
    this.saveCurrentStep()
    this.moveToNextStep()
  }
  moveToNextStep = (): void => {
    switch (this.currentStep) {
      case MapConfigSteps.mapView:
        this.dispatchNavToStep(MapConfigSteps.schoolPerimeter)
        break
      case MapConfigSteps.schoolPerimeter:
        this.dispatchNavToStep(MapConfigSteps.addAreas)
        break
      case MapConfigSteps.addAreas:
        this.store.dispatch(completeMapConfig())
        break
      default:
        throw Error('Invalid step, passed in ISchoolMapConfigStepViewModel passed to moveToNextStep')
    }
  }
  handleDispatchAction = (o: MapConfigToolbarOptions): void => {
    this.store.dispatch(handleMapConfigToolbarClick(getExtendedAction(o)))
  }
  //SLIDER SECTION
  // proximity slider
  sliderProximityRelease = (value: number): void => {
    this.store.dispatch(updateSchoolProximityBoundary(getExtendedAction(value)))
  }
  onProximitySliderChange = (value: number): void => {
    this.store.dispatch(setProximitySliderValue(getExtendedAction(value)))
  }
  onProximitySliderPress = (value: number): void => {
    this.store.dispatch(engageMapConfigSlider(getExtendedAction(value)))
  }
  //rotation
  sliderRotationRelease = (value: number): void => {
    this.store.dispatch(updateRotationSlider(getExtendedAction(value)))
  }
  sliderRotationChange = (value: number): void => {
    this.store.dispatch(updateRotationSlider(getExtendedAction(value)))
  }
  sliderRotationPress = (value: number): void => {
    this.store.dispatch(updateRotationSlider(getExtendedAction(value)))
  }
  /** Steps are built with internal observables since the completed active, disabled, or complete can be affected by what the user does in the steps. */
  mapConfigStepVms: ISchoolMapConfigStepViewModel[] = Object.values(MapConfigSteps).map((s: MapConfigSteps, i: number) => ({
    step: i + 1,
    option: s,
    displayText: SchoolMapConfigStepViewModel.mapConfigDisplayNamesLookup[s],
    complete$: this.getIsCompleteStep(s),
    active$: this.getStepIsActive(s),
    disabled$: this.stepIsDisabled(s),
  }))
}
