import { InstructionsVm } from '@view/pages/instructions-page/instructions-page.view'

export const instructionsToDisplayMock: InstructionsVm[] = [
  {
    id: 1,
    title: 'Test 1',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 2,
    title: 'Test 2',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 3,
    title: 'Test 3',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 4,
    title: 'Test 4',
    content: [],
    isModified: true,
    isSpecialForStudents: false
  },
  {
    id: 5,
    title: 'Test 5',
    content: [],
    isModified: false,
    isSpecialForStudents: true
  },
  {
    id: 6,
    title: 'Test 6',
    content: [],
    isModified: true,
    isSpecialForStudents: true
  },
  {
    id: 7,
    title: 'Test 7',
    content: [],
    isModified: true,
    isSpecialForStudents: true
  },
  {
    id: 8,
    title: 'Test 8',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 9,
    title: 'Test 9',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 10,
    title: 'Test 10',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  },
  {
    id: 11,
    title: 'Test 11',
    content: [],
    isModified: false,
    isSpecialForStudents: false
  }
]
