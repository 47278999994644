import {
  SchoolOwlState,
  SchoolPreferenceEnum,
  SchoolStateEnum
} from '@model/school/school-configuation.model'

export const mockSchoolOwlState: SchoolOwlState = {
  schoolId: 1,
  schoolLogicalId: '1',
  drillMode: false,
  preference: SchoolPreferenceEnum.manual,
  state: SchoolStateEnum.routine,
  lastSuspectedEvent: null,
  historicDateExtensionOverride: null,
  additionalData: null
}
