import Graphic from '@arcgis/core/Graphic'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'

export namespace ArcgisAlertGraphicHandler {
  export const updateAlertSymbolsConditionally = (
    context: ArcGisMapService,
    g: Graphic,
    opaque: boolean
  ): boolean => {
    if (
      context.popupRef?.isShownDueToClick &&
      g.attributes?.id === context.popupRef?.attributes?.id
    ) {
      return false
    }
    g.symbol = ArcGisSymbolFactory.getSymbolForMedicalAlertMessage(opaque)
    return true
  }
}
