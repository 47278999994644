import { ExtendedAction } from '@action/extended-ngrx-action'
import { GetPredefinedGroupDto } from '@model/message/predefined-message.model'
import { IInstructionsFormValue } from '@model/message/update-instructions-form.model'
import { createAction, props } from '@ngrx/store'
import {
  CommunicationsStandardDto,
  UpdateInstructionDto
} from '@view/pages/instructions-page/instructions-page-dto.view'
import { IShowUpdateInstructionsUiPayload } from '@view/pages/instructions-page/instructions-page.view'

export enum InstructionsPageActions {
  toggleShowRevertStandardModal = '[InstructionsPageActions] Toggle Show Revert Standard Modal',
  toggleShowRevertStandardWarningModal = '[InstructionsPageActions] Toggle Show Revert Standard Warning Modal',

  getInstructionsStandards = '[InstructionsPageActions] Get Instructions Standards',
  getCurrentInstructionsStandard = '[InstructionsPageActions] Get Current Instructions Standard',
  setInstructionsStandards = '[InstructionsPageActions] Set Instructions Standards',
  setCurrentInstructionsStandard = '[InstructionsPageActions] Set Current Instructions Standard',
  setInstructionsStandardIdToUpdate = '[InstructionsPageActions] Set Instructions Standard Id To Update',

  updateCurrentInstructionsStandard = '[InstructionsPageActions] Update Current Instructions Standard',

  setCurrentInstructions = '[InstructionsPageActions] Set Current Instructions',
  setUpdateInstructionsUiPayload = '[InstructionsPageActions] Set Update Instructions Ui Payload',

  updateUpdateInstructionsForm = '[InstructionsPageActions] Update UpdateInstructions Form',
  updateInstructions = '[InstructionsPageActions] Update Instructions'
}

export const toggleShowRevertStandardModal = createAction(
  InstructionsPageActions.toggleShowRevertStandardModal
)
export const toggleShowRevertStandardWarningModal = createAction(
  InstructionsPageActions.toggleShowRevertStandardWarningModal
)

export const getInstructionsStandards = createAction(
  InstructionsPageActions.getInstructionsStandards
)
export const getCurrentInstructionsStandard = createAction(
  InstructionsPageActions.getCurrentInstructionsStandard
)
export const setInstructionsStandards = createAction(
  InstructionsPageActions.setInstructionsStandards,
  props<ExtendedAction<CommunicationsStandardDto[]>>()
)
export const setCurrentInstructionsStandard = createAction(
  InstructionsPageActions.setCurrentInstructionsStandard,
  props<ExtendedAction<number>>()
)
export const setInstructionsStandardIdToUpdate = createAction(
  InstructionsPageActions.setInstructionsStandardIdToUpdate,
  props<ExtendedAction<number>>()
)
export const updateCurrentInstructionsStandard = createAction(
  InstructionsPageActions.updateCurrentInstructionsStandard
)

export const setCurrentInstructions = createAction(
  InstructionsPageActions.setCurrentInstructions,
  props<ExtendedAction<GetPredefinedGroupDto[]>>()
)
export const setUpdateInstructionsUiPayload = createAction(
  InstructionsPageActions.setUpdateInstructionsUiPayload,
  props<ExtendedAction<IShowUpdateInstructionsUiPayload>>()
)

export const updateUpdateInstructionsForm = createAction(
  InstructionsPageActions.updateUpdateInstructionsForm,
  props<ExtendedAction<IInstructionsFormValue>>()
)
export const updateInstructions = createAction(
  InstructionsPageActions.updateInstructions,
  props<ExtendedAction<UpdateInstructionDto[]>>()
)
