import { Component, Input } from '@angular/core'
import { ResponseTypeEnum } from '@model/message/predefined-message.model'
import { AreaStatusIcons } from '@view/area/area-status.view'

@Component({
  selector: 'app-status-icons',
  templateUrl: './status-icons.component.html',
  styleUrls: ['./status-icons.component.scss']
})
export class StatusIconsComponent {
  @Input() lookup: Record<AreaStatusIcons, boolean> | null = {
    [AreaStatusIcons.medical]: false,
    [AreaStatusIcons.missingPerson]: false,
    [AreaStatusIcons.threat]: false
  }
  icons = Object.values(AreaStatusIcons)
  get showIcons() {
    if (!this.lookup) {
      return false
    }
    return Object.values(this.lookup).some((v) => v)
  }
  showIcon(i: AreaStatusIcons): boolean {
    if (!this.lookup) {
      return false
    }
    return this.lookup[i]
  }
  getImageForResponse = (i: AreaStatusIcons): string => {
    // console.log(`response image in images: ${response}`)
    switch (i) {
      case AreaStatusIcons.threat:
        return 'threatIndicator'
      case AreaStatusIcons.medical:
        return ResponseTypeEnum.medicalAlert
      case AreaStatusIcons.missingPerson:
        return ResponseTypeEnum.missingPerson
      default:
        return ''
    }
  }
}
