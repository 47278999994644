export type ExcludeFromTuple<T, U> = T extends [infer First, ...infer Rest]
  ? First extends U
  ? ExcludeFromTuple<Rest, U>
  : [First, ...ExcludeFromTuple<Rest, U>]
  : []

export type IsTypeInTuple<T, Tuple extends any[]> = T extends Tuple[number] ? true : false

export type ExcludeMultipleFromTuple<T, U extends any[]> = T extends [infer First, ...infer Rest]
  ? IsTypeInTuple<First, U> extends true
  ? ExcludeMultipleFromTuple<Rest, U>
  : [First, ...ExcludeMultipleFromTuple<Rest, U>]
  : []
export type TupleToRecord<T, ValueType> = T extends [infer First, ...infer Rest]
  ? First extends keyof any // Check if 'First' is a valid type for an object key
  ? { [K in First]: ValueType } & TupleToRecord<Rest, ValueType>
  : never
  : {}

type Primitive = string | number | boolean | null | undefined
type ObjectOrArray = Record<string, any> | any[]

// Usage example
export class GenericDiff<T> {
  static isObject(item: any): item is Record<string, any> {
    return item && typeof item === 'object' && !Array.isArray(item)
  }

  static isArray(item: any): item is any[] {
    return Array.isArray(item)
  }

  static isPrimitive(item: any): item is Primitive {
    return item !== Object(item)
  }

  static logDifferences(oldVersion: any, newVersion: any, path: string = ''): void {
    if (this.isPrimitive(oldVersion) || this.isPrimitive(newVersion)) {
      if (oldVersion !== newVersion) {
        console.log(`Difference at "${path}": ${oldVersion} -> ${newVersion}`)
      }
    } else if (this.isArray(oldVersion) && this.isArray(newVersion)) {
      const maxLength = Math.max(oldVersion.length, newVersion.length)
      for (let i = 0; i < maxLength; i++) {
        this.logDifferences(oldVersion[i], newVersion[i], `${path}[${i}]`)
      }
    } else if (this.isObject(oldVersion) && this.isObject(newVersion)) {
      const allKeys = new Set([...Object.keys(oldVersion), ...Object.keys(newVersion)])
      for (const key of allKeys) {
        this.logDifferences(oldVersion[key], newVersion[key], path ? `${path}.${key}` : key)
      }
    } else {
      console.log(`Incompatible types at "${path}": ${typeof oldVersion} -> ${typeof newVersion}`)
    }
  }
}

export class TsObjPropUtils {
  static notNullOrUndefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
  }
}
