import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel'
import SnappingControls from '@arcgis/core/widgets/support/SnappingControls'
import { SchoolDtoProps } from '@model/school/school.model'
import {
  IMapConfigViewModel,
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcgisAreaViewModel } from '../area/arcgis-area.view'
import { ArcgisBoundaryViewModel } from '../area/arcgis-boundary.view'
import { ArcGisSchoolBoundaryGraphic } from '../graphic/school-boundary-graphic.view'
import { ArcGisSketchViewModelFactory } from '../sketch/arc-gis-sketch-view-model-factory.view'
import { ArcGisSketchViewModelEventHandler } from '../sketch/arc-gis-sketch-vm-event-handler.view'
import { ArcGisSketchCustomToolStateHandler } from '../sketch/custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'

export class ArcGisMapConfigStateHandler {
  static handleMapConfigVmChange = (
    context: ArcGisMapService,
    vm: IMapConfigViewModel | null
  ): void => {
    context.log('handleMapConfigVmChange called', vm)
    if (!vm) {
      return
    }
    // If the school dto doesn't have a boundary wait for the recommended polygon api to load
    if (!vm?.partialState?.schoolDto?.boundary && !vm?.partialState.polygonApi.hasLoaded) {
      return
    }
    // This entire vm handler relies on the presence of this dynamically loaded object so skip until it exists
    if (!vm?.partialState?.schoolDto) {
      return
    }
    //Any time we get a update, update our sync ref to the school dto for map config related data
    // Since this is coming from state create a copy of it
    context.log(`Updating school dto with vm?.partialState?.schoolDto`, vm?.partialState?.schoolDto)
    context.schoolDto = { ...vm?.partialState?.schoolDto }
    const schoolDtoBoundaryExists =
      (vm.partialState.schoolDto?.boundary && vm.partialState.schoolDto?.boundary.length > 0) ??
      null
    const subareas = (context.schoolDto && context.schoolDto[SchoolDtoProps.subareas]) ?? []
    const newProximityValue =
      context.schoolDto[SchoolDtoProps.schoolConfiguation]?.schoolProximityBoundary

    //First time we load the page set the proximity slider to the sync ref for async sketch vm handling
    if (
      context._syncRefToProximitySliderValue == null &&
      newProximityValue !== null &&
      newProximityValue !== undefined
    ) {
      context._syncRefToProximitySliderValue = newProximityValue
    }
    const sketchVmInitialized = !!context?._sketchViewModel
    context.log(`sketchVmInitialized`, sketchVmInitialized)
    const { step, error } = vm
    if (!step) {
      console.error(`DEV ERROR: Handler received no step in vm`)
      return
    }
    const isMapViewConfigStep = step === MapConfigSteps.mapView
    const onBoundaryStep = step === MapConfigSteps.schoolPerimeter
    const onAreaStep = step === MapConfigSteps.addAreas
    const isAreaOrBoundary = onBoundaryStep || onAreaStep

    // TODO Can't rely on being on complete config step to determine if the config is complete
    // const isCompleteConfig = step.option === MapConfigSteps.completeConfiguration

    const subareasExist = subareas?.length > 0
    const boundaryRemovedByUser =
      context.mapConfigValidationError === MapConfigValidationErrors.boundaryMustExist

    // if (isAreaOrBoundary && error) {
    if (isAreaOrBoundary && context.mapConfigValidationError !== error) {
      context.mapConfigValidationError = error
    }
    if (onAreaStep && context._sketchViewModel && vm.partialState.schoolDto) {
      // In case we just created an area sync the primary key on the map
      // Handle post new area, in regards to updating the id in the area on map and in popup
      context.log(`Running check for just created area!`)
      ArcgisAreaViewModel.updateNewAreaOnMapConfigMap(context, vm.partialState.schoolDto)
    }
    //Save step and error in context for user interaction handling, click, mouse move, zoom, etc... Only update the error when it exists otherwise skip processing if same step
    if (context.activeConfigStep !== null && context.activeConfigStep === step) {
      return
    }
    //Update sync ref to step
    context.activeConfigStep = step
    //When the step changes we have to clear any pending interactions, Recreate the sketch view model each time we change steps to handle boundary and area individually
    let sketchVm: SketchViewModel | null = null

    context.log(`Rebuilding sketch view model`)
    sketchVm = ArcGisSketchViewModelFactory.getSketchViewModel(context)

    // If there's a boundary to add to the map, do it but check to make sure the user isn't on the map view step in case they remove it and navigate backaward for each potential variant of adding the boundary
    if (
      !sketchVmInitialized &&
      !schoolDtoBoundaryExists &&
      vm.partialState.osmPolygon &&
      !isMapViewConfigStep &&
      !boundaryRemovedByUser
    ) {
      // TODO Update the osm response to use lon lat and not lat lon tuple
      const remapLatLong = vm.partialState.osmPolygon.map((latLong) => [latLong[1], latLong[0]])
      ArcGisSchoolBoundaryGraphic.addRingsToBoundaryLayer(context, remapLatLong)
    } else if (
      !sketchVmInitialized &&
      vm.partialState?.schoolDto?.boundary &&
      !isMapViewConfigStep &&
      !boundaryRemovedByUser
    ) {
      ArcGisSchoolBoundaryGraphic.addRingsToBoundaryLayer(
        context,
        vm.partialState?.schoolDto?.boundary
      )
    }
    if (!sketchVmInitialized && subareasExist && context._schoolAreaLayer?.graphics.length === 0) {
      ArcgisAreaViewModel.addAreasToMapConfigMap(context, subareas)
    }
    if (
      vm.partialState.schoolDto?.boundary &&
      context._schoolBoundaryLayer?.graphics.length === 0 &&
      !isMapViewConfigStep &&
      !boundaryRemovedByUser
    ) {
      // TODO Figure out where to put the drawing into create mode - likely not here but in the sketch vm handler if there's no boundary
      // context._sketchViewModel.create(SketchToolEnum.polygon)
      ArcGisSchoolBoundaryGraphic.addRingsToBoundaryLayer(
        context,
        vm.partialState.schoolDto.boundary
      )
    }
    if (sketchVm) {
      context._sketchViewModel = sketchVm
      context._sketchViewModel.snappingOptions = ArcgisAreaViewModel.areaSnappingOptions(context)
      context._snappingControls = new SnappingControls({
        view: context._mapView,
        snappingOptions: context._sketchViewModel.snappingOptions
      })
    } else {
      console.error(`DEV ERROR: No sketch view model was constructed when expected.`)
    }
    context._mapView.constraints = {
      rotationEnabled: isMapViewConfigStep
    }
    // Once any of these branches of if else if gets too long extract to own function
    if (isMapViewConfigStep) {
      ArcGisSketchViewModelEventHandler.removeEventHandlers(context)
    } else if (onBoundaryStep && context._sketchViewModel && context._schoolBoundaryLayer) {
      ArcGisSketchViewModelEventHandler.addSketchViewModelEventHandlersForBoundary(context)
      context._sketchViewModel.layer = context._schoolBoundaryLayer
      //Since we can't draw when there's a boundary update tools on nav
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(context, null)
      ArcgisBoundaryViewModel.setBoundaryProximityIfExists(context)
    } else if (onAreaStep && context._sketchViewModel && context._schoolAreaLayer) {
      context._sketchViewModel.layer = context._schoolAreaLayer
      ArcGisSketchViewModelEventHandler.addSketchViewModelEventHandlersForAreas(context)
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(context, null)
    }
    // LAYER VISIBILITY
    const visibilityOfAreaLayer = isAreaOrBoundary && !isMapViewConfigStep
    // buffer proximity layer is only visible on boundary step, aka step 2
    if (context._schoolBoundaryProximityLayer) {
      context.log(`Setting visibility of proximity layer to ${onBoundaryStep}`)
      context._schoolBoundaryProximityLayer.visible = onBoundaryStep
    }
    // Boundary is only visible on step 2 or 3
    if (context._schoolBoundaryLayer) {
      context.log(`Setting visibility of boundary layer to ${visibilityOfAreaLayer}`)
      context._schoolBoundaryLayer.visible = visibilityOfAreaLayer
    }
    // Area layer is only visible on step 3
    if (context._schoolAreaLayer) {
      context.log(`Setting visibility of area layer to ${visibilityOfAreaLayer}`)
      context._schoolAreaLayer.visible = visibilityOfAreaLayer
    }
    // Clear the sketch view model if we're on the map view step
    if (context._sketchViewModel && isMapViewConfigStep) {
      context.log(`Clearing sketch view model`)
      context._sketchViewModel.cancel()
      context._sketchViewModel.delete()
    }

  }
  /** Handle updates to slider values from state to enable sync between proximity graphic and the slider */
  static handleNewProximitySliderValue = (
    context: ArcGisMapService,
    sliderValue: number | null
  ) => {
    context._syncRefToProximitySliderValue = sliderValue
  }
}
