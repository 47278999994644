import { AttackerTypeEnum, EmergencyResponeEnum } from '@model/emergency.model'
import { BaseLayerToggleOptions } from '@view/map/base-layer/base-layer-toggle.view'

export enum SchoolPreferenceEnum {
  manual = 'manual',
  /** TODO Determine if the toggle goes between manual and auto versus hybrid and auto */
  hybrid = 'hybrid',
  automatic = 'automatic'
}
export enum SchoolStateEnum {
  routine = 'routine',
  suspected = 'suspected',
  emergency = 'emergency'
}
// TODO Get display text for variants for attack and response and then extend - turn into a function that receives school state and returns display string
export const schoolStateDisplayText: Record<SchoolStateEnum, string> = {
  [SchoolStateEnum.routine]: '',
  [SchoolStateEnum.suspected]: 'Suspected ',
  [SchoolStateEnum.emergency]: 'Confirmed '
}
export const schoolAttackerTypeDisplayText: Record<AttackerTypeEnum, string> = {
  [AttackerTypeEnum.shooting]: 'Active Shooter',
  [AttackerTypeEnum.knifeAttack]: 'Knife Attack'
}
export const schoolResponeTypeDisplayText: Record<EmergencyResponeEnum, string> = {
  [EmergencyResponeEnum.secure]: 'Secure',
  [EmergencyResponeEnum.hold]: 'Hold',
  [EmergencyResponeEnum.lockDown]: 'Lock Down'
}
export const DRILL_MODE_PREPEND = 'DRILL MODE: '
export interface SchoolOwlState {
  /** Required for school identification TODO Discuss why we need school id here as well as in top level object*/
  schoolId: number
  schoolLogicalId: string

  additionalData: any | string | null

  /** This influences how the server rules engine works as well as dashboard features and UI/UX */
  state: SchoolStateEnum

  /**TODO Enables front end and backend logic based on last school state if exists */
  // lastState: SchoolStateEnum | null

  /** Prepends  DRILL_MODE_PREPEND to all sent messages. TODO Discuss enforcing in BE to not rely on FE for critical messaging requirements. */
  drillMode: boolean

  /** This specifies how the rules engine will operate and can be changed at any time by any school admin user. */
  preference: SchoolPreferenceEnum

  /** Optional field when triggering an emergency */
  attackerType?: AttackerTypeEnum

  /** Response Type */
  responseType?: EmergencyResponeEnum

  /** This will have an override date string during suspected and emergency school states, during routine it should be empty.*/
  historicDateExtensionOverride: string | null

  /** This is only used on the BE rules engine logic, we could potentially remove it from the auto mapper profile if we're never going to use it on the front end*/
  lastSuspectedEvent: string | null
}
export interface SchoolConfiguationDto {
  /** This would be the fuzzy boundary (in feet) rule which would filter alerts/location/messages so we don't receive unnecessary data, this applies to the school areas. */
  alertProximityRule?: number

  /** This would be the fuzzy boundary (in feet) rule which would filter alerts/location/messages so we don't receive unnecessary data, this applies to the school boundary. */
  schoolProximityBoundary?: number

  /** Used for threat model expansion logic */
  proximityDistanceForAttackerAreaInFeet?: number

  /** +/- hours from UTC */
  timeZone?: number | null

  /** Set in school map page based on school admin preferences */
  zoomLevel?: number | null

  /** This is the default rotation of the school map as set by the school admin */
  mapViewRotation?: number | null

  /**
   * The selected base map name
   * saved on BE for use by all school users
   * related to a hard coded setting in the front end.
   * empty string or undefined means not selected
   * */
  schoolBaseMapType?: BaseLayerToggleOptions | null
}
