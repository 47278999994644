import { mockUnknownLogicalId } from '@mock/school.mock'
import { DecoratedAlertViewModel } from '@model/alert.model'
import {
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload,
  ChatResponseWithPollResponseIdPayload
} from '@model/message.model'
import { GetResponseDto } from '@model/message/predefined-message.model'
import { ValidSchoolAreaDto } from '@model/school/school-subarea.model'
import { AreaType, SchoolAreaType } from '@model/school/sub-area.model'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { MobileUserTypes } from '@model/user/user.model'
import {
  BaseAreaGraphicAttributes,
  NewAreaGraphicAttributes,
  StatusColorEnum
} from '@view/area/area.view'
import {
  GraphicAttributesTypeEnum,
  RecentMessageIndicatorTypeEnum
} from '@view/area/graphic-attributes.model'
import {
  LocationGraphicAttrProps,
  LocationGraphicAttributes,
  LocationOfAlertResponseAttr,
  LocationOfMessageResponseAttr,
  LocationOfPollResponseAttr,
  LocationStatusViewModel,
  RecentMessageGraphicAttributes
} from '@view/area/location.view'
import { IMapConfigAreaViewModel } from '@view/pages/school-map-config-page/school-map-config.view'

/** Here to contralize how we construct attribute objects from dtos for use in graphic attributes */
export class ArcGisGraphicAttrFac {
  static getAttributesForNewArea = (
    areaMapConfigVm: IMapConfigAreaViewModel
  ): NewAreaGraphicAttributes => {
    return {
      kind: GraphicAttributesTypeEnum.area,
      type: AreaType.building,
      name: `Area ${areaMapConfigVm.existingAreaNames.length + 1}`,
      logicalId: crypto.randomUUID(),
      areaType: SchoolAreaType.subarea
    }
  }
  /** Here to centralize attribute logic for map config area graphic creation */
  static getAttributesForAreaDto = (a: ValidSchoolAreaDto): BaseAreaGraphicAttributes => ({
    kind: GraphicAttributesTypeEnum.area,
    id: a.id,
    type: a.type ?? AreaType.unknown,
    name: a.name ?? '',
    logicalId: a.logicalId ?? null,
    areaType: a.areaType ?? SchoolAreaType.subarea
  })
  // TODO Remove chat response dto payload and only use timestamp
  static getRecentMessageIndicatorAttr(
    locAttr: LocationGraphicAttributes
  ): RecentMessageGraphicAttributes {
    const {
      chatResponseDtoPayload,
      id,
      recentMessageIndicatorType,
      oldestConsideredDtoTimestamp,
      mobileId
    } = locAttr
    return {
      oldestConsideredDtoTimestamp,
      chatResponseDtoPayload,
      id,
      mobileId,
      recentMessageIndicatorType,
      kind: GraphicAttributesTypeEnum.recentMessageIndicator
    }
  }
  static getAttributesForThreatGraphic = () => {
    return {}
  }
  /** TODO Only save timestamp as rest of dto isn't needed. */
  static getPollResponseGraphicAttributes = (
    payload: ChatResponseWithPollResponseIdPayload,
    status: StatusColorEnum
  ): LocationOfPollResponseAttr => {
    return {
      kind: GraphicAttributesTypeEnum.pollResponse,
      chatResponseDtoPayload: payload,
      statusVm: {
        message: '',
        status
      },
      mobileId: payload.mobileUserId ?? mockUnknownLogicalId,
      id: payload.mobileUserId ?? mockUnknownLogicalId
    }
  }
  static getMessageGraphicAttributes = (
    payload: ChatResponseWithMessagePayload
  ): LocationOfMessageResponseAttr => {
    return {
      kind: GraphicAttributesTypeEnum.messageResponse,
      chatResponseDtoPayload: payload,
      mobileId: payload.mobileUserId ?? mockUnknownLogicalId,
      id: payload.mobileUserId ?? mockUnknownLogicalId
    }
  }
  static getMedicalAlertResponseGraphicAttributes = (
    payload: ChatResponseDtoPayload,
    alertVm: DecoratedAlertViewModel
  ): LocationOfAlertResponseAttr => {
    return ArcGisGraphicAttrFac.getAlertGraphicAttributes(
      payload,
      alertVm,
      GraphicAttributesTypeEnum.medicalAlertResponse
    )
  }
  static getAlertResponseGraphicAttributes = (
    payload: ChatResponseDtoPayload,
    alertVm: DecoratedAlertViewModel
  ): LocationOfAlertResponseAttr => {
    return ArcGisGraphicAttrFac.getAlertGraphicAttributes(
      payload,
      alertVm,
      GraphicAttributesTypeEnum.attackAlertResponse
    )
  }
  static getAlertGraphicAttributes = (
    payload: ChatResponseDtoPayload,
    alertVm: DecoratedAlertViewModel,
    kind: GraphicAttributesTypeEnum
  ): LocationOfAlertResponseAttr => {
    return {
      kind,
      chatResponseDtoPayload: payload,
      mobileId: payload.mobileUserId ?? mockUnknownLogicalId,
      id: payload.logicalId ?? crypto.randomUUID(),
      userType: alertVm?.userType ?? MobileUserTypes.unknown,
      fullName: alertVm?.fullName ?? '',
      response: alertVm?.response
    }
  }
  static resetLocationAttributes = (
    existingLocAttr: LocationGraphicAttributes
  ): LocationGraphicAttributes => {
    return {
      ...existingLocAttr,
      statusVm: new LocationStatusViewModel(),
      recentMessageIndicatorType: RecentMessageIndicatorTypeEnum.unknown,
      icon: '',
      // Okay to remove nested dto here since we may conclude events or once a SERT replies directly to a user message we will never indicate that on the map again
      chatResponseDtoPayload: null,
      oldestConsideredDtoTimestamp: null
    }
  }
  static resetRecentMessageAttributes = (
    existingLocAttr: RecentMessageGraphicAttributes
  ): RecentMessageGraphicAttributes => {
    return {
      ...existingLocAttr,
      recentMessageIndicatorType: RecentMessageIndicatorTypeEnum.unknown,
      icon: '',
      // Okay to remove nested dto here since we want to clear on conclude event or once a SERT replies directly to a user message we will never indicate that on the map again
      chatResponseDtoPayload: null,
      oldestConsideredDtoTimestamp: null
    }
  }
  static getLocationAttributes = (
    vm: DecoratedUserLocationViewModel,
    recentMessageIndicatorIcon?: string
  ): LocationGraphicAttributes => {
    const {
      userType,
      statusMessage,
      statusColor,
      mobileUserId,
      chatResponseDtoPayload,
      email,
      phone,
      fullName
    } = vm
    return {
      [LocationGraphicAttrProps.oldestConsideredDtoTimestamp]: vm.oldestConsideredDtoTimestamp
        ? vm.oldestConsideredDtoTimestamp
        : null,
      [LocationGraphicAttrProps.mobileId]: mobileUserId,
      [LocationGraphicAttrProps.id]: mobileUserId,
      [LocationGraphicAttrProps.fullName]: fullName ?? '',
      [LocationGraphicAttrProps.userType]: userType ?? MobileUserTypes.unknown,
      [LocationGraphicAttrProps.email]: email ?? '',
      [LocationGraphicAttrProps.phone]: phone ?? '',
      // TODO Make sure to build a status vm based on the location status view model class
      [LocationGraphicAttrProps.statusVm]: {
        message: statusMessage ?? '',
        status: statusColor ?? StatusColorEnum.unknown
      },
      [LocationGraphicAttrProps.chatResponseDtoPayload]: chatResponseDtoPayload,
      [LocationGraphicAttrProps.kind]: GraphicAttributesTypeEnum.location,
      [LocationGraphicAttrProps.icon]: recentMessageIndicatorIcon,
      [LocationGraphicAttrProps.recentMessageIndicatorType]: vm.recentMessageIndicatorType
    }
  }
}
