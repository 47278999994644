import { ChatResponseDtoPayload } from '@model/message.model'
import {
  GetResponseDto,
  PredefinedMessageHelper,
  ResponseDtoDisplayTextProps,
  ResponseTypeEnum
} from '@model/message/predefined-message.model'
import { TimeUtils } from '@shared/time.utils'
import { ChatMessageVmType, ChatUiItemViewModel } from './message.view'

export enum PollResponseImage {
  attack = 'attacker-emergency',
  medical = 'med-emergency'
}

export class ResponseIdChatMessageVm {
  public payload: ChatResponseDtoPayload | null = null
  public responseDto: GetResponseDto | null = null
  message: string = ''
  svgImage: string = ''
  pathImage: string = ''
  constructor(payload: ChatResponseDtoPayload, responseDto: GetResponseDto) {
    this.payload = payload
    this.responseDto = responseDto
    this.message = this.getMessageText(responseDto)
    if (!responseDto.browserImage) {
      this.pathImage = this.getImageForResponseDto(responseDto)
    }
    try {
      this.svgImage = decodeURIComponent(responseDto.browserImage ?? '')
    } catch (e) {
      // TODO Integrate into logging service
      console.error(`Failed to decode URI from response id ${responseDto.id}) catch error`)
      console.error(e)
    }
  }
  getImageForResponseDto(responseDto: GetResponseDto): string {
    if (PredefinedMessageHelper.isThreatIndicator(responseDto.type)) {
      return PollResponseImage.attack
    } else if (responseDto.type === ResponseTypeEnum.medicalAlert) {
      return PollResponseImage.medical
    }
    return 'response-id-message-default'
  }

  getMessageText(responseDto: GetResponseDto): string {
    return responseDto[ResponseDtoDisplayTextProps.longText]?.length > 0
      ? responseDto[ResponseDtoDisplayTextProps.longText]
      : responseDto[ResponseDtoDisplayTextProps.text]
  }

  static getVmFromDto(
    payload: ChatResponseDtoPayload,
    responseDto: GetResponseDto,
    chatRoomId: string
  ): ChatUiItemViewModel {
    let vm = new ChatUiItemViewModel()
    vm.responesIdVm = new ResponseIdChatMessageVm(payload, responseDto)
    vm.type = ChatMessageVmType.responseIdMessage
    vm.timestampDisplay = TimeUtils.getTimeSinceStringFromDate(new Date(payload.timestamp)) ?? ''
    vm.timestamp = payload.timestamp
    const { id, schoolLogicalId } = payload
    vm.id = payload.logicalId ?? `U:${id}-S:${schoolLogicalId}-${Date.now().toString()}`
    vm.chatRoomId = chatRoomId
    return vm
  }
}
