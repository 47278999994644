<div class="standard-checkboxes-container">
  <mat-radio-group class="input-container" [(ngModel)]="selectedOption">
    <mat-radio-button
      *ngFor="let item of instructionsPageCompServ.selectStandards$ | async"
      [value]="item.id"
      (change)="changeStandard($event)"
      [checked]="isChecked(item.id)"
      >{{ item.description }}</mat-radio-button
    >
  </mat-radio-group>
</div>
