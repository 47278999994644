import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import {
  DeleteAreaSideEffectPayload,
  SchoolAreaModelHelper
} from '@domain/dto-helpers/area-model.helper'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import {
  ResponseIdsByResponseGroupTypeLookup,
  emptyResponseIdsByResponseGroupTypeLookup
} from '@domain/dto-helpers/response-group-model.helper'

import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
// import { mockChatMessageResponseDtoResponse } from '@mock/messaging.mock'
import {
  mockUnknownLogicalId,
  mockUnknownSchoolId,
  mockUnknownSchoolIntId
} from '@mock/school.mock'
import {
  ChatHistoryLookup,
  ChatMessageDto,
  ChatMessageDtoPayload,
  ChatResponseDtoPayload
} from '@model/message.model'
import { GetPredefinedGroupDto, GetResponseDto } from '@model/message/predefined-message.model'
import { GetSchoolAreaDto, SchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDto } from '@model/school/school.model'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { GetUserDto, MobileUserTypes, SchoolIds } from '@model/user/user.model'
import { IAppConfig } from '@service/app-config/app-config.model'
import { CommonUtils } from '@shared/common.utils'
import {
  DEFAULT_CHATROOM_DISPLAY_TEXT,
  DEFAULT_CHATROOM_ID,
  ENTIRE_SCHOOL_CHATROOM_ID
} from '@shared/constants'
import { ApiState } from '@state/api.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { StatusColorEnum } from '@view/area/area.view'
import { ChatRoomSelectViewModel } from '@view/chat/chat-room-select.view'
import { ChatRoomViewModel, IChatRoomViewModel } from '@view/chat/chat-room.view'
import { ChatRoomMessagesLookup, ChatRoomTypeEnum } from '@view/chat/chat.view'
import { UserLocationViewModel } from '@view/location/user-location.view'
import { DashboardPageViewModel } from '@view/pages/dashboard-page/dashboard-page.view'

// let oldState: DashboardPageState | null = null

export class DashboardPageState extends ApiState {
  vm = new DashboardPageViewModel()
  showSelectEventUi = false

  /** This is needed here because we need access to the threat model in config when processing alert responses in the historic messages endpoint reducer handler */
  appConfig: IAppConfig | null = null

  //CHAT
  // #region For state that connects map to chat interactions

  /** This is the source of truth object that all other objects are computed from. This represents all messages for the school by chronological order. */
  currentSchoolChatMessages: ChatMessageDto[] = []
  /** Used to display avaiable chat room buttons */
  chatRoomIdToAttributesLookup: Record<string, IChatRoomViewModel> =
    ChatRoomSelectViewModel.DEFAULT_CHAT_ROOM_VM_LOOKUP
  /** In all non everyone chat room cases we'll have a lookup between an area logical id to a chat room name */
  areaLogicalIdToChatRoomNameLookup: Record<string, string> = {
    [DEFAULT_CHATROOM_ID]: DEFAULT_CHATROOM_DISPLAY_TEXT
  }
  /** Since the chat room id is defined by a number of things, including potentially the area guid, then we want a fast way to map from guid to primary key id. */
  areaLogicalIdToAreaIdLookup: Record<string, number> = {
    [DEFAULT_CHATROOM_ID]: 0
  }
  /** Similarly to guid to id lookup, this is the reverse to keep needed data at fast reteival.  */
  areaIdToAreaLogicalIdLookup: Record<number, string> = {
    0: DEFAULT_CHATROOM_ID
  }

  //USERS
  //#region
  getUsersForSchoolApiState: ApiState = new ApiState()
  /** Fast way to get from a users guid to their display name. */
  mobileIdToDisplayNameLookup: Record<string, string> = {}
  /** Track the last location of a user by their mobile id */
  userLocationLookup: Record<string, DecoratedUserLocationViewModel> = {}
  /** Recent message indicators should be hidden when a SERT member replies so track messages for that purpose */
  userMobileIdToLastSertMessageLookup: Record<string, ChatMessageDtoPayload> = {}
  /** Used to track the user's last alert response so we can cache stale data accurately, and override the user's recent message indicator accurately. */
  userMobileIdToAlerts: Record<string, ChatResponseDtoPayload[]> = {}
  /** Used to track the user's last negate alert response so we can dynamically reapply it. */
  userMobileIdToNegateAlerts: Record<string, ChatResponseDtoPayload[]> = {}
  /**
   * These track all poll responses that have no alert type. This means they aren't an alert or explicitly negate an alert.
   * At the time of coding this is only used for attacker alert, medical alert, missing person alert, and negating those missing person alert.
   *  Everything else should be aggregated in this object.
   * As we have to remove the last contribution from a user to an area if they contribute a non alert response to a new area. For example, are you okay, puts an area to green, if they say they are okay in a new area, the last area they were okay in should be recalculated in case there are no contributers to make it green.
   * In other words, track the last poll response of a user by their mobile id, this is necessary since we need an efficient way to find all the user's last non alert poll response to recalculate area statuses. */
  userMobileIdToLastPollResponseLookup: Record<string, ChatResponseDtoPayload> = {}
  /** Track the last message response of a user by their mobile id */
  userMobileIdToLastMessageResponseLookup: Record<string, ChatResponseDtoPayload> = {}
  userLocationsHistoricDataApiState = new ApiState()
  usersForSchoolLookup: Record<number, GetUserDto[]> = {}
  /** Quick way to get to a user's type from their guid id. */
  userToTypeLookup: Record<string, MobileUserTypes> = {}
  //#endregion

  // MESSAGES
  //#region
  /** This api state is a prerequisite before working with messages on the dashboard and seeing instructions in the communications page. */
  predefinedGroupsApiState: ApiState = new ApiState()
  /** This api state is a prerequisite before working with messages on the dashboard */
  responseGroupsApiState: ApiState = new ApiState()
  /** Processing this data is only allowed after having all of the messages and groups handled in state. */
  historicChatMessagesApiState = new ApiState()
  sentPollToIncludedUserTypes: Record<string, MobileUserTypes[]> = {}
  /** This enables us to update area statuses based on when we get answers to poll status updates for teachers in a specific area. */
  areaStatusLookup: Record<number, AreaStatusViewModel> = {}
  /** We'll need a way to know about all the alerts the a user provides. */
  areaIdToAlerts: Record<number, ChatResponseDtoPayload[]> = {}
  /** We'll need a way to know about all the negated alerts the a user provides. */
  areaIdToNegatedAlerts: Record<number, ChatResponseDtoPayload[]> = {}
  /** Track the historic result of the geo spatial query to group non alert poll responses by area id */
  areaIdToUsersLastPollResponses: Record<number, ChatResponseDtoPayload[]> = {}
  /** A school id to a users lookup */
  /** This is a lookup from the primary key of the record in the db to the object as a source of truth for all response id related logic. */
  responseIdToGetResponseDto: Record<number, GetResponseDto> = {}
  /** Is exactly the same as responseIdToGetResponseDto but mean to be a fast way to get from the id to the status color, in the future if fast access to things like long message, or response type are needed, additional lookup objects should be added to state. */
  responseIdToStatusColorEnum: Record<number, StatusColorEnum> = {}
  /** A way to quickly get an array of all receive response ids associated with a poll logical id.
   * Since we're aggregating poll responses by the poll sent message, we'll want an easy reference to all responses by that sent poll */
  sentPollToPollResponseLookup: Record<string, number[]> = {}
  /** When we get response groups those include the alerts that the OWL mobile app can send and we need to exclude those from regular poll processing */
  responseIdsByResponseGroupTypeLookup: ResponseIdsByResponseGroupTypeLookup = {
    ...emptyResponseIdsByResponseGroupTypeLookup
  }
  groupedPredefinedMessages: GetPredefinedGroupDto[] | null = null
  // real-time
  chatDataBySchoolLookup: ChatHistoryLookup = {}

  //SCHOOL
  selectedSchoolId: SchoolIds = mockUnknownSchoolId
  // Connection section

  override isLoading = true

  constructor() {
    super()
  }
  /**
   * Used when logic requires that all dash api state is loaded.
   * As additional api state is added, this function should be updated to include it.
   */
  static returnStateIfAllApiStateLoaded(s: DashboardPageState): DashboardPageState | null {
    // TODO IsLoading on state should likely be updated to reflect all the dependencies
    // s.isLoading ||
    const hasLoadingApiState =
      s.responseGroupsApiState.isLoading ||
      s.predefinedGroupsApiState.isLoading ||
      s.userLocationsHistoricDataApiState.isLoading ||
      s.historicChatMessagesApiState.isLoading ||
      s.getUsersForSchoolApiState.isLoading

    // We need the app config in context to set up the threat model, and we need all endpoints to resolve before we can process all messages and locations.
    if (!s.appConfig || hasLoadingApiState) {
      return null
    }
    // TODO Issue where this is invoked more than once, needs to be fixed
    // if (oldState !== null) {
    //   console.log("'DashboardPageState' GenericDiff.logDifferences")
    //   GenericDiff.logDifferences(oldState, s)
    // }
    // oldState = s
    //     console.log(`returnStateIfAllApiStateLoaded: All api state loaded`)
    //     console.log(s)
    //     console.log(`
    // s.responseGroupsApiState.isLoading ${s.responseGroupsApiState.isLoading}
    // s.predefinedGroupsApiState.isLoading ${s.predefinedGroupsApiState.isLoading}
    // s.userLocationsHistoricDataApiState.isLoading ${s.userLocationsHistoricDataApiState.isLoading}
    // s.historicChatMessagesApiState.isLoading ${s.historicChatMessagesApiState.isLoading}
    // s.getUsersForSchoolApiState.isLoading ${s.getUsersForSchoolApiState.isLoading}
    //     `)
    return s
  }
  static handleAppConfig(s: DashboardPageState, a: ExtendedAction<IAppConfig>): DashboardPageState {
    return {
      ...s,
      appConfig: a.payload
    }
  }
  //#region
  static handleConnection = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      ...ApiState.createIsLoadingState()
    }
  }
  static handleError = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      ...ApiState.createHasErrorState()
    }
  }
  //#endregion

  //USER
  /**
   * When the user first onboards update signal r state with their school id selection.
   */
  static handleUpdateToSchoolAddition = (
    s: DashboardPageState,
    a: ExtendedAction<SchoolDto>
  ): DashboardPageState => {
    return {
      ...s,
      selectedSchoolId: {
        logicalId: a.payload.logicalId ?? mockUnknownLogicalId,
        id: a.payload.id ?? mockUnknownSchoolIntId
      }
    }
  }
  static postSchoolAreaDtoSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetSchoolAreaDto>
  ): DashboardPageState => {
    const { type, name, logicalId } = a.payload
    const smartAreaName = CommonUtils.getSmartAreaName(type, name)
    if (!logicalId) {
      console.warn(`patchSchoolAreaDtoSuccess not implemented for area with no logical id `)
      return s
    }
    return {
      ...s,
      areaStatusLookup: AreaStatusViewModel.updateAreaStatusLookupByNewAreaDto(
        a.payload,
        s.areaStatusLookup
      ),
      chatRoomIdToAttributesLookup: {
        ...s.chatRoomIdToAttributesLookup,
        [logicalId]: ChatRoomSelectViewModel.buildAreaChatRoomSelectVm(logicalId, smartAreaName)
      },
      areaLogicalIdToChatRoomNameLookup: {
        ...s.areaLogicalIdToChatRoomNameLookup,
        [logicalId]: smartAreaName
      }
    }
  }
  /**
   * Handle when SERT admin deletes area.
   * TODO Decide how to handle deleting an area that has messages, for now this code is commented out to avoid errors.
   * If there's no chat room name or attributes for the chat room id, the title will default to everyone which is a bug, and needs to also be handled here.
   * While commenting out this code prevents this during a session, if you reload the page, the area no longer exists and the bug is present.
   * TODO Confirm if proper way to handle this is prevent showing any messages associated with a deleted area, will need implementation for specific real time and historic handling.
   */
  static deleteSchoolAreaSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<DeleteAreaSideEffectPayload>
  ): DashboardPageState => {
    const { areaLogicalId } = a.payload
    if (!areaLogicalId) {
      console.warn(`patchSchoolAreaDtoSuccess not implemented for area with no logical id `)
      return s
    }
    // const chatRoomIdToAttributesLookup = Object.assign({}, s.chatRoomIdToAttributesLookup)
    // const areaLogicalIdToChatRoomNameLookup = Object.assign({}, s.areaLogicalIdToChatRoomNameLookup)
    // delete chatRoomIdToAttributesLookup[areaLogicalId]
    // delete areaLogicalIdToChatRoomNameLookup[areaLogicalId]
    const newLookup = AreaStatusViewModel.deleteAreaStatusLookupByNewAreaDto(
      a.payload.areaId,
      s.areaStatusLookup
    )
    return {
      ...s,
      areaStatusLookup: newLookup
      // chatRoomIdToAttributesLookup,
      // areaLogicalIdToChatRoomNameLookup
    }
  }
  static patchSchoolAreaDtoSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetSchoolAreaDto>
  ): DashboardPageState => {
    const { type, name, logicalId } = a.payload
    if (!logicalId) {
      console.warn(`patchSchoolAreaDtoSuccess not implemented for area with no logical id `)
      return s
    }
    const chatRoomIdToAttributesLookup = Object.assign({}, s.chatRoomIdToAttributesLookup)
    const areaLogicalIdToChatRoomNameLookup = Object.assign({}, s.areaLogicalIdToChatRoomNameLookup)
    const smartAreaName = CommonUtils.getSmartAreaName(type, name)
    chatRoomIdToAttributesLookup[logicalId] = ChatRoomSelectViewModel.buildAreaChatRoomSelectVm(
      logicalId,
      smartAreaName
    )
    areaLogicalIdToChatRoomNameLookup[logicalId] = smartAreaName
    return {
      ...s,
      areaStatusLookup: AreaStatusViewModel.updateAreaStatusLookupByPatchedAreaDto(
        a.payload,
        s.areaStatusLookup
      ),
      chatRoomIdToAttributesLookup,
      areaLogicalIdToChatRoomNameLookup
    }
  }
  static handleReceivedChatMessage = (
    s: DashboardPageState,
    a: ExtendedAction<ChatMessageDto>
  ): DashboardPageState => {
    return {
      ...s
      // TODO Unclear if we want to enable switching schools for the dashboard
      // chatDataBySchoolLookup: ChatMessageDtoHelper.handleNewChatMessage(s.chatDataBySchoolLookup, a.payload)
    }
  }
  //#endregion

  /** School USERS */
  static handleGetUsersForSchool = (
    s: DashboardPageState,
    a: ExtendedAction<SchoolIds>
  ): DashboardPageState => {
    // Any time we need to get users for school, we need to set the selected school id which comes from auth state, awaiting decisions around multi school sessions and district associations
    return {
      ...s,
      selectedSchoolId: a.payload,
      getUsersForSchoolApiState: ApiState.createIsLoadingState()
    }
  }
  static handleGetUsersForSchoolError = (
    s: DashboardPageState,
    a: ExtendedAction<HttpErrorResponse>
  ): DashboardPageState => {
    return {
      ...s,
      getUsersForSchoolApiState: ApiState.createHasErrorState()
    }
  }
  static handleGetUsersForSchoolSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetUserDto[]>
  ): DashboardPageState => {
    let mobileIdToDisplayNameLookup: Record<string, string> = {}
    let userToTypeLookup: Record<string, MobileUserTypes> = Object.assign({}, s.userToTypeLookup)
    let userLocationLookup = { ...s.userLocationLookup }
    a.payload.forEach((dto) => {
      //Set up a location view model for all mobile users.
      if (dto.mobileId) {
        const userLocationVm = UserLocationViewModel.getLocationVmFromUserDto(dto)
        if (userLocationVm) {
          userLocationLookup[userLocationVm.mobileUserId] = userLocationVm
        }
      }
      // If the user is a mobile user save their display name for in chat ui usage
      if (dto.mobileId) {
        mobileIdToDisplayNameLookup[dto.mobileId] = UserDtoHelper.getFullName(dto)
      }
      //We want to skip any users that lack a mobile user type, as a defensive code practice since our user object has that prop as potentially undefined
      if (!dto.mobileType || !dto.mobileId) {
        return
      }
      userToTypeLookup[dto.mobileId] = dto.mobileType
    })
    return {
      ...s,
      mobileIdToDisplayNameLookup,
      userToTypeLookup,
      userLocationLookup,
      getUsersForSchoolApiState: ApiState.createHadLoadedState(),
      usersForSchoolLookup: {
        ...s.usersForSchoolLookup,
        [s.selectedSchoolId.id]: a.payload
      }
    }
  }
  //SCHOOL
  static handleSchoolByIdSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetSchoolDto>
  ): DashboardPageState => {
    // console.log(`handleSchoolByIdSuccess`, a)
    //TODO Set up handling for area lookup by school id handling when we want to support more than one school for a user
    let areaStatusLookup: Record<number, AreaStatusViewModel> = {}
    let areaIdToAreaLogicalIdLookup: Record<number, string> = {}
    let areaLogicalIdToChatRoomNameLookup: Record<string, string> = {}
    let areaLogicalIdToAreaIdLookup: Record<string, number> = {}
    if (SchoolDtoHelper.isSchoolWithAreas(a.payload)) {
      const { subareas } = a.payload
      subareas.forEach((areaDto: SchoolAreaDto) => {
        if (!areaDto.logicalId) {
          console.warn(`Dev error, area doesn't have a logical id.`)
          return
        }
        if (SchoolAreaModelHelper.areaHasNameAndIds(areaDto)) {
          areaLogicalIdToAreaIdLookup[areaDto.logicalId] = areaDto.id
          areaLogicalIdToChatRoomNameLookup[areaDto.logicalId] = CommonUtils.getSmartAreaName(
            areaDto.type,
            areaDto.name
          )
          // When we first get a school by id, we want to build a area status lookup object to receive any historic poll responses specific to an area
          areaStatusLookup[areaDto.id ?? 0] = AreaStatusViewModel.getStatusVmfromAreaDto(areaDto)
        }
        if (areaDto.logicalId && areaDto.id) {
          areaIdToAreaLogicalIdLookup[areaDto.id] = areaDto.logicalId
        }
      }, {})
      return {
        ...s,
        areaStatusLookup,
        areaIdToAreaLogicalIdLookup,
        areaLogicalIdToChatRoomNameLookup,
        areaLogicalIdToAreaIdLookup
      }
    } else {
      return s
    }
  }

  /** If the admin escalates or initiates an event hide the attack/response selection panel */
  static hideAttackResponseTypeConditionally = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      showSelectEventUi: false
    }
  }

  static disableCheckStatus = (
    s: DashboardPageState,
    a: ExtendedAction<any>
  ): DashboardPageState => {
    let disabledAreaCheckStatuses = { ...s.vm.disabledAreaCheckStatuses }
    disabledAreaCheckStatuses[a.payload.id] = a.payload.disable
    return {
      ...s,
      vm: {
        ...s.vm,
        disabledAreaCheckStatuses: disabledAreaCheckStatuses
      }
    }
  }
  /** Handles a collection of chat message dtos, and assembles chat room related data. */
  static handleGetChatMessagesSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<ChatMessageDto[]>,
    d?: Date
  ): DashboardPageState => {
    /** Function is reused to filter down data by stale data date, so build up poll to poll responses every time it's invoked. */
    let sentPollToPollResponseLookup: Record<string, number[]> = {}
    let sentPollToIncludedUserTypes: Record<string, MobileUserTypes[]> = {}
    const userMobileIdToLastSertMessageLookup = { ...s.userMobileIdToLastSertMessageLookup }
    const userMobileIdToAlerts = {
      ...s.userMobileIdToAlerts
    }
    const userMobileIdToNegateAlerts = {
      ...s.userMobileIdToNegateAlerts
    }
    const userMobileIdToLastPollResponseLookup = {
      ...s.userMobileIdToLastPollResponseLookup
    }
    const userMobileIdToLastMessageResponseLookup = { ...s.userMobileIdToLastMessageResponseLookup }
    const userLocationLookup = { ...s.userLocationLookup }

    const { chatVm } = s.vm
    let chatRoomIdToAttributesLookup: Record<string, IChatRoomViewModel> = {
      ...s.chatRoomIdToAttributesLookup
    }
    //Put all historic chat message dtos into the everyone chat room
    // TODO Add another chat room using the school dto, and add helper that decides which chats are shown there, like all the ones that are sent specifically to the boundary only, whereas everyone includes one on one chats and subarea chats
    let newChatRoomMessageLookup: ChatRoomMessagesLookup = {}
    newChatRoomMessageLookup[DEFAULT_CHATROOM_ID] = (a.payload ?? []).filter(
      ChatMessageDtoHelper.canShowMessageInChatRoom
    )
    newChatRoomMessageLookup[ENTIRE_SCHOOL_CHATROOM_ID] = (a.payload ?? []).filter(
      ChatMessageDtoHelper.canShowMessageInEntireSchooltRoom
    )
    // TODO Move this into chat room view model - getChatRoomVmFromDto
    a.payload.forEach((dto: ChatMessageDto) => {
      const chatRoomButtonVmExists =
        chatRoomIdToAttributesLookup[dto.payload.chatRoomId ?? ''] ?? null
      // Create the view model to simplify updating state even if it already exists in the attribute lookup object above
      const vm = ChatRoomViewModel.getChatRoomVm(dto, s)
      if (!chatRoomButtonVmExists) {
        // Add the vm to the lookup
        chatRoomIdToAttributesLookup[vm.chatRoomId] = vm
      }
      //HISTORIC LOCATION VIEW MODEL SECTION
      // If this dto is for a SERT message to a mobile user, track it by mobile id for later recent message indicator logic
      if (vm.type === ChatRoomTypeEnum.oneOnOne && ChatMessageDtoHelper.isChatMessage(dto)) {
        const updatedLocationVm = UserLocationViewModel.getDecoratedUserLocationFromChatMessage(
          userLocationLookup[vm.chatRoomId]
        )
        if (updatedLocationVm) {
          // console.log(`Updating location vm with`)
          // console.log(updatedLocationVm)
          userLocationLookup[vm.chatRoomId] = updatedLocationVm
        }
        // If this dto is for a SERT message to a user track it by mobile id
        userMobileIdToLastSertMessageLookup[vm.chatRoomId] = dto.payload
      } else if (ChatMessageDtoHelper.isChatResponseDto(dto) && dto.payload.mobileUserId) {
        //If this dto is a response dto of any kind update the user's location object with the decorated data reusing the updated location vm in this context
        const existingLocationVm: DecoratedUserLocationViewModel | null =
          userLocationLookup[dto.payload.mobileUserId] ?? null
        const updatedLocationVm = UserLocationViewModel.getDecoratedUserLocationFromChatResponse(
          s,
          existingLocationVm,
          dto.payload,
          d
        )
        // console.log(`Updating location vm with`)
        // console.log(updatedLocationVm)
        if (updatedLocationVm) {
          userLocationLookup[dto.payload.mobileUserId] = updatedLocationVm
        }
      }
      // If this dto went to a one on one chat update all relevant parts of state handle it in three optional ways
      if (ChatMessageDtoHelper.isChatResponseDto(dto)) {
        // Update the pulsing alerts collection - and update the user's last alert for later reference
        const mobileUserId = dto.payload.mobileUserId ?? mockUnknownLogicalId
        if (!dto.payload.mobileUserId) {
          console.warn(
            'Mobile user id is required for chat response dto, got response dto without mobile id.'
          )
        }
        //When we process alerts, if it's a threat indicator, we have to check it's age before aggregating it into the user's alert collection
        let shouldTrackAlert = true
        const hasResponseId = ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload)
        const responseIsAlert = ChatMessageDtoHelper.isAlertResponse(
          dto,
          s.responseIdToGetResponseDto
        )
        const isThreatIndicator = ChatMessageDtoHelper.isThreatIndicatorAlertReponse(
          dto,
          s.responseIdToGetResponseDto
        )
        const isNegatingAlert = ChatMessageDtoHelper.isNegatingAlertResponse(
          dto,
          s.responseIdToGetResponseDto
        )
        if (isThreatIndicator) {
          shouldTrackAlert = ChatMessageDtoHelper.shouldTackThreatIndicatorAlert(dto, s)
        }
        // We either treat a response as an alert or not.
        if (isNegatingAlert) {
          const existingNegateAlerts = userMobileIdToNegateAlerts[mobileUserId] ?? []
          userMobileIdToNegateAlerts[mobileUserId] = [...existingNegateAlerts, dto.payload]
        } else if (responseIsAlert && shouldTrackAlert) {
          const existingAlerts = userMobileIdToAlerts[mobileUserId] ?? []
          userMobileIdToAlerts[mobileUserId] = [...existingAlerts, dto.payload]
        } else if (hasResponseId && !responseIsAlert) {
          // Whether the poll was sent to everyone, an area, or a specific user, we want to aggregate everyones responses
          userMobileIdToLastPollResponseLookup[mobileUserId] = dto.payload
        }
        const isChatResponse = ChatMessageDtoHelper.isChatResponseDto(dto)

        // Add chat response with response id add to everyone chat room and one on one chat room.
        if (isChatResponse && hasResponseId) {
          const existingChatRoomMessages = newChatRoomMessageLookup[vm.chatRoomId] ?? []
          newChatRoomMessageLookup[vm.chatRoomId] = [...existingChatRoomMessages, dto]
          const existingOneOnOneMessages = newChatRoomMessageLookup[mobileUserId] ?? []
          newChatRoomMessageLookup[mobileUserId] = [...existingOneOnOneMessages, dto]
        }
        if (vm.type === ChatRoomTypeEnum.oneOnOne) {
          // Handle the received message with
          if (isChatResponse) {
            //Update the user's latest message response lookup, and
            userMobileIdToLastMessageResponseLookup[vm.chatRoomId] = dto.payload
            //Update the user's latest message response lookup, and
            //add their response to the chat message lookup

            newChatRoomMessageLookup =
              ChatRoomSelectViewModel.handleAddChatMessageDtoToAreaChatRoom(
                dto.payload,
                dto,
                newChatRoomMessageLookup
              )
          }
        } else if (
          (vm.type === ChatRoomTypeEnum.subarea ||
            vm.type === ChatRoomTypeEnum.everyone ||
            vm.type === ChatRoomTypeEnum.entireSchool) &&
          ChatMessageDtoHelper.isPollResponseWithPollIdDto(dto)
        ) {
          const { pollLogicalId } = dto.payload
          const existingResponseIds = sentPollToPollResponseLookup[pollLogicalId] ?? []
          sentPollToPollResponseLookup[pollLogicalId] = [
            ...existingResponseIds,
            dto.payload.responseId
          ]
          const userType = s.userToTypeLookup[dto.payload.mobileUserId]
          const userTypesForPoll = sentPollToIncludedUserTypes[pollLogicalId] ?? []
          if (!userTypesForPoll.includes(userType)) {
            sentPollToIncludedUserTypes[pollLogicalId] = [...userTypesForPoll, userType]
          }
        }
      }

      //Before we add a dto to a collection, let's make sure it's either a sent poll, a sent message or a received message, TODO We've discussed adding poll responses to the chat so that will need to be updated here to always add it, even if poll response, need component to handle that first though.
      if (
        ChatMessageDtoHelper.isChatMessage(dto) ||
        ChatMessageDtoHelper.isChatResponseMessageDto(dto)
      ) {
        if (vm.chatRoomId && vm.chatRoomId !== DEFAULT_CHATROOM_ID) {
          const existingDtos = newChatRoomMessageLookup[vm.chatRoomId] ?? []
          newChatRoomMessageLookup[vm.chatRoomId] = [...existingDtos, dto]
        }
      }
    })
    // console.log(`Handle chat dtos user lookup vm`)
    // console.log(userLocationLookup)
    const state = {
      ...s,
      historicChatMessagesApiState: ApiState.createHadLoadedState(),
      // Set our source of truth for chat message dtos
      currentSchoolChatMessages: a.payload,
      // We handle chat message dtos on initial load and then every stale data clean up call, hence clearing our emptry chat rooms
      chatRoomIdToAttributesLookup: ChatRoomSelectViewModel.clearEmptyChatRooms(
        s.vm.chatVm.selectedChatRoomId,
        chatRoomIdToAttributesLookup,
        newChatRoomMessageLookup
      ),
      vm: {
        ...s.vm,
        chatVm: {
          ...chatVm,
          messagesByChatroomId: newChatRoomMessageLookup
        }
      },
      // State lookup updates
      userMobileIdToLastSertMessageLookup,
      userMobileIdToLastPollResponseLookup,
      userMobileIdToLastMessageResponseLookup,
      userMobileIdToAlerts,
      userMobileIdToNegateAlerts,
      sentPollToPollResponseLookup,
      sentPollToIncludedUserTypes,
      userLocationLookup
    }
    return state
  }

  //HISTORIC DATA
  //CHAT MESSAGE
  /** Track that we're loading historic chat messages */
  static handleGetHistoricChatMessages = (
    s: DashboardPageState,
    a: ExtendedAction<number>
  ): DashboardPageState => {
    return {
      ...s,
      historicChatMessagesApiState: ApiState.createIsLoadingState()
    }
  }
  static handleGetChatMessagesError = (
    s: DashboardPageState,
    a: ExtendedAction<HttpErrorResponse>
  ): DashboardPageState => {
    return {
      ...s,
      historicChatMessagesApiState: ApiState.createHasErrorState()
    }
  }
  /** Handle a chat message with chat rooms in mind, and keep track of all global messages for the default chat room. This function only saves lookups to allow location lookup vm to construct in map side effects for faster map handling. */
  static handleChatMessageDto = (
    s: DashboardPageState,
    a: ExtendedAction<ChatMessageDto>
  ): DashboardPageState => {
    const { chatVm } = s.vm

    const { payload: dto } = a
    const { logicalId } = dto.payload

    const userMobileIdToAlerts = {
      ...s.userMobileIdToAlerts
    }
    const userMobileIdToNegateAlerts = {
      ...s.userMobileIdToNegateAlerts
    }
    const userMobileIdToLastPollResponseLookup = {
      ...s.userMobileIdToLastPollResponseLookup
    }

    let sentPollToPollResponseLookup: Record<string, number[]> = {
      ...s.sentPollToPollResponseLookup
    }
    let sentPollToIncludedUserTypes: Record<string, MobileUserTypes[]> = {
      ...s.sentPollToIncludedUserTypes
    }
    let userLocationLookup = {
      ...s.userLocationLookup
    }
    let newMessagesByChatRoomId: ChatRoomMessagesLookup = {
      ...chatVm.messagesByChatroomId
    }
    const isChatResponse = ChatMessageDtoHelper.isChatResponseDto(dto)
    const isPollResponse = ChatMessageDtoHelper.isPollResponseWithPollIdDto(dto)
    if (isChatResponse && ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload)) {
      const { mobileUserId } = dto.payload
      if (ChatMessageDtoHelper.isAlertResponse(dto, s.responseIdToGetResponseDto)) {
        const existingAlerts = userMobileIdToAlerts[mobileUserId] ?? []
        userMobileIdToAlerts[mobileUserId] = [...existingAlerts, dto.payload]
      }
      if (ChatMessageDtoHelper.isNegatingAlertResponse(dto, s.responseIdToGetResponseDto)) {
        const { mobileUserId } = dto.payload
        const existingNegateAlerts = userMobileIdToNegateAlerts[mobileUserId] ?? []
        userMobileIdToNegateAlerts[mobileUserId] = [...existingNegateAlerts, dto.payload]
      }
      // Add chat response with response id add to everyone chat room and one on one chat room.
      // but only if it's a poll response since we return a different state in that case.
      if (isPollResponse) {
        const existingChatRoomMessages = newMessagesByChatRoomId[DEFAULT_CHATROOM_ID] ?? []
        newMessagesByChatRoomId[DEFAULT_CHATROOM_ID] = [...existingChatRoomMessages, dto]
        const existingOneOnOneMessages = newMessagesByChatRoomId[mobileUserId] ?? []
        newMessagesByChatRoomId[mobileUserId] = [...existingOneOnOneMessages, dto]
      }
    }
    // Update the location view model if the SERT member replies to a user
    if (ChatMessageDtoHelper.isChatMessageToMobileUserIds(dto)) {
      const mobileUserId = dto.payload.mobileUserIds[0]
      const existingLocationVm = userLocationLookup[mobileUserId] ?? null
      if (!existingLocationVm) {
        console.warn(
          `handleChatMessageDto: No existing location vm for mobile user id ${mobileUserId}`
        )
      } else {
        let newLocationVm =
          UserLocationViewModel.getDecoratedUserLocationFromChatMessage(existingLocationVm)
        if (newLocationVm) {
          // console.log(`SERT MEMBER RESPONSE - NEW USER LOCATION VM`)
          // console.log(newLocationVm)
          userLocationLookup[mobileUserId] = newLocationVm
        }
      }
    }

    //Every time we receive a resposne, if it's a poll response, aggregate it in the lookup for later reference
    if (isPollResponse) {
      const typedDto = dto.payload as ChatResponseDtoPayload
      if (!typedDto.mobileUserId) {
        console.warn(`Poll response received without mobile id.`)
        return s
      }
      //Upsert the most recent poll response by mobile user id
      userMobileIdToLastPollResponseLookup[typedDto.mobileUserId] = typedDto
      //Track response id by poll logical id
      if (dto.payload.pollLogicalId) {
        const { pollLogicalId, mobileUserId } = dto.payload
        sentPollToPollResponseLookup[pollLogicalId] = [
          ...(sentPollToPollResponseLookup[pollLogicalId] ?? []),
          dto.payload.responseId
        ]
        const userType = s.userToTypeLookup[mobileUserId]
        const userTypesForPoll = s.sentPollToIncludedUserTypes[pollLogicalId] ?? []
        if (!userTypesForPoll.includes(userType)) {
          sentPollToIncludedUserTypes[pollLogicalId] = [...userTypesForPoll, userType]
        }
      }
      if (dto.payload.mobileUserId) {
        const updatedVm = UserLocationViewModel.getDecoratedUserLocationFromChatResponse(
          s,
          userLocationLookup[dto.payload.mobileUserId],
          dto.payload
        )
        if (updatedVm) {
          userLocationLookup[dto.payload.mobileUserId] = updatedVm
        }
      }
      return {
        ...s,
        vm: {
          ...s.vm,
          chatVm: {
            ...s.vm.chatVm,
            messagesByChatroomId: newMessagesByChatRoomId
          }
        },
        currentSchoolChatMessages: [...s.currentSchoolChatMessages, a.payload],
        userMobileIdToAlerts,
        userMobileIdToNegateAlerts,
        userMobileIdToLastPollResponseLookup,
        sentPollToPollResponseLookup,
        sentPollToIncludedUserTypes,
        userLocationLookup
      }
    }
    const userMobileIdToLastMessageResponseLookup: Record<string, ChatResponseDtoPayload> = {
      ...s.userMobileIdToLastMessageResponseLookup
    }
    let chatRoomIdToAttributesLookup = Object.assign({}, s.chatRoomIdToAttributesLookup)
    const userMobileIdToLastSertMessageLookup = { ...s.userMobileIdToLastSertMessageLookup }
    // Ignore auto sent messages, they're not relevant to the mobile user messages getting responses from a SERT admin
    if (
      ChatMessageDtoHelper.isChatMessage(dto) &&
      dto.payload.mobileUserIds &&
      !dto.payload.autoSent
    ) {
      // If this dto is for a SERT message to a user track it by mobile id
      const userId = dto.payload.mobileUserIds[0] ?? null
      if (userId) {
        userMobileIdToLastSertMessageLookup[userId] = dto.payload
      }
    }
    const { chatRoomId, areaLogicalId } = dto.payload
    let chatRoomIdToUse: string | null = null
    if (!chatRoomId) {
      console.warn(`Chat message dto received without chat room id for dto with id: ${logicalId}`)
    }
    if (areaLogicalId && areaLogicalId !== DEFAULT_CHATROOM_ID) {
      const areaName = s.areaLogicalIdToChatRoomNameLookup[areaLogicalId]
      chatRoomIdToAttributesLookup[areaLogicalId] =
        ChatRoomSelectViewModel.buildAreaChatRoomSelectVm(areaLogicalId, areaName)
      chatRoomIdToUse = areaLogicalId
    } else if (
      // IF this is a SERT sent message
      // TODO Wrap in dto helper
      ChatMessageDtoHelper.isChatMessage(dto) &&
      Array.isArray(dto.payload.mobileUserIds) &&
      dto.payload.mobileUserIds.length > 0
    ) {
      const mobileUserId = dto.payload.mobileUserIds[0] ?? mockUnknownLogicalId
      if (mobileUserId) {
        chatRoomIdToUse = mobileUserId
      }
      const chatRoomExists = s.chatRoomIdToAttributesLookup[mobileUserId] ?? null
      // If the chat message dtp is for a new one on one message then create a chat room for it
      if (mobileUserId && !chatRoomExists) {
        chatRoomIdToAttributesLookup[mobileUserId] =
          ChatRoomSelectViewModel.buildOneOnOneChatRoomSelectVm(
            mobileUserId,
            s.mobileIdToDisplayNameLookup
          )
      }
    } else if (ChatMessageDtoHelper.isChatResponseDto(dto) && dto.payload.mobileUserId) {
      const { mobileUserId, message, isSos } = dto.payload
      chatRoomIdToUse = mobileUserId
      const existingChatRoomId = chatRoomIdToAttributesLookup[mobileUserId]
      // Create a chat room for the one on one message response if it doesn't exist already
      if (!existingChatRoomId) {
        chatRoomIdToAttributesLookup[mobileUserId] =
          ChatRoomSelectViewModel.buildOneOnOneChatRoomSelectVm(
            mobileUserId,
            s.mobileIdToDisplayNameLookup
          )
      }
      const isAlert = ChatMessageDtoHelper.isAlertResponse(dto, s.responseIdToGetResponseDto)
      // Upsert the latest message response from a mobile for last communication indication
      if (message && !isAlert) {
        userMobileIdToLastMessageResponseLookup[mobileUserId] = dto.payload
      }
      if (isAlert) {
        userMobileIdToAlerts[dto.payload.mobileUserId] = [
          ...(userMobileIdToAlerts[dto.payload.mobileUserId] ?? []),
          dto.payload
        ]
      }
      if (dto.payload.mobileUserId) {
        const updatedVm = UserLocationViewModel.getDecoratedUserLocationFromChatResponse(
          s,
          userLocationLookup[dto.payload.mobileUserId],
          dto.payload
        )
        if (updatedVm) {
          userLocationLookup[dto.payload.mobileUserId] = updatedVm
        }
      }
      newMessagesByChatRoomId = ChatRoomSelectViewModel.handleAddChatMessageDtoToAreaChatRoom(
        dto.payload,
        dto,
        newMessagesByChatRoomId
      )
    }
    // Now that we checked area logical id or mobile user id for targeting chat room id, we can assume it's for everyone
    if (!chatRoomIdToUse) {
      chatRoomIdToUse = DEFAULT_CHATROOM_ID
    }

    //If message is meant for the everyone chat room, only add it there, otherwise
    if (chatRoomIdToUse === DEFAULT_CHATROOM_ID) {
      const currentChatMessagesForChatRoom = newMessagesByChatRoomId[chatRoomIdToUse] ?? []
      newMessagesByChatRoomId = {
        ...newMessagesByChatRoomId,
        [chatRoomIdToUse]: [...currentChatMessagesForChatRoom, a.payload]
      }
      if (ChatMessageDtoHelper.canShowMessageInEntireSchooltRoom(a.payload)) {
        newMessagesByChatRoomId[ENTIRE_SCHOOL_CHATROOM_ID] = [
          ...newMessagesByChatRoomId[ENTIRE_SCHOOL_CHATROOM_ID],
          a.payload
        ]
      }
    } else {
      //Add it to both the everyone chat room and the specific area or user chat room
      const currentChatMessagesForChatRoom = newMessagesByChatRoomId[chatRoomIdToUse] ?? []
      const currentChatMessagesForEvenyoneChatRoom =
        newMessagesByChatRoomId[DEFAULT_CHATROOM_ID] ?? []
      newMessagesByChatRoomId = {
        ...newMessagesByChatRoomId,
        [DEFAULT_CHATROOM_ID]: [...currentChatMessagesForEvenyoneChatRoom, a.payload],
        [chatRoomIdToUse]: [...currentChatMessagesForChatRoom, a.payload]
      }
    }

    return {
      ...s,
      currentSchoolChatMessages: [...s.currentSchoolChatMessages, a.payload],
      userLocationLookup,
      chatRoomIdToAttributesLookup,
      userMobileIdToAlerts,
      userMobileIdToNegateAlerts,
      userMobileIdToLastMessageResponseLookup,
      userMobileIdToLastSertMessageLookup,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          messagesByChatroomId: newMessagesByChatRoomId
        }
      }
    }
  }
  static handleUserRegistrationComplete = (
    s: DashboardPageState,
    a: ExtendedAction<GetUserDto>
  ): DashboardPageState => {
    const registeredUser = a.payload
    if (
      !registeredUser.mobileId ||
      !registeredUser.schoolIds.some((x) => x.logicalId == s.selectedSchoolId.logicalId)
    ) {
      return s
    }
    let userLocationLookup = { ...s.userLocationLookup }
    let userToTypeLookup = { ...s.userToTypeLookup }
    let mobileIdToDisplayNameLookup = { ...s.mobileIdToDisplayNameLookup }

    let updatedUserCollection = [...(s.usersForSchoolLookup[s.selectedSchoolId.id] ?? [])]
    let existingUser = updatedUserCollection.find((x) => x.logicalId == a.payload.logicalId)
    if (existingUser == null) {
      updatedUserCollection = [...updatedUserCollection, registeredUser]
    } else {
      updatedUserCollection = updatedUserCollection.map((user) =>
        user.logicalId === registeredUser.logicalId
          ? { ...user, mobileId: registeredUser.mobileId }
          : user
      )
    }
    userToTypeLookup[registeredUser.mobileId] = registeredUser.mobileType ?? MobileUserTypes.unknown
    //If the user is a mobile app user, set up a location view model for future messages and locations
    if (a.payload.mobileId) {
      const userLocationVm = UserLocationViewModel.getLocationVmFromUserDto(a.payload)
      if (userLocationVm) {
        userLocationLookup[userLocationVm.mobileUserId] = userLocationVm
      }
    }

    mobileIdToDisplayNameLookup[registeredUser.mobileId] = UserDtoHelper.getFullName(registeredUser)
    return {
      ...s,
      mobileIdToDisplayNameLookup,
      userToTypeLookup,
      userLocationLookup,
      usersForSchoolLookup: {
        ...s.usersForSchoolLookup,
        [s.selectedSchoolId.id]: updatedUserCollection
      }
    }
  }
}

export const defaultDashboardPageState = new DashboardPageState()
