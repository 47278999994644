import { AttackerTypeEnum } from '@model/emergency.model'
import { ChatResponseDtoPayload } from '@model/message.model'
import { MobileUserTypes } from '@model/user/user.model'
import { StatusColorEnum } from '@view/area/area.view'
import { IThreatItemViewModel } from '@view/area/threat-area.view'

/** These are included as the type in the get predefined message dto but also as query params when using the service to get the dtos.*/
export enum PredefinedMessageType {
  poll = 'poll',
  message = 'message'
}

export enum MessageTypeEnum {
  chat = 'chat',
  instruction = 'instruction',
  update = 'update'
}

export const textInSelectMessage: Record<PredefinedMessageType, string> = {
  [PredefinedMessageType.message]: 'Select message',
  [PredefinedMessageType.poll]: 'Select Status Check'
}

export const messageIconName: Record<PredefinedMessageType, string> = {
  [PredefinedMessageType.message]: 'chat-in-message',
  [PredefinedMessageType.poll]: 'chat-in-poll'
}
export type AlertResponseTypes =
  | ResponseTypeEnum.threatIndicatorNoZone
  | ResponseTypeEnum.threatIndicatorWithZone
  | ResponseTypeEnum.medicalAlert
  | ResponseTypeEnum.missingPerson
export namespace PredefinedMessageHelper {
  export const reduceAreaAlertsByDimmedThreatVm = (
    existingAlertsForArea: ChatResponseDtoPayload[],
    threatVm: IThreatItemViewModel,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): ChatResponseDtoPayload[] => {
    return existingAlertsForArea.filter((alertDto) => {
      // Alerts must have a responseId or be an sos alert
      // WHat to do about isSos here?
      // if (!alertDto.isSos) {
      //   return false
      // }
      if (!alertDto.responseId) {
        return false
      }
      const getResponseDto = responseIdToGetResponseDto[alertDto.responseId]
      // Ignore other types
      if (
        getResponseDto.type !== ResponseTypeEnum.threatIndicatorWithZone &&
        getResponseDto.type !== ResponseTypeEnum.threatIndicatorNoZone
      ) {
        return true
      }
      // Ignore other threat indicators with different logical ids
      if (alertDto.logicalId !== threatVm.logicalId) {
        return true
      }
      return false
    })
  }
  /**
   *
   * @param responseType  The response type to check
   * @returns  True if the response type is a threat indicator, either with or without a zone, is the red threat area.
   */
  export const isThreatIndicator = (responseType: ResponseTypeEnum | null | undefined): boolean => {
    return (
      responseType === ResponseTypeEnum.threatIndicatorWithZone ||
      responseType === ResponseTypeEnum.threatIndicatorNoZone
    )
  }
  /**
   * In order for a message to be considered an alert it must have a response type we know about.
   * For those messages without a response id, an isSos is required but handled elsewhere.*/
  export const IsAlertResponseType = (
    responseType: ResponseTypeEnum | null | undefined
  ): responseType is AlertResponseTypes => {
    if (!responseType) return false
    return [
      ResponseTypeEnum.threatIndicatorWithZone,
      ResponseTypeEnum.threatIndicatorNoZone,
      ResponseTypeEnum.medicalAlert,
      ResponseTypeEnum.missingPerson
    ].includes(responseType)
  }
  export const isNegateAlertResponseType = (
    responseType: ResponseTypeEnum | null | undefined
  ): responseType is ResponseTypeEnum.negateMissingPerson => {
    return responseType === ResponseTypeEnum.negateMissingPerson
  }
}
/** All of these specify an alert type or a negation of a specific alert type. Don't inlcude non alert related values in this enumeration. */
export enum ResponseTypeEnum {
  //ALERT...
  /** Used in the threat indicator logic, for the threat model on the map, and in the area panel and popup. */
  threatIndicatorWithZone = 'threatIndicatorWithZone',
  /** Only displayed on the area panel and popup, IMPORTANT: Doesn't contribute to red threat area on the map. */
  threatIndicatorNoZone = 'threatIndicatorNoZone',
  /** Display on in the area panel and popup */
  medicalAlert = 'medicalAlert',
  missingPerson = 'missingPerson',
  //NEGATE ALERT...
  /** Used to remove the missing person indicator from an area and potentially affect the status color based on priority rules. */
  negateMissingPerson = 'negateMissingPerson'
}
/** Used for responses with a type which may not necessarily have a message to display. */
export const ResponseTypeDisplayStrings: Record<ResponseTypeEnum, string> = {
  [ResponseTypeEnum.threatIndicatorWithZone]: 'Attacker Alert',
  [ResponseTypeEnum.threatIndicatorNoZone]: 'Attacker Alert',
  [ResponseTypeEnum.medicalAlert]: 'Medical Alert',
  [ResponseTypeEnum.missingPerson]: 'Missing Person',
  [ResponseTypeEnum.negateMissingPerson]: ''
}
export class ResponseTypeAreaPriority {
  static getNegatingResponseType = (responseType: ResponseTypeEnum) => {
    switch (responseType) {
      case ResponseTypeEnum.missingPerson:
        return ResponseTypeEnum.negateMissingPerson
      default:
        return null
    }
  }
}

export enum ResponseDtoDisplayTextProps {
  text = 'text',
  longText = 'longText'
}
export interface GetResponseDto {
  /** This is the responseId we get back in the chat response dto*/
  id: number
  logicalId?: string
  [ResponseDtoDisplayTextProps.text]: string
  [ResponseDtoDisplayTextProps.longText]: string
  color: null | StatusColorEnum
  colorNumber: null | number
  /** Currently only used on mobile application */
  image?: string
  /** Specifically for display the the browser app*/
  browserImage?: string
  /** @value of true determines if poll response can be used for area status calculation */
  calculateAreaStatus?: boolean
  emergencyConfirmation?: boolean
  emergencyType?: AttackerTypeEnum | null
  /** This is included is specific response types that require us to track response to poll or unsolicited poll association aka alerts or negating alerts. */
  type?: ResponseTypeEnum | null
}
/** Returned in the predefined all endpoint. */
export interface GetPredefinedGroupDto {
  id: number
  groupId: string
  /**  This will be defined if the defaults have been overriden */
  removeFromToast: boolean | null
  description: string
  type: PredefinedMessageType
  messageType: MessageTypeEnum
  messages: GetPredefinedMessageDto[]
}

export interface GetPredefinedMessageDto {
  id: number
  predefinedGroupId: number | null
  schoolId: number | null
  text: string
  userType: MobileUserTypes | null
  responses: GetResponseDto[]
}
