import { MobileUserTypes, mobileUserAllowedOnMap } from '@model/user/user.model'
import { LocationGraphicAttrProps, LocationGraphicAttributes } from '@view/area/location.view'
import {
  ILocationVisibilityViewModel,
  locationVisibilityByTypeLookup
} from '@view/map/locations/map-control-ui.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisVisibilityHandler } from '../visibility/arcgis-visibility.view'

export const handleUserTypeVisibility = (
  context: ArcGisMapService,
  visibilityVm: ILocationVisibilityViewModel | null
) => {
  if (!visibilityVm) {
    return
  }
  //Handle showing and hiding visible popups while toggling visiblity by user type
  Object.values(MobileUserTypes).forEach((t) => {
    if (mobileUserAllowedOnMap(t)) {
      if (visibilityVm[locationVisibilityByTypeLookup[t]]) {
        showHiddenPopupIfShowingLayer(context, t)
      } else {
        hidePopupIfHidingLayer(context, t)
      }
    }
  })
  ArcGisVisibilityHandler.toggleVisibilityUserLayersByVisibilityVm(context, visibilityVm)
}
const hidePopupIfHidingLayer = (context: ArcGisMapService, t: MobileUserTypes) => {
  if (currentPopupIsForUserType(context, t) && context.popupRef?.popupVisible) {
    context.popupRef?.reset()
  }
  // console.log(`Hiding popup for type ${t}`)
}
const showHiddenPopupIfShowingLayer = (context: ArcGisMapService, t: MobileUserTypes) => {
  const typeMatches = currentPopupIsForUserType(context, t)
  if (typeMatches && context?.popupRef) {
    // console.log(`Showing popup for type ${t}`)
    context.popupRef.showPopup()
  }
}
const currentPopupIsForUserType = (context: ArcGisMapService, t: MobileUserTypes): boolean => {
  return (
    !!context.popupRef &&
    context.popupRef.isLocationPopup &&
    (context.popupRef.attributes as LocationGraphicAttributes)[
      LocationGraphicAttrProps.userType
    ] === t
  )
}
