import { Injectable } from "@angular/core";

@Injectable()
export class OpenStreetService {

    constructor() {
        // console.log('OpenstreetService constructor');
    }

    async getBoundary(wayId: number) {
        let res = await this.getFromOpenStreet(wayId);
        return await res.text();
    }

    async getFromOpenStreet(wayId: number): Promise<Response> {
        return fetch(`https://www.openstreetmap.org/api/0.6/way/${wayId}/full`)

    }
}