import Graphic from '@arcgis/core/Graphic'
import {
  GraphicAttributesTypeEnum,
  RecentMessageIndicatorTypeEnum
} from '@view/area/graphic-attributes.model'
import { LocationGraphicAttributes } from '@view/area/location.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'

export namespace ArcgisUserGraphicHandler {
  /** Here to only remove border styles from non selected areas
   * @returns true if update was executed
   */
  export const updateUserLocationSymbolsConditionally = (
    context: ArcGisMapService,
    g: Graphic,
    hover: boolean,
    isLocation: boolean,
    isRecentMessage: boolean
  ): boolean => {
    let actionTaken = false
    //First make sure we're not removing the selected style from a clicked area
    if (
      context.popupRef?.isShownDueToClick &&
      g.attributes?.id === context.popupRef?.attributes?.id
    ) {
      return false
    }
    if (isLocation) {
      ArcgisUserGraphicHandler.updateLocationGraphicSymbol(context, g, hover)
      actionTaken = true
    }
    if (isRecentMessage) {
      ArcgisUserGraphicHandler.updateRecentMessageGraphicSymbol(context, g, hover)
      actionTaken = true
    }
    return actionTaken
  }
  export const updateLocationGraphicSymbol = (
    context: ArcGisMapService,
    g: Graphic,
    hover: boolean
  ) => {
    if (g.attributes.kind === GraphicAttributesTypeEnum.location) {
      ArcgisUserGraphicHandler.setStyleForUserLocationGraphic(g, false, hover)
    }
  }
  export const updateRecentMessageGraphicSymbol = (
    context: ArcGisMapService,
    g: Graphic,
    hover: boolean
  ) => {
    ArcgisUserGraphicHandler.setStyleForRecentMessageGraphic(
      g,
      // We only hide the message symbol if we're about to show the popup, but if the popup is shown elsewhere due to a click, we don't want to hide the recent message symbol
      context.popupRef?.isShownDueToClick ? false : hover
    )
  }
  export const setStyleForUserLocationGraphic = (
    g: Graphic,
    selected: boolean,
    hover: boolean
  ): void => {
    g.symbol = ArcGisSymbolFactory.getSymbolForMobileUser(
      g.attributes?.userType,
      selected,
      hover
    ).clone()
  }

  export const setStyleForRecentMessageGraphic = (g: Graphic, hideSymbol: boolean): void => {
    // Similar logic to recent message symbol selection in user location handler but uses location view model - perhaps we should just use the location view model in the attributes
    // console.log(`setStyleForRecentMessageGraphic
    // g.attributes.id ${g.attributes.id}
    // hideSymbol ${hideSymbol}`)
    const attr = g.attributes as LocationGraphicAttributes
    // Seven options in how the recent message symbol can be rendered
    if (hideSymbol || attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.unknown) {
      // 1. hidden when nothing selected, on hover over location
      g.symbol = ArcGisSymbolFactory.hiddenSymbol.clone()
    } else if (attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.alert) {
      // 2. User submitted alert poll response
      g.symbol = ArcGisSymbolFactory.alertPollResponseRecentMessageSymbol.clone()
    } else if (attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.medicalAlert) {
      g.symbol = ArcGisSymbolFactory.medicalAlertPollResponseRecentMessageSymbol.clone()
    } else if (
      attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.redWithMessagePoll
    ) {
      // 3. User provided a red answer to a poll as the recent message
      g.symbol = ArcGisSymbolFactory.redMessageResponseRecentMessageSymbol.clone()
    } else if (
      attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.yellowWithMessagePoll
    ) {
      // 3. User provided a yellow answer to a poll as the recent message
      g.symbol = ArcGisSymbolFactory.yellowMessageResponseRecentMessageSymbol.clone()
    } else if (attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.redPoll) {
      // 4. User recently provided a message but the last poll response was a red response
      g.symbol = ArcGisSymbolFactory.redPollResponseRecentMessageSymbol.clone()
    } else if (attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.yellowPoll) {
      // 5. User recently provided a message but the last poll response was a yellow response
      g.symbol = ArcGisSymbolFactory.yellowPollResponseRecentMessageSymbol.clone()
    } else if (attr.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.chatMessage) {
      // 6. User recently send a regular chat message
      g.symbol = ArcGisSymbolFactory.regularRecentMessageSymbol.clone()
    } else {
      g.symbol = ArcGisSymbolFactory.hiddenSymbol.clone()
    }
  }
}
