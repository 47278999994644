import { LatLonAlt } from '../location.model'
import { DistrictDto } from './district.model'
import { SchoolConfiguationDto, SchoolOwlState } from './school-configuation.model'
import { SchoolPolygonBlobUrlDto } from './school-polygon-blob-url.model'
import { SchoolAreaDto } from './school-subarea.model'

export enum SchoolDtoProps {
  id = 'id',
  logicalId = 'logicalId',
  name = 'name',

  street = 'street',
  zip = 'zip',
  state = 'state',
  city = 'city',
  phone = 'phone',

  latLong = 'latLong',
  zoomLevel = 'zoomLevel',

  boundary = 'boundary',

  mainMap = 'mainMap',

  subareaMapBlobUrls = 'subareaMapBlobUrls',
  subareas = 'subareas',

  ncesSchoolId = 'ncesSchoolId',
  stateSchoolId = 'stateSchoolId',

  district = 'district',
  //TODO: Name should be fixed in API first
  schoolConfiguation = 'schoolConfiguation',
  schoolOwlState = 'schoolOwlState'
}

export interface SchoolDto {
  [SchoolDtoProps.id]?: number
  [SchoolDtoProps.logicalId]?: string
  [SchoolDtoProps.name]?: string

  /** General address info */
  [SchoolDtoProps.street]?: string
  [SchoolDtoProps.zip]?: string
  [SchoolDtoProps.state]?: string
  [SchoolDtoProps.city]?: string
  [SchoolDtoProps.phone]?: string

  /** We need a center for where to zoom the map */
  [SchoolDtoProps.latLong]?: LatLonAlt
  [SchoolDtoProps.boundary]: number[][] | null//"[[1,2], [3,4]]"

  /** TODO We'll need to plan the endpoint to upload the map image and then when the associated polygon is saved, we'll need to associate it. */
  [SchoolDtoProps.mainMap]?: SchoolPolygonBlobUrlDto

  /** TODO Plan these out as we'll want an easy way to get all subareas and if they're of type image, they'd include a blob url for the image. */
  [SchoolDtoProps.subareaMapBlobUrls]?: SchoolPolygonBlobUrlDto[]
  [SchoolDtoProps.subareas]?: SchoolAreaDto[]

  //School identifiers
  /** NCESSCH: National Center for Education Satistics -  */
  [SchoolDtoProps.ncesSchoolId]?: string
  /** LEAID: Local Education Agency Identification Number - used in Common Core of Data (CCD) */
  [SchoolDtoProps.stateSchoolId]?: string

  [SchoolDtoProps.district]?: DistrictDto
  [SchoolDtoProps.schoolConfiguation]?: SchoolConfiguationDto
  [SchoolDtoProps.schoolOwlState]?: SchoolOwlState
}
export interface PatchSchoolDto extends Partial<SchoolDto> {
  [SchoolDtoProps.id]: number
}
export interface GetSchoolDto extends SchoolDto {
  [SchoolDtoProps.id]: number
  [SchoolDtoProps.logicalId]: string
  [SchoolDtoProps.name]: string
}
