import { AbstractControl, FormControl, FormGroup } from '@angular/forms'
import { SchoolValidationViewModel } from '@view/school/school-validation.view'

export abstract class BaseFormModel<T> {
  protected abstract _form: FormGroup
  public isValid = () => this._form.valid
  /** Function that clears the form */
  public clearForm = () => {
    this._form.setValue(new SchoolValidationViewModel())
  }
  /** Function that returns form values */
  public getFormValues = () => {
    return this._form.value
  }
  /** Enables you to get any form value by the form prop name */
  public getFormValueByKey = (k: T) => {
    return this._form.get(k as string)?.value ?? ''
  }
  /** Enables you to get any form value by the form prop name */
  public getFormControlByKey = (k: T): FormControl => {
    return (this._form.get(k as string) as FormControl) ?? new FormControl()
  }
  public getRawFormValues = () => {
    return this._form.getRawValue()
  }
}
