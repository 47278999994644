import { SpatialReference } from '@arcgis/core/geometry'
export class ArcgisSpatialRefence {
  /** Web mercator spatial reference */
  static webMercatorSpatialReference = new SpatialReference({
    wkid: 3857
  })
  /** Geographic units */
  static geoUnitsLatLongSpatialReference = new SpatialReference({
    wkid: 4326
  })
}
