import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AppComponentService } from '@service/app.component.service'
import { TimeUtils } from '@shared/time.utils'
import { Observable } from 'rxjs'

export type IHttpResponse<T> = HttpResponse<T> | HttpErrorResponse

@Injectable()
export class ApiService {
  dateQuery(): string {
    return TimeUtils.getQueryString(this.appCompServ.getDateQueryForDashData())
  }
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private appCompServ: AppComponentService
  ) { }
  get<T>(path: string, options = {}
  ): Observable<T> {
    return this.http.get<T>(this.appConfig.getFullPath(path), options)
  }
  post<T, B>(path: string, body: B) {
    return this.http.post<T>(this.appConfig.getFullPath(path), body)
  }
  put<T, B extends object>(path: string, body: B) {
    return this.http.put<T>(this.appConfig.getFullPath(path), body)
  }
  patch<T, B extends object>(path: string, body: B) {
    return this.http.patch<T>(this.appConfig.getFullPath(path), body)
  }
  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.appConfig.getFullPath(path))
  }
}
