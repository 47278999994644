import { MobileUserTypes } from '@model/user/user.model'
import { MapConfigSteps } from '@view/pages/school-map-config-page/school-map-config.view'
import { ArcgisHitTest } from '../arcgis-hit-test.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcgisAreaGraphicHandler } from '../area/arcgis-area-symbol.view'
import { ArcGisGrahpicViewportHandler } from '../popup/arcgis-popup.view'
import { ArcGisLocationsHandler } from '../user-locations/arcgis-user-locations.view'

export namespace ArcGisMouseMoveHandler {
  /** context applies a hover state to areas, removing it from non hovered areas, and related cursor styles, but only shows a popup if there isn't one shown already */
  export const handleMapConfigMouseMove = async (
    context: ArcGisMapService,
    event: __esri.ViewPointerMoveEvent
  ) => {
    // TODO Decide if we should move this bigger popup to the left side
    if (context.mapConfigPopupRef && context.mapConfigPopupRef.popupVisible) {
      ArcGisGrahpicViewportHandler.handleGeometryPopupOutsideViewport(
        context._mapView,
        context.mapConfigPopupRef
      )
    }
    //Test to see if the cursor is over any areas
    // TODO Set up map config layers and use current step to decide if point cursor on boundary or area or on nothing as in step 1 and 4
    //Hit test is just for pointer logic
    const hasValidationError = context.mapConfigValidationError
    const isOnPerimeterStep = context.activeConfigStep === MapConfigSteps.schoolPerimeter
    const isOnAreaStep = context.activeConfigStep === MapConfigSteps.addAreas
    let response: __esri.HitTestResult | null = null
    if (isOnPerimeterStep && !hasValidationError) {
      response = await ArcgisHitTest.getHitForMapConfigPerimeterLayer(context, event)
    } else if (isOnAreaStep && !hasValidationError) {
      response = await ArcgisHitTest.getHitForMapConfigAreaLayer(context, event)
    }
    // console.log('isOnPerimeterStep' + isOnPerimeterStep)
    // console.log('isOnAreaStep' + isOnAreaStep)
    const results: __esri.ViewHit[] = response?.results ?? []
    const graphic = (results[0] as __esri.GraphicHit)?.graphic
    // console.log(`graphic.attributes`)
    // console.log(graphic?.attributes)
    //Update context local state for optimized mouse event handling and update cursor style based on hit test result
    if (graphic) {
      // console.log(`Mouse move to graphic id: ${graphic?.attributes?.id}`)
      context._mapView.container.style.cursor = 'pointer'
    } else {
      // console.log(`Not hovering on any map graphic`)
      context._mapView.container.style.cursor = 'default'
      return
    }
    // We only need to handle mouse move in map config when a popup is open
    if (!context.mapConfigPopupRef?.popupVisible) {
      return
    }
    //In this use case only update the popup if we're on the area step and we have a area popup visible
    if (
      isOnAreaStep &&
      context.mapConfigPopupRef?.popupVisible &&
      context.mapConfigPopupRef &&
      context.mapConfigPopupRef.graphic
    ) {
      ArcGisEventHandlers.handleMapConfigPointerMove(
        context._mapView,
        event,
        context.mapConfigPopupRef.graphic,
        context.mapConfigPopupRef
      )
    }
  }
  /** context applies a hover state to areas, removing it from non hovered areas, and related cursor styles, but only shows a popup if there isn't one shown already */
  export const handleDashMouseMove = async (
    context: ArcGisMapService,
    event: __esri.ViewPointerMoveEvent
  ) => {
    if (context.popupRef) {
      ArcGisGrahpicViewportHandler.handleGeometryPopupOutsideViewport(
        context._mapView,
        context.popupRef
      )
    }
    //Test to see if the cursor is over any areas
    const response = await ArcgisHitTest.getHitForDashLayers(context, event)
    const result = ArcgisHitTest.getHitResult(response, context)
    const idOfLayer = result?.layer?.id
    const graphic = (result as __esri.GraphicHit)?.graphic
    const idOfGraphicAttribute = graphic?.attributes?.id ?? null

    // If we're still hovering over the same user as last time, don't change any styles, or change anything about the popup
    if (idOfGraphicAttribute && idOfGraphicAttribute === context.lastHoveredUserId) {
      return
    }
    const resultIs_studentLocation = idOfLayer === context._layerTypeToIdLookup.studentLocation
    const resultIs_teacherLocation = idOfLayer === context._layerTypeToIdLookup.teacherLocation
    const resultIs_otherStaffLocation =
      idOfLayer === context._layerTypeToIdLookup.otherStaffLocation
    const resultIs_guestLocation = idOfLayer === context._layerTypeToIdLookup.guestLocation
    const resultIsOnLocationLayer =
      resultIs_studentLocation ||
      resultIs_teacherLocation ||
      resultIs_otherStaffLocation ||
      resultIs_guestLocation
    const resultIsOnAreaLayer = idOfLayer === context._layerTypeToIdLookup.area
    const resultIsOnDimmedAlertLayer = idOfLayer === context._layerTypeToIdLookup.dimmedAlert
    const resultIsOnPulsingAlertLayer = idOfLayer === context._layerTypeToIdLookup.pulsingAlert
    const resultIsOnMedicalAlertLayer = idOfLayer === context._layerTypeToIdLookup.medicalAlert
    const resultIsOnAttackAlertLayer = idOfLayer === context._layerTypeToIdLookup.attackAlert
    const resultIsOnOneOfTheAlertLayers =
      resultIsOnPulsingAlertLayer ||
      resultIsOnMedicalAlertLayer ||
      resultIsOnDimmedAlertLayer ||
      resultIsOnAttackAlertLayer

    // Don't allow interactions with opaque locations
    const graphicIsOpaque = context.opaqueUserIds[graphic?.attributes.id]
    if (resultIsOnLocationLayer && graphicIsOpaque) {
      return
    }
    // console.log(`graphic.attributes`)
    // console.log(graphic?.attributes)

    ArcGisMouseMoveHandler.updateLastHoveredStylesConditionally(context)
    //Update context local state for optimized mouse event handling and update cursor style based on hit test result
    const idOfGraphicIsntAlreadyClicked =
      context.lastClickedAreaId !== idOfGraphicAttribute &&
      context.lastClickedUserId !== idOfGraphicAttribute &&
      context.lastClickedAlertId !== idOfGraphicAttribute
    if (idOfGraphicAttribute && idOfGraphicIsntAlreadyClicked) {
      // console.log(`Mouse move to graphic id: ${idOfGraphicAttribute}`)
      context._mapView.container.style.cursor = 'pointer'
    } else {
      // console.log(`Not hovering on any map graphic`)
      context.lastHoveredUserId = null
      context.lastHoveredUserType = null
      context.lastHoveredAreaId = null
      //If we're not hovering over an area then revert the cursor and the other area styles
      context.popupRef?.hidePopupOnZoomOrHoverEvent()
      context._mapView.container.style.cursor = 'default'
      return
      //Update the cursor to indicate ability to click
    }

    //Save the id of the clicked item for easier handling next mouse event to optimize and reduce amount of graphic iteration on mouse events
    let actionTaken = false
    if (resultIsOnLocationLayer) {
      context.lastHoveredUserId = idOfGraphicAttribute
      context.lastHoveredAreaId = null
      if (resultIs_guestLocation) {
        actionTaken = ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          idOfGraphicAttribute,
          context._guestLocationLayer,
          context._guestRecentMessageLayer,
          true
        )
        context.lastHoveredUserType = MobileUserTypes.guest
      }
      if (resultIs_teacherLocation) {
        actionTaken = ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          idOfGraphicAttribute,
          context._teacherLocationLayer,
          context._teacherRecentMessageLayer,
          true
        )
        context.lastHoveredUserType = MobileUserTypes.teacher
      }
      if (resultIs_studentLocation) {
        actionTaken = ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          idOfGraphicAttribute,
          context._studentLocationLayer,
          context._studentRecentMessageLayer,
          true
        )
        context.lastHoveredUserType = MobileUserTypes.student
      }
      if (resultIs_otherStaffLocation) {
        actionTaken = ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          idOfGraphicAttribute,
          context._otherStaffLocationLayer,
          context._otherStaffRecentMessageLayer,
          true
        )
        context.lastHoveredUserType = MobileUserTypes.otherStaff
      }
    }
    // Avoid updating area styles if we're hovering over an area that's selected
    if (resultIsOnAreaLayer && context.lastClickedAreaId !== idOfGraphicAttribute) {
      context.lastHoveredUserId = null
      context.lastHoveredUserType = null
      context.lastHoveredAreaId = idOfGraphicAttribute
      actionTaken = ArcgisAreaGraphicHandler.updateAreaGraphicSymbolConditionally(
        context,
        graphic,
        true
      )
    }
    if (resultIsOnOneOfTheAlertLayers) {
    
        actionTaken = true
    }
    if (!context.popupRef) {
      console.warn(`Can't update popup due to missing popup component reference.`)
      return
    }
    if (actionTaken && !context.popupRef?.isShownDueToClick) {
      ArcGisEventHandlers.handleDashPointerMove(context._mapView, event, graphic, context.popupRef)
    }
  }
  /**
   * Whether we're now hovering over:
   * 1. nothing,
   * 2. an area,
   * 3. an pulsing alert or
   * 4. a location
   * we need to remove styles from the last thing we hovered if we did.
   */
  export const updateLastHoveredStylesConditionally = (context: ArcGisMapService) => {
    if (context.lastHoveredUserId) {
      // TODO Extract to function that takes a type as input or use arcgis layers to return a collection of all layers to call this function on.
      if (
        context._studentLocationLayer?.visible &&
        context.lastHoveredUserType === MobileUserTypes.student
      ) {
        ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          context.lastHoveredUserId,
          context._studentLocationLayer,
          context._studentRecentMessageLayer,
          false
        )
      }
      if (
        context._teacherLocationLayer?.visible &&
        context.lastHoveredUserType === MobileUserTypes.teacher
      ) {
        ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          context.lastHoveredUserId,
          context._teacherLocationLayer,
          context._teacherRecentMessageLayer,
          false
        )
      }
      if (
        context._otherStaffLocationLayer?.visible &&
        context.lastHoveredUserType === MobileUserTypes.otherStaff
      ) {
        ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          context.lastHoveredUserId,
          context._otherStaffLocationLayer,
          context._otherStaffRecentMessageLayer,
          false
        )
      }
      if (
        context._guestLocationLayer?.visible &&
        context.lastHoveredUserType === MobileUserTypes.guest
      ) {
        ArcGisLocationsHandler.updateLocationOnHoverChange(
          context,
          context.lastHoveredUserId,
          context._guestLocationLayer,
          context._guestRecentMessageLayer,
          false
        )
      }
    }
    if (context.lastHoveredAreaId) {
      const existingGraphic = context._schoolAreaLayer?.graphics.find(
        (g) => g.attributes.id === context.lastHoveredAreaId
      )
      if (existingGraphic) {
        ArcgisAreaGraphicHandler.updateAreaGraphicSymbolConditionally(
          context,
          existingGraphic,
          false
        )
      }
    }
  }
  /** Prevent map rotate through right mouse button click */
  export const handleImmediateClickToSkipRotation = (event: __esri.ViewDragEvent) => {
    if (event.button === 2) {
      event.stopPropagation()
    }
    // console.log(`Skipping propogation for right mouse click on map config.`, event)
  }
}
