<div class="modal-container" *ngIf="vm.readyToShow" [ngClass]="{ '--hide-icon': !showIcon }">
  <div class="_modal-row _icon-section" *ngIf="showIcon">
    <img src="{{ titleSvgPath }}" />
  </div>
  <div class="_modal-row _heading">
    {{ vm.titleText }}
  </div>
  <ng-container *ngIf="hasMultiLinePrompt; else singleLineContent">
    <div *ngFor="let line of mulitLinePrompt" class="_modal-row _content">
      {{ line }}
    </div>
  </ng-container>
  <ng-template #singleLineContent>
    <div class="_modal-row _content">
      {{ vm.promptText }}
    </div>
  </ng-template>
  <app-revert-to-standard-modal-checkboxex
    *ngIf="vm.showRevertToStandardCheckboxex"
  ></app-revert-to-standard-modal-checkboxex>
  <div class="_modal-row _action-buttons">
    <app-button
      *ngIf="!!vm.confirmButtonText"
      [mat-dialog-close]="true"
      (click)="onConfirm()"
      [buttonText]="vm.confirmButtonText"
      [buttonIcon]="vm.confirmButtonIcon"
    ></app-button>
    <app-button
      *ngIf="!!vm.cancelButtonText"
      [mat-dialog-close]="true"
      (click)="onCancel()"
      [buttonText]="vm.cancelButtonText"
      [buttonType]="cancelButtonType"
    ></app-button>
  </div>
</div>
