import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AuthService } from '@service/auth/auth.service'
import { SessionStorageKeys } from '@service/browser/base-storage.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { Observable } from 'rxjs'

@Injectable()
export class AuthRouteGuard implements CanActivate {
  constructor(private authService: AuthService, private appConfigService: AppConfigService, private loggingService: LoggingService

  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    //First check if app is public version or not
    if (this.appConfigService.isPublicSite()) {
      this.authService.redirectToWelcomePage()
      return false
    }
    let isAuthed = this.authService.userHasToken()

    let useAuth = this.appConfigService.config.USE_AUTH

    this.loggingService.logLocally(`
      In AUTH GUARD, with state:
        isAuthenticated ${isAuthed}
        state url ${state.url}
        route url ${route.url}
      `, OwlLoggingArea.ROUTING, OwlLogLevels.VERBOSE)

    if (isAuthed) {
      return true
    } else {
      this.saveRequestConditionally(state.url)
      return false
    }
  }

  /** Save the initial request, if it's not an auth based route in order to redirect user there once they authenticate */
  saveRequestConditionally = (url: string) => {
    if (
      url.indexOf(`${FirstLevelDir.auth}`) === -1 &&
      url.indexOf(`${FirstLevelDir.public}`) === -1
    ) {
      // console.log(`Saving initial page request ${url}`)
      sessionStorage.setItem(SessionStorageKeys.initUrlRequestKey, url)
    }
  }
}
