import { createAction, props } from '@ngrx/store'
import { ExtendedAction } from './extended-ngrx-action'

export enum DashboardPageActions {
  selectChatRoomById = '[DashboardPageActions] Set Active Chat Room By Id',
  disableCheckStatus = '[DashboardPageActions] Disable Check Status',
  showChatRoomUi = '[DashboardPageActions] Toggle Chat Component Visibility',
  clearStaleDashData = '[DashboardPageActions] Clear Stale Dash Data',
  updateThreatModelEventLoop = '[DashboardPageActions] Update Dashboard Threat Model Event Loop'
}
export const setChatRoomById = createAction(
  DashboardPageActions.selectChatRoomById,
  props<ExtendedAction<string>>()
)
export const disableCheckStatus = createAction(
  DashboardPageActions.disableCheckStatus,
  props<ExtendedAction<any>>()
)
export const toggleChatUiVisibility = createAction(DashboardPageActions.showChatRoomUi)
export const clearStaleDashData = createAction(
  DashboardPageActions.clearStaleDashData,
  props<ExtendedAction<Date>>()
)
export const updateThreatModelEventLoop = createAction(
  DashboardPageActions.updateThreatModelEventLoop
)
