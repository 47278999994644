import { AreaType, AreaTypeDisplayNames, SelectableAreaType } from '@model/school/sub-area.model'
import { AreaStatusViewModel } from '@view/area/area-status.view'

export namespace CommonUtils {
  /** Utilizes properties of the area dto to construct a name based on it's type.*/
  export const getSmartAreaName = (type: string | undefined, name: string | undefined): string => {
    if (!name) {
      console.warn(`getSmartAreaName for area name falsey value is not supported`)
      return ''
    }
    if (!type) return name
    if (type === AreaType.unknown || type === AreaType.image) {
      console.warn(`getSmartAreaName for Area type ${type} is not supported`)
      return name
    }
    if (type === AreaType.classroom)
      return `${type.charAt(0).toUpperCase()}${type.slice(1)} ${name}`
    if (type === AreaType.other) return name
    const typeLabel = AreaTypeDisplayNames[type as SelectableAreaType]
    return `${name} ${typeLabel.charAt(0).toUpperCase()}${typeLabel.slice(1).toLowerCase()}`
  }
  export const getSubAreaType = (
    areaLogicalId: string,
    areaStatusLookup: Record<number, AreaStatusViewModel>
  ): string =>
    Object.entries(areaStatusLookup).find((lookup) => lookup[1].logicalId === areaLogicalId)?.[1]
      .type || ''
}
