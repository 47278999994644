import { getExtendedAction } from '@action/extended-ngrx-action'
import { patchSchoolDto } from '@action/school-map-config-page.actions'
import { Polygon } from '@arcgis/core/geometry'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'

export namespace ArcGisSaveMapConfigHandler {
  /** If the current school dto doesn't have a boundary we first require the user to save a boundary, then they can optionally upload an image to trace, and then they can add areas one at a time, TODO move function docs to dispatch save school area here */
  export const saveSchool = (context: ArcGisMapService) => {
    if (!context.schoolDto) return
    // Ensure we have a sketch to save for the boundary
    if (!context._sketchViewModel || !context._sketchViewModel?.updateGraphics) {
      return
    }
    // Take the sketch view model out of edit view since we're transitioning to the sub area section
    context._sketchViewModel.complete()
    // const updatedBoundaryGraphic = context._sketchViewModel.updateGraphics.at(0)
    const updatedBoundaryGraphic = context?._schoolBoundaryLayer?.graphics.at(0)

    if (!updatedBoundaryGraphic) {
      console.error(`Attempting to save without a boundary graphic`)
      return
    }
    //Swap lat and long and project to lon lat
    const projectedGeometry = ArcGisMapProjectionViewModel.projectToSpatialReference(
      updatedBoundaryGraphic.geometry,
      ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    )
    //Get the project rings and swap the lat and long
    const projectedRings: number[][] = (projectedGeometry as Polygon).rings[0]
    const action = getExtendedAction({
      id: context.schoolDto.id,
      boundary: projectedRings
    })
    console.log('save boundary action ', action)
    context.store.dispatch(patchSchoolDto(action))
  }
}
