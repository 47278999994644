import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import {
  CommunicationsStandardDto,
  CurrentCommunicationsStandardDto
} from '@view/pages/instructions-page/instructions-page-dto.view'
import { Observable } from 'rxjs'

@Injectable()
export class CommunicationsStandardApiService {
  public readonly serviceBaseDir = 'CommunicationsStandard'
  constructor(private _apiService: ApiService) {}

  getCommunicationsStandards(schoolId: Number): Observable<CommunicationsStandardDto[]> {
    return this._apiService.get(`/${this.serviceBaseDir}?schoolId=${schoolId}`)
  }

  updateCommunicationsStandard(
    schoolId: Number,
    standardId: number
  ): Observable<CommunicationsStandardDto> {
    return this._apiService.patch(
      `/${this.serviceBaseDir}?schoolId=${schoolId}&communicationsStandard=${standardId}`,
      {}
    )
  }

  getCurrentStandard(schoolId: Number): Observable<CurrentCommunicationsStandardDto> {
    return this._apiService.get(`/${this.serviceBaseDir}/school?schoolId=${schoolId}`)
  }
}
