import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { showActivityTimeOutModal } from '@action/global-app.actions'
import { AppConfigService } from '@service/app-config/app-config.service'
import { AuthService } from '@service/auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ActivityTimerService {
  events: string[] = ['mousemove', 'mousedown', 'wheel', 'keypress']
  lastEventTimeStamp: Date = new Date()
  eventThrottleMs = 1000
  timeoutMs = 300000
  timeoutWarningMs = 10000
  warningTimeOut: any
  logOutTimeOut: any

  constructor(
    private appConfig: AppConfigService,
    private store: Store,
    private authService: AuthService
  ) {
  }
  
  init = () => {
    // Set the event throttle and timeout values from the app config
    this.eventThrottleMs = this.appConfig.config.APP_TIMEOUT_EVENT_THROTTLE_MS
    this.timeoutMs = this.appConfig.config.APP_TIMEOUT_MINUTES * 60000
    this.timeoutWarningMs = this.appConfig.config.APP_TIMEOUT_WARNING_SECONDS * 1000

    // Set the event listeners
    this.warningTimeOut = setTimeout(
      this.handleWorningTimeOut,
      this.timeoutMs - this.timeoutWarningMs
    )

    // Set the event listeners
    this.events.forEach((e) => {
      window.addEventListener(e, this.handleUserEvent)
    })
  }

  destroy = () => {
    this.events.forEach((e) => {
      window.removeEventListener(e, this.handleUserEvent)
    })
    if (this.logOutTimeOut) {
      clearTimeout(this.logOutTimeOut)
    }
    if (this.warningTimeOut) {
      clearTimeout(this.warningTimeOut)
    }
  }

  handleUserEvent = () => {
    const timeSinceLastUserEventHandled = new Date().getTime() - this.lastEventTimeStamp?.getTime()
    if (timeSinceLastUserEventHandled < this.eventThrottleMs) {
      // console.log(`Return because it's too soon to track user event.`)
      return
    }
    // console.log(`Tracking user event.`)
    this.lastEventTimeStamp = new Date()

    if (this.logOutTimeOut) {
      clearTimeout(this.logOutTimeOut)
      this.logOutTimeOut = null
    }

    clearTimeout(this.warningTimeOut)
    this.warningTimeOut = setTimeout(
      this.handleWorningTimeOut,
      this.timeoutMs - this.timeoutWarningMs
    )
  }

  handleWorningTimeOut = () => {
    // console.log(`handleWorningTimeOut.`)
    this.store.dispatch(showActivityTimeOutModal())
    this.logOutTimeOut = setTimeout(this.handleLogOutTimeout, this.timeoutWarningMs)
  }

  handleLogOutTimeout = () => {
    // console.log(`handleLogOutTimeout.`)
    this.authService.logout()
  }
}
