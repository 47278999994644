import Graphic from '@arcgis/core/Graphic'
import { Polygon } from '@arcgis/core/geometry'
import { ValidSchoolAreaDto } from '@model/school/school-subarea.model'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'
import { ArcGisMapService } from '../arcgis-map.service'

export namespace ArcGisAreaGraphicFactory {
  export const getAreaById = (context: ArcGisMapService, id: number): __esri.Graphic | null => {
    if (!context._schoolAreaLayer) {
      console.error(`DEV ERROR: context._schoolAreaLayer not defined`)
      return null
    }
    const area = context._schoolAreaLayer.graphics.find((a) => a.attributes.id === id)
    if (!area) {
      console.error(`DEV ERROR: Area with id not found`)
      return null
    }
    return area
  }
  export const getAllOtherAreasFromId = (
    context: ArcGisMapService,
    id: number
  ): __esri.Collection<Graphic> | null => {
    if (!context._schoolAreaLayer) {
      console.error(`DEV ERROR: context._schoolAreaLayer not defined`)
      return null
    }
    return context._schoolAreaLayer.graphics.filter((a) => a.attributes.id !== id) ?? []
  }
  export const getGraphicFromAreaDto = (dto: ValidSchoolAreaDto): __esri.Graphic => {
    const polygon = new Polygon({
      rings: [dto.boundary],
      spatialReference: ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    })
    return new Graphic({
      geometry: polygon,
      symbol: ArcGisSymbolFactory.getMapConfigDisplayGeomSymbol(),
      attributes: ArcGisGraphicAttrFac.getAttributesForAreaDto(dto)
    })
  }
  export const getLocationProximityBufferGraphic = (polygon: __esri.Polygon): __esri.Graphic => {
    return new Graphic({
      geometry: polygon,
      // TODO Set up custom symbol for this
      symbol: ArcGisSymbolFactory.proximityBufferSymbol.clone()
      // No attributes needed most likely
    })
  }
}
