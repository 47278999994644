import { MapConfigValidationErrors } from './school-map-config-validation.view'
import { MapConfigSteps } from './school-map-config.view'

export interface MapConfigSliderVm {
  currentMapConfigStep: MapConfigSteps
  visible: boolean
  min: number
  max: number
  step: number
  onStartValue: number
  rorateImg: boolean
  iconName: string | null
  postfix?: string
  onInputChange: (val: number) => void
  onPress: (val: number) => void
  onRelease: (val: number) => void
  type: MapConfigSliderTypeEnum
}
export enum MapConfigSliderTypeEnum {
  compass = 'compass',
  perimeter = 'perimeter'
}
export class MapConfigSliderViewModel {
  /** Depending on the map config step this configures the view model for the slider */
  static getVm = (
    currentStep: MapConfigSteps | undefined,
    error: MapConfigValidationErrors | null,
    max: number,
    startValue: number,
    onInputChange: (val: number) => void,
    onPress: (val: number) => void,
    onRelease: (val: number) => void,
    type: MapConfigSliderTypeEnum
  ): MapConfigSliderVm | null => {
    if (!currentStep) {
      return null
    }
    let result: MapConfigSliderVm = {
      visible: false,
      currentMapConfigStep: currentStep,
      min: 0,
      max: max,
      step: 1,
      onStartValue: startValue,
      rorateImg: false,
      iconName: null,
      postfix: undefined,
      onInputChange,
      onPress,
      onRelease,
      type
    }
    switch (currentStep) {
      case MapConfigSteps.mapView:
        result.max = 360
        result.rorateImg = true
        result.iconName = 'compass'
        result.postfix = '°'
        result.visible = true
        break
      case MapConfigSteps.schoolPerimeter:
        result.max = max
        result.onStartValue = startValue
        result.postfix = ' ft'
        result.visible = !error
        break
      case MapConfigSteps.addAreas:
        result.max = 100
        result.postfix = '%'
        result.onStartValue = 50 //TODO: add getting existing value
        // Once image upload is in place this can be toggled
        result.visible = false
        break
      default:
        result.max = 100
        break
    }
    return result
  }
}
