import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'
import { mockUnknownLogicalId } from '@mock/school.mock'
import { ChatResponseDtoPayload } from '@model/message.model'
import {
  ResponseTypeEnum,
  GetResponseDto,
  ResponseDtoDisplayTextProps,
  PredefinedMessageHelper
} from '@model/message/predefined-message.model'
import {
  SchoolAreaDto,
  GetSchoolAreaDto,
  SchoolAreaDtoProps
} from '@model/school/school-subarea.model'
import {
  AreaType,
  SelectableAreaType,
  isValidSelectableAreaType
} from '@model/school/sub-area.model'
import { MobileUserTypes } from '@model/user/user.model'
import { CommonUtils } from '@shared/common.utils'
import { IAreaPanelViewModel, StatusColorEnum } from './area.view'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { TimeUtils } from '@shared/time.utils'

export enum AreaStatusIcons {
  threat = 'threat',
  medical = 'medical',
  missingPerson = 'missingPerson'
}
export class AreaStatusViewModelDefaults {
  message = ''
  longMessage = ''
  images: ResponseTypeEnum[] = []
  lastStatus: StatusColorEnum = StatusColorEnum.unknown
  /** Updated when we receive a new status so we can know when the last activity for a room was. */
  lastStatusDate: Date | null = null
  currentResponseType?: ResponseTypeEnum | null = null
  currentStatusColor: StatusColorEnum = StatusColorEnum.unknown
  /** Added on the front end right as it's received to enable tracking delivery time. */
  currentStatusDate: Date | null = null
  userThatSetRedStatus: string | undefined = undefined
  statusSetByTeacher: boolean = false
  /** Use to determine if recalculation is needed because mobile user id contributes status to another area, therefore this area's status should be recalculated without that user's contribution. */
  userIdsContributingToStatus: Set<string> = new Set([])
}
export class AreaStatusViewModel extends AreaStatusViewModelDefaults {
  constructor(
    public name: string = '',
    public logicalId: string | undefined = mockUnknownLogicalId,
    public id: number = 0,
    public type: AreaType = AreaType.unknown
  ) {
    super()
  }
  /** Handle a collection of response types to determine which icons to show in the area status component. */
  static getIconLookupFromImages(images: ResponseTypeEnum[]): Record<AreaStatusIcons, boolean> {
    return {
      [AreaStatusIcons.threat]:
        images.includes(ResponseTypeEnum.threatIndicatorNoZone) ||
        images.includes(ResponseTypeEnum.threatIndicatorWithZone),
      [AreaStatusIcons.medical]: images.includes(ResponseTypeEnum.medicalAlert),
      [AreaStatusIcons.missingPerson]: images.includes(ResponseTypeEnum.missingPerson)
    }
  }
  /**
   * Only use responses that are allowed to be included in area status calculation, poll reponses and alerts (those with a response type).
   * When we receive a poll response from a user, and they are in a specific location, we want to update the area view model if the poll response update is applicable. Encodes prioritization for red status to be preserved.
   * Returns existing area vm if no changes made
   */
  static getAreaStatusVmFromResponse = (
    existingAreaVm: AreaStatusViewModel,
    chatResDto: ChatResponseDtoPayload,
    userType: MobileUserTypes,
    getResponseDto: GetResponseDto
  ): AreaStatusViewModel => {
    // console.log(`Area Status VM Logic for: Type for chat res dto is `, getResponseDto.type)
    const { mobileUserId, timestamp } = chatResDto
    if (!mobileUserId) {
      console.warn(`Ignoring status update from user with no mobile id. Mobile id is required.`)
      return existingAreaVm
    }
    if (!timestamp) {
      console.warn(`Ignoring status update from response with no timestamp. Timestamp is required.`)
      return existingAreaVm
    }
    let newVm: AreaStatusViewModel
    //Defensive code as all areas should have a default vm constructed in the lookup object
    if (!existingAreaVm) {
      newVm = new AreaStatusViewModel()
    } else {
      newVm = Object.assign({}, existingAreaVm)
    }
    //Since we recalculate using every included user's information, ignore stale data left over from an old pass on the collection
    const userIsTeacher = userType === MobileUserTypes.teacher
    const newStatusDate = new Date(timestamp)
    const responseIsOlderThanCurrenVmAppliedRes = AreaStatusViewModel.statusUpdateIsOutdated(
      newVm.currentStatusDate,
      newStatusDate
    )
    const responseHasNoType = !getResponseDto.type

    const [resCanUpdateAreaVm, newType, oldType] = AreaStatusViewModel.userResponseCanUpdateAreaVm(
      newVm,
      getResponseDto
    )
    //In the case of a poll repsonse without a specific type, ignore older responses unless it's from a teacher
    // TODO This may be an issue as the new alert type based logic requires a lot of refactoring for chronological based processing to be possible, need to ensure that all area dtos are handled in a sorted order if not currently
    if (responseHasNoType && responseIsOlderThanCurrenVmAppliedRes && !userIsTeacher) {
      console.info(
        `Skipping response from mobile id ${mobileUserId} as it's older than data we received!
        newType ${newType}
        oldType ${oldType}
        `
      )
      return newVm
    }
    //Handle Attack Alert, Medical Alert, and Missing Person Alert icon display logic first as it takes priority even if the status color and message can't be applied due to rules
    if (getResponseDto.type) {
      newVm.images = SchoolAreaModelHelper.getAreaIconImgsByNewType(newVm, getResponseDto.type)
    }
    // If the response is a typed response, and it's not allowed to update the vm, then we can't use it for area status calculation, but we do need to return the new version with the updated images
    if (!resCanUpdateAreaVm) {
      // console.log(
      //   `Ignoring response from mobile id ${mobileUserId} as it's not allowed to update the status of the area!
      //   newType ${newType}
      //   oldType ${oldType}
      //   `
      // )
      // console.log(chatResDto)
      return newVm
    }
    const newResponseHasStatusColor = !!getResponseDto.color

    // The response must either have a color or a type to be used for area status calculation
    if (!newResponseHasStatusColor && !resCanUpdateAreaVm) {
      console.warn(
        `Response for area status calculations has missing color property or a missing type so can't use it for area status logic!
        newType ${newType}
        oldType ${oldType}
        `
      )
      return newVm
    }
    if (
      !resCanUpdateAreaVm &&
      SchoolAreaModelHelper.areaStatusVmCantUpdateForNonAlertPollResponse(
        newVm,
        getResponseDto,
        chatResDto,
        userType
      )
    ) {
      // Local area status troubleshooting console log
      // console.info(`Ignoring new status
      //       from user id ${mobileUserId}
      //       user is teacher: ${userIsTeacher}
      //       who tried to update status to ${getResponseDto.color}
      //       `)
      return newVm
    }
    // TODO Confirm that this logic that was put in place for status check responses, applies to alerts as well. For now assuming that it's only applicable to non typed poll responses.

    //If we get to this point in code there will be a vm update so save ref to mobile user id that triggered this in case they leave the area.
    //Track the history of all status contributions for removal on user move to another area.
    if (!resCanUpdateAreaVm) {
      newVm.userIdsContributingToStatus = new Set([
        ...newVm.userIdsContributingToStatus,
        mobileUserId
      ])
    }
    const updateVmBasedOnNonTypedRes =
      (newVm.statusSetByTeacher && userIsTeacher) || newResponseHasStatusColor
    //Handle shared vm updates if the response is typed or if it's a non-typed poll response that can update the vm
    if (resCanUpdateAreaVm || updateVmBasedOnNonTypedRes) {
      // console.log(
      //   `User id ${mobileUserId}, setting area id ${areaId}, status to ${getResponseDto.color}`
      // )
      newVm.message = SchoolAreaModelHelper.getMessageConditionally(
        getResponseDto,
        ResponseDtoDisplayTextProps.text
      )
      newVm.longMessage = SchoolAreaModelHelper.getMessageConditionally(
        getResponseDto,
        ResponseDtoDisplayTextProps.longText
      )
      newVm.lastStatus = newVm.currentStatusColor
      newVm.lastStatusDate = newVm.currentStatusDate
      newVm.currentResponseType = getResponseDto.type
      newVm.currentStatusColor = getResponseDto.color ?? StatusColorEnum.unknown
      newVm.currentStatusDate = newStatusDate
      //If a user is setting the status to red only they or a teacher can revert that status
      newVm.statusSetByTeacher = userIsTeacher
    }

    //Status color of red only being overridable by a student that set it or a teacher is specific to non-typed poll responses only
    if (getResponseDto.color === StatusColorEnum.red) {
      newVm.userThatSetRedStatus = mobileUserId
    }
    return newVm
  }
  // static clearHistoricDtosFromOtherAreaIds = (
  //   newAreaId: number,
  //   historyOfAreaIdToAllResponses: Record<number, ChatResponseDtoPayload[]>,
  //   newAreaIdCollection: Record<number, ChatResponseDtoPayload[]>
  // )
  /** In order to apply statuses correctly, we need to know the predefined messages, we need the existing area status lookup, and we need a collection of chat response dtos grouped by the area they were received in.
   *  This function rebuilds the area status based on the passed in mapping of area id to related response dtos. This is rebuilt when a user changes their status so we can recalculate areas that have a removed status.
   */
  static getAreaStatusLookupByChatResponses(
    s: DashboardPageState,
    areaIdToUsersLastPollResponses: Record<number, ChatResponseDtoPayload[]>,
    areaIdToAlerts: Record<number, ChatResponseDtoPayload[]>,
    areaIdToNegatedAlerts: Record<number, ChatResponseDtoPayload[]>
  ): Record<number, AreaStatusViewModel> {
    let newAreaStatusLookup: Record<number, AreaStatusViewModel> = { ...s.areaStatusLookup }
    let areaIdsWithResponses: Set<number> = new Set(
      [
        ...Object.keys(areaIdToUsersLastPollResponses),
        ...Object.keys(areaIdToAlerts),
        ...Object.keys(areaIdToNegatedAlerts)
      ].map(Number)
    )
    //We must recalculate all other areas whenever getting a new response because the update could be associated to a user that has previously contributed a status update to another area which will mean that area's status is potentially different now
    for (let areaId of areaIdsWithResponses) {
      const mobileUsersLastPollResponseForArea = areaIdToUsersLastPollResponses[areaId] ?? []
      const alertsForArea = areaIdToAlerts[areaId] ?? []
      const negatedAlertsForArea = areaIdToNegatedAlerts[areaId] ?? []
      const newAreaStatusVm = AreaStatusViewModel.getAreaStatusVmFromDtoCollections(
        s,
        areaId,
        mobileUsersLastPollResponseForArea,
        alertsForArea,
        negatedAlertsForArea
      )
      newAreaStatusLookup[areaId] = newAreaStatusVm
    }
    // console.log(`new area status lookup`, newAreaStatusLookup)
    return newAreaStatusLookup
  }
  /**
   *
   * @param s  The global dashboard page state
   * @param areaId The id of the area we're updating the lookup for
   * @param mobileUsersLastPollResponseForArea The current collection of non alert related responses for the area
   * @param alertsForArea The current collection of alert related responses for the area
   * @param copyAreaStatusLookup  The area status lookup that we're updating
   * @returns  An updated copy of the area status lookup
   */
  static getAreaStatusVmFromDtoCollections = (
    s: DashboardPageState,
    areaId: number,
    mobileUsersLastPollResponseForArea: ChatResponseDtoPayload[],
    alertsForArea: ChatResponseDtoPayload[],
    negatedAlertsForArea: ChatResponseDtoPayload[]
  ): AreaStatusViewModel => {
    //Every time we rebuild from a recomputed collection, we need to reset the area status view model
    let newAreaStatusVm = AreaStatusViewModel.resetFromVm(s.areaStatusLookup[areaId])
    const alertsExistForArea = alertsForArea.length > 0
    const negatedAlertsExistForArea = negatedAlertsForArea.length > 0
    // TODO Split alerts by negating and non - possibly higher up the  chain
    if (alertsExistForArea) {
      //If alerts exist for the area, then use the alert poll responses to update the area status
      alertsForArea.forEach((responseDto) => {
        newAreaStatusVm = AreaStatusViewModel.getAreaStatusVmFromResponse(
          newAreaStatusVm,
          responseDto,
          s.userToTypeLookup[responseDto.mobileUserId ?? '0'],
          s.responseIdToGetResponseDto[responseDto.responseId ?? 0]
        )
      })
    }

    // If any negated alerts exist reprocess in case it removes one of the added alerts
    if (negatedAlertsExistForArea) {
      //If no alerts exist for the area, then use the non alert poll responses to update the area status
      negatedAlertsForArea.forEach((responseDto) => {
        //A response dto represents many objects, a poll result will have a response id
        if (!responseDto.responseId) {
          return
        }
        const alertToNegateDate = TimeUtils.getDateFromString(responseDto?.timestamp ?? '')
        const negateAlertEpochTime = alertToNegateDate?.getTime() ?? 0
        const currentVmEpochTime = newAreaStatusVm.currentStatusDate?.getTime()
        // Only negate the alert if current area status type is missing person and the negate alert was received after the current area status was applied
        if (
          newAreaStatusVm.currentResponseType === ResponseTypeEnum.missingPerson &&
          currentVmEpochTime &&
          currentVmEpochTime > negateAlertEpochTime
        ) {
          return
        }
        newAreaStatusVm = AreaStatusViewModel.getAreaStatusVmFromResponse(
          newAreaStatusVm,
          responseDto,
          s.userToTypeLookup[responseDto.mobileUserId ?? '0'],
          s.responseIdToGetResponseDto[responseDto.responseId ?? 0]
        )
      })
      // TODO need to else if there's a negating alert, then we need to update the area status vm with that
    }

    if (!alertsExistForArea && mobileUsersLastPollResponseForArea.length > 0) {
      //If no alerts exist for the area, then use the non alert poll responses to update the area status
      mobileUsersLastPollResponseForArea.forEach((responseDto) => {
        //A response dto represents many objects, a poll result will have a response id
        if (!responseDto.responseId) {
          return
        }
        newAreaStatusVm = AreaStatusViewModel.getAreaStatusVmFromResponse(
          newAreaStatusVm,
          responseDto,
          s.userToTypeLookup[responseDto.mobileUserId ?? '0'],
          s.responseIdToGetResponseDto[responseDto.responseId ?? 0]
        )
      })
    }
    return newAreaStatusVm
  }
  /**
   * Allows updating area state as long as the priority rules allow the override:
   * 2. If there's a missing person negated status, allow any update, whether alert or non-alert
   * 3. if the status is from a non alert poll response:
   *  Red Status: only allow updates from a teach or an alert type
   *  Green Status: only allow updates from a non alert poll response or an alert poll response, as negating an alert isn't allowed to take effect
   */
  static userResponseCanUpdateAreaVm = (
    areaStatusViewModel: AreaStatusViewModel,
    getResponseDto: GetResponseDto
  ): [boolean, ResponseTypeEnum | null | undefined, ResponseTypeEnum | null | undefined] => {
    let canUpdate = false
    const newType = getResponseDto.type
    const lastResponseType = areaStatusViewModel.currentResponseType
    //New type indicator
    const newTypeIsNonAlertPollResponse = !newType
    const newTypeNegatesMissingPerson = newType === ResponseTypeEnum.negateMissingPerson
    const newTypeIsMissingPerson = newType === ResponseTypeEnum.missingPerson
    const newTypeIsAttackAlert = PredefinedMessageHelper.isThreatIndicator(newType)
    const newTypeisMedicalAlert = newType === ResponseTypeEnum.medicalAlert
    //Old type indicator
    const oldTypeIsNonAlertPollResponse = !lastResponseType
    const oldTypeIsMissingPerson = lastResponseType === ResponseTypeEnum.missingPerson
    const oldTypeIsAttackAlert = PredefinedMessageHelper.isThreatIndicator(lastResponseType)
    const oldTypeisMedicalAlert = lastResponseType === ResponseTypeEnum.medicalAlert
    const oldTypeIsNegateMissingPerson = lastResponseType === ResponseTypeEnum.negateMissingPerson

    // 1. If there's no response type or status color on the vm, allow any update
    // Always allow updating the area with a response type if the last response type was null, as long as the new response type isn't a negate missing person alert
    if (!lastResponseType && areaStatusViewModel.currentStatusColor === StatusColorEnum.unknown) {
      canUpdate = true
    }

    //1st priority rule: If the old type is an alert type, it can only override the old type with a newer attack alert
    if (oldTypeIsAttackAlert) {
      canUpdate = newTypeIsAttackAlert
    }
    //2nd priority rule: If the old type is an medical alert type, it can only be overridden by a newer medical alert, or an attack alert
    else if (oldTypeisMedicalAlert) {
      canUpdate = newTypeisMedicalAlert || newTypeIsAttackAlert
    }
    //3rd priority rule: If the old type is a missing person alert, it can be overridden by a newer missing person alert, or a negate missing person alert, or one of the higher priority alerts like medical or attack
    else if (oldTypeIsMissingPerson) {
      canUpdate =
        newTypeIsMissingPerson ||
        newTypeNegatesMissingPerson ||
        newTypeisMedicalAlert ||
        newTypeIsAttackAlert
      //4th priority rule: If the old type is a negate missing person alert, it can be overridden by any of the higher priority alerts like medical or attack
    } else if (oldTypeIsNegateMissingPerson) {
      canUpdate = newTypeisMedicalAlert || newTypeIsAttackAlert || newTypeIsMissingPerson
    } else if (newTypeIsNonAlertPollResponse) {
      canUpdate = true
      //5th priority rule: If the old type is a non alert poll response, it can only be overridden by anything that's not a negate missing person alert
    } else if (oldTypeIsNonAlertPollResponse) {
      canUpdate = newTypeIsMissingPerson || newTypeIsAttackAlert || newTypeisMedicalAlert
    }
    return [canUpdate, newType, lastResponseType]
  }

  /** When processing poll responses with a color and no type, we need to ignore older updates */
  static statusUpdateIsOutdated = (
    currentStatusDate: Date | null,
    newStatusDate: Date
  ): boolean => {
    if (!currentStatusDate) {
      return false
    }
    const epochTimeOfLastUpdate = currentStatusDate?.getTime() ?? 0
    const epochTimeOfNewStatus = newStatusDate.getTime()
    return epochTimeOfLastUpdate > epochTimeOfNewStatus
  }
  static getStatusVmfromAreaDto = (dto: SchoolAreaDto): AreaStatusViewModel => {
    const { name, logicalId, id, type } = dto
    if (!logicalId) {
      console.warn(`getStatusVmfromAreaDto: logicalId is null for id ${id}`)
      return new AreaStatusViewModel(name, undefined, id, type)
    }
    return new AreaStatusViewModel(name, logicalId, id, type)
  }
  /** Builds a view model specific for the area tile,
   * as derived from the passed in area status vm
   * which is updated in global state dynamically so it must be kept as the source of truth. */
  static getAreaPanelViewModelFromStatusVm = (
    key: number,
    vm: AreaStatusViewModel
  ): IAreaPanelViewModel => {
    const { name, message, longMessage, images, type, currentStatusColor, logicalId } = vm
    // console.log('New area status vm')
    const iconLookup = AreaStatusViewModel.getIconLookupFromImages(images)
    const result: IAreaPanelViewModel = {
      id: key,
      logicalId: logicalId ?? mockUnknownLogicalId,
      displayString: CommonUtils.getSmartAreaName(type, name),
      name,
      message: AreaStatusViewModel.concatAreaMessage(longMessage ?? message),
      iconLookup,
      type: type as SelectableAreaType,
      areaStatus: currentStatusColor
    }
    return result
  }
  /**
   * When we receive a new area dto we need to update the area status lookup with a new area status view model
   */
  static updateAreaStatusLookupByNewAreaDto = (
    dto: GetSchoolAreaDto,
    areaStatusLookup: Record<number, AreaStatusViewModel>
  ) => {
    const newAreaStatusLookup = { ...areaStatusLookup }
    const newStatusVm = AreaStatusViewModel.getStatusVmfromAreaDto(dto)
    newAreaStatusLookup[dto[SchoolAreaDtoProps.id]] = newStatusVm
    return newAreaStatusLookup
  }
  static deleteAreaStatusLookupByNewAreaDto = (
    id: number,
    areaStatusLookup: Record<number, AreaStatusViewModel>
  ) => {
    const newAreaStatusLookup = { ...areaStatusLookup }
    delete newAreaStatusLookup[id]
    return newAreaStatusLookup
  }
  static updateAreaStatusLookupByPatchedAreaDto = (
    dto: SchoolAreaDto,
    areaStatusLookup: Record<number, AreaStatusViewModel>
  ): Record<number, AreaStatusViewModel> => {
    // console.log('*****', dto, areaStatusLookup)
    let newAreaStatusLookup = { ...areaStatusLookup }
    const areaId = dto[SchoolAreaDtoProps.id] ?? null
    if (!areaId) {
      console.warn('updateAreaStatusLookupByPatchedAreaDto: areaId is null')
      return newAreaStatusLookup
    }
    const existingAreaViewModel = newAreaStatusLookup[areaId]
    if (!existingAreaViewModel) {
      console.warn(`updateAreaStatusLookupByPatchedAreaDto: existingAreaViewModel is null`)
      return areaStatusLookup
    }
    let newAreaStatusVm = { ...existingAreaViewModel }
    if (
      dto.name &&
      dto.type &&
      (newAreaStatusVm.name !== dto.name || newAreaStatusVm.type !== dto.type)
    ) {
      newAreaStatusVm.name = dto.name
      newAreaStatusVm.type = dto.type
      newAreaStatusLookup[areaId] = newAreaStatusVm
    }
    return newAreaStatusLookup
  }

  /** When we reset an area status view model we need to preserve the name and logical id that we got when we first received the school dto's sub area collection */
  static resetFromVm = (vm: AreaStatusViewModel): AreaStatusViewModel => {
    return {
      ...vm,
      ...new AreaStatusViewModelDefaults()
    }
  }

  static getCollectionFromVm = (
    lookup: Record<number, AreaStatusViewModel>
  ): IAreaPanelViewModel[] => {
    return Object.entries(lookup)
      .filter((lookupItem) => {
        const { type } = lookupItem[1]
        const areaStatusVmHasValidType = isValidSelectableAreaType(type)
        if (!areaStatusVmHasValidType) {
          console.warn(`AreaStatusViewModel.getCollectionFromVm: Invalid area type ${type}`)
          return false
        }
        return true
      })
      .map((lookupItem) => {
        let key: number
        try {
          key = parseInt(lookupItem[0], 10)
        } catch (e) {
          // console.log('DEV error, non numberic value set in area status view model!')
          key = 0
        }
        const value = lookupItem[1]
        return AreaStatusViewModel.getAreaPanelViewModelFromStatusVm(key, value)
      })
  }

  static concatAreaMessage = (message: string) => {
    return message?.length > 28 ? message?.slice(0, 28).concat('...') : message
  }
}
