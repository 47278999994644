import { UserValidationStatus } from '@model/user/user.model'
import { createSelector } from '@ngrx/store'
import { selectSelectedSchoolId, selectUserDto } from '@selector/auth.selector'
import {
  RosterInvitationSummaryDto,
  RosterPageViewModel,
  RosterTabToMobileUserType,
  RosterTabs
} from '@view/pages/roster-page/roster-page.view'
import { RosterPageSummaryCardViewModel } from '@view/pages/roster-page/roster-summary.view'
import {
  selectRosterPageState,
  selectRosterPageVm,
  selectRosterTabToVmLookup,
  selectRosterVmBySelectedTab,
  selectSelectedUserTypeUsersExist
} from './roster-page.selector'
import { RosterTableViewModel } from '@view/pages/roster-page/roster-table.view'

export const selectSelectedRosterTab = createSelector(
  selectRosterPageVm,
  (vm) => vm.selectedRosterTab
)
export const selectRosterPageSpinnerState = createSelector
export const selectShowRosterPageSpinnerState = createSelector(
  selectRosterPageVm,
  (vm) => vm.showRosterPageSpinner
)
export const selectSelectedMobileUserTypeForTab = createSelector(
  selectSelectedRosterTab,
  RosterPageViewModel.getSelectedMobileUserTypeForTab
)
export const inviteSummaryDtoByMobileTypeLookup = createSelector(
  selectRosterPageState,
  (pageState) => pageState?.inviteSummaryDtoByMobileTypeLookup ?? null
)
export const selectInviteSummaryDtoForCurrentTab = createSelector(
  selectSelectedRosterTab,
  inviteSummaryDtoByMobileTypeLookup,
  RosterPageViewModel.getInvitatationSummaryDtoForCurrentTab
)
export const selectCurrentTypeInvitationSummaryErrors = createSelector(
  selectInviteSummaryDtoForCurrentTab,
  RosterPageViewModel.getUserInvitationsFromSummaryDto
)
// TODO This should use the roster vm for is processing rather than using the dto, for now they are the same but can be source of confusion as it's two sources of truth for loading a roster tab.
export const isProcessingInvitationForCurrentUserType = createSelector(
  selectInviteSummaryDtoForCurrentTab,
  (dto: RosterInvitationSummaryDto | null): boolean => {
    return dto ? !dto?.completed : false
  }
)
export const selectIsPreviewMod = createSelector(
  selectRosterPageVm,
  (vm) => vm?.rosterValidationMode
)

export const selectCanShowCreateUserButton = createSelector(
  selectSelectedRosterTab,
  (type) => type !== RosterTabs.consolidatedAllUsers
)
export const selectDynamicAddUserString = createSelector(
  selectSelectedRosterTab,
  RosterPageViewModel.getDynamicAddUserString
)
export const selectDynamicTooltipAddUserString = createSelector(
  selectSelectedRosterTab,
  RosterPageViewModel.getDynamicToolTipString
)
export const selectShowCreateUpdateUiPayload = createSelector(
  selectRosterPageVm,
  (vm) => vm.showCreateUpdateUiPayload
)
export const selectCreateUpdateUserUiIsVisible = createSelector(
  selectShowCreateUpdateUiPayload,
  (payload) => payload.show
)
export const selectCreateUpdateUserFormPayload = createSelector(
  selectShowCreateUpdateUiPayload,
  selectUserDto,
  RosterPageViewModel.getCreateUpdateUserFormPayload
)
export const selectShowDeactivateMw = createSelector(
  selectRosterPageVm,
  (vm) => vm?.showDeactivateMw
)
export const selectCurrentSchoolIdAndSelectedRosterTab = createSelector(
  selectSelectedRosterTab,
  selectSelectedSchoolId,
  (rosterTab, schoolIds) => ({
    rosterTab,
    schoolIds
  })
)
export const selectRosterValidationDto = createSelector(
  selectRosterPageVm,
  (vm) => vm.rosterValidationDto
)

export const selectPreviewDataUserValidationDto = createSelector(
  selectRosterValidationDto,
  (validationDto) => validationDto?.users
)
export const selectTabUserRosterVmsFromValidationDto = createSelector(
  selectPreviewDataUserValidationDto,
  selectSelectedRosterTab,
  RosterPageViewModel.getRosterUserVmsFromValidationDto
)
/** If we're in preview mode we show roster vms for validation dtos but while we're not in preview mode we show the roster tab related user view models. */
export const selectRosterUserVmsForSelectedTab = createSelector(
  selectRosterVmBySelectedTab,
  selectTabUserRosterVmsFromValidationDto,
  selectIsPreviewMod,
  (rosterTabVm, rosterUserVms, isPreview) => {
    if (isPreview) {
      return rosterUserVms
    }
    return rosterTabVm?.userVms ?? null
  }
)
/** Invitation summary is also know as preview mode. */
export const selectRosterValidationSummaryDtoIfIsPreview = createSelector(
  selectRosterValidationDto,
  selectIsPreviewMod,
  RosterPageSummaryCardViewModel.getValidationSummaryDtoIfIsPreview
)
export const selectIsSelectedMobileUserType = createSelector(
  selectSelectedRosterTab,
  (tab) => RosterTabToMobileUserType[tab]
)
export const selectRosterValidationIsValid = createSelector(selectRosterValidationDto, (dto) => {
  return dto?.valid ?? null
})

export const selectRosterValidationUserDtos = createSelector(
  selectRosterValidationDto,
  RosterTableViewModel.getUploadRosterVm
)

export const selectShowReinviteButton = createSelector(
  selectSelectedUserTypeUsersExist,
  selectIsPreviewMod,
  selectSelectedRosterTab,
  (existUsers, isPreview, selectedRosterTab) => {
    if (!isPreview && existUsers) {
      return RosterPageViewModel.tabIsForMobileUserType(selectedRosterTab)
    }
    return false
  }
)
export const selectSelectedTabUserCount = createSelector(
  selectRosterTabToVmLookup,
  selectSelectedRosterTab,
  (lookup, selectedTab) => {
    return lookup[selectedTab]?.userVms?.length ?? 0
  }
)

export const selectCurrentTabSummaryDtoUserInvitations = createSelector(
  selectInviteSummaryDtoForCurrentTab,
  RosterPageViewModel.getUserInvitationsFromSummaryDto
)

export const selectCurrentTabInviteErrors = createSelector(
  selectCurrentTabSummaryDtoUserInvitations,
  RosterPageViewModel.getCurrentTabInviteErrors
)
