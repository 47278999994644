import { ExtendedAction } from "@action/extended-ngrx-action";
import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export enum WelcomeApiActions {
    submitSignUpPilotUserSuccess = "[Welcome API] Submit Sign Up Pilot User Success",
    submitSignUpPilotUserFailure = "[Welcome API] Submit Sign Up Pilot User Failure",

    downloadSignupsSuccess = "[Welcome API] Download Signups Success",
    downloadSignupsFailure = "[Welcome API] Download Signups Failure",

    invitePilotUsersSuccess = "[Welcome API] Invite Pilot Users Success",
    invitePilotUsersFailure = "[Welcome API] Invite Pilot Users Failure",
}

export const submitSignUpPilotUserSuccess = createAction(
    WelcomeApiActions.submitSignUpPilotUserSuccess
)
export const submitSignUpPilotUserFailure = createAction(
    WelcomeApiActions.submitSignUpPilotUserFailure,
    props<ExtendedAction<HttpErrorResponse>>()
)
//Download Signups
export const downloadSignupsSuccess = createAction(
    WelcomeApiActions.downloadSignupsSuccess
)
export const downloadSignupsFailure = createAction(
    WelcomeApiActions.downloadSignupsFailure,
    props<ExtendedAction<HttpErrorResponse>>()
)
//Invite Pilot Users
export const invitePilotUsersSuccess = createAction(
    WelcomeApiActions.downloadSignupsSuccess
)
export const invitePilotUsersFailure = createAction(
    WelcomeApiActions.downloadSignupsFailure,
    props<ExtendedAction<HttpErrorResponse>>()
)