import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import { InvitationSummarySuccessPayload } from '@action/roster-page/roster-api.actions'
import {
  TriggerReinvitePayload
} from '@action/roster-page/roster-page.actions'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { SetRosterUsersByTabPayload } from '@model/user/set-roster-users-by-tab.payload'
import { GetUserDto, MobileUserShownOnMap, MobileUserTypes } from '@model/user/user.model'
import { ApiState } from '@state/api.state'
import {
  RosterInvitationSummaryDto,
  RosterPageViewModel,
  RosterTabToTabVmLookup,
  RosterTabs,
  UserInvitationHistoryDto
} from '@view/pages/roster-page/roster-page.view'
import { RosterTableViewModel } from '@view/pages/roster-page/roster-table.view'

export type InviteSummaryDtoByTabLookup = Record<RosterTabs, RosterInvitationSummaryDto>
export type InviteSummaryDtoByMobileUserTypeLookup = Record<
  MobileUserTypes,
  RosterInvitationSummaryDto | null
>
export type InviteHistoryDtoByUserIdLookup = Record<number, UserInvitationHistoryDto>

export class RosterPageState {
  /** Since this has a default constructor, it'll always be defined. */
  vm = new RosterPageViewModel()
  rosterTabVmLookupByTab: RosterTabToTabVmLookup = Object.values(RosterTabs).reduce((acc, tab) => {
    acc[tab] = null
    return acc
  }, {} as RosterTabToTabVmLookup)

  // DTO LOOKUPS
  /** Roster tab view model is null until created from collection of users. */
  /** Invitation data may or may not exists, server currently returns nothing if not invite exists, null used to indicate that outcome. */
  inviteSummaryDtoByMobileTypeLookup: InviteSummaryDtoByMobileUserTypeLookup = Object.values(
    MobileUserTypes
  ).reduce((acc, type) => {
    acc[type] = null
    return acc
  }, {} as InviteSummaryDtoByMobileUserTypeLookup)

  /**
   * Used for quick access to invite error by user id. Should be updated on any invite related network action.
   */
  userInviteHistoryDtoByUserIdLookup: InviteHistoryDtoByUserIdLookup = {}

  // API STATES
  invitationSummaryApiLookup: Record<MobileUserShownOnMap, ApiState> = {
    student: new ApiState(),
    teacher: new ApiState(),
    otherStaff: new ApiState()
  }
  /** TODO it seems we only track one api call but the expectation is that we'd track success and error of invitations by tab. */
  reinviteApiResult: ApiState = new ApiState()
  createUserApiState: ApiState = new ApiState()
  updateUserApiState: ApiState = new ApiState()
  serverValidations: Record<string, string> = {}
  createUpdateUserErrorText = ``

  constructor() {}
  // API Handling
  // Invitation Summary Dto
  static handleGetInvitationSummaryDto(
    s: RosterPageState,
    a: ExtendedAction<MobileUserTypes>
  ): RosterPageState {
    return {
      ...s,
      invitationSummaryApiLookup: {
        ...s.invitationSummaryApiLookup,
        [a.payload]: ApiState.createIsLoadingState()
      }
    }
  }
  static handleSignalrInvitationSummaryDto(
    s: RosterPageState,
    a: ExtendedAction<RosterInvitationSummaryDto>
  ): RosterPageState {
    if (!a.payload || !a.payload.userType) {
      console.warn('Signalr Payload issue in: No user type found in invitation summary dto')
      return s
    }
    const payload = getExtendedAction({
      invitationSummaryDto: a.payload,
      mobileUserType: a.payload.userType
    })
    return RosterPageState.getInvitationSummaryDtoSuccess(s, payload)
  }
  static getInvitationSummaryDtoSuccess = (
    s: RosterPageState,
    a: ExtendedAction<InvitationSummarySuccessPayload>
  ): RosterPageState => {
    const { invitationSummaryDto, mobileUserType } = a.payload
    const invitationSummaryApiLookup = {
      ...s.invitationSummaryApiLookup,
      [mobileUserType]: ApiState.createHadLoadedState()
    }
    // We'll try to get all the invitation summaries but they might not exist yet in which case it's null but we need to still update that the api has resolved to know that the page is ready to show.
    if (!invitationSummaryDto) {
      return {
        ...s,
        invitationSummaryApiLookup
      }
    }
    return {
      ...s,
      invitationSummaryApiLookup,
      // Any time we get an invitation summary we have to update potential errors for each roster user vm.
      rosterTabVmLookupByTab: RosterPageViewModel.getTabLoadingByTypeFromDto(
        s.rosterTabVmLookupByTab,
        invitationSummaryDto,
        mobileUserType
      ),
      inviteSummaryDtoByMobileTypeLookup: RosterPageViewModel.getUpdatedInvitationLookupWithNewDto(
        s.inviteSummaryDtoByMobileTypeLookup,
        invitationSummaryDto,
        mobileUserType
      ),
      userInviteHistoryDtoByUserIdLookup: RosterPageViewModel.getUpdatedInvitationHistoryLookup(
        a.payload.invitationSummaryDto,
        s.userInviteHistoryDtoByUserIdLookup
      )
    }
  }
  /** TODO If error needs display create http error response with mobile user type payload to handle. */
  static getInvitationSummaryDtoError = (
    s: RosterPageState,
    a: ExtendedAction<MobileUserTypes>
  ): RosterPageState => {
    if (!a.payload) {
      return s
    }
    return {
      ...s,
      invitationSummaryApiLookup: {
        ...s.invitationSummaryApiLookup,
        [a.payload]: ApiState.createHasErrorState()
      }
    }
  }

  //Handle Roster Page init - move to page view model
  static getSetRosterUsersByTabPayload(
    users: GetUserDto[] | null,
    inviteSummaryApiStateLookups: Record<MobileUserShownOnMap, ApiState>
  ): SetRosterUsersByTabPayload | null {
    const allApiStatesAreLoaded = Object.values(inviteSummaryApiStateLookups).every(
      (apiState) => apiState.hasLoaded
    )
    if (!allApiStatesAreLoaded) {
      return null
    }
    return {
      users: users ?? []
    }
  }
  static handleGetUsersByUserTypeLookup = (
    s: RosterPageState,
    a: ExtendedAction<SetRosterUsersByTabPayload>
  ): RosterPageState => {
    // Any time we need to get users for school, we need to set the selected school id which comes from auth state, awaiting decisions around multi school sessions and district associations
    return {
      ...s,
      rosterTabVmLookupByTab: UserDtoHelper.getRosterUsersByRosterTabLookup(
        a.payload,
        s.rosterTabVmLookupByTab,
        s.userInviteHistoryDtoByUserIdLookup
      ),
      vm: {
        ...s.vm,
        showRosterPageSpinner: false
      }
    }
  }
  /** Sets api state to loading and updates vm for tab to indicate loading. */
  static handleTriggerReinviteUnregAction = (
    s: RosterPageState,
    a: ExtendedAction<TriggerReinvitePayload>
  ): RosterPageState => {
    return {
      ...s,
      rosterTabVmLookupByTab: RosterTableViewModel.getUpdatedRosterTabWithReinviteSuccess(
        s.rosterTabVmLookupByTab,
        a.payload,
        true
      ),
      reinviteApiResult: ApiState.createIsLoadingState()
    }
  }

  /** Handles api state of loaded and updates vm for tab to indicate loading completed successfully. */
  static reinviteUnregisteredSuccess = (
    s: RosterPageState,
    a: ExtendedAction<TriggerReinvitePayload>
  ): RosterPageState => {
    return {
      ...s,
      rosterTabVmLookupByTab: RosterTableViewModel.getUpdatedRosterTabWithReinviteSuccess(
        s.rosterTabVmLookupByTab,
        a.payload,
        false
      ),
      reinviteApiResult: ApiState.createHadLoadedState()
    }
  }
  static reinviteUnregisteredError = (s: RosterPageState): RosterPageState => {
    return {
      ...s,
      reinviteApiResult: ApiState.createHasErrorState()
    }
  }
}
