// @ts-nocheck
import { Injectable } from "@angular/core";
//TODO decide if we'll ever try to make open layers work with angualr by trying to figure out how to fix the optimization bailout issue

// https://openlayers.org/en/latest/apidoc/module-ol_geom_Point-Point.html
// import Feature, { FeatureLike } from 'ol/Feature';
// import Map from 'ol/Map';
// import MapBrowserEvent from "ol/MapBrowserEvent";
// import View from 'ol/View';
// import { FullScreen, defaults as defaultControls } from 'ol/control';
// "ol-layerswitcher": "^4.1.1",
// import LayerSwitcher from 'ol-layerswitcher';
// import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';



// import { ListenerFunction } from "ol/events";
// import { Extent } from "ol/extent";
// import OSMXML from 'ol/format/OSMXML';
// import Geometry, { Type } from "ol/geom/Geometry";
// import Point from 'ol/geom/Point';
// import Polygon from 'ol/geom/Polygon';
// import Draw, { DrawEvent, GeometryFunction } from 'ol/interaction/Draw';
// import Modify from "ol/interaction/Modify";
// import Pointer from "ol/interaction/Pointer";
// import Translate, { TranslateEvent } from 'ol/interaction/Translate';
// import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
// import BaseLayer from "ol/layer/Base";
// import BaseVectorLayer from "ol/layer/BaseVector";
// import ImageLayer from "ol/layer/Image";
// import Projection from "ol/proj/Projection";
// import { OSM, Vector as VectorSource, XYZ } from 'ol/source';
// import Static from "ol/source/ImageStatic";
// import Fill from "ol/style/Fill";
// import Icon from 'ol/style/Icon';
// import Stroke from "ol/style/Stroke";
// import Style from 'ol/style/Style';

import { maxZoom, mockCenter } from "@mock/alerts.mock";
import { AlertDto } from "@model/alert.model";
import { MobileUserTypes } from "@model/user/user.model";
import { Store } from "@ngrx/store";
import { AppConfigService } from "@service/app-config/app-config.service";
import { OpenStreetService } from "@service/network/openstreet.service";
import { defaultOlZoom } from "@shared/constants";
import { GlobalState } from "@state/auth.state";
import { MapModesEnum, MapSourceEnum } from "@view/map/map-enums.view";
import { MapViewModel, SchoolBoundaryPartialState } from "@view/map/map.view";
import { MapProjectionViewModel } from "@view/map/ol-map-projection.view";
import { PolygonTypeEnum } from "../map.component.service.model";
import { BaseMapService } from "../map.service.model";
import { IOpenLayersMapService, OlSelectionTypeToCreate } from "./open-layers.service.model";


//https://openlayers.org/en/latest/examples/draw-shapes.html

@Injectable()
// export class OpenLayersMapService extends BaseMapService {
export class OpenLayersMapService extends BaseMapService<Map> implements IOpenLayersMapService {
    override _addPolygonAsRecommendedSchoolBoundary(partialState: Partial<SchoolBoundaryPartialState> | null): void {
        throw new Error("Method not implemented.");
    }
    private _mapType = MapSourceEnum.Esri
    vm: MapViewModel = new MapViewModel()

    get isInSelectionMode(): boolean {
        return this.vm.mapMode === MapModesEnum.selectPoints
    }
    get isInSelectShapesMode(): boolean {
        return this.vm.mapMode === MapModesEnum.selectShapes
    }
    get isInUploadMode(): boolean {
        return this.vm.mapMode === MapModesEnum.upload
    }
    //Other examples
    //May Whitney as example of one polygon for the whole school
    // https://www.openstreetmap.org/way/802203760

    //TEMP Hardcoded but should be fetched from our server
    //Add school boundary from open street map
    //https://www.openstreetmap.org/way/376368163
    boundaries = [
        //Main Boundary
        376368163,
        //East Building
        261953052,
        // //West Building
        631903189,
        849412750,
    ]
    center = [mockCenter.lat, mockCenter.lon]
    //
    //Saint George Elementary School
    // boundaries = [
    //     //Main Boundary
    //     32701520,
    // ]
    // center = MapProjectionViewModel.transform4326_to_900913([
    //     -87.5456853,
    //     41.7337453
    // ])

    //Core 
    // private _baseMapLayerSwitcher?: LayerSwitcher;
    // TODO make other internal implementation details private for easy public api
    tileLayer: any //?: BaseLayer;
    draw?: any // Draw
    pointer?: any // Pointer;
    modify?: any // Modify;
    translate?: any // Translate;

    //Vector Sources
    iconVectorSource: any // = new VectorSource({ wrapX: false });
    drawVectorSource: any // = new VectorSource({ wrapX: false });
    boundaryVectorSource: any // = new VectorSource({ wrapX: false });

    //Vector Layers
    iconVectorLayer?: any // VectorLayer<any>;
    drawVectorLayer?: any // VectorLayer<any>;
    boundaryVectorLayer?: any // VectorLayer<any>;
    uploadedImageLayer?: any // ImageLayer<Static>

    //TEMP WIP
    uploadedImagePixelProjection?: any // Projection;
    uploadedImageExtent?: any // Extent;
    private _uploadedImageSource?: any // Static;

    isDrawing: any // boolean = false
    hitDetection?: any // boolean | BaseVectorLayer<any, any>
    selectedFeatures: any // Feature[] = []
    selectedFeatureIds: (number | string)[] = []

    get selectionTypeOptions(): PolygonTypeEnum[] {
        return Object.values(PolygonTypeEnum)
    }
    constructor(
        private store: Store,
        private _openStreetService: OpenStreetService,
        private _appConfig: AppConfigService
    ) {
        super(store)
        // console.log('MapComponentService constructor');
    }
    typeSelect: any;

    //Public Api
    // #region

    handlePolygonTypeChange = (): void => {
        if (!this._map) return;
        this._removeDrawInteraction()
        this._addDrawInteraction()
    };
    addSchoolMapImage = (blobUrl: string, extent: Extent) => {
        //Remove any existing image layers - to prevent them from being continually added
        this._clearUploadedImageLayer()

        const features = this.drawVectorSource.getFeatures()
        features.forEach((feature, index) => {
            const geometry = feature.getGeometry()
            if (geometry instanceof Polygon && index === 0) {
                // This will only get the extent of a drawing that's finished, we need the extent from the drawing as it's being drawn
                const imageLayer = this._createImageLayer(extent, blobUrl)
                this._map?.addLayer(imageLayer)
            }
        })
    }
    clearMap = () => {
        this.drawVectorSource.clear()
        this._clearUploadedImageLayer()
    }
    saveSchoolMapImage = () => {
        const imagePolygon = this.drawVectorSource.getFeatures()[0]
        // console.log(`TODO implement save polygon for school map image placement
        // ${JSON.stringify(imagePolygon)}`)

    }
    destroy() {
        this._map?.dispose()
        this._map = undefined
        this.subs.forEach(sub => sub.unsubscribe())
    }
    // init = (
    // ) => {
    //     console.log(`Calling init in open layers map service`)
    //     this.subs = [
    //         this.validSchoolMapPageState$.subscribe(this._initBasedOnSchoolDto),
    //         this.selectSchoolPolygon$.subscribe(this._addPolygonAsRecommendedSchoolBoundary)
    //     ]
    // }
    //#endregion

    //INTERNAL IMPLEMENTATION
    //#region 
    /** For now get a whole list and readd to map. */
    // private _handleNewAlert = (alertRecords: Record<number, AlertDto[]>) => {
    private _handleNewAlert = (alertRecords: AlertDto[] | null): void => {
        if (!alertRecords) return
        // console.log('New alert received', alertRecords)
        //Reset the icons each time we get a new collections as the location may have changed
        this.iconVectorSource.clear()

        //Ignore alert if empty object or array
        const numberOfKeys = Object.keys(alertRecords).length
        if (numberOfKeys === 0) return

        const isArray = Array.isArray(alertRecords)
        if (isArray && alertRecords.length === 0) return

        //TODO Verify if same id of alert can have an update in lat and long to show people moving on the map, if not just add new ones and remove the clear call above
        if (numberOfKeys > 0) {
            alertRecords.forEach(a => this._addPoint(a))
        }
    }
    /** Here to add an icon for each alert based on it's location and type */
    private _addPoint({ latLong, userType }: AlertDto): void {
        const { lat, lon } = latLong ?? {}
        const iconStyle = new Style({
            image: new Icon({
                src: this._getSrcIconForAlertType(userType),
                anchor: [0.5, 1],
                scale: this._getScaleForIcon(userType),
                opacity: 1
            })
        });
        const pointFeature = new Feature({
            geometry: new Point([lat ?? -1, lon ?? -1])
        });
        pointFeature.setStyle(iconStyle);
        this.iconVectorSource.addFeature(pointFeature);
    }
    /** Just a temporary workaround until we get designs for users by type. */
    private _getScaleForIcon(type: MobileUserTypes): number {
        switch (type) {
            case MobileUserTypes.otherStaff:
                return .5
            case MobileUserTypes.student:
                return .5
            case MobileUserTypes.youngStudent:
                return 1
            default:
                return 1
        }
    }
    /** Users have icons by their user type, and potentially by their roles when that's integrated */
    private _getSrcIconForAlertType(type: MobileUserTypes): string {
        switch (type) {
            case MobileUserTypes.otherStaff:
                return `assets/admin.png`//TODO different icon
            case MobileUserTypes.student:
                return `assets/icon.png` //TODO different icon
            case MobileUserTypes.youngStudent:
                return `assets/registered.png`//TODO different icon
            default:
                return 'assets/icon.png'
        }
    }

    /** Here to provide different means of selection. Need to do multi select as well as multi drag, but need to discuss UX, in terms of keyboard showcuts used or UI control button selection or both. */
    private _addDrawInteraction = () => {
        // console.log(`_addDrawInteraction
        // shapeMode ${this.vm.shapeMode}
        // aspectRatio ${this.vm.aspectRatioVm?.aspectRatio}
        // `)
        const geometryFunction = OlSelectionTypeToCreate.selectionOlFunc[this.vm.shapeMode](this.vm.aspectRatioVm?.aspectRatio)
        this.draw = this._getDraw(geometryFunction)
        this._addDrawInteractionEventHandlers()
        this.pointer = this._getPointer()
        this._map?.addInteraction(this.draw);
    }
    private _addTranslateInteraction = () => {
        this.translate = new Translate({
            layers: [this.drawVectorLayer ?? new VectorLayer()],
        })
        this.translate.on('translateend', this._handleTranslateEnd);
        this._map?.addInteraction(this.translate);
    }
    /** TODO Set up a popup that shows which alerts can be messaged due to selection */
    private _handleUploadModeDrawEnd = async (points: Extent) => {
        //TODO Move to page state but for now if we uploaded an image, add that to the map
        const { href } = this.vm.fileVm ?? {}
        if (!href) return
        this.addSchoolMapImage(href, points)
    }
    private _handleMapClick = (event: any) => {
        // console.log(MapProjectionViewModel.transform900913_to_4326(event.coordinate))
        if (this.isInSelectShapesMode) {
            this._map?.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                this._updateSelectedFeatureStyle(feature)
            })
        }
    }
    private _addBoundariesToMap = () => {
        this.boundaryVectorLayer = new VectorLayer({
            source: this.boundaryVectorSource,
        })
        this._map?.addLayer(this.boundaryVectorLayer);
    }

    private _getMapSource = (): OSM | XYZ => {
        switch (this._mapType) {
            case MapSourceEnum.OpenStreetMap:
                return new OSM();
            case MapSourceEnum.Esri:
                return new XYZ({
                    attributions: 'Open Street Maps',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                        'World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                });
            case MapSourceEnum.Google:
                return new XYZ({
                    url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
                });
            default:
                return new OSM();
        }
    }

    private _getPixelProjection = (extent: Extent): Projection => {
        return new Projection({
            code: 'uploaded-image-pixel-projection',
            units: 'pixels',
            extent: extent
        })
    }
    private _createImageLayer = (extent: Extent, blobUrl: string): ImageLayer<Static> => {
        this.uploadedImagePixelProjection = this._getPixelProjection(extent)
        this._uploadedImageSource = new Static({
            url: blobUrl,
            projection: this.uploadedImagePixelProjection,
            imageExtent: extent
        })
        return new ImageLayer({
            source: this._uploadedImageSource,
            // TODO Idea add a slider for opacity so it can be user controlled
            opacity: 0.5
        })
    }
    /** This function toggles the draw vector layer into interaction mode */
    private _setModifyInteraction = (enable = true) => {
        if (enable) {
            this.modify = new Modify({
                source: this.boundaryVectorSource,
                hitDetection: this.hitDetection,
            });
            this._map?.addInteraction(this.modify);
        } else if (this.modify) {
            this._map?.removeInteraction(this.modify)
        }
    }

    /** @deprecated Now we get the polygon by lat long from our api TODO remove 
     *  These may be saved in the database as actual shape files or sourced from external source like OSM, TODO research ESRI */
    private _getBoundaries = () => {
        let promiseCollection: Promise<string>[] = [];

        this.boundaries.forEach((id) => {
            promiseCollection.push(this._openStreetService.getBoundary(id))
        })

        return Promise.all(promiseCollection)
            .then((responses: string[]) => this._handleBoundary(responses))
            .then(this._addBoundariesToMap)
    }
    private _addStyleToFeature = (features: Feature<Geometry>[]) => {
        features.forEach(g => g.setStyle(this._getFeatureStyle()))
    }
    /** Returns selected line style for feature */
    private _getSelectedFeatureStyle = () => {
        return new Style({
            zIndex: 101,
            stroke: new Stroke({
                color: 'rgba(246, 99, 79, 1.0)',
                width: 4,
            }),
            fill: this._getDefaultFill()
        });
    }
    private _getValidationFailFeatureStyle = () => {
        return new Style({
            stroke: new Stroke({
                color: 'rgba(255, 0, 0, 1)',
                width: 4,
            }),

        });
    }
    private _getFeatureStyle = () => {
        return new Style({
            zIndex: 100,
            stroke: new Stroke({
                color: 'rgba(246, 99, 79, 1.0)',
                width: 1,
            }),
            fill: this._getDefaultFill()
        })
    }
    private _getDefaultFill = () => {
        return new Fill({
            color: 'rgba(246, 99, 79, 0.3)',
        })
    }

    private async _handleBoundary(responses: string[]) {
        responses.forEach((r: string, i: number) => {
            const feature = new OSMXML().readFeatures(r, {
                featureProjection: this._map!.getView().getProjection(),
            });

            this._addStyleToFeature(feature)
            this.boundaryVectorSource.addFeatures(feature);
        })
    }
    //#endregion
    // private _handlePointerMove = (e: any) => {
    //     // console.log(`MOUSE MOVE ${e}`)
    //     if (this.isInUploadMode && this.isDrawing) {
    //         this.addSchoolMapImage(this._uploadFileService.blobUrl)
    //     }
    // }
    private _getPointer = () => {
        return new Pointer({
            handleDownEvent: (evt: MapBrowserEvent<any>) => {
                if (!this.isInSelectionMode && this.isInUploadMode) {
                    // get the pixel coordinates of the click event
                    const pixel = evt.pixel;

                    // perform a hit test to find the vector features at the clicked point
                    const features: any[] = [];
                    this._map?.forEachFeatureAtPixel(pixel, (feature) => {
                        features.push(feature);
                    });

                    // do something with the selected features
                    console.log('Selected features:', features);
                    //If after drawing and adding a shape we click on it, we should put it into edit state and transition out of the draw state, and into edit state, TODO user can toggle back in a menu
                    if (features.length > 0) {
                        this._map?.removeInteraction(this.draw as any);
                        this._setModifyInteraction()
                    }

                    // return false to prevent the default click behavior
                    return false;
                }
                return false
            },
        });
    }
    private _getMap = (selector: string): Map => {
        return new Map({
            controls: defaultControls().extend([new FullScreen()]),
            layers: [
                this.tileLayer ?? new TileLayer(),
                this.iconVectorLayer ?? new VectorLayer(),
                this.drawVectorLayer ?? new VectorLayer(),
            ],
            // interactions: [this.translate ?? new Translate()],
            target: selector,
            view: new View({
                center: MapProjectionViewModel.transform4326_to_900913(this.center),
                zoom: defaultOlZoom,
                maxZoom: maxZoom,
            }),
        });
    }
    //DRAW
    private _getDraw = (geometryFunction: GeometryFunction) => {
        return new Draw({
            source: this.drawVectorSource,
            type: "Circle" as Type,
            geometryFunction,
        });
    }
    private _addDrawInteractionEventHandlers = () => {
        this.draw?.on('change', (e: any) => {
            // console.log(`DRAW CHANGE Event`)
            // console.log(e)
        })
        this.draw?.on('drawstart', this._drawStartEventListener)
        this.draw?.on('drawend', this._drawEndEventListener)
    }
    private _removeDrawInteraction = () => {
        if (this.draw) {
            this._removeDrawInteractionEventHandlers()
            this._map?.removeInteraction(this.draw);
        }
    }
    private _removeDrawInteractionEventHandlers() {
        this.draw?.removeEventListener("drawstart", this._drawStartEventListener)
        this.draw?.removeEventListener("drawend", this._drawEndEventListener as ListenerFunction)
    }
    // even listeners
    private _drawStartEventListener = () => {
        // console.log(`DRAW START`)
        this.isDrawing = true
    }
    /** Get the feature object from the event, get the polygon geometry from the feature, get the array of points from the polygon */
    private _drawEndEventListener = (event: DrawEvent) => {
        let feature = event.feature;
        let polygon = <Polygon>feature.getGeometry();
        let points = polygon?.getCoordinates();
        const latLongPoints = points.map(coordinate => coordinate.map(point => MapProjectionViewModel.transform900913_to_4326(point)))
        // console.log(`DRAW END`)
        // console.log(latLongPoints)
        let extent = polygon?.getExtent()
        this.isDrawing = false

        //TODO If we're in selection mode, we never handle draw end in the sense that each new selection draws a new polygon
        if (this.isInSelectionMode) {
            this.drawVectorSource.clear()
        }
        if (this.isInUploadMode) {
            this._handleUploadModeDrawEnd(extent)
            this._addTranslateInteraction()
        }
    }
    //Map Mode section
    setDrawMode = () => {
        this._setModifyInteraction(false)
        this._removeDrawInteraction()
        this._addDrawInteraction()
        this._removeKeyboardInteractions()

    }
    setImageUploadMode = async () => {
        this._removeDrawInteraction()
        this._setModifyInteraction(false)
        this._addDrawInteraction()
        this._removeKeyboardInteractions()

    }
    setSelectShapes = () => {
        this._removeDrawInteraction()
        this._addKeyboardInteractions()
    }
    setEditMode = () => {
        this._removeDrawInteraction()
        this._setModifyInteraction()
        this._removeKeyboardInteractions()
    }
    setSelectionMode = () => {
        this.handlePolygonTypeChange()
        this._removeKeyboardInteractions()
    }
    private _clearUploadedImageLayer() {
        this._map?.getLayers().forEach((layer) => {
            if (layer instanceof ImageLayer) {
                this._map?.removeLayer(layer)
            }
        })
    }
    private _handleTranslateEnd = (event: TranslateEvent) => {
        // Do something when the translation has finished
        // // console.log('translateend', event)
        const feature = event.features.getArray()[0]
        let polygon = <Polygon>feature.getGeometry();
        this._handleUploadModeDrawEnd(polygon?.getExtent())
    }
    private _updateSelectedFeatureStyle = (feature: FeatureLike) => {
        // // console.log('feature')
        // // console.log(feature)
        const id = feature?.getId() ?? "-1"
        const f = feature as Feature
        if (this.selectedFeatureIds.includes(id)) {
            this.selectedFeatureIds = this.selectedFeatureIds.filter((featureId) => featureId !== id)
            this.selectedFeatures = this.selectedFeatures.filter((selectedFeature) => selectedFeature !== feature)
            f.setStyle(this._getFeatureStyle())
        } else {
            this.selectedFeatures.push(f)
            this.selectedFeatureIds.push(id)
            f.setStyle(this._getSelectedFeatureStyle())
        }
    }
    /** Move polygons via keyboard, todo set up different osm layer so we can only add those and save them, not move them, as that's not needed. */
    private _addKeyboardInteractions = () => {
        window.addEventListener('keydown', this._handleKeyEvent)
    }
    private _removeKeyboardInteractions = () => {
        window.removeEventListener('keydown', this._handleKeyEvent)
    }
    private _handleKeyEvent = (event: any) => {
        const keyCode = event.keyCode;
        const delta = [0, 0];
        switch (keyCode) {
            case 37: // Left arrow key
                delta[0] = -10;
                break;
            case 38: // Up arrow key
                delta[1] = 10;
                break;
            case 39: // Right arrow key
                delta[0] = 10;
                break;
            case 40: // Down arrow key
                delta[1] = -10;
                break;
            default:
                break;
        }
        if (delta[0] !== 0 || delta[1] !== 0) {
            // console.log(`Moving by ${delta[0]}, ${delta[1]}`)
            this.selectedFeatures.forEach((f: any) => {
                const geometry = f.getGeometry();
                geometry?.translate(delta[0], delta[1]);
            })
        }
    }
    /** @deprecated Use map config view model insteadAdds a polygon to the boundary vector source */
    // override _addPolygonAsRecommendedSchoolBoundary = (polygon: CoordinateCollection | null) => {
    //     if (!polygon) return
    //     if (this.boundaryVectorSource.getFeatures().length > 0) return
    //     console.log(`Adding polygon as recommended school boundary with ${polygon.length} points`)
    //     const transformedCoords = polygon.map(coord => MapProjectionViewModel.transform4326_to_900913(coord, true));

    //     const polygonFeature = new Feature({
    //         geometry: new Polygon([transformedCoords])
    //     });
    //     polygonFeature.setStyle(this._getFeatureStyle());
    //     this.boundaryVectorSource.addFeature(polygonFeature)
    //     this._addBoundariesToMap()
    // }
    override _initBasedOnSchoolDto = (authState: GlobalState | null) => {
        throw new Error("Method not implemented.");
    }
    // override _initBasedOnSchoolDto = (prereq: [AuthState, ISchoolMapPageState] | null) => {
    //     // If page state is null or we already have a map then we don't need to do anything
    //     const [authState, pageState] = prereq ?? [null, null]
    //     if (!authState || !pageState || this._map) return
    //     if (SchoolMapPageState.getLatLongOrNull(authState, pageState) === null) {
    //         return
    //     }
    //     const schoolId = UserDtoHelper.getDefaultSchoolId(authState.userDto?.schoolIds)
    //     if (!schoolId) return

    //     const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId]
    //     const { latLong } = schoolDto ?? {}
    //     const { lat, lon } = latLong ?? {}
    //     if (!lat || !lon) {
    //         console.error(`School ${schoolId} does not have a lat/long set`)
    //         return
    //     }
    //     console.log(`Updating center with ${lat}, ${lon}`)
    //     this.center = [lon, lat]
    //     //Once we have the center we can zoom to it
    //     //Set this up as menu option to potentially be integrated into UI if we want to pull from open streets as a potential source instead of drawing a shape
    //     // this._getBoundaries()
    //     this.tileLayer = new TileLayer({
    //         source: this._getMapSource(),
    //     });
    //     this.iconVectorLayer = new VectorLayer({
    //         source: this.iconVectorSource,
    //     });
    //     this.drawVectorLayer = new VectorLayer({
    //         source: this.drawVectorSource,
    //     });
    //     this.vm = pageState.vm
    //     this._map = this._getMap(this.vm.mapSelector)
    //     this.setUpMapMode(this.vm.mapMode)
    //     this._addBaseMapSelectionWidget()



    //     //Subscribe to updates from websocket
    //     if (this._appConfig?.config.USE_MOCK_SIGNALR_DATA) {
    //         // mockAlerts.forEach(alert => this._addPoint(alert))

    //         setInterval(() => {
    //             const randomIndex = Math.floor(Math.random() * mockAlerts.length);
    //             this.store.dispatch(receiveSignalrAlert(getExtendedAction(mockAlerts[randomIndex])))
    //         }, 5000)
    //     }
    //     this.visibleAlerts$.subscribe(this._handleNewAlert)
    //     this._map?.on("click", this._handleMapClick)
    // }
    private _addBaseMapSelectionWidget = () => {
        // Create the base map options
        // const osm = new LayerTile({
        //     title: 'OSM',
        //     type: 'base',
        //     visible: true,
        //     source: new SourceOSM()
        //   } as BaseLayerOptions);

        //   const watercolor = new LayerTile({
        //     title: 'Water color',
        //     type: 'base',
        //     visible: false,
        //     source: new SourceStamen({
        //       layer: 'watercolor'
        //     })
        //   } as BaseLayerOptions);

        //   const baseMaps = new LayerGroup({
        //     title: 'Base maps',
        //     layers: [osm, watercolor]
        //   } as GroupLayerOptions);

        // // Create the control element
        // // TODO npm i 
        // this._baseMapLayerSwitcher = new LayerSwitcher({
        //     reverse: true,
        //     groupSelectStyle: 'group'
        //     // tipLabel: 'Base Maps',
        //     // groupSelectStyle: 'children',
        //     // reverse: true,
        //     // groupCounter: true,
        //     // show_progress: true,
        //     // show_title: true,
        //     // activationMode: 'click',
        //     // startActive: true,
        //     // startVisible: true,
        //     // layers: baseMaps
        // });

        // // Add the control element to the map
        // this._map?.addControl(this._baseMapLayerSwitcher);
    }
}


