import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import { sendCheckStatus, setSelectedUserMobileId } from '@action/user/dashboard-page.action'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core'
import Graphic from '@arcgis/core/Graphic'
import { LabelSizeEnum } from '@component/shared/custom-ui-label/custom-ui-label.component'
import { Store } from '@ngrx/store'
import { AreaGraphicAttributes, StatusColorEnum } from '@view/area/area.view'
import { GraphicAttributes, GraphicAttributesTypeEnum } from '@view/area/graphic-attributes.model'
import {
  LocationGraphicAttrProps,
  LocationGraphicAttributes,
  LocationOfAlertResponseAttr
} from '@view/area/location.view'
import { AppComponentService } from '@service/app.component.service'
import { Observable, Subject, Subscription, tap } from 'rxjs'
import { MobileUserTypes, mobileUserTypesDisplayText } from '@model/user/user.model'
import { BaseMapPopup } from '../base-map-popup.model'
import { TimeUtils } from '@shared/time.utils'
import { CommonUtils } from '@shared/common.utils'
import { AreaStatusIcons, AreaStatusViewModel } from '@view/area/area-status.view'
import { ResponseTypeEnum } from '@model/message/predefined-message.model'

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupComponent extends BaseMapPopup {
  title = ''
  areaName = ''

  icon? = ''
  defaultAlertIcon = './assets/icons/response-id-message-default.svg'
  images: ResponseTypeEnum[] = []
  statusMessage = ''
  status = ''
  statusColor: StatusColorEnum = StatusColorEnum.unknown
  /** This is set to true when we click on a map item that has a popup */
  showAction = false
  attributes:
    | LocationGraphicAttributes
    | AreaGraphicAttributes
    | LocationOfAlertResponseAttr
    | null = null
  timeStampToDisplay: string | null = null
  lookup: Record<AreaStatusIcons, boolean> | null = null

  public reset(): void {
    this.title = ''
    this.areaName = ''
    this.icon = ''
    this.images = []
    this.lookup = null
    this.statusMessage = ''
    this.statusColor = StatusColorEnum.unknown
    this.showAction = false
    this.graphic = null
    this.attributes = null
    this.timeStampToDisplay = null
    this.cd.markForCheck()
  }
  public setContent = (attributes: GraphicAttributes) => {
    this.attributes = attributes
    if (attributes.kind === GraphicAttributesTypeEnum.area) {
      this._setAreaParams(attributes as AreaGraphicAttributes)
    } else if (attributes.kind === GraphicAttributesTypeEnum.location) {
      this._setLocationParams(attributes as LocationGraphicAttributes)
    } else if (
      attributes.kind === GraphicAttributesTypeEnum.attackAlertResponse ||
      attributes.kind === GraphicAttributesTypeEnum.medicalAlertResponse
    ) {
      this._setAlertParams(attributes as LocationOfAlertResponseAttr)
    }
    this.cd.markForCheck()
  }
  get userType(): string {
    if (this.isLocationPopup) {
      return mobileUserTypesDisplayText[(this.attributes as LocationGraphicAttributes).userType]
    } else {
      return ''
    }
  }
  /** We can show an icon if it's been set and the source is always a chat response, once the chat response is removed from the location graphic attributes no icon should be shown in the related popup, additionally we omit green poll responses*/
  get showIcon(): boolean {
    if (this.isLocationPopup) {
      return !!(this.attributes as LocationGraphicAttributes)?.icon
    }
    return false
  }

  labelVarient = LabelSizeEnum.small
  showLabel: boolean = false
  actionLabelText: string = 'Check Status'

  actionPayload: ExtendedAction<number> = getExtendedAction(0)

  areaId$ = new Subject<number>()
  disabled$ = new Observable<boolean>()

  get isAreaPopup(): boolean {
    return this.attributes?.kind == GraphicAttributesTypeEnum.area
  }
  get isLocationPopup(): boolean {
    return this.attributes?.kind == GraphicAttributesTypeEnum.location
  }
  get isAlertPopup(): boolean {
    return (
      this.attributes?.kind === GraphicAttributesTypeEnum.attackAlertResponse ||
      this.attributes?.kind === GraphicAttributesTypeEnum.medicalAlertResponse
    )
  }
  get alerterUserTypeImg(): string {
    if (this.isAlertPopup) {
      switch ((this.attributes as LocationGraphicAttributes).userType) {
        case MobileUserTypes.student:
          return 'open-book'
        case MobileUserTypes.otherStaff:
          return 'people'
        case MobileUserTypes.teacher:
          return 'apple-on-book'
        case MobileUserTypes.unknown:
          return 'guests'
      }
    }
    return ''
  }
  get isShownDueToClick(): boolean {
    return this.showAction && this.popupVisible
  }

  subs: Subscription[] = []

  constructor(
    cd: ChangeDetectorRef,
    elementRef: ElementRef,
    private store: Store,
    private appCompServ: AppComponentService
  ) {
    super(cd, elementRef)
    this.subs = [
      this.areaId$.subscribe(
        (value: number) =>
          (this.disabled$ = this.appCompServ
            .getDisabledAreaCheckStatus$(value)
            .pipe(tap((result) => result)))
      )
    ]
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => (!s.closed ? s.unsubscribe() : null))
  }

  setGraphic = (graphic: Graphic) => {
    this.graphic = graphic
  }
  hidePopupOnClickEvent = () => {
    this.graphic = null
    // Only hide the action when we intentionally close the popup by clicking on something else
    this.showAction = false
    this.showLabel = false
    this.hidePopup()
  }
  hidePopupOnZoomOrHoverEvent = () => {
    if (!this.showAction) {
      this.hidePopup()
    }
  }
  handleActionIconHover = (hovering: boolean) => {
    this.showLabel = hovering
  }
  setIdForAction = (id: number) => {
    this.areaId$.next(id)
    this.actionPayload = getExtendedAction(id)
  }
  actionHandler = () => {
    if (this.attributes?.kind === GraphicAttributesTypeEnum.area) {
      this.store.dispatch(sendCheckStatus(this.actionPayload))
    }
  }
  onNameClickInAlert = () => {
    //a separate property mobileUserId is needed otherwise the check for the value does not work
    let mobileUserId = (this.attributes as LocationGraphicAttributes)[
      LocationGraphicAttrProps.chatResponseDtoPayload
    ]?.mobileUserId

    if (mobileUserId) {
      this.store.dispatch(
        setSelectedUserMobileId(
          getExtendedAction({
            mobileUserId: mobileUserId,
            name: this.title
          })
        )
      )
    }
  }
  private _setAreaParams = (attributes: AreaGraphicAttributes) => {
    // console.log(`Setting popup params`)
    // console.log(attributes)
    this.title = attributes?.type
    this.areaName = CommonUtils.getSmartAreaName(attributes?.type, attributes?.name)
    this.statusMessage = attributes?.statusVm?.longMessage ?? attributes?.statusVm?.message
    this.statusColor = attributes?.statusVm?.currentStatusColor
    this.icon = undefined
    this.images = attributes?.statusVm?.images ?? null
    this.lookup = AreaStatusViewModel.getIconLookupFromImages(this.images)
  }
  private _setLocationParams = (attributes: LocationGraphicAttributes) => {
    //Reset from area in case it's present
    this.areaName = ''
    // console.log(`Setting popup params`)
    // console.log(attributes)
    this.title = attributes?.fullName
    this.statusMessage = attributes?.statusVm?.message
    this.statusColor = attributes?.statusVm?.status
    this.icon = attributes?.icon
  }
  private _setAlertParams = (attributes: LocationOfAlertResponseAttr) => {
    this.statusMessage = attributes?.response?.text
    this.title = attributes?.fullName
    this.icon = decodeURIComponent(attributes?.response?.browserImage ?? '')
    this.timeStampToDisplay = attributes[LocationGraphicAttrProps.chatResponseDtoPayload]
      ? TimeUtils.getTimeSinceStringFromDate(
          new Date(attributes[LocationGraphicAttrProps.chatResponseDtoPayload].timestamp)
        )
      : ''
  }
}
