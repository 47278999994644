import { LatLon } from '@model/location.model'
import {
  ChatResponseDtoPayload,
  GeospatialQueryPayloadHistoric,
  GeospatialQueryPayloadRealtime
} from '@model/message.model'
import { PatchSchoolAreaDto, PostSchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDto } from '@model/school/school.model'
import { ISchoolAreaPatchProps } from '@model/school/sub-area.model'
import { createAction, props } from '@ngrx/store'
import { PolygonTypeEnum } from '@service/map/map.component.service.model'
import { FileViewModel, UploadedImageProps } from '@view/browser/file.view'
import { BaseMapToggleItemViewModel } from '@view/map/base-layer/base-layer-toggle.view'
import { MapApiEnum, MapModesEnum } from '@view/map/map-enums.view'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import {
  ISchoolMapConfigStepViewModel,
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ExtendedAction } from './extended-ngrx-action'
import { MapConfigMapViewStepViewModel } from '@view/pages/school-map-config-page/map-config-map-view-step.view'

// TODO Rename this to be map config actions as different from dash page actions

export enum SchoolMapConfigPageActions {
  setMapApi = '[Map Config Page Actions] Set Map API',
  setMapMode = '[Map Config Page Actions] Set Map Mode',
  setShapeType = '[Map Config Page Actions] Set Shape Type',
  getSchoolPolygonByLoc = '[Map Config Page Actions] Get Osm School Polygon By Location',

  //File Upload
  setFileUploadData = '[Map Config Page Actions] Set File Upload Data',
  setImageAspectRatio = '[Map Config Page Actions] Set Image Aspect Ratio',

  //DASHBOARD SIDE NAV
  toggleShowMap = '[Map Config Page Actions] Toggle Show Map',
  //PATCH
  patchSchoolDto = '[Map Config Page Actions] Patch School Dto',
  //AREA
  addNewSchoolArea = '[Map Config Page Actions] Add New School Area',
  patchSchoolArea = '[Map Config Page Actions] Update School Area',
  selectAreaById = '[Map Config Page Actions] Select Area By Id',
  //Map View
  saveMapView = `[Map Config Page Actions] Save Current Map View`,
  completeMapConfig = `[Map Config Page Actions] Handle Complete Map Click`,
  setMapConfigBaseLayer = `[Map Config Page Actions] Update selected base map layer`,
  updateSubAreaRelatedDataDebounced = `[Map Config Page Actions] Update selected area related data debounced`,
  //VALIDATIONS
  patchAreaPendingDto = `[Map Config Page Actions] Update new area patch payload in validation mode`,
  setMapConfigErrorReason = `[Map Config Page Actions] Update Invalid Map Config Error Code`,
  //MAP CONFIG STEP NAVIGATION
  navigateToStepInMapConfig = `[Map Config Page Actions] Move to Step in Map Config`,
  // TOOLBAR
  handleMapConfigToolbarClick = `[Map Config Page Actions] Handle Toolbar Item Click`,
  handleMapConfigToolbarStateSync = `[Map Config Page Actions] Handle Toolbar to Map Api Sketch View Model Sync`,
  replaySchoolDtoForMapConfig = '[Map Config Page Actions] Replay school dto for navigate to map config',
  // DELETE AREA
  showConfirmAreaDeleteModal = '[Map Config Page Actions] Display modal to confirm area deletion',
  deleteSchoolArea = '[Map Config Page Actions] Delete School Area By Id',
  // DELETE BOUNDARY
  deleteSchoolPerimeterBoundary = '[Map Config Page Actions] Delete School Perimeter Boundary',
  showDeleteSchoolPerimeterBoundaryConfirmation = '[Map Config Page Actions] Delete School Perimeter Boundary Confirmation UI',
  handleZoomInClick = '[Map Config Page Actions] Handle Zoom In Click',
  handleZoomOutClick = '[Map Config Page Actions] Handle Zoom Out Click',
  handleHomeClick = '[Map Config Page Actions] Handle Home Click',
  //SLIDERS
  engageMapConfigSlider = `[Map Config Page Actions] Engage Slider`,
  // proximity
  setProximitySliderValue = '[Map Config Page Actions] Set Slider Value',
  updateSchoolProximityBoundary = `[Map Config Page Actions] update School Proximity Boundary`,
  // rotation
  updateRotationSlider = '[Map Config Page Actions] Update Rotation Slider',
  setRotationSliderValue = '[Map Config Page Actions] Set Rotation Slider Value',
  updateRotationSliderFromArcGis = '[Map Config Page Actions] Update Rotation Slider From ArcGis'
}
//LOCAL DEV EXPERIMENTATION
export const setMapApi = createAction(
  SchoolMapConfigPageActions.setMapApi,
  props<ExtendedAction<MapApiEnum>>()
)
export const setMapMode = createAction(
  SchoolMapConfigPageActions.setMapMode,
  props<ExtendedAction<MapModesEnum>>()
)
export const setPolygonTypeEnum = createAction(
  SchoolMapConfigPageActions.setShapeType,
  props<ExtendedAction<PolygonTypeEnum>>()
)
export const getSchoolPolygonByLoc = createAction(
  SchoolMapConfigPageActions.getSchoolPolygonByLoc,
  props<ExtendedAction<LatLon>>()
)

//DASHBOARD SIDE NAV
export const toggleShowMap = createAction(SchoolMapConfigPageActions.toggleShowMap)

//FILE UPLOAD
export const setFileUploadData = createAction(
  SchoolMapConfigPageActions.setFileUploadData,
  props<ExtendedAction<FileViewModel>>()
)
export const setMapPageUploadImageAspectRatio = createAction(
  SchoolMapConfigPageActions.setImageAspectRatio,
  props<ExtendedAction<UploadedImageProps>>()
)

//PATCH
export const patchSchoolDto = createAction(
  SchoolMapConfigPageActions.patchSchoolDto,
  props<ExtendedAction<Partial<SchoolDto>>>()
)

//AREA
//Select
export const selectAreaById = createAction(
  SchoolMapConfigPageActions.selectAreaById,
  props<ExtendedAction<number | null>>()
)
//New
export const postNewSchoolArea = createAction(
  SchoolMapConfigPageActions.addNewSchoolArea,
  props<ExtendedAction<PostSchoolAreaDto>>()
)
//Update
export const patchSchoolArea = createAction(
  SchoolMapConfigPageActions.patchSchoolArea,
  props<ExtendedAction<PatchSchoolAreaDto>>()
)
//School Map Config Actions
export const replaySchoolDtoForMapConfig = createAction(
  SchoolMapConfigPageActions.replaySchoolDtoForMapConfig,
  props<ExtendedAction<GetSchoolDto>>()
)
export const setMapConfigBaseLayer = createAction(
  SchoolMapConfigPageActions.setMapConfigBaseLayer,
  props<ExtendedAction<BaseMapToggleItemViewModel>>()
)
export const updateSubAreaRelatedDataDebounced = createAction(
  SchoolMapConfigPageActions.updateSubAreaRelatedDataDebounced,
  props<ExtendedAction<PatchSchoolAreaDto>>()
)
//Slider
export const updateRotationSliderFromArcGis = createAction(
  SchoolMapConfigPageActions.updateRotationSliderFromArcGis,
  props<ExtendedAction<number>>()
)
export const updateRotationSlider = createAction(
  SchoolMapConfigPageActions.updateRotationSlider,
  props<ExtendedAction<number>>()
)
export const setRotationSliderValue = createAction(
  SchoolMapConfigPageActions.setRotationSliderValue,
  props<ExtendedAction<number>>()
)
export const updateSchoolProximityBoundary = createAction(
  SchoolMapConfigPageActions.updateSchoolProximityBoundary,
  props<ExtendedAction<number>>()
)
export const setProximitySliderValue = createAction(
  SchoolMapConfigPageActions.setProximitySliderValue,
  props<ExtendedAction<number>>()
)
export const engageMapConfigSlider = createAction(
  SchoolMapConfigPageActions.engageMapConfigSlider,
  props<ExtendedAction<number>>()
)
// Map Config Validation
export const patchAreaPendingDto = createAction(
  SchoolMapConfigPageActions.patchAreaPendingDto,
  props<ExtendedAction<ISchoolAreaPatchProps>>()
)
export const setMapConfigErrorReason = createAction(
  SchoolMapConfigPageActions.setMapConfigErrorReason,
  props<ExtendedAction<MapConfigValidationErrors | null>>()
)
export const completeMapConfig = createAction(
  SchoolMapConfigPageActions.completeMapConfig
)

// Map Config Navigation State
export const navigateToStepInMapConfig = createAction(
  SchoolMapConfigPageActions.navigateToStepInMapConfig,
  props<ExtendedAction<MapConfigSteps>>()
)
// TOOLBAR
export const handleMapConfigToolbarClick = createAction(
  SchoolMapConfigPageActions.handleMapConfigToolbarClick,
  props<ExtendedAction<MapConfigToolbarOptions>>()
)
export const handleMapConfigToolbarStateSync = createAction(
  SchoolMapConfigPageActions.handleMapConfigToolbarStateSync,
  props<ExtendedAction<Record<MapConfigToolbarOptions, boolean>>>()
)
//Delete Area
export const deleteSchoolArea = createAction(
  SchoolMapConfigPageActions.deleteSchoolArea,
  props<ExtendedAction<number>>()
)
export const showConfirmAreaDeleteModal = createAction(
  SchoolMapConfigPageActions.showConfirmAreaDeleteModal,
  props<ExtendedAction<number>>()
)
//Delete Perimeter Boundary
// For now we're removing ability to delete a boundary
// export const deleteSchoolPerimeterBoundary = createAction(
//   SchoolMapConfigPageActions.deleteSchoolPerimeterBoundary
// )
export const showDeleteSchoolPerimeterBoundaryConfirmation = createAction(
  SchoolMapConfigPageActions.showDeleteSchoolPerimeterBoundaryConfirmation
)
//Map View Step 1
export const saveMapView = createAction(
  SchoolMapConfigPageActions.saveMapView,
  props<ExtendedAction<MapConfigMapViewStepViewModel>>()
)
//Map Control Icons
export const handleZoomInClick = createAction(SchoolMapConfigPageActions.handleZoomInClick)
export const handleZoomOutClick = createAction(SchoolMapConfigPageActions.handleZoomOutClick)
export const handleHomeClick = createAction(SchoolMapConfigPageActions.handleHomeClick)
