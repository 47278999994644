import { PilotUserSignUpProps } from '@model/user/pilot-user-sign-up.model'
import {
  emailDisplayString,
  emailPlaceholder,
  firstNameDisplayString,
  lastNameDisplayString,
  phoneDisplayString
} from '@shared/constants'
import { MatIconEnum } from '@view/shared/icon.view'

/** The welcome page can be configured to allow creation of account by the user, or interaction with a submission form, alternatively it's hidden. */
export enum WelcomePageFeature {
  createAccount = 'createAccount',
  signUp = 'signUp',
  disabled = 'disabled'
}
export const WelcomePageCtaBtnDisplayText: Record<WelcomePageFeature, string> = {
  createAccount: 'Create Account',
  signUp: 'Sign Up for Pilot',
  /** When disable this cta button is hidden so no display text is needed unless the requirements change. */
  disabled: ''
}
export namespace PilotUserSignUpFormVm {
  export const LabelTextForUserProp: Record<PilotUserSignUpProps, string> = {
    [PilotUserSignUpProps.firstName]: firstNameDisplayString,
    [PilotUserSignUpProps.lastName]: lastNameDisplayString,
    [PilotUserSignUpProps.email]: emailDisplayString,
    [PilotUserSignUpProps.phone]: phoneDisplayString,
    [PilotUserSignUpProps.school]: 'School',
    [PilotUserSignUpProps.position]: 'Position',
    [PilotUserSignUpProps.city]: 'City',
    [PilotUserSignUpProps.state]: 'State',
    [PilotUserSignUpProps.information]: 'Tell us about your school (optional)'
  }
  export const PlaceholderTextByUserProp: Record<PilotUserSignUpProps, string> = {
    [PilotUserSignUpProps.firstName]: firstNameDisplayString,
    [PilotUserSignUpProps.lastName]: lastNameDisplayString,
    [PilotUserSignUpProps.email]: emailPlaceholder,
    [PilotUserSignUpProps.phone]: phoneDisplayString,
    [PilotUserSignUpProps.school]: 'Your School Name',
    [PilotUserSignUpProps.position]: 'Your Position',
    [PilotUserSignUpProps.city]: 'Anytown',
    [PilotUserSignUpProps.state]: 'Anystate',
    [PilotUserSignUpProps.information]: 'Additional information about your school'
  }
  export const MatIconByUserProp: Partial<Record<PilotUserSignUpProps, MatIconEnum | ''>> = {
    [PilotUserSignUpProps.email]: MatIconEnum.mail,
    [PilotUserSignUpProps.phone]: MatIconEnum.smartphone
  }
  export const UserCreateUpdateFormPrefix: Partial<Record<PilotUserSignUpProps, string>> = {
    [PilotUserSignUpProps.phone]: '+1'
  }
}
export class PilotUserSignUpModalViewModel {
  // FORM MODAL
  static readonly title = 'Sign up for the upcoming OWL for Schools pilot!'
  static readonly subtext = `We'll let you know when the pilot opens.`
  static readonly subtextSchoolAvailability = `OWL for Schools is available only to U.S.A K-12 Schools.`
  static confirmBtnText = 'Sign Up'
  static cancelBtnText = 'Cancel'
  // SIGN UP SUCCESS CONFIRMATION MODAL
  static readonly signUpSuccessTitle = 'Thank you for signing up for OWL for Schools!'
  static readonly signUpSuccessSubtext =
    'Please check for an email acknowledging your enrollment, making sure to look in the spam folder as well.'
  static readonly signUpSuccessSubtext2 =
    "When the pilot is ready to join, we'll email you an invitation with instructions for joining."
  static readonly closeBtnText = 'Close'
}
