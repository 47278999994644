import { ExtendedAction } from '@action/extended-ngrx-action'
import { CoordinateCollection } from '@model/location.model'
import { createAction, props } from '@ngrx/store'

export enum SchoolPolygonApiAction {
    getPolygonSuccess = '[SchoolPolygonApiAction] Get Polygon Success',
    getPolygonError = '[SchoolPolygonApiAction] Get Polygon Error',
}
//Search for School
export const getPolygonSuccess = createAction(
    SchoolPolygonApiAction.getPolygonSuccess,
    props<ExtendedAction<CoordinateCollection | null>>()
)
export const getPolygonError = createAction(
    SchoolPolygonApiAction.getPolygonError,
    props<ExtendedAction<any>>()
)