import Graphic from '@arcgis/core/Graphic'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcgisAlertGraphicHandler } from './arcgis-alert-symbol.view'
import { IThreatItemViewModel } from '@view/area/threat-area.view'
import { clickPulsingAlert } from '@action/user/dashboard-page.action'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { TimeUtils } from '@shared/time.utils'

export namespace ArcGisAlertHandler {
  /** Handles updating the symbol for the alert
   * @return boolean to indicate if action was taken
   */
  export const updateMedicalAlertGraphic = (
    context: ArcGisMapService,
    g: Graphic,
    opaque: boolean
  ): boolean => {
    return ArcgisAlertGraphicHandler.updateAlertSymbolsConditionally(context, g, opaque)
  }

  export const findGraphicByLogicalId = (
    layer: __esri.GraphicsLayer | undefined,
    logicalId: string
  ): Graphic | undefined => {
    return layer?.graphics.find((g) => g.attributes.id === logicalId)
  }

  /** Receives an explicit list of ids
   * @param removedThreatIndicatorIds  string[]
   * then processes any non threat indicator alerts that are aging out from non opaqued to opaqued
   */
  export const updateDimmedThreatMapGraphics = (
    context: ArcGisMapService,
    removedThreatIndicatorIds: string[]
  ): void => {
    removedThreatIndicatorIds.forEach((id) => {
      const alertGraphic = findGraphicByLogicalId(context._attackAlertGraphicsLayer, id)
      const pulsignAlertGraphic = findGraphicByLogicalId(context._pulsingAlertGraphicsLayer, id)
      dimAlertGraphics(context, alertGraphic, pulsignAlertGraphic)
    })
    //Every event loop we have to go over all alerts types and dim any that weren't taken care of the threat indicator logic above
    context._attackAlertGraphicsLayer?.graphics.forEach((g) =>
      checkGraphicForAge(context, g, true, false)
    )
    // Don't dim medical alerts
    // context._medialAlertGraphicsLayer?.graphics.forEach((g) =>
    //   checkGraphicForAge(context, g, false, true)
    // )
  }
  export const checkGraphicForAge = (
    context: ArcGisMapService,
    g: Graphic,
    isAttackAlert: boolean,
    isMedicalAlert: boolean
  ): void => {
    if (!context.threatModel) {
      console.warn(
        `context.threatModel not defined in arc gis map service, it's required for the alert and threat indicator event loop.`
      )
      return
    }
    // console.log(`Checking g.attributes for age`, g.attributes)
    const timestampString = g.attributes.chatResponseDtoPayload.timestamp
    const timestamp = TimeUtils.getDateFromString(timestampString)
    if (!timestamp) {
      console.warn(`Got invalid timestamp from alert graphic`, g.attributes)
      return
    }
    const ageExceeded = context.threatModel.timestampExceedsMaxAge(timestamp)
    if (!ageExceeded) {
      return
    }
    //Age exceed logic
    if (isAttackAlert) {
      const pulsingAlert = findGraphicByLogicalId(
        context._pulsingAlertGraphicsLayer,
        g.attributes.id
      )
      dimAlertGraphics(context, g, pulsingAlert)
    }
    //// This is commented out in case medical alerts need to be dimmed in the future
    // else if (isMedicalAlert) {
    //   context._medialAlertGraphicsLayer?.remove(g)
    //   g.symbol = ArcGisSymbolFactory.opaqueMedicalAlertSymbol.clone()
    //   context._dimmedAlertGraphicsLayer?.add(g)
    // }
  }
  /**
   * Moves an alert graphic from the alert layer to the dimmed alert layer, and silences any pulsing graphics
   * @param context  ArcGisMapService
   * @param vm  IThreatItemViewModel
   */
  export const dimAlert = (context: ArcGisMapService, vm: IThreatItemViewModel): void => {
    // Remove the alert from the alert layer
    let alertGraphic: Graphic | undefined = findGraphicByLogicalId(
      context._attackAlertGraphicsLayer,
      vm.logicalId
    )
    let pulsingGraphic: Graphic | undefined = findGraphicByLogicalId(
      context._pulsingAlertGraphicsLayer,
      vm.logicalId
    )
    dimAlertGraphics(context, alertGraphic, pulsingGraphic)
  }
  export const dimAlertGraphics = (
    context: ArcGisMapService,
    alertGraphic: __esri.Graphic | undefined,
    pulsignAlertGraphic: __esri.Graphic | undefined
  ): void => {
    //and add it to the dimmed alert layer
    if (pulsignAlertGraphic) {
      context._pulsingAlertGraphicsLayer?.remove(pulsignAlertGraphic)
      context.store.dispatch(clickPulsingAlert(getExtendedAction(pulsignAlertGraphic.attributes)))
    }
    if (alertGraphic) {
      //Dimming an alert means that we also silence the pulsing indicator and to persist that it needs to be saved in local storage
      //Remove it from the main alert layer
      context._attackAlertGraphicsLayer?.remove(alertGraphic)
      //Update to opaque symbol
      alertGraphic.symbol = ArcGisSymbolFactory.opaqueAlertSymbol.clone()
      context._dimmedAlertGraphicsLayer?.add(alertGraphic)
    }
  }
}
