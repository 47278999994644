import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { SchoolValidationSearchRes } from '@model/school-validation.model'
import { GetSchoolDto } from '@model/school/school.model'
import { createAction, props } from '@ngrx/store'

export enum SchoolApiAction {
  searchForSchoolSuccess = '[SchoolApiAction] Search For School Success',
  searchForSchoolError = '[SchoolApiAction] Search For School Error',

  saveSchoolForUserSuccess = '[SchoolApiAction] Select School for User Success',
  SelectSchoolForUserError = '[SchoolApiAction] Select School for User Error',

  getSchoolByIdSuccess = `[SchoolApiAction] Get School By Id Success`,
  getSchoolByIdError = `[SchoolApiAction] Get School By Id Error`
}
//Search for School
export const searchForSchoolSuccess = createAction(
  SchoolApiAction.searchForSchoolSuccess,
  props<ExtendedAction<SchoolValidationSearchRes>>()
)
export const searchForSchoolError = createAction(
  SchoolApiAction.searchForSchoolError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//Select School
export const saveSchoolForUserSuccess = createAction(
  SchoolApiAction.saveSchoolForUserSuccess,
  props<ExtendedAction<GetSchoolDto>>()
)
export const saveSchoolForUserError = createAction(
  SchoolApiAction.SelectSchoolForUserError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//Get by id
export const getSchoolByIdSuccess = createAction(
  SchoolApiAction.getSchoolByIdSuccess,
  props<ExtendedAction<GetSchoolDto>>()
)
export const getSchoolByIdError = createAction(
  SchoolApiAction.getSchoolByIdError,
  props<ExtendedAction<HttpErrorResponse>>()
)
