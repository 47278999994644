import { BaseMapOptions } from '@service/map/arcgis/layers/arcgis-baselayer-styles.view'

export enum BaseLayerToggleOptions {
  streetNavigational = 'street-nav',
  light = 'light',
  dark = 'dark',
  hybrid = 'hybrid'
}
export const BaseLayerToggleDisplayNames: Record<BaseLayerToggleOptions, string> = {
  [BaseLayerToggleOptions.streetNavigational]: 'Street Navigational',
  [BaseLayerToggleOptions.light]: 'Light',
  [BaseLayerToggleOptions.dark]: 'Dark',
  [BaseLayerToggleOptions.hybrid]: 'Hybrid'
}
export const BaseLayerToggleArcGisOptions: Record<BaseLayerToggleOptions, BaseMapOptions> = {
  [BaseLayerToggleOptions.streetNavigational]: BaseMapOptions.StreetsNavigationVector,
  [BaseLayerToggleOptions.light]: BaseMapOptions.GrayVector,
  [BaseLayerToggleOptions.dark]: BaseMapOptions.DarkGrayVector,
  [BaseLayerToggleOptions.hybrid]: BaseMapOptions.Hybrid
}
const dir = `assets/icons/base-layers/`
const getFullDir = (s: string, hover: boolean, active: boolean): string => {
  let conditional = ''
  if (hover) {
    conditional = '_hover'
  } else if (active) {
    conditional = '_active'
  }
  return `${dir}${s}-base-layer-icon${conditional}.svg`
}
export type BaseLayerImageSrcLookup = Record<BaseLayerToggleOptions, string>
export const BaseLayerToggleImageSrc = Object.values(BaseLayerToggleOptions).reduce(
  (accum, curr: BaseLayerToggleOptions) => {
    return {
      ...accum,
      [curr]: getFullDir(curr, false, false)
    }
  },
  {} as BaseLayerImageSrcLookup
)
export const BaseLayerToggleImageSrcHover = Object.values(BaseLayerToggleOptions).reduce(
  (accum, curr: BaseLayerToggleOptions) => {
    return {
      ...accum,
      [curr]: getFullDir(curr, true, false)
    }
  },
  {} as BaseLayerImageSrcLookup
)
export const BaseLayerToggleImageSrcActive = Object.values(BaseLayerToggleOptions).reduce(
  (accum, curr: BaseLayerToggleOptions) => {
    return {
      ...accum,
      [curr]: getFullDir(curr, false, true)
    }
  },
  {} as BaseLayerImageSrcLookup
)

export interface BaseMapToggleItemViewModel {
  option: BaseLayerToggleOptions
  displayText: string
  // TODO Add mapBoxLayer when implementing back up mapping service
  arcGisLayer: BaseMapOptions
  imageSrc: string
  imageSrcActive: string
  imageSrcHover: string
}
export const baseLayerToggleVms: BaseMapToggleItemViewModel[] = Object.values(
  BaseLayerToggleOptions
).map(
  (o: BaseLayerToggleOptions): BaseMapToggleItemViewModel => ({
    option: o,
    displayText: BaseLayerToggleDisplayNames[o],
    arcGisLayer: BaseLayerToggleArcGisOptions[o],
    imageSrc: BaseLayerToggleImageSrc[o],
    imageSrcHover: BaseLayerToggleImageSrcHover[o],
    imageSrcActive: BaseLayerToggleImageSrcActive[o]
  })
)
