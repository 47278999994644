import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ModalViewModel } from '@view/matieral/modal.view'
import { BaseModalComponent } from './base-modal.component'
import { BtnColorVariants } from '../button/button.component.view'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent extends BaseModalComponent<ModalComponent> {
  buttonTypeForConfirm = BtnColorVariants.primary
  buttonTypeForCancel = BtnColorVariants.secondary

  confirmText = 'CONFIRM'
  cancelText = 'CANCEL'

  get hasConfigContent(): boolean {
    return !!this.vm.CONCLUDE_EVENT_MODAL_CONTENT
  }

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public vm: ModalViewModel
  ) {
    super(dialogRef, vm)
  }
}
