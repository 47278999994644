import { createSelector } from '@ngrx/store'
import {
  selectInviteSummaryDtoForCurrentTab,
  selectRosterValidationSummaryDtoIfIsPreview
} from './roster-view.selector'

/** Only show the summary card where there is either invite summary or validation summery. */
export const selectSummaryCardIsVisible = createSelector(
  selectInviteSummaryDtoForCurrentTab,
  selectRosterValidationSummaryDtoIfIsPreview,
  (summaryDto, validationSummaryDto) => !!summaryDto || !!validationSummaryDto
)
