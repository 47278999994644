import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { GetResponseGroupDto } from '@model/message/response-group.model'
import { createAction, props } from '@ngrx/store'

export enum ResponseGroupApiActions {
  getResponseGroupsSuccess = `[Response Groups API] Get Response Groups by School Id Success`,
  getResponseGroupsFail = `[Response Groups API] Get Response Groups by School Id Fail`
}

export const getResponseGroupsSuccess = createAction(
  ResponseGroupApiActions.getResponseGroupsSuccess,
  props<ExtendedAction<GetResponseGroupDto[]>>()
)
export const getResponseGroupsFail = createAction(
  ResponseGroupApiActions.getResponseGroupsFail,
  props<ExtendedAction<HttpErrorResponse>>()
)
