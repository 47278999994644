import { createSelector } from '@ngrx/store'
import { RosterTopSectionViewModel } from '@view/pages/roster-page/roster-top-section.view'
import { selectSelectedRosterTab } from './roster-page.selector'
import { selectIsPreviewMod } from './roster-view.selector'

export const selectRosterTopSectionTitle = createSelector(
  selectIsPreviewMod,
  selectSelectedRosterTab,
  RosterTopSectionViewModel.getTitle
)
export const selectRosterTopSectionSubTitle = createSelector(
  selectIsPreviewMod,
  selectSelectedRosterTab,
  RosterTopSectionViewModel.getSubTitle
)
