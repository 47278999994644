import { getSchoolById, getUsersForSchool } from '@action/auth.action'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { getPredefinedGroupsForSchool } from '@action/messages/predefined-messages-page.actions'
import { getResponseGroupsForSchool } from '@action/messages/response-group-page.actions'
import { Injectable } from '@angular/core'
import { GetUserDto } from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { RouterService } from '@service/core/router.service'
import { UserDtoHelper } from './dto-helpers/user-model.helper'
import { connectBySchoolIds } from '@action/signalr.action'
import { GlobalState, GlobalStateProps } from '@state/auth.state'
import { LoggingService } from '@service/logging/logging.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'

@Injectable({
  providedIn: 'root'
})
export class AppCoreDataService {
  constructor(private store: Store, private routeService: RouterService, private loggingService: LoggingService) { }

  /** If user dto is missing this means the user must pick a school as they don't have a school id assigned.
   * If there's no passed school dto this means we need to fetch all school related data by the user's default school id. Planned, persist in session storage if more than one school id and enable toggle in UI to change default school id. For now just using first always with no ability to change it.
   * To centralize the logic for fetching all school related data by the user's default school id.
   * Specifically, when we don't yet know if we're redirecting to the roster, the dash or the map config page.
   * This speeds up page load no matter which page we end up on.
   */
  handleCoreAppDataConcerns = (getUserDto: GetUserDto): void => {
    const { schoolIds } = getUserDto ?? {}
    if (!UserDtoHelper.schoolIdsExist(schoolIds)) {
      this.routeService.navigateToSchoolValidation()
      return
    }
    const schoolIdToUse = UserDtoHelper.getDefaultSchoolId(schoolIds)
    if (!schoolIdToUse) {
      // Dispatch redirect to school picker
      return
    }
    this.store.dispatch(getSchoolById(getExtendedAction(schoolIdToUse.id)))
    this.store.dispatch(getUsersForSchool(getExtendedAction(schoolIdToUse)))
    this.store.dispatch(getPredefinedGroupsForSchool(getExtendedAction(schoolIdToUse.id)))
    this.store.dispatch(getResponseGroupsForSchool(getExtendedAction(schoolIdToUse.id)))
    // For now just default to the first school the user is assocaited to and improve to handle user selection or view of all related data when designed and strategized
    this.store.dispatch(connectBySchoolIds(getExtendedAction(schoolIds.map((s) => s.logicalId))))
  }

  /** For now our global redirect logic requires users and the school dto, once communication data is lifted to global state, include here as well. */
  isMissingCoreData = (s: GlobalState): boolean => {
    const selectedSchoolId = s[GlobalStateProps.selectedSchoolId]
    const usersApi = s[GlobalStateProps.usersForSchoolLookup][selectedSchoolId.id]
    const schoolApi = s[GlobalStateProps.userSchoolLookup][selectedSchoolId.id]
    const predefinedGroupsApiState = s[GlobalStateProps.predefinedGroupsApiState]
    const responseGroupsApiState = s[GlobalStateProps.responseGroupsApiState]
    const commsLoaded = predefinedGroupsApiState.hasLoaded && responseGroupsApiState.hasLoaded
    if (usersApi && schoolApi && commsLoaded) {
      this.log(`Users api, school api, and comms apis are loaded`)
      return false
    }
    this.log(`Missing core data, triggering data fetch.`)
    return true
  }

  log = (m: string): void => {
    this.loggingService.logLocally(m, OwlLoggingArea.ROUTING, OwlLogLevels.VERBOSE)
  }
}
