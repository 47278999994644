import { RosterValidationDto, RosterValidationSummaryDto } from './roster-page.view'

export class RosterPageSummaryCardViewModel {
  static getValidationSummaryDtoIfIsPreview(
    previewData: RosterValidationDto | null,
    isPreview: boolean
  ): RosterValidationSummaryDto | null {
    if (isPreview) {
      return previewData?.summary ?? null
    }
    return null
  }
}
