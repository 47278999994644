import { ExtendedAction } from '@action/extended-ngrx-action'
import { DashboardPageAction } from '@action/user/dashboard-page.action'
import {
  ChatResponseDtoPayload,
  GeospatialQueryPayloadHistoric,
  GeospatialQueryPayloadRealtime
} from '@model/message.model'
import {
  PredefinedMessageHelper,
  GetResponseDto,
  ResponseDtoDisplayTextProps,
  ResponseTypeDisplayStrings,
  ResponseTypeEnum
} from '@model/message/predefined-message.model'
import {
  CompleteSchoolAreaDto,
  PatchSchoolAreaDto,
  PostSchoolAreaDto,
  SchoolAreaDto,
  SchoolAreaDtoProps,
  ValidSchoolAreaDto
} from '@model/school/school-subarea.model'
import { GetSchoolDto } from '@model/school/school.model'
import { AreaType } from '@model/school/sub-area.model'
import { MobileUserTypes } from '@model/user/user.model'
import { ActionCreator } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { NewAreaGraphicAttributes, StatusColorEnum } from '@view/area/area.view'
import { IAreaIdAndThreatVm } from '@view/area/threat-area.view'
import { IMapConfigAreaViewModel } from '@view/pages/school-map-config-page/school-map-config.view'

/** Dynamic lookup object type to determine if area name is unique by type. */
export type AreaNamesByTypeLookup = Record<AreaType, string[]>
export interface DeleteAreaSideEffectPayload {
  areaId: number
  areaLogicalId: string
  existingSchoolDto: GetSchoolDto | null
}
export class SchoolAreaModelHelper {
  static areaIsValid = (a: SchoolAreaDto): a is ValidSchoolAreaDto => {
    if (!a.id) {
      console.error(`Area has no id`)
      return false
    }
    if (!a.boundary) {
      console.error(`Area ${a.id}: has no boundary`)
      return false
    }
    if (!a.logicalId) {
      console.error(`Area ${a.id}: has no logical id`)
      return false
    }
    // TODO This doesn't get returned from the database so we can't rely on it, this is a bug
    // if (!a.schoolLogicalId) {
    //   console.error(`Area ${a.id}: has no schoolLogicalId`)
    //   return false
    // }
    if (a.name === '') {
      console.error(`Area id: ${a.id} has no name`)
      return false
    }
    if (!a?.type || (a?.type as any) === AreaType.unknown) {
      console.error(`Area has an unknown type`)
      return false
    }
    return true
  }
  static getDtoFromAttrAndVm(
    boundary: number[][],
    newAttributes: NewAreaGraphicAttributes,
    areaMapConfigVm: IMapConfigAreaViewModel
  ): PostSchoolAreaDto {
    return {
      schoolId: areaMapConfigVm.schoolId,
      schoolLogicalId: areaMapConfigVm.schoolLogicalId,
      boundary,
      name: newAttributes.name,
      type: newAttributes.type,
      areaType: newAttributes.areaType,
      logicalId: newAttributes.logicalId
    }
  }

  /** In order to work with an area in the context of chat rooms we must have a name for it as well as a logical id to send to a message broker. */
  static areaHasNameAndIds = (subarea: SchoolAreaDto): subarea is CompleteSchoolAreaDto => {
    return !!subarea.name && !!subarea.logicalId && !!subarea.id
  }
  static areaDtoHasPatchProps = (
    subarea: Partial<SchoolAreaDto>
  ): subarea is PatchSchoolAreaDto => {
    return !!subarea[SchoolAreaDtoProps.id] && !!subarea[SchoolAreaDtoProps.schoolId]
  }

  /**
   * @param dto Allows a poll response if it is either calculateAreaStatus of true and has a color or it has a response type which makes it an alert or negate alert type of response.
   */
  static dtoIsValidForUpdatingAreaStatusVm = (dto: GetResponseDto): boolean => {
    return (
      (dto?.calculateAreaStatus && !!dto?.color) ||
      (!!dto.type && Object.values(ResponseTypeEnum).includes(dto.type))
    )
  }

  /**
   * When it comes to non alert related poll responses.
   * Only a teacher can override a status already set by a teacher
   * Or a student is updating the status color that they themselves set
   */
  static areaStatusVmCantUpdateForNonAlertPollResponse = (
    newVm: AreaStatusViewModel,
    getResponseDto: GetResponseDto,
    chatResDto: ChatResponseDtoPayload,
    userType: MobileUserTypes
  ): boolean => {
    const userIsTeacher = userType === MobileUserTypes.teacher
    const oldAreaStatusIsRed = newVm.currentStatusColor === StatusColorEnum.red
    const newAreaStatusIsntRed = getResponseDto.color !== StatusColorEnum.red
    const statusUpdateToRedByThisUser = newVm.userThatSetRedStatus === chatResDto.mobileUserId
    return (
      //This logic only applies to non-typed poll responses
      !getResponseDto.type &&
      //We can ignore any new updates if the last status was set by the teacher and the update isn't coming from a teacher
      ((newVm.statusSetByTeacher && !userIsTeacher) ||
        //We also ignore updates if the old status was red, and the new status isn't red for two conditions:
        // 1: the user making the update isn't a teacher
        // 2; the user that set the status to red to begin with isn't the one making the update
        (oldAreaStatusIsRed &&
          newAreaStatusIsntRed &&
          !userIsTeacher &&
          !statusUpdateToRedByThisUser))
    )
  }

  /**
   *
   * @param newVm  The area status view model that we are updating the images property of
   * @param type  The new response type that we're processing
   * @returns The new images array for the area status view model
   */
  static getAreaIconImgsByNewType = (
    newVm: AreaStatusViewModel,
    type: ResponseTypeEnum | null | undefined
  ): ResponseTypeEnum[] => {
    let newImages: ResponseTypeEnum[] = []
    if (type === ResponseTypeEnum.negateMissingPerson) {
      newImages = [...newVm.images.filter((x) => x !== ResponseTypeEnum.missingPerson)]
    } else if (type && !newVm.images.includes(type)) {
      newImages = [...newVm.images, type]
    } else {
      newImages = newVm.images ?? []
    }
    // console.log(`New images for area status vm`, newImages)
    return newImages
  }

  /** Returns the prop from the get response dto based on if it has a type and no value for that prop, used for text and long text 
   * A res with a type property implies it's alert related and may have specific display strings. 
   * The conditional part is that these may not be set on the get response dto, for example,
      // 1. a threat indicator poll response or alert should always update to the display string "attack alert" if it's that type and there's no message or long message
      // 2. a medical alert poll response or alert should always update to the display string "medical alert" regardless of what the get response dto says
  */
  static getMessageConditionally = (
    getResponseDto: GetResponseDto,
    prop: ResponseDtoDisplayTextProps
  ): string => {
    return getResponseDto.type && !getResponseDto[prop]
      ? ResponseTypeDisplayStrings[getResponseDto.type]
      : getResponseDto[prop]
  }

  /** To be used once arc gis has found which area is associated to a user who just provided a new poll response. */
  static processMessagesByAreaIdResult = (
    s: DashboardPageState,
    a: ExtendedAction<GeospatialQueryPayloadHistoric>
  ): DashboardPageState => {
    const { areaIdToUsersLastPollResponses, areaIdToAlerts, areaIdToNegatedAlerts } = a.payload
    return {
      ...s,
      areaStatusLookup: AreaStatusViewModel.getAreaStatusLookupByChatResponses(
        s,
        areaIdToUsersLastPollResponses,
        areaIdToAlerts,
        areaIdToNegatedAlerts
      ),
      areaIdToUsersLastPollResponses,
      areaIdToAlerts,
      areaIdToNegatedAlerts
    }
  }
  static threatIndicatorDimmed = (
    s: DashboardPageState,
    a: ExtendedAction<IAreaIdAndThreatVm>
  ): DashboardPageState => {
    const { areaId, threatVm } = a.payload
    const copyAreaStatusLookup = { ...s.areaStatusLookup }
    const currentAreaStatusVm = { ...copyAreaStatusLookup[areaId] }
    if (!currentAreaStatusVm) {
      console.warn(`No area status found for area id ${areaId}`)
    }
    //If code is correct, this should always have an alert if we're getting a dimmed action for an area, but for safety ensure that at least one exists
    const existingAlertsForArea = s.areaIdToAlerts[areaId] ?? []
    if (
      existingAlertsForArea.length > 0 &&
      PredefinedMessageHelper.isThreatIndicator(
        currentAreaStatusVm.currentResponseType
      )
    ) {
      let updatedAlertDtosForArea = PredefinedMessageHelper.reduceAreaAlertsByDimmedThreatVm(
        existingAlertsForArea,
        threatVm,
        s.responseIdToGetResponseDto
      )
      const newAreaStatusVm = AreaStatusViewModel.getAreaStatusVmFromDtoCollections(
        s,
        areaId,
        s.areaIdToUsersLastPollResponses[areaId] ?? [],
        updatedAlertDtosForArea,
        s.areaIdToNegatedAlerts[areaId] ?? []
      )
      return {
        ...s,
        areaStatusLookup: {
          ...s.areaStatusLookup,
          [areaId]: newAreaStatusVm
        },
        areaIdToAlerts: {
          ...s.areaIdToAlerts,
          [areaId]: updatedAlertDtosForArea
        }
      }
    }
    return s
  }
  /** To be used once arc gis has found which area is associated to a user who just provided a new non alert poll response. */
  static updateAreaWithResponsePayload = (
    s: DashboardPageState,
    a: ExtendedAction<GeospatialQueryPayloadRealtime>
  ): DashboardPageState => {
    let isAlert = false
    let isNegateAlert = false
    //Handle differently if alert or non alert. Alert related messages have a response type.
    const { payload, areaIdForPayload } = a.payload
    const { responseId, isSos, mobileUserId } = payload
    //For now all aresponses must have a mobile user id, and either a response id or be an sos alert to be processed for an area view model update.
    if (!responseId && !isSos) {
      console.warn(
        `Something went wrong. Ignoring response with no response id or not an SOS alert!`
      )
      return s
    }

    let getResponseDto: GetResponseDto | null = null
    if (responseId) {
      getResponseDto = s.responseIdToGetResponseDto[responseId] ?? null
      isAlert = PredefinedMessageHelper.IsAlertResponseType(getResponseDto.type)
      isNegateAlert = PredefinedMessageHelper.isNegateAlertResponseType(getResponseDto.type)
    }
    if (!getResponseDto) {
      console.warn(`Ignoring response with no response dto found for response id ${responseId}!`)
      return s
    }
    let newAreaVm: AreaStatusViewModel | null = null
    let responseType = getResponseDto.type
    // Only need to interact with this area when the response is alert related
    if (responseType && Object.values(ResponseTypeEnum).includes(responseType)) {
      newAreaVm = AreaStatusViewModel.getAreaStatusVmFromResponse(
        s.areaStatusLookup[areaIdForPayload],
        payload,
        s.userToTypeLookup[mobileUserId ?? '0'],
        s.responseIdToGetResponseDto[payload.responseId ?? 0]
      )
    }
    if (newAreaVm) {
      return {
        ...s,
        areaStatusLookup: {
          ...s.areaStatusLookup,
          [areaIdForPayload]: newAreaVm
        },
        areaIdToAlerts: {
          ...s.areaIdToAlerts,
          [areaIdForPayload]: isAlert
            ? [...(s.areaIdToAlerts[areaIdForPayload] ?? []), payload]
            : s.areaIdToAlerts[areaIdForPayload] ?? []
        },
        areaIdToNegatedAlerts: {
          ...s.areaIdToNegatedAlerts,
          [areaIdForPayload]: isNegateAlert
            ? [...(s.areaIdToNegatedAlerts[areaIdForPayload] ?? []), payload]
            : s.areaIdToNegatedAlerts[areaIdForPayload] ?? []
        }
      }
    }

    // Non alerts require recalculating the area status lookup based on the new response
    // 1. if the resposne is from a user that already contributed to another area, then both areas need to be recalculated
    // 2. if the response is from a user that already contributed to the same area, then only that area needs to be recalculated, with the old response removed
    // 3. if the response is from a user that hasn't contributed to any area, then only the area that the response is associated with needs to be recalculated -- The below code can be optimized to handle this condition better, and avoid recalculating the area status lookup for all areas
    let areaIdToUsersLastPollResponses = { ...s.areaIdToUsersLastPollResponses }
    // Combine the new collection of area id to all responses based on latest response processed by point in polygon algorithm
    // Overwrite any older area id to chat dto results while preserving history
    // console.log(`Old s.historyOfAreaIdToAllResponses`, s.historyOfAreaIdToAllResponses)
    let areaIdOfUserLastContribution: number | null = null
    let userContributionWasToDifferentArea: boolean = false

    if (!mobileUserId) {
      console.error(
        `DEV ERROR: Got mobile response payload without a mobile user id! Dto id ${payload.id}`
      )
      return s
    }

    if (payload && mobileUserId) {
      Object.values(s.areaStatusLookup).forEach((areaStatusVm) => {
        if (areaStatusVm.userIdsContributingToStatus.has(mobileUserId)) {
          // console.log(
          //   `Found user id status contribution ${mobileUserId} in area id ${areaStatusVm.id}`
          // )
          areaIdOfUserLastContribution = areaStatusVm.id
        }
      })
      userContributionWasToDifferentArea = areaIdOfUserLastContribution !== areaIdForPayload
      // Update the history of dtos for the last user contribution area by removing the user's contribution
      if (areaIdOfUserLastContribution && userContributionWasToDifferentArea) {
        const currentDtos = areaIdToUsersLastPollResponses[areaIdOfUserLastContribution]
        let newDtos = currentDtos.filter((dto) => dto.mobileUserId !== mobileUserId)
        areaIdToUsersLastPollResponses[areaIdOfUserLastContribution] = newDtos
        // console.log(
        //   `Removed user id ${mobileUserId} contribution to area status from area id ${areaIdOfUserLastContribution}`
        // )
      }
    }
    //Now recalculate the old one if needed and update the dto collection for area id with the new payload
    if (payload && areaIdForPayload) {
      let existingDtos = areaIdToUsersLastPollResponses[areaIdForPayload] ?? []
      //In case the user already has a response for this area clear it out
      let clearedFromPotentialOldResponse = existingDtos.filter(
        (dto) => dto.mobileUserId !== mobileUserId
      )
      let newDtos = [...clearedFromPotentialOldResponse, payload]
      areaIdToUsersLastPollResponses[areaIdForPayload] = newDtos
    }
    if (areaIdOfUserLastContribution && userContributionWasToDifferentArea) {
      return {
        ...s,
        areaStatusLookup: AreaStatusViewModel.getAreaStatusLookupByChatResponses(
          s,
          areaIdToUsersLastPollResponses,
          s.areaIdToAlerts,
          s.areaIdToNegatedAlerts
        ),
        areaIdToUsersLastPollResponses: areaIdToUsersLastPollResponses
      }
    }
    let newAreaStatusLookup: Record<number, AreaStatusViewModel> = {
      ...s.areaStatusLookup
    }
    const newVm = AreaStatusViewModel.getAreaStatusVmFromResponse(
      newAreaStatusLookup[areaIdForPayload],
      payload,
      s.userToTypeLookup[mobileUserId],
      s.responseIdToGetResponseDto[payload.responseId ?? 0]
    )
    if (newVm) {
      newAreaStatusLookup[areaIdForPayload] = newVm
    }
    return {
      ...s,
      areaStatusLookup: newAreaStatusLookup,
      areaIdToUsersLastPollResponses: areaIdToUsersLastPollResponses
    }
  }
}
