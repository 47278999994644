import { CoordinateCollection } from '@model/location.model'
import { GetSchoolDto } from '@model/school/school.model'
import { PolygonTypeEnum } from '@service/map/map.component.service.model'
import { defaultMapApi, mapDomSelector } from '@shared/constants'
import { ApiState } from '@state/api.state'
import { FileViewModel, UploadedImageProps } from '@view/browser/file.view'
import {
  MapConfigSteps
} from '../pages/school-map-config-page/school-map-config.view'
import { BaseMapToggleItemViewModel, baseLayerToggleVms } from './base-layer/base-layer-toggle.view'
import { MapApiEnum, MapModesEnum } from './map-enums.view'
import { MapConfigToolbarOptions } from './school-map-config/school-map-config-toolbar.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'

export type SchoolBoundaryPartialState = {
  schoolDto: GetSchoolDto | null
  polygonApi: ApiState
  osmPolygon: CoordinateCollection | null
}

export type OwlMapPolygonColor = string | __esri.Color | number[] | undefined

export class MapViewModel {
  /** This is defined once we load the school dto and process it with step vm logic in SchoolMapConfigStepViewModel */
  currentMapConfigStep: MapConfigSteps | null = null
  //Validation
  mapConfigErrorReason: MapConfigValidationErrors | null = null
  //Tools
  mapConfigToolbarEnabledState: Record<MapConfigToolbarOptions, boolean> = defaultToolEnabledState
  //Map View
  baseLayerSelectVm: BaseMapToggleItemViewModel | null = null
  baseLayerSelectVms: BaseMapToggleItemViewModel[] = baseLayerToggleVms
  mapSelector = mapDomSelector
  mapMode: MapModesEnum = MapModesEnum.edit
  mapApi: MapApiEnum | string = defaultMapApi
  /**
   * ISSUE IN ANGULAR ROUTING _ DASHBOARD PAGE CAN STILL BE ON DOM SO CAN"T SHARE ID FOR MAP DIV */
  static getConfigVersionOfSelector = (): string => {
    return `config-${mapDomSelector}`
  }
  /** Perhaps this should be moved to a computed value based on the map mode */
  shapeMode: PolygonTypeEnum = PolygonTypeEnum.Box

  fileVm?: FileViewModel
  aspectRatioVm: UploadedImageProps = {
    width: 0,
    height: 0,
    aspectRatio: 1
  }
  // Sliders are conditionally renders so default to a null value to indicate they're hidden
  proximitySliderValue: number | null = null
  opacitySliderValue: number | null = null
  rotationSliderValue: number | null = null
}
export const defaultToolEnabledState: Record<MapConfigToolbarOptions, boolean> = {
  [MapConfigToolbarOptions.pointer]: true,
  // [MapConfigToolbarOptions.select]: true,

  [MapConfigToolbarOptions.circle]: true,
  [MapConfigToolbarOptions.polygon]: true,
  [MapConfigToolbarOptions.rectangle]: true,

  [MapConfigToolbarOptions.undo]: false,
  [MapConfigToolbarOptions.redo]: false,

  [MapConfigToolbarOptions.duplicate]: false,
  [MapConfigToolbarOptions.trash]: false
}
export const allDisabledToolEnabledState: Record<MapConfigToolbarOptions, boolean> = {
  [MapConfigToolbarOptions.pointer]: false,
  // [MapConfigToolbarOptions.select]: false,

  [MapConfigToolbarOptions.circle]: false,
  [MapConfigToolbarOptions.polygon]: false,
  [MapConfigToolbarOptions.rectangle]: false,

  [MapConfigToolbarOptions.undo]: false,
  [MapConfigToolbarOptions.redo]: false,

  [MapConfigToolbarOptions.duplicate]: false,
  [MapConfigToolbarOptions.trash]: false
}

//"Polygon" | "Circle" | "Point" | "LineString" | "LinearRing" | "MultiPoint" | "MultiLineString" | "MultiPolygon" | "GeometryCollection"
//TODO Move to core ui view models
export interface IRadioOptViewModel<T> {
  label: string
  value: T
}
export class RadioOptViewModel {
  static mapToVm<T>(value: T): IRadioOptViewModel<T> {
    return { label: `${value}`, value }
  }
}
/** Here to enable mapping a different label to this enum value */
export const getMapApiSourceOptions = (): IRadioOptViewModel<MapApiEnum>[] => [
  { value: MapApiEnum.openLayers, label: 'Open Layers' },
  { value: MapApiEnum.arcGis, label: 'Arc Gis' },
  { value: MapApiEnum.mapBox, label: 'Map Box' }
]
export const getMapModeOptions = (): IRadioOptViewModel<MapModesEnum>[] =>
  Object.values(MapModesEnum).map(RadioOptViewModel.mapToVm)

export const getMapSelectOptions = (): IRadioOptViewModel<PolygonTypeEnum>[] =>
  Object.values(PolygonTypeEnum).map(RadioOptViewModel.mapToVm)

// Create a style function for the modify interaction
export class MapStyleViewModel {
  static createModifyStyleFunction = (feature: any, resolution: any) => {
    return {
      'vertex': {
        strokeColor: '#ff0000',
        fillColor: '#ff0000',
        strokeOpacity: 1,
        strokeWidth: 2,
        pointRadius: 3,
        graphicName: 'cross'
      }
    }
  }
}
