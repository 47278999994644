/** List of events that should trigger save, a way to optimize on handling of changes to be saved. */
export enum UpdateStoppedToolEventEnum {
  moveStop = 'move-stop',
  reshapeStop = 'reshape-stop',
  scaleStop = 'scale-stop',
  rotateStop = 'rotate-stop',
  vertexRemove = 'vertex-remove',
  vertexAdd = 'vertex-add'
}
export class ArcGisToolEventInfoViewModel {
  /** Update event tool info utility to determine when update interaction has stopped */
  static updateToolEventInfoIsOfStoppedVariety = (
    event: __esri.SketchViewModelUpdateEvent
  ): boolean => {
    // console.log('event?.toolEventInfo?.type', event?.toolEventInfo?.type)
    // console.log('event?.toolEventInfo', event?.toolEventInfo)
    const editInPauseStated =
      Object.values(UpdateStoppedToolEventEnum).find((v) => v === event?.toolEventInfo?.type) !==
      undefined
    // console.log('editInPauseStated', editInPauseStated)
    return editInPauseStated
  }
    /** TODO Remove if not needed, as create fires a complete event so unless there are performance gains to be had, this can be safely rmeoved
     * Update event tool info utility to determine when update interaction has stopped */
  // export const createToolEventInfoIsOfStoppedVariety = (
  //   event: __esri.SketchViewModelCreateEvent
  // ): boolean => {
  //   console.log('event?.toolEventInfo?.type', event?.toolEventInfo?.type)
  //   console.log('event?.toolEventInfo', event?.toolEventInfo)
  //   const createToolDone =
  //     Object.values(CreateStoppedEventEnum).find((v) => v === event?.toolEventInfo?.type) !==
  //     undefined
  //   console.log('editInPauseStated', createToolDone)
  //   return createToolDone
  // }
}
