import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import {
  GetPredefinedGroupDto,
  GetResponseDto,
  PredefinedMessageType
} from '@model/message/predefined-message.model'
import { ApiState } from '@state/api.state'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { StatusColorEnum } from '@view/area/area.view'

export class PredefinedMessageModelHelper {
  static getMessageDescByGroupId = (
    groupId: string,
    dtos: GetPredefinedGroupDto[] | null,
    checkRemoveFromToast: boolean
  ): string | null => {
    if (!dtos || !groupId) {
      console.warn(`Missing dtos or group id in getting message description by message id`)
      return null
    }
    let message: string | null = null
    dtos.forEach((dto) => {
      if (dto.groupId === groupId) {
        // console.log(`Found message description in predefined group by message id.`)
        if (!(checkRemoveFromToast && dto.removeFromToast)) {
          message = dto.description
        }
      }
    })
    return message
  }
  static accumResponseIdToAttributes = (dtos: GetPredefinedGroupDto[]) => {
    const responseIdToStatusColorEnum: Record<number, StatusColorEnum> = {}
    const responseIdToGetResponseDto: Record<number, GetResponseDto> = {}
    dtos.forEach((dto) => {
      if (dto.type === PredefinedMessageType.poll) {
        dto.messages[0].responses.forEach((r) => {
          responseIdToGetResponseDto[r.id] = r
          responseIdToStatusColorEnum[r.id] =
            r.color ?? PredefinedMessageModelHelper.getStatusColorByColorNumber(r.colorNumber)
        })
      }
    })
    return {
      responseIdToStatusColorEnum,
      responseIdToGetResponseDto
    }
  }
  static getStatusColorByColorNumber = (colorNumber: number | null): StatusColorEnum => {
    switch (colorNumber) {
      case 1:
        return StatusColorEnum.first
      case 2:
        return StatusColorEnum.second
      case 3:
        return StatusColorEnum.third
      case 4:
        return StatusColorEnum.fourth
      default:
        return StatusColorEnum.unknown
    }
  }
  //PREDEFINED MESSAGES
  //get
  static getPredefinedGroups = (
    s: DashboardPageState,
    a: ExtendedAction<number>
  ): DashboardPageState => {
    return {
      ...s,
      predefinedGroupsApiState: ApiState.createIsLoadingState()
    }
  }
  /** Once we get the predefined messages for the school, create color and dto lookup objects for easy access during later async handlers. Recombine with response groups which is why we spread the existing response id to response dto since there's a race condition to populate this lookup between predefined messages and response groups. */
  static getPredefinedGroupsSuccess = (
    s: DashboardPageState,
    a: ExtendedAction<GetPredefinedGroupDto[]>
  ): DashboardPageState => {
    const { responseIdToGetResponseDto, responseIdToStatusColorEnum } =
      PredefinedMessageModelHelper.accumResponseIdToAttributes(a.payload)
    const combined = {
      ...s.responseIdToGetResponseDto,
      ...responseIdToGetResponseDto
    }
    return {
      ...s,
      predefinedGroupsApiState: ApiState.createHadLoadedState(),
      groupedPredefinedMessages: a.payload,
      responseIdToGetResponseDto: combined,
      responseIdToStatusColorEnum
    }
  }
  static getPredefinedGroupsForSchoolFail = (
    s: DashboardPageState,
    a: ExtendedAction<HttpErrorResponse>
  ): DashboardPageState => {
    return {
      ...s,
      predefinedGroupsApiState: ApiState.createHasErrorState(),
      groupedPredefinedMessages: null
    }
  }
}
