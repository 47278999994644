import { ExtendedAction } from '@action/extended-ngrx-action'
import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import { SchoolAreaDto } from '@model/school/school-subarea.model'
import {
  SchoolOwlState,
  SchoolPreferenceEnum,
  SchoolStateEnum
} from '../../model/school/school-configuation.model'
import { GetSchoolDto, SchoolDto, SchoolDtoProps } from '../../model/school/school.model'
import { GetUserDto } from '@model/user/user.model'
import { UserDtoHelper } from './user-model.helper'
import { TsObjPropUtils } from '@shared/typescript.utils'
import { MapConfigSteps } from '@view/pages/school-map-config-page/school-map-config.view'

export class SchoolDtoHelper {
  /** Central handler for which map config steps are complete. TODO Use in org stats */
  static stepIsComplete(s: MapConfigSteps, schoolDto: GetSchoolDto | null): any {
    switch (s) {
      case MapConfigSteps.mapView:
        return SchoolDtoHelper.hasCompleteMapViewStep(schoolDto)
      case MapConfigSteps.schoolPerimeter:
        return SchoolDtoHelper.hasBoundary(schoolDto)
      case MapConfigSteps.addAreas:
        return SchoolDtoHelper.hasAreas(schoolDto)
      default:
        return false
    }
  }
  /**
   * We need to have the user create a boundary and some amount of areas, for now at least one, but next incorporate geometry rules for percent covered or before that number of areas at minimum.
   * Additionally, they should have at least one mobile user in their user roster. */
  static canShowDashboard = (
    school: GetSchoolDto | null,
    userDtos: GetUserDto[] | null
  ): boolean => {
    if (school == null) return false
    const schoolHasDataForDashboard =
      SchoolDtoHelper.hasBoundary(school) && SchoolDtoHelper.hasAreas(school)
    const hasAtLeastOneMobileUser = UserDtoHelper.usersWithMobileTypeExist(userDtos)
    // console.log("schoolHasDataForDashboard", schoolHasDataForDashboard);
    return schoolHasDataForDashboard && hasAtLeastOneMobileUser
  }
  /** This specifies the school set up step */
  static hasBoundary = (school: GetSchoolDto | null): boolean => {
    if (school == null) return false
    return school.boundary != null && school.boundary.length > 0
  }
  static hasAreas = (school: GetSchoolDto | null): boolean => {
    if (school == null) return false
    return school.subareas != null && school.subareas.length > 0
  }
  static hasOnlyOneArea = (school: GetSchoolDto | null): boolean => {
    if (school == null) return false
    return school.subareas != null && school.subareas.length === 1
  }
  /** TODO Add a unit test for edge cases:
   * A complete map view configuration only requires the base layer, zoom level and a rotation which is defaulted to zero */
  static hasCompleteMapViewStep = (school: GetSchoolDto | null): boolean => {
    if (school === null) return false
    const schoolConfig = school[SchoolDtoProps.schoolConfiguation]
    const hasCompletedMapViewStep =
      TsObjPropUtils.notNullOrUndefined(schoolConfig?.schoolBaseMapType) &&
      TsObjPropUtils.notNullOrUndefined(schoolConfig?.zoomLevel) &&
      TsObjPropUtils.notNullOrUndefined(schoolConfig?.mapViewRotation)
    if (!hasCompletedMapViewStep) {
      console.log('incomplete map view', schoolConfig)
    }
    return hasCompletedMapViewStep
  }
  
  /** Central logic for when map config is complete. */
  static hasCompleteMapConfigSteps = (school: GetSchoolDto | null): boolean => {
    if (school === null) return false
    return SchoolDtoHelper.hasBoundary(school) && SchoolDtoHelper.hasAreas(school) && SchoolDtoHelper.hasCompleteMapViewStep(school)
  }

  /** Type assert to make it easier to work with school dto in the context of subareas */
  static isSchoolWithAreas(dto: GetSchoolDto): dto is GetSchoolDto & { subareas: SchoolAreaDto[] } {
    return SchoolDtoHelper.hasAreas(dto)
  }

  // SCHOOL STATE
  /** This logic is core domain logic and drives how the dashboard looks and behaves. */
  static isEmergencyOrSuspected(schoolDto: SchoolDto): boolean {
    if (!schoolDto.schoolOwlState) return false
    return (
      schoolDto.schoolOwlState.state === SchoolStateEnum.emergency ||
      schoolDto.schoolOwlState.state === SchoolStateEnum.suspected
    )
  }

  /** Handles constructing a new school state object for patching the school */
  static constructNewSchoolStateFromAction = (
    a: ExtendedAction<
      EmergencyResponeEnum | AttackerTypeEnum | SchoolStateTransitionEnum | SchoolPreferenceEnum
    >,
    schoolOwlState: SchoolOwlState | undefined
  ): SchoolOwlState => {
    let newSchoolOwlState = Object.assign({}, schoolOwlState)
    const payloadIsForAttackerType = a.payload in AttackerTypeEnum
    const payloadIsForResponseType = a.payload in EmergencyResponeEnum
    //Change back to routine and erase attack and response type if dismissing event
    if (
      a.payload === SchoolStateTransitionEnum.dismissEmergency ||
      a.payload === SchoolStateTransitionEnum.dismissSuspected
    ) {
      newSchoolOwlState.state = SchoolStateEnum.routine
      newSchoolOwlState.attackerType = undefined
      newSchoolOwlState.responseType = undefined
    }
    // Change to emergency state and update both response and attack type since we won't know which one they want to update on this first escalation action
    // TODO Add response attack type handling for escalating a suspected state
    else if (a.payload === SchoolStateTransitionEnum.escalateSuspected) {
      newSchoolOwlState.state = SchoolStateEnum.emergency
    } else if (
      //Handle direct to emergency from routine action
      payloadIsForAttackerType ||
      payloadIsForResponseType
    ) {
      newSchoolOwlState.state = SchoolStateEnum.emergency
      if (payloadIsForAttackerType) {
        newSchoolOwlState.attackerType = a.payload as AttackerTypeEnum
      } else if (payloadIsForResponseType) {
        newSchoolOwlState.responseType = a.payload as EmergencyResponeEnum
      }
    } else if (a.payload in SchoolPreferenceEnum) {
      newSchoolOwlState.preference = a.payload as SchoolPreferenceEnum
    }
    // console.log(`New school state object`)
    // console.table(newSchoolOwlState)
    return newSchoolOwlState
  }
}
