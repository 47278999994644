import { GlobalState } from './auth.state'
import { DashboardPageState } from './page/dashboard-page.state'
import { InstructionsPageState } from './page/instructions-page.state'
import { RosterPageState } from './page/roster-page.state'
import { SchoolMapConfigPageState } from './page/school-map-config-page.state'
import { ISchoolValidationPageState } from './page/school-validation-page.state'
import { WelcomePageState } from './page/welcome-page.state'
import { IOwlRouterTopLevelState } from './router.state'

export enum OwlStateSlices {
  //Available to any page in the app
  /** Regarding user authentication and any user related server data, this is the user context for server provided data about the user. */
  auth = 'auth',

  /** Holds the customized state object for the current route */
  router = 'router',

  //Available only to a distinct url
  /** Dashboard page dashboard actions should trigger even when on other pages to stay up to date while connected so loaded in app module as opposed to a lazy loaded route module*/
  schoolDashboard = 'schoolDashboard',
  schoolMapConfig = 'schoolMapConfig',
  schoolValdation = 'schoolValidation',

  /** Roster page state*/
  roster = 'roster',
  instructions = 'instructions',
  events = 'events',

  //PUBLIC PAGES
  welcome = 'welcome'
}
export interface IAppState extends Record<OwlStateSlices, any> {
  [OwlStateSlices.auth]: GlobalState
  [OwlStateSlices.router]: IOwlRouterTopLevelState
  /** // These are the core app effects and are needed at a global level. Map page effects are global because they're used on the school map page and the dashboard page. */
  [OwlStateSlices.schoolDashboard]: DashboardPageState
  [OwlStateSlices.schoolMapConfig]: SchoolMapConfigPageState | undefined
  [OwlStateSlices.schoolValdation]: ISchoolValidationPageState | undefined

  [OwlStateSlices.roster]: RosterPageState
  [OwlStateSlices.instructions]: InstructionsPageState

  //PUBLIC PAGES
  [OwlStateSlices.welcome]: WelcomePageState
}
