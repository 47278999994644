import { MatSelectOptionVm } from "@view/matieral/select.view"

export interface IUnitedStates {
    stateDisplayString: string,
    stateAbr: string
}
export namespace UnitedStatesVm {
    const unitedStatesSelectOptions: IUnitedStates[] = [
        { stateDisplayString: "Alabama", stateAbr: "AL" },
        { stateDisplayString: "Alaska", stateAbr: "AK" },
        { stateDisplayString: "Arizona", stateAbr: "AZ" },
        { stateDisplayString: "Arkansas", stateAbr: "AR" },
        { stateDisplayString: "California", stateAbr: "CA" },
        { stateDisplayString: "Colorado", stateAbr: "CO" },
        { stateDisplayString: "Connecticut", stateAbr: "CT" },
        { stateDisplayString: "Delaware", stateAbr: "DE" },
        { stateDisplayString: "Florida", stateAbr: "FL" },
        { stateDisplayString: "Georgia", stateAbr: "GA" },
        { stateDisplayString: "Hawaii", stateAbr: "HI" },
        { stateDisplayString: "Idaho", stateAbr: "ID" },
        { stateDisplayString: "Illinois", stateAbr: "IL" },
        { stateDisplayString: "Indiana", stateAbr: "IN" },
        { stateDisplayString: "Iowa", stateAbr: "IA" },
        { stateDisplayString: "Kansas", stateAbr: "KS" },
        { stateDisplayString: "Kentucky", stateAbr: "KY" },
        { stateDisplayString: "Louisiana", stateAbr: "LA" },
        { stateDisplayString: "Maine", stateAbr: "ME" },
        { stateDisplayString: "Maryland", stateAbr: "MD" },
        { stateDisplayString: "Massachusetts", stateAbr: "MA" },
        { stateDisplayString: "Michigan", stateAbr: "MI" },
        { stateDisplayString: "Minnesota", stateAbr: "MN" },
        { stateDisplayString: "Mississippi", stateAbr: "MS" },
        { stateDisplayString: "Missouri", stateAbr: "MO" },
        { stateDisplayString: "Montana", stateAbr: "MT" },
        { stateDisplayString: "Nebraska", stateAbr: "NE" },
        { stateDisplayString: "Nevada", stateAbr: "NV" },
        { stateDisplayString: "New Hampshire", stateAbr: "NH" },
        { stateDisplayString: "New Jersey", stateAbr: "NJ" },
        { stateDisplayString: "New Mexico", stateAbr: "NM" },
        { stateDisplayString: "New York", stateAbr: "NY" },
        { stateDisplayString: "North Carolina", stateAbr: "NC" },
        { stateDisplayString: "North Dakota", stateAbr: "ND" },
        { stateDisplayString: "Ohio", stateAbr: "OH" },
        { stateDisplayString: "Oklahoma", stateAbr: "OK" },
        { stateDisplayString: "Oregon", stateAbr: "OR" },
        { stateDisplayString: "Pennsylvania", stateAbr: "PA" },
        { stateDisplayString: "Rhode Island", stateAbr: "RI" },
        { stateDisplayString: "South Carolina", stateAbr: "SC" },
        { stateDisplayString: "South Dakota", stateAbr: "SD" },
        { stateDisplayString: "Tennessee", stateAbr: "TN" },
        { stateDisplayString: "Texas", stateAbr: "TX" },
        { stateDisplayString: "Utah", stateAbr: "UT" },
        { stateDisplayString: "Vermont", stateAbr: "VT" },
        { stateDisplayString: "Virginia", stateAbr: "VA" },
        { stateDisplayString: "Washington", stateAbr: "WA" },
        { stateDisplayString: "West Virginia", stateAbr: "WV" },
        { stateDisplayString: "Wisconsin", stateAbr: "WI" },
        { stateDisplayString: "Wyoming", stateAbr: "WY" },
        { stateDisplayString: "District of Columbia", stateAbr: "DC" },
        { stateDisplayString: "Puerto Rico", stateAbr: "PR" },
        { stateDisplayString: "Guam", stateAbr: "GU" },
        { stateDisplayString: "U.S. Virgin Islands", stateAbr: "VI" },
        { stateDisplayString: "American Samoa", stateAbr: "AS" },
        { stateDisplayString: "Northern Mariana Islands", stateAbr: "MP" }
    ]

    const getMatSelectOptionsForUnitedStates = (): MatSelectOptionVm[] => {
        return unitedStatesSelectOptions.map((state: IUnitedStates) => {
            return {
                value: state.stateAbr,
                label: state.stateDisplayString
            }
        })
    }
    export const selectStateOptions = getMatSelectOptionsForUnitedStates()
}