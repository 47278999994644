import { Injectable } from '@angular/core'
import { BaseStorageService, StorageType } from './base-storage.service'

@Injectable()
export class SessionStorageService extends BaseStorageService {
  storageType = StorageType.sessionStorage
  override getService(): Storage {
    return sessionStorage
  }
  constructor() {
    super()
  }
}
