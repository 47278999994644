import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { AppConfigService } from '@service/app-config/app-config.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { Observable } from 'rxjs'

@Injectable()
export class PublicRouteGuard implements CanActivate {
  constructor(private appConfigService: AppConfigService,
    private loggingService: LoggingService

  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // Check if route is for non public request and redirect to public site if it isn't
    const isPublic = this.appConfigService.isPublicSite()
    this.loggingService.logLocally(
      `
      In PUBLIC GUARD, with state:
        isPublic ${isPublic}
        for route.url ${route.url}
        router state url ${state.url}
      `, OwlLoggingArea.ROUTING, OwlLogLevels.VERBOSE
    )
    return isPublic
  }
}
