import { Action } from '@ngrx/store'

export interface ExtendedAction<T> extends Partial<Action> {
  payload: T
}

export function getExtendedAction<T extends unknown>(data: T): ExtendedAction<T> {
  return {
    payload: data
  }
}
