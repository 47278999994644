import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { getExtendedAction } from '@action/extended-ngrx-action'
import { dispatchAppConfig } from '@action/global-app.actions'
import { authenticatedAppSubdomain, FirstLevelDir, LocalHostPorts, SecondLevelDir } from '@domain/route/app-routes.domain'
import { Store } from '@ngrx/store'
import { isLocalHost } from '@shared/js-window'
import { BehaviorSubject } from 'rxjs'
import { defaultMockAppConfigService, mockAppConfig } from './app-config.mock'
import { IAppConfig, IAppConfigService } from './app-config.model'

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements IAppConfigService {
  config: IAppConfig = mockAppConfig

  configSubject = new BehaviorSubject<IAppConfig>(
    defaultMockAppConfigService?.config ?? ({} as IAppConfig)
  )
  config$ = this.configSubject.asObservable()

  get useAuth(): boolean {
    return this.config?.USE_AUTH
  }

  constructor(private _http: HttpClient, private _store: Store) { }
  init = () => {
    //TODO Add cache to IaaC config - until then query param of epoch time added to bypass config caching
    return this._http
      .get(`./assets/config.json?v=${new Date().valueOf()}`)
      .toPromise()
      .then(this.handleAppConfigFromServer)
  }
  getRequestOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }
  /** This OWL browser app hosts two apps, a public one, and an authenticated one. This is the centralized logic to determine which one. For local host we have to use ports. */
  isPublicSite = (): boolean => {
    if (isLocalHost()) {
      //Check if port is 4100 
      return window.location.port === LocalHostPorts.Public
    } else {
      //Check if authenticatedAppSubdomain in list of subdomains, this means this isn't the public site as the public site won't have a .app subdomain
      return !window.location.hostname.split('.').includes(authenticatedAppSubdomain)
    }
  }
  /** Central logic to determine if user should be redirected to create account user flow. */
  isCreateAccountFlow = (): boolean => {
    // First directory should be route FirstLevelDir.auth
    const firstDir = window.location.pathname.split('/')[1]

    // Second directory should be route SecondLevelDir.createAccount
    const secondDir = window.location.pathname.split('/')[2]
    return firstDir === FirstLevelDir.auth && secondDir === SecondLevelDir.createAccount
  }
  handleAppConfigFromServer = (data: any) => {
    this.config = data
    this.configSubject.next(this.config)
    this._store.dispatch(dispatchAppConfig(getExtendedAction(this.config)))
    this.setHistoricQuery()
  }
  /** Local reference to a date that's set to the time of the first desired data item on the dash. */
  private historicMessagesTime = new Date()
  setHistoricQuery(): void {
    const epochTime = this.historicMessagesTime.getTime()
    const timeBackInMs = this.config.HISTORIC_CONTENT_WINDOW_MINUTES * 60000
    const timeForQuery = epochTime - timeBackInMs
    this.historicMessagesTime.setTime(timeForQuery)
  }
  /** Whenever the dashboard loads, we'll want to see all of the related messaging that happened since x hours ago. TODO Clarify what value this is and update the temp dev value of 480 minutes / 8 hours*/
  getDateForQueryFromConfig(): Date {
    return this.historicMessagesTime
  }
  useMockData = (): boolean => {
    return this.config?.USE_MOCK_API_DATA
  }
  getConfig = (): IAppConfig => this.config

  getFullPath = (path: string, method = null): string => {
    return `${this.config.API_URL}${path}` //isLocalHost()
    // ? `${this.config.API_URL}${path}`
    // : `./${this.config.API_URL}${path}`
  }
  //TEMP Commented our handling for non app gateway approach where api and front end are on different domains.
  customGetFullPath = (path: string, method = null): string => {
    return `${this.config.API_URL}${path}` ///isLocalHost() ? `${this.config.API_URL}${path}` : `./${this.config.API_URL}${path}`
  }
}
