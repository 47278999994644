import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { HttpHeaders, HttpResponse } from '@angular/common/http'
import { SchoolEventSummaryDto } from '@view/pages/events-page/events-dto.view'

@Injectable()
export class EventsApiService {
  public readonly serviceBaseDir = 'Events'
  constructor(private _apiService: ApiService) {}
  getEvents = (schoolId: number): Observable<SchoolEventSummaryDto[]> => {
    return this._apiService.get(`/${this.serviceBaseDir}?schoolId=${schoolId}`)
  }

  downloadEvent = (schoolId: number, eventId: string | null): Observable<HttpResponse<Blob>> => {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/octet-stream'
    })
    return this._apiService.get(
      `/${this.serviceBaseDir}/download?schoolId=${schoolId}&eventId=${eventId}&timeDifference=${
        new Date().getTimezoneOffset() / 60
      }`,
      {
        headers,
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    )
  }
}
