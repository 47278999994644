import { decrementToastInQueue } from '@action/global-app.actions'
import { animate, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core'
import { Store } from '@ngrx/store'
import { TOAST_LIFETIME, TOAST_LIFETIME_BUFFER } from '@shared/constants'
import { IToastViewModel } from '@view/chat/auto-sent-message-snackbar.view'
import { Subscription } from 'rxjs'
import { ToastVmIcons } from './snackbar.component.model'
import { SnackbarService } from './snackbar.service'

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [
    trigger('state', [
      transition(':enter', [
        style({ opacity: 0, right: '0px' }),
        animate(
          `${TOAST_LIFETIME_BUFFER}ms cubic-bezier(0, 0, 0.2, 1)`,
          style({
            opacity: 1,
            right: '16px'
          })
        )
      ]),
      transition(':leave', [
        style({ opacity: 1, right: '16px' }),
        animate(
          `${TOAST_LIFETIME_BUFFER}ms cubic-bezier(0.4, 0.0, 1, 1)`,
          style({
            opacity: 0,
            right: '0px'
          })
        )
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent implements OnInit, OnDestroy {
  show = false
  iconName = ToastVmIcons.PulsingOwl
  title: string = ''
  message: string = ''
  subs: Subscription[] = []

  constructor(
    private snackbarService: SnackbarService,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.snackbarService.snackbarState.subscribe(this.handleShow)
  }

  handleShow = (toastVm: IToastViewModel) => {
    this.iconName = toastVm.iconName
    this.title = toastVm.title
    this.message = toastVm.message
    this.show = true
    this.cd.markForCheck()

    setTimeout(() => {
      this.show = false
      this.store.dispatch(decrementToastInQueue())
      this.cd.markForCheck()
    }, TOAST_LIFETIME + TOAST_LIFETIME_BUFFER)
  }

  get titleSvgPath(): string {
    return `./assets/icons/${this.iconName}.svg`
  }

  ngOnDestroy() {
    this.subs.forEach((s) => (!s.closed ? s.unsubscribe() : null))
  }
}
