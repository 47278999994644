import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import { createAction, props } from '@ngrx/store'
import { ScrollPlacesInChatRoom } from '@view/chat/chat.view'
import { IPollInteractionViewModel } from '@view/poll/poll.view'

export enum DashboardPageChatUiActions {
  toggleChatUiMenuVisibility = '[DashboardPageActions] Toggle Chat UI Menu Visibility',
  toggleTeachersTypeButton = '[DashboardPageActions] Toggle Teachers Type Button',
  toggleOtherStaffTypeButton = '[DashboardPageActions] Toggle Other Staff Type Button',
  toggleStudentsTypeButton = '[DashboardPageActions] Toggle Students Type Button',

  handleSelectMessageClick = '[DashboardPageActions] Handle Chat UI Menu Select Message Click',
  handleSelectPollClick = '[DashboardPageActions] Handle Chat UI Menu Select Poll Click',
  handleCreatePollClick = '[DashboardPageActions] Handle Chat UI Menu Create Poll Click',

  handleChatRoomChangeLeft = '[DashboardPageActions] Handle Chat UI Menu Go Left',
  handleChatRoomChangeRight = '[DashboardPageActions] Handle Chat UI Menu Go Right',

  handleSelectPredefinedItemId = `[DashboardPageActions] Handle Select Predefined Item Id`,

  setPressedPollId = `[DashboardPageActions] Set Pressed Poll Id`,
  handlePollComponentHover = `[DashboardPageActions] Handle Poll Component Hover`,
  handleShowChatListClick = `[DashboardPageActions] Set Handle Chat UI Switch from List to Room`,

  setScrollPlacesInChatRooms = '[DashboardPageActions] Set Scroll Places in Chat Rooms',
  setScrollPlacesInChatRoom = '[DashboardPageActions] Set Scroll Places in Chat Room',
  setScrollPlacesInLocalStorage = '[DashboardPageActions] Set Scroll Places in Local Storage'
}

export const setScrollPlacesInChatRooms = createAction(
  DashboardPageChatUiActions.setScrollPlacesInChatRooms,
  props<ExtendedAction<Record<string, ScrollPlacesInChatRoom>>>()
)

export const setScrollPlacesInChatRoom = createAction(
  DashboardPageChatUiActions.setScrollPlacesInChatRoom,
  props<ExtendedAction<ScrollPlacesInChatRoom>>()
)

export const setScrollPlacesInLocalStorage = createAction(
  DashboardPageChatUiActions.setScrollPlacesInLocalStorage,
  props<ExtendedAction<boolean>>()
)

export const toggleChatUiMenuVisibility = createAction(
  DashboardPageChatUiActions.toggleChatUiMenuVisibility
)

export const toggleTeachersTypeButton = createAction(
  DashboardPageChatUiActions.toggleTeachersTypeButton
)
export const toggleOtherStaffTypeButton = createAction(
  DashboardPageChatUiActions.toggleOtherStaffTypeButton
)
export const toggleStudentsTypeButton = createAction(
  DashboardPageChatUiActions.toggleStudentsTypeButton
)

export const handleSelectMessageClick = createAction(
  DashboardPageChatUiActions.handleSelectMessageClick
)
export const handleChatRoomChangeLeft = createAction(
  DashboardPageChatUiActions.handleChatRoomChangeLeft
)
export const handleChatRoomChangeRight = createAction(
  DashboardPageChatUiActions.handleChatRoomChangeRight
)
export const handleSelectPollClick = createAction(DashboardPageChatUiActions.handleSelectPollClick)
export const handleCreatePollClick = createAction(DashboardPageChatUiActions.handleCreatePollClick)
export const handleShowChatListClick = createAction(
  DashboardPageChatUiActions.handleShowChatListClick,
  props<ExtendedAction<string>>()
)

//Predefined Item
export const handleSelectPredefinedItemId = createAction(
  DashboardPageChatUiActions.handleSelectPredefinedItemId,
  props<ExtendedAction<number>>()
)

//Poll Component Interaction
export const setPressedPollId = createAction(
  DashboardPageChatUiActions.setPressedPollId,
  props<ExtendedAction<string | null>>()
)
export const handlePollComponentHover = createAction(
  DashboardPageChatUiActions.handlePollComponentHover,
  props<ExtendedAction<IPollInteractionViewModel>>()
)
