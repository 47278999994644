import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import { ModalViewModel } from '@view/matieral/modal.view'

/** Here to centralize the language around escalating, confirming, and dismissing events. TODO Check on language for response and attacker type related selections */
export const getSchoolModalViewModel = (
  t: SchoolStateTransitionEnum | AttackerTypeEnum | EmergencyResponeEnum,
  confirm: any,
  cancel: any
): ModalViewModel => {
  if (
    t in AttackerTypeEnum ||
    t in EmergencyResponeEnum ||
    t === SchoolStateTransitionEnum.escalateSuspected
  ) {
    return {
      ...new ModalViewModel(),
      readyToShow: true,
      iconName: schoolModalIconName[schoolModalViewModelTitle.confirmEmergency],
      titleText: schoolModalViewModelTitle.confirmEmergency,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      promptText: `By clicking "Continue", the entire school will be notified that we are entering a state of emergency. Are you sure you want to continue?`,
      confirmHandler: confirm,
      cancelHandler: cancel
    }
  }
  //   TODO Move other variants into config once language settled
  else if (t === SchoolStateTransitionEnum.dismissSuspected) {
    return {
      ...new ModalViewModel(),
      readyToShow: true,
      iconName: schoolModalIconName[schoolModalViewModelTitle.dismissSuspectedEmergency],
      titleText: schoolModalViewModelTitle.dismissSuspectedEmergency,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      promptText: `This will cancel the suspected emergency and inform everyone involved that we are back to normal. Are you sure you want to continue?`,
      confirmHandler: confirm,
      cancelHandler: cancel
    }
  }
  return new ModalViewModel()
}

export enum schoolModalViewModelTitle {
  confirmEmergency = 'Confirm Emergency',
  dismissSuspectedEmergency = 'Dismiss Suspected Emergency'
}

export const schoolModalIconName: Record<schoolModalViewModelTitle, string> = {
  [schoolModalViewModelTitle.confirmEmergency]: 'confirm-emergency',
  [schoolModalViewModelTitle.dismissSuspectedEmergency]: 'dismiss-suspected-emergency'
}
