import { SignalrMessageType } from '@model/signalr/signalr.model'
import { DEFAULT_CHATROOM_ID, ENTIRE_SCHOOL_CHATROOM_ID } from '@shared/constants'
import {
  ChatHistoryLookup,
  ChatMessageAlertRelatedResDtoPayload,
  ChatMessageDto,
  ChatMessageDtoMessagePayload,
  ChatMessageDtoPayload,
  ChatMessageDtoResponsePayload,
  ChatMessageDtoWithAreaId,
  ChatMessageDtoWithUserIdsPayload,
  ChatMessageMedicalAlertResponseDtoPayload,
  ChatMessageSentPollDtoPayload,
  ChatPollResponseDtoPayload,
  ChatPollResponseIdMobileUserIdDtoPayload,
  ChatPollResponseWithResponseIdDtoPayload,
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload,
  ChatResponseWithMobileIdDto,
  ChatResponseWithPollResponseIdPayload
} from '../../model/message.model'
import { StatusColorEnum } from '@view/area/area.view'
import {
  GetResponseDto,
  PredefinedMessageHelper,
  ResponseTypeEnum
} from '@model/message/predefined-message.model'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { TimeUtils } from '@shared/time.utils'

export class ChatMessageDtoHelper {
  static isResponseIdMessage(
    dto: ChatMessageDto,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): [boolean, GetResponseDto | null] {
    if (!ChatMessageDtoHelper.isChatResponseDto(dto)) {
      return [false, null]
    }
    const { payload } = dto
    if (!ChatMessageDtoHelper.isChatResponseResponseIdPayload(payload)) {
      return [false, null]
    }
    const responseDto: GetResponseDto | undefined =
      responseIdToGetResponseDto[payload.responseId ?? 0] ?? undefined
    if (!responseDto) {
      console.warn(
        `responseDto id not provided in responseIdToGetResponseDto lookup, likely config error, refence config for id: {payload?.responseId} ${payload?.responseId}`
      )
      return [false, null]
    }
    return [true, responseDto]
  }
  /** When we iterate over historic chat messages, and we come across a threat indicator, we need to know if it's too old to affect area status. */
  static shouldTackThreatIndicatorAlert(
    dto: ChatMessageDtoResponsePayload,
    s: DashboardPageState
  ): boolean {
    let shouldTrack = true
    const dateThresholdForThreatIndicator =
      s.appConfig?.THREAT_MODEL_CONFIG.MAX_AGE_THREAT_INDICATOR_THRESHOLD_SECONDS

    // If threat model is misconfigured show warning and don't use it
    if (!dateThresholdForThreatIndicator) {
      console.warn(`Date threshold for threat indicator is not set in app config`)
      return true
    }
    const timeStampDate = TimeUtils.getDateFromString(dto.payload.timestamp)
    if (!timeStampDate) {
      console.warn(`Timestamp for threat indicator is not a valid date ${dto.payload.timestamp}`)
      return true
    }
    const tooOld = TimeUtils.timestampExceedsEpochSeconds(
      timeStampDate,
      dateThresholdForThreatIndicator
    )
    if (tooOld) {
      // console.log(`Threat indicator is too old to track ${timeStampDate.toISOString()}`)
      shouldTrack = false
    } else {
      // console.log(`Threat indicator is NOT too old to track ${timeStampDate.toISOString()}`)
    }
    return shouldTrack
  }
  /**
   * Used to determine if a chat response is a medical alert response
   * @param payload Chat response dto payload
   * @param responseIdToGetResponseDto Lookup of response id to get response dto
   * @returns boolean indicator that payload passed medical alert response payload checks
   */
  static isChatResponseMedicalAlertPayload = (
    payload: ChatResponseDtoPayload | null,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): boolean => {
    if (!payload) {
      return false
    }
    return (
      responseIdToGetResponseDto[payload.responseId ?? 0]?.type === ResponseTypeEnum.medicalAlert
    )
  }
  /** Used by user location view model to override last message indicator based on rules
   *
   */
  static newPayloadIsGreenPollResponseWhileOtherPayloadIsAlert = (
    s: DashboardPageState,
    payload: ChatResponseDtoPayload | null,
    chatResponseDtoPayload: ChatResponseDtoPayload | undefined
  ): boolean => {
    const { responseIdToStatusColorEnum, responseIdToGetResponseDto } = s
    const responseDto: GetResponseDto | undefined =
      responseIdToGetResponseDto[chatResponseDtoPayload?.responseId ?? 0] ?? undefined
    if (!responseDto) {
      console.warn(
        `responseDto id not provided in responseIdToGetResponseDto lookup, likely config error, refence config for id: {payload?.responseId} ${payload?.responseId}`
      )
    }
    const oldResponseIsSosAlert = chatResponseDtoPayload?.isSos ?? false
    const { responseId } = payload ?? {}
    if (!responseId) {
      return false
    }
    const isAlert =
      oldResponseIsSosAlert || PredefinedMessageHelper.IsAlertResponseType(responseDto?.type)
    const responseIdColor = responseIdToStatusColorEnum[responseId] ?? null
    const responseColorIsGreen = responseIdColor === StatusColorEnum.green
    if (responseColorIsGreen && isAlert) {
      // * TODO This must be revisited because new logic seems to invalidate this requirement.
      //  Since the priority is an alert, then a non alert poll response and then a negating alert repsonse, we need to integrate that idea with this.
      // Unless it's fine that the location priority override order is different from the area.
      // Recommendation is that we say not only a response of green but of a green alert negating response
      // Issue is that most likely we can no longer just use the status color of green to invoke logic, since we decided that a non-poll response can't override an alert response, but it can override a negating alert response
      return true
    }
    return false
  }
  /** Used to build original lookup object from historic values. */
  static updatePollIdToResponseIdsLookupFromPayload = (
    lookup: Record<string, number[]>,
    payload: ChatPollResponseDtoPayload
  ): void => {
    const existingResponseIds = lookup[payload.pollLogicalId] ?? []
    lookup[payload.pollLogicalId] = [...existingResponseIds, payload.responseId]
  }
  static canShowMessageInChatRoom = (dto: ChatMessageDto): boolean => {
    if (ChatMessageDtoHelper.isChatMessage(dto)) {
      return true
    } else if (ChatMessageDtoHelper.isChatResponseDto(dto)) {
      // Only use chat response with a message to show in the chat room
      // in that we aggregate poll responses and don't show them individually in the chat
      return !!dto.payload.message
    } else {
      return false
    }
  }
  static canShowMessageInEntireSchooltRoom = (dto: ChatMessageDto): boolean => {
    if (
      dto.payload.chatRoomId !== DEFAULT_CHATROOM_ID &&
      dto.payload.chatRoomId !== ENTIRE_SCHOOL_CHATROOM_ID
    ){
      return false
    }
    return ChatMessageDtoHelper.canShowMessageInChatRoom(dto)
  }
  /** Centralizes type assertion on the chat message or response type. In other words, Dto helper is here to centralize logic for type check between message sent and response */
  static isChatMessage(dto: ChatMessageDto): dto is ChatMessageDtoMessagePayload {
    return dto.type === SignalrMessageType.chatMessage
  }

  static isChatMessageToMobileUserIds(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatMessageDtoWithUserIdsPayload } {
    return (
      this.isChatMessage(dto) && !!dto.payload.mobileUserIds && dto.payload.mobileUserIds.length > 0
    )
  }

  /** Centralizes type assertion on the chat message or response type. In other words, Dto helper is here to centralize logic for type check between message sent and response */
  static isChatResponseDto(dto: ChatMessageDto): dto is ChatMessageDtoResponsePayload {
    return dto.type === SignalrMessageType.chatResponse
  }
  /** Specifies the minimum present properties needed to visualize a chat response on the dashboard map. */
  static isChatResponseDtoPayloadWithAtLeastOneRequired(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithMobileIdDto } {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      !!dto.payload.mobileUserId &&
      (!!(dto.payload as any)?.isSos ||
        !!(dto.payload as any)?.responseId ||
        !!(dto.payload as any)?.message)
    )
  }
  /** This is a typescript type assertion that our chat response includes data needed to aggregate a poll sent component statistics */
  static isChatResponsePollDto(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithPollResponseIdPayload } {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      ChatMessageDtoHelper.isChatResponsePollPayload(dto.payload)
    )
  }
  /** Specifies that this dto has a payload with a mobile user message and mobile user id */
  static isChatResponseMessageDto = (
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithMessagePayload } => {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      !!dto.payload.mobileUserId &&
      !!dto.payload.message
    )
  }
  /**
   *  Specifies an unsolicited alert that got submitted with a response id that's in the list of alert response ids
   * @param payload
   * @param alertResponseIds
   * @returns boolean indicator that payload passed alert response payload checks
   */
  static isSolicitedAlertPayload = (
    dto: ChatMessageDto,
    alertResponseIds: GetResponseDto[]
  ): dto is ChatMessageDto & { payload: ChatMessageAlertRelatedResDtoPayload } => {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload) &&
      alertResponseIds.map((r) => r.id).includes(dto.payload.responseId)
    )
  }
  /**
   *
   * @param dto
   * @param medicalAlertResponseIds Dynamic list of ids that are known at runtime, future feature will involve allowing admin to specify these
   * @returns Boolean indicating that dto has payload with response id that's not an isSos of true and checks against provided array of acceptable medical alert response ids
   */
  static isSolicitedMedicalAlertResponse = (
    dto: ChatMessageDto,
    medicalAlertResponseIds: GetResponseDto[]
  ): dto is ChatMessageDto & { payload: ChatMessageMedicalAlertResponseDtoPayload } => {
    if (!ChatMessageDtoHelper.isChatResponseDto(dto)) {
      return false
    }
    return (
      !!dto.payload.responseId &&
      medicalAlertResponseIds.map((r) => r.id).includes(dto.payload.responseId)
    )
  }
  /** Specifies that this dto represents an alert which can be an unsolicited alert or a solicited alert in the form of a poll response tagged as an alert*/
  static isChatResponseAlertPayload = (
    payload: ChatResponseDtoPayload,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): payload is ChatMessageAlertRelatedResDtoPayload => {
    const isSos = payload.isSos === true
    const responseId: number = payload.responseId ?? 0
    const responseIdType = responseIdToGetResponseDto[responseId]?.type
    const isResponseIdAlert = PredefinedMessageHelper.IsAlertResponseType(responseIdType)
    return isSos || isResponseIdAlert
  }
  /** Used to assert that
   * @param payload has both a responseId and a pollLogicalId
   */
  static isChatResponsePollPayload(
    payload: ChatResponseDtoPayload
  ): payload is ChatPollResponseDtoPayload {
    return payload.responseId !== null && payload.pollLogicalId !== null
  }
  /** Used to assert that
   * @param payload has both a responseId and a mobileuser id
   */
  static isChatResponseResponseIdPayload(
    payload: ChatResponseDtoPayload
  ): payload is ChatPollResponseIdMobileUserIdDtoPayload {
    return payload.responseId !== null && payload.mobileUserId !== null
  }
  /** Used to assert that
   * @param payload has both isSos and no response id - for Balcony reverse compatability for alert processing
   */
  static isChatResponseAlertNoResponseIdPayload(
    payload: ChatResponseDtoPayload
  ): payload is ChatPollResponseIdMobileUserIdDtoPayload {
    return payload.isSos && !payload.responseId && payload.mobileUserId !== null
  }
  /** Used to assert that
   * @param payload has both a responseId and isn't an alert
   */
  static isChatResponseNonAlertResponseIdPayload(
    payload: ChatResponseDtoPayload,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): payload is ChatPollResponseWithResponseIdDtoPayload {
    const type = responseIdToGetResponseDto[payload.responseId ?? 0]?.type
    return payload.responseId !== null && !PredefinedMessageHelper.IsAlertResponseType(type)
  }
  /** Used to assert that
   * @param payload has a message that is a string with content and isn't an alert
   */
  static isChatResponseWithMessageOnlyPayload(
    payload: ChatResponseDtoPayload
  ): payload is ChatResponseWithMessagePayload {
    return !!payload.message && payload.isSos === false && payload.responseId === null
  }
  /** Used to assert that
   * @param payload has a message that is a string with content and isn't an alert
   */
  static isChatResponseWithResponseIdNonAlertPayload(
    payload: ChatResponseDtoPayload,
    lookup: Record<number, GetResponseDto>
  ): payload is ChatResponseWithMessagePayload {
    const responseDto: GetResponseDto | undefined = lookup[payload.responseId ?? 0] ?? undefined
    if (!responseDto) {
      console.warn(
        `isChatResponseWithResponseIdNonAlertPayload, responseDto id not provided in lookup: Record<number, GetResponseDto>, likely config error: {payload?.responseId} ${payload?.responseId}`
      )
    }

    const isntMedicalAlert = responseDto?.type !== ResponseTypeEnum.medicalAlert
    return payload.isSos === false && !!payload.responseId && isntMedicalAlert
  }
  /** Ensures that a chat response has an associated user id and message to display */
  static isChatResponseToShowInChatRoom = (dto: ChatMessageDto): boolean => {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      !!dto.payload.mobileUserId &&
      !!dto.payload.message
    )
  }
  /** Specifies that this dto has a payload with a mobile user id */
  static isChatResponseWithUserIdPayload = (
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithMobileIdDto } => {
    return ChatMessageDtoHelper.isChatResponseDto(dto) && !!dto.payload.mobileUserId
  }
  /** We may want to more explicitly specify the types of our dto internally between response types so centralize that logic here for easier changes */
  static isPollResponseWithPollIdDto(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithPollResponseIdPayload } {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      !!dto.payload.responseId &&
      !!dto.payload.pollLogicalId
    )
  }

  static isChatResponseWithMessageNonAlertDto(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatResponseWithMessagePayload } {
    return (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      !!dto.payload.message &&
      dto.payload.isSos === false &&
      dto.payload.responseId === null
    )
  }
  /** Alerts can only be submitted by a logged in user so the validation is that
   * isSos is true, or the response id is in the list of alert response ids, which will have a response type value in the enum.
   * and there is a mobile user id. */
  static isAlertResponse(
    dto: ChatMessageDto,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): dto is ChatMessageDto & {
    payload: ChatMessageAlertRelatedResDtoPayload
  } {
    if (!ChatMessageDtoHelper.isChatResponseDto(dto)) {
      return false
    }
    const type = dto.payload.responseId
      ? responseIdToGetResponseDto[dto.payload.responseId]?.type
      : null
    return (
      (dto.payload.isSos === true || PredefinedMessageHelper.IsAlertResponseType(type)) &&
      !!dto.payload.mobileUserId
    )
  }

  static isThreatIndicatorAlertReponse(
    dto: ChatMessageDto & {
      payload: ChatResponseDtoPayload
    },
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): dto is ChatMessageDto & {
    payload: ChatMessageAlertRelatedResDtoPayload
  } {
    if (!dto.payload.responseId) {
      return false
    }
    return (
      !!dto.payload.mobileUserId &&
      PredefinedMessageHelper.isThreatIndicator(
        responseIdToGetResponseDto[dto.payload.responseId]?.type
      )
    )
  }
  static isNegatingAlertResponse(
    dto: ChatMessageDtoResponsePayload,
    responseIdToGetResponseDto: Record<number, GetResponseDto>
  ): dto is ChatMessageDto & {
    payload: ChatMessageAlertRelatedResDtoPayload
  } {
    if (!dto.payload.responseId) {
      return false
    }
    return (
      PredefinedMessageHelper.isNegateAlertResponseType(
        responseIdToGetResponseDto[dto.payload.responseId]?.type
      ) && !!dto.payload.mobileUserId
    )
  }

  static isSentPollChatMessage(
    payload: ChatMessageDtoPayload
  ): payload is ChatMessageSentPollDtoPayload {
    return !!payload.pollType && !!payload.logicalId
  }
  /** Only sent polls will have a poll type so we can use that quality on the chat message dto to determine which polls should receive aggregated results views in the chat ui */
  static isSentPoll(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatMessageSentPollDtoPayload } {
    return (
      ChatMessageDtoHelper.isChatMessage(dto) &&
      ChatMessageDtoHelper.isSentPollChatMessage(dto.payload)
    )
  }
  /** Type assertion that helps with dto that has chat room id and area logical id */
  static hasChatRoomIdAndAreaLogicalId(
    dto: ChatMessageDto
  ): dto is ChatMessageDto & { payload: ChatMessageDtoWithAreaId } {
    return !!dto.payload.areaLogicalId && !!dto.payload.chatRoomId && !!dto.payload.logicalId
  }
  /** When we click on an area we'll need to know which chat room id to show, so this builds an easy reference to that based on messages that we receive from history, TODO We'll need to add to this lookup as we create messages for new area based chat rooms. */
  static buildAreaGuidToChatRoomIdLookup = (dtos: ChatMessageDto[]): Record<string, string> => {
    let lookup: Record<string, string> = {}
    dtos.forEach((dto) => {
      const { payload } = dto
      if (payload.areaLogicalId && payload.chatRoomId) {
        lookup[payload.areaLogicalId] = payload.chatRoomId
      }
    })
    return lookup
  }
  // /**
  //  * @deprecated TODO remove usage of this iteration just for the lookup, assemble in main iteration - --- When we get historic chat data, we need to take any poll responses and aggregate them by the mobile id so we can update any area status
  //  */
  // static assembleuserMobileIdToLastPollResponseLookup = (
  //   dtos: ChatMessageDto[]
  // ): Record<string, ChatResponseDtoPayload> => {
  //   const lookup: Record<string, ChatResponseDtoPayload> = {}
  //   dtos.forEach((dto: ChatMessageDto) => {
  //     if (ChatMessageDtoHelper.isPollResponseDto(dto)) {
  //       const typedPayload = dto.payload as ChatResponseDtoPayload
  //       lookup[typedPayload.mobileUserId ?? '0'] = typedPayload
  //     }
  //   })
  //   return lookup
  // }

  /**
   * Here to track chat messages by school id so that in the future we can enable changing school context in one school admin account.
   * */
  static handleNewChatMessage = (
    existingChatMessageLookup: ChatHistoryLookup,
    dto: ChatMessageDto
  ): ChatHistoryLookup => {
    // console.log('new alert received', newAlert)
    // console.log('schooldId', schooldId)
    let schoolId = '0'
    let typedPayload
    // TODO Standardize naming to schoolid or schoolLogicalId to avoid this type of conditional assignment
    if (dto.type === SignalrMessageType.chatMessage) {
      typedPayload = dto.payload as ChatMessageDtoPayload
      schoolId = typedPayload.schoolId ?? '0'
    } else if (dto.type === SignalrMessageType.chatResponse) {
      typedPayload = dto.payload as ChatResponseDtoPayload
      schoolId = typedPayload.schoolLogicalId ?? '0'
    }
    let copyOfChatMessages: ChatHistoryLookup = Object.assign({}, existingChatMessageLookup)
    let currentChatMessagesForSchool = copyOfChatMessages[schoolId] || []

    //Overwrite the school id alert collection to include the most recent one
    let newChatMessageLookup = {
      ...copyOfChatMessages,
      [schoolId]: [...currentChatMessagesForSchool, dto]
    }
    // console.log('newChatMessageLookup')
    // console.log(newChatMessageLookup)
    //Save the collection in js runtime memory - for now until we move this to a store
    return newChatMessageLookup
  }
}
