import { Injectable } from '@angular/core'
import { CanActivate, CanMatch } from '@angular/router'
import { UserTypes } from '@model/user/user.model'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import { Observable, map } from 'rxjs'

/** For the time being once a user selects a school, they can't come back to that screen, and only an OWL admin can change their school. In the future we may enable a user to self onboard to more schools but for now, it's a one school set up. */
@Injectable()
export class SchoolValidationSelectionGuard implements CanActivate, CanMatch {
  constructor(private store: Store) { }

  canMatch(): Observable<boolean> | boolean {
    return this.accessControlLogic
  }
  canActivate(): Observable<boolean> | boolean {
    return this.accessControlLogic
  }
  accessControlLogic = this.store
    .select(selectGlobalState)
    .pipe(map((authState) => UserDtoHelper.canSelectSchool(authState.userDto)))
}
