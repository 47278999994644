import { getExtendedAction } from '@action/extended-ngrx-action'
import { setSignalrrInvitationSummaryDto } from '@action/roster-page/roster-api.actions'
import {
  handleSignalrError,
  receiveSentSignalrChatMessage,
  receiveSignalrChatResponse,
  receiveSignalrSchoolOwlStateUpdate,
  receiveSignalrSchoolUpdate,
  receiveSignalrUserLocation,
  receiveSignalrUserPresenceChange,
  receiveSignalrUserRegistered
} from '@action/signalr.action'
import { Injectable } from '@angular/core'
import * as signalR from '@microsoft/signalr'
import { ChatMessageDto } from '@model/message.model'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import { GetSchoolDto } from '@model/school/school.model'
import {
  SIGNALR_CONNECTION_RECEIVE_MESSAGE,
  SIGNALR_CONNECTION_SEND_MESSAGE,
  SignalrDto,
  SignalrMessageType
} from '@model/signalr/signalr.model'
import { UserLocationDto, UserPresenceChangeDto } from '@model/user/user-location.model'
import { GetUserDto } from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { RosterInvitationSummaryDto } from '@view/pages/roster-page/roster-page.view'
import { Observable, of } from 'rxjs'
import { AppConfigService } from '../app-config/app-config.service'
import { AuthService } from '../auth/auth.service'
import { LoggingService } from '@service/logging/logging.service'
import { ErrorSeverityLevel } from '@shared/constants'

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket
@Injectable()
export class SignalrService {
  public startConnectionPromise?: Promise<void | undefined> | null = null
  public startLocationsConnectionPromise?: Promise<void | undefined> | null = null

  /** Local ref to logical id for current websocket connection. At the time of implementation, approach is to only use one school id at a time, until district organization is implemented. */
  private idForCurrentConnection: string | null = null

  constructor(
    private store: Store,
    private _authService: AuthService,
    // private _interceptor: AuthInterceptorService,
    private _appConfigService: AppConfigService,
    private loggingService: LoggingService
  ) {
    // console.log("SignalrService constructor");
  }
  //CONFIG SECTION TO BE MOVED TO ENV FILE
  private get _url(): string {
    return `${this._appConfigService.config.API_URL}/${this._appConfigService.config.WEB_SOCKET_SIGNALR_DIR}`
  }
  private get _locationsUrl(): string {
    return `${this._appConfigService.config.API_URL}/${this._appConfigService.config.WEB_SOCKET_SIGNALR_LOCATIONS_DIR}`
  }
  private _getPath(schooldId: string): string {
    return `?schoolId=${schooldId}`
  }
  private _getLocationsPath(schooldId: string): string {
    return `?schoolId=${schooldId}`
  }

  private _signalrConnectionUrl(schoolId: string): string {
    return `${this._url}${this._getPath(schoolId)}`
  }
  private _locationsSignalrConnectionUrl(schoolId: string): string {
    return `${this._locationsUrl}${this._getLocationsPath(schoolId)}`
  }

  private _connection: signalR.HubConnection | null = null
  private _locationConnection: signalR.HubConnection | null = null
  // https://learn.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-7.0
  private _signalrOptions: signalR.IHttpConnectionOptions = {
    accessTokenFactory: async () => {
      return this._authService.getAccessTokenPromise()
    },
    // Don't specify transport to let azure signalr service decide
    // transport: signalR.HttpTransportType.WebSockets,
    skipNegotiation: false
  }
  /** Map each message type to a specific dispatcher based on the nested property called type */
  public handleSignalrDto = (dto: SignalrDto<unknown>) => {
    // TODO Set up logging areas for signalr
    // console.log(`Received signalr message of type ${dto.type}`)
    // console.log(dto)
    switch (dto.type) {
      case SignalrMessageType.schoolUpdate:
        this.store.dispatch(
          receiveSignalrSchoolUpdate(getExtendedAction(dto.payload as GetSchoolDto))
        )
        break
      // Do we have a receive school state update or does just the school update work?
      case SignalrMessageType.schoolOwlStateUpdate:
        this.store.dispatch(
          receiveSignalrSchoolOwlStateUpdate(getExtendedAction(dto.payload as SchoolOwlState))
        )
        break
      case SignalrMessageType.userLocation:
        this.store.dispatch(
          receiveSignalrUserLocation(getExtendedAction(dto.payload as UserLocationDto))
        )
        break
      case SignalrMessageType.chatResponse:
        this.store.dispatch(receiveSignalrChatResponse(getExtendedAction(dto as ChatMessageDto)))
        break
      case SignalrMessageType.chatMessage:
        this.store.dispatch(receiveSentSignalrChatMessage(getExtendedAction(dto as ChatMessageDto)))
        break
      case SignalrMessageType.mobileUserRegistered:
        this.store.dispatch(
          receiveSignalrUserRegistered(getExtendedAction(dto.payload as GetUserDto))
        )
        break
      case SignalrMessageType.presenceChange:
        this.store.dispatch(
          receiveSignalrUserPresenceChange(getExtendedAction(dto.payload as UserPresenceChangeDto))
        )
        break
      case SignalrMessageType.processRosterCompleted:
        this.store.dispatch(
          setSignalrrInvitationSummaryDto(
            getExtendedAction(dto.payload as RosterInvitationSummaryDto)
          )
        )
    }
    //Provide developer friendly messaging
    if (!Object.values(SignalrMessageType).includes(dto.type)) {
      console.warn(`${dto.type} needs to be handled in signalr service, currently no handler`)
    }
  }
  /** Return a boolean based on if the message gets sent without exceptions */
  public sendSchoolOwlStateChange = (payload: SchoolOwlState) => {
    return this.sendSignalrMessage<SchoolOwlState>(SignalrMessageType.schoolOwlStateUpdate, payload)
  }
  // TODO Set up an abstract class that takes a type and payload and handles these redunedent code safety and logging setup checks.
  /** Here to enable sending the web socket message when the user submits in the chat room. */
  public sendChatMessage = (payload: ChatMessageDto) => {
    return this.sendSignalrMessage<ChatMessageDto>(SignalrMessageType.chatMessage, payload)
  }
  /** Here to enable sending the web socket message when the user submits in the chat room. Payload is partial because it'll be a PATCH type of communication where only one field may be sent for update. */
  public sendSchoolOwlStateUpdate = (payload: Partial<SchoolOwlState>) => {
    return this.sendSignalrMessage<Partial<SchoolOwlState>>(
      SignalrMessageType.schoolOwlStateUpdate,
      payload
    )
  }
  /** Generic send signalr message handler to defensively check for connection, and wrap send call in try catch just once, here.  */
  public sendSignalrMessage<P>(t: SignalrMessageType, payload: P): Observable<boolean> {
    if (!this._connection) {
      console.warn(
        `SignalrService:sendChatMessage can't send message because _connection is undefined, first connect web socket.`
      )
      return of(false)
    }
    try {
      this._connection.send(SIGNALR_CONNECTION_SEND_MESSAGE, payload)
      return of(true)
    } catch (e: any) {
      console.log(`CATCH IN SignalrService: ${t}`)
      console.error(e)
      return of(false)
    }
  }
  initialWaitTime = 2000
  /** This value is changed during retry logic, multiied by retryMultiplier on each retry until we hit the max retry ceiling, then reset */
  currentWaitTime = 0
  retryMultiplier = 1.5
  /** If we keep increasing our retry timeout by 50% starting until we get over the SIGNALR_RECONNECT_MAX_WAIT configured in ms wait, stop trying again. Start with _initialWaitTime as the first await reconnect call. */
  reconnectPolicy: signalR.IRetryPolicy = {
    nextRetryDelayInMilliseconds: (retryContext: signalR.RetryContext): number | null => {
      this.currentWaitTime =
        retryContext.elapsedMilliseconds === 0
          ? this.initialWaitTime
          : this.currentWaitTime * this.retryMultiplier
      if (this.currentWaitTime < this._appConfigService.config.SIGNALR_RECONNECT_MAX_WAIT) {
        // TODO Add to log area for signalr
        // console.log(`
        // SIGNALR SERVICE:
        //   Total elapsed time since lost websocket connection ${retryContext.elapsedMilliseconds}
        //   setting next retry time to ${this.currentWaitTime}`)
        return this.currentWaitTime
      } else {
        this.currentWaitTime = 0
        return null
      }
    }
  }

  /** Connections to real time data from a school can be for one or more schools but for now we're only enabling one at a time, until a school selection UI for a district admin is designed */
  public initConnectionBySchoolId = async (schooldId: string[]) => {
    const defaultIdForConnection = schooldId[0]
    const newSchoolConnectionRequest = this.idForCurrentConnection !== defaultIdForConnection
    if (this._connection || !newSchoolConnectionRequest) {
      console.warn(
        `SignarlR Connection Requested when it's already instantiated, check request logic!`
      )
      return
    }
    this.idForCurrentConnection = defaultIdForConnection
    if (newSchoolConnectionRequest && this._connection) {
      await this.destory()
    }
    const [connection, promise] = this.connect(
      this._signalrConnectionUrl(this.idForCurrentConnection)
    )
    this._connection = connection
    this.startConnectionPromise = promise
    // TEMP IN DEV while testing migration to signalr with serverless azure functions - hence the feature flag wrapper
    if (this._appConfigService.config.FEATURE_FLAGS.USE_LOCATIONS_SIGNALR_ENDPOINT) {
      const [connection, promise] = this.connect(
        this._locationsSignalrConnectionUrl(this.idForCurrentConnection)
      )
      this._locationConnection = connection
      this.startLocationsConnectionPromise = promise
    }
  }
  /**
   * All parameters are passed in so this can be called on multiple connections
   * @param url Full url for the websocket connection
   * @param connection  The connection object to be created
   * @param promise  The promise to be created
   * @returns void
   */
  private connect = (
    url: string
  ): [connection: signalR.HubConnection | null, promise: Promise<void | undefined> | null] => {
    let connection: signalR.HubConnection | null = null
    let promise: Promise<void | undefined> | null = null
    if (!this.idForCurrentConnection) {
      console.error(
        `DEV ERROR: define this.idForCurrentConnection in signalr service before starting a connection`
      )
      return [null, null]
    }
    try {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(url, this._signalrOptions)
        // For troubleshooting - TODO potentially connect to logging flags
        // .configureLogging(signalR.LogLevel.Trace)
        .withAutomaticReconnect(this.reconnectPolicy)
        .build()

      connection.onclose((e: any) => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e)
        this.reconnect(this.initialWaitTime)
      })
    } catch (e: any) {
      console.warn(e)
    }
    if (!connection) {
      this.store.dispatch(handleSignalrError())
    } else {
      promise = connection.start().catch((err) => {
        this.loggingService.logException(err, ErrorSeverityLevel)
      })
      //Every time we get an alert dispatch it to get handled by state management
      connection.on(SIGNALR_CONNECTION_RECEIVE_MESSAGE, this.handleSignalrDto)
    }
    return [connection, promise]
  }
  public destory = async () => {
    await this.disconnectConnection(this._connection)
    await this.disconnectConnection(this._locationConnection)
  }
  reconnect = (currentWaitTime: number) => {
    setTimeout(() => {
      if (this._connection?.state === 'Disconnected') {
        console.log('Restart connection')
        this._connection?.start().catch((err) => {
          this.loggingService.logException(err, ErrorSeverityLevel)
          if (currentWaitTime < this._appConfigService.config.SIGNALR_RECONNECT_MAX_WAIT) {
            this.reconnect(currentWaitTime * this.retryMultiplier)
          }
        })
        return
      } else {
        console.log('Restart connection aborted as it is already connected or connecting.')
      }
    }, currentWaitTime)
  }
  disconnectConnection = async (connection: signalR.HubConnection | null) => {
    if (!connection) {
      console.warn(`Can't destroy signalr connection as it's not defined.`)
      return
    }
    this.idForCurrentConnection = null
    await connection
      .stop()
      .then(this.handleConnectionStopThen)
      .catch(this.handleConnectionCatchThen)
    this.handleConnectionCleanup()
  }
  handleConnectionStopThen = () => {
    console.info(`Successfully stopped signalr connction!`)
  }
  handleConnectionCatchThen = () => {
    console.warn(`Caught error when attempting to stop signalr connction!`)
  }
  handleConnectionCleanup = () => {
    console.log(`Setting signalr connection to null as final disconnect step.`)
    this._connection = null
    this._locationConnection = null
  }
}
