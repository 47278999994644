import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { GetSchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { GetUserDto, MobileUserShownOnMap, MobileUserTypes, UserDtoProps } from '../user/user.model'
import { IsSiteLinkInteractive, OwlSiteLinksViewModel } from '@domain/route/app-routes.domain'
import { SchoolAreaDto } from '@model/school/school-subarea.model'
import { DeleteAreaSideEffectPayload } from '@domain/dto-helpers/area-model.helper'

/**
 * This represents aggregation of logic based on DB data for the user's currently selected school.
 * Used in:
 * Global routing logic to determine if user is allowed to use the saved page feature aka once they've fully onboarded, and if so which page that is.
 * This class is only meant to be constructed with defaults, and recomposed by passing in an old instance of the class and new data (dto) to get a new version of the class.
 * Dtos used for internal implementation:
 * @type GetSchoolDto
 * @type GetUserDto
 * @type GetUserDto[] entire school's user dtos.
 *When this data changes:
 * * user creates first area
 * collection of users for a school changes
 * Uses:
 * 1. Determine if user can see map config
 * 2. Determine if user can see the user roster
 * 3. Determine if user can see the dashboard
 * Planned Users:
 * * when user collection changes, we want to calculate the stats for this user collection to determine if it makes sense to redirect to the dashboard, as well as other conditional UI elements like display toggle user type on map if at least one user in that type exists, or user filter in chat only present if associated users exist.
 */
export class OrgStatsModel {
  public schoolIdsExistForUser: boolean = false
  /**
   * This should be used to show the congrats modal on the first time a user creates a mobile user type.
   * This is only true the very first time the school's collection of users changes from zero to more than one non deactivated mobile user type. This is tracked on app load as well as client side db state changes.
   */
  public clientSideAdditionOfAtLeastOneUser: boolean = false

  /** This indicates that the client created a change in the collection of users but this change shouldn't trigger redirect logic, as loading users initially should. This can happen on create/update for a user or disable action of a user.*/
  public clientSideChangeOfUserCollection: boolean = false

  /** This indicates that the client create a change in the communication standards for instructions and that the redirect logic shouldn't be triggered. */
  public clientSideChangeOfInstructionsCollection: boolean = false

  /** School validation onboarding step has the user pick a school for later addition to it's created school dto */
  public hasSchoolDto: boolean = false

  /** 1. Once the user has saved the first step of the map config */
  public hasCompleteMapView: boolean = false

  /** 2. Once they've saved step two */
  public hasBoundary: boolean = false

  /** 3. Once they have at least one area. */
  public hasAreas: boolean = false

  /** TODO Recommend to only show congrats modal on creation of first area as it might be viewed cumbersome to have to click through three things just to leave after a small change to an area. Then use this to trigger show modal logic. */
  public hasOnlyOneArea: boolean = false

  public dependenciesLoaded: boolean = false

  /** If school has users and they're not deactivated, then we can route to the default page */
  public mobileUserCount: number = 0
  /** Track admin user count changes to avoid redirects on creation or disabling of admin accounts. */
  public adminCount: number = 0

  public userApiLoaded = false
  public schoolApiLoaded = false

  /** At least one mobile user, for now, may become more sophisticated in the future. */
  static hasCompletedRoster = (orgStats: OrgStatsModel): boolean => {
    return orgStats.mobileUserCount > 0
  }

  /** The map config has three steps which all must be complete. */
  static hasCompleteMapConfig = (orgStats: OrgStatsModel): boolean => {
    return orgStats.hasCompleteMapView && orgStats.hasBoundary && orgStats.hasAreas
  }

  static canAccessDashboard = (orgStats: OrgStatsModel): boolean => {
    return OrgStatsModel.canRouteToDash(orgStats)
  }

  /** When we create a new area, if the org stats didn't indicate that the area step was complete, set it that way. */
  static getFromSchoolArea(orgStats: OrgStatsModel, schoolDto: GetSchoolDto, payload: SchoolAreaDto): OrgStatsModel {
    if (!orgStats.hasAreas && payload) {
      return {
        ...orgStats,
        hasOnlyOneArea: schoolDto[SchoolDtoProps.subareas]?.length === 1,
        hasAreas: true
      }
    } else {
      return orgStats
    }
  }

  static getFromDeleteArea(lastOrgStats: OrgStatsModel, payload: DeleteAreaSideEffectPayload) {
    if (lastOrgStats.hasOnlyOneArea) {
      return {
        ...lastOrgStats,
        hasOnlyOneArea: false,
        hasAreas: false
      }
    }
    return lastOrgStats
  }

  /** We use the reload action to specifically refresh the collection of users for the school without triggering any redirects.
   * Example: This is used in the roster page to refresh the user collection after a user is created or updated.
   */
  static getFromReloadUsersForSchoolAction(m: OrgStatsModel): OrgStatsModel {
    return {
      ...m,
      clientSideChangeOfUserCollection: true
    }
  }

  /** We use the reload action to specifically refresh the collection of instructions displayed in the communications page without triggering any redirects.
   * Example: This is used in the communications page to refresh the instructions collection after communication standard is changed or a custom instruction is created.
   */
  static getFromReloadInstructionsForSchoolAction(m: OrgStatsModel): OrgStatsModel {
    return {
      ...m,
      clientSideChangeOfInstructionsCollection: true
    }
  }

  /** We rely on a client side mutation indicator to know not to redirect away from the current page, used in roster page. However, we need to reset this after navigating away. */
  static updateForRouteAction(currentOrgStats: OrgStatsModel): OrgStatsModel {
    return {
      ...currentOrgStats,
      clientSideChangeOfUserCollection: false,
      clientSideAdditionOfAtLeastOneUser: false,
      clientSideChangeOfInstructionsCollection: false
    }
  }

  /** TODO Add osm api loaded here as well. */
  static awaitingMapConfigDeps(stats: OrgStatsModel): boolean {
    return !stats.schoolApiLoaded
  }
  static awaitingRosterDeps(stats: OrgStatsModel): boolean {
    return !stats.userApiLoaded || !stats.schoolApiLoaded
  }
  /** TODO Add messages api to auth state and track in org stats. */
  static awaitingDashboardDeps(stats: OrgStatsModel): boolean {
    return !stats.userApiLoaded || !stats.schoolApiLoaded
  }

  static hasIncompleteMapConfig = (orgStats: OrgStatsModel): boolean => {
    return !orgStats.hasCompleteMapView || !orgStats.hasAreas ||
      !orgStats.hasBoundary
  }

  /**
   * To handle redirect logic centrally, org stats will abstract away the logic and we'll just check that all network calls for all potentially redirected pages are loaded.
   * For now we have three pages we might redirect to outside of the very first onboarding step.
   * 1. Map Config
   * 2. User Roster
   * 3. Dashboard
   */
  static dependenciesLoaded(stats: OrgStatsModel) {
    return (
      !OrgStatsModel.awaitingMapConfigDeps(stats) &&
      !OrgStatsModel.awaitingRosterDeps(stats) &&
      !OrgStatsModel.awaitingDashboardDeps(stats)
    )
  }

  /** Computed based on boolean flags set based on dtos from server. */
  static shouldRouteToDashboard = (stats: OrgStatsModel): boolean => {
    if (stats.clientSideChangeOfUserCollection || stats.clientSideChangeOfInstructionsCollection) {
      return false
    }
    //If school has at least one area, and has users and they're not deactivated, then we can route to the default page
    if (stats.schoolIdsExistForUser && stats.hasAreas && stats.mobileUserCount > 0) {
      return true
    }
    return false
  }
  /** Only once the map config and roster steps are done, are you allowed to view the dash. Computed based on class state. */
  static canRouteToDash = (stats: OrgStatsModel): boolean => {
    if (OrgStatsModel.hasCompleteMapConfig(stats) && OrgStatsModel.hasCompletedRoster(stats)) {
      return true
    }
    return false
  }
  static shouldRedirectToDashboard = (stats: OrgStatsModel): boolean => {
    if (stats.clientSideChangeOfUserCollection) {
      return false
    }
    if (stats.hasAreas && stats.mobileUserCount > 0) {
      return true
    }
    return false
  }
  /** As of now the logic for a complete map config is simple, once there's at least one area you can move on to setting up the roster. We always redirect back to the map config page if there's no uses and the user has school ids. Computed based on class state. */
  static shouldRedirectToMapConfig = (stats: OrgStatsModel): boolean => {
    if (stats.schoolIdsExistForUser && !stats.hasAreas) {
      return true
    }
    return false
  }
  /** In order to use the dashboard the admin must create at least one mobile user type. Implementation likely to change at some point, so handle logic here, and add unit tests. Computed based on class state. */
  static shouldRedirectToUserRoster = (stats: OrgStatsModel): boolean => {
    if (stats.clientSideChangeOfUserCollection) {
      return false
    }
    if (stats.hasBoundary && stats.hasAreas && stats.mobileUserCount === 0) {
      return true
    }
    return false
  }
  /** If user doesn't have at least one assigned school id, they must select a school to administer after self onboarding. Computed based on class state. */
  static shouldRedirectToSchoolValidation = (stats: OrgStatsModel): boolean => {
    if (!stats.schoolIdsExistForUser) {
      return true
    }
    return false
  }
  /** For now the user types are static but this object will need to be update to be constructed dynamically based on the org mobile user type config */
  static defaultUsersByTypeCountState: Record<MobileUserShownOnMap, number> = {
    [MobileUserTypes.student]: 0,
    [MobileUserTypes.otherStaff]: 0,
    [MobileUserTypes.teacher]: 0
  }
  public activeMobileUsersByType: Record<MobileUserShownOnMap, number> = {
    ...OrgStatsModel.defaultUsersByTypeCountState
  }
  constructor() { }
  static getFromAuth(lastOrgStats: OrgStatsModel, dto: GetUserDto | null): OrgStatsModel {
    const newOrgStats = { ...lastOrgStats }
    newOrgStats.schoolIdsExistForUser = !UserDtoHelper.hasNoSchoolId(dto?.schoolIds)
    return newOrgStats
  }
  static getFromSchool(lastOrgStats: OrgStatsModel, dto: GetSchoolDto | null): OrgStatsModel {
    const newOrgStats = { ...lastOrgStats }
    newOrgStats.hasSchoolDto = !!dto
    newOrgStats.hasBoundary = SchoolDtoHelper.hasBoundary(dto)
    newOrgStats.hasCompleteMapView = SchoolDtoHelper.hasCompleteMapViewStep(dto)
    newOrgStats.hasAreas = SchoolDtoHelper.hasAreas(dto)
    newOrgStats.hasOnlyOneArea = SchoolDtoHelper.hasOnlyOneArea(dto)
    newOrgStats.schoolApiLoaded = true
    return newOrgStats
  }
  static getIsSiteLinkDisabledFromOrgStats(stats: OrgStatsModel): IsSiteLinkInteractive {
    return OwlSiteLinksViewModel.isSiteLinkInteractive
  }
  /** This function aggregates counts each time we get an updated collection of users for a school, and will later be used for diffing to determine if there was a significant change in state, like if there were no mobile users before and now there is. */
  static getFromSchoolUsers(
    lastOrgStats: OrgStatsModel,
    dtos: GetUserDto[],
    loggedInUserDto: GetUserDto | undefined
  ): OrgStatsModel {
    if (!loggedInUserDto) {
      return new OrgStatsModel()
    }
    // Keep last stats but reset the user by type counts
    let newStats: OrgStatsModel = { ...new OrgStatsModel(), ...lastOrgStats, mobileUserCount: 0, adminCount: 0 }
    const newUsersByTypeCount = { ...OrgStatsModel.defaultUsersByTypeCountState }
    //Filter down to only mobile user types that have an active status
    dtos.forEach((dto) => {
      const userMobileType = dto[UserDtoProps.mobileType]
      //Exclude self from org stats
      if (loggedInUserDto[UserDtoProps.id] === dto[UserDtoProps.id]) {
        return
      }
      if (
        dto[UserDtoProps.mobileType] &&
        dto[UserDtoProps.status] &&
        UserDtoHelper.isMobileUser(userMobileType) &&
        UserDtoHelper.isNotDeactivatedAndExists(dto) &&
        //Isn't admin
        !UserDtoHelper.isRosterAdmin(dto[UserDtoProps.type] ?? null)
      ) {
        // TODO Refactor display of count of users in roster to use this stats object to reduce user collection iteration.
        const mobileType = userMobileType as MobileUserShownOnMap
        newUsersByTypeCount[mobileType]++
        newStats.mobileUserCount++
      }
      if (UserDtoHelper.isAdmin(dto[UserDtoProps.type] ?? null)) {
        newStats.adminCount++
      }
    })
    // Track any change in the user collection
    // Don't overwrite the client side change flag if it's already true - triggered by manual reload of user collection
    if (
      //Wait for the api state to complete the network call
      newStats.userApiLoaded &&
      // Compare for any change in the user collection, without diffing the actual user objects. This is a simple count comparison.
      // Works for disable user, create, but not update. We use manual reload of user collection to handle update.
      (lastOrgStats.adminCount !== newStats.adminCount ||
        lastOrgStats.mobileUserCount !== newStats.mobileUserCount ||
        OrgStatsModel.userTypeCountsChanges(lastOrgStats, newStats))
    ) {
      newStats.clientSideChangeOfUserCollection = true
    }
    if (
      newStats.userApiLoaded &&
      lastOrgStats.mobileUserCount === 0 &&
      newStats.mobileUserCount > 0
    ) {
      newStats.clientSideAdditionOfAtLeastOneUser = true
    } else {
      newStats.clientSideAdditionOfAtLeastOneUser = false
    }
    newStats.activeMobileUsersByType = newUsersByTypeCount
    newStats.userApiLoaded = true
    return newStats
  }
  /** Checks to see if counts by user type has changed. OWL admins can't change to mobiel type so ignore that as it's be counted in adminCount.*/
  static userTypeCountsChanges(lastOrgStates: OrgStatsModel, newStats: OrgStatsModel): boolean {
    if (
      lastOrgStates.activeMobileUsersByType[MobileUserTypes.student] !==
      newStats.activeMobileUsersByType[MobileUserTypes.student] ||
      lastOrgStates.activeMobileUsersByType[MobileUserTypes.otherStaff] !==
      newStats.activeMobileUsersByType[MobileUserTypes.otherStaff] ||
      lastOrgStates.activeMobileUsersByType[MobileUserTypes.teacher] !==
      newStats.activeMobileUsersByType[MobileUserTypes.teacher]
    ) {
      return true
    }
    return false
  }
  /** Diff the stats object to determine if the number of non deactivated users has gone from zero to at least one. */
  static diffForOnboardingRedirection(oldStats: OrgStatsModel, newStats: OrgStatsModel): boolean {
    if (oldStats.mobileUserCount === 0 && newStats.mobileUserCount > 0) {
      return true
    }
    return false
  }
}
