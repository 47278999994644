import { Injectable } from '@angular/core'
import { ChatMessageDto, ChatMessageDtoPayload } from '@model/message.model'
import { GetPredefinedGroupDto } from '@model/message/predefined-message.model'
import { UserLocationDto } from '@model/user/user-location.model'
import { ApiService } from './api.service'
import { UpdateInstructionDto } from '@view/pages/instructions-page/instructions-page-dto.view'

@Injectable()
export class MessageApiService {
  public readonly serviceBaseDir = '/Message'
  constructor(private _apiService: ApiService) {}
  getChatMessages(schoolId: number) {
    return this._apiService.get<ChatMessageDto[]>(
      `${this.serviceBaseDir}/chats?schoolId=${schoolId}${this._apiService.dateQuery()}`
    )
  }
  getLocations(schoolId: number) {
    return this._apiService.get<UserLocationDto[]>(
      `${this.serviceBaseDir}/locations?schoolId=${schoolId}${this._apiService.dateQuery()}`
    )
  }
  getPredefinedMessages(schoolId: number) {
    return this._apiService.get<GetPredefinedGroupDto[]>(
      `${this.serviceBaseDir}/predefined/all?schoolId=${schoolId}`
    )
  }
  postPredefinedInstructionList(schoolId: number, dto: UpdateInstructionDto[]) {
    return this._apiService.post(`${this.serviceBaseDir}/predefined/list?schoolId=${schoolId}`, dto)
  }
  sendMessage(dto: ChatMessageDtoPayload) {
    return this._apiService.post(`${this.serviceBaseDir}`, dto)
  }
  sendCheckStatus(schoolId: number, areaId: number | null, userIds: string[]) {
    return this._apiService.post(
      `${this.serviceBaseDir}/checkStatus?schoolId=${schoolId}&areaId=${areaId}`,
      userIds
    )
  }
}
