import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  setSignalrrInvitationSummaryDto,
  throttledSetSignalrrInvitationSummaryDto
} from '@action/roster-page/roster-api.actions'
import {
  connectBySchoolIds,
  disconnectFromWebsocket,
  sendSignalrChatMessage
} from '@action/signalr.action'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { SignalrService } from '@service/network/signalr.service'
import { MIN_UI_RELATED_STATE_THROTTLE_TIME } from '@shared/constants'
import { delay, tap } from 'rxjs'

@Injectable()
export class SignalrEffects {
  constructor(
    private actions$: Actions,
    private signalrService: SignalrService,
    private store: Store
  ) {}
  connectToSignalrBySchoolIds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(connectBySchoolIds),
        tap((a) => this.signalrService.initConnectionBySchoolId(a.payload))
      ),
    { dispatch: false }
  )
  disconnectFromWebsocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(disconnectFromWebsocket),
        tap((a) => this.signalrService.destory())
      ),
    { dispatch: false }
  )
  sendChatMessageToSignalr$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendSignalrChatMessage),
        tap((a) => this.signalrService.sendChatMessage(a.payload))
      ),
    { dispatch: false }
  )
  delayRealTimeRosterUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSignalrrInvitationSummaryDto),
        delay(MIN_UI_RELATED_STATE_THROTTLE_TIME),
        tap((a) =>
          this.store.dispatch(
            throttledSetSignalrrInvitationSummaryDto(getExtendedAction(a.payload))
          )
        )
      ),
    { dispatch: false }
  )
}
