import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  setMapConfigErrorReason,
  showConfirmAreaDeleteModal
} from '@action/school-map-config-page.actions'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import {
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../../arcgis-map.service'
import { ArcGisSymbolFactory } from '../../arcgis-symbol-factory.view'
import { ArcgisAreaViewModel } from '../../area/arcgis-area.view'
import { ArcgisBoundaryViewModel } from '../../area/arcgis-boundary.view'
import { ArcGisSketchViewModelEventHandler } from '../arc-gis-sketch-vm-event-handler.view'
import { SketchToolEnum } from '../sketch-vm.view'
import { ArcGisSketchCustomToolStateHandler } from './arc-gis-sketch-custom-tool-state-handler.view'
export namespace ArcGisSketchCustomToolClickHandler {
  export const handleCustomToolClick = (context: ArcGisMapService, o: MapConfigToolbarOptions) => {
    switch (o) {
      case MapConfigToolbarOptions.pointer:
        handlePointer(context)
        return
      //   case MapConfigToolbarOptions.select:
      //     console.error(`Need to implement own selection arc gis handling with custom tools.`)
      //     return
      case MapConfigToolbarOptions.circle:
        handleTransitionToNewAreaCreation(context)
        context._sketchViewModel?.create(SketchToolEnum.circle)
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          context.mapConfigValidationError,
          undefined,
          MapConfigToolbarOptions.circle
        )
        return
      case MapConfigToolbarOptions.polygon:
        handleTransitionToNewAreaCreation(context)
        context._sketchViewModel?.create(SketchToolEnum.polygon)
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          context.mapConfigValidationError,
          undefined,
          MapConfigToolbarOptions.polygon
        )
        return
      case MapConfigToolbarOptions.rectangle:
        handleTransitionToNewAreaCreation(context)
        context._sketchViewModel?.create(SketchToolEnum.rectangle)
        ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
          context,
          context.mapConfigValidationError,
          undefined,
          MapConfigToolbarOptions.rectangle
        )
        return
      // NOTE: Need to wait for the redo/undo to apply before we update the tool state, handled in sketch vm event handler for redo/undo
      case MapConfigToolbarOptions.redo:
        context._sketchViewModel?.redo()
        return
      case MapConfigToolbarOptions.undo:
        handleUndoClick(context)
        return
      case MapConfigToolbarOptions.duplicate:
        if (context.activeConfigStep === MapConfigSteps.addAreas) {
          //TODO Apply area validation to the area to prevent save on duplication since it'll duplicate on top of the selected area
          //TODO need to check if event handlers on create are invoked
          context._sketchViewModel?.duplicate()
        }
        return
      case MapConfigToolbarOptions.trash:
        handleTrashClick(context)
        return
      default:
        console.log(`MapConfigToolbarOptions option click not handled`, o)
        return
    }
  }
  /** When the pointer tool is available it deselects graphics and puts the map into click selection mode. */
  export const handlePointer = (context: ArcGisMapService) => {
    if (context._sketchViewModel?.activeTool) {
      context._sketchViewModel.cancel()
    }
    if (context.activeConfigStep === MapConfigSteps.schoolPerimeter) {
      ArcGisSketchViewModelEventHandler.deselectPerimeterBoundary(context)
    } else if (context.activeConfigStep === MapConfigSteps.addAreas) {
      ArcGisSketchViewModelEventHandler.deselectAreaEditIfSelected(context)
    }
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
      context,
      context.mapConfigValidationError
    )
    //Order matters here because if we update the symbol after hiding the popup and completing we'll see a lage in the symbol change as the symbol gets handled in the sketch view model event complete
    handleTransitionToNewAreaCreation(context)
  }
  export const handleTransitionToNewAreaCreation = (context: ArcGisMapService) => {
    if (context.mapConfigPopupRef?.popupVisible) {
      const idToReset = context.mapConfigPopupRef?.areaAttributes?.id ?? null
      if (idToReset) {
        ArcgisAreaViewModel.updateAreaSymbolById(
          context,
          idToReset,
          ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
        )
        context.mapConfigPopupRef?.hidePopup()
        context._sketchViewModel?.complete()
      }
    }
  }
  export const handleUndoClick = (context: ArcGisMapService) => {
    const featureInProgress = true
    if (
      !featureInProgress &&
      context.activeConfigStep === MapConfigSteps.schoolPerimeter &&
      context._schoolBoundaryLayer &&
      context._schoolBoundaryGraphic &&
      context._sketchViewModel &&
      !context._sketchViewModel?.updateGraphics.at(0)
    ) {
      context._schoolBoundaryLayer?.graphics.add(context._schoolBoundaryGraphic)
      context._sketchViewModel.layer = context._schoolBoundaryLayer
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(context, null)
      ArcgisBoundaryViewModel.toggleProximityLayer(context, true)
    } else {
      context._sketchViewModel?.undo()
    }
  }
  export const handleTrashClick = (context: ArcGisMapService) => {
    if (context.activeConfigStep === MapConfigSteps.schoolPerimeter) {
      // We need to clear the sketch view model
      context._sketchViewModel?.delete()
      // As well as the boundary graphic layer in case they navigate away and come back
      context._schoolBoundaryLayer?.graphics.removeAll()
      if (context._schoolBoundaryProximityLayer?.graphics.at(0)) {
        ArcgisBoundaryViewModel.toggleProximityLayer(context, false)
      }
      // context._sketchViewModel?.updateGraphics.removeAll()
      context.store.dispatch(
        setMapConfigErrorReason(getExtendedAction(MapConfigValidationErrors.boundaryMustExist))
      )
    } else if (context.activeConfigStep === MapConfigSteps.addAreas) {
      const sketchVmGraphic = context._sketchViewModel?.updateGraphics.at(0)
      const areaIdToDelete = sketchVmGraphic?.attributes?.id ?? null
      if (sketchVmGraphic && areaIdToDelete) {
        context.store.dispatch(showConfirmAreaDeleteModal(getExtendedAction(areaIdToDelete)))
        // Instead hide the popup if they confirm delete
        // context.mapConfigPopupRef?.hidePopup()
      }
      // Handle if new graphic isn't valid for save
      else if (sketchVmGraphic) {
        context.mapConfigPopupRef?.hidePopup()
        context._sketchViewModel?.delete()
        //When we remove a graphic from the sketch view model via delete that means that the validation will now pass as either the geom or area validation rules no longer apply
        context.store.dispatch(setMapConfigErrorReason(getExtendedAction(null)))
      } else {
        console.error(`DEV ERROR: Attempting to delete area but no id available.`)
      }
    }
    //Update indication of sketch vm in custom tool component
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
      context,
      MapConfigValidationErrors.boundaryMustExist
    )
  }
}
