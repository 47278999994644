import Graphic from '@arcgis/core/Graphic'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import { SimpleMarkerSymbol } from '@arcgis/core/symbols'
import { OwlThreatModel } from '@service/map/threat-indicator.model'
import { TimeUtils } from '@shared/time.utils'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import {
  GraphicAttributesTypeEnum,
  RecentMessageIndicatorTypeEnum
} from '@view/area/graphic-attributes.model'
import { RecentMessageGraphicAttributes } from '@view/area/location.view'
import { UserLocationViewModel } from '@view/location/user-location.view'
import { ArcGisMapService, LayerIdToTypeLookup } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcgisLayersHandler } from '../layers/arcgis-layers-handler.view'
import { ArcGisGraphicAttrFac } from './arcgis-graphic-attr-fac.view'

export namespace ArcGisFilterByTimestampAttribute {
  /** TODO it makes sense to reset the threat layer on a concluded event but confirm this with the client. The only thing we do on a concluded event is remove the threat model.*/
  export const handleConcludeEvent = (context: ArcGisMapService) => {
    // context._threatLocationLayer?.removeAll()
    // Perhaps the model should stay in existence in case new alerts come in and the event was resolved prematurely.
    // context.threatModel = new OwlThreatModel()
  }
  export const filterAllChatMessageDtoDataOnMapByDate = (
    context: ArcGisMapService,
    d: Date,
    state: DashboardPageState
  ) => {
    //Filter out message locations
    ArcgisLayersHandler.getAllRecentMessageIndicatorLayers(context).forEach(
      (layer: GraphicsLayer | undefined) => {
        if (layer) {
          layer.graphics.forEach((g) => {
            handleAttributeTimestampByDate(context, layer!, g, d, state)
          })
        }
      }
    )
    //Filter all location nested message for tooltip indicators
    ArcgisLayersHandler.getUserLocationLayser(context).forEach(
      (layer: GraphicsLayer | undefined) => {
        if (layer) {
          layer.graphics.forEach((g) => {
            handleAttributeTimestampByDate(context, layer!, g, d, state)
          })
        }
      }
    )
    //Filter out message locations
    ArcgisLayersHandler.getMessageLocationLayers(context).forEach(
      (layer: GraphicsLayer | undefined) => {
        if (layer) {
          layer.graphics.forEach((g) => {
            handleAttributeTimestampByDate(context, layer!, g, d, state)
          })
        }
      }
    )
    //Filter out response locations
    ArcgisLayersHandler.getPollResponseLocationLayers(context).forEach(
      (layer: GraphicsLayer | undefined) => {
        if (layer) {
          layer.graphics.forEach((g) => {
            handleAttributeTimestampByDate(context, layer!, g, d, state)
          })
        }
      }
    )
    //Filter out response
    if (context._pulsingAlertGraphicsLayer) {
      context._pulsingAlertGraphicsLayer.graphics.forEach((g) => {
        handleAttributeTimestampByDate(context, context._pulsingAlertGraphicsLayer!, g, d, state)
      })
    }
    if (context._attackAlertGraphicsLayer) {
      context._attackAlertGraphicsLayer.graphics.forEach((g) => {
        handleAttributeTimestampByDate(context, context._attackAlertGraphicsLayer!, g, d, state)
      })
    }
    if (context._dimmedAlertGraphicsLayer) {
      context._dimmedAlertGraphicsLayer.graphics.forEach((g) => {
        handleAttributeTimestampByDate(context, context._dimmedAlertGraphicsLayer!, g, d, state)
      })
    }
    if (context._medialAlertGraphicsLayer) {
      context._medialAlertGraphicsLayer.graphics.forEach((g) => {
        handleAttributeTimestampByDate(context, context._medialAlertGraphicsLayer!, g, d, state)
      })
    }
  }
  /** Here to either remove a grapihic, update the nested graphic attributes for location and recent message indicator. */
  export const handleAttributeTimestampByDate = (
    context: ArcGisMapService,
    layer: GraphicsLayer,
    graphic: Graphic,
    d: Date,
    state: DashboardPageState
  ): void => {
    let timeAssociatedWithGraphic: Date | null = null
    if (
      !graphic.attributes?.kind ||
      graphic.attributes?.kind === GraphicAttributesTypeEnum.unknown
    ) {
      console.error(`Graphic missing kind property from GraphicAttributesTypeEnum`)
      return
    }
    const userMobileId: string | null = graphic.attributes?.mobileId ?? null
    if (!userMobileId) {
      console.error(`DEV ERROR: Graphic attribute missing id!`)
      // console.log(`graphic.attributes`)
      // console.log(graphic.attributes)
      return
    }
    if (
      graphic.attributes.kind === GraphicAttributesTypeEnum.location ||
      graphic.attributes.kind === GraphicAttributesTypeEnum.recentMessageIndicator
    ) {
      timeAssociatedWithGraphic = graphic.attributes.oldestConsideredDtoTimestamp
    } else {
      timeAssociatedWithGraphic = TimeUtils.getDateFromString(
        graphic.attributes?.chatResponseDtoPayload?.timestamp ?? null
      )
    }

    if (!timeAssociatedWithGraphic) {
      // Only location and recent message indicator graphics can have no timestamps in the nested dto as they preserve a potential hidden symbol at that location
      if (
        graphic.attributes.kind !== GraphicAttributesTypeEnum.location &&
        graphic.attributes.kind !== GraphicAttributesTypeEnum.recentMessageIndicator
      ) {
        console.warn(`missing required timestamp for graphic.attributes`)
        console.warn(graphic.attributes)
      }
      return
    }
    const isStale = timeAssociatedWithGraphic.getTime() < d.getTime()
    if (!isStale) {
      // console.log(`graphic.attributes not yet stale, kind then attributes`)
      // console.log(graphic.attributes.kind)
      // console.log(graphic.attributes)
      return
    }

    const existingLocationVm = state?.userLocationLookup[userMobileId] ?? null
    if(!existingLocationVm){
      return console.warn(`handleAttributeTimestampByDate: existingLocationVm not found for userMobileId ${userMobileId}`)
    }
    const newLocationVm = UserLocationViewModel.getDecoratedUserLocationFromClearingStaleData(
      state,
      existingLocationVm,
      d
    )
    // console.log(`New location vm in arc gis`)
    // console.log(newLocationVm)
    //THREE BRANCHS
    //1 for location graphics
    if (graphic.attributes.kind === GraphicAttributesTypeEnum.location) {
      let dynamicAttributes = {
        ...ArcGisGraphicAttrFac.resetLocationAttributes(graphic.attributes)
      }
      if (newLocationVm) {
        const symbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
          newLocationVm.recentMessageIndicatorType
        )
        if (symbol instanceof SimpleMarkerSymbol) {
          graphic.attributes = dynamicAttributes
        } else {
          //Save ref to attributes to potentially update the popup
          dynamicAttributes = ArcGisGraphicAttrFac.getLocationAttributes(newLocationVm, symbol.url)
          graphic.attributes = dynamicAttributes
        }
      }
      // If this is the selected popup then update the content, otherwise it'll be passed to popup on new location interaction
      if (graphic.attributes.id === context.popupRef?.attributes?.id) {
        context.popupRef?.setContent(dynamicAttributes)
      }
    }
    //2 another for recent message indicator graphic
    else if (graphic.attributes.kind === GraphicAttributesTypeEnum.recentMessageIndicator) {
      //If recent message indicator potentially update the graphic instead of removing it
      if (
        newLocationVm &&
        newLocationVm.recentMessageIndicatorType !== RecentMessageIndicatorTypeEnum.unknown
      ) {
        const symbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
          newLocationVm.recentMessageIndicatorType
        )
        const newAttributes = ArcGisGraphicAttrFac.getLocationAttributes(newLocationVm)
        // Only update the symbol if th erecent message graphic should be shown
        if (
          graphic.attributes.id === context.popupRef?.attributes?.id &&
          context.popupRef?.isShownDueToClick
        ) {
          // console.log(
          //   `Popup open for this user so don't update the symbol until the popup is closed`
          // )
        } else {
          graphic.symbol = symbol.clone()
        }
        graphic.attributes = ArcGisGraphicAttrFac.getRecentMessageIndicatorAttr(newAttributes)
      } else {
        // We keep the graphic around to update when the user gets a new recent message indicator
        graphic.symbol = ArcGisSymbolFactory.hiddenSymbol.clone()
        graphic.attributes = ArcGisGraphicAttrFac.resetRecentMessageAttributes(
          graphic.attributes as RecentMessageGraphicAttributes
        )
      }
    }
    //3 and one for all other types of graphics, they just indicate the location when that message was received so when the timestamp is stale so is the whole graphic
    else {
      // Adding the graphic removal in a timeout ensures immediate removal, otherwise it may not get cleared right away
      setTimeout(() => {
        layer.remove(graphic)
      })
    }
  }
  /** Here to either remove a grapihic, update the nested graphic attributes for location and recent message indicator. */
  export const clearLocationAndMessageIndicatorGraphic = (
    context: ArcGisMapService,
    graphic: Graphic
  ): void => {
    if (
      !graphic.attributes?.kind ||
      graphic.attributes?.kind === GraphicAttributesTypeEnum.unknown
    ) {
      console.error(`Graphic missing kind property from GraphicAttributesTypeEnum`)
      return
    }
    if (graphic.attributes.kind === GraphicAttributesTypeEnum.location) {
      let dynamicAttributes = {
        ...ArcGisGraphicAttrFac.resetLocationAttributes(graphic.attributes)
      }
      graphic.attributes = dynamicAttributes
      if (graphic.attributes.id === context.popupRef?.attributes?.id) {
        context.popupRef?.setContent(dynamicAttributes)
      }
    } else if (graphic.attributes.kind === GraphicAttributesTypeEnum.recentMessageIndicator) {
      graphic.symbol = ArcGisSymbolFactory.hiddenSymbol.clone()
      graphic.attributes = ArcGisGraphicAttrFac.resetRecentMessageAttributes(
        graphic.attributes as RecentMessageGraphicAttributes
      )
    }
  }
}
