import { setChatRoomById, toggleChatUiVisibility } from '@action/dashboard-page.actions'
import {
  handleChatRoomChangeLeft,
  handleChatRoomChangeRight,
  handleCreatePollClick,
  handleSelectMessageClick,
  handleSelectPollClick,
  handleSelectPredefinedItemId,
  handleShowChatListClick,
  setScrollPlacesInChatRoom,
  setScrollPlacesInLocalStorage,
  toggleChatUiMenuVisibility,
  toggleOtherStaffTypeButton,
  toggleStudentsTypeButton,
  toggleTeachersTypeButton
} from '@action/dashboard-page/chat-ui.actions'
import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  handleHomeClick,
  handleZoomInClick,
  handleZoomOutClick,
  toggleShowMap
} from '@action/school-map-config-page.actions'
import {
  connectBySchoolIds,
  disconnectFromWebsocket,
  sendSignalrChatMessage
} from '@action/signalr.action'
import {
  getHistoricChatmessages,
  getHistoricUserLocations,
  sendMessage,
  showOwlSchoolStateChangeModal,
  toggleMapUiControl
} from '@action/user/dashboard-page.action'
import { Injectable } from '@angular/core'
import { ExpandedLinkVariant } from '@component/header/dashboard-sidenav/expanded-link/expanded-link.component.model'
import { ButtonIconPlacement } from '@component/shared/button/button.component.view'
import { FirstLevelDir, OwlRoutes, SecondLevelDir } from '@domain/route/app-routes.domain'
import { mockUnknownLogicalId, mockUnknownSchoolIntId } from '@mock/school.mock'
import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import { ChatMessageDtoPayload } from '@model/message.model'
import {
  GetPredefinedGroupDto,
  MessageTypeEnum,
  PredefinedMessageType
} from '@model/message/predefined-message.model'
import { PollType } from '@model/poll.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { SignalrMessageType } from '@model/signalr/signalr.model'
import {
  GetUserDto,
  MobileUserTypes,
  SchoolIds,
  UserDtoProps,
  mobileUserTypesSelectDisplayText
} from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { selectGlobalState, selectOwlSchoolStateProperty } from '@selector/auth.selector'
import {
  selectActiveChatroomId,
  selectChatRoomScrollData,
  selectChatRoomSelectVm,
  selectChatUiIsVisible,
  selectChatUiMenuIsOpen,
  selectChatroomButtons,
  selectCreatePollView,
  selectCurrentChatRoomButtonVm,
  selectCurrentChatRoomType,
  selectGroupedPredefinedMessagesByCurrentView,
  selectIsOneOnOneChatRoom,
  selectIsUserTypeSelected,
  selectMessageHeights,
  selectNumberOfChatRoomMessages,
  selectOtherStaffButton,
  selectSelectedChatroomLabel,
  selectSelectedGroupedPredefinedMessages,
  selectSelectedPredefinedItemId,
  selectSelectedPredefinedMessageTypeForSelection,
  selectShowChatList,
  selectShowChatUi,
  selectShowMessageSelection,
  selectShowPollSelection,
  selectStudentsButton,
  selectTeachersButton,
  selectVisibleChatListsVms,
  selectVisibleChatMessageVms,
  selectVisibleChatRoomHasNoMessages
} from '@selector/page/dashboard-page/chat-ui-selectors'
import {
  dashReadyToBeShown,
  selectAreaStatusLookup,
  selectChatVm,
  selectDashChatError,
  selectDashChatLoading,
  selectDashboardPageState,
  selectIdsForDashWhenAllDataLoaded,
  selectMapVisibleByUserClick,
  selectSideNavIsCollapsed,
  selectUserToTypeLookup
} from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import {
  selectIsShownAreaPanel,
  selectSideNavSettings
} from '@selector/page/dashboard-page/dashboard-view.selectors'
import { AppComponentService } from '@service/app.component.service'
import { OwlLogLevels, OwlLoggingArea } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import { MIN_MAIN_UI_STATE_UPDATE_THROTTLE_TIME } from '@shared/constants'
import { GlobalStateProps } from '@state/auth.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { IChatRoomViewModel } from '@view/chat/chat-room.view'
import { OwlChatValidationsViewModel } from '@view/chat/chat-validations.view'
import { ChatRoomTypeEnum, ScrollPlacesInChatRoom } from '@view/chat/chat.view'
import { ChatUiItemViewModel } from '@view/messages/message.view'
import {
  IAttackResponseEventTypeViewModel,
  IMapUiControlsViewModel,
  ISiteLinkViewModel,
  MapUiControlEnum,
  SideNavLinkIcons,
  UserTypeSelectViewModel,
  mapUiControlToDisplayTextMap
} from '@view/pages/dashboard-page/dashboard-page.view'
import { Observable, Subscription, debounceTime, map, of, tap } from 'rxjs'

// TODO Convert name into app side nav if shown on other pages.
@Injectable()
export class DashboardPageComponentService {
  /** Dashboard ready to be shown, once necessary data is loaded, like school dto, users, and predefined messages/groups. Additionally, deboundtime is used so the UI state change is throttled to ensure the authentication spinner doesn't look glitchy */
  pageReadyToBeShown$ = this.store
    .select(dashReadyToBeShown)
    .pipe(debounceTime(MIN_MAIN_UI_STATE_UPDATE_THROTTLE_TIME))

  coverImageSrc = './assets/full-size/routine-cover.png'
  innerWindowHeight = window.innerHeight
  innerWindowWidth = window.innerWidth / 3
  sideNavCollapsed$ = this.store.select(selectSideNavIsCollapsed)
  chatUiIsVisible$ = this.store.select(selectChatUiIsVisible)
  showChatList$ = this.store.select(selectShowChatList)
  loading$ = this.store.select(selectDashChatLoading)
  error$ = this.store.select(selectDashChatError)

  authState$ = this.store.select(selectGlobalState)
  selectedSchoolId$ = this.store.select(selectIdsForDashWhenAllDataLoaded)
  userDto?: GetUserDto

  chatVmState$ = this.store.select(selectChatVm)
  isTeachersButtonEnabled?: boolean
  isOtherStaffButtonEnabled?: boolean
  isStudentsButtonEnabled?: boolean
  isOneOnOneChatRoom?: boolean

  dashboardPageState$ = this.store.select(selectDashboardPageState)

  areaPanelState$ = this.store.select(selectIsShownAreaPanel)
  arrayOfAreaPanelItems$ = this.store
    .select(selectAreaStatusLookup)
    .pipe(map(AreaStatusViewModel.getCollectionFromVm))

  //SCHOOL STATE
  schoolState$ = this.store.select(selectOwlSchoolStateProperty)
  mapVisibleByUserAction$ = this.store.select(selectMapVisibleByUserClick)
  schoolStateIsEmergency$ = this.schoolState$.pipe(map((s) => s === SchoolStateEnum.emergency))
  schoolStateIsRoutine$ = this.schoolState$.pipe(map((s) => s === SchoolStateEnum.routine))

  //SCHOOL PREFERENCE
  /** You can only set the school preference when the school is in a routine state. TODO Verify this domain logic*/
  togglePreferenceDisabled$ = this.schoolState$.pipe(
    map((s) => s === SchoolStateEnum.emergency || s === SchoolStateEnum.suspected)
  )

  //CHAT UI Section
  currentlySelectedChatroomId$ = this.store.select(selectActiveChatroomId)
  isOneOnOneChatRoom$ = this.store.select(selectIsOneOnOneChatRoom)
  selectChatUiMenuIsOpen$ = this.store.select(selectChatUiMenuIsOpen)
  selectCurrentChatRoomAttributes$ = this.store.select(selectCurrentChatRoomButtonVm)
  selectCurrentChatRoomType$ = this.store.select(selectCurrentChatRoomType)
  selectChatRoomSelectVm$ = this.store.select(selectChatRoomSelectVm)
  selectNumberOfChatRoomMessages$ = this.store.select(selectNumberOfChatRoomMessages)
  chatroomButtons$ = this.store.select(selectChatroomButtons)
  selectSelectedChatroomLabel$ = this.store.select(selectSelectedChatroomLabel)
  selectVisibleChatRoomHasNoMessages$ = this.store.select(selectVisibleChatRoomHasNoMessages)
  chatroomMessages$ = this.store.select(selectVisibleChatMessageVms)
  chatLists$ = this.store.select(selectVisibleChatListsVms)
  messageHeights$ = this.store.select(selectMessageHeights)
  selectUserToTypeLookup$ = this.store.select(selectUserToTypeLookup)
  selectChatRoomScrollData$ = this.store.select(selectChatRoomScrollData)
  // Chat UI Overlays
  selectShowMessageSelection$ = this.store.select(selectShowMessageSelection)
  selectShowPollSelection$ = this.store.select(selectShowPollSelection)
  selectCreatePollView$ = this.store.select(selectCreatePollView)
  selectSelectedPredefinedMessageTypeForSelection$ = this.store.select(
    selectSelectedPredefinedMessageTypeForSelection
  )
  selectTeachersButton$ = this.store.select(selectTeachersButton)
  selectOtherStaffButton$ = this.store.select(selectOtherStaffButton)
  selectStudentsButton$ = this.store.select(selectStudentsButton)
  selectIsUserTypeSelected$ = this.store.select(selectIsUserTypeSelected)
  // polls
  selectGroupedPredefinedMessages$ = this.store.select(selectGroupedPredefinedMessagesByCurrentView)
  selectSelectedGroupedPredefinedMessages$ = this.store.select(
    selectSelectedGroupedPredefinedMessages
  )
  selectedPredefinedItemId$ = this.store.select(selectSelectedPredefinedItemId)
  // Main Chat UI
  selectShowChatUi$ = this.store.select(selectShowChatUi)

  //Local Ref to User Specified data
  text: string = ''
  messageTypeRadioToggleValue: MessageTypeEnum = MessageTypeEnum.update

  get isNonOneOnOneChat(): boolean {
    return this.currentChatRoomButtonVm?.type !== ChatRoomTypeEnum.oneOnOne
  }
  get isInstruction(): boolean {
    return this.messageTypeRadioToggleValue === MessageTypeEnum.instruction
  }
  get isUpdate(): boolean {
    return this.messageTypeRadioToggleValue === MessageTypeEnum.update
  }
  subs: Subscription[] = []

  currentlySelectedSchoolIds: SchoolIds | null = null

  constructor(
    public appCompServ: AppComponentService,
    private store: Store,
    private loggingService: LoggingService
  ) { }

  /** User Type Selection Section */
  //#region
  toggleTeachersTypeButton = () => {
    this.store.dispatch(toggleTeachersTypeButton())
  }
  toggleOtherStaffTypeButton = () => {
    this.store.dispatch(toggleOtherStaffTypeButton())
  }
  toggleStudentsTypeButton = () => {
    this.store.dispatch(toggleStudentsTypeButton())
  }
  callUserTypeSelectByType = (type: MobileUserTypes): void => {
    switch (type) {
      case MobileUserTypes.teacher:
        this.toggleTeachersTypeButton()
        break
      case MobileUserTypes.otherStaff:
        this.toggleOtherStaffTypeButton()
        break
      case MobileUserTypes.student:
        this.toggleStudentsTypeButton()
        break
    }
  }

  getSelectorForUserType = (type: MobileUserTypes): Observable<boolean> => {
    switch (type) {
      case MobileUserTypes.teacher:
        return this.selectTeachersButton$
      case MobileUserTypes.otherStaff:
        return this.selectOtherStaffButton$
      case MobileUserTypes.student:
        return this.selectStudentsButton$
      default:
        return of(false)
    }
  }
  /** A collection of vms created to simplify changes and additions/subtractions based on config. */
  userTypeSelectVms: UserTypeSelectViewModel[] = [
    MobileUserTypes.teacher,
    MobileUserTypes.otherStaff,
    MobileUserTypes.student
  ].map((userType) => ({
    cb: () => this.callUserTypeSelectByType(userType),
    displayText: mobileUserTypesSelectDisplayText[userType],
    isSelected$: this.getSelectorForUserType(userType),
    userType
  }))
  //#endregion

  selectAttackTypeItems: IAttackResponseEventTypeViewModel[] = [
    {
      text: 'Shooting',
      icon: AttackerTypeEnum.shooting,
      cb: () => this.showTransitionSchoolStateModal(AttackerTypeEnum.shooting)
    },
    {
      text: 'Knife Attack',
      icon: AttackerTypeEnum.knifeAttack,
      cb: () => this.showTransitionSchoolStateModal(AttackerTypeEnum.knifeAttack)
    }
  ]
  selectResponseTypeItems: IAttackResponseEventTypeViewModel[] = [
    {
      text: 'Lock Down',
      icon: EmergencyResponeEnum.lockDown,
      cb: () => this.showTransitionSchoolStateModal(EmergencyResponeEnum.lockDown)
    },
    {
      text: 'Secure',
      icon: EmergencyResponeEnum.secure,
      cb: () => this.showTransitionSchoolStateModal(EmergencyResponeEnum.secure)
    },
    {
      text: 'Hold',
      icon: EmergencyResponeEnum.hold,
      cb: () => this.showTransitionSchoolStateModal(EmergencyResponeEnum.hold)
    }
  ]
  //TODO Add a condition based on url selector to show this sidenav link, by adding is visible to the ISiteLinkViewModel
  links: ISiteLinkViewModel[] = [
    {
      text: 'Show Map',
      icon: SideNavLinkIcons.showMap,
      iconPlacement: ButtonIconPlacement.left,
      isLink: false,
      variant: ExpandedLinkVariant.sideNav,
      cb: () => this.store.dispatch(toggleShowMap()),
      isEnabled$: of(true)
    },
    {
      link: OwlRoutes.firstLevelRoutes.setupSchoolAreasMap,
      // School settings defaults to the map config page, and the header there will be used to go to the other settings like user roster, and instructions
      text: 'School Settings',
      iconPlacement: ButtonIconPlacement.left,
      isLink: true,
      icon: SideNavLinkIcons.schoolMapConfig,
      variant: ExpandedLinkVariant.sideNav,
      isEnabled$: this.appCompServ.isLinkEnabled(OwlRoutes.firstLevelRoutes.setupSchoolAreasMap)
    },
    {
      link: OwlRoutes.firstLevelRoutes.events,
      text: 'Debrief Event',
      iconPlacement: ButtonIconPlacement.left,
      isLink: true,
      icon: SideNavLinkIcons.communication,
      variant: ExpandedLinkVariant.sideNav,
      isEnabled$: this.appCompServ.isLinkEnabled(OwlRoutes.firstLevelRoutes.events)
    }
  ]
  init = () => {
    this.subs = [
      this.selectCurrentChatRoomAttributes$.subscribe(this.handleSelectedChatRoom),
      this.selectedSchoolId$
        .pipe(
          tap((schoolIds) => {
            if (
              schoolIds &&
              schoolIds.id !== mockUnknownSchoolIntId &&
              schoolIds.logicalId !== mockUnknownLogicalId &&
              // We can fetch data for this school if the selected school id changes or if this is the first page load
              (this.currentlySelectedSchoolIds == null ||
                this.currentlySelectedSchoolIds?.id !== schoolIds.id)
            ) {
              this.loggingService.logLocally(
                `Dispatching school related data fetch actions, HTTP and Websocket!`,
                OwlLoggingArea.ROUTING,
                OwlLogLevels.VERBOSE
              )
              // Once we have the school state we can display the related Rive animation
              const { id } = schoolIds
              this.appCompServ.initRive()
              this.store.dispatch(getHistoricChatmessages(getExtendedAction(id)))
              this.store.dispatch(getHistoricUserLocations(getExtendedAction(id)))
              this.currentlySelectedSchoolIds = schoolIds
            }
          })
        )
        .subscribe(),
      this.chatVmState$
        .pipe(
          tap((state) => {
            this.isTeachersButtonEnabled = state.isTeachersButtonEnabled
            this.isOtherStaffButtonEnabled = state.isOtherStaffButtonEnabled
            this.isStudentsButtonEnabled = state.isStudentsButtonEnabled
          })
        )
        .subscribe(),
      this.authState$
        .pipe(tap((authState) => (this.userDto = authState[GlobalStateProps.userDto])))
        .subscribe()
    ]
  }
  destroy = () => {
    this.appCompServ.destroyRive()
    this.currentlySelectedSchoolIds = null
    this.subs.forEach((s) => (!s.closed ? s.unsubscribe() : null))
  }
  updateWindowSize() {
    this.innerWindowHeight = window.innerHeight
    this.innerWindowWidth = window.innerWidth / 3
  }

  //CHAT UI
  //#region
  currentChatRoomButtonVm?: IChatRoomViewModel
  handleSelectedChatRoom = (chatButtonVm: IChatRoomViewModel | null) => {
    if (!chatButtonVm) {
      return
    }
    // console.log(`Selected chat room attributes`)
    // console.table(chatButtonVm)
    this.currentChatRoomButtonVm = chatButtonVm
    this.isOneOnOneChatRoom = chatButtonVm.type === ChatRoomTypeEnum.oneOnOne
  }
  handleLeftClick = () => {
    this.store.dispatch(handleChatRoomChangeLeft())
  }
  handleRightClick = () => {
    this.store.dispatch(handleChatRoomChangeRight())
  }
  roomIsSelected = (room: IChatRoomViewModel): boolean => {
    return this.currentChatRoomButtonVm?.chatRoomId === room.chatRoomId
  }
  updateMenuState = () => {
    this.store.dispatch(toggleChatUiMenuVisibility())
  }
  handleSelectMessageClick = () => {
    this.store.dispatch(handleSelectMessageClick())
  }
  handleSelectPollClick = () => {
    this.store.dispatch(handleSelectPollClick())
  }
  handleCreatePollClick = () => {
    this.store.dispatch(handleCreatePollClick())
  }
  toggleChatUiVisibility = () => {
    this.store.dispatch(toggleChatUiVisibility())
  }
  handleShowChatListClick = (chatRoomId: string) => {
    this.store.dispatch(handleShowChatListClick(getExtendedAction(chatRoomId)))
  }
  handleChatroomChange = (v: string) => {
    this.store.dispatch(setChatRoomById(getExtendedAction(v)))
  }
  handleGoToChat = (vm: ChatUiItemViewModel) => {
    // console.log(`Clicked go to chat for vm`)
    // console.log(vm)
    this.store.dispatch(setChatRoomById(getExtendedAction(vm.chatRoomId ?? '0')))
  }
  handleSetScrollPlacesInChatRoom = (p: ScrollPlacesInChatRoom) => {
    this.store.dispatch(setScrollPlacesInChatRoom(getExtendedAction(p)))
  }
  setScrollPlacesInLocalStorage = (p: boolean) => {
    if (p) {
      this.store.dispatch(setScrollPlacesInLocalStorage(getExtendedAction(p)))
    }
  }
  //TODO: add sending multiple messages
  handleSendMessage = (groupedPredefinedMessages?: GetPredefinedGroupDto) => {
    // const selectedSchool = UserDtoHelper.getDefaultSchoolId(this.userDto?.schoolIds)
    if (!this.currentlySelectedSchoolIds) {
      return
    }
    if (!this.currentChatRoomButtonVm) {
      console.warn(
        `Not reference to chat room button view model - which contains attributes needed to send a message`
      )
      return
    }

    const isPredefinedPoll = groupedPredefinedMessages?.type === PredefinedMessageType.poll
    const now = new Date(new Date().toUTCString())
    // const isOneOnOneChat = this.currentChatRoomButtonVm.type === ChatRoomTypeEnum.oneOnOne
    const isSubArea = this.currentChatRoomButtonVm.type === ChatRoomTypeEnum.subarea
    let messageTypeToUse: MessageTypeEnum = MessageTypeEnum.chat
    if (!this.isOneOnOneChatRoom) {
      messageTypeToUse = this.messageTypeRadioToggleValue
    }
    // const isEveryoneChat = this.currentChatRoomButtonVm.type === ChatRoomTypeEnum.everyone
    const dto: ChatMessageDtoPayload = {
      schoolId: this.currentlySelectedSchoolIds.logicalId ?? mockUnknownLogicalId,
      groupId: groupedPredefinedMessages ? groupedPredefinedMessages.groupId : null,
      message: groupedPredefinedMessages ? groupedPredefinedMessages.messages[0].text : this.text,
      chatRoomId: this.currentChatRoomButtonVm.chatRoomId,
      timestamp: now.toISOString(),
      pollType: isPredefinedPoll ? PollType.predefined : null,
      rulesEngine: false,
      autoSent: false,
      messageType: groupedPredefinedMessages?.messageType ?? messageTypeToUse,
      proximityDistance: null,
      responses: isPredefinedPoll ? groupedPredefinedMessages.messages[0].responses : null,
      logicalId: isPredefinedPoll ? crypto.randomUUID() : null,
      eventId: null,
      areaLogicalId: isSubArea ? this.currentChatRoomButtonVm.areaLogicalId : null,
      userTypeFilter: [],
      boundary: null,
      mobileUserIds: this.currentChatRoomButtonVm.mobileUserIds,
      pollToUserIds: null,
      senderId: this.userDto?.[UserDtoProps.id]
    }
    if (!this.isOneOnOneChatRoom) {
      if (this.isTeachersButtonEnabled) {
        dto.userTypeFilter?.push(MobileUserTypes.teacher)
      }
      if (this.isOtherStaffButtonEnabled) {
        dto.userTypeFilter?.push(MobileUserTypes.otherStaff)
      }
      if (this.isStudentsButtonEnabled) {
        dto.userTypeFilter?.push(MobileUserTypes.student)
      }
    }
    // console.log(`Sending dto manually`, dto)
    if (isPredefinedPoll) {
      this.store.dispatch(sendMessage(getExtendedAction(dto)))
    } else {
      this.store.dispatch(
        sendSignalrChatMessage(
          getExtendedAction({
            type: SignalrMessageType.chatMessage,
            payload: dto
          })
        )
      )
    }
    this.text = ''
  }
  //Input valudation
  public inputIsValid = (): boolean => {
    if (this.isNonOneOnOneChat && this.isInstruction) {
      return OwlChatValidationsViewModel.validateInstruction(
        this.text,
        this.appCompServ.appConfig.config
      )
    }
    if (this.isNonOneOnOneChat && this.isUpdate) {
      return OwlChatValidationsViewModel.validateUpdate(
        this.text,
        this.appCompServ.appConfig.config
      )
    }
    return OwlChatValidationsViewModel.isNotEmptyMessage(this.text)
  }
  //#endregion

  //DASHBOARD SIDE NAV and Select event UI
  //#region

  //SCHOOL STATE
  dispatchAttackTypeEmergency = (t: EmergencyResponeEnum | AttackerTypeEnum) => {
    this.store.dispatch(showOwlSchoolStateChangeModal(getExtendedAction(t)))
  }
  showTransitionSchoolStateModal = (
    t: SchoolStateTransitionEnum | EmergencyResponeEnum | AttackerTypeEnum
  ) => {
    this.store.dispatch(showOwlSchoolStateChangeModal(getExtendedAction(t)))
  }
  sideNaveSettings$ = this.store.select(selectSideNavSettings)
  //MAP CONTROLS UI SECTION
  selectEnabledMapUiByProp(prop: MapUiControlEnum): Observable<boolean> {
    return this.sideNaveSettings$.pipe(map((settings) => (settings ? settings[prop] : false)))
  }
  getObservableForMapControlUiText(prop: MapUiControlEnum): Observable<string> {
    return this.sideNaveSettings$.pipe(
      map((settings) => (settings ? this.getDisplayTextForMapUiControl(settings[prop], prop) : ''))
    )
  }
  getDisplayTextForMapUiControl(enabled: boolean, prop: MapUiControlEnum): any {
    return `${enabled ? 'Hide' : 'Show'} ${mapUiControlToDisplayTextMap[prop]}`
  }
  dispatchToggleMapUiControl(mc: MapUiControlEnum) {
    this.store.dispatch(toggleMapUiControl(getExtendedAction(mc)))
  }
  getMapUiControlsVmFromProp = (prop: MapUiControlEnum): IMapUiControlsViewModel => ({
    text$: this.getObservableForMapControlUiText(prop),
    icon: prop,
    cb: () => this.dispatchToggleMapUiControl(prop),
    enabled$: this.selectEnabledMapUiByProp(prop)
  })
  mapUiControls: IMapUiControlsViewModel[] = Object.values(MapUiControlEnum)
    // Filter out guests until that feature is developed
    .filter((mc) => mc !== MapUiControlEnum.showGuests)
    .map(this.getMapUiControlsVmFromProp)
  //#endregion

  //CHAT UI
  handleSelectPredefinedItemId = (id: number) => {
    this.store.dispatch(handleSelectPredefinedItemId(getExtendedAction(id)))
  }
  //Zoom
  handleZoomInClick = () => {
    this.store.dispatch(handleZoomInClick())
  }
  handleZoomOutClick = () => {
    this.store.dispatch(handleZoomOutClick())
  }
  handleHomeClick = () => {
    this.store.dispatch(handleHomeClick())
  }
}
