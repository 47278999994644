import { Injectable } from '@angular/core'
import { CanActivate, CanMatch } from '@angular/router'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { Store } from '@ngrx/store'
import { selectGlobalState } from '@selector/auth.selector'
import { GlobalStateProps } from '@state/auth.state'
import { Observable, map } from 'rxjs'

/** We can only let a user visit the map to draw areas once they've selected a school.*/
@Injectable()
export class SchoolDashboardGuard implements CanActivate, CanMatch {
  constructor(private store: Store) { }

  canMatch(): Observable<boolean> {
    return this.accessControlLogic
  }
  canActivate(): Observable<boolean> {
    return this.accessControlLogic
  }
  accessControlLogic = this.store.select(selectGlobalState).pipe(
    map((authState) => {
      const schoolDto = authState[GlobalStateProps.userSchoolLookup][authState.selectedSchoolId.id]
      const userDtos = authState[GlobalStateProps.usersForSchoolLookup][authState.selectedSchoolId.id]
      if (!schoolDto || !userDtos) return false
      return SchoolDtoHelper.canShowDashboard(schoolDto, userDtos)
    })
  )
}
