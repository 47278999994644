import * as geomtery from "@arcgis/core/geometry";
import { Geometry } from "@arcgis/core/geometry";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { project } from "@arcgis/core/geometry/support/webMercatorUtils";



export class ArcGisMapProjectionViewModel {
    static arcGisTransformTo900913(g: geomtery.Point): any {
        return webMercatorUtils.geographicToWebMercator(g);
    }
    static arcGisTransformTo4326(g: number[], swapLatLong = false): any {
        // TODO integrate lat swap for long to support schema that Balcony is ued to
        // const result = webMercatorUtils.canProject(g, new geomtery.SpatialReference({ wkid: 4326 }));
        try {
            const newlonLat = webMercatorUtils.xyToLngLat(g[0], g[1])
            return swapLatLong ?
                [newlonLat[0], newlonLat[1]] : [newlonLat[1], newlonLat[0]]
        } catch (e) {
            console.warn("couldn't project point to wkid 4326")
            return g
        }
    }
    static projectToSpatialReference = (g: __esri.Geometry, spatialReference: geomtery.SpatialReference) => {
        // Project the geomtery to the desired spatial reference (e.g., WGS84)
        return project(g, spatialReference);
    }
}
