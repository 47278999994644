import {
  PictureMarkerSymbol,
  SimpleFillSymbol,
  SimpleLineSymbol,
  SimpleMarkerSymbol
} from '@arcgis/core/symbols'
import { MobileUserShownOnMap, MobileUserTypes } from '@model/user/user.model'
import { StatusColorEnum } from '@view/area/area.view'
import { RecentMessageIndicatorTypeEnum } from '@view/area/graphic-attributes.model'
import { OwlMapPolygonColor } from '@view/map/map.view'
import Color from '@arcgis/core/Color.js'
import { AreaStatusViewModel } from '@view/area/area-status.view'

export type SymbolStyle =
  | 'backward-diagonal'
  | 'cross'
  | 'diagonal-cross'
  | 'forward-diagonal'
  | 'horizontal'
  | 'none'
  | 'solid'
  | 'vertical'
export enum SymbolStyleEnum {
  backwardDiagonal = 'backward-diagonal',
  cross = 'cross',
  diagonalCross = 'diagonal-cross',
  forwardDiagonal = 'forward-diagonal',
  horizontal = 'horizontal',
  none = 'none',
  solid = 'solid',
  vertical = 'vertical'
}

export class ArcGisSymbolFactory {
  static THREAT_OPACITY = 0.1
  static AREA_OPACITY = 0.16
  static LOCATION_OPACITY = 0.16
  static MAP_CONFIG_OPACITY = 0.32

  static SINGLE_THREAT_LOCATION_SIZE = 64
  static SINGLE_ALERT_LOCATION_SIZE = 12

  /** Utility to create a SimpleFillSymbol
   * @param color
   * @param style
   * @param width
   * @param outlineColor
   */
  static getStyle = (
    color: OwlMapPolygonColor,
    style: SymbolStyleEnum,
    width: number,
    outlineColor: OwlMapPolygonColor
  ) => {
    return new SimpleFillSymbol({
      style,
      color,
      outline: {
        color: outlineColor,
        width: width
      }
    })
  }

  getPictureMarkerSymbol = (url: string, width: number, height: number) => {
    // console.log(url)
    return new PictureMarkerSymbol({ url, width, height })
  }

  /** Here to use the
   * @prop recentMessageIndicator of DecoratedUserLocationViewModel
   * @type RecentMessageIndicatorTypeEnum in vm to return ArcGis Symbol  */
  static getSymbolForRecentMessageIndicatorByVm = (
    type: RecentMessageIndicatorTypeEnum
  ): __esri.SimpleMarkerSymbol | __esri.PictureMarkerSymbol => {
    switch (type) {
      case RecentMessageIndicatorTypeEnum.alert:
        return ArcGisSymbolFactory.alertPollResponseRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.medicalAlert:
        return ArcGisSymbolFactory.medicalAlertPollResponseRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.chatMessage:
        return ArcGisSymbolFactory.regularRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.redWithMessagePoll:
        return ArcGisSymbolFactory.redMessageResponseRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.yellowWithMessagePoll:
        return ArcGisSymbolFactory.yellowMessageResponseRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.redPoll:
        return ArcGisSymbolFactory.redPollResponseRecentMessageSymbol
      case RecentMessageIndicatorTypeEnum.yellowPoll:
        return ArcGisSymbolFactory.yellowPollResponseRecentMessageSymbol
      default:
        return ArcGisSymbolFactory.hiddenSymbol
    }
  }

  /** Size of opaque red circle defined by input param */
  static threatRadiusColor = [204, 51, 51, ArcGisSymbolFactory.THREAT_OPACITY]
  static getFillSymbolForThreat = (): __esri.SimpleFillSymbol => {
    return new SimpleFillSymbol({
      color: ArcGisSymbolFactory.threatRadiusColor,
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  /** For use in the global highlight handling */
  static getHighlightOptions = (): __esri.HighlightOptions => {
    return {
      color: new Color(ArcGisSymbolFactory.editBoundaryColor),
      haloOpacity: 0.9,
      fillOpacity: 0.2,
      haloColor: new Color(ArcGisSymbolFactory.editBoundaryColor),
      shadowColor: new Color(ArcGisSymbolFactory.editBoundaryColor),
      shadowDifference: 0.1,
      shadowOpacity: 0.1
    }
  }
  /** For use in the global highlight handling */
  static getFailedEditHighlightOptions = (): __esri.HighlightOptions => {
    return {
      color: new Color(ArcGisSymbolFactory.areaValidationFailedColor),
      haloOpacity: 0.9,
      fillOpacity: 0.2,
      haloColor: new Color(ArcGisSymbolFactory.areaValidationFailedColor),
      shadowColor: new Color(ArcGisSymbolFactory.areaValidationFailedBorderColor),
      shadowDifference: 0.1,
      shadowOpacity: 0.1
    }
  }
  static getSchoolBoundarySymbol = (): SimpleFillSymbol => {
    return this.getStyle(
      this.mapConfigSchoolBoundaryDisplayColor,
      SymbolStyleEnum.solid,
      this.mapConfigSchoolBoundaryBorderWidth,
      this.mapConfigSchoolBoundaryDisplayBorderColor
    )
  }
  /**
   *
   * @param isSelectedArea Based on data in state
   * @param statusVm From lookup of area status view model in state
   * @returns returns the appropriate symbol for the area
   */
  static getSchoolAreaSymbol = (
    isSelectedArea: boolean,
    statusVm: AreaStatusViewModel,
    isHover: boolean = false
  ): SimpleFillSymbol => {
    let symbol: __esri.SimpleFillSymbol
    switch (statusVm.currentStatusColor) {
      case StatusColorEnum.unknown:
        if (isSelectedArea) {
          symbol = ArcGisSymbolFactory.noStatusSelectedAreaSymbol
        } else if (isHover) {
          symbol = ArcGisSymbolFactory.noStatusHoverAreaSymbol
        } else {
          symbol = ArcGisSymbolFactory.noStatusAreaSymbol
        }
        break
      case StatusColorEnum.green:
        if (isSelectedArea) {
          symbol = ArcGisSymbolFactory.greenSelectedAreaSymbol
        } else if (isHover) {
          symbol = ArcGisSymbolFactory.greenHoverAreaSymbol
        } else {
          symbol = ArcGisSymbolFactory.greenAreaSymbol
        }
        break
      case StatusColorEnum.yellow:
        if (isSelectedArea) {
          symbol = ArcGisSymbolFactory.yellowSelectedAreaSymbol
        } else if (isHover) {
          symbol = ArcGisSymbolFactory.yellowHoverAreaSymbol
        } else {
          symbol = ArcGisSymbolFactory.yellowAreaSymbol
        }
        break
      case StatusColorEnum.red:
        if (isSelectedArea) {
          symbol = ArcGisSymbolFactory.redSelectedAreaSymbol
        } else if (isHover) {
          symbol = ArcGisSymbolFactory.redHoverAreaSymbol
        } else {
          symbol = ArcGisSymbolFactory.redAreaSymbol
        }
        break
      default:
        symbol = ArcGisSymbolFactory.noStatusAreaSymbol
        break
    }
    // console.log(`Color for status color ${statusVm.currentStatus} is hover state ${isHover}`)
    // console.log(`New symbol outline
    // color ${symbol?.outline?.color}
    // width ${symbol?.outline?.width}
    // `)
    return symbol
  }

  //STATUS BASED COLORS

  //#region
  static sharedAreaBorderWidth = 1
  static selectedAreaBorderWidth = 2
  static hoverColor = [0, 0, 0]
  static hoverOutline = new SimpleLineSymbol({
    color: ArcGisSymbolFactory.hoverColor,
    width: ArcGisSymbolFactory.selectedAreaBorderWidth
  })

  //NO STATUS
  static noStatusAreaColor = [171, 171, 172, ArcGisSymbolFactory.AREA_OPACITY]
  static noStatusHoveredOutlineColor = [144, 145, 146]
  static noStatusSelectedOutlineColor = [94, 94, 96]
  static noStatusSelectedOutlineColorReduced = [94, 94, 96, 0.1]
  static noStatusOutlineColor = [198, 198, 199]
  // TODO Where did this rgb come from - i converted our styles rgb and got the above
  // let's double check this
  // static noStatusHoverOutlineColor = [128, 128, 128]
  static noStatusAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.noStatusAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.noStatusOutlineColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  static noStatusHoverAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.noStatusAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.noStatusHoveredOutlineColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  static noStatusSelectedAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.noStatusAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.noStatusSelectedOutlineColor,
      width: ArcGisSymbolFactory.selectedAreaBorderWidth
    })
  })
  //NO STATUS COLOR
  static firstPollResponseColor = [0, 128, 128]
  static secondPollResponseColor = [89, 134, 178]
  static thirdPollResponseColor = [204, 109, 20]
  static fourthPollResponseColor = [128, 0, 128]
  //GREEN STATUS
  static greenPollResponseColor = [119, 157, 45]
  static greenAreaColor = [147, 194, 56, ArcGisSymbolFactory.AREA_OPACITY]
  static greenBorderColor = [140, 185, 54]
  static greenSelectedBorderColor = [78, 102, 30]
  static greenSelectedBorderColorReduced = [78, 102, 30, 0.1]
  static greenHoveredOutlineColor = [119, 157, 45]
  static greenAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.greenAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.greenBorderColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  static greenSelectedAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.greenAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.greenSelectedBorderColor,
      width: ArcGisSymbolFactory.selectedAreaBorderWidth
    })
  })
  static greenHoverAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.greenAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.greenHoveredOutlineColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  //YELLOW STATUS
  // static yellowPollResponse = [193, 133, 24] - Multiple yellow variants in designs TODO figure out what color this is supposed to be
  static yellowPollResponseColor = [227, 187, 30]
  static yellowAreaColor = [228, 157, 29, ArcGisSymbolFactory.AREA_OPACITY]
  static yellowBorderColor = [212, 179, 0]
  static yellowSelectedBorderColor = [126, 87, 16]
  static yellowSelectedBorderColorReduced = [126, 87, 16, 0.1]
  static yellowHoverBorderColor = [170, 143, 0]
  static yellowAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.yellowAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.yellowBorderColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  static yellowSelectedAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.yellowAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.yellowSelectedBorderColor,
      width: ArcGisSymbolFactory.selectedAreaBorderWidth
    })
  })
  static yellowHoverAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.yellowAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.yellowHoverBorderColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  //MAP CONFIG
  // area validation
  static areaOriginalValidationFailedColor: OwlMapPolygonColor = [204, 51, 51, 1]
  static areaValidationFailedBorderColor: OwlMapPolygonColor = [204, 51, 51, 1]
  static areaValidationFailedColor: OwlMapPolygonColor = [204, 51, 51, this.MAP_CONFIG_OPACITY]
  //RED STATUS
  static redPollResponseColor: OwlMapPolygonColor = [204, 51, 51, 1]
  static redAreaColor: OwlMapPolygonColor = [204, 51, 51, ArcGisSymbolFactory.AREA_OPACITY]
  static redBorderColor: OwlMapPolygonColor = [224, 133, 133, 1]
  static redSelectedBorderColor: OwlMapPolygonColor = [163, 41, 41, 1]
  static redSelectedBorderColorReduced: OwlMapPolygonColor = [163, 41, 41, 0.1]
  static redHoverBorderColor: OwlMapPolygonColor = [204, 51, 51, 1]
  static redAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.redAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.redBorderColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  static redSelectedAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.redAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.redSelectedBorderColor,
      width: ArcGisSymbolFactory.selectedAreaBorderWidth
    })
  })
  static redHoverAreaSymbol = new SimpleFillSymbol({
    color: ArcGisSymbolFactory.redAreaColor,
    outline: new SimpleLineSymbol({
      color: ArcGisSymbolFactory.redHoverBorderColor,
      width: ArcGisSymbolFactory.sharedAreaBorderWidth
    })
  })
  //#endregion

  //SECTION FOR CONTENT SYMBOLs
  // todo pass in status of poll or grey if no associated color
  static pollResponseLocationSize = 8
  static getStatusUpdateSymbol = (statusColor: StatusColorEnum) => {
    switch (statusColor) {
      case StatusColorEnum.yellow:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.yellowPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.red:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.redPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.green:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.greenPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.first:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.firstPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.second:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.secondPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.third:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.thirdPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
      case StatusColorEnum.fourth:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.fourthPollResponseColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })

      // case StatusColorEnum.unknown:
      default:
        return new SimpleMarkerSymbol({
          style: 'circle',
          color: ArcGisSymbolFactory.noStatusSelectedOutlineColor,
          size: this.pollResponseLocationSize,
          outline: ArcGisSymbolFactory.getDefaultOutline()
        })
    }
  }

  //SECTION FOR POINT SYMBOLS
  /** This provides symbols specific to a mobile type, additionally if applies a style if selected */
  //TODO: refactor when all location styles will be in design
  static getSymbolForMobileUser = (
    t: MobileUserTypes | undefined,
    selected: boolean = false,
    hover: boolean = false
  ) => {
    switch (t) {
      case MobileUserTypes.teacher:
        return ArcGisSymbolFactory.getSymbolForTeacher(selected, hover)
      case MobileUserTypes.student:
        return ArcGisSymbolFactory.getSymbolForStudent(selected, hover)
      case MobileUserTypes.guest:
        return ArcGisSymbolFactory.getSymbolForGuest(selected, hover)
      case MobileUserTypes.otherStaff:
        return ArcGisSymbolFactory.getSymbolForOtherStaff(selected, hover)
      default:
        return ArcGisSymbolFactory.getTransparentSymbol(selected, hover)
    }
  }
  static getSymbolForTeacher = (selected: boolean, hover: boolean, opaque = false) => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: [85, 172, 190, opaque ? ArcGisSymbolFactory.LOCATION_OPACITY : 1],
      size: ArcGisSymbolFactory.getLocationSize(selected, hover),
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  //CUSTOM MARKER TEMPLATE
  // return new SimpleMarkerSymbol({
  //     color: [226, 119, 40],  // Orange
  //     outline: {
  //         color: [255, 255, 255], // White
  //         width: 1
  //     }
  // })
  static selectedColor = () => [255, 0, 0, 0.8]
  static selectedWidth = () => 1
  static getSymbolForGuest = (selected: boolean, hover: boolean, opaque = false) => {
    // TODO Develop a symbol for a guest
    return new PictureMarkerSymbol({
      // TODO Add teacher svg to assets
      url: '/assets/animated-gif-test.gif',
      // url: "/assets/guest.svg",
      width: '32px',
      height: '32px'
    })
  }
  /** Hex: #55ACBE */
  static getSymbolForStudent = (selected: boolean, hover: boolean, opaque = false) => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: [147, 194, 56, opaque ? ArcGisSymbolFactory.LOCATION_OPACITY : 1],
      size: ArcGisSymbolFactory.getLocationSize(selected, hover),
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  /** Hex: #153E72 */
  static getSymbolForOtherStaff = (selected: boolean, hover: boolean, opaque = false) => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: [21, 62, 114, opaque ? ArcGisSymbolFactory.LOCATION_OPACITY : 1],
      size: ArcGisSymbolFactory.getLocationSize(selected, hover),
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  // White symbol for unknown - check on map
  static getTransparentSymbol = (selected: boolean, hover: boolean) => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: [],
      size: ArcGisSymbolFactory.getLocationSize(selected, hover),
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  static unselectedLocationIndicatorSize = 8
  static hoverLocationIndicatorSize = 12
  static selectedLocationIndicatorSize = 16
  static getLocationSize = (selected: boolean, hover: boolean): number => {
    return selected ? 16 : hover ? 12 : 8
  }
  static getDefaultOutline = (): __esri.SimpleLineSymbolProperties => {
    return {
      color: [],
      width: 0
    }
  }
  //MESSAGES
  static getSymbolForRegularMessage = (): SimpleMarkerSymbol => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: ArcGisSymbolFactory.noStatusSelectedOutlineColor,
      size: 6,
      outline: ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  static getDotSymbolForAlertMessage = (size: number, useOutline = true): SimpleMarkerSymbol => {
    return new SimpleMarkerSymbol({
      style: 'circle',
      color: [255, 0, 0, 0.1],
      size,
      outline: useOutline
        ? {
            color: [255, 0, 0, 0.1],
            width: 1
          }
        : ArcGisSymbolFactory.getDefaultOutline()
    })
  }
  static getSymbolForPulsingAlert = (): PictureMarkerSymbol => {
    return new PictureMarkerSymbol({
      // url: '/assets/animated-svgs/pulsing-alert.svg',
      url: '/assets/animated-svgs/nested-flicker-smaller-pulse.gif',
      width: '40px',
      height: '40px'
    })
  }
  static pulsingAlertSymbol = ArcGisSymbolFactory.getSymbolForPulsingAlert()
  static getSymbolForAlertMessage = (opaque = false): PictureMarkerSymbol => {
    return new PictureMarkerSymbol({
      url: `/assets/icons/red-status${opaque ? `-opaque` : ''}.svg`,
      width: '16px',
      height: '16px'
    })
  }
  /** Only two variants of the medical alert icon exist, the visible and opaque. Previously we had hover and pressed in designs so image files are kept in repo incase we want to reintroduce and image variants based on hover or clicked state but implementation removed, see git history for old implementation */
  static getSymbolForMedicalAlertMessage = (opaque = false): PictureMarkerSymbol => {
    const url = `/assets/icons/medical-alert-circle${opaque ? '-opaque' : ''}.svg`
    // console.log(`url`, url)
    return new PictureMarkerSymbol({
      url,
      width: '16px',
      height: '16px'
    })
  }
  /** Only returns __esri.SimpleMarkerSymbol due to hover being handled in here TODO remove and lift state */
  static getSymbolForRecentMessage = (
    isSos: boolean,
    isMedicalAlert: boolean,
    isPollResponse: Boolean,
    color?: StatusColorEnum,
    // TODO Get rid of hover as hidden symbol here and handle logic above this function
    hover: boolean = false
  ): __esri.SimpleMarkerSymbol | __esri.PictureMarkerSymbol => {
    // TODO Remove once handled everywhere externally - also get rid of magic strings
    if (hover) return ArcGisSymbolFactory.getHiddenSymbol()
    let type = 'message'
    if (isSos) {
      type = 'alert'
    } else if (isMedicalAlert) {
      type = 'medical-alert'
    } else if (color === StatusColorEnum.yellow || color === StatusColorEnum.red) {
      //We want to display that their recent communication was a message but their last poll response was either red or yellow hence the combination of indicators
      //TODO Add to unit test
      type = !isPollResponse ? `${color}-message` : color
    }
    return new PictureMarkerSymbol({
      url: `/assets/icons/map-${type}-response.svg`,
      width: '50px',
      height: '48px'
    })
  }
  static getHiddenSymbol = () => new SimpleMarkerSymbol({ size: 0 })

  //Construct all needed symbols ahead of time
  // threat
  static hiddenSymbol = ArcGisSymbolFactory.getHiddenSymbol()
  //MESSAGE LOCATION INDICATOR
  // regular message
  static regularMessageSymbol = ArcGisSymbolFactory.getSymbolForRegularMessage()
  // poll response - NOTE: you'll find that there are currently premade reduced symbols but the usage has been removed
  // TODO if for sure not going to use reduced versions of these indicators then we can delete them
  static noStatusNoColorPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.unknown
  )

  static noStatusFirstColorPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.first
  )
  static noStatusSecondColorPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.second
  )
  static noStatusThirdColorPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.third
  )
  static noStatusFourthColorPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.fourth
  )

  static greenPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(StatusColorEnum.green)
  static yellowPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(
    StatusColorEnum.yellow
  )
  static redPollResponseSymbol = ArcGisSymbolFactory.getStatusUpdateSymbol(StatusColorEnum.red)
  //RECENT MESSAGE INDICATOR
  static regularRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    false,
    false,
    false,
    undefined,
    false
  )
  static yellowPollResponseRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    false,
    false,
    true,
    StatusColorEnum.yellow,
    false
  )
  static redPollResponseRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    false,
    false,
    true,
    StatusColorEnum.red,
    false
  )
  /** Indicates that the current message dto is a message type but the last poll response had an associated red status */
  static redMessageResponseRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    false,
    false,
    false,
    StatusColorEnum.red,
    false
  )
  /** Indicates that the current message dto is a message type but the last poll response had an associated yellow status */
  static yellowMessageResponseRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    false,
    false,
    false,
    StatusColorEnum.yellow,
    false
  )
  static alertPollResponseRecentMessageSymbol = ArcGisSymbolFactory.getSymbolForRecentMessage(
    true,
    false,
    false,
    undefined,
    false
  )
  static medicalAlertPollResponseRecentMessageSymbol =
    ArcGisSymbolFactory.getSymbolForRecentMessage(false, true, false, undefined, false)
  // static studentRecentMessageSybmolNoStatus = ArcGisSymbolFactory.getSymbolForRecentMessage(StatusColorEnum.unknown, )
  // alert
  static alertSymbol = ArcGisSymbolFactory.getSymbolForAlertMessage()
  static medicalAlertSymbol = ArcGisSymbolFactory.getSymbolForMedicalAlertMessage()
  static opaqueMedicalAlertSymbol = ArcGisSymbolFactory.getSymbolForMedicalAlertMessage(true)
  static opaqueAlertSymbol = ArcGisSymbolFactory.getSymbolForAlertMessage(true)
  //THREAT SYMBOL
  static threatSymbol = ArcGisSymbolFactory.getFillSymbolForThreat()
  //LOCATION
  //student location
  static studentLocationSymbol = ArcGisSymbolFactory.getSymbolForStudent(false, false)
  static studentLocationSymbolOpaque = ArcGisSymbolFactory.getSymbolForStudent(false, false, true)
  static studentLocationSymbolHovered = ArcGisSymbolFactory.getSymbolForStudent(false, true)
  static studentLocationSymbolSelected = ArcGisSymbolFactory.getSymbolForStudent(true, false)
  //teacher location
  static teacherLocationSymbol = ArcGisSymbolFactory.getSymbolForTeacher(false, false)
  static teacherLocationSymbolOpaque = ArcGisSymbolFactory.getSymbolForTeacher(false, false, true)
  static teacherLocationSymbolHovered = ArcGisSymbolFactory.getSymbolForTeacher(false, true)
  static teacherLocationSymbolSelected = ArcGisSymbolFactory.getSymbolForTeacher(true, false)
  //otherstaff location
  static otherStaffLocationSymbol = ArcGisSymbolFactory.getSymbolForOtherStaff(false, false)
  static otherStaffLocationSymbolOpaque = ArcGisSymbolFactory.getSymbolForOtherStaff(
    false,
    false,
    true
  )
  static otherStaffLocationSymbolHovered = ArcGisSymbolFactory.getSymbolForOtherStaff(false, true)
  static otherStaffLocationSymbolSelected = ArcGisSymbolFactory.getSymbolForOtherStaff(true, false)
  //guest location
  static guestLocationSymbol = ArcGisSymbolFactory.getSymbolForGuest(false, false)
  static guestLocationSymbolOpaque = ArcGisSymbolFactory.getSymbolForGuest(false, false, true)
  static guestLocationSymbolHovered = ArcGisSymbolFactory.getSymbolForGuest(false, true)
  static guestLocationSymbolSelected = ArcGisSymbolFactory.getSymbolForGuest(true, false)

  //Lookups by type
  static opacitySymbolByUserType: Record<MobileUserShownOnMap, SimpleMarkerSymbol> = {
    [MobileUserTypes.student]: ArcGisSymbolFactory.studentLocationSymbolOpaque,
    [MobileUserTypes.teacher]: ArcGisSymbolFactory.teacherLocationSymbolOpaque,
    [MobileUserTypes.otherStaff]: ArcGisSymbolFactory.otherStaffLocationSymbolOpaque
  }
  static locationSymbolByUserType: Record<MobileUserShownOnMap, SimpleMarkerSymbol> = {
    [MobileUserTypes.student]: ArcGisSymbolFactory.studentLocationSymbol,
    [MobileUserTypes.teacher]: ArcGisSymbolFactory.teacherLocationSymbol,
    [MobileUserTypes.otherStaff]: ArcGisSymbolFactory.otherStaffLocationSymbol
  }
  static selectedLocationSymbolByUserType: Record<MobileUserShownOnMap, SimpleMarkerSymbol> = {
    [MobileUserTypes.student]: ArcGisSymbolFactory.studentLocationSymbolSelected,
    [MobileUserTypes.teacher]: ArcGisSymbolFactory.teacherLocationSymbolSelected,
    [MobileUserTypes.otherStaff]: ArcGisSymbolFactory.otherStaffLocationSymbolSelected
  }
  //MAP CONFIG SYMBOLS
  //#region
  static failedValidationBorderWidth = 3
  static editBoundaryStrokeWidth = 2
  static mapConfigSchoolBoundaryBorderWidth = 2
  static mapConfigSchoolBoundaryDisplayColor = [113, 186, 201, 0.1]
  static mapConfigSchoolBoundaryDisplayBorderColor = [188, 228, 235, 1]
  //Location Proximity Colors
  /** Colors/Tertiary/200 */
  static tertiary200 = [156, 173, 196]
  static proximityBufferColor = [...this.tertiary200, 0.16]
  static proximityBufferColorBorder = [...this.tertiary200, 1]
  static proximityBufferColorBorderWidth = 1
  // https://developers.arcgis.com/javascript/latest/api-reference/esri-symbols-SimpleLineSymbol.html#style
  static proximityBufferColorBorderStyle: any = 'dash'
  static getMapConfigLocProxBufferSymbol = () => {
    return new SimpleFillSymbol({
      color: ArcGisSymbolFactory.proximityBufferColor,
      outline: new SimpleLineSymbol({
        color: this.proximityBufferColorBorder,
        width: this.proximityBufferColorBorderWidth,
        style: this.proximityBufferColorBorderStyle
      })
    })
  }
  static proximityBufferSymbol = this.getMapConfigLocProxBufferSymbol()
  /** Colors/Warning/600 #65530D */
  static editBoundaryBorderColor = [101, 83, 13, 1]
  /** Here just in case there's a subtle difference needed between edit boundary and area */
  static editBoundaryColor = [227, 187, 30, this.MAP_CONFIG_OPACITY]
  static editGeomColor = [227, 187, 30, this.MAP_CONFIG_OPACITY]
  static getBoundaryEditSymbol = () => {
    return new SimpleFillSymbol({
      color: ArcGisSymbolFactory.editBoundaryColor,
      outline: new SimpleLineSymbol({
        color: ArcGisSymbolFactory.editBoundaryBorderColor,
        width: ArcGisSymbolFactory.editBoundaryStrokeWidth
      })
    })
  }
  static getGeomEditSymbol = () => {
    return new SimpleFillSymbol({
      color: ArcGisSymbolFactory.editGeomColor,
      outline: new SimpleLineSymbol({
        color: ArcGisSymbolFactory.editBoundaryBorderColor,
        width: ArcGisSymbolFactory.editBoundaryStrokeWidth
      })
    })
  }
  static configGeomDisplayColor = [77, 155, 172, this.MAP_CONFIG_OPACITY]
  /** Shared between boundary and area, with different color for edit state
   * Colors/Primary/400 #4D9BAC*/
  static geomDisplayBorderColor = [77, 155, 172, 1]
  static geomDisplayBorderWidth = 1
  static getMapConfigDisplayGeomSymbol = () => {
    return new SimpleFillSymbol({
      color: ArcGisSymbolFactory.configGeomDisplayColor,
      outline: new SimpleLineSymbol({
        color: ArcGisSymbolFactory.geomDisplayBorderColor,
        width: ArcGisSymbolFactory.geomDisplayBorderWidth
      })
    })
  }
  static displaySchoolMapConfigAreaSymbol = this.getMapConfigDisplayGeomSymbol()
  static editSchoolAreaSymbol = this.getGeomEditSymbol()
  static editSchoolGeomSymbol = this.getBoundaryEditSymbol()
  static failedValidationAreaSymbol = this.getStyle(
    this.areaValidationFailedColor,
    SymbolStyleEnum.diagonalCross,
    this.failedValidationBorderWidth,
    this.areaValidationFailedBorderColor
  )
  // #endregion
}
//TODO Discuss other user indicator options
// IMAGE?
// return new PictureMarkerSymbol({
//     // TODO Add teacher svg to assets
//     // url: "/assets/animated-gif-test.gif",
//     url: "/assets/test.svg",
//     width: "32px",
//     height: "32px",
// })
// return new PictureMarkerSymbol({
//     // url: "/assets/animated-gif-test.gif",
//     url: "/assets/test.svg",
//     width: "32px",
//     height: "32px",
// })
// return new WebStyleSymbol({
//     name: "esri-pin-1",
//     styleName: "Esri2DPointSymbolsStyle"
// });
// return new WebStyleSymbol({
//     name: "pentagon-2",
//     styleName: "Esri2DPointSymbolsStyle"
// });
// const symbol_simple = new SimpleMarkerSymbol({
//     color: [226, 119, 40],  // Orange
//     outline: {
//         color: [255, 255, 255], // White
//         width: 1
//     }
// })
// return symbol_simple
// const symbol = new CIMSymbol({
//     data: {
//         type: "CIMSymbolReference",
//         symbol: {
//             type: "CIMPointSymbol",
//             symbolLayers: [
//                 {
//                     type: "CIMVectorMarker",
//                     enable: true,
//                     anchorPointUnits: "Relative",
//                     anchorPoint: { x: 0.5, y: 0.5 },
//                     markerGraphics: [
//                         {
//                             type: "CIMMarkerGraphic",
//                             geometry: {
//                                 rings: [
//                                     [[-20, -20], [20, -20], [20, 20], [-20, 20], [-20, -20]],
//                                 ],
//                             },
//                             symbol: {
//                                 type: "CIMPulsePointSymbol",
//                                 size: 30,
//                                 color: [255, 0, 0, 255],
//                                 alpha: 0.8,
//                                 pulseColor: [255, 0, 0, 255],
//                                 pulseRate: 1000,
//                               } as esriCIM.CIMPointSymbol, // cast to CIMPointSymbol
//                         },
//                     ],
//                 },
//             ],
//         },
//     },
// });
// return symbol
