import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { SketchUpdateToolEnum } from './sketch-vm.view'
import { MapConfigSteps } from '@view/pages/school-map-config-page/school-map-config.view'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'

export namespace ArcGisSketchViewModelFactory {
  /** Builds a sketch view model based on the current map context, and returns null if one exists already */
  export const getSketchViewModel = (context: ArcGisMapService): SketchViewModel | null => {
    const isAreaStep = context.activeConfigStep === MapConfigSteps.addAreas
    const isBoundaryStep = context.activeConfigStep === MapConfigSteps.schoolPerimeter
    const symbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
    // let layer: GraphicsLayer | null = null
    // if (isAreaStep && context._schoolAreaLayer) {
    //   layer = context._schoolAreaLayer
    // } else if (isBoundaryStep && context._schoolBoundaryLayer) {
    //   layer = context._schoolBoundaryLayer
    // } else {
    //   return null
    // }
    let allowDeleteKey = false
    // if (!layer) {
    //   return null
    // }
    if (isBoundaryStep) {
      allowDeleteKey = true
    }
    const sketchVm = new SketchViewModel({
      view: context._mapView,
      // layer,
      polygonSymbol: symbol,
      // https://developers.arcgis.com/javascript/latest/api-reference/esri-views-interactive-sketch-SketchTooltipOptions.html#enabled
      tooltipOptions: {
        // Indicates size of area interacting with
        enabled: false
      },
      activeFillSymbol: symbol,

      defaultUpdateOptions: {
        tool: SketchUpdateToolEnum.reshape,
        enableRotation: true,
        enableScaling: true,
        enableZ: false,
        multipleSelectionEnabled: false,
        // TODO Reset during image upload
        preserveAspectRatio: false,
        toggleToolOnClick: true,
        reshapeOptions: {
          edgeOperation: 'split', // | "split" | "offset";
          shapeOperation: 'move', // "none" | "move" | "move-xy";
          vertexOperation: 'move' // "move-xy"
        }
      },
      updateOnGraphicClick: false,
      defaultCreateOptions: {
        mode: 'click', // "hybrid" | "freehand" | "click";
        hasZ: false
      }
    })
    // How to set this??
    // sketchVm.allowDeleteKey = allowDeleteKey
    return sketchVm
  }
}
