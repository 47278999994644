<div class="icon-container" *ngIf="showIcons">
  <ng-container *ngFor="let icon of icons; index as i">
    <div *ngIf="showIcon(icon)" class="_icon">
      <img
        src="/assets/icons/response-types/{{ getImageForResponse(icon) }}.svg"
        [ngStyle]="{ 'z-index': 100 - i }"
      />
    </div>
  </ng-container>
</div>
