<div
  class="custom-form-input-container"
  [ngClass]="{
    '--big-height': bigHeight
  }"
>
  <mat-form-field
    appearance="outline"
    [floatLabel]="floatLabel"
    class="_custom-mat-form-field"
    *ngIf="control; else provideControl"
  >
    <mat-label>{{ labelText }}</mat-label>
    <div class="--prefix" *ngIf="prefix" matTextPrefix>
      <span>{{ prefix }} </span>
    </div>
    <ng-container *ngIf="bigHeight; else smallInput">
      <textarea matInput [formControl]="control" [placeholder]="placeholderText"></textarea>
    </ng-container>
    <ng-template #smallInput>
      <input matInput [formControl]="control" [placeholder]="placeholderText" mask="{{ mask }}" />
    </ng-template>
    <div class="--error-icon" *ngIf="errorMessage" matSuffix>
      <mat-icon>error_outline</mat-icon>
    </div>
    <div class="--icon" *ngIf="showIcon" matSuffix>
      <mat-icon>{{ icon }}</mat-icon>
    </div>
    <!-- TODO Determine if we'll be using the mat hint component. -->
    <!-- <mat-hint *ngIf="hint">{{ hint }}</mat-hint> -->
    <!-- matTextPrefix -->
  </mat-form-field>
  <div *ngIf="description" class="_input-description">{{ description }}</div>
  <div class="_error-container">
    <ng-container *ngIf="errorMessage">
      <mat-error>{{ errorMessage }}</mat-error>
    </ng-container>
  </div>
</div>
<ng-template #provideControl> provide a control for error handling </ng-template>
<!-- FOR LOCAL DEV - TROUBLESHOOT ISSUES IN -->
<!--
<div>
  errorMessage:
  {{ errorMessage | json }}
</div>
<div>
  hint:
  {{ hint | json }}
</div>
<div>
  placeholder:
  {{ placeholderText | json }}
</div>
<div>
  disabled:
  {{ disabled | json }}
</div> -->
