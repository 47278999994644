import Graphic from '@arcgis/core/Graphic'
import { Point } from '@arcgis/core/geometry'
import {
  ChatResponseDtoPayload,
  ChatResponseWithPollResponseIdPayload,
  ChatResponseWithMessagePayload
} from '@model/message.model'
import { StatusColorEnum } from '@view/area/area.view'
import { AlertViewModel } from './arcgis-alert.view'
import { ArcGisMapService } from './arcgis-map.service'
import { ArcGisSymbolFactory } from './arcgis-symbol-factory.view'
import { ArcgisSpatialRefence } from './spatial-reference/arcgis-spatial-reference.view'
import { ArcGisMessageGraphicHandler } from './graphic/message-location-graphic.view'
import { ArcGisGraphicAttrFac } from './attributes/arcgis-graphic-attr-fac.view'
import { DecoratedAlertViewModel } from '@model/alert.model'
import { IThreatItemViewModel } from '@view/area/threat-area.view'

export namespace ArcGisPointFactory {
  export const getPointFromThreatVm = (threatVm: IThreatItemViewModel): Point => {
    return new Point({
      x: threatVm.latLong.lon,
      y: threatVm.latLong.lat
    })
  }
  export const getPointFromChatRes = (payload: ChatResponseDtoPayload): Point | null => {
    if (!payload.latLong?.lon || !payload.latLong?.lat) {
      return null
    }
    return new Point({
      x: payload.latLong?.lon ?? 0,
      y: payload.latLong?.lat ?? 0
    })
  }
  export const getPoint = (lon: number, lat: number, spatialRef: __esri.SpatialReference = ArcgisSpatialRefence.geoUnitsLatLongSpatialReference): Point => {
    const point = new Point()
    point.x = lon
    point.y = lat
    point.latitude = lat
    point.longitude = lon
    point.spatialReference = spatialRef
    return point
  }
  /** Handles building the alert graphic and adding it to the map for dimmed, pulsing and regular alerts.
   * It's assumed that use of this function will pass in is pulsing or is dimmed but never true for both. */
  export const addAlertPollResponseToMap = (
    context: ArcGisMapService,
    payload: ChatResponseDtoPayload,
    isPulsing: boolean,
    alertVm: DecoratedAlertViewModel,
    dimmed: boolean = false
  ) => {
    if (!payload.latLong) return
    const { lat, lon } = payload.latLong
    const point = ArcGisPointFactory.getPoint(lon, lat)
    const simpleMarkerSymbol = dimmed
      ? ArcGisSymbolFactory.opaqueAlertSymbol.clone()
      : ArcGisSymbolFactory.getSymbolForAlertMessage()
    const attributes = ArcGisGraphicAttrFac.getAlertResponseGraphicAttributes(payload, alertVm)
    let graphic = new Graphic({
      geometry: point,
      symbol: simpleMarkerSymbol,
      attributes
    })
    if (isPulsing) {
      const alertPulseVm = new AlertViewModel(
        graphic,
        ArcGisSymbolFactory.getDotSymbolForAlertMessage(
          ArcGisSymbolFactory.SINGLE_ALERT_LOCATION_SIZE
        ),
        payload
      )
      const pointGraphic = alertPulseVm.setUpTempPulseOnSymbol(attributes)
      context._pulsingAlertGraphicsLayer?.graphics.add(pointGraphic)
    } else if (dimmed) {
      context._dimmedAlertGraphicsLayer?.graphics.add(graphic)
    } else {
      context._attackAlertGraphicsLayer?.graphics.add(graphic)
    }
  }
  export const addMedicalAlertResponseToMap = (
    context: ArcGisMapService,
    payload: any,
    alertVm: DecoratedAlertViewModel,
    opaque: boolean = false
  ) => {
    if (!payload.latLong) return
    const { lat, lon } = payload.latLong
    const point = ArcGisPointFactory.getPoint(lon, lat)
    const attributes = ArcGisGraphicAttrFac.getMedicalAlertResponseGraphicAttributes(
      payload,
      alertVm
    )
    let graphic = new Graphic({
      geometry: point,
      symbol: opaque
        ? ArcGisSymbolFactory.opaqueMedicalAlertSymbol.clone()
        : ArcGisSymbolFactory.medicalAlertSymbol.clone(),
      attributes
    })
    if (opaque) {
      context._dimmedAlertGraphicsLayer?.graphics.add(graphic)
    } else {
      context._medialAlertGraphicsLayer?.graphics.add(graphic)
    }
  }
  // TODO Set up animated transparent gif and move to this - Issue is that redrawing the graphic is too compute intensive on the map, need to do a different symbol or find a better strategy for the pulse
  // export const addAlertPollResponseToMap = (
  //   context: ArcGisMapService,
  //   payload: ChatMessageAlertResponseDtoPayload,
  //   isPulsing: boolean
  // ) => {
  //   if (!payload.latLong) return
  //   const { lat, lon } = payload.latLong
  //   const point = ArcGisPointFactory.getPoint(lon, lat)
  //   if (isPulsing) {
  //     let graphic = new Graphic({
  //       geometry: point,
  //       symbol: ArcGisSymbolFactory.pulsingAlertSymbol,
  //       attributes: ArcGisGraphicAttrFac.getAlertResponseGraphicAttributes(payload)
  //     })
  //     context._pulsingAlertGraphicsLayer?.graphics.add(graphic)
  //   } else {
  //     let graphic = new Graphic({
  //       geometry: point,
  //       symbol: ArcGisSymbolFactory.getSymbolForAlertMessage(),
  //       attributes: ArcGisGraphicAttrFac.getAlertResponseGraphicAttributes(payload)
  //     })
  //     context._attackAlertGraphicsLayer?.graphics.add(graphic)
  //   }
  // }
  /** Set up central handling for adding poll responses with all their related colors TODO Add symbol by dto */
  export const getPointsForPollResponses = (
    responseIdToStatusColorEnum: Record<number, StatusColorEnum>,
    chatMessageDtos: ChatResponseWithPollResponseIdPayload[]
  ): Graphic[] => {
    const graphics: Graphic[] = []
    chatMessageDtos.forEach((payload) => {
      const graphic = ArcGisMessageGraphicHandler.getGraphicForPollResponse(
        payload,
        responseIdToStatusColorEnum
      )
      if (graphic) {
        graphics.push(graphic)
      }
    })
    return graphics
  }
  /** Set up a view model for a chat message location on the map to avoid passing whole payload as we only need the location and not the whole payload */
  export const getPointGraphicsForChatResponseMessages = (
    payloads: ChatResponseWithMessagePayload[]
  ): Graphic[] =>
    payloads.map((p) => ArcGisMessageGraphicHandler.getGraphicForMessageResponse(p, true))
}
