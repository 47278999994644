import { FirstLevelDir } from '@domain/route/app-routes.domain'
import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OwlStateSlices } from '@state/app.state'

// https://ngrx.io/guide/router-store/selectors

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getRouterSelectors` function
export const selectRouter = createFeatureSelector<RouterReducerState>(OwlStateSlices.router)

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle // select the title if available
} = getRouterSelectors()

export const selectPageIsDashboard = createSelector(selectUrl, (url) =>
  url.includes(FirstLevelDir.dashboard)
)
export const selectPageIsRoster = createSelector(selectUrl, (url) =>
  url.includes(FirstLevelDir.roster)
)
