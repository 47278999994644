<div appearance="outline" class="app-select-container" *ngIf="showSelect; else noOptions">
  <mat-form-field appearance="outline" class="_custom-mat-form-field" [floatLabel]="floatLabel">
    <mat-label>{{ labelText }}</mat-label>
    <mat-select
      [(ngModel)]="selected"
      (selectionChange)="handleSelectChange($event)"
      placeholder="{{ placeholderText }}"
    >
      <mat-option *ngFor="let selectOption of options" [value]="selectOption.value">
        {{ selectOption.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<ng-template #noOptions>
  <div class="no-options">Please pass select component options.</div>
</ng-template>
