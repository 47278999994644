import { mockEntireSchoolLogicalId, mockUnknownLogicalId } from '@mock/school.mock'
import { LatLonAlt } from '@model/location.model'
import { ChatResponseDtoPayload, ChatMessageDtoPayload } from '@model/message.model'
import { GetResponseDto } from '@model/message/predefined-message.model'
import { MobileUserTypes } from '@model/user/user.model'
import {
  DEFAULT_CHATROOM_DISPLAY_TEXT,
  DEFAULT_ENTIRE_SCHOOL_DISPLAY_TEXT
} from '@shared/constants'
import { TimeUtils } from '@shared/time.utils'
import { StatusColorEnum } from '@view/area/area.view'
import { ChatRoomTypeEnum } from '@view/chat/chat.view'
import { ChatPollViewModel, PollAggregatedResultVm } from '@view/poll/poll.view'
import { ResponseIdChatMessageVm } from './response-id-message.view'

export enum ChatMessageVmType {
  responseIdMessage = 'responseIdMessage',
  message = 'message',
  poll = 'poll',
  unknown = 'unknown'
}
export class ChatMessageViewModel {
  message: string = ''
  userDisplayName: string = ''
}
export const ColorLookup: Record<StatusColorEnum, string> = {
  [StatusColorEnum.red]: '#cc3333',
  [StatusColorEnum.yellow]: '#E3BB1E',
  [StatusColorEnum.green]: '#8cb936',
  [StatusColorEnum.first]: '#008080',
  [StatusColorEnum.second]: '#5986B2',
  [StatusColorEnum.third]: '#CC6D14',
  [StatusColorEnum.fourth]: '#800080',
  [StatusColorEnum.unknown]: ''
}

export class ChatUiListItemViewModel {
  chatRoomId: string | null = null
  chatRoomDisplayName: string | null = null
  recentMessage: ChatUiItemViewModel | null = null
  unreadCount: number = 0
  type: ChatRoomTypeEnum = ChatRoomTypeEnum.entireSchool
}

export class ChatUiItemViewModel {
  type: ChatMessageVmType = ChatMessageVmType.unknown
  messageVm?: ChatMessageViewModel
  responesIdVm?: ResponseIdChatMessageVm
  pollVm?: ChatPollViewModel

  // Once we have designs for an alert and a negate alert display, we can add them here - add additional explicit ngIfs
  // ex: <ng-container *ngIf="item.pollVm">
  //in the chat-room.component.html
  // alertVm?: ChatAlertViewModel
  // negateAlertVm?: ChatAlertViewModel

  /** For message it means that this message was sent or received, while for poll it means that the poll was sent or it is showing aggregated received results. The nuance is that we don't display individual poll response. */
  isReceived = false
  /** This must be a unique string as it's use in the Angular Scroll CDK trackby function. */
  id: string = ''
  chatRoomId: string | null = null
  latLonAlt?: LatLonAlt
  useType?: MobileUserTypes
  /** For messages sent to an audience, defaults to Everyone, other options include area name, a specific user name, or a concatenated list of names with a tooltip */
  audience: string = ''
  isMessageToOneUser?: boolean
  userTypeFilter?: string[]
  timestampDisplay = ''
  /** This field is required because we use it to determine your scroll position since each messages timestamp should be unique in one chat room. */
  timestamp = ''

  static setAnsweresInPollVm = (
    payload: ChatPollViewModel,
    responses: number[],
    responseIdLookup: Record<number, GetResponseDto>
  ): ChatPollViewModel => {
    // TODO move to poll aggregated result vm and change interface to a class
    payload.aggregatedResultItem = payload.aggregatedResultItem.map((item) => {
      const newItem: PollAggregatedResultVm = {
        ...item
      }
      newItem.count = responses.filter((r) => r === newItem.pollResponseId).length
      newItem.color = ChatUiItemViewModel.getColor(
        responseIdLookup[item.pollResponseId]?.color ?? StatusColorEnum.unknown
      )
      return newItem
    })

    payload.respondentsNumber = responses.length
    payload.notAnsweredCount =
      payload.notAnsweredCount > 0 ? payload.notAnsweredCount - payload.respondentsNumber : 0
    payload.notAnsweredColore = ChatUiItemViewModel.getColor(payload.notAnsweredColore)
    return payload
  }

  static getSentPollVmFromDto = (
    payload: ChatMessageDtoPayload,
    areaGuidToNameLookup: Record<string, string>
  ): ChatUiItemViewModel => {
    const {
      id,
      logicalId,
      autoSent,
      message,
      schoolId,
      chatRoomId,
      timestamp,
      areaLogicalId,
      mobileUserIds,
      responses,
      userTypeFilter
    }: ChatMessageDtoPayload = payload
    let dynamicAudienceString = ''
    //If the chat room isn't associated with an area or a person
    const moreThanOneUserInAudience = mobileUserIds && mobileUserIds.length > 1
    if (areaLogicalId) {
      dynamicAudienceString = areaGuidToNameLookup[areaLogicalId]
    }
    const timeDisplay = TimeUtils.getTimeSinceStringFromDate(new Date(timestamp))
    if (!timeDisplay) {
      // console.log(`Message with id ${id} is in the future, TODO research`)
    }
    return {
      type: ChatMessageVmType.poll,
      pollVm: {
        logicalPollId: logicalId ?? '',
        question: message ?? '',
        aggregatedResultItem: responses?.map((item): any => {
          const pollResponseId: number = item.id
          const answer: string = item.text
          const color: StatusColorEnum | string = item.color ?? ''
          return new PollAggregatedResultVm(pollResponseId, answer, color)
        }),
        /** If we send to area, we won't know total count of audience, but if we have a list of user ids, then we can display % responded. */
        respondentsNumber: 0,
        totalCount: moreThanOneUserInAudience
          ? mobileUserIds.length
          : payload.pollToUserIds?.length ?? 0,
        notAnsweredCount: moreThanOneUserInAudience
          ? mobileUserIds.length
          : payload.pollToUserIds?.length ?? 0,
        notAnsweredColore: '',
        autoSent
      } as ChatPollViewModel,
      isReceived: false,
      // TODO Concat this string - to be used for tool tip text after concatenation
      id: `U:${id}-S:${schoolId}-${Date.now().toString()}`,
      timestampDisplay: timeDisplay ?? '',
      timestamp: timestamp,
      chatRoomId,
      userTypeFilter: userTypeFilter ?? undefined,
      audience:
        chatRoomId === mockUnknownLogicalId
          ? DEFAULT_CHATROOM_DISPLAY_TEXT
          : chatRoomId === mockEntireSchoolLogicalId
          ? DEFAULT_ENTIRE_SCHOOL_DISPLAY_TEXT
          : dynamicAudienceString
    }
  }

  //TODO We need to name it by the area type, or a concat of the user ids.
  // TODO Find a better way to organize channel id and an area id association
  /**
   * Constructs variants of chat message view model based on chat message sent or received by ngrx action type
   */
  static getChatMessageVmFromDto = (
    payload: ChatMessageDtoPayload,
    userDisplayNameLookup: Record<string, string>,
    areaGuidToNameLookup: Record<string, string>
  ): ChatUiItemViewModel => {
    // console.log(`Payload`, payload)
    // console.log('user display name lookup', userDisplayNameLookup)
    // console.log('area guid to name lookup', areaGuidToNameLookup)
    // TODO Return different versions of the
    const {
      logicalId,
      areaLogicalId,
      id,
      message,
      schoolId,
      timestamp,
      chatRoomId,
      mobileUserIds,
      userTypeFilter
    }: ChatMessageDtoPayload = payload
    let chatMessageStatus: ChatMessageStatus = ChatMessageStatus.unknown
    let dynamicAudienceString = ''
    //If the chat room isn't associated with an area or a person
    const moreThanOneUserInAudience = mobileUserIds && mobileUserIds.length > 1
    const oneUserInListOfIds = mobileUserIds && mobileUserIds.length === 1
    if (areaLogicalId) {
      dynamicAudienceString = areaGuidToNameLookup[areaLogicalId]
    } else if (oneUserInListOfIds) {
      dynamicAudienceString = userDisplayNameLookup[mobileUserIds[0]]
    } else if (moreThanOneUserInAudience) {
      dynamicAudienceString =
        mobileUserIds?.map((id) => userDisplayNameLookup[id]).join(', ') ?? 'N/A'
    }
    const timeDisplay = TimeUtils.getTimeSinceStringFromDate(new Date(timestamp))
    if (!timeDisplay) {
      // console.log(`Message with id ${id} is in the future, TODO research`)
    }
    const vm = {
      type: ChatMessageVmType.message,
      messageVm: {
        message: message ?? '',
        userDisplayName: ''
      },
      isReceived: false,
      // TODO Extract to unique id generator to fallback in case logical id doesn't exist
      id: logicalId ?? `U:${id}-S:${schoolId}-${Date.now().toString()}`,
      timestampDisplay: timeDisplay ?? '',
      timestamp: timestamp,
      chatRoomId,
      isMessageToOneUser: oneUserInListOfIds ?? undefined,
      userTypeFilter: userTypeFilter ?? undefined,
      audience:
        chatRoomId === mockUnknownLogicalId
          ? DEFAULT_CHATROOM_DISPLAY_TEXT
          : chatRoomId === mockEntireSchoolLogicalId
          ? DEFAULT_ENTIRE_SCHOOL_DISPLAY_TEXT
          : dynamicAudienceString
    }
    // console.log(`Result vm`, vm )
    return vm
  }
  static getChatResponseVmFromDto = (
    payload: ChatResponseDtoPayload,
    userDisplayNameLookup: Record<string, string>,
    userToTypeLookup: Record<string, MobileUserTypes>
  ): ChatUiItemViewModel => {
    // if(payload.isSos || payload.responseId){
    //   console.log(`Payload`, payload)
    //   console.log('user display name lookup', userDisplayNameLookup)
    //   console.log('area guid to name lookup', userToTypeLookup)
    // }
    // TODO Return different versions of the
    const { logicalId, message, timestamp, chatRoomId, mobileUserId }: ChatResponseDtoPayload =
      payload
    const timeDisplay = TimeUtils.getTimeSinceStringFromDate(new Date(timestamp))
    if (!timeDisplay) {
      // console.log(`Message with id ${id} is in the future, TODO research`)
    }
    const vm = {
      messageVm: {
        message: message ?? '',
        userDisplayName: userDisplayNameLookup[mobileUserId ?? '0'] ?? 'N/A'
      },
      isReceived: true,
      // TODO Extract to shared back up in case logical id doesn't exist for some reason - or confirm that logical id will always exist
      id: logicalId ?? `U:${logicalId}-${Date.now().toString()}`,
      timestampDisplay: timeDisplay ?? '',
      timestamp: timestamp,
      chatRoomId,
      useType: mobileUserId ? userToTypeLookup[mobileUserId] : undefined,
      audience: '',
      type: ChatMessageVmType.message
    }
    return vm
  }

  //TODO: change when the colors are defined in the design
  static getColor(color: StatusColorEnum | string): string {
    switch (color) {
      case StatusColorEnum.red:
        return ColorLookup[StatusColorEnum.red]
      case StatusColorEnum.yellow:
        return ColorLookup[StatusColorEnum.yellow]
      case StatusColorEnum.green:
        return ColorLookup[StatusColorEnum.green]
      case StatusColorEnum.first:
        return ColorLookup[StatusColorEnum.first]
      case StatusColorEnum.second:
        return ColorLookup[StatusColorEnum.second]
      case StatusColorEnum.third:
        return ColorLookup[StatusColorEnum.third]
      case StatusColorEnum.fourth:
        return ColorLookup[StatusColorEnum.fourth]
      //There should be no case where the response to a poll has no color
      // case StatusColorEnum.unknown:
      //   return this.getColorFromArray()
      default:
        // TODO: add logic for custom noAnswerColor when it will be defined in the design
        return ChatUiItemViewModel.noAnswerColor
    }
  }

  static noAnswerColor = `#77B8C6`
}
export enum ChatMessageStatus {
  unknown = 'unknown',

  //Use authoring
  inEditing = 'inEditing',
  sent = 'sent',

  //Received from user
  receivedMessage = 'receivedMessage',

  //Network indicators - Balcony
  sentMsgInTransit = 'inTransit',
  sentMsgTimeout = 'transitTimeout',
  messageReceivedSuccess = 'messageReceivedSuccess',
  messageReceivedFail = 'messageReceivedFail'
}
