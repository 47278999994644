import Graphic from '@arcgis/core/Graphic'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { Polygon } from '@arcgis/core/geometry'
import { Symbol } from '@arcgis/core/symbols'
import { MapPageType } from '@service/map/map.component.service.model'

export namespace ArcGisSchoolBoundaryGraphic {
  export const getEditSchoolBoundaryGraphicFromGraphic = (g: Graphic): Graphic => {
    g.symbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
    return g
  }
  export const addRingsToBoundaryLayer = (context: ArcGisMapService, boundary: number[][]) => {
    const geometry = new Polygon({
      rings: [boundary]
    })
    let symbol: Symbol
    if (context.type === MapPageType.schoolDashboard) {
      symbol = ArcGisSymbolFactory.getSchoolBoundarySymbol().clone()
    } else {
      symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
    }
    context._schoolBoundaryGraphic = new Graphic({
      geometry,
      symbol
    })
    // console.log(`Adding rings to boundary!`)
    context._schoolBoundaryLayer?.graphics.add(context._schoolBoundaryGraphic)
  }
}
