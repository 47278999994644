import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { DecoratedAlertViewModel } from '@model/alert.model'
import {
  ChatMessageDto,
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload,
  ChatResponseWithPollResponseIdPayload
} from '@model/message.model'
import {
  GetResponseDto,
  PredefinedMessageHelper,
  ResponseTypeEnum
} from '@model/message/predefined-message.model'
import { ResponseGroupTypeEnum } from '@model/message/response-group.model'
import { GetSchoolDto } from '@model/school/school.model'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { MobileUserShownOnMap, MobileUserTypes } from '@model/user/user.model'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { StatusColorEnum } from '@view/area/area.view'
import { MapUiControlEnum } from '@view/pages/dashboard-page/dashboard-page.view'

export type MapUiControlEnumForLocation =
  | MapUiControlEnum.showStudents
  | MapUiControlEnum.showTeachers
  | MapUiControlEnum.showOtherStaff

export type ILocationVisibilityViewModel = Record<MapUiControlEnum, boolean>
export type ILocationSideNavToggleProp = Record<MobileUserShownOnMap, MapUiControlEnum>

export const locationVisibilityByTypeLookup: ILocationSideNavToggleProp = {
  [MobileUserTypes.student]: MapUiControlEnum.showStudents,
  [MobileUserTypes.teacher]: MapUiControlEnum.showTeachers,
  [MobileUserTypes.otherStaff]: MapUiControlEnum.showOtherStaff
  // [MobileUserTypes.guest]: MapUiControlEnum.showGuests
}

export type LocationLookupForVisibleMobileUserType = Partial<
  Record<MapUiControlEnumForLocation, MapUiControlEnum>
>
export interface IMapChatContentViewModel {
  // responseIdToPollResponseLookup: Record<number, GetResponseDto>
  userToTypeLookup: Record<string, MobileUserTypes>
  responseIdToGetResponseDto: Record<number, GetResponseDto>
  responseIdToStatusColorEnum: Record<number, StatusColorEnum>
  threatIndicatorLookup: Record<string, DecoratedAlertViewModel[]>
  medicalAlertsLookup: Record<string, DecoratedAlertViewModel[]>
  threatIndicators: ChatResponseDtoPayload[]
  //User type grouped poll responses - TODO turn all of these into view models that have a graphic in them, or better yet a graphic
  studentPollResponses: ChatResponseWithPollResponseIdPayload[]
  teacherPollResponses: ChatResponseWithPollResponseIdPayload[]
  otherStaffPollResponses: ChatResponseWithPollResponseIdPayload[]
  guestPollResponses: ChatResponseWithPollResponseIdPayload[]

  studentMessages: ChatResponseWithMessagePayload[]
  teacherMessages: ChatResponseWithMessagePayload[]
  otherStaffMessages: ChatResponseWithMessagePayload[]
  guestMessages: ChatResponseWithMessagePayload[]

  locationVmLookup: Record<string, DecoratedUserLocationViewModel>
}
/** This selector provides historic locations for the map */
export class LocationsForDashMapViewModel {
  /** Align the dashboard map ui controls with mobile user types and only add them to the map if they are allowed to be visible */
  static canShowLocationByUiToggles = (
    userType: MobileUserTypes | unknown,
    visibilityVm: ILocationVisibilityViewModel
  ) => {
    if (userType === MobileUserTypes.student && visibilityVm.showStudents) {
      return true
    } else if (userType === MobileUserTypes.otherStaff && visibilityVm.showOtherStaff) {
      return true
    } else if (userType === MobileUserTypes.teacher && visibilityVm.showTeachers) {
      return true
    } else if (userType === MobileUserTypes.guest && visibilityVm.showGuests) {
      return true
    } else {
      console.warn(
        `User Type: ${userType} not recognized, or related visibility state in view model not present, visibility vm ${JSON.stringify(
          visibilityVm
        )}`
      )
    }
    return false
  }
  /** Group chat content for easy addition to map based on related user type toggle. NOTE: that alerts aren't filtered by visibility */
  static getChatMessageLocationsWithLookups(
    s: DashboardPageState
  ): IMapChatContentViewModel | null {
    let studentPollResponses: ChatResponseWithPollResponseIdPayload[] = []
    let teacherPollResponses: ChatResponseWithPollResponseIdPayload[] = []
    let otherStaffPollResponses: ChatResponseWithPollResponseIdPayload[] = []
    let guestPollResponses: ChatResponseWithPollResponseIdPayload[] = []

    let studentMessages: ChatResponseWithMessagePayload[] = []
    let teacherMessages: ChatResponseWithMessagePayload[] = []
    let otherStaffMessages: ChatResponseWithMessagePayload[] = []
    let guestMessages: ChatResponseWithMessagePayload[] = []
    let threatIndicatorLookup: Record<string, DecoratedAlertViewModel[]> = {}
    let threatIndicators: ChatResponseDtoPayload[] = []
    let medicalAlertsLookup: Record<string, DecoratedAlertViewModel[]> = {}

    const { userToTypeLookup, mobileIdToDisplayNameLookup, responseIdToGetResponseDto } = s

    //Now that we have last messages by user id, conditionally aggregate all responses
    s.currentSchoolChatMessages.forEach((dto) => {
      const isChatResponseDto = ChatMessageDtoHelper.isChatResponseDto(dto)
      // We're only interested in chat response dtos for historic map processing.
      if (!isChatResponseDto) {
        return
      }
      if (!dto.payload?.mobileUserId) {
        return
      }
      const { responseIdsByResponseGroupTypeLookup, responseIdToGetResponseDto } = s
      const responseIdsConsideredThreatIndicator =
        responseIdsByResponseGroupTypeLookup[ResponseGroupTypeEnum.alert]
      const responseIdsCondsideredMedicalAlert =
        responseIdsByResponseGroupTypeLookup[ResponseGroupTypeEnum.medicalAlert]

      //Check for unsolicited alerts
      let isThreatIndicator = ChatMessageDtoHelper.isSolicitedAlertPayload(
        dto,
        responseIdsConsideredThreatIndicator
      )
      //Check for alert poll responses
      if (
        isChatResponseDto &&
        ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload) &&
        PredefinedMessageHelper.isThreatIndicator(
          responseIdToGetResponseDto[dto.payload.responseId].type
        )
      ) {
        isThreatIndicator = true
      }
      const isSos = isChatResponseDto && dto.payload.isSos && !dto.payload.responseId

      const isMedicalAlert =
        isChatResponseDto &&
        ChatMessageDtoHelper.isSolicitedMedicalAlertResponse(
          dto,
          responseIdsCondsideredMedicalAlert
        )

      if (isThreatIndicator || isSos || isMedicalAlert) {
        const alertVm = {
          mobileUserId: dto.payload.mobileUserId,
          userType: userToTypeLookup[dto.payload.mobileUserId ?? '0'],
          fullName: mobileIdToDisplayNameLookup[dto.payload.mobileUserId ?? '0'],
          chatResponseDtoPayload: dto.payload,
          response: responseIdToGetResponseDto[dto.payload.responseId ?? 0]
        } as DecoratedAlertViewModel

        // Due to mixture of contracts we have to rely on isSos and response id for balcony backwards compatibility
        if (isThreatIndicator) {
          // console.log(`Processing OWL type of alert`, dto.payload)
          const existingVms: DecoratedAlertViewModel[] =
            threatIndicatorLookup[dto.payload.mobileUserId ?? '0'] ?? []
          threatIndicatorLookup[dto.payload.mobileUserId ?? '0'] = [...existingVms, alertVm]
        } else if (
          // Here for reverse compatability for balcony since we must handle isSos without response id logic until we decouple from Balcony completely
          isSos
        ) {
          // console.log(`Processing historic balcony type of alert`, dto.payload)
          const existingVms: DecoratedAlertViewModel[] =
            threatIndicatorLookup[dto.payload.mobileUserId ?? '0'] ?? []
          threatIndicatorLookup[dto.payload.mobileUserId ?? '0'] = [...existingVms, alertVm]
        } else if (isMedicalAlert) {
          // console.log(`processing historic medical alert ${dto.payload.responseId}`)
          const existingVms: DecoratedAlertViewModel[] =
            medicalAlertsLookup[dto.payload.mobileUserId ?? '0'] ?? []
          medicalAlertsLookup[dto.payload.mobileUserId ?? '0'] = [...existingVms, alertVm]
        }
        //Threat indicator logic is more specific than alert logic, alerts can be based on isSos and subset of response ids while threat indicator requires explicit demarcation
        if (
          ChatMessageDtoHelper.isChatResponseDto(dto) &&
          ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload)
        ) {
          if (
            PredefinedMessageHelper.isThreatIndicator(
              s.responseIdToGetResponseDto[dto.payload.responseId].type
            )
          ) {
            // console.log(`processing historic threat indicator ${dto.payload.responseId}`)
            threatIndicators.push(dto.payload)
          }
        }
      }

      if (ChatMessageDtoHelper.isChatResponsePollDto(dto) && dto.payload.pollLogicalId) {
        const userType = s.userToTypeLookup[dto.payload.mobileUserId]
        if (userType === MobileUserTypes.student) {
          studentPollResponses.push(dto.payload)
        } else if (userType === MobileUserTypes.teacher) {
          // console.log(`Counting teacher poll response`)
          teacherPollResponses.push(dto.payload)
        } else if (userType === MobileUserTypes.otherStaff) {
          otherStaffPollResponses.push(dto.payload)
        } else if (userType === MobileUserTypes.guest) {
          guestPollResponses.push(dto.payload)
        }
      } else if (ChatMessageDtoHelper.isChatResponseMessageDto(dto)) {
        const userType = s.userToTypeLookup[dto.payload.mobileUserId]
        if (userType === MobileUserTypes.student) {
          studentMessages.push(dto.payload)
        } else if (userType === MobileUserTypes.teacher) {
          teacherMessages.push(dto.payload)
        } else if (userType === MobileUserTypes.otherStaff) {
          otherStaffMessages.push(dto.payload)
        } else if (userType === MobileUserTypes.guest) {
          guestMessages.push(dto.payload)
        }
      }
    })
    return {
      userToTypeLookup: s.userToTypeLookup,
      responseIdToStatusColorEnum: s.responseIdToStatusColorEnum,
      responseIdToGetResponseDto: s.responseIdToGetResponseDto,
      locationVmLookup: s.userLocationLookup,
      threatIndicatorLookup,
      medicalAlertsLookup,
      threatIndicators,
      studentPollResponses,
      teacherPollResponses,
      otherStaffPollResponses,
      guestPollResponses,
      studentMessages,
      teacherMessages,
      otherStaffMessages,
      guestMessages
    }
  }
  /** Logic for which chat messages to display on the map, when. */
  static filterChatMessageByType = (
    userToType: Record<string, MobileUserTypes>,
    visibilityVm: ILocationVisibilityViewModel,
    dto: ChatMessageDto
  ): boolean => {
    if (!ChatMessageDtoHelper.isChatResponseDto(dto)) {
      return false
    }
    if (!dto.payload.mobileUserId) {
      return false
    }
    const userType = userToType[dto.payload.mobileUserId] ?? null
    if (
      (visibilityVm.showGuests && userType === MobileUserTypes.guest) ||
      (visibilityVm.showOtherStaff && userType === MobileUserTypes.otherStaff) ||
      (visibilityVm.showStudents && userType === MobileUserTypes.student) ||
      (visibilityVm.showTeachers && userType === MobileUserTypes.teacher)
    ) {
      return true
    } else {
      return false
    }
  }
  /** Handle adding and changing the areas that are displayed on the map */
  static getVisibilityWithSchoolDto = (
    schoolDto: GetSchoolDto | null,
    areasVisible: boolean,
    areaStatusLookup: Record<number, AreaStatusViewModel>,
    selectedAreaId: number
  ) => ({
    areasVisible,
    schoolDto,
    areaStatusLookup,
    selectedAreaId
  })
}
