import { IAppConfig } from '@service/app-config/app-config.model'

export class OwlChatValidationsViewModel {
  static validateUpdate = (text: string, config: IAppConfig): boolean => {
    const messageHasContent = OwlChatValidationsViewModel.isNotEmptyMessage(text)
    if (!messageHasContent) {
      return false
    } else if (this.updateTooLong(text, config)) {
      return false
    }
    return true
  }
  static validateInstruction = (text: string, config: IAppConfig): boolean => {
    const messageHasContent = OwlChatValidationsViewModel.isNotEmptyMessage(text)
    const lastReturnIsToWhiteSpace = OwlChatValidationsViewModel.lastCarriageReturnAllowed(
      text,
      config
    )
    if (!messageHasContent) {
      return false
    } else if (this.instructionTooLong(text, config)) {
      return false
    } else if (this.tooManyLinesForInstruction(text, config) && !lastReturnIsToWhiteSpace) {
      return false
    }
    return true
  }
  /** Ensures that the last line consists of spaces or if there is no content, here in case the uses the enter key to send the message after typing the sixth line to avoid invalid message due to the max number of manual line breaks.
   * @param text
   * @param config
   * @returns boolean
   */
  static lastCarriageReturnAllowed = (text: string, config: IAppConfig): boolean => {
    const textByLine = text?.split('\n')
    const numberOfLines = textByLine?.length
    const textInLastLine = textByLine?.[numberOfLines - 1]
    return (
      (!!textInLastLine &&
        textInLastLine.trim() === '' &&
        numberOfLines === config.INSTRUCTION_MAX_NEW_LINE_RETURN + 1) ||
      (!textInLastLine && numberOfLines === config.INSTRUCTION_MAX_NEW_LINE_RETURN + 1)
    )
  }
  static getReturnCarriageRemovedString = (text: string): string => {
    return text?.replaceAll('\n', '')
  }
  static isNotEmptyMessage = (text: string): boolean => {
    const messageWithoutNewLines = OwlChatValidationsViewModel.getReturnCarriageRemovedString(text)
    const messageWithoutSpaces = messageWithoutNewLines?.replaceAll(' ', '')
    return messageWithoutSpaces?.length > 0
  }
  static instructionTooLong = (text: string, config: IAppConfig): boolean => {
    const messageWithoutNewLines = OwlChatValidationsViewModel.getReturnCarriageRemovedString(text)
    return messageWithoutNewLines.length > config.INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE
  }
  static updateTooLong = (text: string, config: IAppConfig): boolean => {
    const messageWithoutNewLines = OwlChatValidationsViewModel.getReturnCarriageRemovedString(text)
    return messageWithoutNewLines.length > config.UPDATES_MAX_CHARACTERS_PER_MESSAGE
  }
  static tooManyLinesForInstruction = (text: string, config: IAppConfig): boolean => {
    const result = text.split('\n').length
    return result > config.INSTRUCTION_MAX_NEW_LINE_RETURN
  }
  static instructionTooLongErrorMessage = (config: IAppConfig): string => {
    return `Instructions can't exceed ${config.INSTRUCTION_MAX_CHARACTERS_PER_MESSAGE} characters.`
  }
  static updateTooLongErrorMessage = (config: IAppConfig): string => {
    return `Update can't exceed ${config.UPDATES_MAX_CHARACTERS_PER_MESSAGE} characters.`
  }
  static tooManyLinesErrorMessage = (config: IAppConfig): string => {
    return `Instructions can't exceed ${config.INSTRUCTION_MAX_NEW_LINE_RETURN} line breaks.`
  }
}
