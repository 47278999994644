import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import { handleToastVm } from '@action/global-app.actions'
import { receiveSentSignalrChatMessage } from '@action/signalr.action'
import {
  setMapUiControlSessionState,
  showDrillModeChangeModal,
  showOwlSchoolStateChangeModal,
  showOwlSchoolStateConcludeEventModal,
  toggleMapUiControl,
  updateSchoolOwlState,
  updateSchoolState
} from '@action/user/dashboard-page.action'
import { sendCheckStatusSuccess } from '@action/user/message-api.action'

import { setScrollPlacesInLocalStorage } from '@action/dashboard-page/chat-ui.actions'
import { Injectable } from '@angular/core'
import { ToastVmIcons } from '@component/shared/snackbar/snackbar.component.model'
import { PredefinedMessageModelHelper } from '@domain/dto-helpers/predefined-message-model.helper'
import { DrillModeEnum } from '@model/drill-mode.model'
import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import { ChatMessageDtoPayload } from '@model/message.model'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { selectScrollPlacesInChatRooms } from '@selector/page/dashboard-page/chat-ui-selectors'
import { selectGroupedPredefinedMessages } from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import { selectSideNavSettings } from '@selector/page/dashboard-page/dashboard-view.selectors'
import { AppConfigService } from '@service/app-config/app-config.service'
import { LocalStorageKeys, SessionStorageKeys } from '@service/browser/base-storage.service'
import { LocalStorageService } from '@service/browser/local-storage.service'
import { SessionStorageService } from '@service/browser/session-storage.service'
import { ModalService } from '@service/material/modal.service'
import {
  AutoSentMessageSnackBarViewModel,
  IToastViewModel
} from '@view/chat/auto-sent-message-snackbar.view'
import { ScrollPlacesInChatRoom } from '@view/chat/chat.view'
import { getDrillModeModalViewModel } from '@view/pages/dashboard-page/drill-mode-modal.view'
import { getSchoolModalViewModel } from '@view/pages/dashboard-page/school-owl-state-modal.view'
import { tap } from 'rxjs'

@Injectable()
export class DashboardPageEffect {
  constructor(
    private actions$: Actions,
    private modalService: ModalService,
    private appConfig: AppConfigService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService
  ) {}
  showTransitionSchoolStateModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showOwlSchoolStateChangeModal),
        tap(
          (
            action: ExtendedAction<
              SchoolStateTransitionEnum | EmergencyResponeEnum | AttackerTypeEnum
            >
          ) => this.showTransitionStateModal(action.payload)
        )
      ),
    { dispatch: false }
  )
  showConcludeEventModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showOwlSchoolStateConcludeEventModal),
        tap((_) =>
          this.modalService.showSchoolStateConcludeModal(
            () => {
              this.store.dispatch(
                updateSchoolState(getExtendedAction(SchoolStateTransitionEnum.dismissEmergency))
              )
            },
            () => {}
          )
        )
      ),
    { dispatch: false }
  )
  showTransitionStateModal = (
    t: SchoolStateTransitionEnum | EmergencyResponeEnum | AttackerTypeEnum
  ) => {
    this.modalService.showSchoolStateTransitionModal(
      getSchoolModalViewModel(
        t,
        () => this.store.dispatch(updateSchoolState(getExtendedAction(t))),
        () => {}
      )
    )
  }

  // Only show dashboard toast when the feature flag is enabled
  sendCheckStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendCheckStatusSuccess),
        tap(() => {
          if (this.appConfig.config.FEATURE_FLAGS.USE_DASHBOARD_TOAST_NOTIFICATIONS) {
            const toastVm: IToastViewModel = {
              iconName: ToastVmIcons.PulsingExclamationMark,
              title: '',
              message: 'Prompt sent successfully !'
            }
            this.store.dispatch(handleToastVm(getExtendedAction(toastVm)))
          }
        })
      ),
    { dispatch: false }
  )

  showToastOnRulesEngineAutoSentMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(receiveSentSignalrChatMessage),
        concatLatestFrom(() => this.store.select(selectGroupedPredefinedMessages)),
        tap(([{ payload: dto }, predefinedGroupDtos]) => {
          const { autoSent, groupId } = dto.payload as ChatMessageDtoPayload
          if (autoSent && groupId) {
            const message = PredefinedMessageModelHelper.getMessageDescByGroupId(
              groupId,
              predefinedGroupDtos,
              true
            )
            if (!message) {
              return
            }
            const toastVm = AutoSentMessageSnackBarViewModel.getAutoSentSnackbarVm(dto, message)
            if (this.appConfig.config.FEATURE_FLAGS.USE_DASHBOARD_TOAST_NOTIFICATIONS) {
              this.store.dispatch(handleToastVm(getExtendedAction(toastVm)))
            }
          }
        })
      ),
    { dispatch: false }
  )

  //DRILL MODE
  showDrillModeTransitionModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showDrillModeChangeModal),
        tap((action: ExtendedAction<DrillModeEnum>) =>
          this.showDrillModeTransitionModal(action.payload)
        )
      ),
    { dispatch: false }
  )
  showDrillModeTransitionModal = (t: DrillModeEnum) => {
    this.modalService.showTransitionModal(
      getDrillModeModalViewModel(
        t,
        () =>
          this.store.dispatch(
            updateSchoolOwlState(
              getExtendedAction({
                drillMode: t === DrillModeEnum.ACTIVE ? true : false
              } as SchoolOwlState)
            )
          ),
        () => {}
      )
    )
  }
  setScrollPlaces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setScrollPlacesInLocalStorage),
        concatLatestFrom(() => this.store.select(selectScrollPlacesInChatRooms)),
        tap(([action, scrollPlaces]) => {
          if (action) {
            this.localStorageService.setKey<Record<string, ScrollPlacesInChatRoom>>(
              LocalStorageKeys.chatMessageReadReceipt,
              scrollPlaces
            )
          }
        })
      ),
    { dispatch: false }
  )
  syncMapUiControlChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setMapUiControlSessionState),
        tap((action) => {
          this.sessionStorageService.setKey(
            SessionStorageKeys.userSideNavSettingsKey,
            action.payload
          )
        })
      ),
    { dispatch: false }
  )
  toggleMapUiControl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleMapUiControl),
        concatLatestFrom(() => [this.store.select(selectSideNavSettings)]),
        tap(([action, settings]) => {
          const newSessionObj = {
            ...settings,
            [action.payload]: settings[action.payload]
          }
          this.sessionStorageService.setKey(
            SessionStorageKeys.userSideNavSettingsKey,
            newSessionObj
          )
        })
      ),
    { dispatch: false }
  )
}
