import { SchoolDtoProps } from '@model/school/school.model'
import { ProfileUserVm, UserDtoProps, UserTypes } from '@model/user/user.model'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OwlStateSlices } from '@state/app.state'
import { GlobalState, GlobalStateProps } from '@state/auth.state'
import { selectUrl } from './route.selector'

export const selectGlobalState = createFeatureSelector<GlobalState>(OwlStateSlices.auth)

export const selectUserAccessToken = createSelector(selectGlobalState, (state) => state.token)

//LOGGED IN USER
export const selectUserDto = createSelector(selectGlobalState, (state) => state.userDto ?? null)
export const selectLoggedInUserId = createSelector(selectUserDto, (userDto) => userDto?.id ?? null)
export const selectIdTokenClaims = createSelector(selectGlobalState, (state) => state?.claims ?? null)
export const selectUserProvidedPostalCode = createSelector(
  selectIdTokenClaims,
  (claims: any) => claims?.postalCode ?? null
)

//SELECTED SCHOOL (by default)
export const selectSelectedSchoolId = createSelector(
  selectGlobalState,
  (state) => state[GlobalStateProps.selectedSchoolId] ?? null
)
export const selectGetSchoolUsersApiStates = createSelector(
  selectGlobalState,
  (state) => state[GlobalStateProps.getUserSchoolApiState] ?? null
)
export const selectSchoolLookupById = createSelector(
  selectGlobalState,
  (state) => state[GlobalStateProps.userSchoolLookup] ?? null
)
export const selectSchoolById = createSelector(
  selectSchoolLookupById,
  selectSelectedSchoolId,
  (lookup, schoolId) => (schoolId && lookup[schoolId.id] ? lookup[schoolId.id] : null)
)

export const selectSchoolSubareas = createSelector(
  selectSchoolById,
  (schoolDto) => schoolDto?.subareas ?? null
)
export const selectSchoolConfiguration = createSelector(
  selectSchoolById,
  (schoolDto) => schoolDto?.[SchoolDtoProps.schoolConfiguation] ?? null
)
export const selectSchoolMapConfigPageStatePrerequistes = createSelector(
  selectUrl,
  selectGlobalState,
  GlobalState.getSchoolMapPrerequistes
)
//OSM Data
export const selectSchoolDtoWithRecommendedPolygon = createSelector(
  selectGlobalState,
  selectSchoolById,
  GlobalState.getBoundaryInteractionPrerequisites
)

//API STATE
//users
export const selectGetSchoolUsersApiStateById = createSelector(
  selectGetSchoolUsersApiStates,
  selectSelectedSchoolId,
  (apiState, schoolId) => (schoolId && apiState[schoolId.id] ? apiState[schoolId.id] : null)
)
//school
export const selectSchoolApiById = createSelector(
  selectGetSchoolUsersApiStates,
  selectSelectedSchoolId,
  (apiState, schoolId) => (schoolId && apiState[schoolId.id] ? apiState[schoolId.id] : null)
)

//Users
export const selectSchoolUsersBySchoolId = createSelector(
  selectGlobalState,
  (state) => state[GlobalStateProps.usersForSchoolLookup] ?? null
)
export const selectSchoolUsers = createSelector(
  selectSchoolUsersBySchoolId,
  selectSelectedSchoolId,
  (lookup, schoolId) => (schoolId && lookup[schoolId.id] ? lookup[schoolId.id] : null)
)
export const selectCurrentOrgStats = createSelector(
  selectGlobalState,
  (state) => state[GlobalStateProps.currentOrgStats]
)

//SCHOOL CONFIG SELECTIONS
export const selectSchoolOwlState = createSelector(
  selectSchoolById,
  (schoolDto) => schoolDto?.schoolOwlState ?? null
)
export const selectOwlSchoolStateProperty = createSelector(
  selectSchoolOwlState,
  (schoolOwlState) => schoolOwlState?.state ?? null
)
export const selectSchoolPreference = createSelector(
  selectSchoolOwlState,
  (schoolOwlState) => schoolOwlState?.preference ?? ''
)
export const selectSchoolDrillMode = createSelector(
  selectSchoolOwlState,
  (schoolOwlState) => schoolOwlState?.drillMode ?? false
)
export const selectGlobalAppVm = createSelector(selectGlobalState, (state) => state.vm)
export const selectNumberOfToastsInQueue = createSelector(selectGlobalAppVm, (vm) => vm.queueCount)
export const selectTimestampOfSingleToast = createSelector(
  selectGlobalAppVm,
  (vm) => vm.timestampStartOfOnlyToast
)

export const selectUsersForSchoolLookup = createSelector(
  selectGlobalState,
  selectSelectedSchoolId,
  (state, schoolId) => state[GlobalStateProps.usersForSchoolLookup][schoolId.id] ?? null
)

export const selectIsProfileOpened = createSelector(
  selectGlobalState,
  (authState) => authState?.vm?.profileOpened
)

export const selectProfileData = createSelector(
  selectUserDto,
  selectSchoolById,
  selectIsProfileOpened,
  (user, school, isOpened): ProfileUserVm => {
    return {
      isOpened: isOpened,
      initials: `${user?.[UserDtoProps.firstName]?.[0]}${user?.[UserDtoProps.lastName]?.[0]}`,
      userFullName: `${user?.[UserDtoProps.firstName]} ${user?.[UserDtoProps.lastName]}`,
      userEmail: user?.[UserDtoProps.email],
      schoolName: school?.[SchoolDtoProps.name],
      canDownloadSignUps: user?.[UserDtoProps.type] === UserTypes.globalAdmin
    }
  }
)

export const selectClientSideAdditionOfAtLeastOneUser = createSelector(
  selectGlobalState,
  (authState) => authState?.currentOrgStats?.clientSideAdditionOfAtLeastOneUser ?? false
)

export const selectEnabledSiteLinks = createSelector(
  selectCurrentOrgStats,
  selectUrl,
  (orgStats, url) => GlobalState.getEnabledSiteLinks(orgStats, url)
)
