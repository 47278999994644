import {
  DEFAULT_CHATROOM_DISPLAY_TEXT,
  DEFAULT_CHATROOM_ID,
  ENTIRE_SCHOOL_CHATROOM_DISPLAY_TEXT,
  ENTIRE_SCHOOL_CHATROOM_ID
} from '@shared/constants'
import { ChatRoomMessagesLookup, ChatRoomTypeEnum } from './chat.view'
import { IChatRoomViewModel } from './chat-room.view'
import { ChatMessageDto, ChatResponseDtoPayload } from '@model/message.model'

/**
 * @param chatMenuOpen Scroll into view requires knowledge of whether the chat ui is shown.
 * @param index Additionally, index of the current chat room id in context to all other chatrooms.
 * @param chatRoomId Needed to know if the room button is enabled.
 * */
export interface IChatRoomSelectViewModel {
  selectedChatRoomId: string
  /** This index will only be provided when we need to show a selected chat room id, null when chat ui is hidden */
  index: number | null
  chatUiVisible: boolean
}
export class ChatRoomSelectViewModel {
  static EVERYONE_CHAT_ROOM_VM: IChatRoomViewModel = {
    label: DEFAULT_CHATROOM_DISPLAY_TEXT,
    chatRoomId: DEFAULT_CHATROOM_ID,
    // areaId: 0,
    areaLogicalId: DEFAULT_CHATROOM_ID,
    mobileUserIds: null,
    type: ChatRoomTypeEnum.everyone
  }
  static ENTIRE_SCHOOL_CHAT_ROOM_VM: IChatRoomViewModel = {
    label: ENTIRE_SCHOOL_CHATROOM_DISPLAY_TEXT,
    chatRoomId: ENTIRE_SCHOOL_CHATROOM_ID,
    areaLogicalId: DEFAULT_CHATROOM_ID,
    mobileUserIds: null,
    type: ChatRoomTypeEnum.entireSchool
  }
  static DEFAULT_CHAT_ROOM_VM_LOOKUP = {
    [DEFAULT_CHATROOM_ID]: ChatRoomSelectViewModel.EVERYONE_CHAT_ROOM_VM,
    [ENTIRE_SCHOOL_CHATROOM_ID]: ChatRoomSelectViewModel.ENTIRE_SCHOOL_CHAT_ROOM_VM
  }
  /** Logic that removes any chat room attributes based on chat messages in chat room by id message lookup,
   * @param selectedChatRoomId If the user has a selected chat room, never remove that one.
   *
   */
  static clearEmptyChatRooms = (
    selectedChatRoomId: string,
    chatRoomIdToAttributesLookup: Record<string, IChatRoomViewModel>,
    lookup: ChatRoomMessagesLookup
  ): Record<string, IChatRoomViewModel> => {
    let newChatRoomIdToAttributesLookup = { ...chatRoomIdToAttributesLookup }
    Object.entries(chatRoomIdToAttributesLookup).forEach((entry) => {
      const key = entry[0]
      // If the chat room doesn't have chat messages let's remove the attributes from the lookup
      const numberOfMessages = lookup[key]?.length ?? 0
      //Iterate over all chat rooms and remove empty ones
      if (
        numberOfMessages === 0 &&
        key !== selectedChatRoomId &&
        key !== DEFAULT_CHATROOM_ID &&
        key != ENTIRE_SCHOOL_CHATROOM_ID
      ) {
        delete newChatRoomIdToAttributesLookup[key]
      }
    })
    return newChatRoomIdToAttributesLookup
  }
  static buildAreaChatRoomSelectVm = (
    areaLogicalId: string,
    areaName: string
  ): IChatRoomViewModel => {
    return {
      areaLogicalId: areaLogicalId,
      chatRoomId: areaLogicalId,
      label: areaName,
      mobileUserIds: null,
      type: ChatRoomTypeEnum.subarea
    }
  }
  static buildOneOnOneChatRoomSelectVm = (
    mobileUserId: string,
    lookup: Record<string, string>
  ): IChatRoomViewModel => {
    return {
      areaLogicalId: null,
      chatRoomId: mobileUserId,
      label: lookup[mobileUserId],
      // areaId: null,
      mobileUserIds: [mobileUserId],
      type: ChatRoomTypeEnum.oneOnOne
    }
  }
  /** Here to process a chat message dto payload, and if the chat room id isn't the user's mobile id or the everyone chat room indictor, then add the dto to the lookup. */
  static handleAddChatMessageDtoToAreaChatRoom = (
    dtoPayload: ChatResponseDtoPayload,
    dto: ChatMessageDto,
    lookup: ChatRoomMessagesLookup
  ): ChatRoomMessagesLookup => {
    if (
      dtoPayload.chatRoomId &&
      dtoPayload.mobileUserId !== dtoPayload.chatRoomId &&
      dtoPayload.chatRoomId !== DEFAULT_CHATROOM_ID
    ) {
      // console.log(`Adding message to area chat room`)
      // console.log(dtoPayload.chatRoomId)
      const existingMessage = lookup[dtoPayload.chatRoomId] ?? []
      lookup[dtoPayload.chatRoomId] = [...existingMessage, dto]
    }
    return lookup
  }
}
