import { AlertDto } from "@model/alert.model"
import { FullLocation, LatLon, LatLonAlt } from "@model/location.model"
import { MobileUserTypes } from "@model/user/user.model"

export const maxZoom = 21
export const numberOfAnonAlerts = 20
export const numberRegisteredAlerts = 30
export const multiplier = .001
export const mockCenterLatLon: FullLocation = {
    lat: 42.1996750000001,
    lon: -87.945054,
    altitude: null,
    accuracy: null
}
// Mercator
export const mockCenter: LatLon = {
    lat: -9789984.289543908,
    lon: 5190955.263449362,
}

export const getAnonMockAlert = (): AlertDto => ({
    latLong: {
        lat: mockCenterLatLon.lat + (Math.random() * multiplier),
        lon: mockCenterLatLon.lon + (Math.random() * multiplier / 2),
        altitude: null,
        accuracy: null
    },
    userType: MobileUserTypes.otherStaff,
    text: 'This is an anonymous alert',
    dateTime: `${new Date()}`,
    id: 1,
    schoolId: 1,
    // balconyId: 'anon',
})
export const adminAlert: AlertDto = {
    latLong: mockCenterLatLon,
    userType: MobileUserTypes.student,
    text: 'This is an admin alert',
    dateTime: `${new Date()}`,
    id: 1,
    schoolId: 1,
    // balconyId: 'anon',
}
export const getRegisteredMockAlert = (): AlertDto => ({
    latLong: {
        lat: mockCenterLatLon.lat + (Math.random() * multiplier),
        lon: mockCenterLatLon.lon + (Math.random() * multiplier / 2),
        altitude: null,
        accuracy: null
    },
    userType: MobileUserTypes.youngStudent,
    text: 'This is a registered user alert',
    dateTime: `${new Date()} `,
    id: 1,
    schoolId: 1,
    // balconyId: 'anon',
})
export const mockAnonAlerts = new Array(numberOfAnonAlerts).fill(0).map(getAnonMockAlert)
export const mockRegisteredAlerts = new Array(numberRegisteredAlerts).fill(0).map(getRegisteredMockAlert)
export const mockAlerts = [...mockAnonAlerts, ...mockRegisteredAlerts, adminAlert]

// console.log(JSON.stringify(mockAlerts))
