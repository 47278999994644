import { Injectable } from '@angular/core'
import { IToastViewModel } from '@view/chat/auto-sent-message-snackbar.view'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  snackbarSubject = new Subject<IToastViewModel>()
  public snackbarState = this.snackbarSubject.asObservable()

  constructor() {}

  show = (toastVm: IToastViewModel) => {
    this.snackbarSubject.next(toastVm)
  }
}
