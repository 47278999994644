import { Geometry, Point, Polygon, SpatialReference, Polyline } from '@arcgis/core/geometry'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import { ChatResponseDtoPayload } from '@model/message.model'

import Graphic from '@arcgis/core/Graphic'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine.js'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisPointFactory } from '../arcgis-point-factory'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'
import Multipoint from '@arcgis/core/geometry/Multipoint.js'
import { GetResponseDto } from '@model/message/predefined-message.model'

export namespace ArcGisGeometryHelper {
  /** Here to take a chat response dto, and use it's location coordinates to figure out what area id it pertains to if any. */
  export const mercatorSpatialReference = new SpatialReference({
    wkid: 3857 // ProjectionNumberEnum.EPSG_900913
  })
  export const latLongSpatialReference = new SpatialReference({
    wkid: 4326 // ProjectionNumberEnum.EPSG_900913
  })
  export const getConvexHull = (proximityPolygon: __esri.Geometry): __esri.Geometry => {
    const diffedGeom = geometryEngine.convexHull(proximityPolygon)
    if (Array.isArray(diffedGeom)) {
      throw Error(`getConvexHull go unexpected value`)
    }
    return diffedGeom
  }
  export const getDiff = (
    inputGeom: __esri.Geometry,
    subtractGeom: __esri.Geometry
  ): __esri.Geometry => {
    const diffedGeom = geometryEngine.difference(inputGeom, subtractGeom)
    if (Array.isArray(diffedGeom)) {
      throw Error(`getDiff not implemented to work on output of array`)
    }
    return diffedGeom
  }
  export const unionGraphics = (graphics: __esri.Geometry[]): __esri.Geometry => {
    return geometryEngine.union(graphics)
  }
  /** Expands a geometry by the default threat point buffer passed into function
   * @param points represents all points that incorporate the threat area
   * @param buffer the buffer area to expand around the points
   */
  export const getBufferedConvexHull = (
    pointsForHull: Point[],
    buffer: number
  ): Geometry | null => {
    if (pointsForHull.length === 0) {
      console.warn(`Called getBufferedConvexHull with empty points array`)
      return null
    }
    const points = pointsForHull.map((p) => [p.longitude, p.latitude])
    // console.log(`initial number of points for threat indicator`, points.length)
    let multiPoint = new Multipoint({
      points,
      spatialReference: ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    })
    //We need to deduplicate the vms because we may have a convex hull of a straight line and that'll prevent it from being shown.
    // console.log(`multi point geometry `, multiPoint)
    const simplifiedMultiPoint = geometryEngine.simplify(multiPoint)
    if (simplifiedMultiPoint instanceof Multipoint) {
      // console.log(`simplified points count`, (simplifiedMultiPoint as any)?.points?.length)
    }
    const hull = geometryEngine.convexHull(simplifiedMultiPoint, true)
    // console.log(`Created convex hull from points for threat locations.`, hull)
    if (Array.isArray(hull)) {
      return null
    }
    const bufferedPolygon = ArcGisGeometryHelper.getBufferedPolygonByFeet(hull as Polygon, buffer)
    // console.log(`Processing results in bufferedPolygon`, bufferedPolygon)
    // console.log(`used buffer value `, buffer)
    return bufferedPolygon
  }
  export const getBufferedPolygonByFeet = (polygon: Polygon, feet: number) => {
    const projectedPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
      polygon,
      mercatorSpatialReference
    )
    const bufferedPolygon = geometryEngine.buffer(
      projectedPolygon,
      feet,
      'feet' as __esri.LinearUnits
    ) as Geometry
    const projectedBackPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
      bufferedPolygon,
      latLongSpatialReference
    )
    return projectedBackPolygon as Polygon
  }
  export const isUserInSelectedArea = (
    context: ArcGisMapService,
    lon: number,
    lat: number,
    selectedAreaId: number
  ): boolean => {
    if (!context.projectedAreaPolygonGeomLookups[selectedAreaId]) {
      console.error(`DEV ERROR: Selected area id not found on layer. ${selectedAreaId}`)
      return false
    }

    const projectedArea = context.projectedAreaPolygonGeomLookups[selectedAreaId]
    if (!projectedArea) {
      console.error(`DEV ERROR: Area is missing a precalulated projection area.`)
    }
    const userPoint = ArcGisPointFactory.getPoint(lon, lat)
    return geometryEngine.contains(projectedArea, userPoint)
  }
  /** Whenever we get a chat response we want to know what area id it's associated to */
  export const getAreaIdForPoint = (
    areaLayer: GraphicsLayer,
    point: __esri.Point
  ): number | null => {
    let areaId: number | null = null
    areaLayer.graphics.forEach((graphic) => {
      if (geometryEngine.contains(graphic.geometry, point)) {
        areaId = graphic.attributes.id ?? 0
      }
    })
    return areaId
  }
  /**
   * @param lookup is a lookup of all users in the system with their most recent poll response
   * @param areaLayer is the layer that contains all the areas
   * @returns a lookup of area id to all users responses in that area
   */
  export const returnResponseByArea = (
    lookup: Record<string, ChatResponseDtoPayload> | Record<string, ChatResponseDtoPayload[]>,
    areaLayer: GraphicsLayer,
    responseDtoLookup: Record<number, GetResponseDto>
  ): Record<number, ChatResponseDtoPayload[]> => {
    let result: Record<number, ChatResponseDtoPayload[]> = {}
    Object.entries(lookup).forEach(
      (item: [string, ChatResponseDtoPayload | ChatResponseDtoPayload[]]) => {
        //Works with payload if it's an single object or an array of objects
        const dtoOrDtos = item[1]
        if (dtoOrDtos instanceof Array) {
          dtoOrDtos.forEach((dto) => addDtoToResult(dto, result, areaLayer, responseDtoLookup))
        } else {
          addDtoToResult(dtoOrDtos, result, areaLayer, responseDtoLookup)
        }
      }
    )
    return result
  }
  /** Receives a result lookup to conditionally add a dto to by area id.  */
  export const addDtoToResult = (
    dto: ChatResponseDtoPayload,
    result: Record<number, ChatResponseDtoPayload[]>,
    areaLayer: GraphicsLayer,
    responseDtoLookup: Record<number, GetResponseDto>
  ): void => {
    const responseDto: GetResponseDto | undefined =
      responseDtoLookup[dto.responseId ?? 0] ?? undefined
    if (!responseDto) {
      console.warn(
        `returnGroupedUsersByArea: responseDto id not provided in responseDtoLookup: Record<number, GetResponseDto>, likely config error: {payload?.responseId} ${dto?.responseId}`
      )
    }
    const useForAreaStatus = responseDto?.calculateAreaStatus ?? false
    if (!useForAreaStatus) return
    //Construct a point for the location of the poll
    const point = ArcGisPointFactory.getPointFromChatRes(dto)
    if (!point) {
      console.warn(`addDtoToResult: point not found for chat resposne payload id: ${dto.id}`)
      return
    }
    const areaId = getAreaIdForPoint(areaLayer, point)
    if (areaId) {
      let existingDtos = result[areaId] ?? []
      result[areaId] = [...existingDtos, dto]
    }
  }
  export const getUsersInArea = (
    boundary: number[][] | null,
    lookup: Record<string, DecoratedUserLocationViewModel>,
    proximityDistanceInMeters: number
  ) => {
    let result: string[] = []
    if (!boundary) return result
    const polygon = new Polygon({ rings: [boundary] })
    const projectedPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
      polygon,
      mercatorSpatialReference
    )
    const bufferedPolygon = geometryEngine.buffer(
      projectedPolygon,
      proximityDistanceInMeters,
      'feet' as __esri.LinearUnits //export type LinearUnits = "meters" | "feet" | "kilometers" | "miles" | "nautical-miles" | "yards" | number;
    ) as Geometry
    const projectedBackPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
      bufferedPolygon,
      latLongSpatialReference
    )
    Object.entries(lookup).forEach((item) => {
      const location = item[1]
      //We may have no location info about new onboarded users that haven't provided a location yet
      if (!location.latLong) {
        return
      }
      const mobileId = item[0]
      if (
        geometryEngine.contains(
          projectedBackPolygon,
          new Point({
            x: location.latLong.lon,
            y: location.latLong.lat
          })
        )
      ) {
        result.push(mobileId)
      }
    })
    return result
  }
  export const getNearestCoordinate = (geometry: Polygon, x: number, y: number): Point => {
    return geometryEngine.nearestCoordinate(
      geometry,
      new Point({
        x: x,
        y: y
      })
    )?.coordinate
  }
  export const getThreatGraphic = (circlePoints: number[][]): Graphic => {
    return new Graphic({
      geometry: new Polygon({
        hasZ: false,
        hasM: false,
        rings: [circlePoints]
      }),
      symbol: ArcGisSymbolFactory.threatSymbol,
      attributes: ArcGisGraphicAttrFac.getAttributesForThreatGraphic()
    })
  }
  export const getSingleLocationThreatGraphic = (lon: number, lat: number): Graphic => {
    return new Graphic({
      geometry: ArcGisPointFactory.getPoint(lon, lat),
      symbol: ArcGisSymbolFactory.getDotSymbolForAlertMessage(
        ArcGisSymbolFactory.SINGLE_THREAT_LOCATION_SIZE,
        false
      ),
      attributes: ArcGisGraphicAttrFac.getAttributesForThreatGraphic()
    })
  }
  export const intersects = (geometry: Polygon, x: number, yMax: number, yMin: number): boolean => {
    return geometryEngine.intersects(
      geometry,
      new Polyline({
        paths: [
          [
            [x, yMax],
            [x, yMin]
          ]
        ]
      })
    )
  }
}
