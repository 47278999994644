import {
  ROUTER_ERROR,
  ROUTER_NAVIGATED,
  ROUTER_NAVIGATION,
  RouterNavigatedAction,
  routerCancelAction,
  routerErrorAction,
  routerNavigatedAction,
  routerNavigationAction,
  routerRequestAction
} from '@ngrx/router-store'
import { Action, createReducer, on } from '@ngrx/store'
import {
  IOwlRouterState,
  IOwlRouterTopLevelState,
  defaultOwlRouterTopLevelState
} from '@state/router.state'

const logStateAndReturn = (state: IOwlRouterTopLevelState, a: RouterNavigatedAction | any) => {
  // console.log(`router state action`)
  // console.log(a)
  switch (a.type) {
    case ROUTER_NAVIGATED:
      return {
        navigationId: a.payload.event.id,
        url: a.payload.routerState.url,
        // action: a,
        state: a.payload.routerState
        // lastNavigationEndEvent: a.payload.event
      }
    case ROUTER_ERROR:
      return state
    case ROUTER_NAVIGATION:
      return state
    default:
      return state
  }
}

//https://ngrx.io/guide/router-store/actions
const reducer = createReducer(
  defaultOwlRouterTopLevelState,
  // Success
  on(routerRequestAction, logStateAndReturn),
  on(routerNavigationAction, logStateAndReturn),
  on(routerNavigatedAction, logStateAndReturn),

  //Errors
  //TODO Add side effects to use logger when cancel or error occur
  on(routerCancelAction, logStateAndReturn),
  on(routerErrorAction, logStateAndReturn)
)

export function owlRouterReducer(state: IOwlRouterTopLevelState | undefined, action: Action) {
  return reducer(state, action)
}
