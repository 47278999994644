import { Point } from '@arcgis/core/geometry'
import { SchoolDtoProps } from '@model/school/school.model'
import { MIN_UI_RELATED_STATE_THROTTLE_TIME, defaultArcGisZoom } from '@shared/constants'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'

export class ArcGisHomeViewModel {
  static getSchoolDtoZoom = (context: ArcGisMapService): number => {
    if (!context.schoolDto) {
      console.error(`Must have school dto in order to get home view model.`)
      return defaultArcGisZoom
    }
    return context.schoolDto[SchoolDtoProps.schoolConfiguation]?.zoomLevel ?? defaultArcGisZoom
  }
  static getTargetGeometry = (context: ArcGisMapService): __esri.Geometry | null => {
    const { latLong } = context?.schoolDto ?? {}
    const { lat, lon } = latLong ?? {}
    if (!lat || !lon) {
      console.error(
        `Can't get target geometry for home button, must have school dto for lat and long as point of origin.`
      )
      return null
    }
    return new Point({
      x: lon,
      y: lat,
      spatialReference: ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    })
  }
  static getGoHomeOptions(context: ArcGisMapService): __esri.GoToOptions2D {
    return {
      animate: true,
      duration: MIN_UI_RELATED_STATE_THROTTLE_TIME,
      easing: 'ease-in-out'
    }
  }
}
