import Graphic from '@arcgis/core/Graphic'
import { ArcGisMapService } from './arcgis-map.service'

export namespace ArcgisHitTest {
  /** TODO Integrate use location layer once we integrate those popups as well */
  export const getHitForDashLayers = async (
    context: ArcGisMapService,
    action: __esri.ViewMouseWheelEvent | __esri.ViewPointerMoveEvent | __esri.ViewClickEvent
  ): Promise<__esri.HitTestResult | null> => {
    if (
      !context._schoolAreaLayer ||
      !context._studentLocationLayer ||
      !context._teacherLocationLayer ||
      !context._otherStaffLocationLayer ||
      !context._guestLocationLayer ||
      !context._pulsingAlertGraphicsLayer ||
      !context._dimmedAlertGraphicsLayer ||
      !context._attackAlertGraphicsLayer ||
      !context._medialAlertGraphicsLayer
    ) {
      return null
    }
    const result = await context._mapView.hitTest(action, {
      include: [
        context._schoolAreaLayer,
        context._studentLocationLayer,
        context._teacherLocationLayer,
        context._otherStaffLocationLayer,
        context._guestLocationLayer,
        context._pulsingAlertGraphicsLayer,
        context._dimmedAlertGraphicsLayer,
        context._attackAlertGraphicsLayer,
        context._medialAlertGraphicsLayer
      ]
    })
    // TODO Fix it so that a hit test that includes an alert, invokes the click handle
    // console.log(`Hit test`)
    // console.log(result)
    return result
  }
  export const getHitForMapConfigAreaLayer = async (
    context: ArcGisMapService,
    action: __esri.ViewMouseWheelEvent | __esri.ViewPointerMoveEvent | __esri.ViewClickEvent
  ): Promise<__esri.HitTestResult | null> => {
    if (!context._schoolAreaLayer) {
      return null
    }
    const result = await context._mapView.hitTest(action, {
      include: [context._schoolAreaLayer]
    })
    // TODO Fix it so that a hit test that includes an alert, invokes the click handle
    // console.log(`Hit test`)
    // console.log(result)
    return result
  }
  export const getHitForMapConfigPerimeterLayer = async (
    context: ArcGisMapService,
    action: __esri.ViewMouseWheelEvent | __esri.ViewPointerMoveEvent | __esri.ViewClickEvent
  ): Promise<__esri.HitTestResult | null> => {
    if (!context._schoolBoundaryLayer) {
      return null
    }
    const result = await context._mapView.hitTest(action, {
      include: [context._schoolBoundaryLayer]
    })

    return result
  }
  /** When working with a hit test result, extract the graphic or return null */
  export const getGraphicFromHitResult = (
    response: __esri.HitTestResult | null,
    context: ArcGisMapService
  ): Graphic | null => {
    const graphic = (getHitResult(response, context) as __esri.GraphicHit)?.graphic
    return graphic
  }
  export const getHitResult = (
    response: __esri.HitTestResult | null,
    context: ArcGisMapService
  ): __esri.ViewHit | null => {
    if (response?.results && response.results.length > 0) {
      const results: __esri.ViewHit[] = response.results
      const alertResult = results.find(
        (x) =>
          x.layer.id == context._layerTypeToIdLookup.pulsingAlert ||
          x.layer.id == context._layerTypeToIdLookup.medicalAlert ||
          x.layer.id == context._layerTypeToIdLookup.attackAlert
      )
      return alertResult ?? results[0]
    } else {
      return null
    }
  }
}
