import Graphic from '@arcgis/core/Graphic'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { SchoolAreaDto } from '@model/school/school-subarea.model'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'

export namespace ArcgisAreaGraphicHandler {
  /** Find the area on the map by id, and update it's attributes based on the updated school area dto. */
  export const updateAttributesWithNewDto = (context: ArcGisMapService, dto: SchoolAreaDto) => {
    const areaGraphicOnMap =
      context._schoolAreaLayer?.graphics.find((g) => g.attributes?.id === dto.id) ?? null
    if (!areaGraphicOnMap) {
      throw Error(`DEV ERROR: Attempting to update area attributes when area not on map ${dto.id}`)
    }
    const validAreaDto = SchoolAreaModelHelper.areaIsValid(dto)
    if (!validAreaDto) {
      return
    }
    areaGraphicOnMap.attributes = ArcGisGraphicAttrFac.getAttributesForAreaDto(dto)
  }
  /** Here to only remove border styles from non selected areas
   * @returns true if action was taken to update the area graphic*/
  export const updateAreaGraphicSymbolConditionally = (
    context: ArcGisMapService,
    g: Graphic,
    hover: boolean
  ) => {
    //First make sure we're not removing the selected style from a clicked area
    const isSelected =
      context.popupRef?.isShownDueToClick && g.attributes?.id === context.popupRef?.attributes?.id

    if (isSelected) {
      return false
    }
    setStyleForAreaGraphic(g, false, hover)
    return true
  }
  /** We'll need to optimize how area styles are set so to prep for that keep this extract function. */
  export const setStyleForAreaGraphic = (g: Graphic, selected: boolean, hovered: boolean) => {
    g.symbol = ArcGisSymbolFactory.getSchoolAreaSymbol(selected, g.attributes.statusVm, hovered)
  }
}
