import { Point } from '@arcgis/core/geometry'
import { LatLon } from '@model/location.model'
import { ChatResponseDtoPayload } from '@model/message.model'
import { ArcGisPointFactory } from '@service/map/arcgis/arcgis-point-factory'
import { TimeUtils } from '@shared/time.utils'

export enum ThreatSourceEnum {
  generalAlert = 'generalAlert',
  threatIndicatorMobileResponse = 'threatIndicatorMobileResponse'
}
export interface IAreaIdAndThreatVm {
  areaId: number
  threatVm: IThreatItemViewModel
}
export type IThreatItemViewModel =
  | {
      latLong: LatLon
      point: Point
      timestamp: Date
      logicalId: string
      type: ThreatSourceEnum.generalAlert
    }
  | {
      latLong: LatLon
      point: Point
      timestamp: Date
      type: ThreatSourceEnum.threatIndicatorMobileResponse
      logicalId: string
      responseId: number
      /** We have threat indicators that place icons on the map and need dimming alert logic but don't contribute to the threat area graphic */
      forZoneCalculation: boolean
    }
export interface ProcessThreatVmOptions {
  historicProcessing?: boolean
}
export class ThreatItemViewModel {
  static filterByDate(threatModelVms: IThreatItemViewModel[], d: Date) {
    return threatModelVms.filter((vm) => d.getTime() < vm.timestamp.getTime())
  }
  static getVmFromMessageWithResponseId(
    p: ChatResponseDtoPayload,
    responseId: number,
    forZoneCalculation: boolean
  ): IThreatItemViewModel | null {
    const { latLong, timestamp, logicalId } = p
    const date = TimeUtils.getDateFromString(timestamp)
    ThreatItemViewModel.validatePayloadConditionalError(p)
    if (date && latLong && logicalId) {
      return {
        logicalId,
        point: ArcGisPointFactory.getPoint(latLong.lon, latLong.lat),
        latLong,
        timestamp: date,
        responseId,
        type: ThreatSourceEnum.threatIndicatorMobileResponse,
        forZoneCalculation
      }
    } else {
      return null
    }
  }
  static getVmFromAlertPayload(p: ChatResponseDtoPayload): IThreatItemViewModel | null {
    const { latLong, timestamp, isSos, logicalId } = p
    const date = TimeUtils.getDateFromString(timestamp)
    ThreatItemViewModel.validatePayloadConditionalError(p)
    if (isSos && date && latLong && logicalId) {
      return {
        logicalId,
        point: ArcGisPointFactory.getPoint(latLong.lon, latLong.lat),
        latLong,
        timestamp: date,
        type: ThreatSourceEnum.generalAlert
      }
    } else {
      return null
    }
  }
  static validatePayloadConditionalError = (p: ChatResponseDtoPayload): void => {
    if (!p.logicalId) {
      console.warn(
        `SYSTEM ERROR: Attempting to construct payload from alert dto without logical id!`,
        p
      )
    }
  }
}
