<!-- matTooltip="{{tooltipText}}"
matTooltipPosition="above" -->
<button
  [ngClass]="{
    '--small-button-height': isSmallButtonHeight,
    '--default-button-height': isDefaultButtonHeight,
    '--large-button-height': isLargeButtonHeight,

    '--default-border-radius': isDefaultBorderRadius,
    '--large-border-radius': isLargeBorderRadius,
    '--small-border-radius': isSmallBorderRadius,

    '--full-width': isFullWidth,

    '--is-disabled': disabled,
    '--is-loading': loading,
    '--selected': isSelected,

    '--is-icon-left': isIconLeft,
    '--is-icon-right': isIconRight
  }"
  class="owl-custom-button --{{ buttonType }}"
  (click)="handleClick()"
  [disabled]="disabled"
>
  <div *ngIf="isIconLeft" class="_icon --left-icon">
    <img src="./assets/icons/{{ buttonIcon }}.svg" />
  </div>
  <span class="_loading-container" *ngIf="loading">
    <mat-spinner diameter="20"></mat-spinner>
  </span>
  <span class="_button-text" [ngClass]="{ '--no-icon': !buttonIcon }">{{ buttonText }}</span>
  <div *ngIf="isIconRight" class="_icon --right-icon">
    <img src="./assets/icons/{{ buttonIcon }}.svg" />
  </div>

  <div *ngIf="tooltipText" class="_tooltip-container">
    <span class="_tooltip">{{ tooltipText }}</span>
  </div>
</button>
