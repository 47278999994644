import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { DeleteAreaSideEffectPayload } from '@domain/dto-helpers/area-model.helper'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { IsSiteLinkInteractive, OwlRoutes } from '@domain/route/app-routes.domain'
import { mockUnknownLogicalId, mockUnknownSchoolId } from '@mock/school.mock'
import { CoordinateCollection, LatLon } from '@model/location.model'
import { GetPredefinedGroupDto } from '@model/message/predefined-message.model'
import { GetResponseGroupDto } from '@model/message/response-group.model'
import { SchoolOwlState } from '@model/school/school-configuation.model'
import {
  PostSchoolAreaDto,
  SchoolAreaDto,
  SchoolAreaDtoProps
} from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { OrgStatsModel } from '@model/user-organization/user-org-stats.model'
import { GetUserDto, SchoolIds } from '@model/user/user.model'
import { IToastViewModel } from '@view/chat/auto-sent-message-snackbar.view'
import { GlobalAppViewModel } from '@view/global.view'
import { SchoolBoundaryPartialState } from '@view/map/map.view'
import { ApiState } from './api.state'
import { RouteStateHelper } from './router.state'
export enum GlobalStateProps {
  //Core Auth State
  token = 'token',
  userDto = 'userDto',
  /** Stores the id token claims user provided during onboarding */
  claims = 'claims',

  //Auto Selected School
  selectedSchoolId = 'selectedSchoolId',

  //Get and Patch School
  schoolIdLookup = 'schoolIdLookup',
  schoolLogicalIdLookup = 'schoolLogicalIdLookup',
  userSchoolLookup = 'userSchoolLookup',
  getUserSchoolApiState = 'getUserSchoolApiState',
  patchUserSchoolApiState = 'patchUserSchoolApiState',

  //Get Users For School
  usersForSchoolLookup = 'usersForSchoolLookup',
  /** Every time we get a new relevant data, we recompute the org statistics based on that data.  */
  currentOrgStats = 'currentOrgStats',
  globalOrgApiDerivedState = 'globalOrgApiDerivedState',
  getUsersForSchoolApiState = 'getUsersForSchoolApiState',

  //School Config
  osmPolygon = 'osmPolygon',
  polygonApi = 'polygonApi',
  patchSchoolDtoApiState = 'patchSchoolDtoApiState',
  predefinedGroupsApiState = "predefinedGroupsApiState",
  responseGroupsApiState = "responseGroupsApiState"
}
/**
 *
 * Auth state includes school information because
 * the token determines the user dto
 * and the user dto determines which school dtos you can get.
 *
 * The patch school process may only be accomplished by a user which has a school admin user type so that is also driven by server side authorization.
 * */
export class GlobalState {
  public [GlobalStateProps.userDto]?: GetUserDto
  public [GlobalStateProps.claims]?: Object
  public [GlobalStateProps.token]?: string

  public [GlobalStateProps.selectedSchoolId]: SchoolIds = mockUnknownSchoolId

  /** A collection of all the users for a given school. Global since this is needed for the roster page and the dashboard page. */
  public [GlobalStateProps.usersForSchoolLookup]: Record<number, GetUserDto[]> = {}
  public [GlobalStateProps.currentOrgStats]: OrgStatsModel = new OrgStatsModel()

  //GET
  public [GlobalStateProps.schoolIdLookup]: Record<string, number> = {}
  public [GlobalStateProps.schoolLogicalIdLookup]: Record<number, string> = {}
  /** Set as a lookup up for future state support in terms of ability to switch between assigned schools. */
  public [GlobalStateProps.userSchoolLookup]: Record<number, GetSchoolDto | null> = {}
  public [GlobalStateProps.getUserSchoolApiState]: Record<number, ApiState> = {}

  //PATCH
  public [GlobalStateProps.patchUserSchoolApiState]: Record<number, ApiState> = {}
  public [GlobalStateProps.patchSchoolDtoApiState] = new ApiState()

  //OSM
  public [GlobalStateProps.osmPolygon]: CoordinateCollection | null = []
  public [GlobalStateProps.polygonApi] = new ApiState()

  //COMMUNICATION
  
  // This api state is tracked globally as it is used for core app data aka used across pages. This isn't persisted by school id as we should load it from server on each interaction.
  public [GlobalStateProps.predefinedGroupsApiState]: ApiState = new ApiState()
  public [GlobalStateProps.responseGroupsApiState]: ApiState = new ApiState()
  
  //Cross Page Vm
  vm: GlobalAppViewModel = new GlobalAppViewModel()

  //COMMS APIs
  // predefined groups
  static handleGetPredefinedGroupsForSchool = (
    s: GlobalState,
    a: ExtendedAction<number>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.predefinedGroupsApiState]: ApiState.createIsLoadingState()
    }
  }
  static handleGetPredefinedGroupsForSchoolSuccess = (
    s: GlobalState,
    a: ExtendedAction<GetPredefinedGroupDto[]>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.predefinedGroupsApiState]: ApiState.createHadLoadedState()
    }
  }
  static handleGetPredefinedGroupsForSchoolFail = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.predefinedGroupsApiState]: ApiState.createHasErrorState()
    }
  }
  // response groups
  static getResponseGroupsForSchool = (
    s: GlobalState,
    a: ExtendedAction<number>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.responseGroupsApiState]: ApiState.createIsLoadingState()
    }
  }
  static getResponseGroupsSuccess = (
    s: GlobalState,
    a: ExtendedAction<GetResponseGroupDto[]>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.responseGroupsApiState]: ApiState.createHadLoadedState()
    }
  }
  static getResponseGroupsFail = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.responseGroupsApiState]: ApiState.createHasErrorState()
    }
  }

  /** The enabled links lookup is computed from the school dto and the users dto.
   * If the current url is the same as the link, we always return true.
   */
  static getEnabledSiteLinks(orgStats: OrgStatsModel, url: string): IsSiteLinkInteractive {
    const routeIsDash = url === OwlRoutes.firstLevelRoutes.dashboard
    const routeIsRoster = url === OwlRoutes.firstLevelRoutes.roster
    const routeIsSchoolValidation = url === OwlRoutes.schoolValidationPage
    const routeIsSetupSchoolMap = url === OwlRoutes.firstLevelRoutes.setupSchoolAreasMap
    const routeIsEvents = url === OwlRoutes.firstLevelRoutes.events
    const lookup = {
      [OwlRoutes.firstLevelRoutes.dashboard]: routeIsDash
        ? true
        : OrgStatsModel.canAccessDashboard(orgStats),
      // Both roster and instructions become available after completing map config
      [OwlRoutes.firstLevelRoutes.instructions]: routeIsDash
        ? true
        : OrgStatsModel.hasCompleteMapConfig(orgStats),
      [OwlRoutes.firstLevelRoutes.roster]: routeIsRoster
        ? true
        : OrgStatsModel.hasCompleteMapConfig(orgStats),
      [OwlRoutes.schoolValidationPage]: routeIsSchoolValidation ? true : !orgStats.hasSchoolDto,
      [OwlRoutes.firstLevelRoutes.setupSchoolAreasMap]: routeIsSetupSchoolMap
        ? true
        : orgStats.hasSchoolDto,
      [OwlRoutes.firstLevelRoutes.events]: routeIsEvents
        ? true
        : OrgStatsModel.hasCompleteMapConfig(orgStats)
    }
    console.log(`Enabled links lookup:`, lookup)
    return lookup
  }

  /** We need to handle org stats in a specific way to handle any redirect indicator flags. */
  static handleReloadUsersForSchool(s: GlobalState): GlobalState {
    return {
      ...s,
      currentOrgStats: OrgStatsModel.getFromReloadUsersForSchoolAction(
        s[GlobalStateProps.currentOrgStats]
      )
    }
  }

  /** We need to handle org stats in a specific way to handle any redirect indicator flags. */
  static reloadInstructionsForCommunications(s: GlobalState): GlobalState {
    return {
      ...s,
      currentOrgStats: OrgStatsModel.getFromReloadInstructionsForSchoolAction(
        s[GlobalStateProps.currentOrgStats]
      )
    }
  }

  static handleTokenReceived = (
    state: GlobalState,
    action: ExtendedAction<string>
  ): GlobalState => {
    // Here just in case we need new token logic in state// Here just in case we need new token logic in state
    return state
  }
  static handleRouterNavigation(state: GlobalState): GlobalState {
    return {
      ...state,
      currentOrgStats: OrgStatsModel.updateForRouteAction(state.currentOrgStats)
    }
  }
  static setValidationClaims = (
    state: GlobalState,
    action: ExtendedAction<Object>
  ): GlobalState => {
    return {
      ...state,
      claims: action.payload
    }
  }
  static handleUserData = (s: GlobalState, a: ExtendedAction<GetUserDto>): GlobalState => {
    const schoolLogicalIdLookup: Record<number, string> = a.payload.schoolIds.reduce(
      (accum, curr) => ({ ...accum, [curr.id]: curr.logicalId }),
      {}
    )
    return {
      ...s,
      userDto: a.payload,
      currentOrgStats: OrgStatsModel.getFromAuth(s[GlobalStateProps.currentOrgStats], a.payload),
      schoolLogicalIdLookup,
      selectedSchoolId: UserDtoHelper.getDefaultSchoolId(a.payload.schoolIds) ?? mockUnknownSchoolId
    }
  }
  static handleUpdateToSchoolAddition = (
    s: GlobalState,
    a: ExtendedAction<GetSchoolDto>
  ): GlobalState => {
    const existingSchoolIds = s.userDto?.schoolIds ?? []
    const newSchoolId = a.payload.id ?? 0
    const newUserDto = Object.assign({}, s.userDto)
    newUserDto.schoolIds = [
      ...existingSchoolIds,
      {
        id: newSchoolId,
        logicalId: a.payload.logicalId ?? mockUnknownLogicalId
      }
    ]

    return {
      ...s,
      userDto: newUserDto,
      selectedSchoolId:
        UserDtoHelper.getDefaultSchoolId(newUserDto.schoolIds) ?? mockUnknownSchoolId,
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [newSchoolId]: a.payload
      }
    }
  }
  static getBySchoolDtoId = (s: GlobalState): GetSchoolDto | null => {
    return s.userSchoolLookup[s.selectedSchoolId.id] ?? null
  }
  static getSchoolById = (s: GlobalState): GlobalState => {
    return {
      ...s,
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [s.selectedSchoolId.id]: ApiState.createIsLoadingState()
      }
    }
  }
  /** This function assumes that when we get a school by id it happens because of the default or a future user selection. */
  static handleSchoolByIdSuccess = (
    s: GlobalState,
    a: ExtendedAction<GetSchoolDto>
  ): GlobalState => {
    const { id, logicalId } = a.payload
    return {
      ...s,
      selectedSchoolId: {
        id,
        logicalId
      },
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [id]: a.payload
      },
      currentOrgStats: OrgStatsModel.getFromSchool(s[GlobalStateProps.currentOrgStats], a.payload),
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [id]: ApiState.createHadLoadedState()
      }
    }
  }
  static handleSchoolByIdError = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    return {
      ...s,
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [s.selectedSchoolId.id]: null
      },
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [s.selectedSchoolId.id]: ApiState.createHasErrorState()
      }
    }
  }
  //USERS
  static handleGetUsersForSchool = (s: GlobalState, a: ExtendedAction<SchoolIds>): GlobalState => {
    const firstSchoolId = a.payload.id ?? 0
    return {
      ...s,
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [firstSchoolId]: ApiState.createIsLoadingState()
      }
    }
  }
  static handleGetUsersForSchoolSuccess = (
    s: GlobalState,
    a: ExtendedAction<GetUserDto[]>
  ): GlobalState => {
    return {
      ...s,
      usersForSchoolLookup: {
        ...s.usersForSchoolLookup,
        [s.selectedSchoolId.id]: a.payload
      },
      currentOrgStats: OrgStatsModel.getFromSchoolUsers(
        s[GlobalStateProps.currentOrgStats],
        a.payload,
        s[GlobalStateProps.userDto]
      ),
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [s.selectedSchoolId.id]: ApiState.createHadLoadedState()
      }
    }
  }
  static handleGetUsersForSchoolError = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    return {
      ...s,
      getUserSchoolApiState: {
        ...s.getUserSchoolApiState,
        [s.selectedSchoolId.id]: ApiState.createHasErrorState()
      }
    }
  }
  //PATCH SCHOOL DTO
  static handlePatchSchoolError = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    console.error(a.payload.message)
    return {
      ...s,
      [GlobalStateProps.patchUserSchoolApiState]: {
        ...s[GlobalStateProps.patchUserSchoolApiState],
        [s.selectedSchoolId.id]: ApiState.createHasErrorState()
      },
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [s.selectedSchoolId.id]: null
      }
    }
  }
  static handlePatchSchoolSuccess = (
    s: GlobalState,
    a: ExtendedAction<GetSchoolDto>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.patchUserSchoolApiState]: {
        ...s[GlobalStateProps.patchUserSchoolApiState],
        [s.selectedSchoolId.id]: ApiState.createHadLoadedState()
      },
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [a.payload.id]: a.payload
      },
      currentOrgStats: OrgStatsModel.getFromSchool(s[GlobalStateProps.currentOrgStats], a.payload)
    }
  }
  /** This feature is disabled for now, will need to be integrated into new map config steps pattern to work properly.
   * Specific to map config but needs to be updated in global state to make it to the map config vm handler. */
  // static deleteSchoolPerimeterBoundarySuccess = (
  //   s: GlobalState,
  //   a: ExtendedAction<GetSchoolDto>
  // ): GlobalState => {
  //   // console.log(`Handling deleteSchoolPerimeterBoundarySuccess in auth state.`)
  //   return {
  //     ...s,
  //     [GlobalStateProps.patchUserSchoolApiState]: {
  //       ...s[GlobalStateProps.patchUserSchoolApiState],
  //       [s.selectedSchoolId.id]: ApiState.createHadLoadedState()
  //     },
  //     userSchoolLookup: {
  //       ...s.userSchoolLookup,
  //       [a.payload.id]: a.payload
  //     }
  //   }
  // }
  /** Implement api state for patch area and integrate selector into map config once we want to address the unhappy path. */
  static handlePatchSchoolAreaError = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    console.warn(`Unhappy path not handled for patch area error.`)
    return s
  }
  /** Rebuild the school by id lookup after a successful deletion of an area */
  static deleteSchoolAreaSuccess = (
    s: GlobalState,
    a: ExtendedAction<DeleteAreaSideEffectPayload>
  ): GlobalState => {
    const schoolId = s[GlobalStateProps.selectedSchoolId]
    if (!schoolId) {
      console.error(`DEV ERROR: Issue in deleteSchoolAreaSuccess, no selected school id available`)
      return s
    }
    const schoolDto = s.userSchoolLookup[schoolId.id]
    if (!schoolDto) {
      console.error(`DEV ERROR: School dto not in auth state for patch area success`)
      return s
    }
    const schoolAreas =
      schoolDto[SchoolDtoProps.subareas]?.filter(
        (area) => area[SchoolAreaDtoProps.id] !== a.payload.areaId
      ) ?? []
    return {
      ...s,
      [GlobalStateProps.currentOrgStats]: OrgStatsModel.getFromDeleteArea(
        s[GlobalStateProps.currentOrgStats],
        a.payload
      ),
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [schoolId.id]: {
          ...schoolDto,
          subareas: [...schoolAreas]
        }
      }
    }
  }
  /** Rebuild the school by id lookup after a successful area patch */
  static handlePatchSchoolAreaSuccess = (
    s: GlobalState,
    a: ExtendedAction<SchoolAreaDto>
  ): GlobalState => {
    // TODO Move to school area dto helper to rebuild school lookup from area payload
    const schoolId = a.payload[SchoolAreaDtoProps.schoolId]
    if (!schoolId) {
      console.error(`DEV ERROR: School area patch didn't return school id for patch area success`)
      return s
    }
    const schoolDto = s.userSchoolLookup[schoolId]
    if (!schoolDto) {
      console.error(`DEV ERROR: School dto not in auth state for patch area success`)
      return s
    }
    const schoolAreas =
      schoolDto[SchoolDtoProps.subareas]?.filter(
        (area) => area[SchoolAreaDtoProps.id] !== a.payload.id
      ) ?? []
    return {
      ...s,
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [schoolId]: {
          ...schoolDto,
          subareas: [...schoolAreas, a.payload]
        }
      }
    }
  }
  static handleNewSchoolOwlState = (
    s: GlobalState,
    a: ExtendedAction<SchoolOwlState>
  ): GlobalState => {
    return {
      ...s,
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [s.selectedSchoolId.id]: {
          ...s.userSchoolLookup[s.selectedSchoolId.id],
          schoolOwlState: a.payload
        } as GetSchoolDto
      }
    }
  }
  /** When we patch a school we expect to get the new school dto from signalr, this also handles syncing various school owl browser clients */
  static handleNewSchoolDto = (s: GlobalState, a: ExtendedAction<GetSchoolDto>): GlobalState => {
    return {
      ...s,
      userSchoolLookup: {
        ...s.userSchoolLookup,
        [s.selectedSchoolId.id]: a.payload
      }
    }
  }
  static handlePatchSchoolDto = (
    s: GlobalState,
    a: ExtendedAction<Partial<SchoolDto>>
  ): GlobalState => {
    return {
      ...s,
      [GlobalStateProps.patchUserSchoolApiState]: {
        ...s[GlobalStateProps.patchUserSchoolApiState],
        [s.selectedSchoolId.id]: ApiState.createIsLoadingState()
      }
    }
  }
  // POST
  // area
  static handleSaveSchoolAreaDto = (
    s: GlobalState,
    a: ExtendedAction<PostSchoolAreaDto>
  ): GlobalState => {
    const schoolId = a.payload.id ?? 0
    const newSchoolGetApiState = Object.assign({}, s.getUserSchoolApiState)
    newSchoolGetApiState[schoolId] = ApiState.createIsLoadingState()
    return {
      ...s,
      getUserSchoolApiState: newSchoolGetApiState
    }
  }
  /** Update the users school lookup with the new area for the school. */
  static handleSaveSchoolAreaDtoSuccess = (
    s: GlobalState,
    a: ExtendedAction<SchoolAreaDto>
  ): GlobalState => {
    const areaId = a.payload.id ?? 0
    const newSchoolAreaDto = Object.assign({}, a.payload)
    const newAreaSchoolId = newSchoolAreaDto[SchoolAreaDtoProps.schoolId]
    if (!newAreaSchoolId) {
      console.error(`New school area returned without id!`)
      return s
    }
    const currentSchool = s[GlobalStateProps.userSchoolLookup][newAreaSchoolId]
    if (!currentSchool) {
      console.error(`School area created for not in state.`)
      return s
    }
    const copyOfCurrentSchool = Object.assign({}, currentSchool)
    const existingSchoolAreas = currentSchool[SchoolDtoProps.subareas] ?? null
    const newUserSchoolLookup = Object.assign({}, s.userSchoolLookup)
    if (existingSchoolAreas) {
      copyOfCurrentSchool.subareas = [...existingSchoolAreas, newSchoolAreaDto]
    } else {
      copyOfCurrentSchool.subareas = [newSchoolAreaDto]
    }
    const newSchoolGetApiState = Object.assign({}, s.getUserSchoolApiState)
    newSchoolGetApiState[areaId] = ApiState.createHadLoadedState()
    newUserSchoolLookup[newAreaSchoolId] = copyOfCurrentSchool
    return {
      ...s,
      userSchoolLookup: newUserSchoolLookup,
      getUserSchoolApiState: newSchoolGetApiState,
      currentOrgStats: OrgStatsModel.getFromSchoolArea(
        s[GlobalStateProps.currentOrgStats],
        currentSchool,
        a.payload
      )
    }
  }
  static handleSaveSchoolAreaDtoError = (
    s: GlobalState,
    a: ExtendedAction<HttpErrorResponse>
  ): GlobalState => {
    const newSchoolGetApiState = Object.assign({}, s.getUserSchoolApiState)
    newSchoolGetApiState[s.selectedSchoolId.id] = ApiState.createHasErrorState()
    const newUserSchoolLookup = Object.assign({}, s.userSchoolLookup)
    newUserSchoolLookup[s.selectedSchoolId.id] = null

    return {
      ...s,
      getUserSchoolApiState: newSchoolGetApiState,
      userSchoolLookup: newUserSchoolLookup
    }
  }
  /** Logic that returns null until the school map is ready to show then returns the school map page state and the auth state */
  static getSchoolMapPrerequistes = (
    currentRoute: string,
    authState: GlobalState
  ): GlobalState | null => {
    // console.log(`Current route:`, currentRoute)
    if (!RouteStateHelper.canSetUpMap(currentRoute)) {
      return null
    }
    if (!authState.userSchoolLookup[authState.selectedSchoolId.id]) {
      return null
    }
    return authState
  }

  /** When a user is setting up a school map page, we need them to either draw a new boundary, use the osm polygon, or edit it, or we can just use the boundary that was saved already to simply display it. */
  static handleGetSchoolPolygonByLoc = (s: GlobalState, a: ExtendedAction<LatLon>): GlobalState => {
    return {
      ...s,
      polygonApi: ApiState.createIsLoadingState()
    }
  }
  static handleSchoolPolygonByLocError = (s: GlobalState, a: ExtendedAction<any>): GlobalState => {
    return {
      ...s,
      polygonApi: ApiState.createHasErrorState()
    }
  }
  static handleSchoolPolygonByLocSuccess = (
    s: GlobalState,
    a: ExtendedAction<CoordinateCollection | null>
  ): GlobalState => {
    return {
      ...s,
      osmPolygon: a.payload,
      polygonApi: ApiState.createHadLoadedState()
    }
  }
  static getBoundaryInteractionPrerequisites = (
    s: GlobalState,
    dto: GetSchoolDto | null
  ): SchoolBoundaryPartialState => ({
    polygonApi: s.polygonApi,
    schoolDto: dto,
    osmPolygon: s.osmPolygon && s.osmPolygon.length > 0 ? s.osmPolygon : null
  })
  // TOAST QUEUE SECTION - Extract to global app view model
  static handleShowSingleToast = (
    s: GlobalState,
    a: ExtendedAction<IToastViewModel>
  ): GlobalState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        queueCount: 1,
        timestampStartOfOnlyToast: new Date()
      }
    }
  }

  static handleEnqueueToast = (s: GlobalState): GlobalState => {
    const clearTimestamp = s.vm.queueCount > 1
    return {
      ...s,
      vm: {
        ...s.vm,
        queueCount: s.vm.queueCount + 1,
        timestampStartOfOnlyToast: clearTimestamp ? null : s.vm.timestampStartOfOnlyToast
      }
    }
  }
  static decrementToastInQueue = (s: GlobalState): GlobalState => {
    const newCount = s.vm.queueCount - 1
    return {
      ...s,
      vm: {
        ...s.vm,
        queueCount: newCount,
        timestampStartOfOnlyToast: newCount === 1 ? new Date() : null
      }
    }
  }
  static toggleProfile = (s: GlobalState): GlobalState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        profileOpened: !s.vm.profileOpened
      }
    }
  }
}
export const defaultAuthState = new GlobalState()
