import Graphic from '@arcgis/core/Graphic'
import { mobileUserAllowedOnMap } from '@model/user/user.model'
import { StatusColorEnum } from '@view/area/area.view'
import { GraphicAttributesTypeEnum } from '@view/area/graphic-attributes.model'
import { LocationGraphicAttrProps, LocationOfPollResponseAttr } from '@view/area/location.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcgisLayersHandler } from '../layers/arcgis-layers-handler.view'
import { DashboardPageViewModel } from '@view/pages/dashboard-page/dashboard-page.view'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { ArcGisLocationHandler } from '../user-locations/arcgis-user-location.view'

export namespace ArcGisPollResponseLocGraphicHandler {
  /** Show all locations for received poll responses based on a pressed poll id. Continue to hide all others. */
  export const pollResponsesVisibilityHandler = (
    context: ArcGisMapService,
    pressedPollId: string | null,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    const showAllMapData =
      (schoolOwlState !== SchoolStateEnum.routine && !pressedPollId && !state.vm.selectedAreaId) ||
      (schoolOwlState === SchoolStateEnum.routine && !pressedPollId)
    // Set up the opacity logic for the user locations based on state as the user let's go of the pressed poll id or clear the lookup because there's no selected area on release

    context._studentPollResponsesLayer?.graphics.forEach((graphic) => {
      handleStyleForGraphic(graphic, pressedPollId)
    })
    context._teacherPollResponsesLayer?.graphics.forEach((graphic) => {
      handleStyleForGraphic(graphic, pressedPollId)
    })
    context._otherStaffPollResponsesLayer?.graphics.forEach((graphic) => {
      handleStyleForGraphic(graphic, pressedPollId)
    })
    context._guestPollResponsesLayer?.graphics.forEach((graphic) => {
      handleStyleForGraphic(graphic, pressedPollId)
    })

    ArcgisLayersHandler.getUserLocationLayersWithType(context).forEach(([layer, type]) => {
      if (mobileUserAllowedOnMap(type)) {
        layer?.graphics.forEach((g) => {
          if (showAllMapData) {
            g.symbol = ArcGisSymbolFactory.locationSymbolByUserType[type].clone()
            return
          }
          const mobileUserId = g.attributes[LocationGraphicAttrProps.mobileId]
          ArcGisLocationHandler.handleLocationOpacityLogic(
            context,
            mobileUserId,
            state,
            schoolOwlState
          )
          const userIsOpaque = context.opaqueUserIds[mobileUserId]
          if (userIsOpaque) {
            g.symbol = ArcGisSymbolFactory.opacitySymbolByUserType[type].clone()
          } else {
            g.symbol = ArcGisSymbolFactory.locationSymbolByUserType[type].clone()
          }
        })
      }
    })
    ArcgisLayersHandler.getAllRecentMessageIndicatorLayersWithType(context).forEach(
      ([layer, type]) => {
        if (mobileUserAllowedOnMap(type)) {
          layer?.graphics.forEach((g) => {
            if (showAllMapData) {
              g.symbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
                g.attributes[LocationGraphicAttrProps.recentMessageIndicatorType]
              ).clone()
              return
            }
            const mobileUserId = g.attributes[LocationGraphicAttrProps.mobileId]
            const userIsOpaque = context.opaqueUserIds[mobileUserId]
            userIsOpaque
              ? (g.symbol = ArcGisSymbolFactory.hiddenSymbol.clone())
              : (g.symbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
                  g.attributes[LocationGraphicAttrProps.recentMessageIndicatorType]
                ).clone())
          })
        }
      }
    )
    context._attackAlertGraphicsLayer?.graphics.forEach((g) => {
      pressedPollId
        ? (g.symbol = ArcGisSymbolFactory.opaqueAlertSymbol.clone())
        : (g.symbol = ArcGisSymbolFactory.alertSymbol.clone())
    })
    context._medialAlertGraphicsLayer?.graphics.forEach((g) => {
      pressedPollId
        ? (g.symbol = ArcGisSymbolFactory.opaqueMedicalAlertSymbol.clone())
        : (g.symbol = ArcGisSymbolFactory.medicalAlertSymbol.clone())
    })
    if (pressedPollId && context._pulsingAlertGraphicsLayer) {
      context._pulsingAlertGraphicsLayer.visible = false
    } else if (context._pulsingAlertGraphicsLayer) {
      context._pulsingAlertGraphicsLayer.visible = true
    }
    //If user is pressing poll that implies deseletion of a selected user on the map
    if (context.popupRef?.popupVisible) {
      context.popupRef?.hidePopup()
    }
  }
  const handleStyleForGraphic = (graphic: Graphic, pressedPollId: string | null) => {
    if (graphic.attributes?.kind !== GraphicAttributesTypeEnum.pollResponse) {
      console.warn('handleStyleForGraphic called for incorrect graphic by attributes kind')
      return
    }
    const typedGraphicAttributes: LocationOfPollResponseAttr = graphic.attributes
    const showPollResponseLocationIndicator =
      pressedPollId !== null &&
      typedGraphicAttributes.chatResponseDtoPayload.pollLogicalId === pressedPollId
    setStyleForPollResponseGraphic(graphic, showPollResponseLocationIndicator)
  }
  /** Show or hide the location of the poll response based on @param show */
  const setStyleForPollResponseGraphic = (g: Graphic, show: boolean): void => {
    switch ((g.attributes as LocationOfPollResponseAttr)?.statusVm?.status) {
      case StatusColorEnum.green:
        g.symbol = show
          ? ArcGisSymbolFactory.greenPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.yellow:
        g.symbol = show
          ? ArcGisSymbolFactory.yellowPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.red:
        g.symbol = show
          ? ArcGisSymbolFactory.redPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.first:
        g.symbol = show
          ? ArcGisSymbolFactory.noStatusFirstColorPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.second:
        g.symbol = show
          ? ArcGisSymbolFactory.noStatusSecondColorPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.third:
        g.symbol = show
          ? ArcGisSymbolFactory.noStatusThirdColorPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.fourth:
        g.symbol = show
          ? ArcGisSymbolFactory.noStatusFourthColorPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
      case StatusColorEnum.unknown:
        g.symbol = show
          ? ArcGisSymbolFactory.noStatusNoColorPollResponseSymbol.clone()
          : ArcGisSymbolFactory.hiddenSymbol.clone()
        break
    }
  }
}
