import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { ChatMessageDto, ChatMessageDtoPayload } from '@model/message.model'
import { MessageTypeEnum } from '@model/message/predefined-message.model'

export const generateToastTitle = (messageDto: ChatMessageDto): string => {
  let { userTypeFilter, messageType } = messageDto.payload as ChatMessageDtoPayload

  const messageUserScope = () => {
    if (userTypeFilter?.includes('OtherStaff')) {
      let modifiedUserTypeFilter = [...userTypeFilter]
      modifiedUserTypeFilter[modifiedUserTypeFilter.indexOf('OtherStaff')] = 'OTHER STAFF'
      userTypeFilter = modifiedUserTypeFilter
    }
    switch (userTypeFilter?.length) {
      case 1:
        return userTypeFilter[0] + (userTypeFilter[0] !== 'OTHER STAFF' ? 'S' : '')
      case 2:
        return `${
          userTypeFilter[0].toUpperCase() + (userTypeFilter[0] !== 'OTHER STAFF' ? 'S' : '')
        } AND ${userTypeFilter[1].toUpperCase() + (userTypeFilter[1] !== 'OTHER STAFF' ? 'S' : '')}`
      default:
        return 'EVERYONE'
    }
  }
  const toastType = () => {
    if (ChatMessageDtoHelper.isSentPoll(messageDto)) {
      return 'QUESTION'
    } else if (messageType === MessageTypeEnum.instruction) {
      return 'INSTRUCTIONS'
    } else {
      return 'UPDATES'
    }
  }

  return `${toastType()} AUTO-SENT TO ${messageUserScope()}`
}
