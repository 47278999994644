import Graphic from '@arcgis/core/Graphic'
import { Polygon, Polyline } from '@arcgis/core/geometry'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisAreaGraphicFactory } from '../graphic/area-graphic.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'

export namespace ArcGisGeomValidaiton {
  /** Ensure that boundary doesn't self intersect or intersect any area
   * TODO Add min and max size to validation errors when we know requirements
   */
  export const validateBoundary = (
    context: ArcGisMapService,
    updateGraphic: Graphic
  ): MapConfigValidationErrors | null => {
    let areaOutsideBoundary = false
    if (!context._sketchViewModel || !context._sketchViewModel?.updateGraphics) {
    }
    if (!updateGraphic) {
      console.error(`DEV ERROR: Attempting to validate when no boundery exists!`)
      return null
    }
    let boundaryAsPolygon = updateGraphic.geometry as Polygon
    if (boundaryAsPolygon.isSelfIntersecting || boundaryAsPolygon.rings.length > 1) {
      return MapConfigValidationErrors.boundarySelfIntersects
    }
    const areasOnMap: __esri.Collection<Graphic> | null = context._schoolAreaLayer?.graphics ?? null
    // No need to validate if there's no areas on the map
    if (!areasOnMap) {
      return null
    }
    for (let areaGraphic of areasOnMap) {
      // With passed update graphic it seems to be in mercator on first click TODO Validate that it stays that way
      const latLongProjectionOfBoundary = ArcGisMapProjectionViewModel.projectToSpatialReference(
        updateGraphic.geometry,
        ArcgisSpatialRefence.webMercatorSpatialReference
      )
      let projectedLastAreaInteracted: __esri.Geometry | null = null
      //TODO VALIDATE ASSUMPTION: Interacting with an area via sketch view model implicitly converts it's spatial reference to mercator
      //Convert to lat long spartial ref for geometry validation if needed
      if (areaGraphic.geometry.spatialReference.isGeographic) {
        projectedLastAreaInteracted = ArcGisMapProjectionViewModel.projectToSpatialReference(
          areaGraphic.geometry,
          ArcgisSpatialRefence.webMercatorSpatialReference
        )
      } else {
        projectedLastAreaInteracted = areaGraphic.geometry
      }
      // console.log(` validating areaGeom id`, areaGraphic?.attributes?.id)
      // If any area is not fully within the boundary break and return error
      if (!geometryEngine.within(projectedLastAreaInteracted, latLongProjectionOfBoundary)) {
        areaOutsideBoundary = true
        break
      }
    }
    if (areaOutsideBoundary) {
      return MapConfigValidationErrors.boundaryDoesntIncludeAllAreas
    }
    return null
  }

  /** Uses geometry engine to validate that this area doesn't intersect any other areas. */
  export const areaByIdIntersectOtherAreas = (
    context: ArcGisMapService,
    id: number | null,
    graphic: Graphic
  ): boolean => {
    if (!id && !graphic) {
      console.error(`Must pass area id or graphic to validate geometry`)
      return false
    }
    let doesIntersect = false
    let allOtherAreaGeoms: __esri.Collection<Graphic> | null = null
    //Validate intersection with other areas
    if (id) {
      allOtherAreaGeoms = ArcGisAreaGraphicFactory.getAllOtherAreasFromId(context, id)
    } else if (graphic) {
      allOtherAreaGeoms = context._schoolAreaLayer?.graphics ?? null
    }
    if (!allOtherAreaGeoms) {
      console.error(`Couldn't get other areas!`)
      return true
    }
    if (!allOtherAreaGeoms) {
      console.info(`Area is valid because it's the only one on the map.`)
      return false
    }
    // Use let of to break iteration as soon as we have one validation failure
    for (let g of allOtherAreaGeoms) {
      if (g?.attributes?.logicalId === graphic?.attributes?.logicalId) {
        // console.log(`Skipping validation a newly created area against itself`)
        continue
      }
      const mercatorProjectedAreaGeom = ArcGisMapProjectionViewModel.projectToSpatialReference(
        g.geometry,
        ArcgisSpatialRefence.webMercatorSpatialReference
      )
      if (geometryEngine.intersects(graphic.geometry, mercatorProjectedAreaGeom)) {
        doesIntersect = true
        break
      }
    }
    // console.log(`Area id intersects others: ${doesIntersect}`)
    return doesIntersect
  }
  /** Takes an area primary key id and validates that it doesn't intersect the school boundary. */
  export const areaByIdIsOutSideBoundary = (
    context: ArcGisMapService,
    id: number | null,
    graphic?: Graphic
  ): boolean => {
    let graphicToValidate: Graphic | null = null
    if (id) {
      graphicToValidate = ArcGisAreaGraphicFactory.getAreaById(context, id)
    } else if (graphic) {
      graphicToValidate = graphic
    }
    if (!graphicToValidate) {
      return true
    }
    if (!context._schoolBoundaryLayer) {
      console.error(`DEV ERROR: No Boundary layer found!`)
      return true
    }
    const boundaryGraphic = context._schoolBoundaryLayer.graphics.at(0) ?? null
    if (context._schoolBoundaryLayer.graphics.length === 0 || !boundaryGraphic) {
      console.error(`DEV ERROR: No boundary graphic found!`)
      return true
    }
    let projectedBoundaryGeom: __esri.Geometry | null = null
    // When we edit the boundary before this step, the spatial reference is adjusted by the sketch view model
    if (boundaryGraphic.geometry.spatialReference.isGeographic) {
      projectedBoundaryGeom = ArcGisMapProjectionViewModel.projectToSpatialReference(
        boundaryGraphic.geometry,
        ArcgisSpatialRefence.webMercatorSpatialReference
      )
    }
    let areaGeometryToValidate: __esri.Geometry | null = null
    // If we're validating by id we'll need to project to the sketch view model project which is the geo units aka lat long
    if (id) {
      const areaToLatLong = ArcGisMapProjectionViewModel.projectToSpatialReference(
        graphicToValidate.geometry,
        ArcgisSpatialRefence.webMercatorSpatialReference
      )
      areaGeometryToValidate = areaToLatLong
    } else {
      areaGeometryToValidate = graphicToValidate.geometry
    }
    const areaIsWithinBoundary = geometryEngine.within(
      areaGeometryToValidate,
      projectedBoundaryGeom ? projectedBoundaryGeom : boundaryGraphic.geometry
    )
    return !areaIsWithinBoundary
  }
  /** Uses geometry engine to validate that this area vertices do not lie on edges. */
  export const areaVerticesOnEdges = (polygon: Polygon): boolean => {
    if (!polygon) {
      console.error(`Must pass polygon to validate geometry`)
      return false
    }
    const rings = polygon.rings
    if (rings.length != 1) return true
    const points = rings[0]
    if (points.length <= 1) return true
    const lines = []
    for (let i = 1; i < points.length; i++) {
      lines.push(
        new Polyline({
          paths: [
            [
              [points[i - 1][0], points[i - 1][1]],
              [points[i][0], points[i][1]]
            ]
          ]
        })
      )
    }
    for (let i = lines.length - 1; i >= 0; i--) {
      // console.log(JSON.stringify(lines[i].paths[0]))
      let touchCount = 0
      for (let j = i - 1; j >= 0; j--) {
        if (
          geometryEngine.contains(lines[i], lines[j]) ||
          geometryEngine.contains(lines[j], lines[i])
        ) {
          return true
        }
        if (geometryEngine.touches(lines[i], lines[j])) {
          touchCount++
          if (touchCount > 2) {
            return true
          }
        }
      }
    }
    return false
  }
  /** Use the various geometry validations for an area and return either the failed validation type or null if all validations pass. */
  export const getAreaValidationErrorOrNull = (
    context: ArcGisMapService,
    areaId: number | null,
    updateGraphic: Graphic
  ): MapConfigValidationErrors | null => {
    if (updateGraphic.geometry.spatialReference.isGeographic) {
      updateGraphic.geometry = ArcGisMapProjectionViewModel.projectToSpatialReference(
        updateGraphic.geometry,
        ArcgisSpatialRefence.webMercatorSpatialReference
      )
    }
    const polygon = updateGraphic.geometry as Polygon
    let areaIntersectsItself = polygon?.isSelfIntersecting || polygon.rings.length > 1
    if (areaIntersectsItself) {
      return MapConfigValidationErrors.areaSelfIntersects
    }
    const areaVerticesOnEdges = ArcGisGeomValidaiton.areaVerticesOnEdges(polygon)
    if (areaVerticesOnEdges) {
      return MapConfigValidationErrors.areaVerticesOnEdges
    }
    const areaIntersectsOthers = ArcGisGeomValidaiton.areaByIdIntersectOtherAreas(
      context,
      areaId,
      updateGraphic
    )
    if (areaIntersectsOthers) {
      return MapConfigValidationErrors.intersectsOtherAreas
    }
    const areaIntersectsBoundary = ArcGisGeomValidaiton.areaByIdIsOutSideBoundary(
      context,
      areaId,
      updateGraphic
    )
    if (areaIntersectsBoundary) {
      return MapConfigValidationErrors.intersectsBoundary
    }
    return null
  }
}
