export const OwlStateMachineName = "OWL Animation"
export enum OwlStateMachineInputs {
    isTracking = "isTracking",
    WindsHovered = "WindsHovered",
    HeadHovered = "HeadHovered",
    StartEventHovered = "StartEventHovered"
}
export enum OwlAnimationsEnum {
    EyesBlink = "Eyes Blink",
    PupilSize = "Pupil Size",
    Wings = "Wings",
    Breath = "Breath",
    MouseIn = "Mouse In",
    MouseOut = "Mouse Out",
    HoverStartEvent = "Hover Start Event"
}

//School State
export const OwlSchoolStateStateArtBoard = "OWL Status (3 trigger)"
export const OwlSchoolStateStateMachineName = "OWL School State"
export enum SchoolStateTriggers {
    Normal = 'Normal',
    Suspected = 'Suspected',
    Emergency = 'Emergency'
}
export const OwlSchoolStateStateMachineNameNewOld = "OWL School State"
export enum SchoolStateTriggersNewOld {
    Forward = 'Forward',
    Fast = 'Fast',
    Back = 'Back'
}

export const OwlSchoolStateStateMachineNameOld = "OWL Status (Full)"
export const OwlSchoolStateStateMachineNameOldFast = "OWL Status (Fast)"
export enum SchoolStateAnimationsOld {
    Normal = 'Normal',
    Suspected = 'Suspected',
    Confirmed = 'Confirmed'
}
