import { createSelector } from '@ngrx/store'
import { selectOwlSchoolStateProperty, selectSchoolById } from '@selector/auth.selector'
import { ILocationVisibilityViewModel } from '@view/map/locations/map-control-ui.view'
import { DashboardPageViewModel } from '@view/pages/dashboard-page/dashboard-page.view'
import { PollInteractionViewModel } from '@view/poll/poll.view'
import {
  selectDashboardViewModel,
  selectMapVisibleByUserClick,
  selectSentPollToIncludedUserTypes,
  selectShowSelectEventUi,
  selectSideNavIsCollapsed
} from './dashboard-page-state.selectors'

export const dashboardSchoolStateLobelIsVisible = createSelector(
  selectSchoolById,
  DashboardPageViewModel.getSchoolOwlStateLabelVisible
)
export const selectMapApi = createSelector(selectDashboardViewModel, (vm) => vm.mapApi)
export const selectDisableCheckStatus = createSelector(
  selectDashboardViewModel,
  (vm) => vm.disabledAreaCheckStatuses
)
export const selectAreaIdSelected = createSelector(
  selectDashboardViewModel,
  (vm) => vm.selectedAreaId ?? 0
)
export const selectSelectedUserMobileId = createSelector(
  selectDashboardViewModel,
  (vm) => vm.selectedUserMobileId ?? null
)
export const selectShowMainCtaButtonTooltip = createSelector(
  selectSideNavIsCollapsed,
  selectShowSelectEventUi,
  (collapsed, selectEventUiShown) => collapsed && !selectEventUiShown
)
//Side Nav Settings
export const selectSideNavSettings = createSelector(
  selectDashboardViewModel,
  (vm) => vm.sideNavVisibilitySettings
)
export const selectShowAreaPanel = createSelector(
  selectSideNavSettings,
  (settings) => settings.showAreaPanel
)
export const selectShowSubAreas = createSelector(
  selectSideNavSettings,
  (settings) => settings.showSubAreas
)
// This was removed from the list of side navs
// export const selectShowSuspects = createSelector(
//   selectSideNavSettings,
//   (settings) => settings.showSuspects
// )

export const selectDashVmShowStudents = createSelector(
  selectSideNavSettings,
  (settings) => settings.showStudents
)
export const selectDashVmShowTeacher = createSelector(
  selectSideNavSettings,
  (settings) => settings.showTeachers
)
export const selectDashVmShowOtherStaff = createSelector(
  selectSideNavSettings,
  (settings) => settings.showOtherStaff
)
export const selectDashVmShowGuests = createSelector(
  selectSideNavSettings,
  (settings) => settings.showGuests
)
export const selectCurrentDimmedAttackAlertState = createSelector(
  selectSideNavSettings,
  (settings) => settings.showOldAttackAlerts
)
//Location
export const selectDashboardVmLocationVisibility = createSelector(
  selectDashVmShowStudents,
  selectDashVmShowTeacher,
  selectDashVmShowOtherStaff,
  selectDashVmShowGuests,
  (showStudents, showTeachers, showOtherStaff, showGuests) =>
    ({
      showStudents,
      showTeachers,
      showOtherStaff,
      showGuests
    } as ILocationVisibilityViewModel)
)
export const selectDashShownDueToClick = createSelector(
  selectDashboardViewModel,
  (vm) => vm.mapVisibleByUserClick
)
export const selectIsShownAreaPanel = createSelector(
  selectMapVisibleByUserClick,
  selectShowAreaPanel,
  selectOwlSchoolStateProperty,
  DashboardPageViewModel.getAreaPanelIsVisible
)
//Poll Interactions
export const selectPressedPollId = createSelector(
  selectDashboardViewModel,
  (vm) => vm.pressedPollId
)
export const pollComponentHoverVm = createSelector(
  selectDashboardViewModel,
  (vm) => vm.pollComponentHoverVm
)

export const selectPollIdToShowToolTipForOrNull = createSelector(
  selectDashboardVmLocationVisibility,
  selectSentPollToIncludedUserTypes,
  pollComponentHoverVm,
  PollInteractionViewModel.getShowNoVisibleUsersOnMapTooltipForPoll
)

export const selectMapStateTransitionDeps = createSelector(
  selectAreaIdSelected,
  selectMapVisibleByUserClick,
  (selectedAreaId, mapVisibilityByUserClick) => ({
    selectedAreaId,
    mapVisibility: mapVisibilityByUserClick
  })
)
