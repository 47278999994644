import { SimpleChanges } from '@angular/core'
import { phoneCountryCode } from './constants'

export class FormUtils {

  

  /** For better UX we only make the user enter 10 digits, and we remove the +1 that comes from the server, when we create the DTO we must add it */
  static getPhoneValueForDto = (formValue: string): string => {
    return `${phoneCountryCode}${formValue}`
  }
  /**
   * Purpose: Check to make sure the form is different from the user's original dto data. 
   * Usage: in submit form allowed logic to make sure that the user has changed something about the form before they're allowed to submit. */
  static areDiff(
    p: string,
    formValue: string | null | undefined,
    dtoValue: string | null | undefined
  ): boolean {
    if (!formValue && !dtoValue) return false
    // When dealing with a phone property the from value will always be different from the dto value, because we don't make the user type in the +1
    if (formValue && p === 'phone') {
      return this.getPhoneValueForDto(formValue) !== dtoValue
    }
    //Trimmed versions if it's string, otherwise leave as null or undefined
    let formValueTrimmed = formValue?.trim() ?? formValue
    let dtoValueTrimmed = dtoValue?.trim() ?? dtoValue
    return dtoValueTrimmed !== formValueTrimmed
  }
  /** Utility method exists in order to update any string property to a trimmed version, so no leading or trailing spaces are saved. */
  static trimAllFormValues<T extends Record<string, any>>(form: T): T {
    let trimmedForm: Record<string, any> = {}

    for (const key in form) {
      if (Object.prototype.hasOwnProperty.call(form, key)) {
        const value = form[key]
        trimmedForm[key] = typeof value === 'string' ? value.trim() : value
      }
    }

    return trimmedForm as T
  }
  /** Validates that the changes for the passed prop name has a previous value and a current value. */
  static hasChangeForProp(changes: SimpleChanges, propName: string): boolean {
    return (
      (!!changes[propName]?.previousValue ?? false) && (!!changes[propName]?.currentValue ?? false)
    )
  }
}
