export enum MapPageType {
    schoolMapConfig = "schoolMapConfig",
    schoolDashboard = "schoolDashboard",
    unknown = "unknown"
}
export enum PolygonTypeEnum {
    /** Contrained to be aligned */
    Box = "Box",
    /** Free to be rotated */
    RotationBox = "RotationBox",
    /** A polygon based on a passed aspect ratio */
    AspectRatioBoundBox = "AspectRatioBoundBox",
}

export interface BaseMapComponentService {
    /** 
     * Sets up the map in the DOM
     * @param selector - The selector html element id for the map container
     * @param selectionType - The type of drawn shape selection to use
     * @returns void
    */
    init(selector: string, selectionType: PolygonTypeEnum): void;

    /** Here in case any observable subscriptions are required for the service to operate. Consider removing when state mgmt integrated */
    cleanUp(): void;

    /** Used to programmatically fall back to a back up mapping api in case there are errors or potentially by the user. */
    destroy(): void;

    /** Clears drawn items on the map */
    undoSelection(): void;

    /** TODO Integrate file upload process for this.  */
    addSchoolMapImage(blobUrl: string, aspectRatio: number): void;

    /** Should provide a abstract interface for different types of selectors between arc gis and open street map and potentially google maps */
    handleSelectionTypeChange(selectionType: PolygonTypeEnum): void;
}