import { generateToastTitle } from '@component/shared/snackbar/snackbar.helper'
import { ChatMessageDto } from '@model/message.model'
import { ToastVmIcons } from '../../component/shared/snackbar/snackbar.component.model'

export interface IToastViewModel {
  iconName: ToastVmIcons
  title: string
  message: string
}
export class AutoSentMessageSnackBarViewModel {
  static getAutoSentSnackbarVm(messageDto: ChatMessageDto, message: string): IToastViewModel {
    if (message) {
      return {
        iconName: ToastVmIcons.PulsingOwl,
        title: generateToastTitle(messageDto),
        // TODO Move to string util funciton with passed param for truncate length
        message: message.length > 35 ? message.slice(0, 35) + '...' : message
      }
    } else {
      throw Error('Attempted to construct Toast View Model without message !')
    }
  }
}
