// import { Coordinate } from "ol/coordinate";

// import Point from "ol/geom/Point";
import { ProjectionNames } from "./map-enums.view";

export class MapProjectionViewModel {
  // OPEN LAYERS
  /**
   * 
   * @param point A static function that takes a lat long point, 
   * @param sourceProjection its projection type, 
   * @param targetProjection  and the target projection type
   * @param targetProjection  a new point in the target projection type
   */
  // private static transformPoint(
  //   point: Coordinate, // The lat long point as an array of two numbers
  //   sourceProjection: string, // The source projection type as a string, e.g. "EPSG:4326"
  //   targetProjection: string, // The target projection type as a string, e.g. "EPSG:900913"
  //   swapLatLon: boolean
  // ): Coordinate {
  //   // Create a new point object from the input array
  //   let pointObj
  //   if (swapLatLon) {
  //     pointObj = new Point([point[1], point[0]]);
  //   } else {
  //     pointObj = new Point(point);
  //   }
  //   // Transform the point object using the source and target projections
  //   pointObj.transform(sourceProjection, targetProjection);
  //   // Return the transformed point as an array
  //   return pointObj.getCoordinates();
  // }
  // static transform4326_to_900913(point: Coordinate, swapLatLon: boolean = false): Coordinate {
  //   return this.transformPoint(
  //     point,
  //     ProjectionNames.EPSG_4326,
  //     ProjectionNames.EPSG_900913,
  //     swapLatLon
  //   );
  // }
  // static transform900913_to_4326(point: Coordinate, swapLatLon: boolean = false): Coordinate {
  //   return this.transformPoint(
  //     point,
  //     ProjectionNames.EPSG_900913,
  //     ProjectionNames.EPSG_4326,
  //     swapLatLon
  //   );
  // }
}