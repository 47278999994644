import { ExtendedAction } from '@action/extended-ngrx-action'
import { DrillModeEnum } from '@model/drill-mode.model'
import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import {
  ChatMessageDtoPayload,
  GeospatialQueryPayloadHistoric,
  GeospatialQueryPayloadRealtime
} from '@model/message.model'
import { SchoolOwlState, SchoolPreferenceEnum } from '@model/school/school-configuation.model'
import { createAction, props } from '@ngrx/store'
import { SetAreaAction, SetUserAction } from '@view/area/area.view'
import { LocationOfAlertResponseAttr } from '@view/area/location.view'
import { IAreaIdAndThreatVm, ThreatItemViewModel } from '@view/area/threat-area.view'
import {
  IMapUiControlSessionSettings,
  MapUiControlEnum
} from '@view/pages/dashboard-page/dashboard-page.view'

export enum DashboardPageAction {
  //SESSION STATE
  setMapUiControlSessionState = '[DashboardPageAction] Set Map Ui Controls in Session State',
  unSetMapUiControlSessionState = '[DashboardPageAction] Unset Map Ui Control in Session State',
  //CHAT MESSAGES
  getHistoricChatmessages = '[DashboardPageAction] Get Chat Messages',
  sendCheckStatus = '[DashboardPageAction] Send Check Status',
  sendMessage = '[DashboardPageAction] Send Message',
  //AREAS
  toggleMapUiControl = '[DashboardPageAction] Toggle Map Ui Control',
  setSelectedAreaId = '[DashboardPageAction] Set Selected Area Id',
  clearSelectedIds = '[DashboardPageAction] Clear Selected Ids',
  //LOCATIONS
  setSelectedUserMobileId = '[DashboardPageAction] Set Selected User Mobile Id',
  getHistoricUserLocations = '[DashboardPageAction] Get Historic User Locations',
  //SCHOOL STATE
  updateSchoolState = '[DashboardPageAction] Update School State',
  toggleSelectEventUi = '[DashboardPageAction] Toggle Select Event Ui',
  showSchoolStateChangeModal = '[DashboardPageAction] Show School State Change Modal',
  showSchoolConcludeEventModal = '[DashboardPageAction] Show School Conclude Event Modal',
  updateSchoolOwlState = '[DashboardPageAction] Update School Owl State',
  //DRILL MODE
  showDrillModeChangeModal = '[DashboardPageAction] Show Drill Mode Change Modal',
  // TODO Close the initial prompt window in reducer
  dismissSchoolStateChangeModal = '[DashboardPageAction] Dismiss School State Change Modal',
  clickPulsingAlert = '[DashboardPageAction] Click Pulsing Alert',
  //Geometry Engine Logic
  threatIndicatorDimmed = '[DashboardPageAction] Threat Indicator Dimmed',
  updateAreaWithResponsePayload = `[DashboardPageAction] Update Area Status With Single Response Dto Geospatial Query`,
  updateAreaStatusByAreaIdToDtoLookup = `[DashboardPageAction] Update Area Status With Dto By Area Id`
}
//CHAT MESSAGES
export const getHistoricChatmessages = createAction(
  DashboardPageAction.getHistoricChatmessages,
  props<ExtendedAction<number>>()
)
export const sendCheckStatus = createAction(
  DashboardPageAction.sendCheckStatus,
  props<ExtendedAction<number>>()
)
export const sendMessage = createAction(
  DashboardPageAction.sendMessage,
  props<ExtendedAction<ChatMessageDtoPayload>>()
)
//AREAS
export const setMapUiControlSessionState = createAction(
  DashboardPageAction.setMapUiControlSessionState,
  props<ExtendedAction<IMapUiControlSessionSettings>>()
)
export const toggleMapUiControl = createAction(
  DashboardPageAction.toggleMapUiControl,
  props<ExtendedAction<MapUiControlEnum>>()
)
export const setSelectedArea = createAction(
  DashboardPageAction.setSelectedAreaId,
  props<ExtendedAction<SetAreaAction>>()
)
export const clearSelectedMapId = createAction(DashboardPageAction.clearSelectedIds)
//LOCATIONS
export const getHistoricUserLocations = createAction(
  DashboardPageAction.getHistoricUserLocations,
  props<ExtendedAction<number>>()
)
export const setSelectedUserMobileId = createAction(
  DashboardPageAction.setSelectedUserMobileId,
  props<ExtendedAction<SetUserAction>>()
)
//SCHOOL STATE
export const showOwlSchoolStateChangeModal = createAction(
  DashboardPageAction.showSchoolStateChangeModal,
  props<ExtendedAction<SchoolStateTransitionEnum | EmergencyResponeEnum | AttackerTypeEnum>>()
)
export const showOwlSchoolStateConcludeEventModal = createAction(
  DashboardPageAction.showSchoolConcludeEventModal
)
export const updateSchoolState = createAction(
  DashboardPageAction.updateSchoolState,
  props<
    ExtendedAction<
      EmergencyResponeEnum | AttackerTypeEnum | SchoolStateTransitionEnum | SchoolPreferenceEnum
    >
  >()
)
export const updateSchoolOwlState = createAction(
  DashboardPageAction.updateSchoolOwlState,
  props<ExtendedAction<Partial<SchoolOwlState>>>()
)

//SCHOOL MODE
export const showDrillModeChangeModal = createAction(
  DashboardPageAction.showDrillModeChangeModal,
  props<ExtendedAction<DrillModeEnum>>()
)

//EVENT
export const toggleSelectEventUi = createAction(DashboardPageAction.toggleSelectEventUi)
export const clickPulsingAlert = createAction(
  DashboardPageAction.clickPulsingAlert,
  props<ExtendedAction<LocationOfAlertResponseAttr>>()
)

//Geometry Engine Logic
export const updateAreaStatusByAreaIdToDtoLookup = createAction(
  DashboardPageAction.updateAreaStatusByAreaIdToDtoLookup,
  props<ExtendedAction<GeospatialQueryPayloadHistoric>>()
)
export const updateAreaWithResponsePayload = createAction(
  DashboardPageAction.updateAreaWithResponsePayload,
  props<ExtendedAction<GeospatialQueryPayloadRealtime>>()
)
export const threatIndicatorDimmed = createAction(
  DashboardPageAction.threatIndicatorDimmed,
  props<ExtendedAction<IAreaIdAndThreatVm>>()
)
