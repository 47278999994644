import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { ChatMessageDto } from '@model/message.model'
import { DEFAULT_CHATROOM_ID, DEFAULT_CHATROOM_DISPLAY_TEXT } from '@shared/constants'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { ChatRoomTypeEnum } from './chat.view'
import { CommonUtils } from '@shared/common.utils'

// export interface IChatRoomViewModel {
//   chatRoomId: string
//   /** Name of user or name of area, TODO Determine other labesl */
//   label: string
//   areaId: number | null
//   areaLogicalId: string | null
//   mobileUserIds: string[] | null
//   type: ChatRoomTypeEnum
// }

/** TODO Move to this interface that doesn't need an area id, as long as there's not dependencies on it, then delete above deprecated version */
export interface IChatRoomViewModel {
  chatRoomId: string
  /** Name of user or name of area, TODO Determine other labesl */
  label: string
  areaLogicalId: string | null
  mobileUserIds: string[] | null
  type: ChatRoomTypeEnum
}
export class ChatRoomViewModel {
  static getChatRoomVm = (dto: ChatMessageDto, s: DashboardPageState): IChatRoomViewModel => {
    const { chatRoomId, areaLogicalId, logicalId } = dto.payload
    let chatRoomIdToUse: string | null = null
    // TODO Alerts don't come with a chat room id, determine if they'll be shown in any chat room
    // if (!chatRoomId) {
    //   console.warn(`Chat room id not provided in message dto id: ${logicalId}`)
    //   console.log(`dto`, dto)
    // }
    // let isMultiUserChat = false // Not clear if we're supporting this
    let isAreaChat = false
    let isUserChat = false
    let mobileUserName: string | null = ''
    let areaName = ''
    let mobileUserIds: string[] | null = null
    let dynamicType: ChatRoomTypeEnum

    // AREA SPECIFIC CHAT MESSAGE DTO
    if (areaLogicalId) {
      isAreaChat = true
      areaName = s.areaLogicalIdToChatRoomNameLookup[areaLogicalId]
      chatRoomIdToUse = areaLogicalId
    } else if (
      // USER RESPONSE SPECIFIC CHAT MESSAGE DTO
      //Handle chat responses differently since they will come from exactly one mobile user id
      ChatMessageDtoHelper.isChatResponseMessageDto(dto)
    ) {
      const { mobileUserId } = dto.payload
      isUserChat = true
      mobileUserName = s.mobileIdToDisplayNameLookup[mobileUserId]
      mobileUserIds = [mobileUserId]
      chatRoomIdToUse = mobileUserId
    } else if (ChatMessageDtoHelper.isChatMessage(dto)) {
      // When we send a message we have nested array of one mobile user id and
      if (Array.isArray(dto.payload.mobileUserIds) && dto.payload.mobileUserIds.length > 0) {
        isUserChat = true
        const mobileIdForChatRoom = dto.payload.mobileUserIds[0]
        if (mobileIdForChatRoom) {
          mobileUserName = s.mobileIdToDisplayNameLookup[mobileIdForChatRoom] ?? null
          mobileUserIds = dto.payload.mobileUserIds
          chatRoomIdToUse = mobileIdForChatRoom
        }
        // when we get historic we just have the user mobile id as the chat room id
      } else if (dto.payload.chatRoomId && s.mobileIdToDisplayNameLookup[dto.payload.chatRoomId]) {
        isUserChat = true
        const mobileIdForChatRoom = dto.payload.chatRoomId
        if (mobileIdForChatRoom) {
          mobileUserName = s.mobileIdToDisplayNameLookup[mobileIdForChatRoom] ?? null
          mobileUserIds = [mobileIdForChatRoom]
          chatRoomIdToUse = mobileIdForChatRoom
        }
      }
    }
    if (isUserChat) {
      dynamicType = ChatRoomTypeEnum.oneOnOne
    } else if (isAreaChat) {
      dynamicType = ChatRoomTypeEnum.subarea
    } else {
      dynamicType = ChatRoomTypeEnum.everyone
    }
    let dynamicLabel = ''
    if (isUserChat) {
      dynamicLabel = mobileUserName
    } else if (isAreaChat) {
      dynamicLabel = areaName
    }
    if (!chatRoomIdToUse) {
      chatRoomIdToUse = DEFAULT_CHATROOM_ID
    }
    return {
      areaLogicalId,
      chatRoomId: chatRoomIdToUse,
      label: !!dynamicLabel ? dynamicLabel : DEFAULT_CHATROOM_DISPLAY_TEXT,
      mobileUserIds,
      type: dynamicType
      //   TODO Determine if we'll need the area id in the context of a chat room
      //   areaId: null
    }
  }
}
