import { ExtendedAction } from '@action/extended-ngrx-action'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { ChatMessageDto, ChatMessageDtoPayload, ChatResponseDtoPayload } from '@model/message.model'
import {
  GetPredefinedGroupDto,
  GetResponseDto,
  PredefinedMessageType
} from '@model/message/predefined-message.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { SignalrMessageType } from '@model/signalr/signalr.model'
import { MobileUserTypes } from '@model/user/user.model'
import {
  DEFAULT_CHATROOM_DISPLAY_TEXT,
  DEFAULT_CHATROOM_ID,
  ENTIRE_SCHOOL_CHATROOM_DISPLAY_TEXT,
  ENTIRE_SCHOOL_CHATROOM_ID
} from '@shared/constants'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { AreaStatusViewModel } from '@view/area/area-status.view'
import { StatusColorEnum } from '@view/area/area.view'
import {
  ChatMessageVmType,
  ChatUiItemViewModel,
  ChatUiListItemViewModel
} from '@view/messages/message.view'
import { ChatPollViewModel } from '@view/poll/poll.view'
import { IChatRoomSelectViewModel } from './chat-room-select.view'
import { IChatRoomViewModel } from './chat-room.view'
import { ResponseIdChatMessageVm } from '@view/messages/response-id-message.view'

export interface ScrollPlacesInChatRoom {
  topPosition: number
  lastMessageTimeStamp: string
}

export class ChatRoomScrollData {
  constructor(
    public placesInChatRoom: ScrollPlacesInChatRoom,
    public messagesTimeStamps: string[],
    public currentChatRoomId: string
  ) {}

  get savedViewedMessageIndex(): number {
    if (this.placesInChatRoom.lastMessageTimeStamp !== '' && this.messagesTimeStamps.length > 0) {
      return this.messagesTimeStamps.lastIndexOf(this.placesInChatRoom.lastMessageTimeStamp)
    }
    return -1
  }

  get newMessagesCount(): number {
    if (this.placesInChatRoom && this.messagesTimeStamps.length > 0) {
      const lastViewedMessageIndex = this.savedViewedMessageIndex

      if (lastViewedMessageIndex > 0 && this.messagesTimeStamps.length > lastViewedMessageIndex) {
        return this.messagesTimeStamps.length - lastViewedMessageIndex - 1
      }
    }

    return 0
  }

  isMessageOlderThanSaved(currentTimeStamp: string): boolean {
    const currentIndex = this.messagesTimeStamps.indexOf(currentTimeStamp)
    return currentIndex > this.savedViewedMessageIndex
  }
}

export enum ChatRoomTypeEnum {
  entireSchool = 'entireSchool',
  everyone = 'everyone',
  oneOnOne = 'oneOnOne',
  subarea = 'subarea'
}
export type ChatRoomMessagesLookup = Record<string, ChatMessageDto[]>
export enum ChatUiViewModelProps {
  chatMenuOpen = 'chatMenuOpen',
  showSelectMessage = 'showSelectMessage',
  showSelectPoll = 'showSelectPoll',
  showCreatePoll = 'showCreatePoll',
  selectedChatRoomId = 'selectedChatRoomId',
  messagesByChatroomId = 'messagesByChatroomId',
  showChatList = 'showChatList',
  isTeachersButtonEnabled = 'isTeachersButtonEnabled',
  isOtherStaffButtonEnabled = 'isOtherStaffButtonEnabled',
  isStudentsButtonEnabled = 'isStudentsButtonEnabled',
  scrollPlacesInChatRooms = 'scrollPlacesInChatRooms'
}

export class ChatUiViewModel {
  public [ChatUiViewModelProps.chatMenuOpen] = false
  public [ChatUiViewModelProps.showSelectMessage] = false
  public [ChatUiViewModelProps.showSelectPoll] = false
  public [ChatUiViewModelProps.showCreatePoll] = false
  public [ChatUiViewModelProps.showChatList] = true
  public [ChatUiViewModelProps.isTeachersButtonEnabled] = true
  public [ChatUiViewModelProps.isOtherStaffButtonEnabled] = true
  public [ChatUiViewModelProps.isStudentsButtonEnabled] = false
  public [ChatUiViewModelProps.scrollPlacesInChatRooms] = {} as Record<
    string,
    ScrollPlacesInChatRoom
  >

  /** TODO Determine if it should be a guid or id */
  selectedPredefinedItemId: number = 0

  static messageSizes = {
    mainTextLineHeight: 20,
    itemsInMaineLine: 32,
    headerHeight: 26,
    emptyHeaderHeight: 2,
    emptyHeaderHeightToOneUser: 6,
    extraLineHeight: 17,
    mainPadding: 24,
    pollResultPadding: 28,
    pollAnswersHeight: 16,
    pollAnswersPadding: 8,
    pollNoAnswerHeight: 24,
    pollHeaderMargin: 2,
    totalHeight: 32,
    itemsInPollAnswerLine: 10,
    bottomLabel: 16,
    margin: 8
  }

  /** Tracks the active chatroom  */
  public [ChatUiViewModelProps.selectedChatRoomId]: string = DEFAULT_CHATROOM_ID
  /** Get a collection of chat messages based on the chat room id.
   * Global chat room for the school aka the default chat room, here in case none were made.
   * TODO Decide if global means across collection of districts or if just for a selected school if that's used
   */
  public [ChatUiViewModelProps.messagesByChatroomId]: ChatRoomMessagesLookup = {
    [DEFAULT_CHATROOM_ID]: [],
    [ENTIRE_SCHOOL_CHATROOM_ID]: []
  }
  static calculateMessageSizes = (
    isOneOnOneChatRoom: boolean,
    messages: ChatUiItemViewModel[] | null
  ): number[] => {
    return (
      messages?.map((message, index) => {
        //Handle 72px height response id message - if we have really long messages this will need to be extended.
        if (message.type == ChatMessageVmType.responseIdMessage) {
          const responseIdHeight = 72 + this.messageSizes.margin
          return responseIdHeight
        } else if (message.type == ChatMessageVmType.poll) {
          //header Height
          let pollHeight = this.messageSizes.pollHeaderMargin
          //question Height
          pollHeight +=
            Math.ceil(
              (message.pollVm?.question?.length ?? 1) / this.messageSizes.itemsInMaineLine
            ) * this.messageSizes.mainTextLineHeight
          //answers Height
          message.pollVm?.aggregatedResultItem.forEach((element) => {
            pollHeight +=
              Math.ceil(element.answer.length / this.messageSizes.itemsInPollAnswerLine) *
                this.messageSizes.pollAnswersHeight +
              this.messageSizes.pollAnswersPadding
          })
          //start margin Height
          if (index === 0) {
            pollHeight += this.messageSizes.margin
          }
          //total Height + paddings
          pollHeight +=
            this.messageSizes.totalHeight +
            this.messageSizes.pollResultPadding +
            this.messageSizes.mainPadding +
            this.messageSizes.margin +
            this.messageSizes.bottomLabel +
            ((message.pollVm?.notAnsweredCount ?? 0) > 0 ? this.messageSizes.pollNoAnswerHeight : 0)
          return pollHeight
        } else {
          let messageHeight = 0
          if (message.messageVm?.message) {
            //start margin Height
            if (index === 0) {
              messageHeight += this.messageSizes.margin
            }
            if (!isOneOnOneChatRoom) {
              messageHeight += this.messageSizes.headerHeight
              if (!message.isMessageToOneUser) {
                messageHeight += this.messageSizes.emptyHeaderHeightToOneUser
              }
            } else {
              messageHeight += this.messageSizes.emptyHeaderHeight
            }

            let linesCount = this.calcLinesCount(message.messageVm?.message ?? '')

            if (linesCount < 2 && isOneOnOneChatRoom) {
              messageHeight += this.messageSizes.extraLineHeight
            }

            messageHeight +=
              linesCount * this.messageSizes.mainTextLineHeight +
              this.messageSizes.mainPadding +
              this.messageSizes.margin
          }
          return messageHeight
        }
      }) ?? []
    )
  }

  static calcLinesCount = (text: string): number => {
    let linesArray = text.split('\n')
    let linesCount = 0
    linesArray.forEach((sbstr) => {
      linesCount += Math.ceil(sbstr.length / this.messageSizes.itemsInMaineLine)
    })
    return linesCount
  }

  static getChatSentPollVm = (
    dto: ChatMessageDto,
    responseIdsForPollLogicalId: Record<string, number[]>,
    areaGuidToNameLookup: Record<string, string>,
    responseIdLookup: Record<number, GetResponseDto>
  ): ChatUiItemViewModel => {
    const vm = ChatUiItemViewModel.getSentPollVmFromDto(
      dto.payload as ChatMessageDtoPayload,
      areaGuidToNameLookup
    )
    vm.pollVm = ChatUiItemViewModel.setAnsweresInPollVm(
      vm.pollVm ?? new ChatPollViewModel(),
      responseIdsForPollLogicalId[dto.payload.logicalId ?? ''] ?? [],
      responseIdLookup
    )
    return vm
  }

  /** Centralize all logic for building a specific view model based on the dto type or the contents within TODO Add alert, suggested poll, poll auto sent, etc... */
  static getChatMessageResponseVmFromDto = (
    dto: ChatMessageDto,
    userDisplayNameLookup: Record<string, string>,
    areaGuidToNameLookup: Record<string, string>,
    userToTypeLookup: Record<string, MobileUserTypes>
  ): ChatUiItemViewModel => {
    let vm = new ChatUiItemViewModel()
    if (dto.payload.message && dto.type === SignalrMessageType.chatMessage) {
      const typedPayload = dto.payload as ChatMessageDtoPayload
      vm = ChatUiItemViewModel.getChatMessageVmFromDto(
        typedPayload,
        userDisplayNameLookup,
        areaGuidToNameLookup
      )
    } else if (
      //Don't show alert related responses until the UI is implemented - need to bring in the response dto here
      // !AlertMessageHelper.IsAlertResponseType(dto.type) &&
      // TODO Issue wehre empty poll component is displayed even though a response id is present a poll logical id isn't and that logic needs to be integrated
      dto.payload.message &&
      dto.type === SignalrMessageType.chatResponse
    ) {
      const typedPayload = dto.payload as ChatResponseDtoPayload
      vm = ChatUiItemViewModel.getChatResponseVmFromDto(
        typedPayload,
        userDisplayNameLookup,
        userToTypeLookup
      )
    }
    return vm
  }

  //SIGNALR Combinations
  // TODO Need to build a chat room to map view model that associates the display name, the area name, the chat room guid, and other related data for easy access
  static getChatroomOptions = (
    chatRoomIdToAttributes: Record<string, IChatRoomViewModel>
  ): IChatRoomViewModel[] => {
    return Object.values(chatRoomIdToAttributes)
  }
  static selectedChatroomLabel = (
    chatRoomIdToAttributes: Record<string, IChatRoomViewModel>,
    id: string
  ): string => {
    return chatRoomIdToAttributes[id].label
  }
  /** Takes a collection of poll sent, and messages sent/receive with related display data to return view models to show in the selected chat room */
  static getChatroomMessages = (
    chatVm: ChatUiViewModel,
    // TODO Use attributes to find name instead of using area guid to name and user display name lookups
    chatRoomIdToAttributesLookup: Record<string, IChatRoomViewModel>,
    userDisplayNameLookup: Record<string, string>,
    areaGuidToNameLookup: Record<string, string>,
    responseIdsForPollLogicalId: Record<string, number[]>,
    responseIdToGetResponseDto: Record<number, GetResponseDto>,
    userToTypeLookup: Record<string, MobileUserTypes>
  ): ChatUiItemViewModel[] | null => {
    if (!chatVm) {
      return null
    }
    const dtos = chatVm.messagesByChatroomId[chatVm.selectedChatRoomId]
    if (!dtos || dtos.length === 0) {
      return null
    }

    // //Need to check the dto type and its contents on iteration and either build a chat ui item type message or chat ui item type poll
    let vms: ChatUiItemViewModel[] = dtos
      .map((dto: ChatMessageDto): ChatUiItemViewModel => {
        const [isResponseIdDto, responseDto] = ChatMessageDtoHelper.isResponseIdMessage(
          dto,
          responseIdToGetResponseDto
        )
        if (isResponseIdDto && responseDto) {
          return ResponseIdChatMessageVm.getVmFromDto(
            dto.payload as ChatResponseDtoPayload,
            responseDto,
            chatVm.selectedChatRoomId
          )
        } else if (ChatMessageDtoHelper.isSentPoll(dto)) {
          return ChatUiViewModel.getChatSentPollVm(
            dto,
            responseIdsForPollLogicalId,
            areaGuidToNameLookup,
            responseIdToGetResponseDto
          )
        } else {
          // If it's not a sent poll then it's assumed to be a sent or received message
          return ChatUiViewModel.getChatMessageResponseVmFromDto(
            dto,
            userDisplayNameLookup,
            areaGuidToNameLookup,
            userToTypeLookup
          )
        }
        //  else {
        //   console.info(`
        //     Chat Message DTO
        //       type ${dto.type}
        //       id ${dto.payload.id}
        //     not handled in VM construction
        //     `)
        //   return null
        // }
      })
      .filter((vm): boolean => !!vm)
    if (vms.length > 0) {
      // console.table(vms)
      return vms
    } else {
      return null
    }
  }

  static getChatLists = (
    chatVm: ChatUiViewModel,
    userDisplayNameLookup: Record<string, string>,
    areaGuidToNameLookup: Record<string, string>,
    areaStatusLookup: Record<string, AreaStatusViewModel>,
    responseIdsForPollLogicalId: Record<string, number[]>,
    responseIdToGetResponseDto: Record<number, GetResponseDto>,
    userToTypeLookup: Record<string, MobileUserTypes>
  ): ChatUiListItemViewModel[] | null => {
    if (!chatVm) {
      return null
    }
    let vms: ChatUiListItemViewModel[] = Object.entries(chatVm.messagesByChatroomId)
      .map((lookup): ChatUiListItemViewModel => {
        const key = lookup[0]
        const value = lookup[1].filter((m) => !m.payload.autoSent)
        const lastMessageDto = value[value.length - 1]
        let recentMessage = null
        if (lastMessageDto != null) {
          recentMessage = ChatMessageDtoHelper.isSentPoll(lastMessageDto)
            ? ChatUiViewModel.getChatSentPollVm(
                lastMessageDto,
                responseIdsForPollLogicalId,
                areaGuidToNameLookup,
                responseIdToGetResponseDto
              )
            : ChatUiViewModel.getChatMessageResponseVmFromDto(
                lastMessageDto,
                userDisplayNameLookup,
                areaGuidToNameLookup,
                userToTypeLookup
              )
        }
        let displayName =
          key == DEFAULT_CHATROOM_ID
            ? DEFAULT_CHATROOM_DISPLAY_TEXT
            : key == ENTIRE_SCHOOL_CHATROOM_ID
            ? ENTIRE_SCHOOL_CHATROOM_DISPLAY_TEXT
            : recentMessage?.audience
        if (!displayName) {
          displayName =
            userDisplayNameLookup[key] ??
            areaGuidToNameLookup[key] ??
            recentMessage?.messageVm?.userDisplayName
        }
        var type = ChatRoomTypeEnum.oneOnOne
        if (key == DEFAULT_CHATROOM_ID) {
          type = ChatRoomTypeEnum.everyone
        } else if (key == ENTIRE_SCHOOL_CHATROOM_ID) {
          type = ChatRoomTypeEnum.entireSchool
        } else if (!!areaGuidToNameLookup[key]) {
          type = ChatRoomTypeEnum.subarea
        }
        return {
          chatRoomId: key,
          chatRoomDisplayName: displayName,
          recentMessage: recentMessage,
          type: type,
          unreadCount: 0
        }
      })
      .filter((vm): boolean => !!vm)
      .sort((a, b) => {
        if (a.type == ChatRoomTypeEnum.entireSchool || a.type == ChatRoomTypeEnum.everyone) {
          return -1
        }
        if (b.type == ChatRoomTypeEnum.entireSchool || b.type == ChatRoomTypeEnum.everyone) {
          return 1
        }
        const firstTimestamp = Date.parse(a.recentMessage?.timestamp ?? new Date(+0).toString())
        const secondTimestamp = Date.parse(b.recentMessage?.timestamp ?? new Date(+0).toString())
        if (firstTimestamp > secondTimestamp) {
          return -1
        } else if (firstTimestamp < secondTimestamp) {
          return 1
        } else {
          return 0
        }
      })
    return vms.length > 0 ? vms : null
  }

  //
  static getSelectedPredefinedMessageTypeForSelection = (
    vm: ChatUiViewModel,
    state: SchoolStateEnum | null
  ): PredefinedMessageType | null => {
    if (state !== SchoolStateEnum.routine) {
      return vm.showSelectMessage
        ? PredefinedMessageType.message
        : vm.showSelectPoll
        ? PredefinedMessageType.poll
        : null
    }
    return null
  }

  //REDUCER HANDLERS
  //CHAT UI
  /** Track the use selected chatroom. */
  static handleSelectedChatroom = (
    s: DashboardPageState,
    a: ExtendedAction<string>
  ): DashboardPageState => {
    const { chatVm } = s.vm
    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...chatVm,
          showSelectPoll: false,
          selectedChatRoomId: a.payload
        }
      }
    }
  }
  static toggleChatUiVisibility = (s: DashboardPageState): DashboardPageState => {
    // TODO Clear empty chats on close
    // const newMessageByChatroomId = Object.assign({}, s.vm.chatVm.messagesByChatroomId)
    // let newMessageChatRoomButtons= Object.assign({}, s.chatRoomIdToAttributesLookup)
    // //If we're closing the chat UI clear out the chat room related data if it has no content
    // if (s.vm.chatUiVisible) {
    //   //Remove any aspect of a non interacted with chat room from state
    //   Object.entries(newMessageByChatroomId).forEach((entry) => {
    //     const key = entry[0]
    //     const value = entry[1]
    //     if (value.length === 0) {
    //       delete newMessageByChatroomId[key]
    //       delete newMessageChatRoomButtons[key]
    //     }
    //   })
    // }
    return {
      ...s,
      // chatRoomIdToAttributesLookup: newMessageChatRoomButtons,
      vm: {
        ...s.vm,
        chatUiVisible: !s.vm.chatUiVisible
        // chatVm: {
        //   ...s.vm.chatVm,
        //   messagesByChatroomId: newMessageByChatroomId,
        // }
      }
    }
  }
  static handleChatRoomChangeRight = (s: DashboardPageState): DashboardPageState => {
    const currentChatRoomId = s.vm.chatVm.selectedChatRoomId
    let nextIndex = 0
    let nextChatRoomId = null
    const keys = Object.keys(s.chatRoomIdToAttributesLookup)
    const values = Object.values(s.chatRoomIdToAttributesLookup)
    values.forEach((value: IChatRoomViewModel, index: number, array: IChatRoomViewModel[]) => {
      const isChatRoom = value.chatRoomId === currentChatRoomId
      if (isChatRoom && index < array.length - 1) {
        nextIndex = index + 1
      } else if (isChatRoom && index === array.length) {
        nextIndex = 0
      }
    })
    nextChatRoomId = keys[nextIndex]
    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          selectedChatRoomId: nextChatRoomId
        }
      }
    }
  }
  static handleChatRoomChangeLeft = (s: DashboardPageState): DashboardPageState => {
    const currentChatRoomId = s.vm.chatVm.selectedChatRoomId
    let nextIndex = 0
    let nextChatRoomId = null
    const keys = Object.keys(s.chatRoomIdToAttributesLookup)
    const values = Object.values(s.chatRoomIdToAttributesLookup)
    values.forEach((value: IChatRoomViewModel, index: number, array: IChatRoomViewModel[]) => {
      const isChatRoom = value.chatRoomId === currentChatRoomId
      if (isChatRoom && index > 0) {
        nextIndex = index - 1
      } else if (isChatRoom && index === 0) {
        nextIndex = array.length - 1
      }
    })
    nextChatRoomId = keys[nextIndex]
    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          selectedChatRoomId: nextChatRoomId
        }
      }
    }
  }
  static toggleChatUiMenuVisibility = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.chatMenuOpen)
    )
  }
  static toggleTeachersTypeButton = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.isTeachersButtonEnabled)
    )
  }
  static toggleOtherStaffTypeButton = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(
        s.vm.chatVm,
        ChatUiViewModelProps.isOtherStaffButtonEnabled
      )
    )
  }
  static toggleStudentsTypeButton = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.isStudentsButtonEnabled)
    )
  }
  static handleSelectMessageClick = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.showSelectMessage)
    )
  }
  static handleSelectPollClick = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.showSelectPoll)
    )
  }
  static handleCreatePollClick = (s: DashboardPageState): DashboardPageState => {
    return ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.showCreatePoll)
    )
  }
  static handleShowChatListClick = (
    s: DashboardPageState,
    a: ExtendedAction<string>
  ): DashboardPageState => {
    let newSate = ChatUiViewModel.getNewDashPageStateWithChatVm(
      s,
      ChatUiViewModel.toggleBooleanVmProp(s.vm.chatVm, ChatUiViewModelProps.showChatList)
    )
    if (!a.payload) return newSate
    return ChatUiViewModel.handleSelectedChatroom(newSate, a)
  }
  static getNewDashPageStateWithChatVm(
    s: DashboardPageState,
    newVm: ChatUiViewModel
  ): DashboardPageState {
    const newState = Object.assign({}, s)
    const newDashVm = Object.assign({}, s.vm)
    const newChatVm = Object.assign({}, newVm)
    newState.vm = newDashVm
    newState.vm.chatVm = newChatVm
    return newState
  }
  // TODO Consider bringing this to a base class for toggling boolean properties
  /** Toggle Boolean prop */
  static toggleBooleanVmProp(vm: ChatUiViewModel, prop: ChatUiViewModelProps) {
    return ChatUiViewModel.createNewChatVmWithUpdatedProp<boolean>(!vm[prop], prop, vm)
  }
  /** Build state internal utils */
  static createNewChatVmWithUpdatedProp<T>(
    value: T,
    key: ChatUiViewModelProps,
    vm: ChatUiViewModel
  ): ChatUiViewModel {
    let chatMenuShouldAutoClose = false
    if (
      (key === ChatUiViewModelProps.showCreatePoll ||
        key === ChatUiViewModelProps.showSelectMessage ||
        key === ChatUiViewModelProps.showSelectPoll) &&
      value === true &&
      vm[ChatUiViewModelProps.chatMenuOpen]
    ) {
      chatMenuShouldAutoClose = true
    }
    const newChatVm = {
      ...vm,
      [key]: value as T,
      [ChatUiViewModelProps.chatMenuOpen]: chatMenuShouldAutoClose
        ? false
        : vm[ChatUiViewModelProps.chatMenuOpen]
    }
    // console.log(`new chat vm`, newChatVm)
    return newChatVm
  }
  /** Provide the data needed to show the selected chat room component. */
  static returnChatRoomSelectVm(
    chatUiVisible: boolean,
    selectedChatRoomId: string,
    vms: IChatRoomViewModel[]
  ): IChatRoomSelectViewModel | null {
    let chatRoomSelectVm: IChatRoomSelectViewModel = {
      selectedChatRoomId,
      chatUiVisible,
      index: -1
    }
    vms.forEach((vm: IChatRoomViewModel, i: number) => {
      if (vm.chatRoomId === selectedChatRoomId) {
        chatRoomSelectVm.index = i
      }
    })
    if (chatRoomSelectVm.index !== -1) {
      // console.log(`Returning chat room select vm`)
      // console.log(chatRoomSelectVm)
      return chatRoomSelectVm
    } else {
      return null
    }
  }

  //MESSAGE
  static returnActiveChatAttributes(
    lookup: Record<string, IChatRoomViewModel>,
    selectActiveChatRoom: string
  ): IChatRoomViewModel | null {
    return lookup[selectActiveChatRoom]
  }

  static returnActiveChatType(
    lookup: Record<string, IChatRoomViewModel>,
    selectActiveChatRoom: string
  ): ChatRoomTypeEnum | null {
    return lookup[selectActiveChatRoom]?.type ?? null
  }
  //Filters chat messages by all non poll messages for display on map, if they have a location
  static getMessagesToShowOnMap(dtos: ChatMessageDto[]) {
    return dtos.filter((dto) => {
      if (dto.type === SignalrMessageType.chatResponse) {
        const typedDto = dto.payload as ChatResponseDtoPayload
        return !!typedDto.latLong
      } else {
        return false
      }
    })
  }

  //TODO Clean up this three tier spread operator
  static handleSelectPredefinedItemId = (
    s: DashboardPageState,
    a: ExtendedAction<number>
  ): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          selectedPredefinedItemId: a.payload
        }
      }
    }
  }
  static getSelectedPredefinedItem = (
    groupedMessages: GetPredefinedGroupDto[] | null,
    selectedPredefinedItemId: number
  ): GetPredefinedGroupDto | null => {
    if (!groupedMessages) {
      return null
    }
    return selectedPredefinedItemId !== 0
      ? groupedMessages?.filter((m) => m.id === selectedPredefinedItemId)[0]
      : null
  }

  static setScrollPlacesInChatRooms = (
    s: DashboardPageState,
    a: ExtendedAction<Record<string, ScrollPlacesInChatRoom>>
  ): DashboardPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          scrollPlacesInChatRooms: a.payload
        }
      }
    }
  }

  static setScrollPlacesInChatRoom = (
    s: DashboardPageState,
    a: ExtendedAction<ScrollPlacesInChatRoom>
  ): DashboardPageState => {
    let chatRoomId = s.vm.chatVm.selectedChatRoomId
    let places = Object.assign({}, s.vm.chatVm.scrollPlacesInChatRooms)

    places[chatRoomId] = a.payload

    return {
      ...s,
      vm: {
        ...s.vm,
        chatVm: {
          ...s.vm.chatVm,
          scrollPlacesInChatRooms: places
        }
      }
    }
  }

  static getUpdatedScrollPlacesInChatRoom = (
    s: DashboardPageState,
    a: ExtendedAction<ScrollPlacesInChatRoom>
  ): Record<string, ScrollPlacesInChatRoom> => {
    let chatRoomId = s.vm.chatVm.selectedChatRoomId
    let places = Object.assign({}, s.vm.chatVm.scrollPlacesInChatRooms)
    places[chatRoomId] = a.payload
    return places
  }

  static getScrollDataForChatRoom = (
    scrollPlacesInChatRooms: Record<string, ScrollPlacesInChatRoom>,
    chatUiItems: ChatUiItemViewModel[] | null,
    currentChatRoomId: string
  ): ChatRoomScrollData | null => {
    if (!chatUiItems || !currentChatRoomId) {
      return null
    }

    let chatRoomScrollData = new ChatRoomScrollData(
      {
        lastMessageTimeStamp: '',
        topPosition: 0
      },
      chatUiItems.map((m) => m.timestamp),
      currentChatRoomId
    )
    const placesInChatRoom: ScrollPlacesInChatRoom | undefined =
      scrollPlacesInChatRooms[currentChatRoomId]
    if (placesInChatRoom) {
      // console.log('if (scrollPlacesInChatRooms[currentChatRoomId])')
      chatRoomScrollData.placesInChatRoom = { ...placesInChatRoom }
      if (
        chatRoomScrollData.placesInChatRoom.lastMessageTimeStamp !== '' &&
        chatRoomScrollData.messagesTimeStamps.lastIndexOf(
          scrollPlacesInChatRooms[currentChatRoomId].lastMessageTimeStamp
        ) < 0
      ) {
        chatRoomScrollData.placesInChatRoom.lastMessageTimeStamp = ''
      }
      return chatRoomScrollData
    } else {
      // console.log('else - if (scrollPlacesInChatRooms[currentChatRoomId])')

      chatRoomScrollData.placesInChatRoom = {
        lastMessageTimeStamp: '',
        topPosition: 0
      }
      // console.log(chatRoomScrollData)
      return chatRoomScrollData
    }
  }
}
