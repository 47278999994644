import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import {
  getResponseGroupsFail,
  getResponseGroupsSuccess
} from '@action/messages/response-group-api.actions'
import { getResponseGroupsForSchool } from '@action/messages/response-group-page.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ResponseGroupApiService } from '@service/network/response-group.service'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class ResponseGroupEffects {
  constructor(
    private actions$: Actions,
    private responseGroupApiService: ResponseGroupApiService
  ) // private store: Store,
  // private _appConfig: AppConfigService
  {}
  getPredefinedMessagesBySchoolId = createEffect(() =>
    this.actions$.pipe(
      ofType(getResponseGroupsForSchool),
      mergeMap((a: ExtendedAction<number>) =>
        this.responseGroupApiService.getResponseGroups(a.payload).pipe(
          map((res) => getResponseGroupsSuccess(getExtendedAction(res))),
          catchError((e: HttpErrorResponse) => of(getResponseGroupsFail(getExtendedAction(e))))
        )
      )
    )
  )
}
