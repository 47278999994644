import { FullLocation } from '@model/location.model'
import { StatusColorEnum } from '@view/area/area.view'
import { MobileUserTypes } from './user.model'
import { ChatResponseDtoPayload } from '@model/message.model'
import { LocationStatusViewModel } from '@view/area/location.view'
import { RecentMessageIndicatorTypeEnum } from '@view/area/graphic-attributes.model'

export interface UserLocationDto {
  id: number
  mobileUserId: string
  schoolLogicalId: string
  timestamp: string
  latLong: FullLocation
}
export interface UserPresenceChangeDto {
  mobileUserId: string
  schoolLogicalId: string
  timeStamp: string
}
export enum DecoratedUserLocationViewModelProps {
  recentMessageIndicatorType = 'recentMessageIndicatorType',
  oldestConsideredDtoTimestamp = 'oldestConsideredDtoTimestamp',
  chatResponseDtoPayload = 'chatResponseDtoPayload',
  statusColor = 'statusColor',
  statusMessage = 'statusMessage',
  message = 'message'
}
// TODO Discuss how we need to know the user type - we can either get a collection of all users and have a lookup dictionary in FE state, or we can decorate every location with their type based on their id
export interface DecoratedUserLocationViewModel {
  /** @deprecated Find a reason for this to be here or let's remove it //TODO why is this a number and not a string? */
  schoolId: number
  mobileUserId: string
  //From location or latest chat message/alert/poll reponse
  latLong: FullLocation | null
  // Attributes collected from users endpoint
  /**
   * When we get a location from balcony type might be undefined but we can decorate it since we'll be holding all the users for that school as a cached lookup object.
   * */
  userType?: MobileUserTypes
  fullName?: string
  email?: string
  phone?: string
  locTimeStamp: Date | null

  //View model logic based on last message rules by priority
  //TODO unify hanlding to LocationStatusViewModel
  locationStatusVm?: LocationStatusViewModel | null

  /** @deprecated use locationStatusVm instead of spreading vm in here */
  [DecoratedUserLocationViewModelProps.statusColor]: StatusColorEnum | null
  /** @deprecated use locationStatusVm instead of spreading vm in here */
  [DecoratedUserLocationViewModelProps.statusMessage]: string | null
  /** This should be the recent poll response, message or alert by rule of priority. */
  [DecoratedUserLocationViewModelProps.chatResponseDtoPayload]: ChatResponseDtoPayload | null
  [DecoratedUserLocationViewModelProps.recentMessageIndicatorType]: RecentMessageIndicatorTypeEnum
  /** Used to track the oldest DTO that has an effect on the recent message indicator */
  [DecoratedUserLocationViewModelProps.oldestConsideredDtoTimestamp]: Date | null
}
export class DecoratedUserLocationDefaults implements Partial<DecoratedUserLocationViewModel> {
  public [DecoratedUserLocationViewModelProps.recentMessageIndicatorType] =
    RecentMessageIndicatorTypeEnum.unknown
  public [DecoratedUserLocationViewModelProps.statusColor] = StatusColorEnum.unknown
  public [DecoratedUserLocationViewModelProps.statusMessage] = ''
  public [DecoratedUserLocationViewModelProps.message] = null
  public [DecoratedUserLocationViewModelProps.chatResponseDtoPayload]: ChatResponseDtoPayload | null =
    null
  public [DecoratedUserLocationViewModelProps.oldestConsideredDtoTimestamp] = null
}
// TODO Follow pattern with AreaStatusViewModel and set up reset of view model since it depends on multiple dtos like message and location, so we can reset it rather than remove all the data
// export class UserLocationViewModelDefaults {
//   static resetFromVm = (vm: UserLocationViewModelDefaults)
// }
export interface UserSosUpdate extends DecoratedUserLocationViewModel {
  //TODO potentially add fields here, maybe a message or status
}
export interface UserStatusUpdate extends DecoratedUserLocationViewModel {
  pollId: number
  pollResonseId: number
}
