import { alphanumericValidationMessage } from '@shared/constants';

export type AreaGeomValidationErrors = MapConfigValidationErrors.areaSelfIntersects |
    MapConfigValidationErrors.intersectsOtherAreas |
    MapConfigValidationErrors.intersectsBoundary |
    MapConfigValidationErrors.areaVerticesOnEdges

export type BoundaryGeomValidationErrors = MapConfigValidationErrors.boundaryDoesntIncludeAllAreas |
    MapConfigValidationErrors.boundarySelfIntersects | MapConfigValidationErrors.boundaryMustExist

export enum MapConfigValidationErrors {
    // Attribute validation
    emptyAreaName = 'emptyAreaName',
    areaNameExistsForAreaTypeAlready = 'areaNameExistsForAreaTypeAlready',
    nameTooLong = 'tooLong',
    invalidAreaName = 'invalidAreaName',
    // Geometry Validation
    // area
    areaSelfIntersects = 'areaSelfIntersects',
    intersectsOtherAreas = 'intersectsOtherAreas',
    intersectsBoundary = 'intersectsBoundary',
    areaVerticesOnEdges = 'areaVerticesOnEdges',
    // area quantity
    atLeastOneAreaMustExist = 'atLeastOneAreaMustExist',
    // boundary
    boundaryDoesntIncludeAllAreas = 'boundaryDoesntIncludeAllAreas',
    boundarySelfIntersects = 'boundarySelfIntersects',
    boundaryMustExist = 'boundaryMustExist'
}

export namespace MapConfigValidationViewModel {

    /** Utility to narrow enum to specific logical values related to area attributes errors */
    export const errorIsRelatedToAreaAttributes = (
        error: MapConfigValidationErrors | null
    ): error is AreaGeomValidationErrors => {
        switch (error) {
            case MapConfigValidationErrors.emptyAreaName:
            case MapConfigValidationErrors.areaNameExistsForAreaTypeAlready:
            case MapConfigValidationErrors.nameTooLong:
                return true
            default:
                return false
        }
    }
    /** Utility to narrow enum to specific logical values related to area geom validation errors */
    export const errorIsRelatedToAreaGeom = (
        error: MapConfigValidationErrors | null
    ): error is AreaGeomValidationErrors => {
        switch (error) {
            case MapConfigValidationErrors.areaSelfIntersects:
            case MapConfigValidationErrors.intersectsOtherAreas:
            case MapConfigValidationErrors.intersectsBoundary:
            case MapConfigValidationErrors.areaVerticesOnEdges:
                return true
            default:
                return false
        }
    }
    /** Utility to narrow enum to specific logical values related to area geom validation errors */
    export const errorNotRelatedToAreaGeom = (
        error: MapConfigValidationErrors
    ): error is AreaGeomValidationErrors => {
        switch (error) {
            case MapConfigValidationErrors.areaSelfIntersects:
            case MapConfigValidationErrors.intersectsOtherAreas:
            case MapConfigValidationErrors.intersectsBoundary:
            case MapConfigValidationErrors.areaVerticesOnEdges:
                return false
            default:
                return true
        }
    }
    export const errorIsRelatedToBoundaryGeom = (
        error: MapConfigValidationErrors | null
    ): error is BoundaryGeomValidationErrors => {
        switch (error) {
            case MapConfigValidationErrors.boundaryDoesntIncludeAllAreas:
            case MapConfigValidationErrors.boundarySelfIntersects:
                return true
            default:
                return false
        }
    }
    export const errorIsRelatedToAreaStep = (error: MapConfigValidationErrors | null): boolean => {
        return errorIsRelatedToAreaAttributes(error) || errorIsRelatedToAreaGeom(error)
    }
    export const mapConfigErrorMessages: Record<MapConfigValidationErrors, string> = {
        // Attributes
        [MapConfigValidationErrors.emptyAreaName]: 'Area must have a name.',
        [MapConfigValidationErrors.areaNameExistsForAreaTypeAlready]: 'Area must have a unique name.',
        [MapConfigValidationErrors.nameTooLong]: 'Max length exceeded',
        [MapConfigValidationErrors.invalidAreaName]: alphanumericValidationMessage,
        // Geometry
        // area
        [MapConfigValidationErrors.areaSelfIntersects]: `Area can't intersect itself.`,
        [MapConfigValidationErrors.intersectsOtherAreas]: `Areas can't overlap with other areas.`,
        [MapConfigValidationErrors.intersectsBoundary]: `Areas can't fall outside of school boundary.`,
        [MapConfigValidationErrors.areaVerticesOnEdges]: `Area vertices cannot lie on edges.`,

        //TODO Decide if we ever show the user this error message
        [MapConfigValidationErrors.atLeastOneAreaMustExist]: `You must create at least one area to proceed.`,
        // boundary
        [MapConfigValidationErrors.boundaryDoesntIncludeAllAreas]: `School boundary can't intersect areas`,
        [MapConfigValidationErrors.boundarySelfIntersects]: `School boundary can't intersect with itself`,
        [MapConfigValidationErrors.boundaryMustExist]: `You must define a boundary to proceed`
        // todo - min and max size
    }
}