import { ExtendedAction } from '@action/extended-ngrx-action'
import { createAction, props } from '@ngrx/store'

export enum RedirectAngularRouterAction {
  Redirect = '[RedirectAngularRouterAction] Redirect'
}

export const redirectAngularRouter = createAction(
  RedirectAngularRouterAction.Redirect,
  props<ExtendedAction<string>>()
)