import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import { ILocationVisibilityViewModel } from '@view/map/locations/map-control-ui.view'
import { ArcGisMapService } from '../arcgis-map.service'

export namespace ArcGisVisibilityHandler {
  /** Here to handle visbility by passed boolean for layer, correct order of inputs required, location, recent messages, message location, poll location */
  export const toggleVisibilityForUserLayers = (
    locationLayer: GraphicsLayer | undefined,
    recentMessageIndicatorLayer: GraphicsLayer | undefined,
    messageLocationLayer: GraphicsLayer | undefined,
    pollLocationLayer: GraphicsLayer | undefined,
    userTypeToggleVisibility: boolean,
    zoomVisibility: boolean
  ) => {
    if (locationLayer) {
      locationLayer.visible = userTypeToggleVisibility
    }
    // Zoom visibility only applies to recent message indicator
    if (recentMessageIndicatorLayer) {
      recentMessageIndicatorLayer.visible = userTypeToggleVisibility && zoomVisibility
    }
    //This layer isn't even shown at this time
    if (messageLocationLayer) {
      messageLocationLayer.visible = userTypeToggleVisibility
    }
    //This layer has hidden graphics by default and specific grpahics are shown only when we click on the related poll
    if (pollLocationLayer) {
      pollLocationLayer.visible = userTypeToggleVisibility
    }
  }
  /** Uses visbility vm to show and hide layers by user type with optiona
   * @param showRecentMessageIndicator for when locations should be toggled visible but recent messages should be hidden
   */
  export const toggleVisibilityUserLayersByVisibilityVm = (
    context: ArcGisMapService,
    visibilityVm: ILocationVisibilityViewModel
  ) => {
    const zoomVisibility = context._mapView.zoom > context._appConfig.config.ZOOM_LEVEL_VISIBILITY
    context.userTypeLayerVisibilityVm = visibilityVm
    //Student
    ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
      context._studentLocationLayer,
      context._studentRecentMessageLayer,
      context._studentMessageLocationLayer,
      context._studentPollResponsesLayer,
      visibilityVm.showStudents,
      zoomVisibility
    )
    //Teacher
    ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
      context._teacherLocationLayer,
      context._teacherRecentMessageLayer,
      context._teacherMessageLocationLayer,
      context._teacherPollResponsesLayer,
      visibilityVm.showTeachers,
      zoomVisibility
    )
    //Other staff
    ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
      context._otherStaffLocationLayer,
      context._otherStaffRecentMessageLayer,
      context._otherStaffMessageLocationLayer,
      context._otherStaffPollResponsesLayer,
      visibilityVm.showOtherStaff,
      zoomVisibility
    )
    ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
      context._guestMessageLocationLayer,
      context._guestRecentMessageLayer,
      context._guestMessageLocationLayer,
      context._guestPollResponsesLayer,
      visibilityVm.showGuests,
      zoomVisibility
    )
  }
}
