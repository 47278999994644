import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  handleUploadRosterBrowserError,
  validateRoster
} from '@action/roster-page/roster-page.actions'
import { setFileUploadData } from '@action/school-map-config-page.actions'
import { ElementRef, Injectable } from '@angular/core'
import { UploadRosterUserDto, UserDtoProps } from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { FileViewModel, UploadedImageProps } from '@view/browser/file.view'
import {
  RosterFileErrorViewModel,
  RosterFileErrorsProps,
  fileTypesToUpload,
  getMissedUploadedFileColumnNames,
  rosterFileSample
} from '@view/file/roster-file.view'
import * as XLSX from 'xlsx'

@Injectable()
export class UploadFileService {
  file: File | null = null

  fileTypeError = false
  reader = new FileReader()

  constructor(private store: Store) {}

  cleanUp = () => {
    this.file = null
    this.fileTypeError = false
    this.reader = new FileReader()
  }

  /** Clean up local refs and clear existing client side errors.  */
  cleanUpRosterUpload = () => {
    this.cleanUp()
    this.store.dispatch(handleUploadRosterBrowserError(getExtendedAction(null)))
  }

  onUploadClick = (fileUpload: ElementRef<any> | null) => {
    if (!fileUpload) {
      console.warn('fileUpload is not defined')
      return
    }
    // This is necessary to prep the file input for the next upload
    fileUpload.nativeElement.value = ''
    this.cleanUpRosterUpload()
    fileUpload.nativeElement.click()
  }

  onFileSelected = (event: any) => {
    this.file = event.target.files[0]
    this.uploadRoster()
  }

  downloadRosterSample = () => {
    this.cleanUpRosterUpload()
    try {
      const ws = XLSX.utils.json_to_sheet(rosterFileSample)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'OWL_Roster_Sample.csv', { bookType: 'csv' })
    } catch (e) {
      //TODO: handle error from catch
      this.dispatchUploadByType(RosterFileErrorsProps.devErrorSampleFileBorken)
    }
  }

  downloadGenericCsv = (data:any[], fileName: string) => {
    //If no items to download, return
    if (data.length === 0) return
    try {
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `${fileName}.csv`, { bookType: 'csv' })
    } catch (e) {
      console.error('Error downloading generic csv', e)
    }
  }

  hasUploadedRosterFileValidType = (): boolean => {
    if (!this.file?.name) {
      this.dispatchUploadByType(RosterFileErrorsProps.fileNameMissing)
      return false
    }
    let fileType = this.file?.name.substring(this.file?.name.lastIndexOf('.') + 1)?.toLowerCase()
    if (!fileType) {
      this.dispatchUploadByType(RosterFileErrorsProps.unknownFileType)
      return false
    }
    if (fileTypesToUpload.lastIndexOf(fileType) < 0) {
      const message = `File type is ${fileType.toUpperCase()}. Must be either CSV or XLS.`
      this.dispatchUploadByType(RosterFileErrorsProps.invalidFileType, message)
      return false
    }
    return true
  }

  hasParsedRosterFileValidColumns = (columns: string[]): boolean => {
    if (columns.length === 0) {
      this.dispatchUploadByType(RosterFileErrorsProps.noColumnsInFile)
      return false
    }
    const missedColumns = getMissedUploadedFileColumnNames(columns)
    if (missedColumns) {
      this.dispatchUploadByType(RosterFileErrorsProps.invalidColumns, missedColumns.join(', '))
      return false
    }
    return true
  }

  handleOnLoad = (): void => {
    var workbook = XLSX.read(this.reader.result, { type: 'binary' })
    var first_sheet_name = workbook.SheetNames[0]
    var worksheet = workbook.Sheets[first_sheet_name]

    var headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 }).shift() as string[]
    if (!this.hasParsedRosterFileValidColumns(headers)) return

    var roster = XLSX.utils.sheet_to_json(worksheet, { raw: true }) as UploadRosterUserDto[]
    if (roster.length === 0) {
      this.dispatchUploadByType(RosterFileErrorsProps.emptyFile)
      return
    }

    this.store.dispatch(
      validateRoster(
        getExtendedAction(
          roster.map((item) => {
            item[UserDtoProps.logicalId] = crypto.randomUUID()
            item[UserDtoProps.phone] = item[UserDtoProps.phone]?.toString()
            return item
          })
        )
      )
    )
  }

  uploadRoster = () => {
    if (this.file && this.hasUploadedRosterFileValidType()) {
      try {
        this.reader.onload = this.handleOnLoad
        this.reader.readAsArrayBuffer(this.file)
      } catch (error) {
        this.dispatchUploadByType(RosterFileErrorsProps.fileTypeError)
      }
    }
  }

  /**
   * Dispatches an error to the store based on the error type, and an optional message.
   */
  dispatchUploadByType = (errorType: RosterFileErrorsProps, message?: string) => {
    const payload = RosterFileErrorViewModel.getPayloadFromEnum(errorType, message)
    this.store.dispatch(handleUploadRosterBrowserError(getExtendedAction(payload)))
  }

  getAspectRatioOfUploadedFile = (blobUrl: string): Promise<UploadedImageProps> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = blobUrl

      img.onload = (): void => {
        resolve({
          aspectRatio: img.naturalWidth / img.naturalHeight,
          width: img.naturalWidth,
          height: img.naturalHeight
        })
      }
      img.onerror = (event: any) => {
        reject(event)
      }
    })
  }

  /**
   * Generate a blob url to send to save in model from a passed file input event with valid target
   */
  handleFileInput = (files: FileList) => {
    //Reset any previous errors
    this.fileTypeError = false
    let file = null
    let fileName = ''

    let blobUrl = null
    if (files) {
      file = files[0]
      fileName = file.name
    }

    if (file) {
      //Process the file if valid extension
      this.file = file
      blobUrl = URL.createObjectURL(file)
      // this.reader.onload = () => {
      //   this.model.file = this.reader.result
      //   console.log(`Set up binary ref to uploaded file`)
      // }

      // //Set up binary ref
      // this.reader.readAsBinaryString(file)
    }
    this.store.dispatch(
      setFileUploadData(
        getExtendedAction({
          filename: fileName ?? 'no-filename',
          href: blobUrl ?? ''
        })
      )
    )
  }
  handleDownload = (vm: FileViewModel) => {
    const a = document.createElement('a')
    a.href = vm.href
    a.download = vm.filename
    a.click()
  }
  handleRemove = (url: string) => {
    URL.revokeObjectURL(url)
    this.cleanUp()
  }
}
