import { GetSchoolDto, SchoolDto } from '@model/school/school.model'
import { AreaType, SchoolAreaType } from '@model/school/sub-area.model'
import { mockSchoolOwlState } from './school-configuration.mock'
import { SchoolAreaDto, SchoolAreaDtoProps } from '@model/school/school-subarea.model'
import { SchoolConfiguationDto } from '@model/school/school-configuation.model'
import { BaseLayerToggleOptions } from '@view/map/base-layer/base-layer-toggle.view'

export const mockUnknownSchoolIntId = 0
export const mockMadeUpLogicalId = 'e2581f02-621d-4685-9a5c-86eed872c450'
export const mockModeUpResponseId = 99
export const mockUnknownLogicalId = '00000000-0000-0000-0000-000000000000'
export const mockEntireSchoolLogicalId = '00000000-0000-0000-0000-000000000001'
export const mockUnknownSchoolId = {
  id: mockUnknownSchoolIntId,
  logicalId: mockUnknownLogicalId
}
export const mockSchoolConfig: SchoolConfiguationDto = {
  alertProximityRule: 0,
  timeZone: -6,
  zoomLevel: 16,
  mapViewRotation: 90,
  schoolBaseMapType: BaseLayerToggleOptions.light,
  proximityDistanceForAttackerAreaInFeet: 0,
  schoolProximityBoundary: 0
}
export const mockSchoolDto: GetSchoolDto = {
  id: 7,
  logicalId: '1',
  name: 'Mock School',
  latLong: {
    lat: -9789984.289543908,
    lon: 5190955.263449362,
    altitude: 0
  },

  //Address
  street: '1234 Mock Street',
  state: 'MO',
  zip: '12345',
  city: 'Mock City',

  boundary: [[1, 2]],

  subareas: [
    {
      id: 1,
      schoolId: 1, //TODO create different contract for internal use and omit redundent school id
      name: 'Mock Subarea',
      type: AreaType.building,
      areaType: null,
      boundary: [[1, 2]]
    }
  ],
  ncesSchoolId: '',
  stateSchoolId: '',
  schoolOwlState: mockSchoolOwlState,
  schoolConfiguation: mockSchoolConfig
}

export const mockSchoolAreaDto: SchoolAreaDto = {
  [SchoolAreaDtoProps.id]: 1,
  [SchoolAreaDtoProps.logicalId]: mockMadeUpLogicalId,
  [SchoolAreaDtoProps.schoolId]: 1,
  [SchoolAreaDtoProps.schoolLogicalId]: mockMadeUpLogicalId,
  [SchoolAreaDtoProps.name]: 'Mock School Area',
  [SchoolAreaDtoProps.areaType]: SchoolAreaType.subarea,
  [SchoolAreaDtoProps.type]: AreaType.building,
  [SchoolAreaDtoProps.boundary]: [
    [42.19714981325333, -87.94800569999997],
    [42.1978788222402, -87.94797699999997],
    [42.204393598592226, -87.94773289999998],
    [42.20431635655517, -87.94295799999998],
    [42.19896300483647, -87.94307789999999],
    [42.1989650007544, -87.9433926],
    [42.19897947115934, -87.94564109999996],
    [42.19770896963146, -87.94566979999999],
    [42.19770547677523, -87.94636390000001],
    [42.19722066833548, -87.94640699999998],
    [42.19710739999999, -87.94658889999997],
    [42.197149913049216, -87.94771039999998],
    [42.19714981325333, -87.94800569999997]
  ]
}

// export const mockPalmsMiddleSchoolDto: SchoolDto = {
//     id: 'mock',
//     name: 'Palms Middle School',
//     latLon: {
//         lat: -9789984.289543908,
//         lon: 5190955.263449362,
//     },
// }

/** Temp school dto with two large areas and one tiny one for a classroom */
export const mockStevensonHighSchoolDto: SchoolDto = {
  'id': 2,
  'name': 'Adlai E Stevenson High School',
  'street': '1 Stevenson Dr',
  'state': 'IL',
  'city': 'Lincolnshire',
  'latLong': {
    'lat': 42.1996750000001,
    'lon': -87.945054,
    'altitude': 0
  },
  'boundary': [
    [42.19714981325333, -87.94800569999997],
    [42.1978788222402, -87.94797699999997],
    [42.204393598592226, -87.94773289999998],
    [42.20431635655517, -87.94295799999998],
    [42.19896300483647, -87.94307789999999],
    [42.1989650007544, -87.9433926],
    [42.19897947115934, -87.94564109999996],
    [42.19770896963146, -87.94566979999999],
    [42.19770547677523, -87.94636390000001],
    [42.19722066833548, -87.94640699999998],
    [42.19710739999999, -87.94658889999997],
    [42.197149913049216, -87.94771039999998],
    [42.19714981325333, -87.94800569999997]
  ],
  'subareaMapBlobUrls': [],
  'subareas': [
    {
      'id': 1002,
      'schoolId': 2,
      'name': 'Room',
      'type': AreaType.classroom,
      'areaType': null,
      'boundary': [
        [42.19728800796986, -87.94779032526401],
        [42.19748258235241, -87.94779032526401],
        [42.19747399820109, -87.94729980287931],
        [42.19754553275966, -87.94729594049835],
        [42.19756270104167, -87.94783667383575],
        [42.19991471158565, -87.94781736193084],
        [42.199920434132274, -87.94751223383331],
        [42.199843179709006, -87.94751223383331],
        [42.199840318432265, -87.94723800478361],
        [42.19944546099819, -87.94725345430756],
        [42.19944546099819, -87.94714530764006],
        [42.19953129978071, -87.94714530764006],
        [42.19952843848983, -87.94709123430633],
        [42.19947121264519, -87.94708350954437],
        [42.199465490057875, -87.94689039049528],
        [42.199465490057875, -87.94672817049405],
        [42.199262337872426, -87.94673589525603],
        [42.199262337872426, -87.94680541811368],
        [42.19911641125165, -87.94679383097073],
        [42.199119272561184, -87.9468865281143],
        [42.19880166641249, -87.94688266573333],
        [42.19875588521438, -87.94674748239895],
        [42.19846975197473, -87.94675520716093],
        [42.19842110919513, -87.94667795954129],
        [42.19738243384797, -87.94668954668424],
        [42.19729659214643, -87.94672817049405],
        [42.19728514657741, -87.94685176668547],
        [42.19728800796986, -87.94779032526401]
      ]
    },
    {
      'id': 1003,
      'schoolId': 2,
      'name': 'Room',
      'type': AreaType.classroom,
      'areaType': null,
      'boundary': [
        [42.199628265672565, -87.94341227350611],
        [42.19961395923887, -87.94347407160181],
        [42.19961682052586, -87.9435783558883],
        [42.19967690752293, -87.94358221826928],
        [42.19966832366969, -87.94371353922267],
        [42.199736994462846, -87.94371353922267],
        [42.199731271899594, -87.94377533731837],
        [42.19965687853025, -87.94377533731837],
        [42.19965401724507, -87.94448601541897],
        [42.199579623784764, -87.94448601541897],
        [42.199545288312, -87.94416157541652],
        [42.199485201189795, -87.94416157541652],
        [42.19948806248263, -87.94421564875026],
        [42.19928491036975, -87.94431220827481],
        [42.19927918776557, -87.94445125399014],
        [42.19926488125282, -87.94447056589503],
        [42.199267742555634, -87.94450146494289],
        [42.19928491036975, -87.94450532732387],
        [42.19940508493796, -87.94441263018032],
        [42.199407946234416, -87.94451691446683],
        [42.19930207817921, -87.94452463922879],
        [42.19930780078131, -87.94476796923061],
        [42.1991132320187, -87.9447756939926],
        [42.19911609332838, -87.94517738161468],
        [42.1991904873344, -87.94517738161468],
        [42.19919334864057, -87.94523145494841],
        [42.19931638468351, -87.94522759256743],
        [42.19951667540379, -87.9453782254257],
        [42.19965115595975, -87.94538981256865],
        [42.19977132983143, -87.94538981256865],
        [42.20040653078503, -87.94545161066436],
        [42.20122770453637, -87.94545161066436],
        [42.20131067968762, -87.94534732637786],
        [42.20127920636721, -87.94437014398952],
        [42.200397947030936, -87.94437400637052],
        [42.20036647325589, -87.94420792398832],
        [42.20036647325589, -87.94411136446378],
        [42.20032355444648, -87.9441075020828],
        [42.20032069319147, -87.94372898874661],
        [42.200283496864714, -87.94372898874661],
        [42.2002863581214, -87.94358608065029],
        [42.200329276956076, -87.94358221826931],
        [42.20032355444648, -87.94338523683925],
        [42.200206242885336, -87.94334275064844],
        [42.19974271702557, -87.94335433779139],
        [42.19972268805376, -87.94339296160122],
        [42.199628265672565, -87.94341227350611]
      ]
    }
  ],
  'stateSchoolId': '173258003385'
  // "schoolConfiguation": {},
}
