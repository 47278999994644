import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthRouteGuard } from '@domain/guards/auth-route-guard'
import { PublicRouteGuard } from '@domain/guards/public-route-guard'
import { SchoolAreasMapGuard } from '@domain/guards/school-areas-map-selection.guard'
import { SchoolDashboardGuard } from '@domain/guards/school-dashboard.guard'
import { SchoolValidationSelectionGuard } from '@domain/guards/select-school.guard'
import { FirstLevelDir, SecondLevelDir } from '@domain/route/app-routes.domain'
import { OwlModulePreloadingStrategy } from '@view/angular.view'
const routes: Routes = [
  //PUBLIC
  {
    path: `${FirstLevelDir.public}`,
    loadChildren: () =>
      import('./page/public/welcome-page/welcome-page.module').then((m) => m.WelcomePageModule),
    canActivate: [PublicRouteGuard]
  },
  //AUTHENTICATED USERS
  {
    path: `${FirstLevelDir.auth}`,
    loadChildren: () => import('./page/auth/auth-page.module').then((m) => m.AuthPageModule)
  },
  {
    path: `${FirstLevelDir.school}/${SecondLevelDir.validation}`,
    loadChildren: () =>
      import('./page/school/validation/school-validation.module').then(
        (m) => m.SchoolValidationPageModule
      ),
    canActivate: [AuthRouteGuard, SchoolValidationSelectionGuard]
  },
  {
    path: `${FirstLevelDir.setupSchoolAreasMap}`,
    loadChildren: () =>
      import('./page/school/map-config/map-config-page.module').then(
        (m) => m.SchoolMapConfigPageModule
      ),
    canActivate: [AuthRouteGuard, SchoolAreasMapGuard]
  },
  {
    path: `${FirstLevelDir.dashboard}`,
    loadChildren: () =>
      import('./page/dashboard/dashboard-page.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthRouteGuard, SchoolDashboardGuard]
  },
  {
    path: `${FirstLevelDir.roster}`,
    loadChildren: () => import('./page/roster/roster-page.module').then((m) => m.RosterPageModule),
    canActivate: [AuthRouteGuard]
  },
  {
    path: `${FirstLevelDir.events}`,
    loadChildren: () => import('./page/events/events-page.module').then((m) => m.EventsPageModule),
    canActivate: [AuthRouteGuard]
  },
  {
    path: `${FirstLevelDir.instructions}`,
    loadChildren: () =>
      import('./page/instructions/instructions-page.module').then((m) => m.InstructionsPageModule),
    canActivate: [AuthRouteGuard]
  },
  { path: '', redirectTo: `${FirstLevelDir.auth}`, pathMatch: 'full' },
  { path: '**', redirectTo: `${FirstLevelDir.auth}`, pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: OwlModulePreloadingStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
