import { DtoValidationFailed } from '@model/server-validation.model'
import { UserServerValidationModel } from '@model/user/server-validation.model'
import { PatchUserDtoType, PostUserDtoType } from '@model/user/user.model'
import { RosterPageState } from '@state/page/roster-page.state'

export class RosterUserValidationViewModel {
  /** Handles the logic of parsing a server sent error res with the dto that triggered it to handle server validation related state changes. */
  static getValidationState = (
    s: RosterPageState,
    dtoValidation: DtoValidationFailed<PostUserDtoType | PatchUserDtoType>
  ): Partial<RosterPageState> => {
    const { dto, res } = dtoValidation
    const userEmail = dto.email ?? ''
    const userPhone = dto.phone ?? ''
    const hasEmailError = UserServerValidationModel.errorContainsEmail(res)
    const hasPhoneError = UserServerValidationModel.errorContainsPhone(res)
    const errorDetailDisplayString = res?.error?.detail ?? 'Error creating user.'
    let copyOfServerValidaitons = { ...s.serverValidations }
    if (hasPhoneError) {
      copyOfServerValidaitons[UserServerValidationModel.getPhoneKey(userPhone)] =
        errorDetailDisplayString
    } else if (hasEmailError) {
      copyOfServerValidaitons[UserServerValidationModel.getEmailKey(userEmail)] =
        errorDetailDisplayString
    }
    return {
      serverValidations: copyOfServerValidaitons,
      createUpdateUserErrorText: errorDetailDisplayString
    }
  }
}
