export class GlobalAppViewModel {
  queueCount: number = 0
  timestampStartOfOnlyToast: Date | null = null

  profileOpened: boolean = false
}
/** TODO While this works there may be some issues with Angular Material UI alignment, if issue is found, rework this approach. */
export function disableScrollingGlobally(disable: boolean): void {
  disable
    ? (document.getElementsByTagName('html')[0].className = 'globally-disable-scroll')
    : (document.getElementsByTagName('html')[0].className = '')
}
