import {
  BaseLayerToggleOptions,
  BaseMapToggleItemViewModel,
  baseLayerToggleVms
} from '@view/map/base-layer/base-layer-toggle.view'
import { MapPageType } from '../../map.component.service.model'

export enum BaseMapOptions {
  Satalite = 'satellite',
  Hybrid = 'hybrid',
  Terrain = 'terrain',
  Oceans = 'oceans',
  Osm = 'osm',
  DarkGrayVector = 'dark-gray-vector',
  GrayVector = 'gray-vector',
  StreetsVector = 'streets-vector',
  TopoVector = 'topo-vector',
  StreetsNightVector = 'streets-night-vector',
  StreetsReliefVector = 'streets-relief-vector',
  StreetsNavigationVector = 'streets-navigation-vector'
}

export class ArcGisBaselayerStyles {
  /** Database will save a unified option that will have different base layers for different map api services, for now defaulting to arcgis. */
  static getBaselayerByMapPageType(
    mapPageType: MapPageType,
    baseMap: BaseLayerToggleOptions | null
  ): BaseMapToggleItemViewModel {
    let vm: BaseMapToggleItemViewModel | undefined
    if (baseMap) {
      vm = baseLayerToggleVms.find((vm) => vm.option === baseMap)
    }
    if (vm) {
      return vm
    }
    const defaultLightBaseLayer = baseLayerToggleVms[0]
    switch (mapPageType) {
      case MapPageType.schoolMapConfig:
        return defaultLightBaseLayer
      case MapPageType.schoolDashboard:
        return defaultLightBaseLayer
      default:
        return defaultLightBaseLayer
    }
  }
}
