import { ChatResponseDtoPayload } from '@model/message.model'

export enum UserTypes {
  /** This isn't used right now TODO Clarify if we need a web type like this.*/
  anon = 'anon',
  /** This isn't used right now TODO Clarify if we need a web type like this. */
  registered = 'registered',
  /** NOTE: you can't create school owner admins, only created in the system during school validation or transfer of ownership by existing owner in a specifically dedicated UI. */
  schoolOwner = 'schoolOwner',
  /** These are school admins, and SERT users */
  schoolAdmin = 'schoolAdmin',
  /** These types of users will be OWL admins and can access any school. */
  globalAdmin = 'globalAdmin'
}
export enum MobileUserTypes {
  student = 'student',
  teacher = 'teacher',
  otherStaff = 'otherStaff',
  /** TODO Implement in back end once we have a plan for onboarding guest users into mobile app */
  guest = 'guest',
  unknown = 'unknown',
  /** Maybe we're using this one */
  youngStudent = 'youngStudent'
}
/** For the sake of current implementations this is a subset of mobile types but will need to be extended to support dynamic types when admins are allowed to create types dynamically. */
export type MobileUserShownOnMap =
  | MobileUserTypes.student
  | MobileUserTypes.teacher
  | MobileUserTypes.otherStaff

export type MobileUserTypesForPollInteractions =
  | MobileUserTypes.student
  | MobileUserTypes.teacher
  | MobileUserTypes.otherStaff
  | MobileUserTypes.guest

/** Issue currently is that we don't have a symbol for a guest on the map, nor a strategy for what a guest is. For now use this typescript assertion function to avoid issue. */
export const mobileUserAllowedOnMap = (value: string): value is MobileUserShownOnMap => {
  switch (value) {
    case MobileUserTypes.student:
    case MobileUserTypes.teacher:
    case MobileUserTypes.otherStaff:
      return true
    default:
      return false
  }
}

export const mobileUserTypesDisplayText: Record<MobileUserTypes, string> = {
  [MobileUserTypes.student]: 'Student',
  [MobileUserTypes.teacher]: 'Teacher',
  [MobileUserTypes.otherStaff]: 'Other Staff',
  [MobileUserTypes.guest]: 'Guest',
  [MobileUserTypes.unknown]: 'Unknown',
  [MobileUserTypes.youngStudent]: 'Young Student'
}
/** Used in user type select component */
export const mobileUserTypesSelectDisplayText: Record<MobileUserTypes, string> = {
  [MobileUserTypes.student]: 'Students',
  [MobileUserTypes.teacher]: 'Teachers',
  [MobileUserTypes.otherStaff]: 'Other Staff',
  [MobileUserTypes.guest]: '',
  [MobileUserTypes.unknown]: '',
  [MobileUserTypes.youngStudent]: ''
}
export enum UserDtoProps {
  id = 'id',
  logicalId = 'logicalId',
  type = 'type',
  email = 'email',
  phone = 'phone',
  oid = 'oid',
  mobileId = 'mobileId',
  schoolIds = 'schoolIds',
  mobileType = 'mobileType',
  role = 'role',
  active = 'active',
  status = 'status',
  firstName = 'firstName',
  lastName = 'lastName',
  message = 'message'
}
export const UserPropsWithValidationTuple = [
  UserDtoProps.firstName,
  UserDtoProps.lastName,
  UserDtoProps.email,
  UserDtoProps.phone
]
export enum UserDtoStatus {
  added = 'added',
  invited = 'invited',
  registered = 'registered', // mobile only
  active = 'active',
  deactivated = 'deactivated',
  invitationFailed = 'invitationFailed'
}
export enum UserValidationStatus {
  added = 'added',
  updated = 'updated',
  removed = 'removed',
  unchanged = 'unchanged',
  validationFailed = 'validationFailed'
}
export type UserRosterStatus = UserDtoStatus | UserValidationStatus

export const StatusOrderForSorting: UserRosterStatus[] = [
  UserDtoStatus.invitationFailed,
  UserValidationStatus.validationFailed,
  UserDtoStatus.invited,
  UserDtoStatus.registered,
  UserValidationStatus.updated,
  UserDtoStatus.active,
  UserDtoStatus.added,
  UserValidationStatus.added,
  UserDtoStatus.deactivated,
  UserValidationStatus.removed,
  UserValidationStatus.unchanged
]

export const RosterStatusDisplayString: Record<UserRosterStatus, string> = {
  // [UserValidationStatus.added]: 'Added',
  [UserDtoStatus.added]: 'Added',
  [UserDtoStatus.invited]: 'Invited',
  [UserDtoStatus.registered]: 'Registered',
  [UserDtoStatus.active]: 'Active',
  [UserDtoStatus.deactivated]: 'Deactivated',
  [UserDtoStatus.invitationFailed]: 'Failed',
  [UserValidationStatus.updated]: 'Updated',
  [UserValidationStatus.removed]: 'Removed',
  [UserValidationStatus.unchanged]: 'Unchanged',
  [UserValidationStatus.validationFailed]: 'Failed'
}

export interface SchoolIds {
  id: number
  logicalId: string
}
export interface UserDto {
  [UserDtoProps.id]?: number
  [UserDtoProps.logicalId]?: string

  /** We really require this field but if it's undefined we'll assume that the user is anonymous.  */
  [UserDtoProps.type]?: UserTypes

  /** We need either email or phone or both in the case of a school admin roster upload */
  [UserDtoProps.email]?: string

  /** We need either email or phone or both in the case of a school admin roster upload */
  [UserDtoProps.phone]?: string

  /** In Azure B2C OID - GUID of unique id in directory, this is string | undefined because a roster upload will create the user but when the user actually signs in to OWL we can extract their oid from the access token. */
  [UserDtoProps.oid]?: string

  /** If a user registers in the OWL mobile app, OWL will have this mobile id defined. */
  [UserDtoProps.mobileId]?: string

  /** Required field to associate a user to many school */
  [UserDtoProps.schoolIds]: SchoolIds[]

  /** Whenever we invite a mobile app user we must know who they */
  [UserDtoProps.mobileType]?: MobileUserTypes

  /** TODO For now we don't have roles we just have user types.
   * One user one dash type and one mobile type. If we want to add roles we'll need to add a new field to the user table in the database.
   */
  // [UserDtoProps.role]?: UserRoleDto

  [UserDtoProps.firstName]?: string
  [UserDtoProps.lastName]?: string
  [UserDtoProps.message]?: ChatResponseDtoPayload
  [UserDtoProps.status]?: UserDtoStatus
}
export type GetUserDto = UserDto & {
  [UserDtoProps.id]: number
}
export type PostUserDto = UserDto & {
  [UserDtoProps.id]: undefined
}
export type PatchUserDto = UserDto & {
  [UserDtoProps.id]: number
}
export type PostWebUserDto = PostUserDto & {
  [UserDtoProps.type]: UserTypes
  [UserDtoProps.mobileType]: undefined
}
export type PostMobileUserDto = PostUserDto & {
  [UserDtoProps.type]: undefined
  [UserDtoProps.mobileType]: MobileUserTypes
}
export type PatchWebUserDto = PatchUserDto & {
  [UserDtoProps.type]: UserTypes
  [UserDtoProps.mobileType]: undefined
}
export type PatchMobileUserDto = PatchUserDto & {
  [UserDtoProps.type]: undefined
  [UserDtoProps.mobileType]: MobileUserTypes
}
export type PostUserDtoType = PostWebUserDto | PostMobileUserDto
export type PatchUserDtoType = PatchWebUserDto | PatchMobileUserDto
// export type PatchUserType = PostWebUserDto | PostMobileUserDto
/** For school admin roster uploads we know that we'll require a mobile phone number as well as first/last */
export interface MobileUserDto extends UserDto {
  [UserDtoProps.mobileId]: string
  [UserDtoProps.firstName]: string
  [UserDtoProps.lastName]: string
}

export interface PostBalconyUserDto {
  [UserDtoProps.id]: number
  [UserDtoProps.type]: MobileUserTypes
  [UserDtoProps.phone]: string
  [UserDtoProps.schoolIds]: string[]
}
export interface SuccessBalconyOnboardingDto {
  [UserDtoProps.id]: number
  schoolId: string
}
export enum RosterUserVmProps {
  getUserDto = 'getUserDto',
  rosterValidationDto = 'rosterValidationDto',
  userInvitationHistoryDto = 'userInvitationHistoryDto',
  /** This is a background lookup by column */
  backgroundStyleByColumnEnum = 'backgroundStyleByColumnEnum',
  id = 'id',
  logicalId = 'logicalId',
  name = 'name',
  email = 'email',
  phone = 'phone',
  role = 'role',
  status = 'status',
  statusForSorting = 'statusForSorting',
  isCurrentUser = 'isCurrentUser'
}

export type UploadRosterUserDto = {
  [UserDtoProps.logicalId]?: string
  [UserDtoProps.firstName]?: string
  [UserDtoProps.lastName]?: string
  [UserDtoProps.phone]?: string
  [UserDtoProps.email]?: string
}

export type ProfileUserVm = {
  isOpened: boolean
  initials: string
  userFullName: string
  userEmail?: string
  schoolName?: string
  canDownloadSignUps: boolean
}
