import { redirectAngularRouter } from '@action/redirect.action'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { routerErrorAction } from '@ngrx/router-store'
import { RouterService } from '@service/core/router.service'
import { tap } from 'rxjs'

@Injectable()
export class RouteEffects {
  constructor(private actions$: Actions, private service: RouterService) {}

  angularRouterRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectAngularRouter),
        tap((action) => this.service.navigate(action.payload))
      ),
    { dispatch: false }
  )
  logRouterError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerErrorAction),
        tap((action) => {
          console.error('Router navigation error:', action.payload)
        })
      ),
    { dispatch: false }
  )
}
