import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { DeleteAreaSideEffectPayload } from '@domain/dto-helpers/area-model.helper'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { CoordinateCollection } from '@model/location.model'
import {
  GetSchoolAreaDto,
  PatchSchoolAreaDto,
  SchoolAreaDto
} from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { ISchoolAreaPatchProps } from '@model/school/sub-area.model'
import { OrgStatsModel } from '@model/user-organization/user-org-stats.model'
import { GetUserDto } from '@model/user/user.model'
import { PolygonTypeEnum } from '@service/map/map.component.service.model'
import { ApiState } from '@state/api.state'
import { GlobalState } from '@state/auth.state'
import { FileViewModel, UploadedImageProps } from '@view/browser/file.view'
import { BaseMapToggleItemViewModel } from '@view/map/base-layer/base-layer-toggle.view'
import { MapApiEnum, MapModesEnum } from '@view/map/map-enums.view'
import { MapViewModel } from '@view/map/map.view'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import {
  ISchoolMapConfigStepViewModel,
  MapConfigSteps,
  SchoolMapConfigStepViewModel
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { MapConfigValidationViewModel } from '@view/pages/school-map-config-page/school-map-config-validation.view'
export interface MapConfigDeps {
  userDto: GetUserDto | null
  schoolDto: GetSchoolDto | null
  schoolUsers: GetUserDto[] | null
  currentOrgStats: OrgStatsModel
}
export class SchoolMapConfigPageState {
  vm = new MapViewModel()
  //Save map config server error
  patchAreaPendingDto: ISchoolAreaPatchProps | null = null
  serverError: HttpErrorResponse | null = null
  /** If true async save is being debounced, if false it's saved, and if error response we need to indicate network or server error. */
  asyncSavePendingLookup: Record<number, boolean> | HttpErrorResponse = {}
  selectedAreaId: number | null = null

  /** While we're deleting an area we need to block interactions with the "Save Updates" button sometimes the "Complete button"  */
  deleteAreaApiState: ApiState | null = null
  polgyonApiState: ApiState | null = null
  constructor() { }

  static getMapConfigDeps = (
    userDto: GetUserDto | null,
    schoolDto: GetSchoolDto | null,
    schoolUsers: GetUserDto[] | null,
    currentOrgStats: OrgStatsModel
  ): MapConfigDeps => {
    return {
      userDto,
      schoolDto,
      schoolUsers,
      currentOrgStats
    }
  }
  /** Updates the current active step */
  static navigateToStepInMapConfig(s: SchoolMapConfigPageState, a: ExtendedAction<MapConfigSteps>) {
    return {
      ...s,
      vm: {
        ...s.vm,
        currentMapConfigStep: a.payload
      }
    }
  }

  /** Indicate in UI when area step is no longer complete. */
  static deleteSchoolAreaSuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<DeleteAreaSideEffectPayload>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      deleteAreaApiState: ApiState.createHadLoadedState()
    }
  }
  /** Update no boundary error state if polygon received and placed on the map */
  static getPolygonSuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<CoordinateCollection | null>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      polgyonApiState: ApiState.createHadLoadedState(),
    }
  }
  /** Indicate in UI when boundary step is no longer complete. 
   * TODO Wrap in feature flag as we are removing the ability to delete and re-add the boundary
  */
  static deleteSchoolPerimeterBoundarySuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<GetSchoolDto>
  ): SchoolMapConfigPageState => {
    return s
  }
  /** Indicate in UI when pending save is triggered. */
  static updateSubAreaRelatedDataDebounced = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<PatchSchoolAreaDto>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      asyncSavePendingLookup: {
        ...s.asyncSavePendingLookup,
        [a.payload.id]: true
      }
    }
  }
  /** Track the currently selected area id. */
  static selectAreaById = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<number | null>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      selectedAreaId: a.payload,
      // Reset async on new area selected
      asyncSavePendingLookup: {}
    }
  }
  /** Indicate in UI when pending save results in error. */
  static updateSchoolAreaDtoError = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<HttpErrorResponse>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      asyncSavePendingLookup: a.payload
    }
  }
  /** Indicate in UI when pending save is completed. */
  static updateSchoolAreaDtoSuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<GetSchoolAreaDto>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      asyncSavePendingLookup: {
        // Remove any older ones so a new area selected doesn't indicate save until changed
        // ...s.asyncSavePendingLookup,
        [a.payload.id]: false
      }
    }
  }
  /** Indicate that the save was successful and update the steps to indicate that the user completed the configuration*/
  static postSchoolAreaDtoSuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<GetSchoolAreaDto>
  ): SchoolMapConfigPageState => {
    // const newSteps = SchoolMapConfigStepViewModel.getStepsByActiveAndCompleteStep(
    //   MapConfigSteps.addAreas,
    //   {
    //     [MapConfigSteps.mapView]: true,
    //     [MapConfigSteps.schoolPerimeter]: true,
    //     [MapConfigSteps.addAreas]: true,
    //   }
    // )
    return {
      ...s,
      asyncSavePendingLookup: {
        // TODO Can't do this here because as a side effect we update the popup so need ot emit the the saved value
        // [a.payload.id]: false
      },
      vm: {
        ...s.vm,
        // Once we create at least one area we can complete the config
        // mapConfigStepVms: newSteps,
        // mapConfigForwardNavBlocked: false
      }
    }
  }

  /** Every time we interact with the arc gis sketch view model we need to update the enabled and disabled states of our custom tools */
  static handleMapConfigToolbarStateSync = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<Record<MapConfigToolbarOptions, boolean>>
  ): SchoolMapConfigPageState => {
    //If we're on the permiter step we need to enable and disable forward and backward navigation based on the map config validation error
    let state = {
      ...s,
      vm: {
        ...s.vm,
        mapConfigToolbarEnabledState: a.payload
      }
    }
    return state
  }
  static handlePatchSchoolSuccess = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<GetSchoolDto>
  ) => {
    return {
      ...s,
      patchSchoolApi: ApiState.createHadLoadedState()
      /** Anytime we successfully save the school we move to the next step ??*/
      // vm: {
      //   ...s.vm,
      //   currentMapConfigStep: MapConfigSteps.addAreas,
      // }
    }
  }
  static handlePatchSchoolError = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<HttpErrorResponse>
  ) => {
    console.error(`Error on patch school boundary`)
    return {
      ...s,
      vm: {
        ...s.vm,
        mapConfigForwardNavBlocked: true,
        mapConfigBackNavBlocked: true
      },
      serverError: a.payload
    }
  }
  /** Put current name in state for validation purposes as side effects.*/
  static patchAreaPendingDto = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<ISchoolAreaPatchProps>
  ) => ({
    ...s,
    patchAreaPendingDto: a.payload
  })

  /** During the map config process there are a number of validations associated with each step. If any validation error exists block map config step navigation. */
  static setMapConfigErrorReason = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<MapConfigValidationErrors | null>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        mapConfigErrorReason: a.payload,
      }
    }
  }

  /** Sets up map config view model based on school dto. */
  static setUpMapConfigStepVm = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<GetSchoolDto>
  ): SchoolMapConfigPageState => {
    const rotation = a.payload?.[SchoolDtoProps.schoolConfiguation]?.mapViewRotation ?? 0
    let schoolBoundary = a.payload[SchoolDtoProps.boundary] ?? []
    const hasNoPerimeterBoundary = schoolBoundary.length === 0
    return {
      ...s,
      vm: {
        ...s.vm,
        mapConfigErrorReason: SchoolMapConfigStepViewModel.getMapValidationError(a.payload),
        currentMapConfigStep: SchoolMapConfigStepViewModel.getActiveStep(a.payload),
        rotationSliderValue: rotation,
        proximitySliderValue: hasNoPerimeterBoundary
          ? null
          : a.payload[SchoolDtoProps.schoolConfiguation]?.schoolProximityBoundary ?? null,
      }
    }
  }
  /** Once the user uploads an image for tracing on the map, we'll calculate it's height, width, aspectRatio for map service usage.  */
  static handleAspectRatioVm = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<UploadedImageProps>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        aspectRatioVm: a.payload
      }
    }
  }

  /** We'll render the UI differently once the user uploads the image, and we'll need a ref to it so it can be added to the map, once the user draws the shape, or clicks an existing shape. */
  static handleFileUploadData = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<FileViewModel>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        fileVm: a.payload
      }
    }
  }
  static setMapApi = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<MapApiEnum>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        mapApi: a.payload
      }
    }
  }
  static setMapMode = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<MapModesEnum>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        mapMode: a.payload
      }
    }
  }
  static setPolygonTypeEnum = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<PolygonTypeEnum>
  ): SchoolMapConfigPageState => ({
    ...s,
    vm: {
      ...s.vm,
      shapeMode: a.payload
    }
  })
  /** Here to validate that page state has lat lon for map center */
  static getLatLongOrNull = (authState: GlobalState): true | null => {
    const schoolDto = authState.userSchoolLookup[authState.selectedSchoolId.id]
    if (!schoolDto) {
      return null
    }
    const { latLong } = schoolDto
    if (!latLong) {
      return null
    }
    const { lat, lon } = latLong
    if (!lat || !lon) return null
    return true
  }
  static setMapConfigBaseLayer = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<BaseMapToggleItemViewModel>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        baseLayerSelectVm: a.payload
      }
    }
  }
  static setProximitySliderValue = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<number>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        proximitySliderValue: a.payload
      }
    }
  }
  static updateRotationSlider = (
    s: SchoolMapConfigPageState,
    a: ExtendedAction<number>
  ): SchoolMapConfigPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        rotationSliderValue: a.payload
      }
    }
  }
}
