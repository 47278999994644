import { AreaType, SchoolAreaType, SelectableAreaType } from './sub-area.model'

export enum SchoolAreaDtoProps {
  id = 'id',
  logicalId = 'logicalId',
  schoolId = 'schoolId',
  schoolLogicalId = 'schoolLogicalId',
  name = 'name',
  areaType = 'areaType',
  type = 'type',
  boundary = 'boundary'
}
export interface SchoolAreaDto {
  [SchoolAreaDtoProps.id]?: number
  [SchoolAreaDtoProps.logicalId]?: string | null

  [SchoolAreaDtoProps.schoolId]?: number
  [SchoolAreaDtoProps.schoolLogicalId]?: string | null

  /** Room numbers, building names, parking lot name, field/gym name, etc... Add new types as needed but keep the name field generic for now for ease of use and simplicity */
  [SchoolAreaDtoProps.name]?: string
  [SchoolAreaDtoProps.areaType]?: SchoolAreaType | null
  /**
   * Set by the school admin drawing the map  
   * */
  [SchoolAreaDtoProps.type]?: SelectableAreaType

  /** This is a set of lat long tuples as point in an array that comprises a closed polygon boundary.
   * A polygon like their trigger alert endpoitns
   *  POLYGON(([[-122.28534698486327, 37.42049041500457], [-122.24380493164061, 37.38312585371579], [-122.32276916503905, 37.3820346377109], [-122.3529815673828, 37.394309902182506], [-122.28534698486327, 37.42049041500457]]}))
   */
  [SchoolAreaDtoProps.boundary]?: number[][]
}
// export interface ValidSchoolAreaDto {
//   [SchoolAreaDtoProps.id]: number
//   [SchoolAreaDtoProps.logicalId]: string
//   [SchoolAreaDtoProps.type]: SelectableAreaType
//   [SchoolAreaDtoProps.name]: string
//   [SchoolAreaDtoProps.areaType]: SchoolAreaType
//   [SchoolAreaDtoProps.boundary]: number[][]
// }
export interface CompleteSchoolAreaDto extends SchoolAreaDto {
  [SchoolAreaDtoProps.name]: string
  [SchoolAreaDtoProps.logicalId]: string
  [SchoolAreaDtoProps.id]: number
}
export type GetSchoolAreaDto = SchoolAreaDto & {
  [SchoolAreaDtoProps.id]: number
  [SchoolAreaDtoProps.logicalId]: string
}
/** TODO It would be easier if the server generated a logical id when we post otherwise we have to generate guids on the client, which is not ideal in non required scenarios. Additionally, it seems we have to pass in the school logical id and the school id or the area is allowed to be created without those properties. TODO Enforce database constraints on logical ids being non nullable. */
export type PostSchoolAreaDto = SchoolAreaDto & {
  [SchoolAreaDtoProps.schoolId]: number
  [SchoolAreaDtoProps.logicalId]: string
  // [SchoolAreaDtoProps.schoolLogicalId]: string
}
export type PatchSchoolAreaDto = Partial<SchoolAreaDto> & {
  [SchoolAreaDtoProps.schoolId]: number
  [SchoolAreaDtoProps.id]: number
}
export type ValidSchoolAreaDto = SchoolAreaDto & {
  [SchoolAreaDtoProps.schoolId]: number
  [SchoolAreaDtoProps.id]: number
  [SchoolAreaDtoProps.boundary]: number[][]
  [SchoolAreaDtoProps.logicalId]: string
  [SchoolAreaDtoProps.areaType]: SchoolAreaType
  [SchoolAreaDtoProps.type]: AreaType
  [SchoolAreaDtoProps.name]: string
}

export interface BalconyAreaDto extends Exclude<SchoolAreaDto, SchoolAreaDtoProps.type> {}
