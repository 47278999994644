import { Component, Input } from '@angular/core'
import { LabelPointerDirections } from './custom-ui-label.component.model'

export enum LabelSizeEnum {
  medium = 'medium',
  small = 'small'
}
export enum Placement {
  right = 'right',
  left = 'left'
}
export enum BorderRadiusOptions {
  allRounded = 'allRounded',
  leftRounded = 'leftRounded',
  rightRounded = 'rightRounded'
}
@Component({
  selector: 'app-custom-ui-label',
  templateUrl: './custom-ui-label.component.html',
  styleUrls: ['./custom-ui-label.component.scss']
})
export class CustomUiLabelComponent {
  @Input() placement = Placement.right
  @Input() variant = LabelSizeEnum.medium
  @Input() borderRounding: BorderRadiusOptions | null = null
  @Input() displayed = true
  @Input() text: string | null = ''
  @Input() labelClass = ''
  get direction(): LabelPointerDirections {
    switch (this.placement) {
      case Placement.left:
        return LabelPointerDirections.right
      case Placement.right:
        return LabelPointerDirections.left
      default:
        return LabelPointerDirections.left
    }
  }
  /** Either uses override passed as input or infers rounding based on placement. */
  get roundingClass(): BorderRadiusOptions {
    if (this.borderRounding) {
      return this.borderRounding
    }
    switch (this.placement) {
      case Placement.left:
        return BorderRadiusOptions.leftRounded
      case Placement.right:
        return BorderRadiusOptions.rightRounded
      default:
        return BorderRadiusOptions.allRounded
    }
  }
}
