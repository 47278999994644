import { getSchoolById } from '@action/auth.action'
import { ExtendedAction, getExtendedAction } from '@action/extended-ngrx-action'
import {
  deleteSchoolArea,
  // deleteSchoolPerimeterBoundary,
  getSchoolPolygonByLoc,
  patchSchoolArea,
  patchSchoolDto,
  postNewSchoolArea
} from '@action/school-map-config-page.actions'
import {
  deleteSchoolAreaError,
  deleteSchoolAreaSuccess,
  // deleteSchoolPerimeterBoundaryError,
  // deleteSchoolPerimeterBoundarySuccess,
  patchSchoolAreaDtoError,
  patchSchoolAreaDtoSuccess,
  patchSchoolStateError,
  patchSchoolStateSuccess,
  patchSchoolSuccess,
  postSchoolAreaDtoError,
  postSchoolAreaDtoSuccess
} from '@action/school/school-api.action'
import { getPolygonError, getPolygonSuccess } from '@action/school/school-osm-polygon-api.action'
import {
  getSchoolByIdError,
  getSchoolByIdSuccess
} from '@action/school/school-validation-api.action'
import { updateSchoolOwlState, updateSchoolState } from '@action/user/dashboard-page.action'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import {
  AttackerTypeEnum,
  EmergencyResponeEnum,
  SchoolStateTransitionEnum
} from '@model/emergency.model'
import { LatLon } from '@model/location.model'
import { SchoolOwlState, SchoolPreferenceEnum } from '@model/school/school-configuation.model'
import { GetSchoolAreaDto, PatchSchoolAreaDto, PostSchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { selectSchoolById } from '@selector/auth.selector'
import { selectNewSchoolConfiguration } from '@selector/page/school-map-config-page/school-map-config-page-state.selector'
import { SchoolApiService } from '@service/network/school.service'
import { SignalrService } from '@service/network/signalr.service'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
/** Getting school by id and the school polygon is considered part of the auth flow since you need a school dto in order to use the app, just like you need a user object first.
 * When onboarding you need to have the option of using the osm school bounday to potentially save or edit for a school.
 * */
export class SchoolAuthEffects {
  constructor(
    private actions$: Actions,
    private schoolApiService: SchoolApiService,
    private signalrService: SignalrService,
    private store: Store
  ) {}
  getSchoolById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSchoolById),
      mergeMap((a) =>
        this.schoolApiService.getSchoolById(a.payload).pipe(
          map((res: GetSchoolDto) => {
            if (!SchoolDtoHelper.hasBoundary(res)) {
              this.triggerGetSchoolPolygon(res[SchoolDtoProps.latLong])
            }
            return getSchoolByIdSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(getSchoolByIdError(getExtendedAction(e))))
        )
      )
    )
  )
  triggerGetSchoolPolygon = (latLong: LatLon | undefined) => {
    this.store.dispatch(
      getSchoolPolygonByLoc(
        getExtendedAction({
          lat: latLong?.lat ?? 0,
          lon: latLong?.lon ?? 0
        })
      )
    )
  }
  //Now that we have a school dto we know where to route the user
  getSchoolPolygon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSchoolPolygonByLoc),
      mergeMap((a: ExtendedAction<LatLon>) =>
        this.schoolApiService.getSchoolOsmPolygon(a.payload).pipe(
          map((res) => {
            return getPolygonSuccess(getExtendedAction(res))
          }),
          catchError((e: any) => of(getPolygonError(getExtendedAction(e))))
        )
      )
    )
  )
  saveSchoolBoundaryAndProximityPolygon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchSchoolDto),
      concatLatestFrom((_) => this.store.select(selectNewSchoolConfiguration)),
      mergeMap(([a, updatedConfig]) =>
        this.schoolApiService
          .patchSchool({
            ...a.payload,
            schoolConfiguation: {
              ...updatedConfig,
              ...a.payload?.[SchoolDtoProps.schoolConfiguation]
            }
          })
          .pipe(
            map((res: any) => {
              return patchSchoolSuccess(getExtendedAction(res))
            }),
            catchError((e: any) => of(patchSchoolStateError(getExtendedAction(e))))
          )
      )
    )
  )
  // Temporarily comment out delete perimeter boundary since we are not using it - needs to be re implemented in a way that matches the new pattern
  // deleteSchoolPerimeterBoundary$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(deleteSchoolPerimeterBoundary),
  //     concatLatestFrom(() => this.store.select(selectSchoolById)),
  //     mergeMap(([_, schoolDto]) =>
  //       this.schoolApiService
  //         .patchSchool({
  //           id: schoolDto?.id ?? 0,
  //           // TODO Discuss should it be possible to set boundary to null, temp solution use empty array
  //           boundary: []
  //         })
  //         .pipe(
  //           map((res: any) => {
  //             return deleteSchoolPerimeterBoundarySuccess(getExtendedAction(res))
  //           }),
  //           catchError((e: any) => of(deleteSchoolPerimeterBoundaryError(getExtendedAction(e))))
  //         )
  //     )
  //   )
  // )
  deleteSchoolAreaById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSchoolArea),
      concatLatestFrom(() => this.store.select(selectSchoolById)),
      mergeMap(([a, schoolDto]) =>
        this.schoolApiService.deleteSchoolArea(a.payload).pipe(
          map((res: GetSchoolAreaDto) => {
            return deleteSchoolAreaSuccess(
              getExtendedAction({
                areaId: res.id,
                areaLogicalId: res.logicalId,
                existingSchoolDto: schoolDto
              })
            )
          }),
          catchError((e: any) => of(deleteSchoolAreaError(getExtendedAction(e))))
        )
      )
    )
  )
  saveNewSchoolArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postNewSchoolArea),
      mergeMap((a: ExtendedAction<PostSchoolAreaDto>) =>
        this.schoolApiService.saveSchoolArea(a.payload).pipe(
          map((res: any) => {
            return postSchoolAreaDtoSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(postSchoolAreaDtoError(getExtendedAction(e))))
        )
      )
    )
  )
  patchSchoolArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchSchoolArea),
      mergeMap((a: ExtendedAction<PatchSchoolAreaDto>) =>
        this.schoolApiService.updateSchoolArea(a.payload).pipe(
          map((res: GetSchoolAreaDto) => {
            return patchSchoolAreaDtoSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(patchSchoolAreaDtoError(getExtendedAction(e))))
        )
      )
    )
  )
  /** Use the existing school state to patch the new school state with an emergency state */
  constructEmergencySchoolPatchDto = (
    a: ExtendedAction<
      EmergencyResponeEnum | AttackerTypeEnum | SchoolStateTransitionEnum | SchoolPreferenceEnum
    >,
    schoolDto: SchoolDto | null
  ) => {
    if (!schoolDto || !schoolDto.schoolOwlState) {
      console.error('SchoolDto state is null, every school should have a schoolOwlState')
      return this.schoolApiService.patchSchoolState({})
    }
    const schoolOwlStatePatchDto: Partial<SchoolOwlState> = {
      ...SchoolDtoHelper.constructNewSchoolStateFromAction(a, schoolDto.schoolOwlState),
      schoolLogicalId: schoolDto.logicalId,
      schoolId: schoolDto.id
    }
    return this.schoolApiService.patchSchoolState(schoolOwlStatePatchDto)
  }
  constructSchoolStatePatchDto = (a: ExtendedAction<any>, schoolDto: SchoolDto | null) => {
    if (!schoolDto || !schoolDto.schoolOwlState) {
      console.error('SchoolDto state is null, every school should have a schoolOwlState')
      return this.schoolApiService.patchSchoolState({})
    }
    const schoolOwlStatePatchDto: Partial<SchoolOwlState> = {
      ...schoolDto.schoolOwlState,
      ...a.payload,
      schoolLogicalId: schoolDto.logicalId,
      schoolId: schoolDto.id
    }
    return this.schoolApiService.patchSchoolState(schoolOwlStatePatchDto)
  }

  /** Use the existing school state to patch the new school state with an emergency state */
  constructEmergencySchoolSignalR = (
    a: ExtendedAction<EmergencyResponeEnum | AttackerTypeEnum | SchoolStateTransitionEnum>,
    schoolDto: SchoolDto | null
  ) => {
    if (!schoolDto || !schoolDto.schoolOwlState) {
      console.error('SchoolDto state is null, every school should have a schoolOwlState')
      return of(false)
    }

    return this.signalrService.sendSchoolOwlStateChange(
      SchoolDtoHelper.constructNewSchoolStateFromAction(a, schoolDto.schoolOwlState)
    )
  }
  // TODO Decide if we can just have an endpoint that updates a schools state only instead of patchingt the whole school and getting back a new school dto object, best to only send data needed for update
  setSchoolToEmergencyViaPatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSchoolState),
      concatLatestFrom((_) => this.store.select(selectSchoolById)),
      mergeMap(([a, schoolDto]) =>
        this.constructEmergencySchoolPatchDto(a, schoolDto).pipe(
          map((res: SchoolOwlState) => {
            return patchSchoolStateSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(patchSchoolStateError(getExtendedAction(e))))
        )
      )
    )
  )
  updateSchoolOwlStateViaPatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSchoolOwlState),
      concatLatestFrom((_) => this.store.select(selectSchoolById)),
      mergeMap(([a, schoolDto]) =>
        this.constructSchoolStatePatchDto(a, schoolDto).pipe(
          map((res: SchoolOwlState) => {
            return patchSchoolStateSuccess(getExtendedAction(res))
          }),
          catchError((e: HttpErrorResponse) => of(patchSchoolStateError(getExtendedAction(e))))
        )
      )
    )
  )
}
