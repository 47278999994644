import { Component, Input } from '@angular/core'
import {
  BtnBorderRadius,
  BtnColorVariants,
  ButtonHeight,
  ButtonIconPlacement,
  ButtonWidth
} from './button.component.view'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() borderRadius = BtnBorderRadius.default
  @Input() loading: boolean | null = false
  @Input() height = ButtonHeight.default
  @Input() width = ButtonWidth.fitContent
  @Input() cb: Function | null = () => { }
  @Input() disabled: boolean | null = false
  @Input() buttonText: string | null = null
  @Input() buttonIcon: string | null | undefined
  @Input() buttonType: BtnColorVariants = BtnColorVariants.primary
  @Input() iconPlacement: ButtonIconPlacement | null = ButtonIconPlacement.right
  @Input() isSelected: boolean | null = false
  @Input() tooltipText: string | null = null

  // Border Height
  get isLargeButtonHeight(): boolean {
    return this.height === ButtonHeight.large
  }
  get isDefaultButtonHeight(): boolean {
    return this.height === ButtonHeight.default
  }
  get isSmallButtonHeight(): boolean {
    return this.height === ButtonHeight.small
  }
  // Border Width
  get isSmallBorderRadius(): boolean {
    return this.borderRadius === BtnBorderRadius.small
  }
  get isDefaultBorderRadius(): boolean {
    return this.borderRadius === BtnBorderRadius.default
  }
  get isLargeBorderRadius(): boolean {
    return this.borderRadius === BtnBorderRadius.large
  }
  // Icon
  get hasIcon(): boolean {
    return !!this.buttonIcon
  }
  get isIconLeft(): boolean {
    return !this.loading && this.hasIcon && this.iconPlacement === ButtonIconPlacement.left
  }
  get isIconRight(): boolean {
    return !this.loading && this.hasIcon && this.iconPlacement === ButtonIconPlacement.right
  }
  // Button Size - default is fit content and this can stretch to container
  get isFullWidth(): boolean {
    return this.width === ButtonWidth.fullWidth
  }

  /** Handle click only when
   * 1. button is not disabled
   * 2. button is not loading
   */
  handleClick = () => {
    if (this.disabled || this.loading || !this.cb) return
    this.cb()
  }
}
