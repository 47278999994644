import { ExtendedAction } from '@action/extended-ngrx-action'
import { createAction, props } from '@ngrx/store'
import { SchoolEventSummaryDto } from '@view/pages/events-page/events-dto.view'

export enum EventsPageActions {
  getEvents = '[EventsPageActions] Get Events',
  setEvents = '[EventsPageActions] Set Events',
  downloadEvent = '[EventsPageActions] Download Event'
}

export const getEvents = createAction(EventsPageActions.getEvents)
export const setEvents = createAction(
  EventsPageActions.setEvents,
  props<ExtendedAction<SchoolEventSummaryDto[]>>()
)
export const downloadEvent = createAction(
  EventsPageActions.downloadEvent,
  props<ExtendedAction<SchoolEventSummaryDto>>()
)
