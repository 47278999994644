import { Point, Polygon } from '@arcgis/core/geometry'
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel'
import { BaseMapPopup } from '@component/shared/base-map-popup.model'
import { MapPopupComponent } from '@component/shared/map-popup/map-popup.component'
import { MapPopupPositionEnum } from '@component/shared/map-popup/map-popup.component.model'
import { ArcGisPointFactory } from './arcgis-point-factory'

export namespace ArcGisEventHandlers {
  //  __esri.SketchViewModelDefaultUpdateOptions
  // export class SchoolBoundaryImage{

  // }
  // export class SchoolBoundary{

  // }
  export const updatePopupPosition = (popupRef: BaseMapPopup, mapView: __esri.MapView): void => {
    if (!popupRef.graphic) {
      return
    }
    const place = ArcGisEventHandlers.getPopupPlacement(popupRef.graphic, mapView, popupRef)
    if (!place) {
      return
    }
    // console.log(`Setting popup position at `)
    // console.log(`x: ${place.x} y: ${place.y} rotation: ${mapView.rotation}`)
    popupRef.setPosition(place.x, place.y, mapView.rotation)
  }

  /** This returns the top and left coordinate for the popup based on, the graphic and the screen side, and if considers if popup will be placed outside of view and adjusts accordingly. */
  export const getPopupPlacement = (
    graphic: __esri.Graphic,
    mapView: __esri.MapView,
    component: BaseMapPopup
  ): __esri.MapViewScreenPoint | null => {
    let geometry =
      graphic.geometry?.type == 'point'
        ? (graphic.geometry as Point)
        : (graphic.geometry as Polygon)
    const isPoint = geometry instanceof Point
    //Default to render on right unless there's no room
    let renderUiOnLeft = false
    const { componentWidth, componentHeight } = component
    const popupHalfHeight = componentHeight / 2
    const popupBufferSpaceWidth = geometry instanceof Point ? 20 : 10
    const widthBuffer = 50
    const widthOfScreen = mapView.width
    const heightOfScreen = mapView.height
    let center = ArcGisPointFactory.getPoint(0, 0)
    let pixelCenter = {
      x: 0,
      y: 0
    }
    let xMax = 0
    let xMin = widthOfScreen
    let yForXmax = 0
    let yForXmin = heightOfScreen
    //Y value depends on if it's a point or polygon
    let yValue = 0
    if (geometry instanceof Polygon) {
      const extent = geometry.extent
      center = extent?.center
      //Calculate the x max and min of bounding box of the potentially rotated polygon
      geometry.rings[0].forEach((points) => {
        const xValue = points[0]
        const yValue = points[1]
        // TODO Determine why sometimes we don't have an x and y value here
        // if (!xValue || !yValue) {
        //   console.warn(`
        //   Get Popup Placement for geom attributes
        //     xValue: ${xValue}
        //     yValue: ${yValue}
        //     `)

        //   console.log("geom", geometry)
        // }
        const screenXy = mapView.toScreen(new Point({ x: xValue, y: yValue })) ?? { x: 0, y: 0 }
        const { x } = screenXy
        if (x > xMax) {
          xMax = x
          yForXmax = screenXy.y
        }
        if (x < xMin) {
          xMin = x
          yForXmin = screenXy.y
        }
      })
      renderUiOnLeft = xMax + componentWidth + widthBuffer > widthOfScreen

      // Use the Y value of the left or right most x value
      if (renderUiOnLeft) {
        yValue = yForXmin - popupHalfHeight
      } else {
        yValue = yForXmax - popupHalfHeight
      }
      // console.log(`
      // Existing geometry
      // xmin ${xMin}
      // xmax ${xMax}
      // center x ${center.x}
      // center y ${center.y}
      // `)
    } else if (isPoint) {
      const pixelPoint = mapView.toScreen(geometry)
      center = geometry
      xMin = pixelPoint.x
      xMax = pixelPoint.x
      yValue = pixelPoint.y - popupHalfHeight
    } else {
      throw new Error(`DEV ERROR: Unsupported geometry type ${typeof geometry}`)
    }
    pixelCenter = mapView.toScreen(center)
    // Mutable as we will cap it to inside of screen, defaults to right of geometry
    let screenPoint = {
      x: 0,
      y: 0
    }
    //const rotatedPoint = screenPoin
    if (renderUiOnLeft) {
      screenPoint = {
        x: xMin,
        y: yValue
      }
      //Adjust for UI Size
      screenPoint.x = screenPoint.x - componentWidth - popupBufferSpaceWidth
      component.setPopupPosition(MapPopupPositionEnum.left)
    } else {
      screenPoint = {
        x: xMax + popupBufferSpaceWidth,
        y: yValue
      }
      component.setPopupPosition(MapPopupPositionEnum.right)
    }
    if (screenPoint.x === 0 && screenPoint.y === 0) {
      console.warn(`DEV ERROR: Popup handler can't set position`)
      return null
    }
    return screenPoint
  }
  export const handleDashPointerMove = (
    mapView: __esri.MapView,
    event: __esri.ViewPointerMoveEvent,
    hitGraphic: __esri.Graphic,
    popupRef: BaseMapPopup
  ): void => {
    // console.log(`Handle dash pointer move graphic attributes`)
    // console.log(hitGraphic.attributes)
    if (!popupRef) {
      return
    }
    if (!popupRef.popupVisible) {
      popupRef.showPopup()
    }
    if (!hitGraphic.attributes) {
      console.error(`DEV ERROR: No attributes for graphic`)
      // console.log(hitGraphic)
    }
    popupRef.setContent(hitGraphic.attributes, null)
    setTimeout(() => {
      const placementOfPopup = ArcGisEventHandlers.getPopupPlacement(hitGraphic, mapView, popupRef)
      if (placementOfPopup) {
        popupRef.setPosition(placementOfPopup.x, placementOfPopup.y, mapView.rotation)
      }
      // console.log(`New placement of popup`)
      // console.log(`
      // x ${placementOfPopup.x}
      // y ${placementOfPopup.y}
      // `)
    }, 0)
  }
  export const handleMapConfigPointerMove = (
    mapView: __esri.MapView,
    event: __esri.ViewPointerMoveEvent,
    hitGraphic: __esri.Graphic,
    popupRef: BaseMapPopup
  ): void => {
    // console.log(hitGraphic.geometry.extent)
    if (!popupRef) {
      return
    }
    setTimeout(() => {
      const placementOfPopup = ArcGisEventHandlers.getPopupPlacement(hitGraphic, mapView, popupRef)
      if (placementOfPopup) {
        popupRef.setPosition(placementOfPopup.x, placementOfPopup.y, mapView.rotation)
      }
      // console.log(`New placement of popup`)
      // console.log(`
      // x ${placementOfPopup.x}
      // y ${placementOfPopup.y}
      // `)
    }, 0)
  }

  export const handleMouseWheelChange = async (
    graphic: __esri.Graphic,
    mapView: __esri.MapView,
    popup: BaseMapPopup | undefined
  ): Promise<void> => {
    if (!popup) {
      console.warn(
        `Zoom event handler can't update popup location because related graphic reference isn't avialable.`
      )
      return
    }
    const placementPoint = ArcGisEventHandlers.getPopupPlacement(graphic, mapView, popup)
    // Always show the popup of to the right middle of the shape
    if (placementPoint) {
      // console.log(
      //   `Updating popup placement to placement point x: ${placementPoint.x} y: ${placementPoint.y}`
      // )
      popup.setPosition(placementPoint.x, placementPoint.y, mapView.rotation)
    }
    return
  }
  /** TODO Need to integrate showing the message in the popup */
  export const handleClickEventForPopup = (
    hitGraphic: __esri.Graphic,
    mapView: __esri.MapView,
    popup: MapPopupComponent | undefined
  ): void => {
    if (!popup) {
      return
    }
    popup.setGraphic(hitGraphic)
    popup.setContent(hitGraphic.attributes)
    popup.setIdForAction(hitGraphic.attributes.id as number)
    // When we click on the popup we want the action to be avaialble
    popup.showAction = true
    popup.showPopup()
    // Always show the popup of to the right middle of the shape
    setTimeout(() => {
      const placementPoint = ArcGisEventHandlers.getPopupPlacement(hitGraphic, mapView, popup)
      if (placementPoint) {
        popup.setPosition(placementPoint.x, placementPoint.y, mapView.rotation)
      }
    }, 0)
  }
  export const handleUpdate = (event: any, sketchViewModel: SketchViewModel) => {
    if (!event) {
      return
    }
    if (event.state === 'complete') {
      sketchViewModel.complete()
    }
  }
  // export const handleUpdateComplete = () => {
  //   // console.log("Graphic update completed");
  // }
}
