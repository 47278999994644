import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { DtoValidationFailed } from '@model/server-validation.model'
import {
  GetUserDto,
  MobileUserTypes,
  PatchUserDtoType,
  PostUserDto,
  PostUserDtoType,
  UploadRosterUserDto
} from '@model/user/user.model'
import { createAction, props } from '@ngrx/store'
import {
  RosterInvitationSummaryDto,
  RosterValidationDto
} from '@view/pages/roster-page/roster-page.view'
import { TriggerReinvitePayload } from './roster-page.actions'

export enum RosterApiActions {
  // create
  createAndInviteUserSuccess = '[RosterApiActions] Create And Invite User Success',
  createAndInviteUserError = '[ RosterApiActions] Create And Invite User Error',

  // update
  updateUserSuccess = '[ RosterApiActions] Update User Success',
  updateUserError = '[ RosterApiActions] Update User Error',

  // roster file upload
  updateRosterSuccess = '[ RosterApiActions] Update Roster Success',
  updateRosterError = '[ RosterApiActions] Update Roster Error',

  // roster validation
  validateRosterSuccess = '[ RosterApiActions] Validate Roster Success',
  validateRosterError = '[ RosterApiActions] Validate Roster Error',

  //reinvite unregistered
  reinviteUnregisteredSuccess = '[RosterApiActions] Re-invite Unregistered Success',
  reinviteUnregisteredError = '[RosterApiActions] Re-invite Unregistered Error',

  //deactivate
  deactivareSelectedUserSuccess = '[RosterApiActions] Deactivate Selected User Success',
  deactivareSelectedUserError = '[RosterApiActions] Deactivate Selected User Error',

  // get invitation summary dto
  getInvitationSummaryByMobileTypeDtoSuccess = '[RosterApiActions] Get Invitation Summary Dto By Type Success',
  getInvitationSummaryByMobileTypeDtoError = '[RosterApiActions] Get Invitation Summary Dto By Type Error',

  // Used by signalr - no success/fail pair on real time comms
  setSignalrrInvitationSummaryDto = '[RosterApiActions] Set Invitation Summary Dto for SignalR',
  /** This is the same action but delayed so that the UI isn't updated in a jarring manner */
  throttledSetSignalrrInvitationSummaryDto = '[RosterApiActions] Throttled Set Invitation Summary Dto for SignalR'
}

//Create
export const createAndInviteUserSuccess = createAction(
  RosterApiActions.createAndInviteUserSuccess,
  props<ExtendedAction<PostUserDto>>()
)
// TODO Discuss why the status code is 500 when it's a validation error. Shouldn't it be a conflict error?
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/409
export const createAndInviteUserError = createAction(
  RosterApiActions.createAndInviteUserError,
  props<ExtendedAction<DtoValidationFailed<PostUserDtoType>>>()
)
//Update
export const updateUserSuccess = createAction(
  RosterApiActions.updateUserSuccess,
  props<ExtendedAction<GetUserDto>>()
)
export const updateUserError = createAction(
  RosterApiActions.updateUserError,
  props<ExtendedAction<DtoValidationFailed<PatchUserDtoType>>>()
)

export interface UpdateRosterPayload {
  users: UploadRosterUserDto[]
  type: MobileUserTypes
}
export const updateRosterSuccess = createAction(RosterApiActions.updateRosterSuccess)
export const updateRosterError = createAction(
  RosterApiActions.updateRosterError,
  props<ExtendedAction<HttpErrorResponse>>()
)

export const validateRosterSuccess = createAction(
  RosterApiActions.validateRosterSuccess,
  props<ExtendedAction<RosterValidationDto>>()
)
export const validateRosterError = createAction(
  RosterApiActions.validateRosterError,
  props<ExtendedAction<HttpErrorResponse>>()
)

export interface InvitationSummarySuccessPayload {
  mobileUserType: MobileUserTypes
  invitationSummaryDto: RosterInvitationSummaryDto | null
}
export const getInvitationSummaryByMobileTypeDtoSuccess = createAction(
  RosterApiActions.getInvitationSummaryByMobileTypeDtoSuccess,
  props<ExtendedAction<InvitationSummarySuccessPayload>>()
)
/** This action uses the same dot as the get invitation summary dto success action but this is only used by signalr and will always contain a value. */
export const setSignalrrInvitationSummaryDto = createAction(
  RosterApiActions.setSignalrrInvitationSummaryDto,
  props<ExtendedAction<RosterInvitationSummaryDto>>()
)
/** This action is the same as  setSignalrrInvitationSummaryDto but delays it by a min ui state change wait time.*/
export const throttledSetSignalrrInvitationSummaryDto = createAction(
  RosterApiActions.throttledSetSignalrrInvitationSummaryDto,
  props<ExtendedAction<RosterInvitationSummaryDto>>()
)
export const getInvitationSummaryDtoByMobileTypeError = createAction(
  RosterApiActions.getInvitationSummaryByMobileTypeDtoError,
  props<ExtendedAction<MobileUserTypes>>()
)

//reinvite unregistered
export const reinviteUnregisteredSuccess = createAction(
  RosterApiActions.reinviteUnregisteredSuccess,
  props<ExtendedAction<TriggerReinvitePayload>>()
)
export const reinviteUnregisteredError = createAction(RosterApiActions.reinviteUnregisteredError)

//deactivate
export const deactivareSelectedUserSuccess = createAction(
  RosterApiActions.deactivareSelectedUserSuccess
)
export const deactivareSelectedUserError = createAction(
  RosterApiActions.deactivareSelectedUserError
)
