// import Graphic from "@arcgis/core/Graphic.js";
// import CreateToolEventInfo from "@arcgis/core/widgets/Sketch/SketchViewModel.js";

//It seems the arc gis npm package doesn't export the interfaces for some of these events so I had to copy them here to use typescript features on arc gis functions and objects
export enum SketchVmStateEnum {
  start = 'start',
  active = 'active',
  complete = 'complete'

  // TODO This doesn't exist in update, likely only create, check
  // cancel = 'cancel'
}
export enum SketchEventTypeEnum {
  update = 'update',
  redo = 'redo',
  undo = 'undo',
  create = 'create'
}
export type SketchCreateEventState =
  | SketchVmStateEnum.start
  | SketchVmStateEnum.active
  | SketchVmStateEnum.complete
// | SketchVmStateEnum.cancel
// export type SketchUpdateEventState = Exclude<SketchCreateEventState, SketchVmStateEnum.cancel>

export type SketchTool = 'point' | 'polyline' | 'polygon' | 'rectangle' | 'circle'
export enum SketchToolEnum {
  point = 'point',
  polyline = 'polyline',
  polygon = 'polygon',
  rectangle = 'rectangle',
  circle = 'circle'
}
export type SketchUpdateTool = 'move' | 'transform' | 'reshape'
export enum SketchUpdateToolEnum {
  move = 'move',
  transform = 'transform',
  reshape = 'reshape'
}

export enum SketchEvents {
  create = 'create',
  update = 'update',
  start = 'start'
}

// export interface SketchCreateEvent {
//     graphic: Graphic,
//     state: SketchCreateEventState
//     tool: SketchTool,
//     toolEventInfo: CreateToolEventInfo,
//     type: SketchEvents.create
// }
// export interface SketchUpdateEvent {
//     graphic: Graphic[],
//     state: SketchUpdateEventState,
//     aborted: boolean,
//     tool: SketchTool,
//     toolEventInfo: UpdateToolEventInfo,
//     type: SketchEvents.update

// }
