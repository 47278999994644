export const INVITE_REINVITE_BUTTON_TOOLTIP_TEXT = 'Re-invite Unregistered'
export enum RosterColumnsEnum {
  name = 'name',
  role = 'role',
  phone = 'phone',
  email = 'email',
  statusImage = 'statusImage',
  statusForSorting = 'statusForSorting',
  menu = 'menu'
}
export type RosterColumnsNoMenu = Exclude<RosterColumnsEnum, RosterColumnsEnum.menu>
export type RosterColumnsNoRole = Exclude<RosterColumnsEnum, RosterColumnsEnum.role>
export type RosterColumnsPreview = RosterColumnsNoMenu & RosterColumnsNoRole
export type RosterColumnsWithBackgroundStyle =
  | RosterColumnsEnum.name
  | RosterColumnsEnum.phone
  | RosterColumnsEnum.email
