<ng-content></ng-content>
<div
  class="main-ui-label-container --{{ variant }} --{{ placement }}"
  [ngClass]="{ '--displayed': displayed }"
>
  <div class="main-ui-label  {{ labelClass }} --{{ roundingClass }}">
    <div class="label-pointer {{ direction }}"></div>
    <div class="_label-text">
      {{ text }}
    </div>
  </div>
</div>
