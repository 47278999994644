export enum LocalStorageKeys {
  // Dashboard 
  pulsingAlerts = 'pulsingAlerts',
  chatMessageReadReceipt = 'chatMessageReadReceipt',
  // Auth
  authStateChangeActionKey = 'authStateChangeActionKey',
  // Public
  userSignedUpForPilotProgam = 'userSignedUpForPilotProgam'
}
export enum SessionStorageKeys {
  initUrlRequestKey = 'initialRequestUrlKey',
  userSideNavSettingsKey = 'userSideNavSettingsKey'
}
export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage'
}
export abstract class BaseStorageService {
  abstract storageType: StorageType
  abstract getService(): Storage
  constructor() {}
  getKey<T>(key: string): T | null {
    const jsonData = this.getService().getItem(key)
    if (jsonData === null) {
      return null
    }
    try {
      return JSON.parse(jsonData) as T
    } catch (e) {
      console.error(`Error parsing storage item`, e)
      return null
    }
  }
  getStringValue(key: string): string | null {
    return this.getService().getItem(key)
  }
  setKey<T>(key: string, value: T): void {
    this.getService().setItem(key, JSON.stringify(value))
  }
  /** Useful for accumulating data associated to one key. */
  addToIdStringValue(key: string, value: string): void {
    const initialValue = this.getKey<string>(key)
    this.setKey(key, initialValue ? `${initialValue},${value}` : value)
  }

  clearByKey(key: string): void {
    this.getService().removeItem(key)
  }

  // clear all data from session storage
  // clearAll(): void {
  //     sessionStorage.clear();
  // }
}
