import { Store } from '@ngrx/store'
import { selectSchoolMapConfigPageStatePrerequistes } from '@selector/auth.selector'

import { GlobalState } from '@state/auth.state'

import { selectMapConfigViewModelWithState } from '@selector/page/school-map-config-page/school-map-config-page-state.selector'
import { MapModesEnum } from '@view/map/map-enums.view'
import { IMapConfigViewModel } from '@view/pages/school-map-config-page/school-map-config.view'
import { Subscription } from 'rxjs'
import { MapPageType } from './map.component.service.model'
import { MapConfigEditAreaPopupComponent } from '@component/map-config-edit-area-popup/map-config-edit-area-popup.component'
import { MapPopupComponent } from '@component/shared/map-popup/map-popup.component'

export abstract class BaseMapService<T> {
  _map: T | undefined
  mapInitPrereq$ = this._store.select(selectSchoolMapConfigPageStatePrerequistes)
  mapConfigVm$ = this._store.select(selectMapConfigViewModelWithState)
  baseSubs: Subscription[] = []
  type = MapPageType.unknown

  constructor(public _store: Store) { }

  /** To support features in open layers and arc gis we need to require that the service implements a shared set of functions */
  abstract setSelectionMode(): void
  abstract setDrawMode(): void
  abstract setEditMode(): void
  abstract setSelectShapes(): void
  abstract setImageUploadMode(): void
  abstract setPopup(popup: MapPopupComponent | MapConfigEditAreaPopupComponent): void

  //Data reaction functions
  init = (type: MapPageType) => {
    // console.log(`Calling init in base map service with type ${type}`)
    this.type = type

    if (this.type === MapPageType.schoolMapConfig) {
      this.baseSubs = [
        this.mapInitPrereq$.subscribe(this._initBasedOnSchoolDto),
        this.mapConfigVm$.subscribe(this._handleMapConfigVmUpdates)
      ]
    } else if (this.type === MapPageType.schoolDashboard) {
      this.baseSubs = [this.mapInitPrereq$.subscribe(this._initBasedOnSchoolDto)]
    }
  }
  abstract _initBasedOnSchoolDto(authState: GlobalState | null): void
  /** Specifically here to handle map config view model */
  abstract _handleMapConfigVmUpdates(step: IMapConfigViewModel | null): void

  abstract destroy(): void

  /** Todo integrate with subscription to page state instead of maintaining this local state prop */
  setUpMapMode = (mapMode: MapModesEnum) => {
    switch (mapMode) {
      case MapModesEnum.selectPoints:
        this.setSelectionMode()
        break
      case MapModesEnum.draw:
        this.setDrawMode()
        break
      case MapModesEnum.edit:
        this.setEditMode()
        break
      case MapModesEnum.selectShapes:
        this.setSelectShapes()
        break
      case MapModesEnum.upload:
        this.setImageUploadMode()
        break
    }
  }
}
