import Graphic from '@arcgis/core/Graphic'
import { Point } from '@arcgis/core/geometry'
import {
  ChatResponseWithMessagePayload,
  ChatResponseWithPollResponseIdPayload
} from '@model/message.model'
import { StatusColorEnum } from '@view/area/area.view'
import { ArcGisPointFactory } from '../arcgis-point-factory'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'

export namespace ArcGisMessageGraphicHandler {
  /**
   * These graphics get added with a hidden symbol by default to be shown conditionally based on when user clicks and holds a poll chat component.
   * After adding the graphic to the map, it can only be shown by invoking pollResponsesVisibilityHandler to show the correct color graphics.
   */
  export const getGraphicForPollResponse = (
    payload: ChatResponseWithPollResponseIdPayload,
    responseIdToStatusColorEnum: Record<number, StatusColorEnum>
  ): Graphic => {
    const colorToUseForSymbol =
      responseIdToStatusColorEnum[payload.responseId] ?? StatusColorEnum.unknown
    return new Graphic({
      geometry: ArcGisPointFactory.getPoint(payload.latLong?.lon ?? 0, payload.latLong?.lat ?? 0),
      symbol: ArcGisSymbolFactory.hiddenSymbol,
      attributes: ArcGisGraphicAttrFac.getPollResponseGraphicAttributes(
        payload,
        colorToUseForSymbol
      )
    })
  }
  /** Gets added as hidden by default.'
   * IDEA - not in requirements 1.Shown when user is selected.
   * */
  export const getGraphicForMessageResponse = (
    payload: ChatResponseWithMessagePayload,
    hidden: boolean = true
  ): Graphic => {
    return new Graphic({
      geometry: new Point({
        x: payload.latLong?.lon ?? 0,
        y: payload.latLong?.lat ?? 0
      }),
      symbol: hidden ? ArcGisSymbolFactory.hiddenSymbol : ArcGisSymbolFactory.regularMessageSymbol,
      attributes: ArcGisGraphicAttrFac.getMessageGraphicAttributes(payload)
    })
  }
}
