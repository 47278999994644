import { Injectable } from '@angular/core'
import * as Msal from '@azure/msal-browser'
import {
  AccountInfo,
  BrowserAuthError,
  BrowserAuthOptions,
  BrowserCacheLocation,
  BrowserSystemOptions,
  EndSessionRequest,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser'
import { authenticatedAppSubdomain, FirstLevelDir, LocalHostPorts, OwlRoutes, SecondLevelDir } from '@domain/route/app-routes.domain'
import { AppConfigService } from '@service/app-config/app-config.service'
import { localHostText, nonSecureUrlPrefix, secureUrlPrefix } from '@shared/constants'
import { isLocalHost } from '@shared/js-window'
import { Observable, Subject } from 'rxjs'

import { tokenReceived } from '@action/auth.action'
import { getExtendedAction } from '@action/extended-ngrx-action'
import { redirectAngularRouter } from '@action/redirect.action'
import { setValidationClaims } from '@action/user/user-api.action'
import { Store } from '@ngrx/store'
import { LocalStorageKeys } from '@service/browser/base-storage.service'
import {
  CrossBrowserStateIndicator,
  MsalAcquireTokenSilentErrorCodes,
  MsalRedirectErrorCode
} from './auth.model'

@Injectable()
export class AuthService {
  private _authStateSubject = new Subject<boolean>()
  authStateObservable = this._authStateSubject.asObservable()

  loginRequest!: RedirectRequest
  resetPassword!: RedirectRequest
  editProfile!: RedirectRequest
  signUpProfile!: RedirectRequest
  tokenRequest!: SilentRequest

  authOptions?: BrowserAuthOptions
  systemOptions?: BrowserSystemOptions

  cacheOptions: Msal.CacheOptions = {
    cacheLocation: BrowserCacheLocation.SessionStorage
    // This would be true for for IE support,
    // but isn't needed as IE is deprecated, according to Microsoft.
    // storeAuthStateInCookie: false
  }

  msalConfig?: Msal.Configuration
  private _token = ''
  onLocalHost: boolean = isLocalHost()
  redirectUrl = ''
  pnPublicPage = false

  private msal?: Msal.IPublicClientApplication

  /** We need to redirect across subdomains for deployed isntances and by port on local host */
  getHostNameWithoutAppSubdomain() {
    //If local host replace port instead of subdomain
    if (isLocalHost()) {
      return window.location.host.replace(`:${LocalHostPorts.Auth}`, `:${LocalHostPorts.Public}`)
    } else {
      return window.location.host.replace(`${authenticatedAppSubdomain}.`, '')
    }
  }

  getAccessToken(): string {
    return this._token
  }
  /** Set up bearer token header here */
  getAuthHeaders = (): { [key: string]: string } => {
    return {
      'Authorization': `Bearer ${this._token}`
    }
  }

  //TODO Set this up to be configurable by environment variables
  system: BrowserSystemOptions | undefined = {
    // redirectNavigationTimeout: 2000
  }
  // = {
  //   loggerOptions: {
  //     loggerCallback: (level: Msal.LogLevel, message: string, containsPii: boolean): void => {
  //       switch (level) {
  //         case Msal.LogLevel.Error:
  //           console.error(message)
  //           return
  //         case Msal.LogLevel.Info:
  //           console.info(message)
  //           return
  //         case Msal.LogLevel.Verbose:
  //           console.debug(message)
  //           return
  //         case Msal.LogLevel.Warning:
  //           console.warn(message)
  //           return
  //       }
  //     },
  //     piiLoggingEnabled: false,
  //   },
  // }

  stateHasSignInPolicy: boolean = false
  stateHasEditPolicy: boolean = false
  stateHasResetPwdPolicy: boolean = false
  stateHasSignUpPolicy: boolean = false

  userClickForgotPassword: boolean = false

  homeAccountId: string = ''

  getActiveAccountResult: Msal.AccountInfo | null | undefined = undefined

  constructor(private appConfig: AppConfigService, private store: Store) {
    window.addEventListener('storage', this.handleStorageAuthStateChange)
  }

  /**
   * The OWL platform loads the config.json file in an async manner, and it provides environment variables.
   * Due to this, using env vars in a service constructor will yield only undefined variables because the promise for app config service hasn't completed yet, since the angular runtime invokes these construtors early on in the app boostrapping process.
   * Hence the need for an explicit init() function to be called by app component in it's onInit lifecycle method which only gets called when async app config service cb is complete.
   * In other words, if init is called in app component, app initalizer promise wiil be complete.
   */
  init = async (): Promise<void> => {
    // Skip auth if on public site4
    if (this.appConfig.isPublicSite()) {
      return
    }
    this.log(`Auth service init.`)
    //Skipp auth if disabled in config
    if (!this.appConfig.config.USE_AUTH) {
      return
    }
    //Set up config vars
    let rootRedirectUrl = ''
    let logoutRootRedirectUrl = ''
    let logInAuthority = ''

    let resetPasswordAuthority = ''
    let editProfileAuthority = ''
    let signUpAuthority = ''
    let clientId = ''
    let conditionalRootRedirectPrefix = this.onLocalHost ? nonSecureUrlPrefix : secureUrlPrefix
    let loggedOutRedirectUrl = ''
    let loggedOutDir = `${FirstLevelDir.public}/${SecondLevelDir.loggedOut}`
    const {
      TENANT_B2C_URL,
      TENANT_URL,
      CLIENT_ID,
      SIGN_IN_POLICY,
      SIGN_UP_POLICY,
      RESET_PWD_POLICY,
      PROFILE_EDIT_POLICY,
      SCOPE
    } = this.appConfig.config.OWL_B2C_MSAL_CONFIG
    //Host without app. to redirect to public site
    logoutRootRedirectUrl = `${conditionalRootRedirectPrefix}${this.getHostNameWithoutAppSubdomain()}`
    rootRedirectUrl = `${conditionalRootRedirectPrefix}${window.location.host}`

    resetPasswordAuthority = `${secureUrlPrefix}${TENANT_B2C_URL}/${TENANT_URL}/${RESET_PWD_POLICY}`
    editProfileAuthority = `${secureUrlPrefix}${TENANT_B2C_URL}/${TENANT_URL}/${PROFILE_EDIT_POLICY}`
    logInAuthority = `${secureUrlPrefix}${TENANT_B2C_URL}/${TENANT_URL}/${SIGN_IN_POLICY}`
    signUpAuthority = `${secureUrlPrefix}${TENANT_B2C_URL}/${TENANT_URL}/${SIGN_UP_POLICY}`
    loggedOutRedirectUrl = `${logoutRootRedirectUrl}/${loggedOutDir}`
    console.log("loggedOutRedirectUrl", loggedOutRedirectUrl)

    clientId = CLIENT_ID

    this.tokenRequest = {
      scopes: [`${secureUrlPrefix}${TENANT_URL}/${SCOPE}`]
    }
    this.loginRequest = {
      authority: logInAuthority,
      account: undefined,
      scopes: [`${secureUrlPrefix}${TENANT_B2C_URL}/${TENANT_URL}/${SIGN_IN_POLICY}`],
      state: this.appConfig.config.OWL_B2C_MSAL_CONFIG.SIGN_IN_POLICY
    }
    this.resetPassword = {
      authority: resetPasswordAuthority,
      account: undefined,
      scopes: [],
      state: this.appConfig.config.OWL_B2C_MSAL_CONFIG.RESET_PWD_POLICY
    }
    this.editProfile = {
      authority: editProfileAuthority,
      account: undefined,
      scopes: [],
      state: this.appConfig.config.OWL_B2C_MSAL_CONFIG.PROFILE_EDIT_POLICY
    }
    this.signUpProfile = {
      authority: signUpAuthority,
      scopes: [],
      redirectStartPage: window.location.host,
      redirectUri: this.redirectUrl,
      onRedirectNavigate: () => {
        this.clearMsalCache()
      },
      state: this.appConfig.config.OWL_B2C_MSAL_CONFIG.SIGN_UP_POLICY
    }

    this.redirectUrl = `${rootRedirectUrl}/${FirstLevelDir.auth}`
    this.pnPublicPage = window.location.href.includes(FirstLevelDir.public)
    this.authOptions = {
      clientId: clientId,
      authority: this.pnPublicPage ? signUpAuthority : logInAuthority,
      redirectUri: this.redirectUrl,
      postLogoutRedirectUri: loggedOutRedirectUrl,
      knownAuthorities: [TENANT_B2C_URL],
      // This must be set to false or the app will get stuck in a reload loop,
      navigateToLoginRequestUrl: false
    }

    //Local Dev Purposes
    // this.log('this.authOptions')
    // this.log(this.authOptions?.toString() ?? {})
    // this.log('this.tokenRequest')
    // this.log(this.tokenRequest?.toString() ?? {})

    this.msalConfig = {
      auth: this.authOptions,
      cache: this.cacheOptions,
      system: this.system
    }
    const routeIsPublic = window.location.pathname.indexOf(FirstLevelDir.public) !== -1
    const routeIsCreateAccount = window.location.pathname.indexOf(SecondLevelDir.createAccount) !== -1
    const userCancelledFlow = window.location.href.indexOf(MsalRedirectErrorCode.UserCancelled) > -1
    const routeHasAuthRequirement = window.location.pathname.indexOf(FirstLevelDir.public) === -1
    this.stateHasSignInPolicy =
      window.location.href.indexOf(this.appConfig.config.OWL_B2C_MSAL_CONFIG.SIGN_IN_POLICY) > -1
    this.stateHasEditPolicy =
      window.location.href.indexOf(this.appConfig.config.OWL_B2C_MSAL_CONFIG.PROFILE_EDIT_POLICY) >
      -1
    this.stateHasResetPwdPolicy =
      window.location.href.indexOf(this.appConfig.config.OWL_B2C_MSAL_CONFIG.RESET_PWD_POLICY) > -1
    this.stateHasSignUpPolicy =
      window.location.href.indexOf(this.appConfig.config.OWL_B2C_MSAL_CONFIG.SIGN_UP_POLICY) > -1
    this.msal = new Msal.PublicClientApplication(this.msalConfig)
    this.msal.addPerformanceCallback(this.handlePerformance)
    if (routeIsPublic || routeIsCreateAccount) {
      return
    }

    await this.msal.initialize()

    this.log(`
    routeIsPublic ${routeIsPublic}
    routeIsCreateAccount ${routeIsCreateAccount}
    this.stateHasSignInPolicy ${this.stateHasSignInPolicy}
    this.stateHasEditPolicy ${this.stateHasEditPolicy}
    this.stateHasResetPwdPolicy ${this.stateHasResetPwdPolicy}
    this.stateHasSignUpPolicy ${this.stateHasSignUpPolicy}
          `)

    if (
      routeHasAuthRequirement &&
      !userCancelledFlow &&
      !this.stateHasResetPwdPolicy &&
      !this.stateHasSignUpPolicy
    ) {
      this.log(`Auth Service: Setting handleRedirectPromise in msal`)
      await this.setUpMsalPromise()
    }
    //Handle sign up Flow
    else if (this.stateHasSignUpPolicy && !userCancelledFlow && routeHasAuthRequirement) {
      this.clearMsalCache()
      await this.setUpMsalPromise()
    }
    // Handle cancelled sign up flow
    else if (routeHasAuthRequirement && userCancelledFlow) {
      // If the user cancells the sign up flow, bring them back to the welcome page
      if (this.stateHasSignUpPolicy) {
        this.redirectToPublicWelcomePage()
        // this.redirectToWelcomePage()
      } else {
        //Redirect to root of site without saving current page in history, to start the auth flow
        window.location.replace(window.location.origin)
      }
    }
    // Need to show success on password reset
    else if (this.stateHasResetPwdPolicy) {
      this.clearMsalCache()
      this.redirectToLoginRedirectPage()
    }
    //TODO research this as a way to catch interaction in progress errors
    // this.msal?.addEventCallback((event: any) => this.handleMsalAddEventCallback(event))
  }
  handleMsalAddEventCallback = (event: string | null) => {
    // console.log('handleMsalAddEventCallback', event)
    // set active account after redirect
    // if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    //   const account = event.payload.account;
    //   msalInstance.setActiveAccount(account);
    // }
  }
  handleMsalAddEventErrorCb = (error: any) => {
    console.log('error', error)
  }

  //PUBLIC API
  //#region
  /**
   *
   * @returns an observable to indicate that the access token and user data retreival process is complete, both are async hence the need for a wrapping observable. Needs to be used when handling a 401 unauthorized error to retry failed network calls, and prevent additional start auth flow calls from being made while one is in progress.
   *
   */
  startAuthFlow = (): Observable<boolean> => {
    this._authStateSubject = new Subject<boolean>()
    this.authStateObservable = this._authStateSubject.asObservable()
    this.log(`Start auth flow in auth service`)
    this.startGetTokenFlow()
    return this.authStateObservable
  }
  logout = (): Promise<void> | undefined => {
    console.log(`Triggering logout in auth service`)
    //Before we log out, let any potential other tabs know about this
    this.setToken('')
    localStorage.setItem(
      LocalStorageKeys.authStateChangeActionKey,
      CrossBrowserStateIndicator.LoggingOut
    )
    return this.msal?.logout()
  }
  /**
   * Here to handle use case where user wants to log out after being redirected to an access denied page due to usage of a locked account.
   */
  logoutAndRedirectToLoginPage = (): Promise<void> => {
    const logoutRequest: EndSessionRequest | undefined = {
      postLogoutRedirectUri: this.loggedOutPage
    }
    return this.msal?.logoutRedirect(logoutRequest) ?? new Promise(() => { })
  }
  // #endregion

  //Policy Checks
  tfpIsResetPwdPolicy = (tfp: string): boolean =>
    this.resetPassword.authority?.includes(tfp) ?? false
  tfpIsForEditProfilePolicy = (tfp: string): boolean =>
    this.editProfile.authority?.includes(tfp) ?? false
  tfpIsForSignInPolicy = (tfp: string): boolean =>
    this.loginRequest.authority?.includes(tfp) ?? false

  /** Once we have the token redirect to home page. TODO - Redirect by user type in the future. */
  setToken = (t: string) => {
    // console.log(`SET TOKEN`, t)
    this._token = t
    // TEMP Before integrating the user endpoint
    // if(!this.appConfig.config.USE_AUTH){
    // this.store.dispatch(userDataReceived(getExtendedAction(mockUserDto)))
    // }
  }

  //MSAL
  //HANDLE INTERACTIVE ENDPOINTS
  //#region
  /**
   * Handle async aspect of msal promise since you must wait till interactive api calls finish before using other ones.
   *
   * **/
  setUpMsalPromise = async (): Promise<Msal.AuthenticationResult | null | undefined | void> => {
    if (!this.msal || !this.msal?.handleRedirectPromise) {
      console.warn(`this.msal?.handleRedirectPromise not defined`)
      return null
    }
    const result = await this.msal
      .handleRedirectPromise()
      .then(this.handleRedirectPromiseSuccess)
      .catch(this.handleRedirectPromiseFailure)
    if (!result) {
      //check if the user has an active account
      this.getActiveAccountResult = this.msal?.getActiveAccount()
      this.log(`
        getActiveAccountResult : ${JSON.stringify(this.getActiveAccountResult)}
        `)
      //TODO this property exists but perhaps it's just not updated in the typescript interface
      // If we get to after the msal promise and we don't have an account and there was a redirect response we'll need to reauthenticate
      const redirectResponseFailedToAuth = (this.msal as any)?.redirectResponse?.size > 0
      if (this.stateHasResetPwdPolicy) {
        this.dispatchToken('')
        this.msal = undefined
      } else if (
        redirectResponseFailedToAuth &&
        !this.getActiveAccountResult &&
        !this.userClickForgotPassword
      ) {
        this.triggerMsalLoginRedirect()
      }
    }
  }

  //PROMISE HANDLER SECTION
  //#region
  handleRedirectPromiseSuccess = (
    tokenResponse: Msal.AuthenticationResult | null
  ): undefined | void => {
    let accountInAuthResult = null
    if (tokenResponse !== null) {
      let policyIsSignIn = false
      let policyIsEditProfile = false
      let policyIsResetPwd = false

      accountInAuthResult = tokenResponse.account
      if (accountInAuthResult) {
        const idTokenClaims = accountInAuthResult?.idTokenClaims as Object | undefined
        this.handleIdTokenClaims(idTokenClaims)
        if (!idTokenClaims) {
          throw Error(`No tfp in token claims`)
        }
        const tfp: string = (idTokenClaims as any)?.tfp ?? 'unknown'
        policyIsSignIn = this.tfpIsForSignInPolicy(tfp)
        policyIsEditProfile = this.tfpIsForEditProfilePolicy(tfp)
        policyIsResetPwd = this.tfpIsResetPwdPolicy(tfp)
      }

      //For local dev only - no need to retain the id token
      // this.log(tokenResponse.idToken)

      const accessTokenExistsForApiScope =
        tokenResponse.accessToken && this.tokenRequest.scopes.includes(tokenResponse.scopes[0])
      if (accessTokenExistsForApiScope && policyIsSignIn) {
        this.dispatchToken(tokenResponse.accessToken)
        this.redirectToLoggedInPage()
      } else if (!accessTokenExistsForApiScope && policyIsSignIn) {
        this.msal?.setActiveAccount(accountInAuthResult)
        this.startGetTokenFlow()
      } else if (policyIsEditProfile) {
        this.stateHasEditPolicy = true
      } else if (policyIsResetPwd) {
        this.stateHasResetPwdPolicy = true
      }
    } else {
      const currentAccounts: AccountInfo[] = this.msal?.getAllAccounts() ?? []
      let account: AccountInfo | null = null
      if (currentAccounts.length === 0) {
        this.log(`No account found during redirect success so wait for callback`)
      } else if (currentAccounts.length > 1) {
        currentAccounts.forEach((account: AccountInfo) => {
          const tfp: string = (account?.idTokenClaims as any)?.tfp
          if (account?.idTokenClaims) {
            this.handleIdTokenClaims(account.idTokenClaims)
          }

          // First see which user flow user is initiating
          const isSignInAccount = this.tfpIsForSignInPolicy(tfp)
          const isPwdPolicy = this.tfpIsResetPwdPolicy(tfp)
          const isEditPolicy = this.tfpIsForEditProfilePolicy(tfp)

          if (isSignInAccount) {
            //Find last logged in account. Msal locally store all logged in accounts.
            // const mostRecentAccount = currentAccounts.reduce((prev, current) => (prev.idTokenClaims?.auth_time != null && current.idTokenClaims?.auth_time != null && prev.idTokenClaims.auth_time > current.idTokenClaims.auth_time) ? prev : current)
            this.msal?.setActiveAccount(account)
            this.startGetTokenFlow()
          } else if (isPwdPolicy && this.stateHasResetPwdPolicy) {
            this.homeAccountId = account.homeAccountId
          } else if (isEditPolicy && this.stateHasEditPolicy) {
            this.homeAccountId = account.homeAccountId
          }
        })
      } else {
        //This branch imples that there is just one acount
        account = currentAccounts[0]
      }
      if (account !== null) {
        const tfp: string = (account?.idTokenClaims as any)?.tfp
        if (account?.idTokenClaims) {
          this.handleIdTokenClaims(account?.idTokenClaims)
        }
        if (this.tfpIsForSignInPolicy(tfp)) {
          //Save the home account id for the token request process
          this.homeAccountId = account.homeAccountId

          //Set active account based examples without explanation or documentation
          this.msal?.setActiveAccount(currentAccounts[0])
          this.startGetTokenFlow()
        }
      }
    }
  }
  handleIdTokenClaims = (claims: Object | undefined) => {
    if (claims) {
      this.store.dispatch(setValidationClaims(getExtendedAction(claims)))
    }
  }

  handleRedirectPromiseFailure = (error: any) => {
    this.log(`AUTH SERVICE: handleRedirectPromiseFailure error`)
    if (error.errorMessage.indexOf(MsalRedirectErrorCode.UserCancelled) > -1) {
      this.log(
        `AUTH SERVICE: handleRedirectPromiseFailure user cancelled a flow with an active account, start auth flow.`
      )
      //If the user cancelled the flow just assume they are still authenticated and redirect to logged in
      this.startGetTokenFlow()
    } else if (error.errorMessage.indexOf(MsalRedirectErrorCode.ResetPasswordFlowErrorCode) > -1) {
      this.log(
        `AUTH SERVICE: handleRedirectPromiseFailure user clicked forgot password, redirecting to identity provider with reset password flow.`
      )
      //We still trigger the reset flow if the user invoked the user flow form the identity provider
      this.userClickForgotPassword = true
      //but we need to saved that so we don't redirect to login, due to no account found
      this.triggerResetPasswordFlow()
    } else {
      this.log(`AUTH SERVICE: unknown handleRedirectPromiseFailure error`)
      console.table(error)
    }
  }
  //#endregion

  // TRIGGER REDIRECTS SECTION
  // #region
  clearMsalCache = () => {
    //Whenever we trigger a redirect, we want to start a new interactive session so clear the last interaction indicator in session state
    //https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
    let msalInstance: Msal.PublicClientApplication = this.msal as Msal.PublicClientApplication
    msalInstance['browserStorage'].clear()
    this.log(`Clearing msal cache`)
  }
  triggerMsalLoginRedirect = () => {
    this.log(`Auth Service: triggerMsalLoginRedirect`)
    this.msal
      ?.loginRedirect(this.loginRequest)
      .then((res: any) => {
        console.table(res)
      })
      .catch((err: BrowserAuthError) => {
        // console.error('BrowserAuthError err.errorMessage', err.errorMessage)
        // console.error('BrowserAuthError err.errorCode', err.errorCode)
        this.clearMsalCache()
        if (err.message.indexOf(`no_cached_authority_error: No cached authority found.`) > -1) {
          //Happens during reset password and forgot password flow - need to explicit login with sign in policy - since b2c won't switch default policy used without that
          console.warn('Authority for reset password and forgot password flow not cached!')
        }
        this.redirectToLoginRedirectPage()
      })
  }
  triggerResetPasswordFlow = () => {
    if (!this.appConfig.config.USE_AUTH) {
      return
    }
    this.log(`Triggering login redirect with reset password policy.`)
    const accountToUse = this.msal?.getAccountByHomeId(this.homeAccountId ?? '') ?? undefined
    this.resetPassword.account = accountToUse
    this.msal?.loginRedirect(this.resetPassword)
  }
  triggerEditProfile = () => {
    if (!this.appConfig.config.USE_AUTH) {
      return
    }
    const accountToUse = this.msal?.getAccountByHomeId(this.homeAccountId ?? '') ?? undefined
    this.editProfile.account = accountToUse
    this.msal?.loginRedirect(this.editProfile)
  }
  triggerSignUpProfile = () => {
    if (!this.appConfig.config.USE_AUTH) {
      return
    }
    // console.log('this.authOptions', this.authOptions)
    // If we're on a public page, the auth service won't be instantiated until the user invokes a user flow
    this.init()
    // Clear any storage of previous interactions
    localStorage.clear();
    sessionStorage.clear();
    // Clear any msal cache
    this.clearMsalCache()
    // If signed in user wants to sign up we must clear their logged in msal state
    let msalInstance: Msal.PublicClientApplication = this.msal as Msal.PublicClientApplication
    const account = this.msal?.getActiveAccount()
    // console.log(`account during sign up`, account)
    const msalBrowserCache = msalInstance['browserStorage']
    // console.log('msalBrowserCache', msalBrowserCache)
    //In case the user clicked sign up and then cancelled and clicks sign up again
    //Set the authority in the msal instance
    try {
      this.msal?.loginRedirect(this.signUpProfile)
    } catch (e) {
      setTimeout(() => {
        // console.log(`cautch sign up error`, e)
        //If the users bails on a log in and attempts to sign up, right after the first login redirect will fail
        // this.msal?.loginRedirect(this.signUpProfile)
      }, 100)
    }
  }
  handlePerformance: Msal.PerformanceCallbackFunction = (events: Msal.PerformanceEvent[]): void => {
    events.forEach((event: Msal.PerformanceEvent) => {
      if (!event.success) {
        console.warn(`Failed msal event`, event)
      } else {
        this.log(`Successful msal event`)
        let stringifiedEvent = 'Not serializable'
        try {
          stringifiedEvent = JSON.stringify(event)
        } catch (e) {
          return
        }
        this.log(JSON.stringify(stringifiedEvent))
      }
    })
  }
  //#endregion

  //Auth Related Checks
  //#region
  /** Exists to only check for token, to determine if we need to check for custom server authorization error messages */
  userHasToken = (): boolean => {
    let isAuthed = this._token !== ''
    this.log(`Auth: Authed check returns`)
    this.log(`${isAuthed}`)
    return isAuthed
  }
  //#endregion

  //INTERNAL IMPLEMENTATION
  //Token
  async getAccessTokenPromise(): Promise<string> {
    try {
      this.log(`Starting getAccessTokenPromise`)
      const response: Msal.AuthenticationResult | undefined = await this.msal?.acquireTokenSilent(
        this.tokenRequest
      )
      if (response?.accessToken) {
        this.log(`Ending getAccessTokenPromise`)
        this.log(response?.accessToken)
        this.setToken(response?.accessToken)
      } else {
        this.log(`Ending getAccessTokenPromise with no token returned`)
        this.log(`${response?.accessToken}`)
      }
      return response?.accessToken || ''
    } catch (e) {
      console.error(e)
      return ''
    }
  }
  startGetTokenFlow = () => {
    this.log(`Auth Service: startGetTokenFlow with acquire token silent with token request`)
    //No home account id when using azure ad with msal so use that as a condition
    if (this.homeAccountId) {
      this.tokenRequest.account =
        this.msal?.getAccountByHomeId(this.homeAccountId ?? '') ?? undefined
    }
    this.msal
      ?.acquireTokenSilent(this.tokenRequest)
      .then(this.acquireTokenSilentSuccess)
      .catch(this.acquireTokenSilentFailure)
  }
  acquireTokenSilentSuccess = (response: Msal.AuthenticationResult): void => {
    this.log(`Auth: Received token from provider, response`)
    //Commented this one out as it's a lot of content to put into the console
    // use for local dev / troubleshooting
    this.log(JSON.stringify(response))
    if (response.accessToken) {
      this.dispatchToken(response.accessToken)
    } else {
      throw Error("Web App registrations is misconfigured and won't return access tokens.")
    }
  }
  dispatchToken = (t: string) => {
    this.setToken(t)
    this.emitAuthStateIsTrue()
    this.store.dispatch(tokenReceived(getExtendedAction(t)))
  }
  acquireTokenSilentFailure = (err: BrowserAuthError): void => {
    if (
      //This causes a continual failure loop so check if it's happening an short circuit the infinite loop - Figure out why it would throw sso error if it's not triggered
      err.errorCode !== MsalAcquireTokenSilentErrorCodes.SILENT_SSO_ERROR &&
      err.errorCode !== MsalAcquireTokenSilentErrorCodes.NO_ACCOUNT_ERROR &&
      err.errorCode !== MsalAcquireTokenSilentErrorCodes.INVALID_CLIENT
    ) {
      //TODO remove admin consent to app registration and check which error code comes back to prevent infinite loop
      this.msal?.acquireTokenRedirect(this.tokenRequest)
    } else {
      console.error(err)
    }
  }

  emitAuthStateIsTrue = (): void => {
    this.log(`Emitting auth state completed with value: true`)
    localStorage.setItem(
      LocalStorageKeys.authStateChangeActionKey,
      CrossBrowserStateIndicator.FullyAuthenticated
    )
    this._authStateSubject?.next(true)
    this._authStateSubject?.complete()
  }
  handleStorageAuthStateChange = (
    //https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent
    storageEvent: StorageEvent
  ): boolean | undefined => {
    if (storageEvent.key === LocalStorageKeys.authStateChangeActionKey) {
      const { newValue } = storageEvent
      const userIsFullyAuthed = this.userHasToken()
      if (newValue === CrossBrowserStateIndicator.LoggingOut && userIsFullyAuthed) {
        this.log(
          `handleStorageAuthStateChange browser received log out call from other tab, triggering logout in this tab`
        )
        this.logout()
        return true
      } else if (newValue === CrossBrowserStateIndicator.FullyAuthenticated && !userIsFullyAuthed) {
        this.log(
          `handleStorageAuthStateChange browser received fully authed event from other tab, triggering startAuthFlow`
        )
        this.redirectToLoginRedirectPage()
        return true
      }
      this.log(
        `handleStorageAuthStateChange browser received unhandlable auth event from other tab, returning false to indicate no action taken`
      )
      return false
    }
    return undefined
  }

  /** All User Type - Auth Based Redirects */
  redirectToLoginRedirectPage = () => {
    this.log(`Navigating to auth login page`)
    this.store.dispatch(redirectAngularRouter(getExtendedAction(`/${OwlRoutes.login}`)))
  }
  loggedOutPage = `${FirstLevelDir.auth}/${SecondLevelDir.loggedOut}`
  loggedInPage = `${FirstLevelDir.auth}/${SecondLevelDir.loggedIn}`
  publicWelcomePage = `${FirstLevelDir.public}/${SecondLevelDir.welcome}`

  /**
   * When user authenticates redirect them to the logged in page which will handle either redirecting the user to their saved initial page request or their default home page (based on user type) if we don't have an initial request.
   */
  redirectToLoggedInPage = () => {
    this.log(
      `Navigating to auth logged in page, to redirect based on saved page, default home page, and user type.`
    )
    this.store.dispatch(redirectAngularRouter(getExtendedAction(this.loggedInPage)))
  }
  redirectToPublicWelcomePage = () => {
    //Go to public version of app on local host to port and on remove app subdomain on non local host
    if (isLocalHost()) {
      const newLocation = `http://${localHostText}:${LocalHostPorts.Public}`
      window.location.href = newLocation
    } else {
      //Remove app. from subdomain list
      const newHost = window.location.host.replace(authenticatedAppSubdomain, '')
      const newLocation = `${window.location.protocol}${newHost}`
      window.location.href = newLocation
    }
  }
  redirectToWelcomePage = () => {
    this.log(`Navigating to public welcome page`)
    this.store.dispatch(redirectAngularRouter(getExtendedAction(this.publicWelcomePage)))
  }
  //#endregion
  shouldLog = false
  log = (message: string) => {
    if (isLocalHost() && this.shouldLog) {
      console.log(message)
    }
    // TODO implement logger using app insights
    // this.logger.log(message)
  }
  /** Our public and authenticated version of the apps are on different ports on local host and on different subdomains in deploy instances, hence we need to send a user to that location. */
  redirectToAuthVersionOfApp = (route: SecondLevelDir | null = null) => {
    //If on local host redirect to same domain but different port
    if (isLocalHost()) {
      let newLocation = `http://${localHostText}:${LocalHostPorts.Auth}`
      console.log("newLocation", newLocation)
      if (route) {
        newLocation = this.addCreateAccountPath(newLocation, route)
        console.log("newLocation", newLocation)
      }
      window.location.href = newLocation
    } else {
      //Prepend the subdomain to the current domain, using authenticatedAppSubdomain
      let newLocation = `${window.location.protocol}//${authenticatedAppSubdomain}.${window.location.host}`
      if (route) {
        newLocation = this.addCreateAccountPath(newLocation, route)
      }
      console.log("newLocation", newLocation)
      window.location.href = newLocation
    }
  }
  /** To support create account redirect we have to direct the user to a specific sub auth path and handle it on auth app page load. */
  addCreateAccountPath = (newLocation: string, path: SecondLevelDir) => {
    newLocation += `/${FirstLevelDir.auth}/${path}`
    return newLocation
  }
  /** Reuses the redirect to auth version of app but adds a hash to be used to later redirect to create account. */
  redirectToCreateAccountVersionOfApp =
    () => {
      this.redirectToAuthVersionOfApp(SecondLevelDir.createAccount)
    }
}
