import { ExtendedAction } from '@action/extended-ngrx-action'
import { LocationDtoHelper } from '@domain/dto-helpers/location-model.helper'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { FullLocation } from '@model/location.model'
import { ChatMessageDtoPayload, ChatResponseDtoPayload } from '@model/message.model'
import { ResponseTypeEnum } from '@model/message/predefined-message.model'
import { ResponseGroupTypeEnum } from '@model/message/response-group.model'
import {
  DecoratedUserLocationDefaults,
  DecoratedUserLocationViewModel,
  UserLocationDto
} from '@model/user/user-location.model'
import { GetUserDto, MobileUserTypes } from '@model/user/user.model'
import { TimeUtils } from '@shared/time.utils'
import { ApiState } from '@state/api.state'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { StatusColorEnum } from '@view/area/area.view'
import {
  RecentMessageIndicatorTypeEnum,
  RecentMessageViewModel
} from '@view/area/graphic-attributes.model'
;``
export class UserLocationViewModel {
  static handleReceivedUserLocation = (
    s: DashboardPageState,
    a: ExtendedAction<UserLocationDto>
  ): DashboardPageState => {
    let decoratedUserLocation = UserLocationViewModel.getDecoratedUserLocationVm(s, a.payload)
    if (!decoratedUserLocation) {
      console.warn(`Received location for unknown user for 
            school id ${s.selectedSchoolId.id}, 
            mobile user id ${a.payload.mobileUserId}`)
      return s
    }
    return {
      ...s,
      userLocationLookup: {
        ...s.userLocationLookup,
        [a.payload.mobileUserId]: decoratedUserLocation
      }
    }
  }

  //Historic Locations API State
  static handleGetHistoricUserLocations = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      userLocationsHistoricDataApiState: ApiState.createIsLoadingState()
    }
  }
  static handleGetHistoricUserLocationsError = (s: DashboardPageState): DashboardPageState => {
    return {
      ...s,
      userLocationsHistoricDataApiState: ApiState.createHasErrorState()
    }
  }
  static handleReceivedHistoricUserLocations = (
    s: DashboardPageState,
    a: ExtendedAction<UserLocationDto[]>
  ): DashboardPageState => {
    let newUserLocationLookup: Record<string, DecoratedUserLocationViewModel> = {}
    a.payload.forEach((userLocation) => {
      const decoratedUserLocation = UserLocationViewModel.getDecoratedUserLocationVm(
        s,
        userLocation
      )
      if (decoratedUserLocation) {
        newUserLocationLookup[userLocation.mobileUserId] = decoratedUserLocation
      }
    })
    return {
      ...s,
      userLocationsHistoricDataApiState: ApiState.createHadLoadedState(),
      userLocationLookup: {
        ...s.userLocationLookup,
        ...newUserLocationLookup
      }
    }
  }
  /** Checks the dto for a location and if it has a location with a later timestamp we update the user's location view model, otherwise we return null as no update is necessary. */
  static getDecoratedUserLocationVm = (
    s: DashboardPageState,
    dto: UserLocationDto
  ): DecoratedUserLocationViewModel | null => {
    const userLocationVm = s.userLocationLookup[dto.mobileUserId]
    if (!userLocationVm) {
      console.warn(`No location view model found for user that sent new location dto id: ${dto.id}`)
      return null
    }
    if (LocationDtoHelper.shouldUpdateLocation(userLocationVm, dto)) {
      return {
        ...userLocationVm,
        latLong: dto.latLong,
        locTimeStamp: TimeUtils.getDateFromString(dto.timestamp)
      }
    }
    return userLocationVm
  }
  /** Handles SERT responses to update the location view model
   * 1. If chat message then clear nested dto and recent message indicator
   * 2 and 3. If message with poll indicator remove the message portion of the indicator and keep the related poll indicator
   */
  static getDecoratedUserLocationFromChatMessage = (
    existingLocationVm: DecoratedUserLocationViewModel
  ): DecoratedUserLocationViewModel => {
    if (
      existingLocationVm.recentMessageIndicatorType === RecentMessageIndicatorTypeEnum.chatMessage
    ) {
      return {
        ...existingLocationVm,
        chatResponseDtoPayload: null,
        recentMessageIndicatorType: RecentMessageIndicatorTypeEnum.unknown
      }
    } else if (
      RecentMessageViewModel.isPollWithMessage(existingLocationVm.recentMessageIndicatorType)
    ) {
      return {
        ...existingLocationVm,
        recentMessageIndicatorType: RecentMessageViewModel.getPollFromPollWithMessage(
          existingLocationVm.recentMessageIndicatorType
        )
      }
    } else {
      return existingLocationVm
    }
  }
  static getDecoratedUserLocationFromClearingStaleData = (
    s: DashboardPageState,
    existingLocationVm: DecoratedUserLocationViewModel | null,
    d: Date
  ): DecoratedUserLocationViewModel | null => {
    if (!existingLocationVm?.mobileUserId) {
      return null
    }
    let [rulesBasedPayload, userStatusColor, text, recentMessageType, latLong] =
      UserLocationViewModel.provideDtoByPriorityRules(
        existingLocationVm.mobileUserId,
        existingLocationVm,
        null,
        s,
        d
      )
    //     console.log(`Clearing stale data rulesBasedPayload
    // value ${JSON.stringify(rulesBasedPayload)}
    //       `)
    let decoratedUserLocation: DecoratedUserLocationViewModel = {
      ...existingLocationVm,
      oldestConsideredDtoTimestamp: rulesBasedPayload
        ? TimeUtils.getDateFromString(rulesBasedPayload.timestamp)
        : existingLocationVm.oldestConsideredDtoTimestamp,
      mobileUserId: existingLocationVm.mobileUserId,
      latLong,
      statusColor: rulesBasedPayload ? userStatusColor : StatusColorEnum.unknown,
      statusMessage: rulesBasedPayload ? text : null,
      chatResponseDtoPayload: rulesBasedPayload,
      schoolId: s.selectedSchoolId.id,
      recentMessageIndicatorType: recentMessageType
    }
    return decoratedUserLocation
  }
  /** Handles any validation for building a user location vm and returns null if anything fails
   */
  static getDecoratedUserLocationFromChatResponse = (
    s: DashboardPageState,
    existingLocationVm: DecoratedUserLocationViewModel | null,
    payload: ChatResponseDtoPayload,
    d?: Date
  ): DecoratedUserLocationViewModel | null => {
    // Can't cosntruct a location for a chat repsonse payload without a mobile user id
    const { mobileUserId, latLong: newDtoLatLong } = payload
    if (!mobileUserId) {
      console.warn(`Received chat response dto payload without a mobile user id!`)
      return existingLocationVm
    }
    // TODO Consider using alerts/messages/poll responses from uses that haven't enabled location data - if so apply below logic to new view model without updating location
    if (!newDtoLatLong) {
      console.info(
        `Received chat response dto without a location, not updating users current location.`
      )
      return existingLocationVm
    }
    if (!existingLocationVm) {
      console.error(`Received chat response for user unknown user!`)
      return null
    }
    let newLocationTimestamp = TimeUtils.getDateFromString(payload.timestamp)

    let [rulesBasedPayload, userStatusColor, text, recentMessageType, latLong] =
      UserLocationViewModel.provideDtoByPriorityRules(
        mobileUserId,
        existingLocationVm,
        payload,
        s,
        d
      )
    let decoratedUserLocation: DecoratedUserLocationViewModel = {
      ...existingLocationVm,
      // Always preserve the first date we use to any recent message indicator logic as long as there's a nested dto, if not clear it.
      oldestConsideredDtoTimestamp:
        rulesBasedPayload && existingLocationVm.oldestConsideredDtoTimestamp
          ? existingLocationVm.oldestConsideredDtoTimestamp
          : TimeUtils.getDateFromString(rulesBasedPayload?.timestamp),
      mobileUserId,
      statusColor: rulesBasedPayload ? userStatusColor : StatusColorEnum.unknown,
      statusMessage: rulesBasedPayload ? text : null,
      chatResponseDtoPayload: rulesBasedPayload,
      schoolId: s.selectedSchoolId.id,
      recentMessageIndicatorType: rulesBasedPayload
        ? recentMessageType
        : RecentMessageIndicatorTypeEnum.unknown
    }
    if (LocationDtoHelper.shouldUpdateLocationFromMessageDto(existingLocationVm, payload)) {
      decoratedUserLocation.latLong = newDtoLatLong
      decoratedUserLocation.locTimeStamp = newLocationTimestamp
    }
    return decoratedUserLocation
  }
  /** When we find out a user registered, we want to build a location view model in preperation for any future location or message update. */
  static getLocationVmFromUserDto = (dto: GetUserDto): DecoratedUserLocationViewModel | null => {
    if (!dto.mobileId) {
      console.error(`Dev error! Get User Dto missing mobile id.`)
      return null
    }
    return {
      oldestConsideredDtoTimestamp: null,
      mobileUserId: dto.mobileId,
      chatResponseDtoPayload: null,
      // Discuss possibility of adding location to user dto for mobile user onboarding
      latLong: null,
      locTimeStamp: null, //Location timestamp added from location or message dto only
      // Discuss need of this data in the location view model
      schoolId: dto.schoolIds[0].id,
      email: dto.email,
      phone: dto.phone,
      fullName: UserDtoHelper.getFullName(dto),
      statusColor: StatusColorEnum.unknown,
      statusMessage: null,
      userType: dto.mobileType ?? MobileUserTypes.unknown,
      recentMessageIndicatorType: RecentMessageIndicatorTypeEnum.unknown
    }
  }
  /** Function provides logic for dealing with one new chat response dto payload at a time,
   * in the context of our old location view model.
   *
   * @pram currentLocationVm NOTE: Only reason currentLocationVm: DecoratedUserLocationViewModel | null is nullable is for defensive code practice in the extremely unlikely case that we get a message or location before we get the user registered information. Perhaps due to network failure or an error in code.
   * @param d optional date to filter any old replacement dtos
   * */
  static provideDtoByPriorityRules = (
    mobileUserId: string,
    currentLocationVm: DecoratedUserLocationViewModel | null,
    payload: ChatResponseDtoPayload | null,
    s: DashboardPageState,
    d?: Date
  ): [
    ChatResponseDtoPayload | null,
    StatusColorEnum,
    string | null,
    RecentMessageIndicatorTypeEnum,
    FullLocation | null
  ] => {
    let newPayloadByRules: ChatResponseDtoPayload | null = null
    let newLatLongByRules: FullLocation | null = null
    let recentMessageIndicator = RecentMessageIndicatorTypeEnum.unknown

    const newPayloadIsMedicalAlert =
      payload &&
      ChatMessageDtoHelper.isChatResponseMedicalAlertPayload(payload, s.responseIdToGetResponseDto)
    const newPayloadIsAlert = payload && ChatMessageDtoHelper.isChatResponseAlertPayload(payload, s.responseIdToGetResponseDto)
    const newPayloadIsNonAlertPollResponse =
      payload &&
      ChatMessageDtoHelper.isChatResponseNonAlertResponseIdPayload(
        payload,
        s.responseIdToGetResponseDto
      )
    const newPayloadIsMessageResponse =
      payload && ChatMessageDtoHelper.isChatResponseWithMessageOnlyPayload(payload)

    //RULES ABOUT PRIORITY OF MESSAGES

    // old view model versus current payload section
    // If there is no nested dto automatically apply the passed one
    if (currentLocationVm?.chatResponseDtoPayload === null) {
      newPayloadByRules = payload
    } else if (
      // If the new payload is an alert and the old view model had a non alert it overrides the nested dto and indicator logic
      (currentLocationVm &&
        newPayloadIsAlert &&
        //Only allow override on non alert responses
        currentLocationVm.chatResponseDtoPayload?.isSos !== true) ||
      // Medical alerts and regular alerts are treated with the same priority so allow override of payload if it's a medical alert
      newPayloadIsMedicalAlert
    ) {
      // console.log(
      //   `ALERT PAYLOAD PROCESSING: ${s.userLocationLookup[mobileUserId].fullName} Overriding location view model nested chat dto
      //   mobile id ${mobileUserId}
      //   old kind ${currentLocationVm.recentMessageIndicatorType}
      //   `
      // )
      newPayloadByRules = payload
    } else if (
      // If the new payload is a poll response and the old view model has a chat message indicator, override the nested dto and indicator logic
      // TODO // Don't allow poll responses override
      // alertscurrentLocationVm.recentMessageIndicatorType !== RecentMessageIndicatorTypeEnum.medicalAlert
      (currentLocationVm &&
        currentLocationVm.chatResponseDtoPayload &&
        newPayloadIsNonAlertPollResponse &&
        // Allow override on existing poll responses or message, or alerts if the poll response is green only
        (ChatMessageDtoHelper.isChatResponseWithResponseIdNonAlertPayload(
          currentLocationVm.chatResponseDtoPayload,
          s.responseIdToGetResponseDto
        ) ||
          ChatMessageDtoHelper.isChatResponseWithMessageOnlyPayload(
            currentLocationVm?.chatResponseDtoPayload
          ))) ||
      ChatMessageDtoHelper.newPayloadIsGreenPollResponseWhileOtherPayloadIsAlert(
        s,
        payload,
        currentLocationVm?.chatResponseDtoPayload,
      )
    ) {
      // console.log(
      //   `POLL RESPONSE PAYLOAD PROCESSING:${s.userLocationLookup[mobileUserId].fullName} Overriding location view model nested chat dto
      //   mobile id ${mobileUserId}
      //   old kind ${currentLocationVm.recentMessageIndicatorType}
      //   `
      // )
      newPayloadByRules = payload
    } else if (
      // If the current dto is a message we don't need to check because this execution is assumed to be chronological, in that any old location view model nested chat message dto should be older than the new payload we're processing, either in 1. historic chat message or when 2. we receive real time message via signalr
      newPayloadIsMessageResponse &&
      currentLocationVm &&
      currentLocationVm?.chatResponseDtoPayload &&
      // Only accept new chat message when last chat dto was also a chat message - since we preserve poll responses and alerts over chat messages
      ChatMessageDtoHelper.isChatResponseWithMessageOnlyPayload(
        currentLocationVm?.chatResponseDtoPayload
      )
    ) {
      // console.log(
      //   `MESSAGE PAYLOAD PROCESSING: ${s.userLocationLookup[mobileUserId].fullName}Overriding location view model nested chat dto
      //   mobile id ${mobileUserId}
      //   old kind ${currentLocationVm.recentMessageIndicatorType}

      //   `
      // )
      newPayloadByRules = payload
    } else if (currentLocationVm?.chatResponseDtoPayload) {
      //If date filter is passed to rules check before keeping original nested dto
      if (d) {
        const preserveDataTimestampDate = TimeUtils.getDateFromString(
          currentLocationVm?.chatResponseDtoPayload.timestamp
        )
        //Check to make sure
        if (preserveDataTimestampDate && d.getTime() < preserveDataTimestampDate.getTime()) {
          // console.log(
          //   `PAYLOAD PRESERVING: ${
          //     s.userLocationLookup[currentLocationVm?.chatResponseDtoPayload.mobileUserId!].fullName
          //   }Retaining location view model nested chat dto
          //     mobile id ${mobileUserId}
          //     current location vm ${currentLocationVm.recentMessageIndicatorType}
          //     d ${d.toISOString()}
          //     preserveDataTimestampDate ${preserveDataTimestampDate?.toISOString()}
          //     `
          // )
          newPayloadByRules = currentLocationVm?.chatResponseDtoPayload
        } else {
          newPayloadByRules = null
        }
      } else {
        // console.log(
        //   `PAYLOAD PRESERVING: ${
        //     s.userLocationLookup[currentLocationVm?.chatResponseDtoPayload.mobileUserId!].fullName
        //   }Retaining location view model nested chat dto
        //    d ${d}
        //     mobile id ${mobileUserId}
        //     current location vm ${currentLocationVm.recentMessageIndicatorType}
        //     preserveDataTimestampDate ${currentLocationVm?.chatResponseDtoPayload.timestamp}
        //     `
        // )
        // If no overrides apply then just keep the old one to simplify below logic
        newPayloadByRules = currentLocationVm?.chatResponseDtoPayload
      }
    }
    //Change the kind of recent message marker if the user sends a chat after responding to a poll
    let usersLastAlertResponse: ChatResponseDtoPayload | null =
      s.userMobileIdToAlerts[mobileUserId]?.at(-1) ?? null
    //Check if alert should be considered a poll
    let usersLastPollResponse: ChatResponseDtoPayload | null = null
    const responseToCheck = s.userMobileIdToLastPollResponseLookup[mobileUserId] ?? null
    if (responseToCheck && s.responseIdsByResponseGroupTypeLookup) {
      //Check if it's an alert type of poll response
      const allAlertResponseIds =
        s.responseIdsByResponseGroupTypeLookup[ResponseGroupTypeEnum.alert] ?? []
      const lastPollResponseIsAlert = allAlertResponseIds.map(r => r.id).includes(responseToCheck.responseId ?? 0)
      // console.log(`last poll response is alert`, lastPollResponseIsAlert)
      usersLastPollResponse = lastPollResponseIsAlert ? null : responseToCheck
    }
    let usersLastMessageResponse: ChatResponseDtoPayload | null =
      s.userMobileIdToLastMessageResponseLookup[mobileUserId] ?? null
    let usersLastSertMessageResponse: ChatMessageDtoPayload | null =
      s.userMobileIdToLastSertMessageLookup[mobileUserId] ?? null
    //If we get a date into this function this means we're removing data by this date criteria so assign to new payload by rules
    //TODO Refactor into utility function
    if (d) {
      if (usersLastAlertResponse) {
        const dateOfLastAlert = TimeUtils.getDateFromString(usersLastAlertResponse.timestamp)
        if (dateOfLastAlert && dateOfLastAlert.getTime() < d.getTime()) {
          // console.log(`Clearing stale last alert response from options on filter by date`)
          usersLastAlertResponse = null
        }
      }
      if (usersLastPollResponse) {
        const dateOfLastPollResponse = TimeUtils.getDateFromString(usersLastPollResponse.timestamp)
        if (dateOfLastPollResponse && dateOfLastPollResponse.getTime() < d.getTime()) {
          // console.log(`Clearing stale last poll response from options on filter by date`)
          usersLastPollResponse = null
        }
      }
      if (usersLastSertMessageResponse) {
        const dateOfLastSertMessage = TimeUtils.getDateFromString(
          usersLastSertMessageResponse.timestamp
        )
        if (dateOfLastSertMessage && dateOfLastSertMessage.getTime() < d.getTime()) {
          // console.log(`Clearing stale last sert member response from options on filter by date`)
          usersLastSertMessageResponse = null
        }
      }
      if (usersLastMessageResponse) {
        const dateOfLastAlert = TimeUtils.getDateFromString(usersLastMessageResponse.timestamp)
        if (dateOfLastAlert && dateOfLastAlert.getTime() < d.getTime()) {
          // console.log(`Clearing stale last message response from options on filter by date`)
          usersLastMessageResponse = null
        }
      }
      //Now that the available next items are set apply rules
      if (usersLastAlertResponse) {
        newPayloadByRules = usersLastAlertResponse
      } else if (usersLastPollResponse) {
        newPayloadByRules = usersLastPollResponse
      } else if (usersLastMessageResponse) {
        newPayloadByRules = usersLastMessageResponse
      } else {
        newPayloadByRules = null
      }
    }
    // console.log(`newPayloadByRules after rules`)
    // console.log(newPayloadByRules)

    let userPollWithMessageVersion = false

    const { recentMessageIndicatorType: t } = currentLocationVm ?? {}
    //If we're processing a chat message after a poll or before a poll, apply poll with message logic
    if (
      (newPayloadIsMessageResponse &&
        (RecentMessageViewModel.isPollWithoutMessage(t) ||
          RecentMessageViewModel.isPollWithMessage(t))) ||
      (currentLocationVm?.recentMessageIndicatorType ===
        RecentMessageIndicatorTypeEnum.chatMessage &&
        newPayloadIsNonAlertPollResponse) ||
      (d === undefined && RecentMessageViewModel.isPollWithMessage(t)) ||
      (d && usersLastMessageResponse && RecentMessageViewModel.isPollWithoutMessage(t))
    ) {
      userPollWithMessageVersion = true
    }

    //If the new indicator is an alert and there's a more recent green poll response, override the alert
    let greenPollAfterAlert = false
    if (newPayloadByRules?.isSos && usersLastPollResponse && usersLastPollResponse.responseId) {
      const statusOfMostRecentPollResponse =
        s.responseIdToStatusColorEnum[usersLastPollResponse.responseId] ?? null
      const dateOfMostResentPollResponse = TimeUtils.getDateFromString(
        usersLastPollResponse.timestamp
      )
      const alertOfNewPayloadAlert = TimeUtils.getDateFromString(newPayloadByRules.timestamp)
      if (
        alertOfNewPayloadAlert &&
        dateOfMostResentPollResponse &&
        statusOfMostRecentPollResponse === StatusColorEnum.green &&
        alertOfNewPayloadAlert.getTime() < dateOfMostResentPollResponse.getTime()
      ) {
        greenPollAfterAlert = true
        newPayloadByRules = usersLastPollResponse
      }
    }

    const responseIdToUse = newPayloadByRules?.responseId ?? null
    // TODO Move to function in location status view model and remove deprecated fields to opt for better single object
    let userStatusColor = StatusColorEnum.unknown
    let statusText: string | null = null
    if (responseIdToUse) {
      userStatusColor = s.responseIdToStatusColorEnum[responseIdToUse] ?? StatusColorEnum.unknown
      statusText = s.responseIdToGetResponseDto[responseIdToUse]?.text ?? null
    }

    //If the new payload is a chat message, check for more recent SERT responses
    let userHasMoreRecentSertResponse = false
    if (
      usersLastSertMessageResponse &&
      newPayloadByRules?.message &&
      !newPayloadByRules.isSos &&
      newPayloadByRules.responseId === null
    ) {
      const dateOfNewMessagePayload = TimeUtils.getDateFromString(newPayloadByRules.timestamp)
      const dateOfSertResponse = TimeUtils.getDateFromString(usersLastSertMessageResponse.timestamp)
      if (
        dateOfNewMessagePayload &&
        dateOfSertResponse &&
        dateOfNewMessagePayload.getTime() < dateOfSertResponse.getTime()
      ) {
        // console.log(`Overriding recent message indicator due to SERT response`)
        userHasMoreRecentSertResponse = true
      }
    }

    //Now update the recent message indicator
    const isMedicalAlert = ChatMessageDtoHelper.isChatResponseMedicalAlertPayload(
      newPayloadByRules,
      s.responseIdToGetResponseDto
    )
    const isAlert = newPayloadByRules?.isSos
    const isRedStatus = userStatusColor === StatusColorEnum.red
    const isYellowStatus = userStatusColor === StatusColorEnum.yellow
    // TODO Override alert with green
    const isGreenStatus = userStatusColor === StatusColorEnum.green
    // Check everywhere to make sure it's always unknown or null and never undefined
    const hasNoStatus =
      userStatusColor === null ||
      userStatusColor === undefined ||
      userStatusColor === StatusColorEnum.unknown
    const newPayloadIsMessageType =
      !!newPayloadByRules?.message &&
      newPayloadByRules?.responseId === null &&
      newPayloadByRules?.isSos !== true

    if (greenPollAfterAlert) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.greenPoll
      userStatusColor = StatusColorEnum.green
      statusText =
        s.responseIdToGetResponseDto[usersLastPollResponse?.responseId ?? 0]?.text ?? null
    } else if (isMedicalAlert) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.medicalAlert
    } else if (isAlert) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.alert
    } else if (newPayloadIsMessageType && hasNoStatus && !userHasMoreRecentSertResponse) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.chatMessage
    } else if (userPollWithMessageVersion && isRedStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.redWithMessagePoll
    } else if (userPollWithMessageVersion && isYellowStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.yellowWithMessagePoll
    } else if (userPollWithMessageVersion && isGreenStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.greenWithMessagePoll
    } else if (isRedStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.redPoll
    } else if (isYellowStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.yellowPoll
    } else if (isGreenStatus) {
      recentMessageIndicator = RecentMessageIndicatorTypeEnum.greenPoll
    }

    //Use existing location if new object has no location
    if (!newPayloadByRules && currentLocationVm?.latLong) {
      newLatLongByRules = currentLocationVm?.latLong
    }
    // if (currentLocationVm?.mobileUserId === 'e4d3061-a64d-e28b-09bb-768b4') {
    //   console.log(`LOCATION VM BY RULES
    // userStatusColor ${userStatusColor}
    // statusText ${statusText}
    //   recentMessageIndicator ${recentMessageIndicator}
    //   d ${d}
    //   userPollWithMessageVersion ${userPollWithMessageVersion}

    //   usersLastAlertResponse ${JSON.stringify(usersLastAlertResponse)}

    //   usersLastPollResponse ${JSON.stringify(usersLastPollResponse)}

    //   usersLastMessageResponse ${JSON.stringify(usersLastMessageResponse)}

    //   name ${s.mobileIdToDisplayNameLookup[currentLocationVm?.mobileUserId ?? '']}
    //   `)
    // console.log(`newPayloadByRules`)
    // console.log(newPayloadByRules)
    // }
    return [
      newPayloadByRules,
      newPayloadByRules ? userStatusColor : StatusColorEnum.unknown,
      newPayloadByRules ? statusText : '',
      newPayloadByRules ? recentMessageIndicator : RecentMessageIndicatorTypeEnum.unknown,
      newLatLongByRules
    ]
  }
  static getConditionalNewPayloadOnFilter = (
    payload: ChatResponseDtoPayload,
    vm: DecoratedUserLocationViewModel,
    s: DashboardPageState
  ): DecoratedUserLocationViewModel => {
    let newVm = {
      ...vm
    }
    //Apply stale message replacement in location view model

    //TODO Remove this since we're clearing the payload either way, we don't need to know what of the three types it is, just it's id
    // TODO remove passed input and replace with id only to narrow function purpose
    // const oldPayloadIsSos = ChatMessageDtoHelper.isChatResponseAlertPayload(payload)
    // const oldPayloadIsPollResponse =
    //   ChatMessageDtoHelper.isChatResponseNonAlertResponseIdPayload(payload)
    // const oldPayloadIsMessageResponse =
    //   ChatMessageDtoHelper.isChatResponseWithMessageNonAlertPayload(payload)

    const lastUserChatPollResponse = s.userMobileIdToLastPollResponseLookup[vm.mobileUserId] ?? null
    const lastUserChatMessage: ChatResponseDtoPayload | null =
      s.userMobileIdToLastMessageResponseLookup[vm.mobileUserId] ?? null
    const lastUserAlert: ChatResponseDtoPayload | null =
      s.userMobileIdToAlerts[vm.mobileUserId]?.at(-1) ?? null
    const { responseId } = lastUserChatPollResponse ?? {}
    let userStatusColor = StatusColorEnum.unknown
    let statusText: string | null = null
    if (responseId) {
      userStatusColor = s.responseIdToStatusColorEnum[responseId] ?? StatusColorEnum.unknown
      statusText = s.responseIdToGetResponseDto[responseId]?.text ?? null
    }

    //First if the payload we're removing isn't the last alert saved override it in the view model
    if (lastUserAlert !== null && payload.logicalId !== lastUserAlert.logicalId) {
      // console.log(`getConditionalNewPayloadOnFilter overwriting to last user alert`)
      newVm.chatResponseDtoPayload = lastUserAlert
    } else if (
      //Then if the last poll response exists check that
      lastUserChatPollResponse !== null &&
      payload.logicalId !== lastUserChatPollResponse.logicalId
    ) {
      // console.log(`getConditionalNewPayloadOnFilter overwriting to last user poll response`)
      newVm.chatResponseDtoPayload = lastUserChatPollResponse
      newVm.statusColor = userStatusColor
      newVm.statusMessage = statusText
    } else if (
      //Then if the last message response exists check that
      lastUserChatMessage !== null &&
      payload.logicalId !== lastUserChatMessage.logicalId
    ) {
      // console.log(`getConditionalNewPayloadOnFilter overwriting to last user message`)
      newVm.chatResponseDtoPayload = lastUserChatMessage
    } else {
      //Otherwise there's nothing in history to display so we can clear the location vm
      // console.log(`getConditionalNewPayloadOnFilter clearing message as nothing exists for user`)
      newVm = UserLocationViewModel.resetDecoratedUserLocation(newVm)
    }
    return newVm
  }
  /** When concluding an event we want to clear out any user location status view models */
  static resetDecoratedUserLocation = (
    decoratedUserLocation: DecoratedUserLocationViewModel
  ): DecoratedUserLocationViewModel => {
    return {
      ...decoratedUserLocation,
      ...new DecoratedUserLocationDefaults()
    }
  }
}
