import { ExtendedAction } from '@action/extended-ngrx-action'
import { HttpErrorResponse } from '@angular/common/http'
import { ChatMessageDto } from '@model/message.model'
import { UserLocationDto } from '@model/user/user-location.model'
import { createAction, props } from '@ngrx/store'

export enum MessageApiAction {
  chatMessagesSuccess = '[MessageApiAction] Get Messages Success',
  chatMessagesError = '[MessageApiAction] Get Messages Error',

  sendMessageSuccess = '[MessageApiAction] Send Message Success',
  sendMessageError = '[MessageApiAction] Send Message Error',

  getHistoricUserLocationsSuccess = '[MessageApiAction] Get Historic User Locations Success',
  getHistoricUserLocationsError = '[MessageApiAction] Get Historic User Locations Error',

  sendCheckStatusSuccess = '[MessageApiAction] Send Check Status Success',
  sendCheckStatusError = '[MessageApiAction] Send Check Status Error'
}

//CHAT
/** This api endpoint is invoked and resolves only after message processing prerequisites are successfully received. This is because in order to properly process the school messages history we need to know about the school areas, response groups, and predefined responses, as well as all users for the school. */
export const getChatMessagesSuccess = createAction(
  MessageApiAction.chatMessagesSuccess,
  props<ExtendedAction<ChatMessageDto[]>>()
)
export const getChatMessagesError = createAction(
  MessageApiAction.chatMessagesError,
  props<ExtendedAction<HttpErrorResponse>>()
)
export const sendMessageSuccess = createAction(MessageApiAction.sendMessageSuccess)
export const sendMessageError = createAction(
  MessageApiAction.sendMessageError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//LOCATIONS
export const getHistoricUserLocationsSuccess = createAction(
  MessageApiAction.getHistoricUserLocationsSuccess,
  props<ExtendedAction<UserLocationDto[]>>()
)
export const getHistoricUserLocationsError = createAction(
  MessageApiAction.getHistoricUserLocationsError,
  props<ExtendedAction<HttpErrorResponse>>()
)
//CHECK STATUS
export const sendCheckStatusSuccess = createAction(MessageApiAction.sendCheckStatusSuccess)
export const sendCheckStatusError = createAction(
  MessageApiAction.sendCheckStatusError,
  props<ExtendedAction<HttpErrorResponse>>()
)
