import { MobileUserTypes } from '@model/user/user.model'
import {
  GraphicAttributesTypeEnum,
  RecentMessageIndicatorTypeEnum
} from './graphic-attributes.model'
import { StatusColorEnum } from './area.view'
import {
  ChatResponseDtoPayload,
  ChatResponseWithPollResponseIdPayload,
  ChatResponseWithMessagePayload
} from '@model/message.model'
import { GetResponseDto } from '@model/message/predefined-message.model'

export interface BaseLocationGraphic {
  id: string
  mobileId: string
  chatResponseDtoPayload:
    | ChatResponseDtoPayload
    | ChatResponseWithMessagePayload
    | ChatResponseWithPollResponseIdPayload
  kind: GraphicAttributesTypeEnum
}
export enum LocationGraphicAttrProps {
  oldestConsideredDtoTimestamp = 'oldestConsideredDtoTimestamp',
  kind = 'kind',
  id = 'id',
  mobileId = 'mobileId',
  fullName = 'fullName',
  userType = 'userType',
  email = 'email',
  phone = 'phone',
  statusVm = 'statusVm',
  icon = 'icon',
  recentMessageIndicatorType = 'recentMessageIndicatorType',
  chatResponseDtoPayload = 'chatResponseDtoPayload'
}
export interface LocationGraphicAttributes {
  [LocationGraphicAttrProps.oldestConsideredDtoTimestamp]: Date | null
  [LocationGraphicAttrProps.kind]: GraphicAttributesTypeEnum
  [LocationGraphicAttrProps.id]: string
  [LocationGraphicAttrProps.mobileId]: string
  [LocationGraphicAttrProps.fullName]: string
  [LocationGraphicAttrProps.userType]: MobileUserTypes
  [LocationGraphicAttrProps.email]: string
  [LocationGraphicAttrProps.phone]: string
  [LocationGraphicAttrProps.statusVm]: LocationStatusViewModel
  [LocationGraphicAttrProps.icon]?: string
  [LocationGraphicAttrProps.chatResponseDtoPayload]: ChatResponseDtoPayload | null
  [LocationGraphicAttrProps.recentMessageIndicatorType]: RecentMessageIndicatorTypeEnum
}
export interface RecentMessageGraphicAttributes {
  [LocationGraphicAttrProps.oldestConsideredDtoTimestamp]: Date | null
  [LocationGraphicAttrProps.kind]: GraphicAttributesTypeEnum
  [LocationGraphicAttrProps.id]: string
  [LocationGraphicAttrProps.mobileId]: string
  [LocationGraphicAttrProps.icon]?: string
  [LocationGraphicAttrProps.chatResponseDtoPayload]: ChatResponseDtoPayload | null
  [LocationGraphicAttrProps.recentMessageIndicatorType]: RecentMessageIndicatorTypeEnum
}

export class LocationStatusViewModel {
  message = ''
  status: StatusColorEnum = StatusColorEnum.unknown

  /** TODO Pass lookups for response id and build up location status vm from object  */
  static getNewLocationStatusVmFromChatDtoPayload = (
    payload: ChatResponseDtoPayload
  ): LocationStatusViewModel => {
    return new LocationStatusViewModel()
  }
}

export interface LocationOfPollResponseAttr extends BaseLocationGraphic {
  statusVm: LocationStatusViewModel
  kind: GraphicAttributesTypeEnum
  chatResponseDtoPayload: ChatResponseWithPollResponseIdPayload
}
export interface LocationOfMessageResponseAttr extends BaseLocationGraphic {
  kind: GraphicAttributesTypeEnum
  chatResponseDtoPayload: ChatResponseWithMessagePayload
}
export interface LocationOfAlertResponseAttr extends BaseLocationGraphic {
  kind: GraphicAttributesTypeEnum
  chatResponseDtoPayload: ChatResponseDtoPayload
  userType: MobileUserTypes
  fullName: string
  response: GetResponseDto
}
export interface RecentChatResponsePayloadAttr extends BaseLocationGraphic {
  kind: GraphicAttributesTypeEnum
  chatResponseDtoPayload: ChatResponseDtoPayload
}
