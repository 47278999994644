import { GetSchoolDto } from '@model/school/school.model'
import { GetUserDto } from '@model/user/user.model'
import { ApiState } from '@state/api.state'
import { OrgStatsModel } from './user-org-stats.model'

export interface IGlobalRedirectModelDeps {
  /** This is the logged in user so if we don't get this, the user can't use the app, hence no need to track API state as of now.*/
  userDto: GetUserDto | undefined
  /** Without a school do the user will be redirected to select a school. */
  schoolDto: GetSchoolDto | null
  /** School dto needs to be analyzed to determine if they must do map config or if they can continue onboarding to roster page. */
  schoolApiState: ApiState | null
  /** As of writing, once we load the users, and there's none, we'll show success modal on first mobile user created no matter the technique (roster or ad hoc create) so we need to know if this loaded or not when we aggregate org user stats. */
  getSchoolUsersApiStates: ApiState | null
  /** This is the collection of users we need to analyze to determine if the admin is done with the roster page */
  userDtos: GetUserDto[] | null
  /**
   * This is the computed representation of the org's school and user stats, it's used to determine if the admin is still onboarding the org, or if they can use the saved page feature, and if they can, it's used to determine which page to redirect to.
   * Planned, when admins can change id of school they're viewing this will be recomputed for the org in current view. */
  currentOrgStats: OrgStatsModel
}
export interface DashboardPageDependencies {
  userDto: GetUserDto | null
  schoolDto: GetSchoolDto | null
  userDtos: GetUserDto[] | null
  currentOrgStats: OrgStatsModel
}
export class GlobalRedirectHandlingDependencies {
  static getModelDeps(
    userDto: GetUserDto | null,
    schoolDto: GetSchoolDto | null,
    schoolApiState: ApiState | null,
    getSchoolUsersApiStates: ApiState | null,
    userDtos: GetUserDto[] | null,
    currentOrgStats: OrgStatsModel
  ): IGlobalRedirectModelDeps | null {
    if (!userDto) {
      return null
    }
    // We will have a user dto in context before we make a call to get the school dto and the dtos for school users. Once we have those we can invoke global redirect logic.
    if (!schoolApiState?.hasLoaded || !getSchoolUsersApiStates?.hasLoaded) {
      return null
    }
    return {
      userDto,
      schoolDto,
      schoolApiState,
      getSchoolUsersApiStates,
      userDtos,
      currentOrgStats
    }
  }
}
