import { DrillModeEnum } from '@model/drill-mode.model'
import { ModalViewModel } from '@view/matieral/modal.view'

export const getDrillModeModalViewModel = (
  t: DrillModeEnum,
  confirm: any,
  cancel: any
): ModalViewModel => {
  if (t === DrillModeEnum.ACTIVE) {
    return {
      ...new ModalViewModel(),
      readyToShow: true,
      iconName: drillModeIconName[drillModeModalViewModelTitle.enterDrillMode],
      titleText: drillModeModalViewModelTitle.enterDrillMode,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      promptText: `You are about to enter drill mode. The message "This is a drill" will be displayed to all users during drill events while in this mode. Do you want to continue ?`,
      confirmHandler: confirm,
      cancelHandler: cancel
    }
  } else if (t === DrillModeEnum.DEACTIVATE) {
    return {
      ...new ModalViewModel(),
      readyToShow: true,
      iconName: drillModeIconName[drillModeModalViewModelTitle.exitDrillMode],
      titleText: drillModeModalViewModelTitle.exitDrillMode,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      promptText: `You are about to exit drill mode. The message "Not a drill" will be displayed to all users during emergency events. Do you want to continue ?`,
      confirmHandler: confirm,
      cancelHandler: cancel
    }
  }
  return new ModalViewModel()
}

export enum drillModeModalViewModelTitle {
  enterDrillMode = 'Enter Drill Mode',
  exitDrillMode = 'Exit Drill Mode'
}

export const drillModeIconName: Record<drillModeModalViewModelTitle, string> = {
  [drillModeModalViewModelTitle.enterDrillMode]: 'enter-drill-mode',
  [drillModeModalViewModelTitle.exitDrillMode]: 'exit-drill-mode'
}
