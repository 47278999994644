import { SchoolAreaType } from '@model/school/sub-area.model'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcgisHitTest } from '../arcgis-hit-test.view'

export namespace ArcGisMouseWheelHandler {
  export const handleDashboardWheelChange = async (
    context: ArcGisMapService,
    action: __esri.ViewMouseWheelEvent
  ) => {
    if (!context.popupRef) {
      console.warn(`Can't handle mouse wheel change due to no popup component ref`)
      return
    }
    if (!context.popupRef?.popupVisible) {
      return
    }
    //In case user is zooming while hovering over an area, let's get an update reference to the graphic
    const result = await ArcgisHitTest.getHitForDashLayers(context, action)
    const graphic = ArcgisHitTest.getGraphicFromHitResult(result, context)
    if (graphic?.attributes.areaType === SchoolAreaType.boundary) {
      // console.log(`Hovering over school boundary, ignoring interactions with this polygon`)
      return
    }
    if (!graphic || context.popupRef?.isShownDueToClick) {
      ArcGisEventHandlers.updatePopupPosition(context.popupRef, context._mapView)
      return
    }
    context.popupRef.graphic = graphic
    // Update the location of a popup that's being shown due to hover
    await ArcGisEventHandlers.handleMouseWheelChange(graphic, context._mapView, context.popupRef)
  }
  export const handleMapConfigWheeelChange = async (
    context: ArcGisMapService,
    action: __esri.ViewMouseWheelEvent
  ) => {
    if (!context?.mapConfigPopupRef) {
      console.warn(`Can't handle mouse wheel change due to no popup component ref`)
      return
    }
    if (!context.mapConfigPopupRef.popupVisible) {
      return
    }
    if (!context.mapConfigPopupRef.graphic) {
      console.error(`DEV ERROR: Map popup visible but no graphic set!`)
      return
    }
    if (context.mapConfigPopupRef) {
      // if (!context.mapConfigPopupRef.graphic) {
      // console.log(`Updating popup position for graphic because popup is shown `)
      ArcGisEventHandlers.updatePopupPosition(context.mapConfigPopupRef, context._mapView)
    }
    // Update the location of a popup that's being shown due to hover
    await ArcGisEventHandlers.handleMouseWheelChange(
      context.mapConfigPopupRef.graphic,
      context._mapView,
      context.mapConfigPopupRef
    )
  }
}
