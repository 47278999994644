import { ExtendedAction } from '@action/extended-ngrx-action'
import { createAction, props } from '@ngrx/store'

export enum ResponseGroupActions {
  getResponseGroupsForSchool = `[Get Response Groups Page Action] Get Response Groups By School Id`
}

export const getResponseGroupsForSchool = createAction(
  ResponseGroupActions.getResponseGroupsForSchool,
  props<ExtendedAction<number>>()
)
