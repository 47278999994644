import { ExtendedAction } from '@action/extended-ngrx-action'
import { GetPredefinedGroupDto } from '@model/message/predefined-message.model'
import { IInstructionsFormValue } from '@model/message/update-instructions-form.model'
import { SchoolIds } from '@model/user/user.model'
import { CommunicationsStandardDto } from '@view/pages/instructions-page/instructions-page-dto.view'
import {
  InstructionsMessageVm,
  InstructionsPageViewModel,
  InstructionsStandardVm,
  InstructionsVm,
  IShowUpdateInstructionsUiPayload
} from '@view/pages/instructions-page/instructions-page.view'

export class InstructionsPageState {
  vm = new InstructionsPageViewModel()

  constructor() {}

  static getTopSectionStandardString(currentStandard: InstructionsStandardVm | null): string {
    return currentStandard ? currentStandard.name : ''
  }
  static getCurrentSchoolAndStandardIdsToUpdate(
    s: InstructionsPageState,
    currentSchoolIds: SchoolIds
  ) {
    return {
      schoolId: currentSchoolIds.id,
      standardId: s.vm.instructionsStandardIdToUpdate
    }
  }

  static getCurrentSchoolStandard(
    schoolStandard: InstructionsStandardVm | null,
    defaultStandard: InstructionsStandardVm | null
  ): InstructionsStandardVm | null {
    return schoolStandard ?? defaultStandard
  }

  static setInstructionsStandards = (
    s: InstructionsPageState,
    a: ExtendedAction<CommunicationsStandardDto[]>
  ): InstructionsPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        instructionsStandards: a.payload
      }
    }
  }

  static setCurrentInstructionsStandardById = (
    s: InstructionsPageState,
    a: ExtendedAction<number>
  ): InstructionsPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        currentStandard: s.vm.instructionsStandards.find((cs) => cs.id === a.payload) ?? null
      }
    }
  }

  static setInstructionsStandardIdToUpdate = (
    s: InstructionsPageState,
    a: ExtendedAction<number>
  ): InstructionsPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        instructionsStandardIdToUpdate: a.payload
      }
    }
  }

  static setCurrentInstructions = (
    s: InstructionsPageState,
    a: ExtendedAction<GetPredefinedGroupDto[]>
  ): InstructionsPageState => {
    var currentInstructions = a.payload.map<InstructionsVm>((dto) => {
      return {
        id: dto.id,
        title: dto.description,
        content: dto.messages.map<InstructionsMessageVm>((m) => {
          return {
            id: m.id,
            predefinedGroupId: m.predefinedGroupId,
            text: m.text,
            userType: m.userType
          }
        }),
        isModified: dto.messages.filter((m) => m.schoolId !== null).length > 0,
        isSpecialForStudents:
          dto.messages.length > 1 && dto.messages[0].text !== dto.messages[1].text
      }
    })

    return {
      ...s,
      vm: {
        ...s.vm,
        instructionsToDisplay: currentInstructions ?? []
      }
    }
  }

  static setUpdateInstructionsUiPayload = (
    s: InstructionsPageState,
    a: ExtendedAction<IShowUpdateInstructionsUiPayload>
  ): InstructionsPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        showUpdateInstructionsUiPayload: a.payload
      }
    }
  }

  static updateUpdateInstructionsForm = (
    s: InstructionsPageState,
    a: ExtendedAction<IInstructionsFormValue>
  ): InstructionsPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        currentFormValue: a.payload
      }
    }
  }
}
