//SUB AREAS

export enum SchoolAreaType {
  boundary = 'boundary',
  subarea = 'subarea'
}
export enum AreaType {
  /** A building may be used to send specific messaging and will be a container for any rooms within it so we can logically display all rooms in a building based on a geometry engine analysis. */
  building = 'building',
  classroom = 'room',
  hallway = 'hallway',
  diningHall = 'diningHall',
  library = 'library',
  office = 'office',
  laboratory = 'laboratory',
  auditorium = 'auditorium',
  mainQuad = 'mainQuad',
  /** Like a field this has a particular context that may lead to different automated messaging. */
  gym = 'gym',
  /** Has a context in that it's an open field and may be far from safety so may need context specific instructions. */
  field = 'field',
  /** It may be useful to denote a drawn sub area as a parking log, which has a particular context. This context can be used for auto messaging logic */
  parkingLot = 'parkingLot',
  /** Indoor for generic geo fencing */
  indoor = 'indoor',
  /** Outdoor */
  outdoor = 'outdoor',
  other = 'other',
  /**
   * Browser app only
   * These are the polygons associated to an image so that when the user toggles the uploaded image layer they can see any overlays they're using to draw all of the sub areas on the map. */
  image = 'image',
  unknown = 'unknown'
}
export interface ISchoolAreaPatchProps {
  schoolId: number
  id: number
  name?: string
  type?: SelectableAreaType
  boundary?: number[][]
}
const NonSelectableAreaTypes: string[] = [AreaType.unknown, AreaType.image]
export type NonUserAreaTypes = AreaType.unknown | AreaType.image
export const isValidSelectableAreaType = (
  value: AreaType | null | undefined
): value is SelectableAreaType => {
  if (!value) return false
  return !NonSelectableAreaTypes.includes(value)
}
export type SelectableAreaType = Exclude<AreaType, NonUserAreaTypes>

export const AreaTypeDisplayNames: Record<SelectableAreaType, string> = {
  [AreaType.building]: 'Building',
  [AreaType.classroom]: 'Classroom',
  [AreaType.hallway]: 'Hallway',
  [AreaType.diningHall]: 'Dining Hall',
  [AreaType.library]: 'Library',
  [AreaType.office]: 'Office',
  [AreaType.laboratory]: 'Laboratory',
  [AreaType.auditorium]: 'Auditorium',
  [AreaType.mainQuad]: 'Main Quad',
  [AreaType.gym]: 'Gym',
  [AreaType.field]: 'Field',
  [AreaType.parkingLot]: 'Parking Lot',
  [AreaType.indoor]: 'Indoor',
  [AreaType.outdoor]: 'Outdoor',
  [AreaType.other]: 'Other'
}
