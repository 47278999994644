import { getExtendedAction } from '@action/extended-ngrx-action'
import { setPolygonTypeEnum, toggleShowMap } from '@action/school-map-config-page.actions'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  selectImageFileHref,
  selectSchoolMapApi,
  selectSchoolMapMode,
  selectSchoolShapeMode
} from '@selector/page/school-map-config-page/school-map-config-page-state.selector'
import { UploadFileService } from '@service/browser/file/upload-file.service'
import { mainUiSelector, mapDomSelector } from '@shared/constants'
import { MapApiEnum, MapModesEnum } from '@view/map/map-enums.view'
import {
  IRadioOptViewModel,
  getMapApiSourceOptions,
  getMapModeOptions,
  getMapSelectOptions
} from '@view/map/map.view'
import { Subscription, map, tap } from 'rxjs'
import { ArcGisMapService } from './arcgis/arcgis-map.service'
// import { MapBoxService } from "./map-box/map-box.service.ts.temp";
import { MapConfigEditAreaPopupComponent } from '@component/map-config-edit-area-popup/map-config-edit-area-popup.component'
import { MapPopupComponent } from '@component/shared/map-popup/map-popup.component'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { DecoratedAlertViewModel } from '@model/alert.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { GetSchoolAreaDto, SchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto } from '@model/school/school.model'
import { MobileUserTypes } from '@model/user/user.model'
import { selectMapApi } from '@selector/page/dashboard-page/dashboard-view.selectors'
import { AppConfigService } from '@service/app-config/app-config.service'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { BaseMapToggleItemViewModel } from '@view/map/base-layer/base-layer-toggle.view'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import {
  ChatMessageDto,
  ChatMessageDtoWithUserIdsPayload,
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload
} from '../../model/message.model'
import { MapPageType, PolygonTypeEnum } from './map.component.service.model'
import { OpenLayersMapService } from './open-layers/open-layers.service'
import { GetResponseDto } from '@model/message/predefined-message.model'

@Injectable()
export class MapComponentService {
  selector = mapDomSelector
  mainUiSelectorTemplate = mainUiSelector

  // TODO pass this in to init from the component
  mapApiForMapConfig$ = this.store.select(selectSchoolMapApi)
  mapApiForDashboard$ = this.store.select(selectMapApi)

  mapMode$ = this.store.select(selectSchoolMapMode)
  shapeType$ = this.store.select(selectSchoolShapeMode)
  imageFileHref$ = this.store.select(selectImageFileHref)

  mapModeUploadEnabled$ = this.store
    .select(selectSchoolMapMode)
    .pipe(map((value: MapModesEnum) => value === MapModesEnum.upload))
  addSchoolMapDisabled$ = this.imageFileHref$.pipe(map((value: string | null) => !!value))

  subs: Subscription[] = []

  polygonType$ = this.store.select(selectSchoolShapeMode).pipe(tap((t) => (this.polygonType = t)))
  polygonType = PolygonTypeEnum.Box
  private _mapApi: MapApiEnum | string = MapApiEnum.arcGis

  public mapApiOptions: IRadioOptViewModel<MapApiEnum>[] = getMapApiSourceOptions()
  public getMapModeOptions: IRadioOptViewModel<MapModesEnum>[] = getMapModeOptions()
  public getSelectionOptions: IRadioOptViewModel<PolygonTypeEnum>[] = getMapSelectOptions()

  type = MapPageType.unknown

  constructor(
    private store: Store,
    private appConfig: AppConfigService,
    private _openLayersMapService: OpenLayersMapService,
    private _arcGisMapService: ArcGisMapService,
    // private _mapBoxService: MapBoxService,
    private _uploadFileService: UploadFileService
  ) { }
  //PUBLIC API
  //#region
  init = (
    t: MapPageType,
    popup: MapPopupComponent | MapConfigEditAreaPopupComponent | undefined = undefined
  ): void => {
    this.type = t
    if (popup) {
      this._arcGisMapService.setPopup(popup)
    }
    // console.log(`MapComponentService.init() for map page type ${t}`)
    // TODO Set up api change handle based on errors in original provider
    // once we implement a map api backup service
    // requirement to reimplement all mapping features twice
    switch (this.type) {
      case MapPageType.schoolDashboard:
        this.subs = [this.mapApiForDashboard$.subscribe(this.handleMapApiChange)]
        return
      case MapPageType.schoolMapConfig:
        this.subs = [this.mapApiForMapConfig$.subscribe(this.handleMapApiChange)]
    }
  }
  setUp = () => {
    // console.log(`this.type: ${this.type}`)
    switch (this._mapApi) {
      case MapApiEnum.openLayers:
        this._openLayersMapService.init(this.type)
        break
      case MapApiEnum.arcGis:
        this._arcGisMapService.init(this.type)
        break
      // case MapApiEnum.mapBox:
      //     this._mapBoxService.init();
      //     break;
    }
  }
  callBasedOnApi = (
    openLayersCb: Function,
    arcGisLayersCb: Function
    // mapBoxCb: Function
  ) => {
    switch (this._mapApi) {
      case MapApiEnum.openLayers:
        openLayersCb()
        break
      case MapApiEnum.arcGis:
        arcGisLayersCb()
        break
      // case MapApiEnum.mapBox:
      //     mapBoxCb()
      //     break;
    }
  }
  destroy = (): void => {
    switch (this._mapApi) {
      case MapApiEnum.openLayers:
        this._openLayersMapService.destroy()
        this._clearChildNodesOfMapElement()
        break
      case MapApiEnum.arcGis:
        this._arcGisMapService.destroy()
        this._clearChildNodesOfMapElement()
        break
      // case MapApiEnum.mapBox:
      //     this._mapBoxService.destroy();
      //     this._clearChildNodesOfMapElement()
      //     break;
    }
    this.subs.forEach((s) => s.unsubscribe())
  }
  updateThreatModelEventLoop = (responseIdToGetResponseDto: Record<number, GetResponseDto>) => {
    this.callBasedOnApi(
      () => {
        throw Error('updateThreatModelEventLoop not implemented for open layers')
      },
      () => this._arcGisMapService.updateThreatModelEventLoop(responseIdToGetResponseDto)
    )
  }
  // //SignalR Handlers
  // updateUserLocations = (userLocationsLookup: Record<number, UserLocationDto> | null): void => {
  //     if (!userLocationsLookup) { return }
  //     this._arcGisMapService.updateUserLocations(userLocationsLookup)
  //     // this.callBasedOnApi(
  //     //     () => { throw Error('Update user locations not implemented for open layers') },
  //     // )
  // }
  //User Event Handlers
  handleBaseLayerChange = (payload: BaseMapToggleItemViewModel) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleSelectArea not implemented for open layers')
      },
      () => this._arcGisMapService.toggleBaseLayer(payload)
    )
  }
  handleSelectArea = (payload: number | null) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleSelectArea not implemented for open layers')
      },
      () => this._arcGisMapService.handleSelectArea(payload)
    )
  }
  // handleUserMobileToResponseGeoQuery = (state: DashboardPageState) => {
  //   this.callBasedOnApi(
  //     () => {
  //       throw Error('_handleUserMobileToResponseGeoQuery not implemented for open layers')
  //     },
  //     () => this._arcGisMapService._handleUserMobileToResponseGeoQuery(state)
  //   )
  // }
  _handleRealTimeResponseGeospatialQuery = (payload: ChatResponseDtoPayload) => {
    this.callBasedOnApi(
      () => {
        throw Error('_handleRealTimeResponseGeospatialQuery not implemented for open layers')
      },
      () => this._arcGisMapService._handleRealTimeResponseGeospatialQuery(payload)
    )
  }
  handleMapApiChange = (change: string | null) => {
    if (!change || change === MapApiEnum.unknown) {
      return
    } else {
      this._mapApi = change
      this.callBasedOnApi(
        () => this._openLayersMapService.init(this.type),
        () => this._arcGisMapService.init(this.type)
        // () => this._mapBoxService.init(),
      )
    }
  }

  handleStaleData = (payload: Date, state: DashboardPageState) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleConcludeEvent not implemented for open layers')
      },
      () => this._arcGisMapService._handleFilterMapContentByStaleDataDate(payload, state)
    )
  }
  handleSertResponse = (state: DashboardPageState, payload: ChatMessageDtoWithUserIdsPayload) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleConcludeEvent not implemented for open layers')
      },
      () => this._arcGisMapService._handleRealTimeSertResponse(state, payload)
    )
  }
  handleConcludeEvent = () => {
    this.callBasedOnApi(
      () => {
        throw Error('handleConcludeEvent not implemented for open layers')
      },
      () => this._arcGisMapService._handleConcludeEvent()
    )
  }
  /** Update the location related content with current selected mobile user id in mind. */
  handleRealTimeLocationUpdate = (
    mobileUserId: string,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleRealTimeLocationUpdate not implemented for open layers')
      },
      () => this._arcGisMapService._handleNewLocationUpdate(mobileUserId, state, schoolOwlState)
    )
  }
  handlePresenceChange = (mobileUserId: string, state: DashboardPageState) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleRealTimeLocationUpdate not implemented for open layers')
      },
      () => this._arcGisMapService._handleRemoveLocation(mobileUserId, state)
    )
  }
  handleRealTimeChatResponse = (
    dto: ChatMessageDto,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    const { userToTypeLookup } = state
    //Handle Alerts of SOS variety, threat indicators and poll responses, since they rely on response ids, related type, and potentially isSos
    if (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      (ChatMessageDtoHelper.isChatResponseResponseIdPayload(dto.payload) ||
        ChatMessageDtoHelper.isChatResponseAlertNoResponseIdPayload(dto.payload))
    ) {
      this.handleRealTimeMobileResponse(dto, state, schoolOwlState)
    }
    //Handle simple message type responses
    else if (
      ChatMessageDtoHelper.isChatResponseDto(dto) &&
      ChatMessageDtoHelper.isChatResponseWithMessageOnlyPayload(dto.payload)
    ) {
      this.handleRealTimeChatResponseReceivedMessageDto(dto.payload, userToTypeLookup)
    }
  }
  handleMedicalEmergencyResponse = (
    dto: ChatResponseDtoPayload,
    medicalAlertsLookup: Record<string, DecoratedAlertViewModel>
  ) => {
    this.callBasedOnApi(
      () => {
        throw Error('_handleMedicalEmergencyResponse not implemented for open layers')
      },
      () =>
        this._arcGisMapService._handleMedicalEmergencyResponse(
          dto,
          medicalAlertsLookup[dto.mobileUserId ?? '0']
        )
    )
  }
  handleRealTimeMobileResponse = (
    dto: ChatMessageDto,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleRealTimeMobileResponse not implemented for open layers')
      },
      () => this._arcGisMapService._handleRealTimeMobileResponse(dto, state, schoolOwlState)
    )
  }
  handleRealTimeChatResponseReceivedMessageDto = (
    payload: ChatResponseWithMessagePayload,
    lookup: Record<string, MobileUserTypes>
  ) => {
    this.callBasedOnApi(
      () => {
        throw Error('handleRealTimeChatResponseReceivedMessageDto not implemented for open layers')
      },
      () => this._arcGisMapService._handleRealTimeChatResponseReceivedMessageDto(payload, lookup)
    )
  }
  handleSaveBoundaryClick = () => {
    this.callBasedOnApi(
      () => {
        throw Error('Save not implemented for open layers')
      },
      () => this._arcGisMapService.dispatchSaveSchool()
      // () => { throw Error('Save not implemented for map box')}
    )
  }
  handleMapModeChange = (newMapMode: MapModesEnum) => {
    this.callBasedOnApi(
      () => this._openLayersMapService.setUpMapMode(newMapMode),
      () => this._arcGisMapService.setUpMapMode(newMapMode)
      // () => this._mapBoxService.setUpMapMode(newMapMode),
    )
  }
  /** Handle event by calling upload file service */
  handleFileInput = ($event: Event) => {
    let inputElement = $event?.target as HTMLInputElement
    let files = inputElement.files as FileList
    this._uploadFileService.handleFileInput(files)
    this.store.dispatch(setPolygonTypeEnum(getExtendedAction(PolygonTypeEnum.AspectRatioBoundBox)))
  }
  reset = () => {
    this._openLayersMapService.clearMap()
  }
  handlePolygonTypeChange = () => {
    this.callBasedOnApi(
      () => this._openLayersMapService.handlePolygonTypeChange(),
      () => this._arcGisMapService.handlePolygonTypeChange()
      // () => this._mapBoxService.handlePolygonTypeChange(),
    )
  }
  //#endregion
  private _clearChildNodesOfMapElement = () => {
    const mapSelector = document.getElementById(this.selector)
    mapSelector?.childNodes.forEach((n) => n.remove())
  }

  /** After clicking the show map button in the side nav, we should reset the view back to hiding the map.*/
  handleShowMapToggle = (visibility: boolean) => {
    if (visibility) {
      setTimeout(() => {
        this.store.dispatch(toggleShowMap())
      }, this.appConfig.config.SHOW_MAP_MANUALLY_TIMEOUT_MS)
    }
  }
  //#region
  // MAP CONFIG SECTION
  /** Update area type and name in area layer when patching successfully to sync later clicks with updated data */
  updateSchoolAreaDtoSuccess = (dto: GetSchoolAreaDto) => {
    this.callBasedOnApi(
      () => {
        throw Error(`updateSchoolAreaDtoSuccess not implemented for open maps api`)
      },
      () => this._arcGisMapService.updateSchoolAreaDtoSuccess(dto)
    )
  }
  /** Map Config handle toolbar selection*/
  handleMapConfigToolbarClick = (o: MapConfigToolbarOptions) => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleMapConfigToolbarClick not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleMapConfigToolbarClick(o)
    )
  }
  handlePatchSchoolSuccess = (dto: GetSchoolDto) => {
    this.callBasedOnApi(
      () => {
        throw Error(`handlePatchSchoolSuccess not implemented for open maps api`)
      },
      () => this._arcGisMapService.handlePatchSchoolSuccess(dto)
    )
  }
  /** Handle create new area with post */
  handlePostAreaDtoSuccess = (dto: SchoolAreaDto) => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleMapConfigToolbarClick not implemented for open maps api`)
      },
      () => this._arcGisMapService.handlePostAreaDtoSuccess(dto)
    )
  }
  deleteAreaFromMapConfig = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`deleteAreaFromMapConfig not implemented for open maps api`)
      },
      () => this._arcGisMapService.deleteAreaFromMapConfig()
    )
  }
  handleCompleteMap = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleMoveToCompleteConfiguration not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleCompleteMap()
    )
  }

  engageMapConfigSlider = (v: number) => {
    this.callBasedOnApi(
      () => {
        throw Error(`engageMapConfigSlider not implemented for open maps api`)
      },
      () => this._arcGisMapService.engageMapConfigSlider(v)
    )
  }
  // Sliders
  updateMapConfigProximitySlider = (v: number) => {
    this.callBasedOnApi(
      () => {
        throw Error(`updateMapConfigProximitySlider not implemented for open maps api`)
      },
      () => this._arcGisMapService.updateMapConfigProximitySlider(v)
    )
  }
  updateRotationSlider = (v: number) => {
    this.callBasedOnApi(
      () => {
        throw Error(`updateRotationSlider not implemented for open maps api`)
      },
      () => this._arcGisMapService.updateRotationSlider(v)
    )
  }
  //Map View
  handleSaveMapView = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleSaveMapView not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleSaveMapView()
    )
  }
  //Cross page concerns
  handleZoomInClick = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleZoomInClick not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleZoomInClick()
    )
  }
  handleZoomOutClick = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleZoomOutClick not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleZoomOutClick()
    )
  }
  handleHomeClick = () => {
    this.callBasedOnApi(
      () => {
        throw Error(`handleHomeClick not implemented for open maps api`)
      },
      () => this._arcGisMapService.handleHomeClick()
    )
  }
  setPressedPollId = (
    payload: string | null,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    this.callBasedOnApi(
      () => {
        throw Error(`setPressedPollId not implemented for open maps api`)
      },
      () => this._arcGisMapService.setPressedPollId(payload, state, schoolOwlState)
    )
  }
  toggleDimmedAttackAlerts = (payload: boolean) => {
    this.callBasedOnApi(
      () => {
        throw Error(`toggleDimmedAttackAlert is not implemented for open maps api`)
      },
      () => this._arcGisMapService.toggleDimmedAttackAlert(payload)
    )
  }
}
