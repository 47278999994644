import { SchoolDtoHelper } from '@domain/dto-helpers/school-model.helper'
import { GetSchoolDto } from '@model/school/school.model'
import { SchoolMapConfigPageState } from '@state/page/school-map-config-page.state'
import { SchoolBoundaryPartialState } from '@view/map/map.view'
import { Observable } from 'rxjs'
import { MapConfigValidationErrors } from './school-map-config-validation.view'
import { MemoizedSelector, DefaultProjectorFn } from '@ngrx/store'

export enum MapConfigSteps {
  mapView = 'mapView',
  schoolPerimeter = 'schoolPerimeter',
  addAreas = 'addAreas'
}
export type ICompleteStepsIndicatorViewModel = Record<MapConfigSteps, boolean>
export interface IMapConfigAreaViewModel {
  schoolId: number
  schoolLogicalId: string
  existingAreaNames: string[]
}
export interface IMapConfigViewModel {
  step: MapConfigSteps | null
  error: MapConfigValidationErrors | null
  partialState: SchoolBoundaryPartialState
}
export const mapConfigStepNumber = Object.values(MapConfigSteps).reduce(
  (accum, curr: MapConfigSteps, index: number) => ({
    ...accum,
    [curr]: index + 1
  }),
  {} as Record<MapConfigSteps, number>
)
export interface ISchoolMapConfigStepViewModel {
  step: number
  option: MapConfigSteps
  displayText: string

  /** Inferred from the state of the school dto */
  complete$: Observable<boolean>

  /** Determined by current active step in map config page vm */
  active$: Observable<boolean>
  /** Step can become deactivated if user invalidates current step, they'll need to fix it before moving elsewhere. Defaults to true and enabled when we have school dto to infer state. */
  disabled$: Observable<boolean>
}
export class SchoolMapConfigStepViewModel {
  /** The label is a combination of the current active step and the selected value. */
  static getSliderLabel(state: SchoolMapConfigPageState): string {
    const stepIsMapView = state.vm.currentMapConfigStep === MapConfigSteps.mapView
    const stepIsPerimeter = state.vm.currentMapConfigStep === MapConfigSteps.schoolPerimeter
    if (stepIsMapView) {
      return `Rotate map • ${state.vm.rotationSliderValue}°`
    } else if (stepIsPerimeter) {
      return `Extend perimeter • ${state.vm.proximitySliderValue}ft`
    } else {
      console.warn("Slider label only configured for map view and perimeter steps")
    }
    return ''
  }
  static mapConfigDisplayNamesLookup: Record<MapConfigSteps, string> = {
    [MapConfigSteps.mapView]: 'Map View',
    [MapConfigSteps.schoolPerimeter]: 'School Perimeter',
    [MapConfigSteps.addAreas]: 'Add Areas'
  }

  /** Based on the state of the school dto determine the active step for the user. */
  static getActiveStep = (schoolDto: GetSchoolDto | null): MapConfigSteps | null => {
    if (schoolDto === null) return null
    if (SchoolDtoHelper.hasAreas(schoolDto)) return MapConfigSteps.mapView
    else if (SchoolDtoHelper.hasBoundary(schoolDto)) return MapConfigSteps.addAreas
    else if (SchoolDtoHelper.hasCompleteMapViewStep(schoolDto)) return MapConfigSteps.schoolPerimeter
    else {
      return MapConfigSteps.mapView
    }
  }

  /** The map validation error is a result of the school dto on page load, and then based on user interactions. */
  static getMapValidationError = (schoolDto: GetSchoolDto | null): MapConfigValidationErrors | null => {
    if (schoolDto === null) return null
    if (!SchoolDtoHelper.hasAreas(schoolDto)) return MapConfigValidationErrors.atLeastOneAreaMustExist
    else if (!SchoolDtoHelper.hasBoundary(schoolDto)) return MapConfigValidationErrors.boundaryMustExist
    else {
      return null
    }
  }
  /** TODO Recommendation use "Complete Map" to avoid jarring resize, 1 and 2 have 12 chars while Complete Map has the same it may not be the ideal display text */
  static incompleteMapConfigCtaBtnTextLookup: Record<MapConfigSteps, string> = {
    [MapConfigSteps.mapView]: 'Save and Continue',
    [MapConfigSteps.schoolPerimeter]: 'Save and Continue',
    [MapConfigSteps.addAreas]: 'Complete Configuration'
  }
  static completeMapConfigCtaButtonTextLookup: Record<MapConfigSteps, string> = {
    [MapConfigSteps.mapView]: 'Save Updates',
    [MapConfigSteps.schoolPerimeter]: 'Save Updates',
    [MapConfigSteps.addAreas]: 'Complete Configuration' //'Complete Map'
  }
  /**
   * Only shown the map interaction components when we can interact with the map.
   * This is when the user is on the map view step, the school perimeter step, or the add areas step.
   */
  static zoomAndHomeShownOnMapConfig(s: SchoolMapConfigPageState): boolean {
    switch (s.vm.currentMapConfigStep) {
      case MapConfigSteps.mapView:
      case MapConfigSteps.schoolPerimeter:
      case MapConfigSteps.addAreas:
        return true
      default:
        return false
    }
  }
  static showInstructions = (state: SchoolMapConfigPageState): boolean => {
    const stepVmOption = state.vm.currentMapConfigStep ?? null
    if (
      stepVmOption === MapConfigSteps.mapView ||
      stepVmOption === MapConfigSteps.schoolPerimeter ||
      stepVmOption === MapConfigSteps.addAreas
    ) {
      return true
    }
    return false
  }

  static getStepByOption = (o: MapConfigSteps, vm: ISchoolMapConfigStepViewModel[]): ISchoolMapConfigStepViewModel | null => {
    return vm.find((s) => s.option === o) ?? null
  }

  /** TODO @deprecated use getStepByOption 
   * Rework to get step by option id instead of next or previous as that's no longer the pattern */
  static getNextStep = (
    curr: ISchoolMapConfigStepViewModel,
    vm: ISchoolMapConfigStepViewModel[]
  ): ISchoolMapConfigStepViewModel | null => {
    switch (curr.option) {
      case MapConfigSteps.mapView:
        return vm.find((o) => o.option === MapConfigSteps.schoolPerimeter) ?? null
      case MapConfigSteps.schoolPerimeter:
        return vm.find((o) => o.option === MapConfigSteps.addAreas) ?? null
      default:
        return null
    }
  }
  /** TODO @deprecated use getStepByOption
   * Rework to get step by option id instead of next or previous as that's no longer the pattern */
  static getPrevStep = (
    curr: ISchoolMapConfigStepViewModel,
    vms: ISchoolMapConfigStepViewModel[]
  ): ISchoolMapConfigStepViewModel | null => {
    // console.log(`Curr`, curr)
    // console.log('vms', vms)
    switch (curr.option) {
      case MapConfigSteps.schoolPerimeter:
        return vms.find((o) => o.option === MapConfigSteps.mapView) ?? null
      case MapConfigSteps.addAreas:
        return vms.find((o) => o.option === MapConfigSteps.schoolPerimeter) ?? null
      default:
        return null
    }
  }

}
