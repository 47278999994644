import { mobileUserAllowedOnMap } from '@model/user/user.model'
import { LocationGraphicAttrProps } from '@view/area/location.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcgisLayersHandler } from '../layers/arcgis-layers-handler.view'
import { ArcGisLocationHandler } from './arcgis-user-location.view'
import { ArcgisUserGraphicHandler } from './arcgis-user-symbol.view'

export namespace ArcGisLocationsHandler {
  /** Accepts a payload from a area selection to update all locations based on the location being in the area or not, also with consideration for cursor interactions, clicked hovered.
   * @param payload either payload to apply opacity or null to remove all opacity
   */
  export const handleSelectedArea = (context: ArcGisMapService, payload: number | null) => {
    context.opaqueUserIds = {}
    // let countOfHidden = 0
    // let countOfShown = 0
    if (!context._appConfig.config.FEATURE_FLAGS.USE_SELECTED_AREA_OUTSIDE_LOCATION_DIMMING) {
      return
    }
    ArcgisLayersHandler.getUserLocationLayersWithType(context).forEach(([layer, type]) => {
      if (mobileUserAllowedOnMap(type)) {
        layer?.graphics.forEach((g) => {
          const hidden = ArcGisLocationHandler.handleOpacityToggle(context, g, payload, type)
          // if (hidden) {
          //   countOfHidden += 1
          // } else {
          //   countOfShown += 1
          // }
          context.opaqueUserIds[g.attributes.id] = hidden
        })
      }
    })
    // console.log(`
    // Count of hidden ${countOfHidden}
    // Count of shown ${countOfShown}
    // `)
    ArcgisLayersHandler.getAllRecentMessageIndicatorLayersWithType(context).forEach(
      ([layer, type]) => {
        if (mobileUserAllowedOnMap(type)) {
          layer?.graphics.forEach((g) => {
            if (context.opaqueUserIds[g.attributes.id]) {
              g.symbol = ArcGisSymbolFactory.hiddenSymbol.clone()
            } else
              g.symbol = ArcGisSymbolFactory.getSymbolForRecentMessageIndicatorByVm(
                g.attributes[LocationGraphicAttrProps.recentMessageIndicatorType]
              ).clone()
          })
        }
      }
    )
    // Handle selection of area in the case that the popup is already opened.
    // if(payload && context.popupRef?.isLocationPopup){
    //   const selectedAreaId = context._schoolAreaLayer?.graphics.find(g => g.attributes.id === payload)
    //   // context.popupRef.setContent()
    // }
  }
  /** Handles updating the symbol for the location and recent message for a given id
   * @return boolean to indicate if action was taken
   */
  export const updateLocationOnHoverChange = (
    context: ArcGisMapService,
    mobileUserId: string,
    locationLayer: __esri.GraphicsLayer | undefined,
    recentMessageLayer: __esri.GraphicsLayer | undefined,
    hover: boolean
  ): boolean => {
    let actionTaken = false
    const existingLocationGraphic = locationLayer?.graphics.find(
      (g) => g.attributes.id === mobileUserId
    )
    // Skip any updates when handling hover over opaque items
    if (context.opaqueUserIds[existingLocationGraphic?.attributes.id]) {
      return false
    }
    const existingRecentMessageGraphic = recentMessageLayer?.graphics.find(
      (g) => g.attributes.id === mobileUserId
    )
    if (existingLocationGraphic) {
      actionTaken = ArcgisUserGraphicHandler.updateUserLocationSymbolsConditionally(
        context,
        existingLocationGraphic,
        hover,
        true,
        false
      )
    }
    if (existingRecentMessageGraphic) {
      actionTaken = ArcgisUserGraphicHandler.updateUserLocationSymbolsConditionally(
        context,
        existingRecentMessageGraphic,
        hover,
        false,
        true
      )
    }
    return actionTaken
  }
}
