import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisVisibilityHandler } from '../visibility/arcgis-visibility.view'

/** Hide recent message indicator, poll response, and message reponse indicators when zoomed out. */
export const toggleZoomVisibility = (context: ArcGisMapService, zoomVisiblility: boolean): void => {
  //User location recent content indicator
  if (!context?.userTypeLayerVisibilityVm) {
    return
  }
  //Student
  ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
    undefined,
    context._studentRecentMessageLayer,
    context._studentMessageLocationLayer,
    context._studentPollResponsesLayer,
    context.userTypeLayerVisibilityVm.showStudents,
    zoomVisiblility
  )
  //Teacher
  ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
    undefined,
    context._teacherRecentMessageLayer,
    context._teacherMessageLocationLayer,
    context._teacherPollResponsesLayer,
    context.userTypeLayerVisibilityVm.showTeachers,
    zoomVisiblility
  )
  //Other staff
  ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
    undefined,
    context._otherStaffRecentMessageLayer,
    context._otherStaffMessageLocationLayer,
    context._otherStaffPollResponsesLayer,
    context.userTypeLayerVisibilityVm.showOtherStaff,
    zoomVisiblility
  )
  ArcGisVisibilityHandler.toggleVisibilityForUserLayers(
    undefined,
    context._guestRecentMessageLayer,
    context._guestMessageLocationLayer,
    context._guestPollResponsesLayer,
    context.userTypeLayerVisibilityVm.showGuests,
    zoomVisiblility
  )
}
