// For brainstorming purposes only
// https://docs.google.com/document/d/14d3esPm850rdiQPkHFajqhMlxotH8RN5pNKMmOBcMp0/edit#heading=h.c5c4r5a1n35v

export enum EmergencyServerity {
    Suspicion = "I suspect an emergency",
    Actual = "Actual emergency now"
}
export class EmergencySeverityClarification {
    static [EmergencyServerity.Suspicion]: string[] = [
        "Saw someone running toward the school",
        "Header a loud pop sound",
        "Saw someone with a holstered gun"
    ]
    static [EmergencyServerity.Actual]: string[] = [
        "Saw someone shooting",
        "Header multiple shots",
        "Saw people running in panic",
        "People hurt",
        "People on the ground"
    ]
}
export enum AttackerTypeEnum {
    shooting = "shooting",
    // shooter = "shooter", //TODO Discuss from Yair's notes
    // Bomber = "Bomber",
    knifeAttack = "knifeAttack",
    // knife = "knife", // TODO Discuss from Yair's notes
    // OtherOrUnsure = "Other or unspecified"
}
export enum EmergencyResponeEnum {
    lockDown = "lockDown",
    secure = "secure",
    hold = "hold"
}

export enum SchoolStateTransitionEnum {
    dismissSuspected = "dismissSuspected",
    dismissEmergency = "dismissEmergency",
    escalateSuspected = "escalateSuspected"
}
export class EmergencyResponseSource {
    static [EmergencyResponeEnum.lockDown]: string[] = [
        "Officers suspect or saw someone run into the school with a gun",
        "Someone called 911 and reported an ongoing attack at the school (could be swatting)",
        "Based on intelligence sources",
        "Specific reason not provided.",
        "Result of a call to the school"

    ]
    static [EmergencyResponeEnum.secure]: string[] = [
        "Request from police"
    ]
    static [EmergencyResponeEnum.hold]: string[] = [
        "Request from emergency services"
    ]
}
export class EmergencyResponseAction {
    static [EmergencyResponeEnum.lockDown]: string[] = [
        "Lock room doors, silence, hide",
        "Potentially, report (by responding to poll) if seeing evidence of attack(er) to help police investigation and hopefully end event quickly if report was false"
    ]
    static [EmergencyResponeEnum.secure]: string[] = [
        "Outside gates/doors are locked, continue normally inside."
    ]
    static [EmergencyResponeEnum.hold]: string[] = [
        "Lock room doors, continue normally in classes",
        "Let paramedics pass in the hallways without interruptions"
    ]
}
export enum MobileUserStatusEnum {
    AllIsGood = "all is Good",
    Needhelp = "I need help",
    AccountedFor = "everyone is accounted for", // not for student - teach only 
    MissingPeople = "I have missing people" // not for student - teach only //IDEA: Potentially add to auto poll rules engine for how many missing?
}
