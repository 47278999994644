import { ExtendedAction } from '@action/extended-ngrx-action'
import { TabVmLoadingStatePayload } from '@action/roster-page/roster-page.actions'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { DtoValidationFailed } from '@model/server-validation.model'
import {
  IUserFormValue,
  UserFormProps,
  UserFormVariants
} from '@model/user/create-update-user.model'
import { UserServerValidationModel } from '@model/user/server-validation.model'
import {
  GetUserDto,
  MobileUserTypes,
  PatchUserDtoType,
  PostUserDtoType,
  UploadRosterUserDto,
  UserDtoProps,
  UserDtoStatus,
  UserTypes,
  UserValidationStatus
} from '@model/user/user.model'
import { ApiState } from '@state/api.state'
import {
  InviteHistoryDtoByUserIdLookup,
  InviteSummaryDtoByMobileUserTypeLookup,
  RosterPageState
} from '@state/page/roster-page.state'
import { RosterFileUploadError } from '@view/file/roster-file.view'
import { RosterUserVm } from '@view/pages/roster-page/roster-table-user.view'
import { IRosterUserVm } from '@view/pages/roster-page/roster-table-user.view.model'
import { MatIconEnum } from '@view/shared/icon.view'
import { Observable } from 'rxjs'
import { RosterUserValidationViewModel } from './roster-user-validation.view'
import { ModalViewModel } from '@view/matieral/modal.view'
import { ButtonIconPlacement } from '@component/shared/button/button.component.view'
import {
  emailDisplayString,
  emailPlaceholder,
  firstNameDisplayString,
  lastNameDisplayString,
  phoneDisplayString,
  roleDisplayString
} from '@shared/constants'

/** Since we deal with both mobile and browser users in the dashboard, this is a union type for that and only that usage. TODO Move to user model*/
export type OwlUserTypes = UserTypes | MobileUserTypes

/**
 * Each mobile type has a tab and all browser admin types are grouped under admin tab, consolidated shows everyone.
 * When new tabs are needed add them here.
 * */
export enum RosterTabs {
  teachers = 'teachers',
  otherStaff = 'otherStaff',
  students = 'students',
  admins = 'admin',
  consolidatedAllUsers = 'consolidatedAllUsers'
}

export type RosterTabToTabVmLookup = Record<RosterTabs, RosterTabViewModel | null>
/** Some tabs have one specific type, but some have many types and are represented as null for now but could be changes to an array of types if that implementation is needed. */
export type RosterTabsToUserTypes = Record<RosterTabs, OwlUserTypes | null>
export type RosterTabToRosterTabVmLookup = Record<RosterTabs, RosterTabViewModel | null>

export const MobileUserTypeToRosterTab: Record<MobileUserTypes, RosterTabs | null> = {
  [MobileUserTypes.student]: RosterTabs.students,
  [MobileUserTypes.teacher]: RosterTabs.teachers,
  [MobileUserTypes.otherStaff]: RosterTabs.otherStaff,
  // These types of users aren't shown in the roster for now.
  [MobileUserTypes.guest]: null,
  [MobileUserTypes.unknown]: null,
  [MobileUserTypes.youngStudent]: null
}
export const RosterBrowserUserTypeToRosterTab: Record<UserTypes, RosterTabs | null> = {
  [UserTypes.globalAdmin]: RosterTabs.admins,
  [UserTypes.schoolAdmin]: RosterTabs.admins,
  [UserTypes.schoolOwner]: RosterTabs.admins,
  // We don't show these types in the roster for now.
  [UserTypes.anon]: null,
  /** Not clear what a registered user type is, should be a status and until fixed will likely lead to bugs. */
  [UserTypes.registered]: null
}

/** Any time we have a selected roster tab we may need to check if it translates to a mobile user type. */
export const RosterTabToMobileUserType: Record<RosterTabs, MobileUserTypes | null> = {
  [RosterTabs.students]: MobileUserTypes.student,
  [RosterTabs.teachers]: MobileUserTypes.teacher,
  [RosterTabs.otherStaff]: MobileUserTypes.otherStaff,
  [RosterTabs.admins]: null,
  [RosterTabs.consolidatedAllUsers]: null
}
/** Any time we have a non mobile user type related tab we may need to infer the web user types associated with it. */
export const RosterTabToBrowserUserType: Record<RosterTabs, UserTypes[] | null> = {
  [RosterTabs.students]: null,
  [RosterTabs.teachers]: null,
  [RosterTabs.otherStaff]: null,
  [RosterTabs.admins]: [UserTypes.globalAdmin, UserTypes.schoolAdmin, UserTypes.schoolOwner],
  [RosterTabs.consolidatedAllUsers]: [
    UserTypes.globalAdmin,
    UserTypes.schoolAdmin,
    UserTypes.schoolOwner
  ]
}

/** TODO Discuss if dynamically constructed ngrx payloads should be in a view file or if we should have a category of objects called action payloads with their own constructors. */
export interface IShowCreateUpdateUiPayload {
  show: boolean
  /** The variant is only null when we're hiding the UI */
  userFormVariant: UserFormVariants | null
  /** This must be defined if variant is update */
  rosterUserVm?: IRosterUserVm
  /** Selected tab at the time of selecting create or update. */
  tab: RosterTabs
}
export interface ICreateUpdateUserFormPayload {
  uiActionPayload: IShowCreateUpdateUiPayload
  /** This is required to prevent the user from editing their own email address.*/
  loggedInUserDto: GetUserDto
  userType: OwlUserTypes
}

export const UserCreateUpdateFormPrefix: Partial<Record<UserFormProps, string>> = {
  [UserDtoProps.phone]: '+1'
}
export const MatIconByUserProp: Partial<Record<UserFormProps, MatIconEnum | ''>> = {
  [UserDtoProps.email]: MatIconEnum.mail,
  [UserDtoProps.phone]: MatIconEnum.smartphone
}
export const LabelTextForUserProp: Record<UserFormProps, string> = {
  [UserDtoProps.firstName]: firstNameDisplayString,
  [UserDtoProps.lastName]: lastNameDisplayString,
  [UserDtoProps.email]: emailDisplayString,
  [UserDtoProps.phone]: phoneDisplayString,
  [UserDtoProps.type]: roleDisplayString,
  [UserDtoProps.mobileType]: roleDisplayString
}
export const PlaceholderTextByUserProp: Record<UserFormProps, string> = {
  [UserDtoProps.firstName]: firstNameDisplayString,
  [UserDtoProps.lastName]: lastNameDisplayString,
  [UserDtoProps.email]: emailPlaceholder,
  [UserDtoProps.phone]: phoneDisplayString,
  [UserDtoProps.type]: '',
  [UserDtoProps.mobileType]: roleDisplayString
}

export const globalAdminDisplayString = 'Global Admin'
export const schoolAdminDisplayString = 'School Admin'
export const schoolOwnerDisplayString = 'School Owner'

/** Owl user types not displayed in the Roster don't have display strings in this lookup. */
export const RosterUserDisplayString: Record<OwlUserTypes, string> = {
  [MobileUserTypes.student]: 'Student',
  [MobileUserTypes.teacher]: 'Teacher',
  [MobileUserTypes.otherStaff]: 'Other Staff',
  [MobileUserTypes.guest]: '',
  [MobileUserTypes.unknown]: '',
  [MobileUserTypes.youngStudent]: '',
  [UserTypes.schoolAdmin]: schoolAdminDisplayString,
  [UserTypes.globalAdmin]: globalAdminDisplayString,
  [UserTypes.schoolOwner]: schoolOwnerDisplayString,
  [UserTypes.anon]: '',
  /** Registered is not a user type and this should be removed from the code base, as registered is a status not a type. */
  [UserTypes.registered]: ''
}

export const MobileUserTypesToDisplayInRoster: OwlUserTypes[] = [
  MobileUserTypes.teacher,
  MobileUserTypes.otherStaff,
  MobileUserTypes.student
]
export const BroswerUserTypesToDisplayInRoster: OwlUserTypes[] = [
  UserTypes.globalAdmin,
  UserTypes.schoolAdmin,
  UserTypes.schoolOwner
]
export const MobileUserRosterTabsToDisplay: OwlUserTypes[] = [
  MobileUserTypes.teacher,
  MobileUserTypes.otherStaff,
  MobileUserTypes.student
]
export enum UserSuccessToastTypes {
  create = 'User created successfully',
  update = 'User updated successfully'
}

/** TODO Split this between roster tabs and non tab related buttons,
 * this interface is too loose, make count$ and isSelected% a required property.
 *
 * Same with count, if it's a tab with users in it, it will have a count, doens't matter if it's displayed or not.
//  * TODO This should be merged with tab vm that has the tab enum value, the user collection, and the loading state.
 * */
export interface IRosterTabButtonViewModel {
  text: string
  imageName?: string
  iconPlacement: ButtonIconPlacement | null
  cb: Function | null
  /** A button may have a count of users associated with it, but the count is dynamic so it has to be fetched dynamically, and creating an observable in this context is more challenging. */
  count$: Observable<number>
  /** If a roster button is selected if will be styled differently and the selected tab is tracked in state. */
  isSelected$: Observable<boolean>
  /** A function to determine if the roster tab loading spinner should be shown.*/
  isLoadingInCountArea$: Observable<boolean>
}
/** Used for interactive buttons below the roster table. */
export interface IRosterFooterButtonVm {
  /** Footer buttons might not have an image. */
  imageName?: string
  /** This specifies icon placement in the button.*/
  iconPlacement: ButtonIconPlacement | null
  text: string
  cb: Function | null
  tooltipText: string | null
}
export interface IRosterTableActionButtonVm {
  text: string
  /** Not all table action buttons have images. */
  imageName?: string
  cb: Function | null
}

export enum RosterImageNames {
  uploadRoster = 'upload-roster',
  confirmUpload = 'blue-confirm-arrow',
  downloadSample = 'download-sample',
  nineWhiteDots = 'nine-white-dots',
  plusOnABlueBackground = 'plus-on-a-blue-background',
  editUser = 'edit-blue',
  deactivate = 'stop-blue',
  reinviteUnregistered = 're-invite-unregistered'
}

export type RosterTabViewModel = {
  tab: RosterTabs
  /** Used to display a spinner for the tab. */
  isInProcess: boolean
  /** A collection of users shown in the tab. */
  userVms: IRosterUserVm[]
  // Why not have the summary mapped to a tab?
  // rosterSummaryDto: RosterInvitationSummaryDto | null
}

export type UploadRosterPropResultDto = {
  key: UserDtoProps
  value: string
}
/** If this has all the fields of get user dto shouldn't it be */
// export interface RosterUserValidationDto extends GetUserDto {
export type RosterUserValidationDto = {
  logicalId: string
  status: UserValidationStatus
  user: UploadRosterUserDto
  errors: UploadRosterPropResultDto[]
  changes: UploadRosterPropResultDto[]
}
export type RosterValidationSummaryDto = RosterValidationSummaryTotalDto &
  RosterValidationSummaryErrorsDto
export type RosterValidationSummaryTotalDto = {
  total: number
  failed: number
  errors: number
}
export type RosterValidationSummaryErrorsDto = {
  emailMoreThenOnceError: number
  phoneMoreThenOnceError: number
  missingFirstNameError: number
  maxLengthExceedFirstNameError: number
  missingLastNameError: number
  maxLengthExceedLastNameError: number
  missingPhoneError: number
  invalidPhoneError: number
  maxLengthExceedEmailError: number
  invalidEmailError: number

  invalidFirstName: number
  invalidLastName: number
  userAlreadyExists: number
}

export enum RosterValidationErrors {
  emailMoreThenOnceError = 'emailMoreThenOnceError',
  phoneMoreThenOnceError = 'phoneMoreThenOnceError',
  missingFirstNameError = 'missingFirstNameError',
  maxLengthExceedFirstNameError = 'maxLengthExceedFirstNameError',
  missingLastNameError = 'missingLastNameError',
  maxLengthExceedLastNameError = 'maxLengthExceedLastNameError',
  missingPhoneError = 'missingPhoneError',
  invalidPhoneError = 'invalidPhoneError',
  maxLengthExceedEmailError = 'maxLengthExceedEmailError',
  invalidEmailError = 'invalidEmailError',
  invalidFirstName = 'invalidFirstName',
  invalidLastName = 'invalidLastName',
  userAlreadyExists = 'userAlreadyExists'
}

export const RosterValidationErrorString: Record<RosterValidationErrors, string> = {
  [RosterValidationErrors.emailMoreThenOnceError]: 'Email appears more than once',
  [RosterValidationErrors.phoneMoreThenOnceError]: 'Phone number appears more than once',
  [RosterValidationErrors.missingFirstNameError]: 'Missing First Name',
  [RosterValidationErrors.maxLengthExceedFirstNameError]: 'First Name exceeds maximum length',
  [RosterValidationErrors.missingLastNameError]: 'Missing Last Name',
  [RosterValidationErrors.maxLengthExceedLastNameError]: 'Last Name exceeds maximum length',
  [RosterValidationErrors.missingPhoneError]: 'Missing Phone number',
  [RosterValidationErrors.invalidPhoneError]: 'Invalid Phone number',
  [RosterValidationErrors.maxLengthExceedEmailError]: 'Email exceeds maximum length',
  [RosterValidationErrors.invalidEmailError]: 'Email wrong format',
  [RosterValidationErrors.invalidFirstName]: 'Invalid First Name',
  [RosterValidationErrors.invalidLastName]: 'Invalid Last Name',
  [RosterValidationErrors.userAlreadyExists]: 'User Already Exists'
}

export type RosterValidationDto = {
  valid: boolean
  /** Todo give this a better name like, userValidationDtos */
  users: RosterUserValidationDto[]
  summary: RosterValidationSummaryDto
}

export type UserInvitationHistoryDto = {
  id: number
  userId: number
  processRosterId: number
  success: boolean
  /** TODO Is this an array or a single error? What if there are multiple errors? */
  error?: string
  date: Date
}

export type RosterInvitationSummaryDto = {
  id: number
  schoolId: number
  logicalId?: string
  userType: MobileUserTypes
  /** TODO Why does this say users when it's of type GetUserDto? */
  users: GetUserDto
  success: boolean
  error?: string
  created?: Date
  completed?: Date | null
  total: number
  invited: number
  failed: number
  deactivated: number
  updated: number
  /** Sometimes the server doesn't return this property so it is marked undefined */
  userInvitations?: UserInvitationHistoryDto[]
}

export class RosterPageViewModel {
  static DEFAULT_SELECTED_TAB = RosterTabs.teachers
  /** Each tab maps to either one user as in the case of mobile tabs or a collection of user types such as the consolidated or admin tab.*/
  selectedRosterTab: RosterTabs = RosterPageViewModel.DEFAULT_SELECTED_TAB

  /**
   * If set to true when we're looking at a validation summary.
   * It must be false in order to display invite summary.
   */
  rosterValidationMode: boolean = false

  /** TODO is these cleared out on cancel or does it persist? If it persists best to clear it explicitly since really the display of the validaiton should be driven by one thing, ideally the presence of this dto, rather than a is preview mod flag which has to be toggled seperately. */
  rosterValidationDto: RosterValidationDto | null = null

  showDeactivateMw: boolean = false
  /** Shows on page load and set to false once we have everything needed to display the page. Also shown during page operations. */
  showRosterPageSpinner: boolean = true

  selectedUserId?: number

  /** Conditionally displayed in footer */
  uploadRosterFileBrowserError: RosterFileUploadError | null = null

  //USER
  showCreateUpdateUiPayload: IShowCreateUpdateUiPayload = {
    show: false,
    userFormVariant: null,
    rosterUserVm: undefined,
    tab: RosterPageViewModel.DEFAULT_SELECTED_TAB
  }
  currentFormValue: any = {}

  /** We'll need access to the latest invitation history dto for each user when we rebuild the roster user vm. */
  static getUpdatedInvitationHistoryLookup(
    invitationSummaryDto: RosterInvitationSummaryDto | null,
    existingLookup: InviteHistoryDtoByUserIdLookup
  ): InviteHistoryDtoByUserIdLookup {
    let copyLookup: InviteHistoryDtoByUserIdLookup = { ...existingLookup }
    invitationSummaryDto?.userInvitations?.forEach(
      (dto: UserInvitationHistoryDto) => (copyLookup[dto.userId] = dto)
    )
    return copyLookup
  }

  /** Each tab has button text specific to it. */
  static getTabButtonText = (tab: RosterTabs, usePlural: boolean): string => {
    // We only have one tab for browser users.
    if (tab === RosterTabs.admins) {
      return schoolAdminDisplayString
    }
    //Otherwise assume it's a mobile user.
    const userType = RosterTabToMobileUserType[tab]
    // Only provide tab button text for mobile and admin tab.
    if (!userType) {
      console.warn('getTableTabButtonText: userType not found for tab', tab)
      return ''
    }
    if (usePlural && userType !== MobileUserTypes.otherStaff) {
      return `${RosterUserDisplayString[userType]}s`
    }
    return RosterUserDisplayString[userType]
  }

  static getCurrentTabInviteErrors(dto: UserInvitationHistoryDto[] | null): string[] | null {
    if (!dto) {
      console.warn(
        'getCurrentTabInviteErrors: User invitation history dto is null, cannot get errors.'
      )
      return null
    }
    return dto.map((d) => d.error || '')
  }

  static getUserInvitationsFromSummaryDto(
    summaryDto: RosterInvitationSummaryDto | null
  ): UserInvitationHistoryDto[] | null {
    if (!summaryDto) {
      console.warn('getInvitationSummaryErrors: Summary dto is null, cannot get errors.')
      return null
    }
    if (!summaryDto.userInvitations) {
      console.warn('getInvitationSummaryErrors: User invitations are null, cannot get errors.')
      return null
    }
    return summaryDto.userInvitations
  }

  /** Mobile users have specific features associated to their dedicated tab, so we need to logically determine if we're on a mobile user tab or not. */
  static tabIsForMobileUserType(selectedRosterTab: RosterTabs): boolean {
    return RosterTabToMobileUserType[selectedRosterTab] !== null
  }

  /** Returns the mobile user type associated with the roster tab or null if it's a non mobile user roster tab */
  static getSelectedMobileUserTypeForTab(t: RosterTabs): MobileUserTypes | null {
    return RosterTabToMobileUserType[t] || null
  }

  /** Extract RosterUserVm from collection of user validation dtos. */
  static getRosterUserVmsFromValidationDto(
    users: RosterUserValidationDto[] | undefined,
    selectedRosterTab: RosterTabs
  ): IRosterUserVm[] | null {
    if (!users) {
      // console.warn('getUsersFromPreview: Users are undefined, cannot get users from preview.')
      return null
    }
    const mobileUserType = RosterTabToMobileUserType[selectedRosterTab]
    if (!mobileUserType) {
      // console.warn(
      //   'getUsersFromPreview: Mobile user type is undefined, cannot get users from preview.'
      // )
      return null
    }
    return users.map((user) => {
      return RosterUserVm.getRosterUserVmFromValidationDto(user, mobileUserType)
    })
  }

  /** Get the current mobile type invitation data based on the selected tab. */
  static getInvitatationSummaryDtoForCurrentTab(
    tab: RosterTabs,
    inviteSummaryDtoByMobileTypeLookup: InviteSummaryDtoByMobileUserTypeLookup | null
  ): RosterInvitationSummaryDto | null {
    if (!inviteSummaryDtoByMobileTypeLookup) {
      console.warn(
        'getCurrentTypeInvitationData: Invitation data is null, cannot get current type invitation data.'
      )
      return null
    }
    const mobileType = RosterTabToMobileUserType[tab]
    if (!mobileType) {
      console.warn(
        'getCurrentTypeInvitationData: Mobile type is null, cannot get current type invitation data.'
      )
      return null
    }
    return inviteSummaryDtoByMobileTypeLookup[mobileType]
  }

  static getDynamicAddUserString(type: RosterTabs): string | null {
    if (type === RosterTabs.admins) {
      return `Add ${schoolAdminDisplayString}`
    }
    const userType = RosterTabToMobileUserType[type]
    if (userType) {
      return `Add ${RosterUserDisplayString[userType]}`
    }
    return null
  }
  static getDynamicToolTipString(type: RosterTabs): string | null {
    if (type === RosterTabs.admins) {
      return `Add a new ${schoolAdminDisplayString} user`
    }
    const userType = RosterTabToMobileUserType[type]
    if (userType) {
      return `Add a new ${RosterUserDisplayString[userType]} user`
    }
    return null
  }
  static getMobileTypeFromOwlUserType(type: OwlUserTypes | null): MobileUserTypes | null {
    if (type && UserDtoHelper.isMobileUser(type)) {
      return type as MobileUserTypes
    }
    return null
  }
  static getMobileTypeFromTab(selectedRosterTab: RosterTabs): MobileUserTypes | null {
    return RosterTabToMobileUserType[selectedRosterTab] || null
  }

  /** This constructs the payload of all data needed to show the create/update form for mobile and web users. */
  static getCreateUpdateUserFormPayload = (
    payload: IShowCreateUpdateUiPayload,
    loggedInUserDto: GetUserDto | null
  ): ICreateUpdateUserFormPayload | null => {
    const { userFormVariant, rosterUserVm, show, tab } = payload
    // We must have the vm in context to set up the form.
    if (!rosterUserVm && userFormVariant === UserFormVariants.update) {
      console.warn(
        `rosterUserVm is undefined, so we can't show an update variant of the roster user form.`
      )
      return null
    }

    if (!loggedInUserDto) {
      console.warn("loggedInUserDto is undefined, can't set up create or update form.")
      return null
    }
    if (!show) {
      return null
    }

    if (!userFormVariant) {
      return null
    }
    let userType: OwlUserTypes | null = null
    const mobileUserType = RosterTabToMobileUserType[tab]
    if (mobileUserType) {
      userType = mobileUserType
    } else {
      // For now you can only create school admins in the admin form, dedicated process needed for school owner transfer process. Also, can't create global admins in the roster.
      userType = UserTypes.schoolAdmin
    }
    if (userType) {
      return {
        uiActionPayload: payload,
        loggedInUserDto,
        userType
      }
    } else {
      return null
    }
  }

  static setIsRosterValidationMode = (
    s: RosterPageState,
    a: ExtendedAction<boolean>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        rosterValidationMode: a.payload
      }
    }
  }

  static setRosterValidationDto = (
    s: RosterPageState,
    a: ExtendedAction<RosterValidationDto>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        rosterValidationDto: a.payload
      }
    }
  }

  /**
   *
   * @param inviteSummaryDtoLookup  A lookup of invitation data by mobile user type this has a default value in state.
   * @param inviteSummaryDto  The invite summary dto to add to the lookup which might be null
   * @param mobileUserType The mobile user type to update in the lookup
   * @returns A new lookup with the updated data.
   */
  static getUpdatedInvitationLookupWithNewDto(
    inviteSummaryDtoLookup: InviteSummaryDtoByMobileUserTypeLookup,
    inviteSummaryDto: RosterInvitationSummaryDto | null,
    mobileUserType: MobileUserTypes
  ): InviteSummaryDtoByMobileUserTypeLookup {
    let copy = { ...inviteSummaryDtoLookup }
    copy[mobileUserType] = inviteSummaryDto
    return copy
  }

  static getTabLoadingByTypeFromDto(
    oldLookup: RosterTabToTabVmLookup,
    payload: RosterInvitationSummaryDto | null,
    mobileUserType: MobileUserTypes
  ): RosterTabToTabVmLookup {
    if (!payload) {
      console.warn(
        'getTabLoadingByTypeFromDto: Payload is null, cannot get tab loading by type from dto.'
      )
      return oldLookup
    }
    if (!payload.userType) {
      console.warn(
        'getTabLoadingByTypeFromDto: User type is null, RosterInvitationSummaryDto malformed, cannot get tab loading by type from dto.'
      )
    }
    const rosterTabForMobileUser = MobileUserTypeToRosterTab[payload.userType]
    if (!rosterTabForMobileUser) {
      console.warn(
        'getTabLoadingByTypeFromDto: Roster tab for mobile user is null, cannot get tab loading by type from dto.'
      )
      return oldLookup
    }
    const tabVm = oldLookup[rosterTabForMobileUser]
    if (!tabVm) {
      console.warn(
        'getTabLoadingByTypeFromDto: Tab view model is null, cannot get tab loading by type from dto.'
      )
      return oldLookup
    }
    if (!payload) {
      return oldLookup
    }
    const rosterTabWasInprocess = tabVm.isInProcess
    const invitationSummaryHasCompletedDate = payload.completed !== null
    const tabVmIsInProcess = rosterTabWasInprocess || !invitationSummaryHasCompletedDate
    return {
      ...oldLookup,
      [payload.userType]: {
        ...tabVm,
        isInProcess: tabVmIsInProcess
      }
    }
  }

  /** TODO Unclear why get mutates state of completed to null */
  static getInviteSummaryDtoByMobileUserType = (
    selectedRosterTab: RosterTabs | null,
    inviteSummaryDtoByMobileUserTypeLookup: InviteSummaryDtoByMobileUserTypeLookup
  ): InviteSummaryDtoByMobileUserTypeLookup => {
    if (!selectedRosterTab) {
      console.warn(
        'selectedRosterTab is null or is a non mobile user tab, cannot process invitation data item.'
      )
      return inviteSummaryDtoByMobileUserTypeLookup
    }
    const userType = RosterTabToMobileUserType[selectedRosterTab]
    const mobileType = RosterPageViewModel.getMobileTypeFromOwlUserType(userType)
    if (mobileType && inviteSummaryDtoByMobileUserTypeLookup[mobileType]) {
      const copyOfLookup = { ...inviteSummaryDtoByMobileUserTypeLookup }
      const summaryDto = inviteSummaryDtoByMobileUserTypeLookup[mobileType]
      if (!summaryDto) {
        console.warn('summaryDto is null, cannot process invitation data item.')
        return inviteSummaryDtoByMobileUserTypeLookup
      }
      let copySummaryDto = { ...summaryDto }
      // TODO This seems wrong, why are we mutating a dto, this should never be done. why mutate the dto? Completed should be set by server and returned to browser.
      copySummaryDto.completed = null
      copyOfLookup[mobileType] = copySummaryDto
      return copyOfLookup
    }
    return inviteSummaryDtoByMobileUserTypeLookup
  }

  static setRosterTabLoadingState = (
    s: RosterPageState,
    a: ExtendedAction<TabVmLoadingStatePayload>
  ): RosterPageState => {
    let copyOfTabVmLookup = { ...s.rosterTabVmLookupByTab }
    //When no roster tab to update, reset all loading states
    if (!a.payload.tab) {
      let newTabs: RosterTabViewModel[] = []
      Object.values(s.rosterTabVmLookupByTab).forEach((tab) => {
        if (!tab) {
          return
        }
        newTabs.push({
          ...tab,
          isInProcess: false
        })
      })
      newTabs.forEach((tab) => {
        copyOfTabVmLookup = {
          ...copyOfTabVmLookup,
          [tab.tab]: tab
        }
      })
    } else {
      copyOfTabVmLookup = {
        ...s.rosterTabVmLookupByTab,
        [a.payload.tab]: {
          ...s.rosterTabVmLookupByTab[a.payload.tab],
          isInProcess: a.payload.isLoading
        }
      }
    }
    return {
      ...s,
      rosterTabVmLookupByTab: copyOfTabVmLookup,
      inviteSummaryDtoByMobileTypeLookup: RosterPageViewModel.getInviteSummaryDtoByMobileUserType(
        s.vm.selectedRosterTab,
        s.inviteSummaryDtoByMobileTypeLookup
      )
    }
  }

  static setProcessingInvitationDataByUserType = (
    s: RosterPageState,
    a: ExtendedAction<MobileUserTypes>
  ): RosterPageState => {
    let copyOfTabVmLookup = { ...s.rosterTabVmLookupByTab }
    const tabForMobileUser = MobileUserTypeToRosterTab[a.payload]
    //Default to no users for a tab
    // let usersForTab: RosterUserVm[] = []
    let tabVm: RosterTabViewModel | null = null
    if (tabForMobileUser) {
      tabVm = s.rosterTabVmLookupByTab[tabForMobileUser]
      if (tabVm) {
        const userVms = tabVm.userVms
        copyOfTabVmLookup = {
          ...copyOfTabVmLookup,
          [tabForMobileUser]: {
            ...tabVm,
            tab: tabForMobileUser,
            isInProcess: userVms.length > 0 ? true : false,
            userVms
          }
        }
      }
    }
    return {
      ...s,
      rosterTabVmLookupByTab: copyOfTabVmLookup,
      inviteSummaryDtoByMobileTypeLookup: RosterPageViewModel.getInviteSummaryDtoByMobileUserType(
        tabForMobileUser,
        s.inviteSummaryDtoByMobileTypeLookup
      )
    }
  }

  static toggleShowDeactivateModal = (s: RosterPageState): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        showDeactivateMw: !s.vm.showDeactivateMw
      }
    }
  }

  static handleRosterPageSpinnerState = (
    s: RosterPageState,
    a: ExtendedAction<boolean>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        showRosterPageSpinner: a.payload
      }
    }
  }
  /** Each tab has an explicit value in an enum, so set that each time it's changed by the user. */
  static handleSelectedRosterTabChange = (
    s: RosterPageState,
    a: ExtendedAction<RosterTabs>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        selectedRosterTab: a.payload
      }
    }
  }

  static handleSelectedUserId = (
    s: RosterPageState,
    a: ExtendedAction<number>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        selectedUserId: a.payload
      }
    }
  }
  //USER
  static toggleAddEditUserUi = (
    s: RosterPageState,
    a: ExtendedAction<IShowCreateUpdateUiPayload>
  ): RosterPageState => {
    return {
      ...s,
      // reset the error text when the form is hidden
      createUpdateUserErrorText: '',
      vm: {
        ...s.vm,
        showCreateUpdateUiPayload: a.payload
      }
    }
  }
  /** If we get a new form value when there was an api error, this means that the form is now valid with a new dto, that one that caused the api error. */
  static updateCreateUpdateUserForm = (
    s: RosterPageState,
    a: ExtendedAction<IUserFormValue>
  ): RosterPageState => {
    const newVm = {
      ...s.vm,
      currentFormValue: a.payload
    }
    const mobileType = a.payload.mobileType
    const userType = a.payload.type
    let emailKey: string
    let phoneKey: string
    emailKey = UserServerValidationModel.getEmailKey(a.payload.email)
    phoneKey = UserServerValidationModel.getClientSidePhoneKey(a.payload.phone)
    if (UserDtoHelper.isAdmin(userType)) {
    }
    const existingServerValidationMessage =
      s.serverValidations[phoneKey] || s.serverValidations[emailKey]
    if (existingServerValidationMessage) {
      return {
        ...s,
        createUpdateUserErrorText: existingServerValidationMessage,
        vm: newVm
      }
    }
    const createApiHasError = s.createUserApiState === ApiState.createHasErrorState()
    if (createApiHasError) {
      return {
        ...s,
        createUserApiState: ApiState.createHadLoadedState(),
        createUpdateUserErrorText: '',
        vm: newVm
      }
    }
    const updateApiHasError = s.updateUserApiState === ApiState.createHasErrorState()
    if (updateApiHasError) {
      return {
        ...s,
        createUpdateUserErrorText: '',
        updateUserApiState: updateApiHasError
          ? ApiState.createHadLoadedState()
          : s.updateUserApiState,
        vm: newVm
      }
    } else {
      return {
        ...s,
        createUpdateUserErrorText: '',
        vm: newVm
      }
    }
  }
  //create
  static handleCreateAndInviteUser = (
    s: RosterPageState,
    a: ExtendedAction<any>
  ): RosterPageState => {
    return {
      ...s,
      createUserApiState: ApiState.createIsLoadingState()
    }
  }
  static handleCreateSuccess = (s: RosterPageState, a: ExtendedAction<any>): RosterPageState => {
    return {
      ...s,
      createUserApiState: ApiState.createHadLoadedState(),
      vm: {
        ...s.vm,
        showCreateUpdateUiPayload: {
          show: false,
          userFormVariant: null,
          rosterUserVm: undefined,
          tab: s.vm.selectedRosterTab
        }
      }
    }
  }
  static handleCreateError = (
    s: RosterPageState,
    a: ExtendedAction<DtoValidationFailed<PostUserDtoType>>
  ): RosterPageState => {
    return {
      ...s,
      createUserApiState: ApiState.createHasErrorState(),
      ...RosterUserValidationViewModel.getValidationState(s, a.payload)
    }
  }
  //update
  /** Handle api state as well as recalc of org stats to prevent navigation away. */
  static handleUpdateUser = (s: RosterPageState, a: ExtendedAction<any>): RosterPageState => {
    return {
      ...s,
      updateUserApiState: ApiState.createIsLoadingState()
    }
  }
  static handleUpdateUserSuccess = (
    s: RosterPageState,
    a: ExtendedAction<any>
  ): RosterPageState => {
    return {
      ...s,
      updateUserApiState: ApiState.createHadLoadedState(),
      vm: {
        ...s.vm,
        showCreateUpdateUiPayload: {
          show: false,
          userFormVariant: null,
          rosterUserVm: undefined,
          tab: s.vm.selectedRosterTab
        }
      }
    }
  }
  static handleUpdateUserError = (
    s: RosterPageState,
    a: ExtendedAction<DtoValidationFailed<PatchUserDtoType>>
  ): RosterPageState => {
    return {
      ...s,
      updateUserApiState: ApiState.createHasErrorState(),
      ...RosterUserValidationViewModel.getValidationState(s, a.payload)
    }
  }

  static handleUploadError = (
    s: RosterPageState,
    a: ExtendedAction<RosterFileUploadError | null>
  ): RosterPageState => {
    return {
      ...s,
      vm: {
        ...s.vm,
        uploadRosterFileBrowserError: a.payload
      }
    }
  }

  static getAppearedActiveUsersCongratulationsModal = (
    confirm: any,
    cancel: any
  ): ModalViewModel => {
    return {
      ...new ModalViewModel(),
      readyToShow: true,
      iconName: 'congratulations',
      titleText: 'Congratulations!',
      confirmButtonText: 'Go to Dashboard',
      cancelButtonText: 'Stay in Settings',
      promptText: `You have added user(s). You can now use the dashboard, run drills and handle real emergency events.`,
      confirmHandler: confirm,
      cancelHandler: cancel
    }
  }
}
