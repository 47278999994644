import { getExtendedAction } from '@action/extended-ngrx-action'
import { setMapConfigErrorReason } from '@action/school-map-config-page.actions'
import Graphic from '@arcgis/core/Graphic'
import { Geometry, Polygon } from '@arcgis/core/geometry'
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine'
import { SimpleFillSymbol } from '@arcgis/core/symbols'
import SnappingOptions from '@arcgis/core/views/interactive/snapping/SnappingOptions'
import { BaseMapPopup } from '@component/shared/base-map-popup.model'
import { SchoolAreaModelHelper } from '@domain/dto-helpers/area-model.helper'
import { SchoolAreaDto } from '@model/school/school-subarea.model'
import { SchoolDto } from '@model/school/school.model'
import { MapPageType } from '@service/map/map.component.service.model'
import { AreaGraphicAttributes } from '@view/area/area.view'
import { GraphicAttributesTypeEnum } from '@view/area/graphic-attributes.model'
import { ArcGisMapProjectionViewModel } from '@view/map/arcgis-map-projection.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { ArcGisMapService } from '../arcgis-map.service'
import { ArcGisPopupView } from '../arcgis-popup.view'
import { ArcGisSymbolFactory } from '../arcgis-symbol-factory.view'
import { ArcGisEventHandlers } from '../arcgis-view-event-handler.view'
import { ArcGisGraphicAttrFac } from '../attributes/arcgis-graphic-attr-fac.view'
import { ArcGisGeomValidaiton } from '../geometry/arcgis-geometry-validation.view'
import { ArcGisAreaGraphicFactory } from '../graphic/area-graphic.view'
import { ArcGisSketchCustomToolStateHandler } from '../sketch/custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'
import { SketchVmStateEnum } from '../sketch/sketch-vm.view'
import { ArcgisSpatialRefence } from '../spatial-reference/arcgis-spatial-reference.view'
import { ArcGisMapViewHandler } from '../map-view/map-view-handler.view'
import { AreaStatusViewModel } from '@view/area/area-status.view'

export namespace ArcgisAreaViewModel {
  export const updateAreaGraphicByErrorEditOrDisplay = (
    context: ArcGisMapService,
    graphic: __esri.Graphic,
    errorOrNull: MapConfigValidationErrors | null,
    eventState?: SketchVmStateEnum
  ): void => {
    if (errorOrNull) {
      // TODO Add custom edit red symbol
      // console.log(`Setting error symbol`)
      graphic.symbol = ArcGisSymbolFactory.failedValidationAreaSymbol.clone()
      ArcGisMapViewHandler.updateToFailedHighlights(context)
    } else if (
      // Keep the graphic symbol in edit state during changes or if they just clicked on it
      context.mapConfigPopupRef?.areaAttributes?.id === graphic.attributes.id ||
      eventState === SketchVmStateEnum.start
    ) {
      // console.log(`Setting edit school area symbol`)
      graphic.symbol = ArcGisSymbolFactory.editSchoolGeomSymbol.clone()
      ArcGisMapViewHandler.updateToEditHighlights(context)
    } else {
      // console.log(`Setting valid done editing area symbol`)
      graphic.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
    }
  }
  /** Used for update, redo, and undo geometry events */
  export const handleAreaGeomUpdate = (
    context: ArcGisMapService,
    event:
      | __esri.SketchViewModelUndoEvent
      | __esri.SketchViewModelRedoEvent
      | __esri.SketchViewModelUpdateEvent
  ) => {
    if (!event.graphics[0]) {
      console.warn(`Attempting to redo geometry change when no graphic associated to event`)
      return
    }
    const projectedGraphicGeom = ArcGisMapProjectionViewModel.projectToSpatialReference(
      event.graphics[0].geometry,
      ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
    )
    // console.log(`handleRingsChange`)
    // TODO Refactor to reuse validate and save logic between create update, redo and undo
    const validationErrorOrNull = ArcGisGeomValidaiton.getAreaValidationErrorOrNull(
      context,
      event.graphics[0]?.attributes?.id,
      event.graphics[0]
    )
    ArcgisAreaViewModel.updateAreaGraphicByErrorEditOrDisplay(
      context,
      event.graphics[0],
      validationErrorOrNull
    )
    // Any time the validation comes back different we need to update state with that value
    if (context.mapConfigValidationError !== validationErrorOrNull) {
      // console.log(
      //   `Dispatching new map config error value in handle area update`,
      //   validationErrorOrNull
      // )
      context.store.dispatch(setMapConfigErrorReason(getExtendedAction(validationErrorOrNull)))
    }
    if (!validationErrorOrNull) {
      context.mapConfigPopupRef?.handleRingsChange((projectedGraphicGeom as Polygon).rings[0])
    }
    ArcGisPopupView.displayPopupForGraphic(context, event.graphics[0])
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(
      context,
      validationErrorOrNull,
      event.graphics[0]
    )
  }
  /** Checks to see if the area in edit state is missing a primary key, which means it was just created so we must manually find it in the school dto and update the popup component.
   * @returns false if no manual update is needed and
   * @returns true if manual update was made
   */
  export const updateNewAreaOnMapConfigMap = (
    context: ArcGisMapService,
    schoolDto: SchoolDto
  ): void => {
    // See if the popup is in edit mode for an area and if the area doesn't have an ide because it was just created
    const currentAreaInEditHasNoId =
      context.mapConfigPopupRef &&
      context.mapConfigPopupRef?.areaAttributes &&
      context.mapConfigPopupRef?.areaAttributes?.id === undefined
    if (!currentAreaInEditHasNoId) {
      return
    }
    // console.log(`Found an area without an id!`)
    const graphicOnMapWithNoId = context._schoolAreaLayer?.graphics.find(
      (a) => a.attributes?.id === undefined
    )
    if (!graphicOnMapWithNoId) {
      return
    }
    graphicOnMapWithNoId.attributes = context.mapConfigPopupRef?.areaAttributes
    // const areaGraphicWithNoId = context._schoolAreaLayer?.graphics.find(a => a.attributes.logicalId === areaOnMapWithNoId?.attributes.logicalId)
    const areaDtoInSchoolDto = schoolDto.subareas?.find(
      (a) => a.logicalId === graphicOnMapWithNoId.attributes.logicalId
    )
    if (!areaDtoInSchoolDto) {
      return
    }
    const isValidPatchDto = SchoolAreaModelHelper.areaIsValid(areaDtoInSchoolDto)
    if (!isValidPatchDto) {
      throw Error('Posted new area and came back with missing id or school id!')
    }
    const newAttributes = ArcGisGraphicAttrFac.getAttributesForAreaDto(areaDtoInSchoolDto)
    // console.log('newAttributes', newAttributes)
    graphicOnMapWithNoId.attributes = newAttributes
    if (context.mapConfigPopupRef?.areaAttributes?.logicalId === newAttributes.logicalId) {
      // TODO Set up distinct method just to update attributes like in this scenario
      context.mapConfigPopupRef.areaAttributes = newAttributes
    }
    //Now that we have a valid graphic we can put the sketch vm into edit mode
    // Put the newly created area into an edit state
    // console.log(`context._sketchViewModel?.update([graphicOnMapWithNoId])`)
    context._sketchViewModel?.update([graphicOnMapWithNoId])
    // console.log(`Updated new area with id ${newAttributes.id}`)
    return
  }
  /** TODO Fix issue where stale data may not trigger recalc and removal of area status by ordering. */
  export const handleAreaDataChange = (
    context: ArcGisMapService,
    update: {
      areasVisible: boolean
      schoolDto: SchoolDto | null
      areaStatusLookup: Record<number, AreaStatusViewModel>
      selectedAreaId: number
    }
  ): void => {
    // console.log(`handleAreaDataChange called`)
    const { selectedAreaId, areaStatusLookup, areasVisible, schoolDto } = update
    // TODO on redirect back to dash from map config this can get called before areas init - fix race condition
    // Each time clear it so we can display a new status
    if (!schoolDto) {
      console.warn(`_handleAreasVisibilityChange SchoolDto is null`)
      return
    }
    if (!schoolDto.subareas) {
      console.warn(`_handleAreasVisibilityChange School ${schoolDto.id} has no subareas`)
      return
    }
    if (!context._schoolAreaLayer) {
      console.warn(`_handleAreasVisibilityChange SchoolAreaLayer is null`)
      return
    }
    if (Object.values(areaStatusLookup).length === 0) {
      console.warn(`Not ready to add areas to map until we have an area's lookup object in state`)
      return
    }
    //Each time data changes readd all areas
    context._schoolAreaLayer.visible = areasVisible
    //If the popup is for an area and we're hiding areas hide the popup
    if (
      !context._schoolAreaLayer.visible &&
      context.popupRef?.isAreaPopup &&
      context.popupRef.isShownDueToClick
    ) {
      context.popupRef.hidePopup()
    } else if (context.popupRef?.isAreaPopup && context.popupRef.popupVisible === false) {
      context.popupRef.showPopup()
    }
    // console.log(`In _handleAreasVisibilityChange: Adding areas to map`)
    // TODO Optimize to not rerender all areas but find the one that changed and update it, needs to be reconciled with the area view model logic which currently requires redrawing each time we get a new poll which is the complexity at hand
    context._schoolAreaLayer.removeAll()
    addAreasToDashMap(context, schoolDto.subareas, areaStatusLookup, selectedAreaId)

    // console.log(`_schoolAreaLayer is
    //     visible: ${context._schoolAreaLayer?.visible}
    //     number of shapes ${context._schoolAreaLayer?.graphics.length}
    // `)
  }

  export const addAreasToMapConfigMap = (
    context: ArcGisMapService,
    areas: SchoolAreaDto[],
    selectedId = 0
  ) => {
    if (!context._schoolAreaLayer && (context._schoolAreaLayer!.graphics ?? []).length > 0) {
      return
    }
    //Reset the area layer
    areas.forEach((a) => {
      const areaValid = SchoolAreaModelHelper.areaIsValid(a)
      if (!areaValid) {
        return
      }
      const graphic = ArcGisAreaGraphicFactory.getGraphicFromAreaDto(a)
      // console.log(
      //   `Added area ${a.id} to school map config page map, school logical id ${
      //     a[SchoolAreaDtoProps.schoolLogicalId]
      //   }, logical id ${a[SchoolAreaDtoProps.logicalId]}`
      // )
      context._schoolAreaLayer?.add(graphic)
    })
  }
  export const addAreasToDashMap = (
    context: ArcGisMapService,
    areas: SchoolAreaDto[],
    areaStatusLookup: Record<number, AreaStatusViewModel> = {},
    selectedAreaId: number = 0
  ) => {
    if (!context._schoolAreaLayer && (context._schoolAreaLayer!.graphics ?? []).length > 0) {
      return
    }
    // console.log(`Calling addAreasToDashMap`)
    // console.log(`areas`, areas)
    // console.log(`areaStatusLookup`, areaStatusLookup)
    // console.log(`areas`, areas)
    //Reset the area layer
    areas.forEach((a) => {
      const areaValid = SchoolAreaModelHelper.areaIsValid(a)
      if (!areaValid) {
        return
      }
      let statusVm = areaStatusLookup[a.id]
      if (!statusVm) {
        console.warn(`There is no status lookup in state for area with id ${a.id}`)
        statusVm = new AreaStatusViewModel()
      }
      const isSelectedArea = selectedAreaId === a.id
      const polygon = new Polygon({
        rings: [a.boundary],
        spatialReference: ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
      })
      //Save off the expanded projection for faster opacity handling
      if (!context.projectedAreaPolygonGeomLookups[a.id]) {
        const projectedPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
          polygon,
          ArcgisSpatialRefence.webMercatorSpatialReference
        )
        const bufferedPolygon = geometryEngine.buffer(
          projectedPolygon,
          context._appConfig.config.PROXIMITY_DISTANCE_IN_FEET,
          'feet' as __esri.LinearUnits
        ) as Geometry
        const projectedBackPolygon = ArcGisMapProjectionViewModel.projectToSpatialReference(
          bufferedPolygon,
          ArcgisSpatialRefence.geoUnitsLatLongSpatialReference
        )
        if (projectedBackPolygon) {
          context.projectedAreaPolygonGeomLookups[a.id] = projectedBackPolygon as Geometry
        }
      }
      let areaSymbol: SimpleFillSymbol
      if (context.type === MapPageType.schoolDashboard) {
        areaSymbol = ArcGisSymbolFactory.getSchoolAreaSymbol(isSelectedArea, statusVm)
      } else if (context.type === MapPageType.schoolMapConfig) {
        areaSymbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      } else {
        console.warn(`Unaccounted for context map page type, no area symbol applied.`)
        areaSymbol = new SimpleFillSymbol()
      }
      const graphic = new Graphic({
        geometry: polygon,
        symbol: areaSymbol,
        attributes: {
          kind: GraphicAttributesTypeEnum.area,
          id: a.id,
          type: a.type,
          name: a.name,
          logicalId: a.logicalId ?? null,
          areaType: a.areaType,
          statusVm: statusVm
        } as AreaGraphicAttributes
        // popupTemplate: ArcGisPopupView.createPopupTemplate(a)
      })
      //In case the popup is already open update it
      if (isSelectedArea && context.popupRef) {
        context.popupRef?.setContent(graphic.attributes)
        context.popupRef?.setGraphic(graphic)
        ArcGisEventHandlers.updatePopupPosition(context.popupRef as BaseMapPopup, context._mapView)
      }
      context._schoolAreaLayer?.add(graphic)
      // console.log(`Added area ${a.id} to map`)
    })
  }
  export const updateAreaSymbolById = (
    context: ArcGisMapService,
    id: number,
    symbol: __esri.Symbol
  ): boolean => {
    const selectedAreaGraphic = context._schoolAreaLayer?.graphics.find(
      (a) => a.attributes.id === id
    )
    if (selectedAreaGraphic) {
      // console.log(`Updating graphic for area id ${id}`)
      selectedAreaGraphic.symbol = symbol
      return true
    } else {
      // console.warn(`Couldn't update symbol for area id ${id} because it's not in the area layer.`)
      return false
    }
  }
  /** Updates the sketch graphic symbol based on error state and reverts back to edit if no error state. */
  export const updateAreaSybmolByErrorState = (
    context: ArcGisMapService,
    error: MapConfigValidationErrors | null
  ): void => {
    // Only apply error states to areas if the area is in edit which means the map config popup must be visible
    if (!context.mapConfigPopupRef?.popupVisible) {
      return
    }
    if (
      context._sketchViewModel?.updateGraphics &&
      context._sketchViewModel?.updateGraphics.at(0)
    ) {
      const graphicToUpdate = context._sketchViewModel?.updateGraphics.at(0)
      if (error) {
        // console.log(`Updating to updateAreaSybmolByErrorState ${error}`)
        graphicToUpdate.symbol = ArcGisSymbolFactory.failedValidationAreaSymbol.clone()
        ArcGisMapViewHandler.updateToFailedHighlights(context)
      } else {
        // console.log(`Updating to updateAreaSybmolByErrorState ${error}`)
        graphicToUpdate.symbol = ArcGisSymbolFactory.editSchoolAreaSymbol.clone()
        ArcGisMapViewHandler.updateToEditHighlights(context)
      }
    }
  }
  export const areaSnappingOptions = (context: ArcGisMapService): SnappingOptions => {
    const options = new SnappingOptions({
      featureSources: [{ enabled: true, layer: context._schoolAreaLayer }],
      distance: context._appConfig.config.PROXIMITY_DISTANCE_IN_FEET,
      enabled: true,
      selfEnabled: true,
      featureEnabled: true
    })
    return options
  }
}
