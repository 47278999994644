
export enum FirstLevelDir {
  home = 'home',
  /** A listing of all the organizations user's with type association. */
  roster = 'roster',
  /** The main browser app page, after organization has been fully configuration. */
  dashboard = 'dashboard',

  // Not clear if there'll be an account page
  // account = 'account',
  /** Remove school parent directory as everything will be on the first level since we're not going to have many pages. Need to move school validation first */
  school = 'school',
  instructions = 'instructions',
  events = 'events',
  /** Configuration of the map after school selection. */
  setupSchoolAreasMap = 'setupSchoolAreasMap',

  //Service Pages
  forbidden = '403',
  /** Not used now but can be useful potentially if there's a possibility to access a url by id - TODO design */
  notFound = '404',

  /** TODO Design - some page to indicate general server error and site down. */
  serverError = '500',

  //IMPORTANT: No other route in this app should contain these two unique logic bearing keys

  /** Do not reuse in other url paths */
  auth = 'auth',

  /** Do not reuse in other url paths */
  public = 'public'
}
export enum SecondLevelDir {
  //Auth related
  loggedIn = 'loggedIn',
  triggerLogout = 'logout',
  loginRedirect = 'login',
  loggedOut = 'loggedOut',
  createAccount = 'createAccount',

  //School

  /** A listing of all the events an org has had. */
  events = 'events',

  /** Select of the school for first time visitors. */
  validation = 'validation',

  //Public
  welcome = 'welcome'
}

/** This subdomain indicates that the auth layer should be used and non public routes are available. */
export const authenticatedAppSubdomain = "app"

/** These must match the ports in package.json, and are used in the same way as an app. subdomain indicates auth version of app. */
export enum LocalHostPorts {
  Public = '4100',
  Auth = '4200',
}

const routePrefix = '/'
export type RouteEntry = { [key: string]: string }
export type RouteType = {
  url: string
  nextDir: RouteEntry
}
export type FirstLevelRouteMap = Record<FirstLevelDir, string>
export namespace OwlRoutes {
  const returnFirstLevelUrl = (d: FirstLevelDir): RouteEntry => {
    return { [d]: `${routePrefix}${d}` }
  }

  export let firstLevelRoutes: FirstLevelRouteMap = Object.values(FirstLevelDir)
    .map((s) => returnFirstLevelUrl(s))
    .reduce((accum: FirstLevelRouteMap, curr: any) => {
      return {
        ...accum,
        ...curr
      }
    }, {} as any)
  // console.log(firstLevelRoutes)

  //Second Level Routes
  //PUBLIC
  export let welcomePage = `${FirstLevelDir.public}/${SecondLevelDir.welcome}`
  //AUTH
  export let loggedOut = `${FirstLevelDir.auth}/${SecondLevelDir.loggedOut}`
  export let loggedIn = `${FirstLevelDir.auth}/${SecondLevelDir.loggedIn}`
  export let login = `${FirstLevelDir.auth}/${SecondLevelDir.loginRedirect}`
  //SCHOOL
  /** @deprecated Remove second level directories since this app won't have many pages. */
  export const schoolValidationPage = `${FirstLevelDir.school}/${SecondLevelDir.validation}`
}

/** This is the default permission of links until we can derive link access based on the state of the school dto as well as the collection of organization users. */
export type IsSiteLinkInteractive = Record<string, boolean>
export namespace OwlSiteLinksViewModel {
  export const isSiteLinkInteractive = {
    /** This can only be accessed after the org validation, map config, and user roster steps are complete */
    [OwlRoutes.firstLevelRoutes.dashboard]: true,

    /** 
     * This page is blocked until the user validates their school
     */
    [OwlRoutes.firstLevelRoutes.setupSchoolAreasMap]: false,

    /** This page is enabled on a first visit */
    [OwlRoutes.schoolValidationPage]: true,

    /** Access to user roster is predicated on completion of the map configuration. */
    [OwlRoutes.firstLevelRoutes.roster]: false,

    /** A top level directory to encapsulate organization dashboard, events, and other pages. */
    [OwlRoutes.firstLevelRoutes.school]: true,

    [OwlRoutes.welcomePage]: true,
    /** This is an auth based redirect page and can be 
     * accessed by any user who after logged in
     */

    [OwlRoutes.loggedIn]: true,
    /** A user can allways log out and see the log out confirmation screen - TODO designs */
    [OwlRoutes.loggedOut]: true,

    /** Used for msal based redirects for authentication, NOTE: internal code use only. */
    [OwlRoutes.login]: true,

  }
}