import { SchoolValidationFormProps, SchoolValidationSearchDto } from "@model/school-validation.model";
import { ISchoolValidationPageState } from "@state/page/school-validation-page.state";

export type SchoolValidationPropToStringLookup = Record<SchoolValidationFormProps, string>

export class SchoolValidationViewModel implements SchoolValidationPropToStringLookup {
    name: string = ''
    street: string = ''
    city: string = ''
    state: string = ''
    zip: string = ''
    /** If the prop has a value then add it to the string as a url query parameter, otherwise skip it. */
    static getQueryStringFromPresentProps = (vm: SchoolValidationViewModel): string => {
        return encodeURI(Object.entries(vm).reduce((accum, curr) => {
            const value = curr[1]
            let key = curr[0]
            // if (key === SchoolValidationFormProps.address1) {
            //     key = "street"
            // }
            if (value) {
                return `${accum}${accum ? "&" : ""}${key}=${value}`
            } else {
                return accum
            }
        }, ""))
    }

    /** Here to enable auto selection of search results by query if cached or just returned. */
    static selectSchoolSearchResults = (
        s: ISchoolValidationPageState
    ): SchoolValidationSearchDto[] | null => {
        return s
            .searchQueryToResultDtoLookup[
            SchoolValidationViewModel.getQueryStringFromPresentProps(
                s.formValues
            )
        ] || null
    }
}
export const defaultSchoolValidationVm = new SchoolValidationViewModel()

export const displayTextForSchoolValidationInputs: SchoolValidationPropToStringLookup = {
    name: 'School Name',
    street: 'Street Address',
    city: 'City',
    state: 'State',
    zip: 'Zip',
}
export const placeholdersForSchoolValidationInputs: SchoolValidationPropToStringLookup = {
    name: 'Maplewood Elementary School',
    street: '1234 Elm Street',
    city: 'Springfield',
    state: 'Anystate',
    zip: '12345',
}
