import { MatDialogConfig } from '@angular/material/dialog'
import { BtnBorderRadius, BtnColorVariants, ButtonHeight, ButtonWidth } from '@component/shared/button/button.component.view'
import { ConcludeEventModalContent } from '@service/app-config/app-config.model'

// export const modalHeight = 250;
// export const modalWidth = 500;

// export const getPxString = (number: number) => {
//     return `${number}px`
// }

export const defaultModalOptions: MatDialogConfig = {
  // height: getPxString(modalHeight),
  // width: getPxString(modalWidth),
  hasBackdrop: true,
  autoFocus: false,
  id: 'owl-modal'
  // closeOnNavigation: true
}
export type ModalActionResultFunction = (vm?: ModalViewModel) => void
export class ModalViewModel {
  static lineBreakToken = '<br>'
  constructor(
    public readyToShow = false,
    public iconName = '',
    public titleText = '',
    public promptText = '',
    public confirmButtonText = '',
    public cancelButtonText = '',
    public confirmHandler: ModalActionResultFunction = () => { },
    public cancelHandler: ModalActionResultFunction = () => { },
    public CONCLUDE_EVENT_MODAL_CONTENT?: ConcludeEventModalContent,
    public showRevertToStandardCheckboxex = false
  ) {}
  public confirmButtonIcon = ''
}
/** This is a specific combination of button options used for interacting with forms. */
export class FormButtonsViewModel {
  static primaryBtnType = BtnColorVariants.primary
  static secondaryBtnType = BtnColorVariants.secondaryVariant

  static btnHeight = ButtonHeight.large
  static btnWidth = ButtonWidth.fullWidth
  static btnBorderRadius = BtnBorderRadius.small
}