import { SetRosterUsersByTabPayload } from '@model/user/set-roster-users-by-tab.payload'
import {
  GetUserDto,
  MobileUserTypes,
  SchoolIds,
  UserDtoProps,
  UserDtoStatus,
  UserTypes
} from '@model/user/user.model'
import {
  InviteHistoryDtoByUserIdLookup,
  InviteSummaryDtoByMobileUserTypeLookup
} from '@state/page/roster-page.state'
import {
  BroswerUserTypesToDisplayInRoster,
  MobileUserTypeToRosterTab,
  MobileUserTypesToDisplayInRoster,
  OwlUserTypes,
  RosterBrowserUserTypeToRosterTab,
  RosterTabToRosterTabVmLookup,
  RosterTabViewModel,
  RosterTabs,
  UserInvitationHistoryDto
} from '@view/pages/roster-page/roster-page.view'
import { RosterUserVm } from '@view/pages/roster-page/roster-table-user.view'
import { IRosterUserVm } from '@view/pages/roster-page/roster-table-user.view.model'

/** Count of school ids is great than 1 here because users get a mock school association on dev for testing purposes, TODO discuss setting up mock environment for demo but no fake data on prod. */
export class UserDtoHelper {
  /** We don't want to enable a roster table user to edit Global Owl Admins. TODO Confirm how we want to handle Global admins in regard to the roster page.*/
  static USER_TYPES_TO_OMIT_FROM_ROSTER = [UserTypes.globalAdmin]
  /** For now we consider all mobile users valid for statistics aggregation if they aren't deactivated. Simply existing enables the onboarding redirect to dashboard modal to show */
  static isNotDeactivatedAndExists(dto: GetUserDto): boolean {
    return dto[UserDtoProps.status] !== UserDtoStatus.deactivated
  }
  static isAdmin(userType: UserTypes | null) {
    return userType === UserTypes.schoolAdmin || userType === UserTypes.schoolOwner
  }
  /** We let school admins, owners and global admins see the roster page. */
  static isRosterAdmin(userType: OwlUserTypes | null) {
    return (
      userType === UserTypes.schoolAdmin ||
      userType === UserTypes.schoolOwner ||
      userType === UserTypes.globalAdmin
    )
  }
  static isMobileUserType = (t: OwlUserTypes): boolean => {
    return Object.values(MobileUserTypes).includes(t as any)
  }
  static isWebUserType = (t: OwlUserTypes): boolean => {
    return Object.values(UserTypes).includes(t as any)
  }
  /** Once mobile user types are dynamic this will need to be extended */
  static isMobileUser(t: OwlUserTypes | undefined): boolean {
    return [MobileUserTypes.student, MobileUserTypes.teacher, MobileUserTypes.otherStaff].includes(
      t as MobileUserTypes
    )
  }
  /** Once web user types are dynamic this will need to be extended */
  static isWebUser(t: OwlUserTypes | undefined) {
    return [UserTypes.schoolAdmin, UserTypes.schoolOwner, UserTypes.globalAdmin].includes(
      t as UserTypes
    )
  }
  /** Provides logic for who can access the school selection aka account school validation step. */
  static canSelectSchool(userDto: GetUserDto | undefined): boolean {
    if (!userDto) {
      return false
    }
    return UserDtoHelper.hasNoSchoolId(userDto?.schoolIds)
  }
  /** Centralize logic for constructing the display name from the user model. */
  static getFullName = (dto: GetUserDto): string =>
    `${dto?.firstName ? dto?.firstName : ''} ${dto?.lastName ? dto?.lastName : ''}`
  static schoolIdsExist(schoolIds: SchoolIds[] | undefined): boolean {
    return !!schoolIds && schoolIds.length > 0
  }
  /** Here to centralize if you have no school ids associated with your account or you have just one and it's the mock id*/
  static hasNoSchoolId(schoolIds: SchoolIds[] | undefined): boolean {
    if (!schoolIds) return true
    return schoolIds.length === 0
  }

  /** For now hard code to index 1 because the index 0 will have the mocked school id for users until we decide how to set up mocks for all dev users to enable easier testing.
   * // TODO integrate session service to persist selected school id once UI enables that, for now hard code to first set of school ids in user object
   */
  static getDefaultSchoolId(schoolIds: SchoolIds[] | undefined): SchoolIds | undefined {
    if (!schoolIds) return undefined
    if (schoolIds.length === 0) return undefined
    return schoolIds[0]
  }

  /** Gets a collection of user dtos and verifies that there's at least one with a mobileType. */
  static usersWithMobileTypeExist(userDtos: GetUserDto[] | null): boolean {
    if (!userDtos) return false
    if (userDtos.length === 0) return false
    return userDtos.some((dto) => !!dto[UserDtoProps.mobileType])
  }

  /** Enables you to pass in both the mobile and browser user type and get back one of them.
   * Once we handle both for a user, one will have to take precedence over the other. Most likely depending on which tab you're viewing.
   */
  static getRosterUserType(
    mobileType?: MobileUserTypes,
    userType?: UserTypes
  ): OwlUserTypes | null {
    if (userType && BroswerUserTypesToDisplayInRoster.includes(userType)) {
      return userType
    } else if (mobileType && MobileUserTypesToDisplayInRoster.includes(mobileType)) {
      return mobileType
    }
    return null
  }

  static getRosterUserVmFromGetUserDto(
    dto: GetUserDto,
    inviteHistoryDto: UserInvitationHistoryDto | null
  ): IRosterUserVm {
    const { mobileType, type } = dto
    if (!mobileType && !type) {
      throw new Error(
        `UserDtoHelper.getRosterUserVmFromGetUserDto: mobileType and type are both undefined, user must have at least one, some critical code flaw has occurred.`
      )
    }
    const expandedUserType = UserDtoHelper.getRosterUserType(mobileType, type)
    if (!expandedUserType) {
      throw new Error(
        `UserDtoHelper.getRosterUserVmFromGetUserDto: expandedUserType is undefined, user must have a resolvable role, some critical code flaw has occurred.`
      )
    }
    return RosterUserVm.fromGetUserDto(dto, expandedUserType, inviteHistoryDto)
  }

  /** Takes in an existing lookup for roster tabs to roster tab view models and adds  */
  static getRosterUsersByRosterTabLookup(
    payload: SetRosterUsersByTabPayload,
    existingLookup: RosterTabToRosterTabVmLookup,
    userInviteHistoryDtoLookup: InviteHistoryDtoByUserIdLookup
  ): RosterTabToRosterTabVmLookup {
    let lookupCopy = {
      ...existingLookup
    }
    if (!payload.users || payload.users.length === 0) {
      return lookupCopy
    }
    lookupCopy = payload.users.reduce(
      (accum, curr) =>
        this.setRosterUsersDtoReducerWithExtraParam(accum, curr, userInviteHistoryDtoLookup),
      {} as RosterTabToRosterTabVmLookup
    )
    // console.log(`RosterTabToRosterTabVmLookup: `, lookupCopy)
    return lookupCopy
  }
  static addToAccumulatorPerTab = (
    tab: RosterTabs,
    accum: RosterTabToRosterTabVmLookup,
    vm: IRosterUserVm
  ): void => {
    let copyOfAccum = { ...accum }
    let copyOfTab: RosterTabViewModel | null = copyOfAccum[tab]

    if (copyOfTab) {
      copyOfAccum[tab] = {
        ...copyOfTab,
        userVms: [...copyOfTab.userVms, vm],
        isInProcess: false,
        tab
      }
    } else {
      copyOfAccum[tab] = {
        tab,
        isInProcess: false,
        userVms: [vm]
      }
    }
    accum = copyOfAccum
  }
  static setRosterUsersDtoReducerWithExtraParam(
    accum: RosterTabToRosterTabVmLookup,
    currDto: GetUserDto,
    userInviteHistoryDtoLookup: InviteHistoryDtoByUserIdLookup
  ): RosterTabToRosterTabVmLookup {
    if (!currDto) {
      // console.log(`Ignoring dto for RosterTabToRosterTabVmLookup because it's null`)
      return accum
    }
    const { mobileType, type } = currDto
    const expandedUserType = UserDtoHelper.getRosterUserType(mobileType, type)
    if (!expandedUserType) {
      console.warn(
        `Ignoring dto for RosterTabToRosterTabVmLookup because it has no user roster allowed mobile or browser user type!`
      )
      return accum
    }

    //Default is consolidated users tab since everyone ends up in there.
    let tabsForUserDisplay: RosterTabs[] = [RosterTabs.consolidatedAllUsers]

    if (mobileType) {
      const mobileUserTab = MobileUserTypeToRosterTab[mobileType]
      mobileUserTab ? tabsForUserDisplay.push(mobileUserTab) : null
    }

    if (type) {
      const browserUserTab = RosterBrowserUserTypeToRosterTab[type]
      browserUserTab ? tabsForUserDisplay.push(browserUserTab) : null
    }
    //Create the view model for the user, only one object will be on the heap while each tab will have a reference to it.
    const userInvitationHistory = userInviteHistoryDtoLookup[currDto.id] ?? null
    const rosterUserVm = UserDtoHelper.getRosterUserVmFromGetUserDto(currDto, userInvitationHistory)

    tabsForUserDisplay.forEach((tab) => {
      if (type && UserDtoHelper.USER_TYPES_TO_OMIT_FROM_ROSTER.includes(type)) {
        return
      }
      let copyOfTab: RosterTabViewModel | null = accum[tab]

      if (copyOfTab) {
        accum[tab] = {
          ...copyOfTab,
          userVms: [...copyOfTab.userVms, rosterUserVm],
          isInProcess: false,
          tab
        }
      } else {
        accum[tab] = {
          tab,
          isInProcess: false,
          userVms: [rosterUserVm]
        }
      }
    })
    return accum
  }
}
