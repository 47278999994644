export enum MapModesEnum {
  selectPoints = 'selectionPoints',
  selectShapes = 'selectionShapes',
  upload = 'upload',
  edit = 'edit',
  draw = 'draw'
}
export enum MapApiEnum {
  openLayers = 'openLayers',
  arcGis = 'arcGis',
  mapBox = 'mapBox',
  unknown = 'unknown'
}
export enum MapSourceEnum {
  OpenStreetMap,
  Esri,
  Google,
  Balcony
}
export enum MapSelectionEnum {
  Polygon = 'Polygon',
  Box = 'Box',
  Square = 'Square',
  Circle = 'Circle',
  Star = 'Star',
  None = 'None'
}
//Projection
export enum ProjectionNumberEnum {
  EPSG_4326 = 4326,
  EPSG_900913 = 900913
}
export class ProjectionNames {
  static readonly EPSG_4326 = `EPSG:${ProjectionNumberEnum.EPSG_4326}`
  static readonly EPSG_900913 = `EPSG:${ProjectionNumberEnum.EPSG_900913}`
}
