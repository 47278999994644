import { ExtendedAction } from '@action/extended-ngrx-action'
import { SchoolIds } from '@model/user/user.model'
import { createAction, props } from '@ngrx/store'

export enum AuthAction {
  tokenReceived = '[AuthAction] Token Received',
  getSchoolById = '[AuthAction] Get School By Id',
  logoutAuthAction = '[AuthAction] Log Out',
  getUsersForSchool = '[AuthAction] Get Users For School',
  
  reloadUsersForSchool = '[AuthAction] Reload Users For School',
  reloadInstructionsForCommunications = "[AuthAction] Reload Instructions For Communications"
}

export const tokenReceived = createAction(AuthAction.tokenReceived, props<ExtendedAction<string>>())
export const logoutAuthAction = createAction(AuthAction.logoutAuthAction)
export const getSchoolById = createAction(AuthAction.getSchoolById, props<ExtendedAction<number>>())
export const getUsersForSchool = createAction(
  AuthAction.getUsersForSchool,
  props<ExtendedAction<SchoolIds>>()
)
export const reloadUsersForSchool = createAction(AuthAction.reloadUsersForSchool)
export const reloadInstructionsForCommunications = createAction(AuthAction.reloadInstructionsForCommunications,
  props<ExtendedAction<number>>()
)
