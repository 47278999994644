import { createSelector } from '@ngrx/store'
import { selectSchoolById } from '@selector/auth.selector'
import { LocationsForDashMapViewModel } from '@view/map/locations/map-control-ui.view'
import {
  selectAreaStatusLookup
} from './dashboard-page-state.selectors'
import { selectAreaIdSelected, selectShowSubAreas } from './dashboard-view.selectors'

export const selectAreaWithStatusData = createSelector(
  selectSchoolById,
  selectShowSubAreas,
  selectAreaStatusLookup,
  selectAreaIdSelected,
  LocationsForDashMapViewModel.getVisibilityWithSchoolDto
)
